import {
	ApiFailureResponse,
	NotificationsSuccessResponse,
	ApiFailure,
	NotificationsReadSuccessResponse,
	NotificationType,
	Nullable,
	NotificationsRequest,
} from '../../types'
import { AUTH_LOGOUT_SUCCESS } from '../redux/auth'

export interface NotificationsState {
	notifications: Nullable<NotificationType[]>
	fetching: boolean
	error: Nullable<ApiFailure>
	page: Nullable<number>
	limit: Nullable<number>
	total: Nullable<number>
	read_fetching: Nullable<boolean>
	read_error: Nullable<ApiFailure>
	opened_notification: any
}

export const NOTIFICATIONS_REQUEST = 'NOTIFICATIONS_REQUEST'
export const NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS'
export const NOTIFICATIONS_FAILURE = 'NOTIFICATIONS_FAILURE'
export const NOTIFICATIONS_READ_REQUEST = 'NOTIFICATIONS_READ_REQUEST'
export const NOTIFICATIONS_READ_SUCCESS = 'NOTIFICATIONS_READ_SUCCESS'
export const NOTIFICATIONS_READ_FAILURE = 'NOTIFICATIONS_READ_FAILURE'
export const OPENED_NOTIFICATION = 'OPENED_NOTIFICATION'
export const CLEAR_OPENED_NOTIFICATION = 'CLEAR_OPENED_NOTIFICATION'

export const notificationsRequestAction = (params?: NotificationsRequest) => ({ type: NOTIFICATIONS_REQUEST, params })
export const notificationsSuccessAction = (payload: NotificationsSuccessResponse) => ({ type: NOTIFICATIONS_SUCCESS, payload })
export const notificationsFailureAction = (payload) => ({ type: NOTIFICATIONS_FAILURE, payload })
export const notificationsReadRequestAction = (params) => ({ type: NOTIFICATIONS_READ_REQUEST, params })
export const notificationsReadSuccessAction = (payload) => ({ type: NOTIFICATIONS_READ_SUCCESS, payload })
export const notificationsReadFailureAction = (payload) => ({ type: NOTIFICATIONS_READ_FAILURE, payload })
export const openedNotificationAction = (payload) => ({ type: OPENED_NOTIFICATION, payload })
export const clearOpenedNotificationAction = (payload) => ({ type: CLEAR_OPENED_NOTIFICATION, payload })

const initialState: NotificationsState = {
	notifications: null,
	fetching: false,
	error: null,
	page: null,
	limit: null,
	total: null,
	read_fetching: null,
	read_error: null,
	opened_notification: null,
}

const NotificationsReducer = (state = initialState, action: any): NotificationsState => {
	const { type, payload, params } = action
	switch (type) {
		case NOTIFICATIONS_REQUEST: {
			return { ...state, error: null, fetching: true }
		}
		case NOTIFICATIONS_SUCCESS: {
			const { notifications, page, limit, total } = payload as NotificationsSuccessResponse
			const _notifications = page == 1
				? notifications
				: [...(state.notifications || []), ...notifications]
			return { ...state, fetching: false, notifications: _notifications, page, limit, total }
		}
		case NOTIFICATIONS_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, fetching: false, error }
		}
		case NOTIFICATIONS_READ_REQUEST: {
			return { ...state, read_fetching: true }
		}
		case NOTIFICATIONS_READ_SUCCESS: {
			const { notification } = payload as NotificationsReadSuccessResponse
			const notifications = [...(state.notifications || [])]
			const index = notifications.findIndex(item => item.id == notification.id)
			notifications[index] = notification
			return { ...state, read_fetching: false, notifications }
		}
		case NOTIFICATIONS_READ_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, read_fetching: false, read_error: error }
		}
		case OPENED_NOTIFICATION: {
			return { ...state, opened_notification: payload }
		}
		case CLEAR_OPENED_NOTIFICATION: {
			return { ...state, opened_notification: null }
		}
		case AUTH_LOGOUT_SUCCESS: {
			return { ...initialState }
		}
		default:
			return state
	}
}

export default NotificationsReducer
