import React from 'react'
import { ScrollView, View, ViewStyle, Text, Dimensions } from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { connect } from 'react-redux'

import { Markdown, Screen, Image, Icon } from '../components'
import { t } from '../localization'
import { metrics, ThemeColors, useTheme, font } from '../theme'
import { MainNavigatorProps } from '../types'
import { AppState } from '../store/redux'

type ScreenProps = DispatchProps & StateProps & StackScreenProps<MainNavigatorProps, 'AideraScreen'>

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

interface Props extends ScreenProps {
	colors: ThemeColors
 }

interface State {
	screenWidth: number
}

function AideraScreenWrapper(props: Props) {
	const { colors } = useTheme()
	return <AideraScreen colors={colors} {...props} />
}

class AideraScreen extends React.Component<Props, State>{
	dimensionsSubscription: any
	state = { screenWidth: window.innerWidth }

	componentDidMount() {
		this.dimensionsSubscription = Dimensions.addEventListener("change", this.onChange)
	}

	componentWillUnmount() {
		this.dimensionsSubscription?.remove()
	}

	onChange = ({ window }: any) => {
    this.setState({ screenWidth: window.width })
  }

	render() {
		const { aboutApp, colors, navigation } = this.props

		const { screenWidth } = this.state
		const isScreenLess695 = screenWidth < 695

		return (
			<Screen>
				<>
					{ navigation.canGoBack() && (
					<View style={[styles.back, { top: 80 }]}>
						<View style={styles.backLink} onClick={() => navigation.goBack()}>
							<Icon
								size={16}
								style={styles.backIcon}
								name={'arrow_back'}
								color={colors.icon.default}
							/>
							<Text style={[styles.backText, { color: colors.text.title }]}>{t('back')}</Text>
						</View>
					</View>
					)}

					<ScrollView
						bounces={false}
						contentContainerStyle={styles.contentContainer}
					>
						<View style={[
							styles.container,
							{
								width: !isScreenLess695 ? '47.22%' : '100%',
								minWidth: !isScreenLess695 ? 635 : 'auto',
							}
						]}>
							<Text style={[styles.title, { color: colors.text.title }]}>{t('AIDERA')}</Text>
							{aboutApp.map(item => {
								return (
									<View key={item.id} style={[styles.content, { backgroundColor: colors.background.primary }]}>
										{!!item.image && <View style={styles.image}>
											<Image uri={item.image.original} width={metrics.screenWidth - 32 * 2} />
										</View>}
										<Markdown
											style={{ marginVertical: 8 }}
											text={item.text}
										/>
									</View>
								)
							})}
						</View>
					</ScrollView>
				</>
			</Screen>
		)
	}

}

const mapStateToProps = (state: AppState) => ({
	aboutApp: state.app.about || [],
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AideraScreenWrapper)

const styles = {
	contentContainer: {
		flexGrow: 1,
		alignItems: 'center',
		marginHorizontal: 30,
	} as ViewStyle,
	container: {
		marginBottom: 30,
	} as ViewStyle,
	content: {
		borderRadius: 20,
		paddingHorizontal: 16,
		paddingVertical: 8,
	} as ViewStyle,
	image: {
		marginVertical: 8,
		borderRadius: 20,
		overflow: 'hidden',
	} as ViewStyle,
	title: {
		marginTop: 40,
		marginBottom: 24,
		fontFamily: font(),
		fontSize: 24,
		lineHeight: 29,
		textAlign: 'left',
	},
	back: {
		position: 'fixed',
		left: 25,
		zIndex: 1,
	},
	backLink: {
		flexDirection: 'row',
		alignItems: 'center',
		cursor: 'pointer',
	},
	backText: {
		fontFamily: font(),
		fontSize: 20,
	},
	backIcon: {
		marginRight: 15,
	},
}
