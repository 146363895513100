import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { AppState } from '../store/redux'
import { StackScreenProps } from '@react-navigation/stack'
import {
	View,
	ViewStyle,
	FlatList,
	TouchableOpacity,
	StyleSheet,
	Text,
	Linking,
	Dimensions,
} from 'react-native'
import {
	Expandable,
	Icon,
	Screen,
	Markdown,
	Menu,
} from '../components'
import { t } from '../localization'
import { Faq, MainNavigatorProps } from '../types'
import { font, ThemeColors, useTheme } from '../theme'
import config from '../config'

type ScreenProps = DispatchProps & StateProps & StackScreenProps<MainNavigatorProps, 'FaqScreen'>

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

interface Props extends ScreenProps {
	colors: ThemeColors
	isSmallScreen: boolean
}

interface State {
	expanded: number[]
}

function FaqScreenWrapper(props: ScreenProps) {
	const { colors } = useTheme()
	const [screenData, setScreenData] = useState(Dimensions.get('window'))

	useEffect(() => {
		const onChange = (result) => {
			setScreenData(result.window)
		}

		const subscription = Dimensions.addEventListener('change', onChange)

		return () => subscription?.remove()
	})

	return <FaqScreen colors={colors} isSmallScreen={screenData.width < 635} {...props} />
}

class FaqScreen extends React.Component<Props, State>{
	constructor(props: Props) {
		super(props)
		this.state = {
			expanded: [],
		}
	}

	render() {
		const { expanded } = this.state
		const { faq, colors, isSmallScreen } = this.props

		return (
			<Screen>
				<FlatList
					bounces={false}
					contentContainerStyle={[
						styles.contentContainer,
						{
							width: !isSmallScreen ? '47.22%' : '100%',
							minWidth: !isSmallScreen ? 635 : 'auto',
						},
					]}
					keyExtractor={(item, index) => `${index}`}
					data={faq || []}
					ListHeaderComponent={
						<Text style={[styles.title, { color: colors.text.title }]}>{t('faq')}</Text>
					}
					renderItem={({ item, index }) => (
						<FaqItem
							item={item}
							isOpen={expanded.includes(index)}
							key={index.toString()}
							onHeaderPress={() => this.onHeaderPress(index)}
							idx={index}
						/>
					)}
					ListFooterComponent={<Footer />}
				/>
			</Screen >
		)
	}

	onHeaderPress = (index: number) => {
		const { expanded } = this.state
		const _expanded = [...expanded]
		if (expanded.includes(index)) {
			const _index = expanded.findIndex(item => item === index)
			_expanded.splice(_index, 1)
		} else {
			_expanded[0] = index
		}
		this.setState({ expanded: _expanded })
	}
}

const mapStateToProps = (state: AppState) => ({
	faq: state.faq.faq,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(FaqScreenWrapper)

const styles = {
	contentContainer: {
		flexGrow: 1,
		alignItems: 'stretch',
		alignSelf: 'center',
		marginVertical: 40,
		paddingHorizontal: 20,
	} as ViewStyle,
	title: {
		marginBottom: 24,
		fontFamily: font(),
		fontSize: 24,
		lineHeight: 29,
	},
}

interface FaqItem {
	item: Faq
	isOpen: boolean
	onHeaderPress: () => void
	idx: number
}

function FaqItem({ item, isOpen, onHeaderPress, idx }: FaqItem) {

	const { colors } = useTheme()
	const marginTop = idx === 0 ? { marginTop: 0 } : {}

	return (
		<Expandable
			isOpen={isOpen}
			style={[faqStyles.container, marginTop, { backgroundColor: colors.background.primary }]}
			header={
				<TouchableOpacity
					activeOpacity={1}
					style={faqStyles.headerContainer}
					onPress={onHeaderPress}
				>
					<Text style={[faqStyles.title, { color: isOpen ? colors.text.title : colors.text.default }]} >
						{item.question}
					</Text>
					<Icon
						name={isOpen ? 'collapse' : 'expand'}
						style={faqStyles.icon}
						color={colors.icon.primary}
						onPress={onHeaderPress}
					/>
				</TouchableOpacity>
			}
		>
			<Markdown
				style={{ paddingBottom: 16, paddingHorizontal: 16 }}
				text={item.answer}
			/>
		</Expandable>
	)
}

const faqStyles = StyleSheet.create({
	container: {
		borderRadius: 20,
		marginTop: 12,
	},
	headerContainer: {
		padding: 16,
		flexDirection: 'row',
		alignItems: 'center',
	},
	title: {
		fontFamily: font('bold'),
		fontSize: 16,
		lineHeight: 19,
		flex: 1,
	},
	icon: {
		marginLeft: 12
	},
})

function Footer() {
	const { colors } = useTheme()
	const [showModal, setShowModal] = useState<boolean>(false)

	const onEmailPress = () => {
		Linking.openURL(`mailto:${config.support_mail}`)
		setShowModal(false)
	}

	return (
		<View style={[footerStyles.container, { backgroundColor: colors.background.primary }]} >
			<Text style={[footerStyles.button, { color: colors.text.default }]}>{t('support_service')}</Text>
			<View style={{ flex: 1 }}>
				<Menu
					data={[
						{
							leftIcon: 'email',
							leftIconColor: colors.icon.white,
							text: config.support_mail,
							onPress: onEmailPress,
							type: 'primaty-button',
						},
					]}
					style={{ paddingVertical: 16 }}
				/>
			</View>
		</View>
	)
}

const footerStyles = StyleSheet.create({
	container: {
		marginTop: 12,
		paddingHorizontal: 16,
		borderRadius: 20,
		flexDirection: 'row',
		alignItems: 'center',
	},
	button: {
		marginRight: 16,
		fontFamily: font('bold'),
		fontSize: 16,
	},
	modalTextStyle: {
		flex: 0,
	},
})
