import React from 'react'
import { Modal, StyleSheet, Text, TouchableOpacity, View, Platform } from 'react-native'
import Button, { ButtonType } from './Button'
import { Nullable } from '../types'
import { font, useTheme } from '../theme'

interface AlertProps {
	title?: string
	message?: string
	buttons?: AlertButton[]
	options?: AlertOptions
	onClose?: () => void
}

interface AlertOptions {
	cancelable?: boolean
	onDismiss?: () => void
}

interface State {
	title: Nullable<string>
	message: Nullable<string>
	buttons: Nullable<AlertButton[]>
	options: Nullable<AlertOptions>
}

interface AlertButton {
	text: string
	onPress?: () => void
	style?: ButtonType
}

const initialState: State = {
	title: null,
	message: null,
	buttons: null,
	options: null,
}

export default class Alert extends React.Component<{}, State>{
	private static AlertInstance
	constructor(props: {}) {
		super(props)
		this.state = {
			...initialState
		}
		Alert.AlertInstance = this
	}

	render() {
		const {
			title,
			message,
			buttons,
			options,
		} = this.state;

		return (
			<_Alert
				title={title}
				message={message}
				buttons={buttons}
				options={options}
				onClose={this.onClose}
			/>
		)
	}

	static alert(title?: string, message?: string, buttons?: AlertButton[], options?: AlertOptions) {
		Alert.AlertInstance._alert(title, message, buttons, options)
	}

	_alert(title: string, message: string, buttons: AlertButton[], options: AlertOptions) {
		this.setState({ title, message, buttons, options })
	}

	onClose = () => {
		const { options } = this.state;
		const { onDismiss = () => null } = (options || {});
		this.setState(initialState)
		onDismiss()
	}
}

function _Alert({
	title,
	message,
	buttons,
	options,
	onClose,
}: AlertProps) {

	const { colors } = useTheme();

	const visible = !!(title || message)
	const { cancelable = true } = (options || {})
	return (
		<Modal
			visible={visible}
			transparent
		>
			<TouchableOpacity
				activeOpacity={1}
				style={[styles.wrapper, { backgroundColor: colors.alert.wrapper }]}
				onPress={cancelable ? onClose : undefined}
			>
				<View style={[styles.container, { backgroundColor: colors.alert.background }]}>
					{!!title && (
						<View style={[styles.titleContainer, { borderColor: colors.border.default }]}>
							<Text style={[styles.title, { color: colors.text.title }]}>{title}</Text>
						</View>
					)}
					{!!message && <Text style={[styles.message, { color: colors.text.default }]}>{message}</Text>}
					{!!buttons?.length && (
						<View style={styles.buttonsContainer}>
							{buttons.map((button, index) => (
								<Button
									key={index.toString()}
									style={styles.button}
									text={button.text}
									type={button.style}
									onPress={() => {
										onClose()
										!!button.onPress && button.onPress()
									}} />
							))}
						</View>
					)}
				</View>
			</TouchableOpacity>
		</Modal>
	)
}

const styles = StyleSheet.create({
	wrapper: {
		flex: 1,
		justifyContent: 'center',
		padding: 20,
	},
	container: {
		paddingTop: 20,
		paddingBottom: 10,
		borderRadius: 20,
		alignSelf: Platform.OS !== 'web' ? 'auto' : 'center',
		minWidth: Platform.OS !== 'web' ? 0 : 375,
	},
	titleContainer: {
		alignItems: 'center',
		marginHorizontal: 20,
		marginBottom: 10,
	},
	title: {
		fontFamily: font(),
		fontSize: 20,
		lineHeight: 24,
		fontWeight: '600',
		textAlign: 'center',
	},
	message: {
		fontFamily: font(),
		fontSize: 16,
		lineHeight: 20,
		marginHorizontal: 20,
		textAlign: 'center',
		marginBottom: 10,
	},
	buttonsContainer: {
		marginVertical: 10,
		flexDirection: 'row',
		paddingLeft: 20,
		paddingRight: 10,
		justifyContent: 'center',
	},
	button: {
		marginRight: 10,
		minWidth: 100,
		maxWidth: 200,
		flex: 1
	},
})
