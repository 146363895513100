import React, { ReactElement, useEffect } from 'react'
import {
	View,
	ViewStyle,
	SafeAreaView,
	ActivityIndicator,
	Image,
	ImageStyle,
	TouchableOpacity,
	Text,
} from 'react-native'
import { Link, useLinkTo, useNavigation, useRoute } from '@react-navigation/native'
import { useSelector } from 'react-redux'

// import Header from './Header'
import HeaderWithMenu from './HeaderWithMenu'
import { AppState } from '../../store'
import { metrics, useTheme, font } from '../../theme'
import { logo_mini } from '../../images'
import { Icon, LinkButton } from '..'
import { t } from '../../localization'
import config from '../../config'

interface ScreenProps {
	children?: ReactElement<any, any> | ReactElement<any, any>[]
	hideHeader?: boolean
	title?: string
	hideGoBack?: boolean
	onGoBackPress?: () => void
	borderless?: boolean
	onSkipPress?: () => void
	fetching?: boolean
}

export default function Screen({
	children,
	hideHeader,
	title,
	onGoBackPress,
	onSkipPress,
	fetching,
}: ScreenProps) {
	const { colors } = useTheme()
	const navigation = useNavigation()

	const token = useSelector((state: AppState) => state.auth?.token || '')
	const registered_at = useSelector((state: AppState) => state.user.profile?.registered_at || null)

	let header = null
	if (!hideHeader) {
		header = token && registered_at
			? <HeaderWithMenu />
			: (
				// <View
				// 	style={[styles.header, {
				// 		backgroundColor: colors.header.background,
				// 		// borderColor: colors.border.default,
				// 		// borderBottomWidth: borderless ? 0 : 1,
				// 	}]}
				// >
				// 	<Header
				// 		title={title}
				// 		hideGoBack={true}
				// 		onGoBackPress={onGoBackPress}
				// 		onSkipPress={onSkipPress}
				// 	/>
				// </View>
				<View style={[styles.headerContainer, { backgroundColor: colors.background.primary }]}>
					<View style={styles.headerWrapper}>
						<Link to={'/auth'}>
							<Image
								style={styles.logoMini}
								source={logo_mini}
								resizeMode={'contain'}
							/>
						</Link>
						<View style={styles.menuWrapper}>
							<LinkButton to={ '/articles' }>
								<View>
									<Icon
										style={styles.leftIcon}
										name={'document'}
										color={colors.icon.default}
									/>
								</View>
								<Text style={[styles.text, { color: colors.text.title }]}>
									{t('articles')}
								</Text>
							</LinkButton>
						</View>
						<View style={{ flexDirection: 'row', alignItems: 'center' }}>
							<TouchableOpacity
								activeOpacity={1}
								hitSlop={{ top: 4, bottom: 4, left: 4, right: 4 }}
								style={[styles.icon, { backgroundColor: colors.background.secondary }]}
								onPress={() => {
									navigation.navigate('AuthStack', { screen: 'AuthScreen' })
								}}
							>
								<Icon name={'user'} />
							</TouchableOpacity>
						</View>
					</View>
				</View>
			)
	}

	return (
		<SafeAreaView style={[styles.container, { backgroundColor: colors.background.default } as ViewStyle]}>
			{!config.isB2B && <ConferenceSession />}
			{header}
			{fetching && (
				<View style={{ zIndex: 1 }}>
					<ActivityIndicator size={'large'} color={colors.fetching} style={styles.fetching.content} />
				</View>
			)}
			<View style={styles.content}>
				{children}
			</View>
		</SafeAreaView>
	)
}

const styles = {
	container: {
		flex: 1,
	} as ViewStyle,
	header: {} as ViewStyle,
	contentContainer: {
		flex: 1,
	},
	content: {
		flex: 1,
		overflow: 'auto',
	},
	title: {
		marginBottom: 24,
		fontFamily: font(),
		fontSize: 24,
		lineHeight: 29,
	},
	fetching: {
		content: {
			position: 'absolute',
			top: metrics.screenHeight * 0.2,
			left: '50%',
			right: '50%',
			zIndex: 1,
			alignSelf: 'center',
		} as ViewStyle,
	},

	headerContainer: {
		zIndex: 1,
	},
	headerWrapper: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingHorizontal: 20,
		height: 60,
	} as ViewStyle,
	logoMini: {
		alignSelf: 'center',
		width: 112,
		height: 15,
		marginRight: 20,
	} as ImageStyle,
	icon: {
		width: 40,
		height: 40,
		borderRadius: 10,
		overflow: 'hidden',
		marginLeft: 9,
		alignItems: 'center',
		justifyContent: 'center',
	} as ViewStyle,

	menuWrapper: {
		flex: 1,
		justifyContent: 'flex-end',
		flexDirection: 'row',
	},
	leftIcon: {
		marginRight: 7,
		opacity: 0.4,
	},
	text: {
		flex: 1,
		fontFamily: font('bold'),
		fontSize: 16,
	},
}

function ConferenceSession() {
	const linkTo = useLinkTo()
	const route = useRoute()
	const conferenceSession = useSelector((state: AppState) => state.conference.session)
	const inRoom = useSelector((state: AppState) => state.conference.inRoom)

	useEffect(() => {
		if (!!conferenceSession && !inRoom && route.name !== 'IncomingCallScreen') {
			linkTo('/incoming-call')
		}
	}, [conferenceSession, inRoom])

	return null
}
