import { put, call, select } from 'redux-saga/effects'
import { ApiResponse } from 'apisauce'
// import OneSignal from 'react-native-onesignal'
import {
	osVersion,
	osName,
	fullBrowserVersion,
	browserName,
	mobileVendor,
	mobileModel,
} from 'react-device-detect'

import {
	Nullable,
	AuthLogoutSuccessResponse,
	AuthSuccessResponse,
} from '../../types'
import { api, ApiSuccessResponse } from '../../api'
import { AppState } from '..'
import {
	authSuccessAction,
	authFailureAction,
	authSetTokenAction,
	authLogoutSuccessAction,
	authLogoutFailureAction,
} from '../redux/auth'
import { userProfileRequestAction } from '../redux/user'
import { appLoadedAction } from '../redux/app'
import config from '../../../config'

const getToken = (state: AppState): Nullable<string> => state.auth.token

export function* authRequestSaga(action: any) {
	const response: ApiResponse<ApiSuccessResponse<AuthSuccessResponse>> = yield call(api.authRequest, action.params)
	if (response.ok) {
		if (!!response.data?.result?.token) {
			yield put(authSetTokenAction(response.data.result))
		} else {
			yield put(authSuccessAction())
		}
	} else {
		yield put(authFailureAction(response.data))
	}
}

export function* authLogoutRequestSaga() {
	const response: ApiResponse<ApiSuccessResponse<AuthLogoutSuccessResponse>> = yield call(api.authLogout)
	if (response.ok) {
		yield put(authLogoutSuccessAction())
	} else {
		yield put(authLogoutFailureAction())
	}
}

export function* authSetTokenSaga(action: any) {
	const token = yield select(getToken)
	if (!token) {
		yield put(appLoadedAction())
		return
	}
	const os = 'web'
	const app_version = !config.isB2B ? config.web_version : config.web_b2b_version
	const build_number = '1'

	const OS = osName || osVersion ? `${osName} ${osVersion}` : `${mobileVendor} ${mobileModel}`
	const model = `${OS}, ${browserName} ${fullBrowserVersion}`

	yield call(api.registerDevice, {
		os,
		model,
		app_version,
		build_number,
	})
	yield put(userProfileRequestAction())
}
