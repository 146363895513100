import React from 'react'
import { Linking, View, ActivityIndicator, StyleSheet } from 'react-native'
import { createStackNavigator } from '@react-navigation/stack'
import {
	LinkingOptions,
	NavigationContainer,
	getStateFromPath,
	getPathFromState,
	PathConfigMap,
} from '@react-navigation/native'
import { useSelector } from 'react-redux'

import {
	AuthScreen,
	DocumentScreen,
	MainScreen,
	CodeConfirmationScreen,
	ProfileScreen,
	AddTrackerScreen,
	AddEmployeeScreen,
	EmployeeScreen,
	NotFoundScreen,
} from './screens'
import { AppState } from './store'
import { Alert } from '../components'
import { ThemesList, useTheme } from '../theme'
import {
	MainNavigatorProps,
	TrackerNavigatorProps,
	EmployeeNavigatorProps,
 } from './types'
import { t } from './localization'
import config from '../config'

const RootNavigator = createStackNavigator()
const MainNavigator = createStackNavigator<MainNavigatorProps>()
const AuthNavigator = createStackNavigator()
const TrackerNavigator = createStackNavigator<TrackerNavigatorProps>()
const EmployeeNavigator = createStackNavigator<EmployeeNavigatorProps>()

const defaultTitleText = t('AIDERA')

const MainStack = () => (
	<MainNavigator.Navigator
		initialRouteName="MainScreen"
		screenOptions={{
			gestureEnabled: true,
			headerShown: false,
			cardStyle: { flex: 1 },
		}}
	>
		<MainNavigator.Screen
			name="MainScreen"
			component={MainScreen}
			listeners={resetParams}
		/>
		<MainNavigator.Screen
			name="ProfileScreen"
			component={ProfileScreen}
			listeners={resetParams}
		/>
		<MainNavigator.Screen
			name="TrackerStack"
			component={TrackerStack}
			listeners={resetParams}
		/>
		<MainNavigator.Screen
			name="EmployeeStack"
			component={EmployeeStack}
			listeners={resetParams}
		/>
	</MainNavigator.Navigator>
)

const AuthStack = () => (
	<AuthNavigator.Navigator
		initialRouteName='AuthScreen'
		screenOptions={{
			gestureEnabled: true,
			headerShown: false,
			cardStyle: { flex: 1 },
		}}
	>
		<AuthNavigator.Screen
			name="AuthScreen"
			component={AuthScreen}
			listeners={resetParams}
		/>
		<AuthNavigator.Screen
			name="CodeConfirmationScreen"
			component={CodeConfirmationScreen}
			listeners={resetParams}
		/>
		<AuthNavigator.Screen
			name="DocumentScreen"
			component={DocumentScreen}
			listeners={resetParams}
		/>
	</AuthNavigator.Navigator>
)

const TrackerStack = () => (
	<TrackerNavigator.Navigator
		// initialRouteName={'TrackerListScreen'}
		screenOptions={{
			gestureEnabled: true,
			headerShown: false,
			cardStyle: { flex: 1 },
		}}
	>
		<TrackerNavigator.Screen
			name="AddTrackerScreen"
			component={AddTrackerScreen}
			listeners={resetParams}
		/>
	</TrackerNavigator.Navigator>
)

const EmployeeStack = () => (
	<EmployeeNavigator.Navigator
		// initialRouteName={'EmployeeListScreen'}
		screenOptions={{
			gestureEnabled: true,
			headerShown: false,
			cardStyle: { flex: 1 },
		}}
	>
		<EmployeeNavigator.Screen
			name="AddEmployeeScreen"
			component={AddEmployeeScreen}
			listeners={resetParams}
		/>
		<EmployeeNavigator.Screen
			name="EmployeeScreen"
			component={EmployeeScreen}
			listeners={resetParams}
		/>
	</EmployeeNavigator.Navigator>
)

export default function AppMainNavigator() {
	const { colors } = useTheme()

	const profile = useSelector((state: AppState) => state.user.profile)
	const { token } = useSelector((state: AppState) => state.auth)
	const { theme, fetching } = useSelector((state: AppState) => state.app)

	if (fetching) {
		return (
			<View style={styles.activityIndicator} >
				<ActivityIndicator color={colors.fetching} size="large" />
			</View>
		)
	}

	return (
		<NavigationContainer
			theme={ThemesList[theme]}
			linking={linking}
			documentTitle={{
				formatter: (options) => {
					return options?.title
						? `${defaultTitleText} | ${options?.title}`
						: defaultTitleText
				}
			}}
		>
			<Alert />
			<RootNavigator.Navigator screenOptions={{
				animationEnabled: false,
				headerShown: false,
				cardStyle: { flex: 1 },
			}}>
				{!!token && !!profile
					? !!profile?.registered_at
						? (<RootNavigator.Screen name="MainStack" component={MainStack} />)
						: (
							<RootNavigator.Screen
								name="ProfileScreen"
								component={ProfileScreen}
							/>
						)
					: <RootNavigator.Screen name="AuthStack" component={AuthStack} />
				}
				<RootNavigator.Screen
					name="NotFoundScreen"
					component={NotFoundScreen}
				/>
			</RootNavigator.Navigator>
		</NavigationContainer >
	)
}

const styles = StyleSheet.create({
	activityIndicator: {
		position: 'absolute',
		flex: 1,
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 1,
	}
})

const getLinkingPathOptions = (): Pick<LinkingOptions, 'getStateFromPath' | 'getPathFromState'> => {
	let currentUrl = ''

	return {
		getStateFromPath: (path: string, config: { initialRouteName?: string | undefined; screens: PathConfigMap<{}> } | undefined) => {
			currentUrl = path

			return getStateFromPath(currentUrl, config)
		},
		getPathFromState: (state: any, config: any) => {
			const path = getPathFromState(state, config)

			return path === '/NotFoundScreen' ? currentUrl : path
		}
	}
}

const linking = {
	prefixes: [config.web_url, 'aidera://'],
	config: {
		screens: {
			MainStack: {
				screens: {
					MainScreen: '',
					ProfileScreen: 'profile',
					TrackerStack: {
						path: 'trackers',
						screens: {
							AddTrackerScreen: {
								path: 'add',
							},
						},
					},
					EmployeeStack: {
						path: 'employees',
						screens: {
							EmployeeScreen: {
								path: ':id',
								parse: {
									id: Number,
								},
							},
							AddEmployeeScreen: {
								path: 'add',
							},
						},
					},
				},
			},
			AuthStack: {
				path: 'auth',
				initialRouteName: 'AuthScreen',
				screens: {
					AuthScreen: '',
					CodeConfirmationScreen: 'code-confirmation',
					DocumentScreen: {
						path: 'doc/:type?/:document?/:title?',
						exact: true,
						stringify: {
							type: () => '',
							document: () => '',
							title: () => '',
						},
					},
				},
			},
			ProfileScreen: 'profile/new',
			NotFoundScreen: '*',
		},
	},
	subscribe(listener: (arg0: string) => any) {
		const onReceiveURL = ({ url }: { url: string }) => listener(url)
		const urlListener = Linking.addEventListener('url', onReceiveURL)
		return () => {
			urlListener.remove()
		}
	},
	...getLinkingPathOptions(),
} as LinkingOptions

const resetParams = ({ navigation }: any) => {
	return {
		blur: () => navigation.setParams({ screen: undefined })
	}
}
