import React from 'react'
import { Image, StyleSheet, Text, View, Platform } from 'react-native'
import { emptyAppointment, emptyNotifications } from '../images'
import { t } from '../localization'
import { font, useTheme } from '../theme'

interface ListEmptyComponentProps {
  type: 'notifications' | 'appointment' | 'trackers'
  offset?: number
}

export default function ({
  type,
  offset = -40,
}: ListEmptyComponentProps) {
  const { colors } = useTheme()
  let image = 0
  let text = 'test'

  switch (type) {
    case 'notifications':
      image = emptyNotifications
      text = Platform.OS !== 'web' ? t('empty_notifications') : t('empty_notifications_web')
      break;
    case 'appointment':
      image = emptyAppointment
      text = Platform.OS !== 'web' ? t('empty_appointment') : t('empty_appointment_web')
      break;
    case 'trackers':
      image = emptyAppointment
      text = t('empty_trackers')
      break;
    default:
      break;
  }


  return (
    <View style={styles.container}>
      <Image
        style={styles.image}
        source={image}
        resizeMode={'contain'}
      />
      <Text style={[styles.text, { color: colors.text.title, marginTop: offset }]}>
        {text}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  image: {
    width: 298,
    height: 320,
  },
  text: {
    fontFamily: font(),
    fontSize: 24,
    lineHeight: 29,
    textAlign: 'center',
  },
})
