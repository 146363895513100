import AuthScreen from './AuthScreen'
import AuthByEmailScreen from './AuthByEmailScreen'
import MainScreen from './MainScreen'
import NotificationsScreen from './NotificationsScreen'
import NotificationScreen from './NotificationScreen'
import CodeConfirmationScreen from './CodeConfirmationScreen'
import CodeConfirmationByEmailScreen from './CodeConfirmationByEmailScreen'
import ProfileScreen from './ProfileScreen'
import FaqScreen from './FaqScreen'
import SupportChatScreen from './SupportChatScreen'
import AppointmentListScreen from './AppointmentListScreen'
import NewAppointmentScreen from './NewAppointmentScreen'
import NewAppointmentGenderScreen from './NewAppointmentGenderScreen'
import NewAppointmentDatetimeScreen from './NewAppointmentDatetimeScreen'
import NewAppointmentConfirmScreen from './NewAppointmentConfirmScreen'
import GuideScreen from './GuideScreen'
import InstructionsScreen from './InstructionsScreen'
import AboutAppScreen from './AboutAppScreen'
import AideraScreen from './AideraScreen'
import DocumentScreen from './DocumentScreen'
import IncomingCallScreen from './IncomingCallScreen'
import PaymentScreen from './PaymentScreen'
// import ConferenceScreen from './ConferenceScreen'
import ConferenceChatScreen from './ConferenceChatScreen'
import ConferenceDoneScreen from './ConferenceDoneScreen'
import NewAppointmentDoctorScreen from './NewAppointmentDoctorScreen'
import ConferenceWebScreen from './ConferenceWebScreen'
import TestListScreen from './TestListScreen'
import TestScreen from './TestScreen'
import ArticleListScreen from './ArticleListScreen'
import ArticleScreen from './ArticleScreen'
import NotFoundScreen from './NotFoundScreen'
import QuestionListScreen from './QuestionListScreen'
import QuestionScreen from './QuestionScreen'
import TrackerListScreen from './TrackerListScreen'
import AddTrackerScreen from './AddTrackerScreen'
import TrackerScreen from './TrackerScreen'

export {
	AuthScreen,
	AuthByEmailScreen,
	MainScreen,
	NotificationsScreen,
	NotificationScreen,
	CodeConfirmationScreen,
	CodeConfirmationByEmailScreen,
	ProfileScreen,
	FaqScreen,
	SupportChatScreen,
	AppointmentListScreen,
	NewAppointmentScreen,
	NewAppointmentGenderScreen,
	NewAppointmentDatetimeScreen,
	NewAppointmentConfirmScreen,
	GuideScreen,
	InstructionsScreen,
	AboutAppScreen,
	AideraScreen,
	DocumentScreen,
	IncomingCallScreen,
	PaymentScreen,
	// ConferenceScreen,
	ConferenceChatScreen,
	ConferenceDoneScreen,
	NewAppointmentDoctorScreen,
	ConferenceWebScreen,
	TestListScreen,
	TestScreen,
	ArticleListScreen,
	ArticleScreen,
	NotFoundScreen,
	QuestionListScreen,
	QuestionScreen,
	TrackerListScreen,
	AddTrackerScreen,
	TrackerScreen,
}
