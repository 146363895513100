import {
	Nullable,
	ApiFailure,
	ApiFailureResponse,
	EmployeeType,
	EmployeesRequest,
	EmployeesSuccessResponse,
	AddEmployeeRequest,
	AddEmployeeSuccessResponse,
	EmployeeDetailType,
	EmployeeRequest,
	EmployeeSuccessResponse,
	AddTrackerToEmployeeRequest,
	AddTrackerToEmployeeSuccessResponse,
	ReplaceEmployeeTrackerRequest,
	ReplaceEmployeeTrackerSuccessResponse,
	UnlinkEmployeeTrackerRequest,
	UnlinkEmployeeTrackerSuccessResponse,
	DeleteEmployeeRequest,
	DeleteEmployeeSuccessResponse,
} from '../../types'
import { AUTH_LOGOUT_SUCCESS } from '../redux/auth'

export interface EmployeeState {
	employees: Array<EmployeeType>
	employees_fetching: boolean
	employees_error: Nullable<ApiFailure>
	employees_counter: number
	add_employee_success: boolean
	add_employee_fetching: boolean
	add_employee_error: Nullable<ApiFailure>
	employee: Nullable<EmployeeDetailType>
	employee_fetching: boolean
	employee_error: Nullable<ApiFailure>
	add_tracker_to_employee_success: boolean
	add_tracker_to_employee_fetching: boolean
	add_tracker_to_employee_error: Nullable<ApiFailure>
	replace_employee_tracker_success: boolean
	replace_employee_tracker_fetching: boolean
	replace_employee_tracker_error: Nullable<ApiFailure>
	unlink_employee_tracker_success: boolean
	unlink_employee_tracker_fetching: boolean
	unlink_employee_tracker_error: Nullable<ApiFailure>
	delete_employee_success: boolean
	delete_employee_fetching: boolean
	delete_employee_error: Nullable<ApiFailure>
}

export const EMPLOYEES_REQUEST = 'EMPLOYEES_REQUEST'
export const EMPLOYEES_SUCCESS = 'EMPLOYEES_SUCCESS'
export const EMPLOYEES_FAILURE = 'EMPLOYEES_FAILURE'
export const ADD_EMPLOYEE = 'ADD_EMPLOYEE'
export const ADD_EMPLOYEE_REQUEST = 'ADD_EMPLOYEE_REQUEST'
export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS'
export const ADD_EMPLOYEE_FAILURE = 'ADD_EMPLOYEE_FAILURE'
export const EMPLOYEE_REQUEST = 'EMPLOYEE_REQUEST'
export const EMPLOYEE_SUCCESS = 'EMPLOYEE_SUCCESS'
export const EMPLOYEE_FAILURE = 'EMPLOYEE_FAILURE'
export const ADD_TRACKER_TO_EMPLOYEE_REQUEST = 'ADD_TRACKER_TO_EMPLOYEE_REQUEST'
export const ADD_TRACKER_TO_EMPLOYEE_SUCCESS = 'ADD_TRACKER_TO_EMPLOYEE_SUCCESS'
export const ADD_TRACKER_TO_EMPLOYEE_FAILURE = 'ADD_TRACKER_TO_EMPLOYEE_FAILURE'
export const REPLACE_EMPLOYEE_TRACKER_REQUEST = 'REPLACE_EMPLOYEE_TRACKER_REQUEST'
export const REPLACE_EMPLOYEE_TRACKER_SUCCESS = 'REPLACE_EMPLOYEE_TRACKER_SUCCESS'
export const REPLACE_EMPLOYEE_TRACKER_FAILURE = 'REPLACE_EMPLOYEE_TRACKER_FAILURE'
export const UNLINK_EMPLOYEE_TRACKER_REQUEST = 'UNLINK_EMPLOYEE_TRACKER_REQUEST'
export const UNLINK_EMPLOYEE_TRACKER_SUCCESS = 'UNLINK_EMPLOYEE_TRACKER_SUCCESS'
export const UNLINK_EMPLOYEE_TRACKER_FAILURE = 'UNLINK_EMPLOYEE_TRACKER_FAILURE'
export const DELETE_EMPLOYEE_REQUEST = 'DELETE_EMPLOYEE_REQUEST'
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS'
export const DELETE_EMPLOYEE_FAILURE = 'DELETE_EMPLOYEE_FAILURE'

export const employeesRequestAction = (params?: EmployeesRequest) =>
	({ type: EMPLOYEES_REQUEST, params })
export const employeesSuccessAction = (payload: EmployeesSuccessResponse) =>
	({ type: EMPLOYEES_SUCCESS, payload })
export const employeesFailureAction = (payload: ApiFailureResponse) =>
	({ type: EMPLOYEES_FAILURE, payload })
export const addEmployeeAction = () =>
	({ type: ADD_EMPLOYEE })
export const addEmployeeRequestAction = (params: AddEmployeeRequest) =>
	({ type: ADD_EMPLOYEE_REQUEST, params })
export const addEmployeeSuccessAction = (payload: AddEmployeeSuccessResponse) =>
	({ type: ADD_EMPLOYEE_SUCCESS, payload })
export const addEmployeeFailureAction = (payload: ApiFailureResponse) =>
	({ type: ADD_EMPLOYEE_FAILURE, payload })
export const employeeRequestAction = (params: EmployeeRequest) =>
	({ type: EMPLOYEE_REQUEST, params })
export const employeeSuccessAction = (payload: EmployeeSuccessResponse) =>
	({ type: EMPLOYEE_SUCCESS, payload })
export const employeeFailureAction = (payload: ApiFailureResponse) =>
	({ type: EMPLOYEE_FAILURE, payload })
export const addTrackerToEmployeeRequestAction = (params: AddTrackerToEmployeeRequest) =>
	({ type: ADD_TRACKER_TO_EMPLOYEE_REQUEST, params })
export const addTrackerToEmployeeSuccessAction = (payload: AddTrackerToEmployeeSuccessResponse) =>
	({ type: ADD_TRACKER_TO_EMPLOYEE_SUCCESS, payload })
export const addTrackerToEmployeeFailureAction = (payload: ApiFailureResponse) =>
	({ type: ADD_TRACKER_TO_EMPLOYEE_FAILURE, payload })
export const replaceEmployeeTrackerRequestAction = (params: ReplaceEmployeeTrackerRequest) =>
	({ type: REPLACE_EMPLOYEE_TRACKER_REQUEST, params })
export const replaceEmployeeTrackerSuccessAction = (payload: ReplaceEmployeeTrackerSuccessResponse) =>
	({ type: REPLACE_EMPLOYEE_TRACKER_SUCCESS, payload })
export const replaceEmployeeTrackerFailureAction = (payload: ApiFailureResponse) =>
	({ type: REPLACE_EMPLOYEE_TRACKER_FAILURE, payload })
export const unlinkEmployeeTrackerRequestAction = (params: UnlinkEmployeeTrackerRequest) =>
	({ type: UNLINK_EMPLOYEE_TRACKER_REQUEST, params })
export const unlinkEmployeeTrackerSuccessAction = (payload: UnlinkEmployeeTrackerSuccessResponse) =>
	({ type: UNLINK_EMPLOYEE_TRACKER_SUCCESS, payload })
export const unlinkEmployeeTrackerFailureAction = (payload: ApiFailureResponse) =>
	({ type: UNLINK_EMPLOYEE_TRACKER_FAILURE, payload })
export const deleteEmployeeRequestAction = (params: DeleteEmployeeRequest) =>
	({ type: DELETE_EMPLOYEE_REQUEST, params })
export const deleteEmployeeSuccessAction = (payload: DeleteEmployeeSuccessResponse) =>
	({ type: DELETE_EMPLOYEE_SUCCESS, payload })
export const deleteEmployeeFailureAction = (payload: ApiFailureResponse) =>
	({ type: DELETE_EMPLOYEE_FAILURE, payload })

const initialState: EmployeeState = {
	employees: [],
	employees_fetching: false,
	employees_error: null,
	employees_counter: 0,
	add_employee_success: false,
	add_employee_fetching: false,
	add_employee_error: null,
	employee: null,
	employee_fetching: false,
	employee_error: null,
	add_tracker_to_employee_success: false,
	add_tracker_to_employee_fetching: false,
	add_tracker_to_employee_error: null,
	replace_employee_tracker_success: false,
	replace_employee_tracker_fetching: false,
	replace_employee_tracker_error: null,
	unlink_employee_tracker_success: false,
	unlink_employee_tracker_fetching: false,
	unlink_employee_tracker_error: null,
	delete_employee_success: false,
	delete_employee_fetching: false,
	delete_employee_error: null,
}

const EmployeeReducer = (state = initialState, action: any): EmployeeState => {
	const { type, payload } = action
	switch (type) {
		case EMPLOYEES_REQUEST: {
			return { ...state, employees_fetching: true, employees_error: null }
		}
		case EMPLOYEES_SUCCESS: {
			const { employees, employees_counter } = payload as EmployeesSuccessResponse
			return {
				...state,
				employees_fetching: false,
				employees: employees,
				employees_counter: employees_counter,
			}
		}
		case EMPLOYEES_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, employees_fetching: false, employees_error: error }
		}
		case ADD_EMPLOYEE: {
			return {
				...state,
				add_employee_success: false,
			}
		}
		case ADD_EMPLOYEE_REQUEST: {
			return {
				...state,
				add_employee_success: false,
				add_employee_fetching: true,
				add_employee_error: null,
			}
		}
		case ADD_EMPLOYEE_SUCCESS: {
			return { ...state, add_employee_success: true, add_employee_fetching: false }
		}
		case ADD_EMPLOYEE_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, add_employee_success: false, add_employee_fetching: false, add_employee_error: error }
		}
		case EMPLOYEE_REQUEST: {
			return {
				...state,
				employee: null,
				employee_fetching: true,
				employee_error: null,
			}
		}
		case EMPLOYEE_SUCCESS: {
			return { ...state, employee: payload, employee_fetching: false }
		}
		case EMPLOYEE_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, employee: null, employee_fetching: false, employee_error: error }
		}
		case ADD_TRACKER_TO_EMPLOYEE_REQUEST: {
			return {
				...state,
				add_tracker_to_employee_success: false,
				add_tracker_to_employee_fetching: true,
				add_tracker_to_employee_error: null,
			}
		}
		case ADD_TRACKER_TO_EMPLOYEE_SUCCESS: {
			return { ...state, add_tracker_to_employee_success: true, add_tracker_to_employee_fetching: false }
		}
		case ADD_TRACKER_TO_EMPLOYEE_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, add_tracker_to_employee_fetching: false, add_tracker_to_employee_error: error }
		}
		case REPLACE_EMPLOYEE_TRACKER_REQUEST: {
			return {
				...state,
				replace_employee_tracker_success: false,
				replace_employee_tracker_fetching: true,
				replace_employee_tracker_error: null,
			}
		}
		case REPLACE_EMPLOYEE_TRACKER_SUCCESS: {
			return { ...state, replace_employee_tracker_success: true, replace_employee_tracker_fetching: false }
		}
		case REPLACE_EMPLOYEE_TRACKER_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, replace_employee_tracker_fetching: false, replace_employee_tracker_error: error }
		}
		case UNLINK_EMPLOYEE_TRACKER_REQUEST: {
			return {
				...state,
				unlink_employee_tracker_success: false,
				unlink_employee_tracker_fetching: true,
				unlink_employee_tracker_error: null,
			}
		}
		case UNLINK_EMPLOYEE_TRACKER_SUCCESS: {
			return { ...state, unlink_employee_tracker_success: true, unlink_employee_tracker_fetching: false }
		}
		case UNLINK_EMPLOYEE_TRACKER_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, unlink_employee_tracker_fetching: false, unlink_employee_tracker_error: error }
		}
		case DELETE_EMPLOYEE_REQUEST: {
			return {
				...state,
				delete_employee_success: false,
				delete_employee_fetching: true,
				delete_employee_error: null,
			}
		}
		case DELETE_EMPLOYEE_SUCCESS: {
			return { ...state, delete_employee_success: true, delete_employee_fetching: false }
		}
		case DELETE_EMPLOYEE_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, delete_employee_fetching: false, delete_employee_error: error }
		}
		case AUTH_LOGOUT_SUCCESS: {
			return { ...initialState }
		}
		default:
			return state
	}
}

export default EmployeeReducer
