import React, { useEffect } from 'react'
import { ScrollView, StyleSheet, View, ViewStyle, Text } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'

import { Markdown, Screen, Icon, Logo } from '../../components'
import { useTheme, useWindowMetrics, font } from '../../theme'
import { t } from '../localization'
import { AppState } from '../store'

export default function DocumentScreen({ route }: any) {
	const { colors } = useTheme()
	const { document } = route.params || {}

	const { token } = useSelector((state: AppState) => state.auth)
	const navigation = useNavigation()

	useEffect(() => {
		if (!document) {
			navigation.navigate('AuthStack', { screen: 'AuthScreen' })
		} else {
			navigation.setOptions({ title: document.title })
		}
	}, [])

	if (!document) {
		return null
	}

	const { width: screenWidth } = useWindowMetrics()
	const isScreenWidthLess635 = screenWidth < 635

	return (
		<Screen hideHeader={!token}>
			<>
				<View style={[styles.back, { top: !token ? 40 : 80 }]}>
					<View style={styles.backLink} onClick={() => navigation.goBack()}>
						<Icon
							size={16}
							style={styles.backIcon}
							name={'arrow_back'}
							color={colors.icon.default}
						/>
						<Text style={[styles.backText, { color: colors.text.title }]}>{t('back')}</Text>
					</View>
				</View>
				<ScrollView
					bounces={false}
					contentContainerStyle={[styles.contentContainer, {
						marginTop: token ? 0 : 30,
					}]}
				>
					{ !token && (<Logo style={styles.logo} onClick={() => navigation.goBack()} />)}
					<View style={[styles.contentWrapper, {
						width: !isScreenWidthLess635 ? '47.22%' : '100%',
						minWidth: !isScreenWidthLess635 ? 635 : 'auto',
					}]}>
						<Text style={[styles.title, { color: colors.text.title }]}>{document.title}</Text>
						<Markdown
							style={[styles.content, { backgroundColor: colors.background.primary }]}
							text={document.content}
						/>
					</View>
				</ScrollView>
			</>
		</Screen>
	)
}

const styles = StyleSheet.create({
	contentContainer: {
		flexGrow: 1,
		justifyContent: 'space-between',
		alignItems: 'center',
	} as ViewStyle,
	content: {
		borderRadius: 20,
		padding: 16,
	} as ViewStyle,
	contentWrapper: {
		paddingHorizontal: 20,
		marginBottom: 40,
	},
	logo: {
		marginTop: 10,
		marginBottom: 20,
	},
	title: {
		marginTop: 40,
		marginBottom: 24,
		fontFamily: font(),
		fontSize: 24,
		lineHeight: 29,
		textAlign: 'left',
	},
	back: {
		position: 'fixed',
		left: 25,
		zIndex: 1,
	},
	backLink: {
		flexDirection: 'row',
		alignItems: 'center',
		cursor: 'pointer',
	},
	backText: {
		fontFamily: font(),
		fontSize: 20,
	},
	backIcon: {
		marginRight: 15,
	},
})
