import React, { useEffect, useRef, useState } from 'react'
import {
	ActivityIndicator,
	View,
	Text,
	FlatList,
	StyleSheet,
	TouchableWithoutFeedback,
	Image,
	Dimensions,
} from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import moment from 'moment'
import 'moment/locale/ru'

moment().locale('ru')

import { Button, DoctorModal, Icon, Screen } from '../components'
import { t } from '../localization'
import { useTheme, ThemeColors, font } from '../theme'
import { Answer, DoctorType, MainNavigatorProps, Nullable, QuestionType } from '../types'
import { questionByIdRequestAction } from '../store/redux/questions'
import { appointmentGenderRequestAction } from '../store/redux/appointments'
import { AppState } from '../store'

type ScreenProps = StackScreenProps<MainNavigatorProps, 'QuestionStack'>

interface Props extends ScreenProps {
	colors: ThemeColors
}

export default function QuestionScreen(props: Props) {
	const { colors } = useTheme()
	const dispatch = useDispatch()
	const navigation = useNavigation()

	const questionId = props.route.params.id

	const [screenData, setScreenData] = useState(Dimensions.get('window'))
	const questionById = useSelector((state: AppState) => state.question.questionById)
	const fetching = useSelector((state: AppState) => state.question.questionByIdFetching)
	const error = useSelector((state: AppState) => state.question.questionByIdError)
	const genderList = useSelector((state: AppState) => state.appointment.doctor_gender_list)

	const [question, setQuestion] = useState<Nullable<QuestionType>>(null)
	const [showModal, setShowModal] = useState(false)
	const [selectedDoctor, setSelectedDoctor] = useState<Nullable<DoctorType>>(null)
	const [isAddNewAppointment, setIsAddNewAppointment] = useState(false)

	const mounted = useRef<boolean>()

	useEffect(() => {
		const onChange = (result) => {
			setScreenData(result.window)
		}

		const subscription = Dimensions.addEventListener('change', onChange)

		return () => subscription?.remove()
	})

	useEffect(() => {
		if (mounted.current && error && +error.code === 404) {
			navigation.navigate('NotFoundScreen')
		}
	}, [error])

	useEffect(() => {
		if (!mounted.current) {
			mounted.current = true;
		}
		dispatch(questionByIdRequestAction({ id: questionId }))
	}, [])

	useEffect(() => {
		if (questionById !== null && questionById.id === +questionId) {
			setQuestion(questionById)
		}
	}, [questionById])

  useEffect(() => {
    if (isAddNewAppointment && selectedDoctor && genderList) {
      navigation.navigate('AppointmentStack', {
        screen: 'NewAppointmentScreen',
        params: { serviceId: selectedDoctor.service_id },
      })
    }
  }, [genderList, isAddNewAppointment])

	if (fetching || !question) {
		return (
			<Screen title={t('questions')} borderless>
				<View style={styles.container}>
					<ActivityIndicator color={colors.fetching} size="large" />
				</View>
			</Screen>
		)
	}

	const { answers, client: user, content } = question
	let gender
	switch (user.gender) {
		case 0:
			gender = 'Женщина'
			break;
		case 1:
			gender = 'Мужчина'
			break;
		default:
			gender = 'Мужчина'
	}

	const onRefresh = () => {
		dispatch(questionByIdRequestAction({ id: questionId }))
	}

	const renderAnswer = ({ item: answer }: { item: Answer }) => {
		const { doctor, content } = answer
		return (
			<>
				<View style={[styles.cardWrapper, { backgroundColor: colors.background.primary }]}>
					<View style={styles.answerHeader}>
						<TouchableWithoutFeedback onPress={() => {
							setSelectedDoctor(doctor)
							setShowModal(true)
						}}>
							<View style={styles.row}>
								<View style={styles.answerImageContainer}>
									<Image
										source={{ uri: doctor.photo.thumbnail + '?w=40h=40' }}
										style={styles.avatar}
										resizeMode={'cover'}
									/>
								</View>
								<View style={styles.column}>
									<Text style={[
										styles.doctor_name,
										{ color: colors.text.title },
									]}>{`${doctor.lastname} ${doctor.firstname}`}</Text>
									<Text style={[styles.headerText, {
										color: colors.text.primary,
									}]}>{doctor.is_platform ? t('platform_psychologist') : t('psychologist')}</Text>
								</View>
							</View>
						</TouchableWithoutFeedback>
					</View>
					<Text>{content}</Text>
					{doctor.service_id && doctor.is_platform && (
						<Button
							style={styles.newAppointmentBtn}
							text={t('new_appointment')}
							onPress={() => {
								dispatch(appointmentGenderRequestAction({ service_id: doctor.service_id }))
								setIsAddNewAppointment(true)
							}}
							disabled={!doctor.is_platform}
						/>
					)}
				</View>
			</>
		)
	}

	const renderAnswers = () => {
		return (
			<FlatList
				data={answers}
				renderItem={renderAnswer}
				keyExtractor={item => item.id.toString()}
				onEndReachedThreshold={0.5}
				onRefresh={onRefresh}
				refreshing={fetching}
				contentContainerStyle={{ flex: 1 }}
			/>
		)
	}

	const renderTitle = () => {
		return (
			<View style={styles.title}>
				<Text style={[styles.titleText, { color: colors.text.title }]}>{t('questions')}</Text>
			</View>
		)
	}

	const isSmallScreen = screenData.width < 655

	return (
		<Screen>
			<>
				{ navigation.canGoBack() && (
				<View style={[styles.back, { top: 80 }]}>
					<View style={styles.backLink} onClick={() => navigation.goBack()}>
						<Icon
							size={16}
							style={styles.backIcon}
							name={'arrow_back'}
							color={colors.icon.default}
						/>
						<Text style={[styles.backText, { color: colors.text.title }]}>{t('back')}</Text>
					</View>
				</View>
				)}
				<View style={[
					styles.container, {
						width: !isSmallScreen ? '47.22%' : '100%',
						minWidth: !isSmallScreen ? 635 : 'auto',
					},
				]}>
					{renderTitle()}
					<View style={[styles.cardWrapper, { backgroundColor: colors.background.primary }]}>
						<View style={styles.mb12}>
							<Text style={styles.textBold}>{user.fullname || gender}</Text>
						</View>
						<Text style={[styles.text, { color: colors.text.default }]}>{content}</Text>
					</View>
					{ renderAnswers() }
					{ selectedDoctor && (
						<DoctorModal
							isVisible={showModal}
							onClose={() => setShowModal(false)}
							doctor={selectedDoctor}
						/>
					)}
				</View>
			</>
		</Screen>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'stretch',
		alignSelf: 'center',
		paddingHorizontal: 20,
		marginTop: 25,
	},
	cardWrapper: {
		marginBottom: 10,
		borderRadius: 20,
		padding: 16,
	},
	mb12: {
		marginBottom: 12,
	},
	text: {
		fontFamily: font(),
	},
	textBold: {
		fontFamily: font('bold'),
	},
	answerHeader: {
		flexDirection: 'row',
		marginBottom: 12,
	},
	answerImageContainer: {
		width: 40,
		height: 40,
		borderRadius: 20,
		overflow: 'hidden',
		marginRight: 10,
	},
	avatar: {
		width: '100%',
		height: '100%',
	},
	doctor_name: {
		fontFamily: font('bold'),
		marginBottom: 4,
	},
	row: {
		flexDirection: 'row',
	},
	column: {
		flexDirection: 'column',
	},
	headerText: {
		fontFamily: font(),
		fontSize: 12,
	},
	newAppointmentBtn: {
		flex: 1,
		marginTop: 9,
	},
	back: {
		position: 'fixed',
		left: 25,
		zIndex: 1,
	},
	backLink: {
		flexDirection: 'row',
		alignItems: 'center',
		cursor: 'pointer',
	},
	backText: {
		fontFamily: font(),
		fontSize: 20,
	},
	backIcon: {
		marginRight: 15,
	},
	title: {
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: 24,
	},
	titleText: {
		fontSize: 24,
		fontFamily: font('bold'),
	},
})
