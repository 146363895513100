import {
	ApiFailure,
	ApiFailureResponse,
	Nullable,
	TestType,
	TestDetailType,
	TestCategory,
	TestsRequest,
	TestsSuccessResponse,
	TestsCategoriesRequest,
	TestsCategoriesSuccessResponse,
	TestRequest,
	TestSuccessResponse,
	TestResult,
	TestResultRequest,
	TestResultSuccessResponse,
} from '../../types'
import { AUTH_LOGOUT_SUCCESS } from '../redux/auth'

export interface TestState {
	tests: Nullable<TestType[]>
	testsFetching: boolean
	testsError: Nullable<ApiFailure>
	testsCategories: Nullable<TestCategory[]>
	testsCategoriesFetching: boolean
	testsCategoriesError: Nullable<ApiFailure>
	test: Nullable<TestDetailType>
	testFetching: boolean
	testError: Nullable<ApiFailure>
	testResult: Nullable<TestResult>
	testResultFetching: boolean
	testResultError: Nullable<ApiFailure>
}

export const TESTS_REQUEST = 'TESTS_REQUEST'
export const TESTS_SUCCESS = 'TESTS_SUCCESS'
export const TESTS_FAILURE = 'TESTS_FAILURE'
export const TESTS_CATEGORIES_REQUEST = 'TESTS_CATEGORIES_REQUEST'
export const TESTS_CATEGORIES_SUCCESS = 'TESTS_CATEGORIES_SUCCESS'
export const TESTS_CATEGORIES_FAILURE = 'TESTS_CATEGORIES_FAILURE'
export const TEST_REQUEST = 'TEST_REQUEST'
export const TEST_SUCCESS = 'TEST_SUCCESS'
export const TEST_FAILURE = 'TEST_FAILURE'
export const TEST_RESULT_REQUEST = 'TEST_RESULT_REQUEST'
export const TEST_RESULT_SUCCESS = 'TEST_RESULT_SUCCESS'
export const TEST_RESULT_FAILURE = 'TEST_RESULT_FAILURE'
export const TEST_RESULT_CLEAR = 'TEST_RESULT_CLEAR'

export const testsRequestAction = (params?: TestsRequest) => ({ type: TESTS_REQUEST, params })
export const testsSuccessAction = (payload) => ({ type: TESTS_SUCCESS, payload })
export const testsFailureAction = (payload) => ({ type: TESTS_FAILURE, payload })

export const testsCategoriesRequestAction = (params?: TestsCategoriesRequest) => ({ type: TESTS_CATEGORIES_REQUEST, params })
export const testsCategoriesSuccessAction = (payload) => ({ type: TESTS_CATEGORIES_SUCCESS, payload })
export const testsCategoriesFailureAction = (payload) => ({ type: TESTS_CATEGORIES_FAILURE, payload })

export const testRequestAction = (params?: TestRequest) => ({ type: TEST_REQUEST, params })
export const testSuccessAction = (payload) => ({ type: TEST_SUCCESS, payload })
export const testFailureAction = (payload) => ({ type: TEST_FAILURE, payload })

export const testResultRequestAction = (params?: TestResultRequest) => ({ type: TEST_RESULT_REQUEST, params })
export const testResultSuccessAction = (payload) => ({ type: TEST_RESULT_SUCCESS, payload })
export const testResultFailureAction = (payload) => ({ type: TEST_RESULT_FAILURE, payload })
export const testResultClearAction = () => ({ type: TEST_RESULT_CLEAR })

const initialState: TestState = {
	tests: [],
	testsFetching: false,
	testsError: null,
	testsCategories: [],
	testsCategoriesFetching: false,
	testsCategoriesError: null,
	test: null,
	testFetching: false,
	testError: null,
	testResult: null,
	testResultFetching: false,
	testResultError: null,
}

const TestReducer = (state = initialState, action: any): TestState => {
	const { type, payload } = action
	switch (type) {
		case TESTS_REQUEST: {
			return { ...state, testsError: null, testsFetching: true }
		}
		case TESTS_SUCCESS: {
			const { tests } = payload as TestsSuccessResponse
			return { ...state, testsFetching: false, tests }
		}
		case TESTS_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, testsFetching: false, testsError: error }
		}
		case TESTS_CATEGORIES_REQUEST: {
			return { ...state, testsCategoriesError: null, testsCategoriesFetching: true }
		}
		case TESTS_CATEGORIES_SUCCESS: {
			const { categories } = payload as TestsCategoriesSuccessResponse
			return { ...state, testsCategoriesFetching: false, testsCategories: categories }
		}
		case TESTS_CATEGORIES_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, testsCategoriesFetching: false, testsCategoriesError: error }
		}
		case TEST_REQUEST:
			return { ...state, testError: null, testFetching: true }
		case TEST_SUCCESS:
			return { ...state, testFetching: false, test: payload }
		case TEST_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, testFetching: false, testError: error }
		}
		case TEST_RESULT_REQUEST:
			return { ...state, testResultError: null, testResultFetching: true }
		case TEST_RESULT_SUCCESS:
			return { ...state, testResultFetching: false, testResult: payload }
		case TEST_RESULT_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, testResultFetching: false, testResultError: error }
		}
		case TEST_RESULT_CLEAR: {
			return { ...state, testResult: null }
		}
		case AUTH_LOGOUT_SUCCESS: {
			return { ...initialState }
		}
		default:
			return state
	}
}

export default TestReducer
