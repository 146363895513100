import React, { createRef, RefObject, useState, useEffect } from 'react'
import {
  ScrollView,
  StyleSheet,
  Text,
  ViewStyle,
  View,
  TouchableOpacity,
  ActivityIndicator,
  Image,
  Dimensions,
} from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { connect, useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import isEqual from 'lodash/isEqual'
import partition from 'lodash/partition'
import { useIsFocused, useNavigation } from '@react-navigation/native'

import { Screen, Alert, Modal, Button, TabBar, ClientForm, Logo } from '../components'
import { t } from '../localization'
import { font, useTheme, ThemeColors } from '../theme'
import { GenderType, MainNavigatorProps, Nullable, TestType } from '../types'
import { AppState } from '../store/redux'
import { userProfileDeleteRequestAction, userProfileUpdateRequestAction } from '../store/redux/user'
import { authLogoutRequestAction } from '../store/redux/auth'
import { testsRequestAction, testRequestAction } from '../store/redux/tests'
import { checkClientData, getUtcOffset } from '../service'
import { usePrevious } from '../hooks/usePrevious'
import { achieveLocked, defaultAchieve } from '../images'

type ScreenProps = DispatchProps & StateProps & StackScreenProps<MainNavigatorProps, 'ProfileScreen'>

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

interface Props extends ScreenProps {
	colors: ThemeColors,
	isSmallScreen: boolean,
}

interface State {
	firstname: Nullable<string>
	lastname: Nullable<string>
	middlename: Nullable<string>
	birthdate: Nullable<string>
	phone: Nullable<string>
	email: Nullable<string>
	gender: Nullable<GenderType>
	registered_at: Nullable<string>
	activeTab: 0 | 1
}

function ProfileScreenWrapper(props: Props) {
  const { colors } = useTheme()
  const [screenData, setScreenData] = useState(Dimensions.get('window'))

  useEffect(() => {
    const onChange = (result) => {
      setScreenData(result.window)
    }

    const subscription = Dimensions.addEventListener('change', onChange)

		return () => subscription?.remove()
  })

  return <ProfileScreen colors={colors} isSmallScreen={screenData.width < 635} {...props} />
}

class ProfileScreen extends React.Component<Props, State>{
  dimensionsSubscription: any
  scrollViewRef: RefObject<ScrollView>

  constructor(props: Props) {
    super(props)

    this.state = {
      lastname: null,
      firstname: null,
      middlename: null,
      birthdate: null,
      gender: null,
      phone: null,
      email: null,
      registered_at: null,
      activeTab: 0,
    }

    this.scrollViewRef = createRef<ScrollView>()
  }

  componentDidMount() {
    const { profile, getTests } = this.props
    this.setState({
      ...profile,
      birthdate: profile?.birthdate || '1999-01-01',
      phone: profile?.phone || null,
      email: profile?.email || null,
    })

    if (profile?.registered_at) {
      getTests()
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { profile, fetching, navigation, error } = this.props
    if (JSON.stringify(prevProps.profile || '') !== JSON.stringify(profile || '')) {
      this.setState({ ...profile })
    }
    if (prevProps.fetching && !fetching && !error) {
      Alert.alert(null, t('profile_success'),
        [{ text: t('back') }],
        {onDismiss: () => {
          if (navigation.canGoBack()) {
            navigation.goBack()
          } else {
            navigation.navigate('MainStack', { screen: 'MainScreen' })
          }
        }}
      )
    }
  }

  onTabChange = (id: 0 | 1) => {
    this.setState({
      activeTab: id,
    })

    this.scrollViewRef.current?.scrollTo({ y: 0, animated: false })
  }

  renderTitle = () => {
    const { colors } = this.props
    const { registered_at } = this.state

    if (!registered_at) {
      return (<>
        <Text style={[styles.content.title, { color: colors.text.title }]}>{t('familiarity')}</Text>
        <Title />
      </>)
    }

    return (
      <View style={styles.title}>
        <Text style={[styles.titleText, { color: colors.text.title }]}>{t('profile')}</Text>
      </View>
    )
  }

  render() {
    const { isSmallScreen, profile } = this.props;
    const {
      lastname,
      firstname,
      middlename,
      birthdate,
      gender,
      phone,
      email,
      registered_at,
      activeTab,
    } = this.state
    const birthdatePlaceholder = moment('1999-01-01').format('YYYY-MM-DD')
    const _birthdate = !registered_at && !birthdate ? birthdatePlaceholder : birthdate

    return (
      <Screen hideHeader={!registered_at}>
        {!registered_at && <Logo style={styles.logo} clickable={false} />}
        <View style={[
          styles.content.wrapper,
          {
            width: !isSmallScreen ? '55%' : '100%',
            minWidth: !isSmallScreen ? 635 : 'auto',
          },
        ]}>
          <View style={styles.content.form}>
            { this.renderTitle() }
            { !!registered_at && (
              <TabBar
                containerStyle={styles.tabBar}
                active={activeTab}
                onChange={this.onTabChange}
                leftTabText={t('profile_about')}
                rightTabText={t('profile_info')}
              />
            )}
            <ScrollView
              ref={this.scrollViewRef}
              bounces={false}
              contentContainerStyle={styles.contentContainer}
            >
              { activeTab === 1 || !registered_at ? (
                <ClientForm
                  lastname={lastname}
                  firstname={firstname}
                  middlename={middlename}
                  birthdate={_birthdate}
                  gender={gender}
                  phone={phone}
                  email={email}
                  onChange={this.onChange}
                  onSavePress={this.onSavePress}
                  type={!registered_at ? 'registration' : 'profile'}
                  phoneFilled={!!profile?.phone}
                  emailFilled={!!profile?.email}
                />
              ) : (
                <TestList />
              )}
              {!!registered_at && activeTab === 1 && (
                <>
                  <Logout />
                  <Delete />
                </>
              )}
            </ScrollView>
          </View>
        </View>
      </Screen>
    )
  }

  onChange = (params: { [key: string]: string | boolean }) => {
    // @ts-ignore
    this.setState({ ...params })
  }

  onSavePress = () => {
    const { profileUpdate, profile } = this.props
    const {
      lastname,
      firstname,
      middlename,
      birthdate,
      gender,
      email,
      phone,
      registered_at
    } = this.state;

    const isValid = checkClientData({
      lastname,
      firstname,
      birthdate,
      gender,
      email,
      phone,
    },
      true,
      !registered_at && !profile?.email,
      !registered_at && !profile?.phone,
    )
    if (isValid) {
      profileUpdate({
        registered_at,
        firstname,
        lastname,
        middlename,
        birthdate,
        gender,
        email: email || undefined,
        phone: phone || '',
        tz_offset: getUtcOffset(),
      })
    }
  }

}

const mapStateToProps = (state: AppState) => ({
  profile: state.user.profile,
  fetching: state.user.fetching,
  error: state.user.error,
})

const mapDispatchToProps = {
  profileUpdate: userProfileUpdateRequestAction,
  getTests: testsRequestAction
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileScreenWrapper)

const styles = {
  contentContainer: {
    flexGrow: 1,
  } as ViewStyle,
  content: {
    wrapper: {
      flexGrow: 1,
      marginTop: 26,
      marginBottom: 40,
      paddingHorizontal: 20,
      paddingBottom: 17,
      alignSelf: 'center',
    },
    form: {
      textAlign: 'center',
    },
    title: {
      fontSize: 36,
      marginBottom: 20,
    },
  },
  logo: {
    marginTop: 40,
    marginBottom: 34,
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 30,
  },
  titleText: {
    fontSize: 24,
    fontFamily: font('bold'),
  },
  titleBackIcon: {
    marginRight: 15,
  },
  tabBar: {
    marginBottom: 30,
  },
}

function Title() {
  const { colors } = useTheme()
  return (
    <Text style={[titleStyles.text, { color: colors.text.primary }]}>
      {t('fill_profile')}
    </Text>
  )
}

const titleStyles = StyleSheet.create({
  text: {
    fontFamily: font(),
    fontSize: 16,
    lineHeight: 19,
    marginBottom: 24,
  },
})

function Logout() {

  const { colors } = useTheme()
  const dispatch = useDispatch()

  return (
    <Text
      style={[logoutStyles.text, { color: colors.text.title }]}
      onPress={() => dispatch(authLogoutRequestAction())}
    >
      {t('logout')}
    </Text>
  )
}

const logoutStyles = StyleSheet.create({
  text: {
    marginTop: 20,
    textAlign: 'center',
    fontFamily: font('bold'),
    fontSize: 14,
    lineHeight: 17,
  },
})

function Delete() {
  const { colors } = useTheme();
  const dispatch = useDispatch();
  const [isVisible, setVisible] = useState(false)

  return (
    <>
      <Text
        style={[deleteStyle.text, { color: colors.text.subtitle }]}
        onPress={() => setVisible(true)}
      >
        {t('profile_delete')}
      </Text>
      <DeleteProfileModal
        isVisible={isVisible}
        onConfirm={() => {
          dispatch(userProfileDeleteRequestAction())
        }}
        onCancel={() => setVisible(false)}
      />
    </>
  )
}

const deleteStyle = StyleSheet.create({
  text: {
    marginTop: 16,
    textAlign: 'center',
    fontFamily: font('bold'),
    fontSize: 14,
    lineHeight: 17,
  },
})

interface CancelAppointmentModalProps {
  isVisible: boolean
  onConfirm: () => void
  onCancel: () => void
}

function DeleteProfileModal({
  isVisible,
  onConfirm,
  onCancel,
}: CancelAppointmentModalProps) {

  const { colors } = useTheme()

  return (
    <Modal
      isVisible={isVisible}
      onClose={onCancel}
      position='center'
    >
      <View style={deleteModal.container}>
        <Text style={[deleteModal.title, { color: colors.text.title }]}>
          {t('profile_delete')}
        </Text>
        <Text style={[deleteModal.text, { color: colors.text.default }]}>
          {t('confim_profile_delete')}
        </Text>
      </View>
      <View style={deleteModal.buttonContainer}>
        <Button
          text={t('no')}
          style={deleteModal.button}
          type={'secondary'}
          onPress={() => onCancel()}
        />
        <Button
          text={t('yes')}
          style={deleteModal.button}
          onPress={() => onConfirm()}
        />
      </View>
    </Modal>
  )
}

const deleteModal = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
  title: {
    fontFamily: font('bold'),
    fontSize: 20,
    lineHeight: 24,
  },
  text: {
    marginTop: 14,
    fontFamily: font(),
    fontSize: 16,
    lineHeight: 19,
  },
  buttonContainer: {
    marginTop: 24,
    paddingHorizontal: 11,
    flexDirection: 'row',
  },
  button: {
    flex: 1,
    marginHorizontal: 5,
  },
})

const ModalTestTextBlock = ({ label, text }: { label: string, text: string | undefined }) => {
  const { colors } = useTheme()
  return (
    <>
      <Text style={[
        testsStyle.modalTestLabel,
        { color: colors.text.default }
      ]}>{label}</Text>
      <Text style={[
        testsStyle.modalTestText,
        { color: colors.text.default }
      ]}>{text}</Text>
    </>
  )
}

function TestList() {
  const { colors } = useTheme()

  const dispatch = useDispatch()
  const navigation = useNavigation()

  const allTests = useSelector((state: AppState) => state.test.tests)
  const allTestsFetching = useSelector((state: AppState) => state.test.testsFetching)
  const test = useSelector((state: AppState) => state.test.test)

  const [isProfileTestModalVisible, setIsProfileTestModalVisible] = useState(false)
  const [isProfileRepeatModalVisible, setIsProfileRepeatModalVisible] = useState(false)
  const [numColumns, setNumColumns] = useState(0)
  const [tests, setTests] = useState<TestType[]>([])
  const [additionalTests, setAdditionalTests] = useState<TestType[]>([])
  const [clickedTestId, setClickedTestId] = useState<Nullable<number>>(null)

  const previousTest = usePrevious(test)

  const isFocused = useIsFocused()

  useEffect(() => {
    if (!!allTests && numColumns > 0) {
      const groupedTests = partition(allTests, item => item.show_achievement)

      setTests(fillTestArray(groupedTests[0]))
      const additionalFinishedTests = groupedTests[1].filter(item => item.has_result)
      setAdditionalTests(fillTestArray(additionalFinishedTests))
    }
  }, [allTests, numColumns])

  useEffect(() => {
    if (isFocused && test && clickedTestId === test.id && !isEqual(previousTest, test)) {
      setIsProfileTestModalVisible(true)
    }
  }, [test])

  const fillTestArray = (filteredTests: TestType[]) => {
    const numItemsToFill = (numColumns - filteredTests.length % numColumns) % numColumns
    const emptyArr = numItemsToFill > 0 ? Array(numItemsToFill).fill({ is_empty: true }) : []
    return [
      ...filteredTests,
      ...emptyArr
    ]
  }

  const onProfileTestPress = (id: number) => {
    setClickedTestId(id)
    if (id === test?.id) {
      setIsProfileTestModalVisible(true)
    }
    dispatch(testRequestAction({ id }))
  }

  const onProfileTestModalClose = () => {
    setIsProfileTestModalVisible(false)
  }

  const onProfileRepeatModalClose = () => {
    setIsProfileRepeatModalVisible(false)
  }

  const onPressStart = () => {
    setIsProfileTestModalVisible(false)
    if (!test?.result) {
      navigation.navigate('TestStack', { screen: 'TestScreen', params: { id: test?.id, from: 'profile' } })
    } else {
      setTimeout(() => setIsProfileRepeatModalVisible(true), 500)
    }
  }

  const onModalRepeatConfirm = () => {
    setIsProfileRepeatModalVisible(false)
    navigation.navigate('TestStack', { screen: 'TestScreen', params: { id: test?.id, from: 'profile' } })
  }

  const renderAchieves = (filteredTests: TestType[]) => {
    return (
      <View style={[testsStyle.container, { backgroundColor: colors.background.primary }]}>
        <View style={testsStyle.contentWrapper}>
          {filteredTests.length ? filteredTests.map((item, index) => {
            if (item.is_empty) {
              return <View key={index} style={testsStyle.testContent} />
            }
            return (
              <Test
                key={index}
                id={item.id}
                img={item.image}
                additionalImg={item.achievement_image}
                achieveImg={item.result?.achievement_image}
                isLocked={!item.has_result}
                onTestPress={onProfileTestPress}
              />
            )
          }) : (
            <View style={{
              flex: 1,
              alignItems: 'center'
            }}>
              <Text style={{
                fontFamily: font('bold'),
                fontSize: 18,
                paddingVertical: 8,
                color: colors.text.empty,
              }}>{t('tests_soon')}</Text>
            </View>
          )}
        </View>
      </View>
    )
  }

  const itemWidth = 65 + 10 + 10

  return (
    <View
      onLayout={(event) => {
        const { width } = event.nativeEvent.layout
        setNumColumns(~~((width - 8) / itemWidth))
      }}
    >
      {allTestsFetching ? (
        <View style={styles.activityIndicator}>
          <ActivityIndicator size={'large'} color={colors.fetching} style={{ padding: 16 }} />
        </View>
      ) : (numColumns > 0 && (
        <ScrollView
          bounces={false}
          showsVerticalScrollIndicator={false}
        >
          { renderAchieves(tests) }
          { !!additionalTests.length && (
            <>
              <Text style={[
                testsStyle.additionalTestsTitle,
                { color: colors.text.title }
              ]}>{t('additional_tests')}</Text>
              { renderAchieves(additionalTests) }
            </>
          )}
        </ScrollView>
      ))}
      <Modal
        isVisible={isProfileTestModalVisible}
        onClose={onProfileTestModalClose}
        position='center'
        width='80%'
      >
        <View style={testsStyle.modalTestContainer}>
          <Text style={[testsStyle.modalTestHeaderTitle, { color: colors.text.title }]}>
            {
              !test?.result
                ? t('first_testing')
                : t('retesting')
            }
          </Text>
          { !!test?.image && (
            <Image
              style={testsStyle.modalTestPreview}
              source={{ uri: test.image }}
              resizeMode='contain'
            />
          )}
          <Text style={[testsStyle.modalTestTitle, {
            color: colors.text.title,
            marginTop: !!test?.image ? 10 : 0,
          }]}>
            {test?.title}
          </Text>
          { test?.result && (
            <ModalTestTextBlock label={t('test_title')} text={test.result.description} />
          )}
          { !test?.result && test?.author && (
            <ModalTestTextBlock label={t('test_author')} text={test.author} />
          )}
          { !test?.result && (
            <ModalTestTextBlock
              label={t('test_questions_count')}
              text={`${test?.questions.length}`}
            />
          )}
          <ModalTestTextBlock
            label={t('test_instruction')}
            text={test?.description}
          />
          { test?.result && test?.author && (
            <ModalTestTextBlock label={t('test_author')} text={test.author} />
          )}
          <Button
            text={
              !test?.result
                ? t('test_start')
                : t('test_repeat')
            }
            style={testsStyle.modalTestButton}
            onPress={onPressStart}
          />
        </View>
      </Modal>
      <Modal
        isVisible={isProfileRepeatModalVisible}
        onClose={onProfileRepeatModalClose}
        position='center'
        width={375}
      >
        <View style={testsStyle.modalRepeatContainer}>
          <Text style={[testsStyle.modalRepeatHeaderTitle, { color: colors.text.title }]}>
            { t('retesting') }
          </Text>
          <Text style={[testsStyle.modalRepeatText, { color: colors.text.secondary }]}>
            {t('test_repeat_confirm')}
          </Text>
          <View style={testsStyle.modalRepeatButtonContainer}>
            <Button
              text={t('yes')}
              style={testsStyle.modalRepeatButton}
              type={'secondary'}
              onPress={onModalRepeatConfirm}
            />
            <Button
              text={t('no')}
              style={testsStyle.modalRepeatButton}
              onPress={onProfileRepeatModalClose}
            />
          </View>
        </View>
      </Modal>
    </View>
  )
}

function Test({ id, img, additionalImg, achieveImg, isLocked, onTestPress }: {
  id: number,
  img: string | number | undefined,
  additionalImg: string | number | undefined,
  achieveImg: string | number | undefined,
  isLocked: boolean,
  onTestPress: (id: number) => void
}) {
  const { colors } = useTheme()

  const achieveImage = isLocked
    ? additionalImg ? { uri: additionalImg } : achieveLocked
    : !isLocked
      ? achieveImg ? { uri: achieveImg } : defaultAchieve
      : img ? { uri: img } : defaultAchieve

  return (
    <TouchableOpacity
      style={[
        testsStyle.testContent,
        { backgroundColor: !isLocked ? colors.background.default : colors.background.achieveLocked },
      ]}
      onPress={() => onTestPress(id)}
    >
      <Image
        style={testsStyle.achieveImage}
        source={achieveImage}
      />
    </TouchableOpacity>
  )
}

const testsStyle = StyleSheet.create({
  container: {
    borderRadius: 20,
    // overflow: 'hidden',
    paddingVertical: 8,
    paddingHorizontal: 4,
  },
  contentWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  testContent: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 65,
    height: 65,
    borderRadius: 33,
    marginVertical: 8,
    marginHorizontal: 10,
  },
  achieveImage: {
    width: 46,
    height: 46,
  },
  additionalTestsTitle: {
    fontFamily: font('bold'),
    fontSize: 16,
    lineHeight: 19,
    marginTop: 20,
    marginBottom: 10,
  },
  modalTestContainer: {
    flex: 1,
    paddingHorizontal: 16,
  },
  modalTestHeaderTitle: {
    fontFamily: font('bold'),
    fontSize: 20,
    lineHeight: 24,
  },
  modalTestTitle: {
    fontFamily: font('bold'),
    fontSize: 20,
    lineHeight: 24,
    marginVertical: 10,
  },
  modalTestLabel: {
    fontFamily: font('bold'),
    fontSize: 14,
    lineHeight: 17,
    marginBottom: 5,
  },
  modalTestText: {
    fontFamily: font(),
    fontSize: 14,
    marginBottom: 16,
  },
  modalTestPreview: {
    flex: 1,
    height: 224,
    marginTop: 16,
  },
  modalTestButton: {
    height: 40,
  },
  modalRepeatContainer: {
    flex: 1,
    paddingHorizontal: 16,
  },
  modalRepeatHeaderTitle: {
    fontFamily: font('bold'),
    fontSize: 20,
    lineHeight: 24,
    marginBottom: 14,
  },
  modalRepeatText: {
    fontFamily: font(),
    fontSize: 16,
  },
  modalRepeatButtonContainer: {
    flex: 1,
    marginTop: 24,
    flexDirection: 'row',
  },
  modalRepeatButton: {
    flex: 1,
    marginHorizontal: 5,
  },
  activityIndicator: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
})