import config from '../config'
import { FixtureApi } from '.'
import { NotificationsSuccessResponse, NotificationType } from '../types'

const LIMIT = 10

export default ({ resolve, successResponse, errorResponse, axiosConfig }: FixtureApi) => {
	switch (axiosConfig.method?.toUpperCase()) {
		case 'GET': get({ resolve, successResponse, errorResponse, axiosConfig }); break;
		case 'PATCH': patch({ resolve, successResponse, errorResponse, axiosConfig }); break;
		default: resolve(errorResponse(axiosConfig)); break;
	}
}

function get({ resolve, successResponse, errorResponse, axiosConfig }) {
	const token: NotificationTokens = axiosConfig.headers.Authorization
	const page = axiosConfig.params?.page

	switch (token) {
		case 'Bearer token:79999999999': {
			setTimeout(() => resolve(successResponse(axiosConfig, {
				notifications: generateNotifications(page, token),
				total: NOTIFICATIONS[token].length,
				limit: LIMIT,
				page,
			} as NotificationsSuccessResponse)), config.api.fixtureDuration)
			break;
		}
		case 'Bearer token:79999999998': {
			setTimeout(() => resolve(successResponse(axiosConfig, {
				notifications: generateNotifications(page, token),
				total: NOTIFICATIONS[token].length,
				limit: LIMIT,
				page,
			} as NotificationsSuccessResponse)), config.api.fixtureDuration)
			break;
		}
		default:
			setTimeout(() => resolve(errorResponse(axiosConfig, {
				message: 'Сесся устарела',
			}, 401)), config.api.fixtureDuration)
			break;
	}
}

function patch({ resolve, successResponse, errorResponse, axiosConfig }) {
	const token: NotificationTokens = axiosConfig.headers.Authorization
	const { notification_id } = axiosConfig.params
	const notification = NOTIFICATIONS[token].find(notification => notification.id == notification_id)
	setTimeout(() => resolve(successResponse(axiosConfig, {
		notification: { ...notification, isNew: false }
	})), config.api.fixtureDuration)
}





function generateNotifications(page, token: keyof typeof NOTIFICATIONS) {
	const received = (page - 1) * 10
	const delta = NOTIFICATIONS[token].length - received
	const notificationsCount = received + (delta < LIMIT ? delta : LIMIT)
	return NOTIFICATIONS[token].slice(received, notificationsCount)
}

type NotificationTokens = keyof typeof NOTIFICATIONS

const NOTIFICATIONS = {
	'Bearer token:79999999999': [
		{
			id: 1,
			sended_at: '2020-01-01T12:00',
			readed_at: null,
			important: !!Math.round(Math.random()),
			title: `Date format I should use in my search query`,
			message: `<ISO datetime> ::= YYYYMMDDThhmmss[,ffff]Z <W3C datetime> ::= YYYY-MM-DD[Thh:mm:ss[.ffff]][<timezone>].`,
			image: {
				original: 'https://cs8.pikabu.ru/post_img/big/2016/02/04/7/145458292112119207.jpg',
				thumbnail: 'https://cs8.pikabu.ru/post_img/big/2016/02/04/7/145458292112119207.jpg',
			}
		},
		{
			id: 2,
			sended_at: '2020-01-02T15:39',
			readed_at: null,
			important: !!Math.round(Math.random()),
			title: `How to manipulate date and time in JavaScript`,
			message: `In theory, handling dates as a developer is as simple as creating, storing, and, if necessary, manipulating dates.`,
			image: {
				original: 'https://s1.1zoom.ru/big0/697/Love_Night_Moon_Trees_Silhouette_Two_Dating_576752_1280x853.jpg',
				thumbnail: 'https://s1.1zoom.ru/big0/697/Love_Night_Moon_Trees_Silhouette_Two_Dating_576752_1280x853.jpg',
			}
		},
		{
			id: 3,
			sended_at: '2020-01-03T14:05',
			readed_at: null,
			important: !!Math.round(Math.random()),
			title: `Terraformを使ったAzure App Serviceのバックアップとログの`,
			message: `本記事はFIXERが提供する「cloud.config Tech Blog」に掲載された「Terraformを使ったAppServiceのバックアップとAppServiceログの設定」を再編集した.`,
			image: {
				original: 'https://bipbap.ru/wp-content/uploads/2017/04/0_7c779_5df17311_orig.jpg',
				thumbnail: 'https://bipbap.ru/wp-content/uploads/2017/04/0_7c779_5df17311_orig.jpg',
			}
		},
		{
			id: 4,
			sended_at: '2020-01-04T18:55',
			readed_at: null,
			important: !!Math.round(Math.random()),
			title: `British tourists charged £1000s for pier visits in billing blunder`,
			message: `British seaside resorts are famous for their piers, walkways that stretch out over the sea so that visitors can get the feeling of being “at.`,
			image: {
				original: 'https://cs8.pikabu.ru/post_img/big/2016/02/04/7/145458292112119207.jpg',
				thumbnail: 'https://cs8.pikabu.ru/post_img/big/2016/02/04/7/145458292112119207.jpg',
			}
		},
		{
			id: 5,
			sended_at: '2020-01-05T14:05',
			readed_at: null,
			important: !!Math.round(Math.random()),
			title: `It's Time for an HTML5 Time Tag`,
			message: `The <time> tag and its datetime attribute are supported by all the major browsers including Google Chrome 6.0+, Internet Explorer/Edge 9.0+.`,
			image: {
				original: 'https://cs8.pikabu.ru/post_img/big/2016/02/04/7/145458292112119207.jpg',
				thumbnail: 'https://cs8.pikabu.ru/post_img/big/2016/02/04/7/145458292112119207.jpg',
			}
		},
		{
			id: 6,
			sended_at: '2020-01-06T04:33',
			readed_at: null,
			important: !!Math.round(Math.random()),
			title: `Inbox of Tricks: Stand Out with Gmail's New Promotion Tools`,
			message: `The inbox is a competitive place. Marketers strive to craft compelling subject lines which shine that little bit brighter than the rest.`,
			image: {
				original: 'https://cs8.pikabu.ru/post_img/big/2016/02/04/7/145458292112119207.jpg',
				thumbnail: 'https://cs8.pikabu.ru/post_img/big/2016/02/04/7/145458292112119207.jpg',
			}
		},
		{
			id: 7,
			sended_at: '2020-01-07T04:01',
			readed_at: null,
			important: !!Math.round(Math.random()),
			title: `New FAQs: Google Jobs Feature In Search`,
			message: `Google's webmaster team, specifically Mariya Moeva from Google, has just posted a new FAQ around this feature. This is tailored for webmasters.`,
			image: {
				original: 'https://cs8.pikabu.ru/post_img/big/2016/02/04/7/145458292112119207.jpg',
				thumbnail: 'https://cs8.pikabu.ru/post_img/big/2016/02/04/7/145458292112119207.jpg',
			}
		},
		{
			id: 8,
			sended_at: '2020-01-08T08:33',
			readed_at: null,
			important: !!Math.round(Math.random()),
			title: `VBScript and Task Scheduler 2.0 : Creating Scheduled Tasks`,
			message: `My next task was to build a script that could create all the options available for a new task. I found that objects available actually presented.`,
			image: {
				original: 'https://cs8.pikabu.ru/post_img/big/2016/02/04/7/145458292112119207.jpg',
				thumbnail: 'https://cs8.pikabu.ru/post_img/big/2016/02/04/7/145458292112119207.jpg',
			}
		},
		{
			id: 9,
			sended_at: '2020-01-09T08:18',
			readed_at: null,
			important: !!Math.round(Math.random()),
			title: `macOS Sierra: Unified Logging, la nueva manera de guardar`,
			message: `YYYYMMDDTHHMMSS.tracev3 . Estos archivos son binarios que requieren del uso de la nueva utilidad de análisis de logs en macOS Sierra para.`,
			image: {
				original: 'https://cs8.pikabu.ru/post_img/big/2016/02/04/7/145458292112119207.jpg',
				thumbnail: 'https://cs8.pikabu.ru/post_img/big/2016/02/04/7/145458292112119207.jpg',
			}
		},
		{
			id: 10,
			sended_at: '2020-01-10T23:41',
			readed_at: null,
			important: !!Math.round(Math.random()),
			title: `Putting CLI Skeletons to Work in AWS`,
			message: `You can script Amazon Web Services tasks using the command-line interface to build a skeleton script. By Brien Posey; 06/15/2018.`,
			image: {
				original: 'https://cs8.pikabu.ru/post_img/big/2016/02/04/7/145458292112119207.jpg',
				thumbnail: 'https://cs8.pikabu.ru/post_img/big/2016/02/04/7/145458292112119207.jpg',
			}
		},
		{
			id: 11,
			sended_at: '2020-01-11T04:22',
			readed_at: null,
			important: !!Math.round(Math.random()),
			title: `Java 8 Features Supported by Spring 4`,
			message: `spring code base uses a lot of functional interfaces and with java 8, lambda expressions can be used to write cleaner and compact code. we can.`,
			image: {
				original: 'https://cs8.pikabu.ru/post_img/big/2016/02/04/7/145458292112119207.jpg',
				thumbnail: 'https://cs8.pikabu.ru/post_img/big/2016/02/04/7/145458292112119207.jpg',
			}
		},
		{
			id: 12,
			sended_at: '2021-09-02T04:22',
			readed_at: null,
			important: !!Math.round(Math.random()),
			title: `En finir avec CSV ou Excel pour échanger des données`,
			message: `Le gros intérêt de json c'est qu'il mappe directement a des objets (le o de json), et est super flexible niveau schéma. Ces 2 points n'ont que peu d'intérêt.`,
			image: {
				original: 'https://cs8.pikabu.ru/post_img/big/2016/02/04/7/145458292112119207.jpg',
				thumbnail: 'https://cs8.pikabu.ru/post_img/big/2016/02/04/7/145458292112119207.jpg',
			}
		},
	] as NotificationType[],
	'Bearer token:79999999998': [] as NotificationType[],
}