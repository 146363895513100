import React, { useEffect, useState } from 'react'
import {
	FlatList,
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
	ScaledSize,
} from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'react-native-flash-message'
import moment from 'moment'
import toUpper from 'lodash/toUpper'
import isNumber from 'lodash/isNumber'

import {
	Button,
	Icon,
	ListEmptyComponent,
	Modal,
	Screen,
	TextInput,
  TabBar,
  RadioButtonGroup,
} from '../../components'
import { t } from '../localization'
import { AppState } from '../store'
import { font, useTheme } from '../../theme'
import { Nullable, TrackerType, EmployeeType } from '../types'
import {
  trackersRequestAction,
  deleteTrackerRequestAction,
} from '../store/redux/tracker'
import {
  employeesRequestAction,
} from '../store/redux/employee'
import { usePrevious } from '../../hooks/usePrevious'
import config from '../../config'
import { getUtcOffset } from '../../service'

type Type = 'TRACKERS' | 'EMPLOYEES'
type SortType = 'FIO' | 'EMAIL' | 'RATING' | 'LAST-RATING'

export default function MainScreen({ route }: { route: any }) {
  const { colors } = useTheme()
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const companyName = useSelector((state: AppState) => state.user.profile?.company_name) || ''
  const maxTrackersCount: number = useSelector((state: AppState) => state.user.profile?.company_max_trackers) || 0
  const maxEmployeesCount: number = useSelector((state: AppState) => state.user.profile?.company_max_employee) || 0
  const trackers: Array<TrackerType> = useSelector((state: AppState) => state.tracker.trackers)
  const trackersFetching: boolean = useSelector((state: AppState) => state.tracker.trackers_fetching)
  const deleteTrackerSuccess: boolean = useSelector((state: AppState) => state.tracker.delete_tracker_success)
  const employees: Array<EmployeeType> = useSelector((state: AppState) => state.employee.employees)
  const employeesFetching: boolean = useSelector((state: AppState) => state.employee.employees_fetching)
  const employeesCount: number = useSelector((state: AppState) => state.employee?.employees_counter) || 0

  const [isManageTrackerModalVisible, setIsManageTrackerModalVisible] = useState<boolean>(false)
  const [isDeleteTrackerModalVisible, setIsDeleteTrackerModalVisible] = useState<boolean>(false)
  const [isSortModalVisible, setIsSortModalVisible] = useState<boolean>(false)
  const [selectedTracker, setSelectedTracker] = useState<Nullable<TrackerType>>(null)
  const [deleteFieldValue, setDeleteFieldValue] = useState<string>('')
  const [showErrorText, setShowErrorText] = useState<boolean>(false)
  const [screenData, setScreenData] = useState(Dimensions.get('window'))
  const type = toUpper(route.params?.type)
  const sortBy = toUpper(route.params?.sortBy)
  const [tabType, setTabType] = useState<Type>(type === 'TRACKERS' || type === 'EMPLOYEES' ? type : 'TRACKERS')
  const [sortType, setSortType] = useState<SortType>(
    sortBy === 'FIO' || sortBy === 'EMAIL' || sortBy === 'RATING' || sortBy === 'LAST-RATING'
    ? sortBy
    : 'FIO'
  )
  const [sortedEmployees, setSortedEmployees] = useState<Array<EmployeeType>>([])

  const prevDeleteTrackerSuccess = usePrevious(deleteTrackerSuccess)

  useEffect(() => {
    const onChange = (result: { window: React.SetStateAction<ScaledSize> }) => {
      setScreenData(result.window)
    }

    const subscription = Dimensions.addEventListener('change', onChange)

    const type = toUpper(route.params?.type)
    setTabType(type === 'TRACKERS' || type === 'EMPLOYEES' ? type : 'TRACKERS')
    const sortBy = toUpper(route.params?.sortBy)
    setSortType(
      sortBy === 'FIO' || sortBy === 'EMAIL' || sortBy === 'RATING' || sortBy === 'LAST-RATING'
      ? sortBy
      : 'FIO'
    )

    return () => subscription?.remove()
  })

  useEffect(() => {
    dispatch(trackersRequestAction({ tz_offset: getUtcOffset() }))
    dispatch(employeesRequestAction())
    onTabChange(tabType, true)

    const sortBy = toUpper(route.params?.sortBy)
    if (sortBy) {
      setSortedEmployeesHandler(sortType)
    }
  }, [])

  useEffect(() => {
    setSortedEmployees(employees)
  }, [employees])

  useEffect(() => {
    if (prevDeleteTrackerSuccess !== null && !prevDeleteTrackerSuccess && deleteTrackerSuccess) {
      setIsDeleteTrackerModalVisible(false)
      dispatch(trackersRequestAction({ tz_offset: getUtcOffset() }))
      showMessage({
        type: 'success',
        message: t('delete_tracker_success'),
        duration: config.api.messageDuration,
      })
    }
  }, [deleteTrackerSuccess])

  const onTabChange = (id: 0 | 1 | Type, init = false) => {
    const types: Array<Type> = ['TRACKERS', 'EMPLOYEES']
    const type: Type = isNumber(id) ? types[id] : id
    setTabType(type)
    if (!init) {
      navigation.setParams({ type, sortBy: undefined })
    }
  }

  const onManageTrackerModalClose = () => {
    setIsManageTrackerModalVisible(false)
  }

  const onDeleteTrackerModalClose = () => {
    setIsDeleteTrackerModalVisible(false)
  }

  const onSortModalClose = () => {
    setIsSortModalVisible(false)
  }

  const onDeleteTrackerModalConfirm = () => {
    if (deleteFieldValue !== 'DELETE') {
      setShowErrorText(true)
    } else {
      dispatch(deleteTrackerRequestAction({ id: selectedTracker!.id }))
    }
  }

  const onTrackersRefresh = () => {
    dispatch(trackersRequestAction({ tz_offset: getUtcOffset() }))
  }

  const onEmployeesRefresh = () => {
    dispatch(employeesRequestAction())
  }

  const renderTrackerListTile = ({ item, index }: { item: TrackerType, index: number }) => {
    return (
      <View style={[styles.card, {
        backgroundColor: colors.background.primary,
      }]}>
        <View style={[styles.trackerIconContainer, { backgroundColor: item.background }]}>
          <Text style={[styles.trackerIcon, { color: colors.text.default }]}>{item.icon}</Text>
        </View>
        <View style={styles.trackerDescriptionContainer}>
          <Text style={[styles.trackerTitle, {color: colors.text.subtitle}]}>
            {item.title}
          </Text>
          <Text style={{color: colors.text.primary}} numberOfLines={2}>{item.description}</Text>
        </View>
        <View style={styles.trackerTrailing}>
          <Icon
            name={'more'}
            color={colors.icon.inactive}
            onPress={() => {
              setSelectedTracker(item)
              setIsManageTrackerModalVisible(true)
            }}
          />
        </View>
      </View>
    )
  }

  const renderEmployeeListTile = ({ item }: { item: EmployeeType }) => {
    return (
      <TouchableOpacity
        style={[styles.card, {
          backgroundColor: colors.background.primary,
        }]}
        onPress={() => {
          navigation.navigate('EmployeeStack', {
            screen: 'EmployeeScreen',
            params: { id: item.id },
          })
        }}
      >
        <View style={styles.employeeCardContent}>
          <View style={{flex: 1}}>
            <View style={styles.employeeTrackerContainer}>
              {item.tracker_background != null
              && item.tracker_icon != null
              && item.tracker_title != null
              && (
                <View style={[styles.employeeTrackerIconContainer, {
                  backgroundColor: item.tracker_background,
                }]}>
                  <Text style={[styles.employeeTrackerIconContent, {
                    color: colors.text.default,
                  }]}>{item.tracker_icon}</Text>
                </View>
              )}
              <Text style={{ color: colors.text.primary }}>
                {item.tracker_title ? t('tracker_name', [item.tracker_title]) : t('no_tracker')}
              </Text>
            </View>
            <Text style={[styles.employeeFIO, {color: colors.text.default}]}>
              {`${item.lastname} ${item.firstname} ${item.middlename !== null ? item.middlename : ''}`.trim()}
            </Text>
            <Text style={{color: colors.text.subtitle}}>{item.email}</Text>
          </View>
          {item.tracker_background != null
              && item.tracker_icon != null
              && item.tracker_title != null
              && (
            <View style={styles.employeeRatingsContainer}>
              <RatingView
                rating={item.rate_week_ago ?? 0}
                date={moment().subtract(7, 'days').format('DD.MM')}
              />
              <RatingView
                rating={item.rate ?? 0}
                oldRating={item.rate_week_ago ?? 0}
                date={moment().format('DD.MM')}
              />
            </View>
          )}
        </View>
      </TouchableOpacity>
    )
  }

  const Divider = () => (
    <View style={[styles.divider, {borderBottomColor: colors.divider}]} />
  )

  const renderTitle = () => {
    return (
      <View style={styles.title}>
        <Text style={[styles.titleText, { color: colors.text.title }]}>
          {trackers.length > 0 ? companyName : t('trackers')}
        </Text>
      </View>
    )
  }

  const renderTrackerCounter = () => {
    return (
      <View style={styles.counter}>
        <Text style={[styles.counterText, {color: colors.text.title}]}>
          {`${trackers.length}/${maxTrackersCount}`}
        </Text>
      </View>
    )
  }

  const renderEmployeesCounter = () => {
    return (
      <Text style={[styles.counterText, {color: colors.text.title}]}>
        {`${employeesCount}/${maxEmployeesCount}`}
      </Text>
    )
  }

  const renderEmployeesSort = () => {
    return (
      <Icon
        name={'sort'}
        size={18}
        color={colors.icon.default}
        onPress={() => {
          setIsSortModalVisible(true)
        }}
      />
    )
  }

  const setSortedEmployeesHandler = (selectedSortType: SortType) => {
    const itemsToSort = [...sortedEmployees]
    switch (selectedSortType) {
      case 'FIO':
        itemsToSort.sort((a, b) => {
          const fullnameA = `${a.lastname} ${a.firstname} ${a.middlename}`;
          const fullnameB = `${b.lastname} ${b.firstname} ${b.middlename}`;
          return fullnameA.localeCompare(fullnameB);
        })
        break;
      case 'EMAIL':
        itemsToSort.sort((a, b) => a.email.localeCompare(b.email))
        break;
      case 'RATING':
        itemsToSort.sort((a, b) => {
          const rateA = a.rate ? a.rate : 99999
          const rateB = b.rate ? b.rate : 99999
          return rateA - rateB
        })
        break;
      case 'LAST-RATING':
        itemsToSort.sort((a, b) => {
          const rateA = a.rate_week_ago ? a.rate_week_ago : 99999
          const rateB = b.rate_week_ago ? b.rate_week_ago : 99999
          return rateA - rateB
        })
        break;
      default:
    }
    setSortedEmployees(itemsToSort)
    setSortType(selectedSortType)
    navigation.setParams({ sortBy: selectedSortType })
  }

  const isSmallScreen = screenData.width < 655

  return (
    <Screen>
      <View style={[
        styles.contentWrapper,
        {
          width: !isSmallScreen ? '47.22%' : '100%',
          minWidth: !isSmallScreen ? 635 : 'auto',
        },
      ]}>
        {renderTitle()}
        <TabBar
          containerStyle={styles.tabBar}
          active={tabType === 'TRACKERS' ? 0 : 1}
          onChange={onTabChange}
          leftTabText={t('trackers')}
          rightTabText={t('employees')}
        />
        {tabType === 'TRACKERS' && (
          <View style={styles.container}>
            {renderTrackerCounter()}
            <FlatList
              style={styles.content}
              bounces={false}
              keyExtractor={(item, index) => `${index}_${item.id}`}
              data={trackers}
              renderItem={renderTrackerListTile}
              ItemSeparatorComponent={() => <View style={styles.separator} />}
              ListEmptyComponent={() => (
                <View style={styles.emptyContainer}>
                  <ListEmptyComponent type={'trackers'} />
                  <Button
                    style={styles.emptyButton}
                    text={`+ ${t('new_tracker')}`}
                    onPress={() => {
                      navigation.navigate('TrackerStack', { screen: 'AddTrackerScreen' })
                    }}
                  />
                </View>
              )}
              onRefresh={onTrackersRefresh}
              refreshing={trackersFetching}
            />
            {trackers.length > 0 && (
              <Button
              text={`+ ${t('new_tracker')}`}
              disabled={trackers.length >= maxTrackersCount}
              onPress={() => {
                navigation.navigate('TrackerStack', { screen: 'AddTrackerScreen' })
              }}
              />
            )}
          </View>
        )}
        {tabType === 'EMPLOYEES' && (
          <View style={styles.container}>
            <View style={[styles.counter, styles.employeeCounter]}>
              {renderEmployeesCounter()}
              {renderEmployeesSort()}
            </View>
            <FlatList
              style={styles.content}
              bounces={false}
              keyExtractor={(item, index) => `${index}_${item.id}`}
              data={sortedEmployees}
              renderItem={renderEmployeeListTile}
              ItemSeparatorComponent={() => <View style={styles.separator} />}
              onRefresh={onEmployeesRefresh}
              refreshing={employeesFetching}
              />
            <Button
              text={`+ ${t('new_employee')}`}
              disabled={sortedEmployees.length >= maxEmployeesCount}
              onPress={() => {
                navigation.navigate('EmployeeStack', { screen: 'AddEmployeeScreen' })
              }}
            />
          </View>
        )}
        <Modal
          isVisible={isManageTrackerModalVisible}
          onClose={onManageTrackerModalClose}
          onModalHide={onManageTrackerModalClose}
          position='center'
        >
          <View style={manageTrackerModalStyles.container}>
            <Text style={[manageTrackerModalStyles.title, { color: colors.text.default }]}>
              {t('tracker')}
            </Text>
            <TouchableOpacity onPress={() => {
              setIsManageTrackerModalVisible(false)
              navigation.navigate('TrackerStack', { screen: 'AddTrackerScreen', params: {
                id: selectedTracker!.id,
                mode: 'edit',
              }})
            }}>
              <View style={manageTrackerModalStyles.tileContainer}>
                <Text style={manageTrackerModalStyles.tileText}>{t('edit')}</Text>
              </View>
            </TouchableOpacity>
            <Divider />
            <TouchableOpacity onPress={() => {
              setIsManageTrackerModalVisible(false)
              setIsDeleteTrackerModalVisible(true)
            }}>
              <View style={manageTrackerModalStyles.tileContainer}>
                <Text style={manageTrackerModalStyles.tileText}>{t('delete')}</Text>
              </View>
            </TouchableOpacity>
          </View>
        </Modal>
        <Modal
          isVisible={isDeleteTrackerModalVisible}
          onClose={onDeleteTrackerModalClose}
          position='center'
          width={375}
        >
          <View style={deleteTrackerModalStyles.container}>
            <Text style={[deleteTrackerModalStyles.title, { color: colors.text.default }]}>
              {t('tracker_deleting')}
            </Text>
            <View>
              <Text style={deleteTrackerModalStyles.description}>
                {t('tracker_deleting_description_start')}
                <Text style={{ color: colors.text.error }}>DELETE</Text>
                {t('tracker_deleting_description_end')}
              </Text>
              <TextInput
                textStyle={deleteTrackerModalStyles.deleteInput}
                placeholder={t('delete_placeholder')}
                onChange={(value) => {
                  setShowErrorText(false)
                  setDeleteFieldValue(value)
                }}
                maxLength={10}
                error={showErrorText ? t('delete_field_error') : ''}
              />
              <View style={deleteTrackerModalStyles.buttonContainer}>
                <Button
                  text={t('cancel')}
                  style={deleteTrackerModalStyles.modalDeleteTrackerButton}
                  type={'secondary'}
                  onPress={onDeleteTrackerModalClose}
                />
                <View style={deleteTrackerModalStyles.spacer}/>
                <Button
                  text={t('delete')}
                  type={'warning'}
                  style={deleteTrackerModalStyles.modalDeleteTrackerButton}
                  onPress={onDeleteTrackerModalConfirm}
                  disabled={deleteFieldValue.length === 0}
                />
              </View>
            </View>
          </View>
        </Modal>
        <Modal
          isVisible={isSortModalVisible}
          onClose={onSortModalClose}
          position='center'
          width={375}
        >
          <View style={sortModalStyles.container}>
            <Text style={[sortModalStyles.title, { color: colors.text.default }]}>
              {t('sort_by')}
            </Text>
            <RadioButtonGroup
              buttons={[
                { rightText: t('fio'), value: 'FIO' },
                { rightText: t('email'), value: 'EMAIL' },
                { rightText: t('byCurrentRating'), value: 'RATING' },
                { rightText: t('byRating7DaysAgo'), value: 'LAST-RATING' },
              ]}
              checked={sortType}
              onSelect={setSortedEmployeesHandler}
            />
          </View>
        </Modal>
      </View>
    </Screen>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 24,
  },
  contentWrapper: {
    marginTop: 40,
    paddingHorizontal: 20,
    alignSelf: 'center',
  },
  content: {
    marginBottom: 12,
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 12,
  },
  titleText: {
    fontSize: 24,
    fontFamily: font(),
  },
  separator: {
    height: 8,
  },
  divider: {
    height: 1,
    borderBottomWidth: 1,
  },
  card: {
    borderRadius: 20,
    padding: 16,
    flexDirection: 'row',
  },
  trackerIconContainer: {
    width: 40,
    height: 40,
    marginTop: 5,
    marginRight: 12,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  trackerIcon: {
    fontSize: 20,
  },
  trackerDescriptionContainer: {
    flex: 1,
    marginRight: 8,
  },
  trackerTitle: {
    fontSize: 18,
    marginBottom: 12,
  },
  trackerTrailing: {
    alignItems: 'flex-end',
  },
  trackerPoints: {
    fontSize: 18,
  },
  emptyContainer: {
    alignItems: 'center',
  },
  emptyButton: {
    marginTop: 16,
    width: '100%',
  },
  tabBar: {
    marginBottom: 24,
  },
  counter: {
    marginBottom: 12,
  },
  employeeCounter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  counterText: {
    fontFamily: font(),
    fontSize: 24,
  },
  employeeCardContent: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  employeeTrackerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  },
  employeeTrackerIconContainer: {
    width: 20,
    height: 20,
    marginRight: 8,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  employeeTrackerIconContent: {
    fontSize: 10,
  },
  employeeFIO: {
    fontFamily: font(),
    fontSize: 18,
    marginBottom: 4,
  },
  employeeRatingsContainer: {
    flexDirection: 'row',
  },
})
const manageTrackerModalStyles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
  title: {
    paddingBottom: 8,
    fontSize: 24,
    fontWeight: 'bold',
  },
  tileContainer: {
    paddingVertical: 18,
  },
  tileText: {
    fontWeight: 'bold',
    fontSize: 16,
  },
})
const deleteTrackerModalStyles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
  title: {
    paddingBottom: 8,
    fontSize: 24,
    fontWeight: 'bold',
  },
  modalDeleteTrackerButton: {
    flex: 1,
  },
  description: {
    marginBottom: 16,
  },
  buttonContainer: {
    marginTop: 24,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  spacer: {
    width: 3,
  },
  deleteInput: {
    height: 40,
  },
})
const sortModalStyles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
  title: {
    paddingBottom: 24,
    fontSize: 24,
    fontWeight: 'bold',
  },
})

function RatingView(
  { rating, oldRating, date }: { rating: number, oldRating?: number, date: string }
) {
  const { colors } = useTheme()

  const [text, setText] = useState<string>(`${rating}`)

  const bgColor = oldRating != undefined
    ? rating == oldRating
      ? colors.background.blue
      : rating > oldRating
        ? colors.background.green
        : colors.background.red
    : colors.background.input

  const textColor = oldRating != undefined
    ? rating == oldRating
      ? colors.text.blue
      : rating > oldRating
        ? colors.text.green
        : colors.text.red
    : colors.text.default

  return (
    <View
      style={[ratingViewStyles.container, { backgroundColor: bgColor }]}
      onMouseEnter={() => { setText(date) }}
      onMouseLeave={() => { setText(`${rating}`) }}
    >
      <Text style={[ratingViewStyles.content, { color: textColor }]}>{text}</Text>
    </View>
  )
}
const ratingViewStyles = StyleSheet.create({
  container: {
    width: 70,
    height: 64,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    cursor: 'pointer',
  },
  content: {
    fontFamily: font(),
    fontSize: 18,
  },
})