import React from 'react'
import { Image, View, Text, TextStyle, ViewStyle } from 'react-native'
import { CommonActions } from '@react-navigation/native'
import { connect } from 'react-redux'

import { AppState } from '../store/redux'
import { Button, Screen } from '../components'
import { t } from '../localization'
import { font, ThemeColors, useTheme } from '../theme'
import { notFound } from '../images'

interface Props {
	colors: ThemeColors,
	navigation: any,
}

interface State { }

function NotFoundScreenWrapper(props: Props) {
	const { colors } = useTheme()
	return <NotFoundScreen colors={colors} {...props} />
}

class NotFoundScreen extends React.Component<Props, State>{

	render() {
		const { colors, token, navigation } = this.props

		return (
			<Screen hideHeader={!token}>
				<View style={styles.contentContainer}>
					<Image
						source={notFound}
						style={styles.image}
						resizeMode={'contain'}
					/>
					<Text style={[styles.title, { color: colors.text.title }]}>
						{t('page_not_found')}
					</Text>
					<Text style={[styles.text, { color: colors.text.default }]}>
						{t('page_not_found_description')}
					</Text>
					<Button
						text={t('to_main')}
						style={styles.button}
						onPress={() => navigation.dispatch((state: any) => (
							CommonActions.reset({
								index: 0,
								routes: state.routeNames[0] === 'AuthStack'
									? [{ name: 'AuthStack', params: { screen: 'AuthScreen' } }]
									: [{ name: 'MainStack', params: { screen: 'MainScreen' } }],
							})
						))}
					/>
				</View>
			</Screen>
		)
	}
}

const mapStateToProps = (state: AppState) => {
	return ({ token: state.auth.token })
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(NotFoundScreenWrapper)

const styles = {
	contentContainer: {
		flexGrow: 1,
		paddingTop: 12,
		paddingBottom: 40,
		paddingHorizontal: 16,
		alignItems: 'center',
		justifyContent: 'center',
	} as ViewStyle,
	image: {
		width: 379,
		height: 237,
	},
	title: {
		fontFamily: font(),
		fontSize: 36,
	},
	text: {
		marginTop: 20,
		fontFamily: font(),
		fontSize: 18,
		textAlign: 'center',
	} as TextStyle,
	button: {
		marginTop: 20,
		width: 201,
	},
}
