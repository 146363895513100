import {
	ApiFailure,
	ApiFailureResponse,
	Nullable,
	ServiceReviewsRequest,
	ServiceReviewsSuccessResponse,
	CreateReviewRequest,
	CreateReviewSuccessResponse,
} from '../../types'

export interface ReviewsState {
	service_reviews: any
	service_reviews_fetching: boolean
	service_reviews_error: Nullable<ApiFailure>
	create_service_review_fetching: boolean
	create_service_review_error: Nullable<ApiFailure>
}

export const SERVICE_REVIEWS_REQUEST = 'SERVICE_REVIEWS_REQUEST'
export const SERVICE_REVIEWS_SUCCESS = 'SERVICE_REVIEWS_SUCCESS'
export const SERVICE_REVIEWS_FAILURE = 'SERVICE_REVIEWS_FAILURE'
export const CREATE_REVIEW_REQUEST = 'CREATE_REVIEW_REQUEST'
export const CREATE_REVIEW_SUCCESS = 'CREATE_REVIEW_SUCCESS'
export const CREATE_REVIEW_FAILURE = 'CREATE_REVIEW_FAILURE'

export const serviceReviewsRequestAction = (params: ServiceReviewsRequest) =>
	({ type: SERVICE_REVIEWS_REQUEST, params })
export const serviceReviewsSuccessAction = (payload: ServiceReviewsSuccessResponse) =>
	({ type: SERVICE_REVIEWS_SUCCESS, payload })
export const serviceReviewsFailureAction = (payload: ApiFailureResponse) =>
	({ type: SERVICE_REVIEWS_FAILURE, payload })
export const createReviewRequestAction = (params: CreateReviewRequest) =>
	({ type: CREATE_REVIEW_REQUEST, params })
export const createReviewSuccessAction = (payload: CreateReviewSuccessResponse) =>
	({ type: CREATE_REVIEW_SUCCESS, payload })
export const createReviewFailureAction = (payload: ApiFailureResponse) =>
	({ type: CREATE_REVIEW_FAILURE, payload })

const initialState: ReviewsState = {
	service_reviews: null,
	service_reviews_fetching: false,
	service_reviews_error: null,
	create_service_review_fetching: false,
	create_service_review_error: null
}

const ReviewReducer = (state = initialState, action: any): ReviewsState => {
	const { type, payload } = action
	switch (type) {
		case SERVICE_REVIEWS_REQUEST: {
			return { ...state, service_reviews_fetching: true, service_reviews_error: null }
		}
		case SERVICE_REVIEWS_SUCCESS: {
			const { result, serviceId } = payload as ServiceReviewsSuccessResponse
			const { reviews, page, limit, total } = result
			return {
				...state,
				service_reviews_fetching: false,
				service_reviews: {
					...state.service_reviews,
					[serviceId]: {
						reviews: page === 1 ? reviews : [...(state.service_reviews[serviceId].reviews || []), ...reviews],
						page: page,
						limit: limit,
						total: total,
					}
				}
			}
		}
		case SERVICE_REVIEWS_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, service_reviews_fetching: false, service_reviews_error: error }
		}
		case CREATE_REVIEW_REQUEST: {
			return { ...state, create_service_review_fetching: true, create_service_review_error: null }
		}
		case CREATE_REVIEW_SUCCESS: {
			return { ...state, create_service_review_fetching: false }
		}
		case CREATE_REVIEW_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, create_service_review_fetching: false, create_service_review_error: error }
		}
		default:
			return state
	}
}

export default ReviewReducer
