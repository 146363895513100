import { put, call } from 'redux-saga/effects'
import { ApiResponse } from 'apisauce'

import { api, ApiSuccessResponse } from '../../api'
import {
	Action,
	EmployeesRequest,
	EmployeesSuccessResponse,
	AddEmployeeRequest,
	AddEmployeeSuccessResponse,
	EmployeeRequest,
	EmployeeSuccessResponse,
	AddTrackerToEmployeeRequest,
	AddTrackerToEmployeeSuccessResponse,
	ReplaceEmployeeTrackerRequest,
	ReplaceEmployeeTrackerSuccessResponse,
	UnlinkEmployeeTrackerRequest,
  UnlinkEmployeeTrackerSuccessResponse,
	DeleteEmployeeRequest,
	DeleteEmployeeSuccessResponse,
} from '../../types'
import {
	employeesSuccessAction,
	employeesFailureAction,
	addEmployeeSuccessAction,
	addEmployeeFailureAction,
	employeeSuccessAction,
	employeeFailureAction,
	addTrackerToEmployeeSuccessAction,
	addTrackerToEmployeeFailureAction,
	replaceEmployeeTrackerSuccessAction,
	replaceEmployeeTrackerFailureAction,
	unlinkEmployeeTrackerSuccessAction,
	unlinkEmployeeTrackerFailureAction,
	deleteEmployeeSuccessAction,
	deleteEmployeeFailureAction,
} from '../redux/employee'

export function* employeesRequestSaga(action: Action<EmployeesRequest>) {
	const response: ApiResponse<ApiSuccessResponse<EmployeesSuccessResponse>> = yield call(api.employeesRequest)
	if (response.ok) {
		yield put(employeesSuccessAction({
			employees: response.data!.result.employees,
			employees_counter: response.data!.result.employees_counter,
		}))
	} else {
		yield put(employeesFailureAction(response.data))
	}
}

export function* addEmployeeRequestSaga(action: Action<AddEmployeeRequest>) {
	const response: ApiResponse<ApiSuccessResponse<AddEmployeeSuccessResponse>> = yield call(api.addEmployeeRequest, action.params)
	if (response.ok) {
		yield put(addEmployeeSuccessAction(response.data!.result))
	} else {
		yield put(addEmployeeFailureAction(response.data))
	}
}

export function* employeeRequestSaga(action: Action<EmployeeRequest>) {
	const response: ApiResponse<ApiSuccessResponse<EmployeeSuccessResponse>> = yield call(api.employeeRequest, action.params)
	if (response.ok) {
		yield put(employeeSuccessAction(response.data!.result))
	} else {
		yield put(employeeFailureAction(response.data))
	}
}

export function* addTrackerToEmployeeRequestSaga(action: Action<AddTrackerToEmployeeRequest>) {
	const response: ApiResponse<ApiSuccessResponse<AddTrackerToEmployeeSuccessResponse>> = yield call(api.addTrackerToEmployeeRequest, action.params)
	if (response.ok) {
		yield put(addTrackerToEmployeeSuccessAction(response.data!.result))
	} else {
		yield put(addTrackerToEmployeeFailureAction(response.data))
	}
}

export function* replaceEmployeeTrackerRequestSaga(action: Action<ReplaceEmployeeTrackerRequest>) {
	const response: ApiResponse<ApiSuccessResponse<ReplaceEmployeeTrackerSuccessResponse>> = yield call(api.replaceEmployeeTrackerRequest, action.params)
	if (response.ok) {
		yield put(replaceEmployeeTrackerSuccessAction(response.data!.result))
	} else {
		yield put(replaceEmployeeTrackerFailureAction(response.data))
	}
}

export function* unlinkEmployeeTrackerRequestSaga(action: Action<UnlinkEmployeeTrackerRequest>) {
	const response: ApiResponse<ApiSuccessResponse<UnlinkEmployeeTrackerSuccessResponse>> = yield call(api.unlinkEmployeeTrackerRequest, action.params)
	if (response.ok) {
		yield put(unlinkEmployeeTrackerSuccessAction(response.data!.result))
	} else {
		yield put(unlinkEmployeeTrackerFailureAction(response.data))
	}
}

export function* deleteEmployeeRequestSaga(action: Action<DeleteEmployeeRequest>) {
	const response: ApiResponse<ApiSuccessResponse<DeleteEmployeeSuccessResponse>> = yield call(api.deleteEmployeeRequest, action.params)
	if (response.ok) {
		yield put(deleteEmployeeSuccessAction(response.data!.result))
	} else {
		yield put(deleteEmployeeFailureAction(response.data))
	}
}
