import { showMessage } from 'react-native-flash-message'
import { create, ApisauceInstance, ApiResponse } from 'apisauce'
import moment from 'moment'
import 'moment/locale/ru'

import { store, AppState } from './store'
import config from './config'
import { create as fixtureCreate } from './fixtures'
import { getTimeZone } from './service'
import {
	AuthRequest,
	AuthByEmailRequest,
	AuthSuccessResponse,
	ChatMessagesRequest,
	ChatMessageSuccessResponse,
	ChatSendMessageRequest,
	ChatSendMessageSuccessResponse,
	ChatSuccessResponse,
	ServiceRequest,
	ServiceByIdRequest,
	ServiceSuccessResponse,
	Empty,
	FaqRequest,
	FaqSuccessResponse,
	FileDeleteResponse,
	NotificationsReadSuccessResponse,
	NotificationsRequest,
	UploadedFileType,
	UserProfileRequest,
	UserProfileSuccessResponse,
	UserProfileUpdateRequest,
	NotificationsSuccessResponse,
	RegisterDeviceRequest,
	GuideSuccessResponse,
	InstructionsSuccessResponse,
	AppSuccessResponse,
	AppointmentGenderSuccessResponse,
	AppointmentPrimarySlotsRequest,
	AppointmentSlotsSuccessResponse,
	AppointmentPrimaryReserveRequest,
	AppointmentPrimaryReserveSuccessResponse,
	AppointmentGenderRequest,
	AppointmentMakeRequest,
	AppointmentMakeSuccessResponse,
	AppointmentPatientRequest,
	AppointmentPatientSuccessRespoonse,
	AppointmentsRequest,
	AppointmentsSuccessResponse,
	AppointmentCancelRequest,
	AppointmentCancelSuccessResponse,
	AppointmentSecondarySlotsRequest,
	AppointmentSecondaryReserveRequest,
	AppointmentSecondaryReserveSuccessResponse,
	AppointmentSecondaryReserveDeleteRequest,
	AppointmentSecondaryReserveDeleteSuccessResponse,
	AuthLogoutSuccessResponse,
	AppointmentCheckRequest,
	AppointmentCheckSuccess,
	AppointmentPaymentStatusRequest,
	AppointmentPaymentStatusSuccessResponse,
	AppointmentPrimaryScheduleRequest,
	AppointmentPrimaryScheduleResponse,
	AppointmentSecondaryScheduleRequest,
	AppointmentSecondaryScheduleResponse,
	PromocodeRequest,
	GuideRequest,
	InstructionsRequest,
	ServiceReviewsRequest,
	CreateReviewRequest,
	ServiceReviewsSuccessResponse,
	CreateReviewSuccessResponse,
	TestsRequest,
	TestsCategoriesRequest,
	TestRequest,
	TestResultRequest,
	TestsSuccessResponse,
	TestsCategoriesSuccessResponse,
	TestSuccessResponse,
	TestResultSuccessResponse,
	ArticleRequest,
	ArticleByIdRequest,
	ArticleSuccessResponse,
	ArticleLikeRequest,
	ArticleDislikeRequest,
	ArticleRepostRequest,
	ArticleSetViewedRequest,
	ArticleLikeSuccessResponse,
	ArticleDislikeSuccessResponse,
	ArticleRepostSuccessResponse,
	ArticleSetViewedSuccessResponse,
	QuestionRequest,
	QuestionByIdRequest,
	QuestionSuccessResponse,
	AskQuestionRequest,
	AskQuestionSuccessResponse,
	TrackerRequest,
	TrackerSuccessResponse,
	CreateTrackerRequest,
	CreateTrackerSuccessResponse,
	EditTrackerRequest,
	EditTrackerSuccessResponse,
	DeleteTrackerRequest,
	DeleteTrackerSuccessResponse,
	TrackerRatesRequest,
	TrackerRatesSuccessResponse,
	TrackerSettingsRequest,
	TrackerSettingsSuccessResponse,
	TrackerStatsRequest,
	TrackerStatsSuccessResponse,
	TrackerSendStatsRequest,
	TrackerSendStatsSuccessResponse,
} from './types'
import { authLogoutSuccessAction } from './store/redux/auth'
import {
	chatFilesUploadDoneAction,
	chatFilesUploadProgressAction,
} from './store/redux/chat'
import {
	conferenceChatFilesUploadDoneAction,
	conferenceChatFilesUploadProgressAction,
} from './store/redux/conference'
import { appointmentPrimaryReserveRequestAction } from './store/redux/appointments'

moment().locale('ru')

type ApiRequest = (
	url: string,
	data: Object,
	axiosConfig?: any
) => Promise<ApiResponse<any>>

type ApiRequestParams = {
	url: string
	payload?: Object
	axiosConfig: {
		data?: Object
		headers: Object
		params?: Object
	}
}

export interface ApiFailure {
	message: string
	code: string
	reason: string
}

export interface ApiSuccessResponse<T> {
	result: T
	success: boolean
}

export type ApiFailureResponse = {
	error: ApiFailure
}

function showAlert(message: string) {
	showMessage({
		type: 'danger',
		message,
		duration: config.api.messageDuration,
	})
}

export class Api {
	private apisauce: ApisauceInstance
	private fixture: ApisauceInstance

	static AUTH = 'auth'
	static CLIENT = 'client'
	static CHAT = 'chat'
	static FAQ = 'faqs'
	static SERVICES = 'services'
	static GUIDES = 'guides'
	static INSTRUCTIONS = 'instructions'
	static APP = 'app'
	static APPOINTMENT = 'appointment'
	static PATIENT = 'patient'
	static PAYMENTS = 'payments'
	static CONFERENCE = 'appointments'
	static PROMOCODE = 'check_coupon'
	static USER = 'user'
	static NOTIFICATIONS = 'notifications'
	static REVIEWS = 'review'
	static TESTS = 'tests'
	static ARTICLES = 'post'
	static QUESTIONS = 'help'
	static TRACKERS = 'tracker'
	static SETTINGS = 'settings'

	constructor(baseURL: string) {
		this.apisauce = create({
			baseURL,
			headers: {
				'Cache-Control': 'no-cache',
			},
			timeout: 60 * 1000,
		})
		this.fixture = fixtureCreate();
	}

	_setAxiosConfig = (config?: { params?: Object; data?: Object; headers?: Object }) => {
		const state: AppState = store.getState()
		const token = state.auth?.token || ''
		const timezone = getTimeZone()
		return {
			headers: {
				Authorization: 'Bearer ' + token,
				'X-TIMEZONE-OFFSET': timezone,
			},
			...(config || {}),
		}
	}

	_setAxiosUploadFileConfig = (config?: { params?: Object; data?: Object; onUploadProgress?: Object }) => {
		const state: AppState = store.getState()
		const token = state.auth?.token || ''
		const timezone = getTimeZone()
		return {
			headers: {
				Authorization: 'Bearer ' + token,
				'X-TIMEZONE-OFFSET': timezone,
				'Content-Type': 'multipart/form-data'
			},
			...(config || {}),
		}
	}

	_get = async (url: string, axiosConfig?: any) => {
		return await this._handleResponse(config.api.useFixture ? this.fixture.get : this.apisauce.get, { url, axiosConfig })
	}

	_put = async (url: string, axiosConfig?: any) => {
		return await this._handleResponse(config.api.useFixture ? this.fixture.put : this.apisauce.put, { url, axiosConfig })
	}

	_patch = async (url: string, axiosConfig?: any) => {
		return await this._handleResponse(config.api.useFixture ? this.fixture.patch : this.apisauce.patch, { url, axiosConfig })
	}

	_post = async (url: string, axiosConfig?: any) => {
		return await this._handleResponse(config.api.useFixture ? this.fixture.post : this.apisauce.post, { url, axiosConfig })
	}

	_delete = async (url: string, axiosConfig?: any) => {
		return await this._handleResponse(config.api.useFixture ? this.fixture.delete : this.apisauce.delete, { url, axiosConfig })
	}

	_handleResponse = async (
		apiRequest: ApiRequest,
		params: ApiRequestParams
	): Promise<ApiResponse<any>> => {
		const { url, axiosConfig } = params
		const res = await apiRequest(url, {}, axiosConfig)
		config.api.showLogs && console.log(res.config?.method?.toUpperCase(), res.status, res.config?.url, { res })
		return this._handleError(res)
	}

	_handleError = (res: ApiResponse<any>) => {
		if (!res.ok) {
			let message = res.data?.error?.reason
			let isShowAlert = true
			switch (res.status) {
				case 400: {
					const error = res.data?.error
					if (error.code == 1001 || error.code == 1002) {
						message = 'Ошибка на сервере, повторите попытку позже.'
					}
					break;
				}
				case 401:
				case 403:
					store.dispatch(authLogoutSuccessAction())
					break;
				case 404:
				case 422: {
					if (res.data?.error?.code === 1501) {
						isShowAlert = false
					}
					break;
				}
				case 449: {
					const state: AppState = store.getState()
					const request = state.appointment.temp_reserve
					store.dispatch(appointmentPrimaryReserveRequestAction(request))
					return res
				}
				default:
					if (res.status >= 500) {
						message = 'Ошибка на сервере, повторите попытку позже'
					}
					if (!message) {
						switch (res.problem) {
							case 'CLIENT_ERROR':
								message = 'Неизвестная ошибка приложения'
								break
							case 'SERVER_ERROR':
								message = 'Неизвестная ошибка сервера'
								break
							case 'TIMEOUT_ERROR':
								message = 'Время ожидания ответа от сервера истекло'
								break
							case 'CONNECTION_ERROR':
								message = 'Сервер недоступен'
								break
							case 'NETWORK_ERROR':
								message = 'Нет доступа к интернету'
								break
							default:
								message = 'Неизвестная ошибка'
						}
						if (!res.data?.error) {
							res.data = {
								error: {
									message
								}
							}
						}
					}
					break;
			}

			isShowAlert && showAlert(message || res.data?.error?.message)
			return res
		}
		return res
	}

	authRequest = async (data: AuthRequest): Promise<Empty<ApiSuccessResponse<AuthSuccessResponse> | ApiFailureResponse>> => {
		const url = Api.AUTH + (!!data.code ? '/confirm' : '')
		return await this._post(url, this._setAxiosConfig({ data }))
	}

	authByEmailRequest = async (data: AuthByEmailRequest): Promise<Empty<ApiSuccessResponse<AuthSuccessResponse> | ApiFailureResponse>> => {
		const url = Api.AUTH + (!data.code ? '/email' : '/email-confirm' )
		return await this._post(url, this._setAxiosConfig({ data }))
	}

	authLogout = async (): Promise<Empty<ApiSuccessResponse<AuthLogoutSuccessResponse> | ApiFailureResponse>> => {
		return await this._post(`${Api.AUTH}/logout`, this._setAxiosConfig())
	}

	userProfileRequest = async (params: UserProfileRequest): Promise<Empty<ApiSuccessResponse<UserProfileSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(`${Api.CLIENT}/profile`, this._setAxiosConfig({ params }))
	}

	userProfileUpdateRequest = async (data: UserProfileUpdateRequest): Promise<Empty<ApiSuccessResponse<UserProfileSuccessResponse> | ApiFailureResponse>> => {
		if (!!data.registered_at) {
			delete data.registered_at
			return await this._patch(`${Api.CLIENT}/profile`, this._setAxiosConfig({ data }))
		} else {
			delete data.registered_at
			return await this._post(`${Api.CLIENT}/register`, this._setAxiosConfig({ data }))
		}
	}

	userProfileDeleteRequest = async (): Promise<Empty<any | ApiFailureResponse>> => {
		return await this._delete(`${Api.CLIENT}/profile`, this._setAxiosConfig())
	}

	notificationsRequest = async (params?: NotificationsRequest): Promise<Empty<NotificationsSuccessResponse | ApiFailureResponse>> => {
		return await this._get(`${Api.CLIENT}/notifications`, this._setAxiosConfig({ params }))
	}

	notificationsReadRequest = async (notification_id: number): Promise<Empty<NotificationsReadSuccessResponse | ApiFailureResponse>> => {
		return await this._patch(`${Api.CLIENT}/notifications/${notification_id}/read`, this._setAxiosConfig())
	}

	chatRequest = async (): Promise<Empty<ChatSuccessResponse | ApiFailureResponse>> => {
		return await this._get(Api.CHAT, this._setAxiosConfig())
	}

	chatMessageRequest = async (params: ChatMessagesRequest): Promise<Empty<ChatMessageSuccessResponse | ApiFailureResponse>> => {
		const { id, page, limit } = params
		return await this._get(`${Api.CHAT}/${id}/messages`, this._setAxiosConfig({ params: { page, limit } }))
	}

	chatSendMessage = async (data: ChatSendMessageRequest): Promise<Empty<ChatSendMessageSuccessResponse | ApiFailureResponse>> => {
		delete data.appended
		const { id, ...newData } = data
		return await this._put(`${Api.CHAT}/${id}/send`, this._setAxiosConfig({ data: newData }))
	}

	chatFileUpload = async (id: number, data: File, idx: Number): Promise<Empty<UploadedFileType>> => {
		const res: any = await this.fileUpload(
			`${Api.CHAT}/${id}/file/upload`,
			data,
			idx,
			chatFilesUploadProgressAction,
			chatFilesUploadDoneAction
		)
		return res
	}

	fileUpload = async (
		url: string,
		file: File,
		index: Number,
		uploadProgressAction: any,
		uploadDoneAction: any
	) => {
		const data = new FormData()
		data.append('file', file)
		const fileId = `${file.name}_${file.size}_${index}`

		const onUploadProgress = (progressEvent: ProgressEvent) => {
			const progress = Math.round((100 * progressEvent.loaded) / progressEvent.total)
			store.dispatch(uploadProgressAction({ id: fileId, progress }))
		}

		try {
			const res = await this._post(`${config.api.url}/${url}`, this._setAxiosUploadFileConfig({ data, onUploadProgress }))
			const resFile = res.data.result.file

			store.dispatch(uploadDoneAction({ id: fileId, file: resFile }))

			return { id: resFile.id, uri: resFile.url }
		} catch (err) {
			console.log('Upload error!', err)
			return {}
		}
	}

	fileUploadCancel = async (uploadId: string) => {
		// Upload.cancelUpload(uploadId)
	}

	chatFileDelete = async (id: number): Promise<Empty<FileDeleteResponse | ApiFailureResponse>> => {
		return await this._delete(`${Api.CHAT}/file/${id}/remove`, this._setAxiosConfig())
	}

	chatRead = async (id: number): Promise<Empty<ApiSuccessResponse<undefined> | ApiFailureResponse>> => {
		return await this._patch(`${Api.CHAT}/${id}/read`, this._setAxiosConfig())
	}

	faqRequest = async (params: FaqRequest): Promise<Empty<ApiSuccessResponse<FaqSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(Api.FAQ, this._setAxiosConfig())
	}

	serviceRequest = async (params?: ServiceRequest): Promise<Empty<ApiSuccessResponse<ServiceSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(Api.SERVICES, this._setAxiosConfig({ params }))
	}

	serviceByIdRequest = async (params: ServiceByIdRequest): Promise<Empty<ApiSuccessResponse<ServiceSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(`${Api.SERVICES}/${params.id}`, this._setAxiosConfig())
	}

	registerDevice = async (data: RegisterDeviceRequest): Promise<Empty<ApiSuccessResponse<undefined> | ApiFailureResponse>> => {
		return await this._patch(`${Api.CLIENT}/device`, this._setAxiosConfig({ data }))
	}

	appGuideRequest = async (params: GuideRequest): Promise<Empty<ApiSuccessResponse<GuideSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(Api.GUIDES, this._setAxiosConfig({ params }))
	}

	appInstructionsRequest = async (params: InstructionsRequest): Promise<Empty<ApiSuccessResponse<InstructionsSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(Api.INSTRUCTIONS, this._setAxiosConfig({ params }))
	}

	appRequest = async (): Promise<Empty<ApiSuccessResponse<AppSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(Api.APP, this._setAxiosConfig())
	}

	appointmentPrimaryScheduleRequest = async (params: AppointmentPrimaryScheduleRequest): Promise<Empty<ApiSuccessResponse<AppointmentPrimaryScheduleResponse> | ApiFailureResponse>> => {
		return await this._get(`${Api.APPOINTMENT}/primary/schedule`, this._setAxiosConfig({ params }))
	}

	appointmentSecondaryScheduleRequest = async (params: AppointmentSecondaryScheduleRequest): Promise<Empty<ApiSuccessResponse<AppointmentSecondaryScheduleResponse> | ApiFailureResponse>> => {
		return await this._get(`${Api.APPOINTMENT}/secondary/schedule`, this._setAxiosConfig({ params }))
	}

	appointmentGenderRequest = async (params: AppointmentGenderRequest): Promise<Empty<ApiSuccessResponse<AppointmentGenderSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(`${Api.APPOINTMENT}/primary/doctors`, this._setAxiosConfig({ params }))
	}

	appointmentPrimarySlotsRequest = async (params: AppointmentPrimarySlotsRequest): Promise<Empty<ApiSuccessResponse<AppointmentSlotsSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(`${Api.APPOINTMENT}/primary/slots`, this._setAxiosConfig({ params }))
	}

	appointmentSecondarySlotsRequest = async (params: AppointmentSecondarySlotsRequest): Promise<Empty<ApiSuccessResponse<AppointmentSlotsSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(`${Api.APPOINTMENT}/secondary/slots`, this._setAxiosConfig({ params }))
	}

	appointmentPrimaryReserveRequest = async (params: AppointmentPrimaryReserveRequest): Promise<Empty<ApiSuccessResponse<AppointmentPrimaryReserveSuccessResponse> | ApiFailureResponse>> => {
		return await this._post(`${Api.APPOINTMENT}/primary/reserve`, this._setAxiosConfig({ params }))
	}

	appointmentSecondaryReserveRequest = async (data: AppointmentSecondaryReserveRequest): Promise<Empty<ApiSuccessResponse<AppointmentSecondaryReserveSuccessResponse> | ApiFailureResponse>> => {
		return await this._post(`${Api.APPOINTMENT}/secondary/reserve`, this._setAxiosConfig({ data }))
	}

	appointmentSecondaryReserveDeleteRequest = async (data: AppointmentSecondaryReserveDeleteRequest): Promise<Empty<ApiSuccessResponse<AppointmentSecondaryReserveDeleteSuccessResponse> | ApiFailureResponse>> => {
		return await this._delete(`${Api.APPOINTMENT}/secondary/reserve`, this._setAxiosConfig({ data }))
	}

	appointmentMakeRequest = async (data: AppointmentMakeRequest): Promise<Empty<ApiSuccessResponse<AppointmentMakeSuccessResponse> | ApiFailureResponse>> => {
		return await this._post(`${Api.APPOINTMENT}/make`, this._setAxiosConfig({ data }))
	}

	appointmentPatientCheck = async (data: AppointmentPatientRequest): Promise<Empty<ApiSuccessResponse<AppointmentPatientSuccessRespoonse> | ApiFailureResponse>> => {
		return await this._post(`${Api.PATIENT}/validate`, this._setAxiosConfig({ data }))
	}

	appointmentsFutureRequest = async (params?: AppointmentsRequest): Promise<Empty<ApiSuccessResponse<AppointmentsSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(`${Api.APPOINTMENT}s/future`, this._setAxiosConfig({ params }))
	}

	appointmentsPastRequest = async (params?: AppointmentsRequest): Promise<Empty<ApiSuccessResponse<AppointmentsSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(`${Api.APPOINTMENT}s/past`, this._setAxiosConfig({ params }))
	}

	appointmentCancelRequest = async (params: AppointmentCancelRequest): Promise<Empty<ApiSuccessResponse<AppointmentCancelSuccessResponse> | ApiFailureResponse>> => {
		return await this._delete(`${Api.APPOINTMENT}s/${params.appointment_id}`, this._setAxiosConfig())
	}

	appointmentPaymentCancelRequest = async (id: number): Promise<Empty<ApiSuccessResponse<null> | ApiFailureResponse>> => {
		return await this._delete(`${Api.PAYMENTS}/${id}`, this._setAxiosConfig())
	}

	appointmentPaymentStatusRequest = async (paymentId: AppointmentPaymentStatusRequest): Promise<Empty<ApiSuccessResponse<AppointmentPaymentStatusSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(`${Api.PAYMENTS}/check/${paymentId}`, this._setAxiosConfig())
	}

	appointmentCheckRequest = async (params: AppointmentCheckRequest): Promise<Empty<ApiSuccessResponse<AppointmentCheckSuccess> | ApiFailureResponse>> => {
		return await this._get(`${Api.PAYMENTS}/${params.paymentId}`, this._setAxiosConfig())
	}

	promocodeCheck = async (data: PromocodeRequest): Promise<Empty<{} | ApiFailureResponse>> => {
		return await this._post(Api.PROMOCODE, this._setAxiosConfig({ data }))
	}

	conferenceChatRequest = async (id: number): Promise<Empty<ChatSuccessResponse | ApiFailureResponse>> => {
		return await this._get(`${Api.CONFERENCE}/${id}/chat`, this._setAxiosConfig())
	}

	conferenceChatFileUpload = async (id: number, data: File, idx: Number): Promise<Empty<UploadedFileType>> => {
		const res: any = await this.fileUpload(
			`${Api.CHAT}/${id}/file/upload`,
			data,
			idx,
			conferenceChatFilesUploadProgressAction,
			conferenceChatFilesUploadDoneAction
		)
		return res
	}

	serviceReviewsRequest = async (params: ServiceReviewsRequest): Promise<Empty<ApiSuccessResponse<ServiceReviewsSuccessResponse> | ApiFailureResponse>> => {
		const { serviceId, ...data } = params
		return await this._get(`${Api.SERVICES}/${serviceId}/reviews`, this._setAxiosConfig({ params: data }))
	}

	createReviewRequest = async (data: CreateReviewRequest): Promise<Empty<ApiSuccessResponse<CreateReviewSuccessResponse> | ApiFailureResponse>> => {
		return await this._post(Api.REVIEWS, this._setAxiosConfig({ data }))
	}

	testsRequest = async (params?: TestsRequest): Promise<Empty<ApiSuccessResponse<TestsSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(`${Api.TESTS}/all`, this._setAxiosConfig({ params }))
	}

	testsCategoriesRequest = async (params?: TestsCategoriesRequest): Promise<Empty<ApiSuccessResponse<TestsCategoriesSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(Api.TESTS, this._setAxiosConfig({ params }))
	}

	testRequest = async (params: TestRequest): Promise<Empty<ApiSuccessResponse<TestSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(`${Api.TESTS}/${params.id}`, this._setAxiosConfig({ params }))
	}

	testResultRequest = async (data: TestResultRequest): Promise<Empty<ApiSuccessResponse<TestResultSuccessResponse> | ApiFailureResponse>> => {
		return await this._post(`${Api.TESTS}/calculate`, this._setAxiosConfig({ data }))
	}

	articleRequest = async (params?: ArticleRequest): Promise<Empty<ApiSuccessResponse<ArticleSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(Api.ARTICLES, this._setAxiosConfig({ params }))
	}

	articleByIdRequest = async (params: ArticleByIdRequest): Promise<Empty<ApiSuccessResponse<ArticleSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(`${Api.ARTICLES}/${params.id}`, this._setAxiosConfig())
	}

	articleLikeRequest = async (data: ArticleLikeRequest): Promise<Empty<ApiSuccessResponse<ArticleLikeSuccessResponse> | ApiFailureResponse>> => {
		return await this._post(`${Api.ARTICLES}/${data.id}/like`, this._setAxiosConfig({ data }))
	}

	articleDislikeRequest = async (data: ArticleDislikeRequest): Promise<Empty<ApiSuccessResponse<ArticleDislikeSuccessResponse> | ApiFailureResponse>> => {
		return await this._post(`${Api.ARTICLES}/${data.id}/dislike`, this._setAxiosConfig({ data }))
	}

	articleRepostRequest = async (data: ArticleRepostRequest): Promise<Empty<ApiSuccessResponse<ArticleRepostSuccessResponse> | ApiFailureResponse>> => {
		return await this._post(`${Api.ARTICLES}/${data.id}/repost`, this._setAxiosConfig({ data }))
	}

	articleSetViewedRequest = async (data: ArticleSetViewedRequest): Promise<Empty<ApiSuccessResponse<ArticleSetViewedSuccessResponse> | ApiFailureResponse>> => {
		return await this._post(`${Api.ARTICLES}/${data.id}/view`, this._setAxiosConfig({ data }))
	}

	questionRequest = async (params?: QuestionRequest): Promise<Empty<ApiSuccessResponse<QuestionSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(Api.QUESTIONS, this._setAxiosConfig({ params }))
	}

	userQuestionsRequest = async (params?: QuestionRequest): Promise<Empty<ApiSuccessResponse<QuestionSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(`${Api.QUESTIONS}/my`, this._setAxiosConfig({ params }))
	}

	questionByIdRequest = async (params: QuestionByIdRequest): Promise<Empty<ApiSuccessResponse<QuestionSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(`${Api.QUESTIONS}/${params.id}`, this._setAxiosConfig())
	}

	askQuestionRequest = async (data: AskQuestionRequest): Promise<Empty<ApiSuccessResponse<AskQuestionSuccessResponse> | ApiFailureResponse>> => {
		return await this._post(Api.QUESTIONS, this._setAxiosConfig({ data }))
	}

	trackerRequest = async (params?: TrackerRequest): Promise<Empty<ApiSuccessResponse<TrackerSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(Api.TRACKERS, this._setAxiosConfig({ params }))
	}

	createTrackerRequest = async (data: CreateTrackerRequest): Promise<Empty<ApiSuccessResponse<CreateTrackerSuccessResponse> | ApiFailureResponse>> => {
		return await this._post(Api.TRACKERS, this._setAxiosConfig({ data }))
	}

	editTrackerRequest = async (data: EditTrackerRequest): Promise<Empty<ApiSuccessResponse<EditTrackerSuccessResponse> | ApiFailureResponse>> => {
		return await this._post(`${Api.TRACKERS}/${data.id}`, this._setAxiosConfig({ data }))
	}

	deleteTrackerRequest = async (data: DeleteTrackerRequest): Promise<Empty<ApiSuccessResponse<DeleteTrackerSuccessResponse> | ApiFailureResponse>> => {
		return await this._delete(`${Api.TRACKERS}/${data.id}`, this._setAxiosConfig({ data }))
	}

	trackerRatesRequest = async (params?: TrackerRatesRequest): Promise<Empty<ApiSuccessResponse<TrackerRatesSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(`${Api.TRACKERS}/rates`, this._setAxiosConfig({ params }))
	}

	trackerSettingsRequest = async (params?: TrackerSettingsRequest): Promise<Empty<ApiSuccessResponse<TrackerSettingsSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(Api.SETTINGS, this._setAxiosConfig({ params }))
	}

	trackerStatsRequest = async (params: TrackerStatsRequest): Promise<Empty<ApiSuccessResponse<TrackerStatsSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(`${Api.TRACKERS}/${params.id}/stats`, this._setAxiosConfig({ params }))
	}

	trackerSendStatsRequest = async (data: TrackerSendStatsRequest): Promise<Empty<ApiSuccessResponse<TrackerSendStatsSuccessResponse> | ApiFailureResponse>> => {
		return await this._post(`${Api.TRACKERS}/${data.id}/stats`, this._setAxiosConfig({ data }))
	}
}

export const api = new Api(config.api.url)
