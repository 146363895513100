import React from 'react'
import { View, Dimensions, StyleSheet } from 'react-native'
import { connect } from 'react-redux'
import { StackScreenProps } from '@react-navigation/stack'

import { AppState } from '../store/redux'
import { Screen, Chat } from '../components'
import {
	chatDeleteFileRequestAction,
	chatFilesUploadRequestAction,
	chatMessageRequestAction,
	chatReadAction,
	chatSendMessageRequestAction,
} from '../store/redux/chat'
import {
	MainNavigatorProps,
	PickedFileType,
	UploadFileType,
} from '../types'
import { ThemeColors, useTheme } from '../theme'

type ScreenProps = DispatchProps & StateProps & StackScreenProps<MainNavigatorProps, 'SupportChatScreen'>

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

interface Props extends ScreenProps {
	colors: ThemeColors
}

interface State {
	screenWidth: number
}

function SupportChatScreenWrapper(props: Props) {
  const { colors } = useTheme()
  return <SupportChatScreen colors={colors} {...props} />
}

class SupportChatScreen extends React.Component<Props, State>{
	dimensionsSubscription: any
	state = { screenWidth: window.innerWidth }

	componentDidMount() {
		this.readChat()
		this.dimensionsSubscription = Dimensions.addEventListener("change", this.onChange)
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		this.readChat()
	}

	componentWillUnmount() {
		this.dimensionsSubscription?.remove()
	}

	onChange = ({ window }: any) => {
    this.setState({ screenWidth: window.width })
  }

	render() {
		const { screenWidth } = this.state
		const isScreenLess635 = screenWidth < 635

		const {
			messages,
			client_id,
			files,
			fetching,
			colors,
		} = this.props
		return (
			<Screen>
				<View style={[styles.container, {
					width: !isScreenLess635 ? '47.22%' : '100%',
					minWidth: !isScreenLess635 ? 635 : 'auto',
				}]}>
					<View style={[styles.content, {
						backgroundColor: colors.background.primary,
					}]}>
						<Chat
							type='support'
							client_id={client_id}
							files={files}
							onDeleteFilePress={this.onDeleteFilePress}
							sendMessage={this.sendMessage}
							onAddFiles={this.onAddFiles}
							onEndReached={this.onEndReached}
							fetching={fetching}
							messages={messages}
						/>
					</View>
				</View>
			</Screen>
		)
	}

	onDeleteFilePress = (params: UploadFileType) => {
		const { deleteFile } = this.props
		deleteFile(params.id)
	}

	onAddFiles = (params: PickedFileType[]) => {
		const { addFiles, chat_id } = this.props
		addFiles({ id: chat_id, files: params })
	}

	onEndReached = () => {
		const {
			page,
			limit,
			total,
			fetching,
			getMessages,
			chat_id,
		} = this.props
		if (!fetching && page * limit < total) {
			getMessages({ id: chat_id, page: page + 1 })
		}
	}

	sendMessage = ({ message, files, appended }) => {
		const { sendMessage, chat_id } = this.props
		sendMessage({ id: chat_id, message, files, appended })
	}

	readChat = () => {
		const { unread, chatRead, chat_id } = this.props
		!!unread && chatRead(chat_id)
	}
}

const mapStateToProps = (state: AppState) => ({
	client_id: state.chat.chat?.client._id || 0,
	messages: state.chat.messages,
	page: state.chat.page,
	limit: state.chat.limit,
	total: state.chat.total,
	fetching: state.chat.messages_fetching,
	files: state.chat.files,
	unread: state.chat.unread,
	chat_id: state.chat.chat?.id || 0,
})

const mapDispatchToProps = {
	sendMessage: chatSendMessageRequestAction,
	getMessages: chatMessageRequestAction,
	addFiles: chatFilesUploadRequestAction,
	deleteFile: chatDeleteFileRequestAction,
	chatRead: chatReadAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(SupportChatScreenWrapper)

const styles = StyleSheet.create({
	container: {
		flexGrow: 1,
		marginVertical: 30,
		paddingTop: 12,
		paddingHorizontal: 20,
		alignSelf: 'center',
	},
	content: {
		flex: 1,
		borderRadius: 10,
		overflow: 'hidden',
	},
})
