import React from 'react'
import { connect } from 'react-redux'
import { AppState } from '../store/redux'
import { StackScreenProps } from '@react-navigation/stack'
import {
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
  Image,
} from 'react-native'
import { Badge, Icon, Screen, ConferenceChat } from '../components'
import Conference from '../components/ConferenceWeb'
import { font, ThemeColors, useTheme } from '../theme'

import { conferenceCancelCallAction } from '../store/redux/conference'
import { getFullName } from '../service'
import SocketService from '../services/SocketService'

import { logo_mini } from '../images'

type ScreenProps = DispatchProps & StateProps & StackScreenProps<any>

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

interface Props extends ScreenProps {
  colors: ThemeColors
}

interface State {
  isChatVisible: boolean,
}

function ConferenceWebScreenWrapper(props: Props) {
  const { colors } = useTheme()
  return <ConferenceWebScreen colors={colors} {...props} />
}

class ConferenceWebScreen extends React.Component<Props, State>{
  constructor (props) {
    super(props)

    this.state = {
      isChatVisible: false,
    }
  }

  componentWillMount() {
    const { token, doctor, navigation } = this.props

    if (!token && !doctor) {
      navigation.replace('MainStack') || navigation.replace('AuthStack')
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { token, navigation } = this.props
    if (!!token && prevProps.token !== token) {
      navigation.replace('ConferenceDoneScreen')
    }
  }

  componentWillUnmount(): void {
    this.props.onLeaveSession()
  }

  render() {
    const {
      colors,
      token,
      doctor,
      unreadMessagesCount,
    } = this.props

    const { isChatVisible } = this.state

    let doctorName = getFullName(doctor?.lastname, doctor?.firstname, doctor?.middlename)
    const nameArr = doctorName?.split(' ') || []
    if (nameArr.length > 2) {
      doctorName = nameArr.slice(0, nameArr.length - 1).join(' ') + `\n${nameArr[nameArr.length - 1]}`
    }

    const conferenseMargins = !isChatVisible
      ? { marginHorizontal: 30 }
      : { marginLeft: 20, marginRight: 13 }
    const chatMargins = !isChatVisible
      ? { marginHorizontal: 30 }
      : { marginRight: 20, marginLeft: 13 }

    return (
      <Screen hideHeader>
        <View style={styles.contentContainer}>
          <TouchableOpacity
            style={styles.logoButton}
            activeOpacity={1}
            onPress={this.goToMainPage}
          >
            <Image
              style={styles.logo}
              source={logo_mini}
              resizeMode={'contain'}
            />
          </TouchableOpacity>
          <View style={{
            flex: 1,
            flexDirection: 'row',
          }}>
            <View style={[styles.container, conferenseMargins, { backgroundColor: colors.background.default }]}>
              <View style={styles.contentWrapper}>
                <View style={styles.topContainer}>
                  <Text style={[styles.doctorName, { color: colors.text.selection }]}>
                    {doctorName}
                  </Text>
                  <TouchableOpacity
                    style={[styles.chatButton, { backgroundColor: isChatVisible ? colors.icon.default : colors.icon.videoController.inactive.background }]}
                    activeOpacity={1}
                    onPress={this.onChatPress}
                  >
                    <Icon name={'chat'} color={colors.icon.videoController.inactive.color} />
                    <Badge count={unreadMessagesCount} style={styles.badge} />
                  </TouchableOpacity>
                </View>
                <Conference token={token} onLeaveSession={this.leaveSession} />
              </View>
            </View>
            { isChatVisible && (
              <View style={[styles.container, chatMargins, { backgroundColor: colors.background.primary }]}>
                <ConferenceChat />
              </View>
            )}
          </View>
        </View>
      </Screen>
    )
  }

  onChatPress = () => {
    this.setState({
      isChatVisible: !this.state.isChatVisible,
    })
  }

  leaveSession = () => {
    SocketService.socketDeclineCall()
    this.props.navigation.replace('ConferenceDoneScreen')
  }

  goToMainPage = () => {
    SocketService.socketDeclineCall()
    this.props.navigation.replace('MainStack')
  }
}

const mapStateToProps = (state: AppState) => ({
  token: state.conference.session?.token,
  doctor: state.conference.session?.doctor,
  unreadMessagesCount: state.conference.unread,
})

const mapDispatchToProps = {
  onLeaveSession: conferenceCancelCallAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConferenceWebScreenWrapper)

const styles = {
  contentContainer: {
    flex: 1,
  } as ViewStyle,
  container: {
    flex: 1,
    marginBottom: 20,
    borderRadius: 20,
    overflow: 'hidden',
  },
  contentWrapper: {
    flex: 1,
    alignItems: 'center',
  },
  topContainer: {
    zIndex: 1,
    height: 60,
    width: '100%',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(180deg, #000 0%, transparent 100%)',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  } as ViewStyle,
  doctorName: {
    fontFamily: font(),
    fontSize: 16,
  } as TextStyle,
  chatButton: {
    borderRadius: 5,
    padding: 8,
    overflow: 'hidden',
    position: 'absolute',
    right: 16,
  } as ViewStyle,
  badge: {
    top: 3,
    right: 9,
    alignSelf: 'flex-end'
  } as ViewStyle,
  logoButton: {
		alignSelf: 'center',
	},
  logo: {
		alignSelf: 'center',
		marginVertical: 20,
		width: 112,
		height: 15,
	},
}
