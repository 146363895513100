import React, { useState, useEffect, useRef } from 'react'
import {
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  Image,
  Dimensions,
  ScrollView,
} from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native'
import { useSelector, useDispatch } from 'react-redux'

import { Button, Icon, Modal, Screen } from '../components'
import { t } from '../localization'
import { AppState } from '../store'
import { font, useTheme } from '../theme'
import { TestQuestionType, TestAnswerType } from '../types'
import {
  testResultRequestAction,
  testResultClearAction,
  testsCategoriesRequestAction,
  testsRequestAction,
} from '../store/redux/tests'
import { usePrevious } from '../hooks/usePrevious'

export default function TestScreen() {
  const { colors } = useTheme()
  const navigation = useNavigation()
  const route = useRoute()
  const dispatch = useDispatch()

  const fromScreen = route.params?.from
  const id = route.params?.id

  const bgColors = {
    default: colors.background.input,
    correct: colors.background.correct,
    incorrect: colors.background.incorrect,
  }
  const textColors = {
    default: colors.text.default,
    correct: colors.text.correct,
    incorrect: colors.text.incorrect,
  }

  const timer = useRef(null)

  const test = useSelector((state: AppState) => state.test.test)

  const [screenData, setScreenData] = useState(Dimensions.get('window'))
  const [isCloseModalVisible, setIsCloseModalVisible] = useState(false)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [currentQuestion, setCurrentQuestion] = useState<TestQuestionType>(test.questions[0])
  const [answers, setAnswers] = useState<TestAnswerType[]>([])
  let preparedUserAllAnswers = {}
  test.questions.forEach((question: TestQuestionType) => {
    preparedUserAllAnswers = {
      ...preparedUserAllAnswers,
      [question.id]: [],
    }
  })
  const [userAllAnswers, setUserAllAnswers] = useState(preparedUserAllAnswers)
  const [isQuestionAnswered, setIsQuestionAnswered] = useState(false)

  const result = useSelector((state: AppState) => state.test.testResult)
  const resultFetching = useSelector((state: AppState) => state.test.testResultFetching)

  const previousResult = usePrevious(result)

  useEffect(() => {
    const onChange = (result) => {
      setScreenData(result.window)
    }

    const subscription = Dimensions.addEventListener('change', onChange)

		return () => subscription?.remove()
  })

  useEffect(() => {
    if (!test || +id !== test.id) {
      navigation.navigate('MainStack', { screen: 'MainScreen' })
    }

    return () => {
      dispatch(testResultClearAction())
    }
  }, [])

  useEffect(() => {
    const currentQuestionAnswers = currentQuestion.answers.map(answer => ({
      ...answer,
      checked: false,
      type: 'default',
    }))
    setAnswers(currentQuestionAnswers)
  }, [currentQuestion])

  useEffect(() => {
    if (isQuestionAnswered) {
      if ((!test.show_comment || (!currentQuestion.correct_text && !currentQuestion.correct_image))
        && (!currentQuestion.is_multiple || currentQuestion.search_correct)) {
        timer.current = setTimeout(() => {
          setIsQuestionAnswered(false)
          if (currentQuestionIndex + 1 < test.questions.length) {
            setCurrentQuestionIndex(currentQuestionIndex + 1)
            setCurrentQuestion(test.questions[currentQuestionIndex + 1])
          } else {
            dispatch(testResultRequestAction({
              test_id: test.id,
              answers: userAllAnswers,
            }))
          }
        }, 1000)
      }
    }
    return () => {
      clearTimeout(timer.current)
    }
  }, [isQuestionAnswered])

  useEffect(() => {
    if (previousResult && !result) {
      if (fromScreen === 'profile') {
        navigation.navigate('MainStack', { screen: 'ProfileScreen' })
      } else if (fromScreen === 'testList') {
        navigation.navigate('TestStack', { screen: 'TestListScreen' })
      } else {
        navigation.navigate('MainStack', { screen: 'MainScreen' })
      }
    }
  }, [result])

  const onPressClose = () => {
    setIsCloseModalVisible(true)
  }

  const renderProgressBar = () => {
    const progress = ~~((currentQuestionIndex + 1) * 100 / test.questions.length)
    return (
      <View style={[styles.progressContainer, { backgroundColor: colors.background.secondary }]}>
        <View style={[styles.progress, {
          backgroundColor: colors.background.progress,
          width: `${progress}%`,
        }]} />
      </View>
    )
  }

  const onPressAnswer = (id: number) => {
    let cntRightAnswersFinded = 0
    let cntAnswered = 0
    let cntRightAnswers = 0
    let answerId = 0
    let updatedAnswers = answers.map((answer, index) => {
      if (index === id) {
        answerId = answer.id
      }

      let type = 'default'
      if (!currentQuestion.search_correct) {
        if (!currentQuestion.is_multiple) {
          // NOTE: один вариант + нет поиска верного
          type = index === id ? 'correct' : answer.type || 'default'
        } else {
          // NOTE: несколько вариантов + нет поиска верного
          type = answer.type === 'default'
            ? index === id ? 'correct' : answer.type || 'default'
            : index === id ? 'default' : answer.type || 'default'
        }
      } else {
        if (!currentQuestion.is_multiple) {
          // NOTE: один вариант + с поиском верного
          type = index === id
            ? answer.is_correct ? 'correct' : 'incorrect'
            : answer.is_correct ? 'correct' : answer.type || 'default'
        } else {
          // NOTE: несколько вариантов + с поиском верного
          type = index === id
            ? answer.is_correct ? 'correct' : 'incorrect'
            : answer.type || 'default'
        }
      }

      if (answer.is_correct) {
        cntRightAnswers++
      }
      if (type !== 'default' && answer.is_correct) {
        cntRightAnswersFinded++
      }

      if (type !== 'default') {
        cntAnswered++
      }

      return {
        ...answer,
        checked: index === id ? !answer.checked : answer.checked,
        type,
      }
    })
    setAnswers(updatedAnswers)
    if (cntAnswered > 0) {
      if (!currentQuestion.is_multiple || !currentQuestion.search_correct || cntRightAnswersFinded === cntRightAnswers) {
        setIsQuestionAnswered(true)
      }
    } else {
      setIsQuestionAnswered(false)
    }

    let userAnswers = new Set(userAllAnswers[currentQuestion.id])
    if (userAnswers.has(answerId)) {
      userAnswers.delete(answerId)
    } else {
      userAnswers.add(answerId)
    }

    const updatedUserAllAnswers = {
      ...userAllAnswers,
      [currentQuestion.id]: [...userAnswers],
    }
    setUserAllAnswers(updatedUserAllAnswers)
  }

  const onPressNext = () => {
    setIsQuestionAnswered(false)
    if (currentQuestionIndex + 1 < test.questions.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1)
      setCurrentQuestion(test.questions[currentQuestionIndex + 1])
    } else {
      dispatch(testResultRequestAction({
        test_id: test.id,
        answers: userAllAnswers,
      }))
    }
  }

  const renderQuestion = () => {
    const currentQuestion = test.questions[currentQuestionIndex]

    return (
      <ScrollView
        contentContainerStyle={styles.questionContainer}
        bounces={false}
        showsVerticalScrollIndicator={false}
      >
        <View style={[styles.titleWrapper, {
          marginRight: test.show_progress ? 48 : 0,
          marginTop: !test.show_progress ? 16 : 0,
        }]}>
          <Text style={[styles.title, { color: colors.text.default }]}>
            {test.show_progress && (
              <Text style={[styles.number, { color: colors.text.title}]}>{`${currentQuestionIndex + 1}  `}</Text>
            )}
            {t('question')}
          </Text>
        </View>
        {!!currentQuestion.image && (
          <Image
            style={styles.image}
            source={{ uri: currentQuestion.image }}
            resizeMode='contain'
          />
        )}
        <Text style={[styles.question, { color: colors.text.default }]}>{currentQuestion.title}</Text>
        {
          answers.map((answer, index) => (
            <Button
              key={`${index}`}
              style={[
                styles.answerButton,
                { backgroundColor: bgColors[answer.type] },
              ]}
              textStyle={[styles.answerButtonText, { color: textColors[answer.type] }]}
              text={answer.title}
              onPress={() => onPressAnswer(index)}
              disabled={isQuestionAnswered && !currentQuestion.is_multiple || (currentQuestion.is_multiple && currentQuestion.search_correct && answer.type !== 'default')}
            />
          ))
        }
        {test.show_comment && isQuestionAnswered
          && (!currentQuestion.is_multiple || currentQuestion.search_correct)
          && currentQuestion.correct_image && (
          <Image
            style={[styles.image, { marginBottom: 10 }]}
            resizeMode='contain'
            source={{ uri: currentQuestion.correct_image }}
          />
        )}
        {test.show_comment && isQuestionAnswered
          && (!currentQuestion.is_multiple || currentQuestion.search_correct)
          && currentQuestion.correct_text && (
          <Text style={{
            fontFamily: font(),
            fontSize: 16,
            marginBottom: 10,
            color: colors.text.default,
          }}>{currentQuestion.correct_text}</Text>
        )}
        {(test.show_comment && (currentQuestion.correct_text || currentQuestion.correct_image) || (currentQuestion.is_multiple && !currentQuestion.search_correct)) && (
          <Button
            style={styles.nextButton}
            text={t('next')}
            onPress={onPressNext}
            disabled={!isQuestionAnswered}
          />
        )}
      </ScrollView>
    )
  }

  const onCancel = () => {
    setIsCloseModalVisible(false)
  }

  const onConfirm = () => {
    setIsCloseModalVisible(false)
    if (fromScreen === 'profile') {
      navigation.navigate('MainStack', { screen: 'ProfileScreen' })
    } else if (fromScreen === 'testList') {
      navigation.navigate('TestStack', { screen: 'TestListScreen' })
    } else {
      navigation.navigate('MainStack', { screen: 'MainScreen' })
    }
  }

  const onPressFinish = () => {
    dispatch(testResultClearAction())
    dispatch(testsCategoriesRequestAction())
    dispatch(testsRequestAction())
  }

  const isSmallScreen = screenData.width < 635

  if (result || (previousResult && !result)) {
    const resultImage = result?.image || previousResult?.image
    const hasImage = !!resultImage
    const resultTitle = result?.title || previousResult?.title
    const resultDescription = result?.description || previousResult?.description
    return (
      <Screen borderless>
        <ScrollView>
          <View style={[
            styles.container,
            {
              width: !isSmallScreen ? '55%' : '100%',
              minWidth: !isSmallScreen ? 635 : 'auto',
            },
          ]}>
            <View style={[styles.content, { backgroundColor: colors.background.primary }]}>
              {hasImage && (
                <Image
                  style={styles.image}
                  resizeMode='contain'
                  source={{ uri: resultImage }}
                />
              )}
              <Text style={{
                fontFamily: font(),
                fontSize: 18,
                color: colors.text.title,
                marginBottom: 10,
                textAlign: 'center',
              }}>{t('test_congratulations', [test.title])}</Text>
              <Text style={{
                fontFamily: font('bold'),
                fontSize: 14,
                color: colors.text.default,
                marginBottom: 10,
              }}>{resultTitle}</Text>
              <Text style={{
                fontFamily: font(),
                fontSize: 14,
                color: colors.text.default,
                marginBottom: 20,
              }}>{resultDescription}</Text>
              <Button
                text={t('complete')}
                onPress={onPressFinish}
              />
            </View>
          </View>
        </ScrollView>
      </Screen>
    )
  }

  return (
    <Screen borderless>
      <View style={[
        styles.container,
        {
          width: !isSmallScreen ? '55%' : '100%',
          minWidth: !isSmallScreen ? 635 : 'auto',
        },
      ]}>
        <View style={[styles.content, { backgroundColor: colors.background.primary }]}>
          {resultFetching ? (
            <View style={styles.activityIndicator}>
              <ActivityIndicator color={colors.fetching} size='large' />
            </View>
          ) : (
            <>
              <View style={styles.closeIcon}>
                <Icon
                  name={'close_circle'}
                  size={30}
                  color={colors.closeIcon.icon}
                  backgroundColor={colors.closeIcon.backgroundColor}
                  onPress={onPressClose}
                />
              </View>
              { test.show_progress && renderProgressBar() }
              { test.questions.length > 0 && renderQuestion() }
            </>
          )}
        </View>
        {/* TODO: правильно рассчитать высоту */}
        <View style={{ flex: 1 }}></View>
      </View>
      <Modal
        isVisible={isCloseModalVisible}
        onClose={onCancel}
        position='center'
        width={375}
      >
        <View style={styles.modalCloseContainer}>
          <Text style={[styles.modalTitle, { color: colors.text.title }]}>{t('test_close')}</Text>
          <Text style={[styles.modalContent, { color: colors.text.secondary }]}>{t('test_close_description')}</Text>
          <View style={styles.modalButtonContainer}>
            <Button
              text={t('yes')}
              style={styles.modalButton}
              type={'secondary'}
              onPress={onConfirm}
            />
            <Button
              text={t('no')}
              style={styles.modalButton}
              onPress={onCancel}
            />
          </View>
        </View>
      </Modal>
    </Screen>
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    marginTop: 26,
    marginBottom: 40,
    paddingHorizontal: 20,
    paddingBottom: 17,
    alignSelf: 'center',
  },
  content: {
    borderRadius: 20,
    padding: 16,
  },
  progressContainer: {
    marginTop: 12,
    marginRight: 46,
    height: 6,
    borderRadius: 3,
    marginBottom: 30,
  },
  progress: {
    height: 6,
    borderRadius: 3,
  },
  questionContainer: {
    paddingBottom: 16,
  },
  titleWrapper: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  title: {
    fontFamily: font('bold'),
    fontSize: 16,
  },
  number: {
    fontFamily: font('bold'),
    fontSize: 16,
  },
  question: {
    fontFamily: font(),
    fontSize: 16,
    marginBottom: 10,
  },
  answerButton: {
    minHeight: 50,
    marginBottom: 10,
  },
  answerButtonText: {
    fontFamily: font(),
    fontSize: 16,
  },
  nextButton: {
    marginTop: 10,
  },
  image: {
    flex: 1,
    height: 205,
    marginBottom: 5,
  },

  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    zIndex: 1,
  },
  modalCloseContainer: {
    flex: 1,
    paddingHorizontal: 16,
  },
  modalTitle: {
    fontFamily: font('bold'),
    fontSize: 20,
    lineHeight: 24,
    marginBottom: 14,
  },
  modalContent: {
    fontFamily: font(),
    fontSize: 16,
  },
  modalButtonContainer: {
    flex: 1,
    marginTop: 24,
    flexDirection: 'row',
  },
  modalButton: {
    flex: 1,
    marginHorizontal: 5,
  },

  activityIndicator: {
		position: 'absolute',
		flex: 1,
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 1,
	},
})