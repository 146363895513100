import { put, call, select } from 'redux-saga/effects'
import Cookies from 'universal-cookie'
import { ApiResponse } from 'apisauce'

import { authSetTokenAction } from '../redux/auth'
import {
	AppSuccessResponse,
	Nullable,
} from '../../types'
import I18n, { Localizations } from '../../localization'
import { AppState } from '..'
import { api, ApiSuccessResponse } from '../../api'
import { appInitializedAction } from '../redux/_app'
import {
	appRequestAction,
	appSuccessAction,
	appFailureAction,
} from '../redux/app'

const getCredentials = (service: string) => {
	const cookies = new Cookies()
	return cookies.get(service)
}

const getLocale = (store: AppState): Localizations => store.app.locale

export function* appStartSaga() {
	yield put(appRequestAction())

	I18n.locale = yield select(getLocale)
	const token: Nullable<string> = getCredentials('token')

	if (!!token) {
		yield put(authSetTokenAction({ token }))
	} else {
		yield put(appInitializedAction())
	}
}

export function* appRequestSaga() {
	const response: ApiResponse<ApiSuccessResponse<AppSuccessResponse>> = yield call(api.appRequest)
	if (response.ok) {
		yield put(appSuccessAction(response.data!.result))
	} else {
		yield put(appFailureAction(response.data))
	}
}
