import { put, call } from 'redux-saga/effects'
import { ApiResponse } from 'apisauce'

import { api, ApiSuccessResponse } from '../../api'
import {
	Action,
	QuestionRequest,
	QuestionByIdRequest,
	QuestionSuccessResponse,
	QuestionByIdSuccessResponse,
	AskQuestionRequest,
} from '../../types'
import {
	questionFailureAction,
	questionSuccessAction,
	userQuestionsFailureAction,
	userQuestionsSuccessAction,
	questionByIdFailureAction,
	questionByIdSuccessAction,
	askQuestionSuccessAction,
	askQuestionFailureAction,
} from '../redux/questions'
import { userProfileUpdateAskLimitsAction } from '../redux/user'

export function* questionRequestSaga(action: Action<QuestionRequest>) {
	const response: ApiResponse<ApiSuccessResponse<QuestionSuccessResponse>> = yield call(api.questionRequest, action.params)
	if (response.ok) {
		yield put(questionSuccessAction(response.data?.result))
	} else {
		yield put(questionFailureAction(response.data))
	}
}

export function* userQuestionsRequestSaga(action: Action<QuestionRequest>) {
	const response: ApiResponse<ApiSuccessResponse<QuestionSuccessResponse>> = yield call(api.userQuestionsRequest, action.params)
	if (response.ok) {
		yield put(userQuestionsSuccessAction(response.data?.result))
	} else {
		yield put(userQuestionsFailureAction(response.data))
	}
}

export function* questionByIdRequestSaga(action: Action<QuestionByIdRequest>) {
	const response: ApiResponse<ApiSuccessResponse<QuestionByIdSuccessResponse>> = yield call(api.questionByIdRequest, action.params)
	if (response.ok) {
		yield put(questionByIdSuccessAction(response.data?.result.question))
	} else {
		yield put(questionByIdFailureAction(response.data))
	}
}

export function* askQuestionRequestSaga(action: Action<AskQuestionRequest>) {
	const response: ApiResponse<ApiSuccessResponse<QuestionByIdSuccessResponse>> = yield call(api.askQuestionRequest, action.params)
	if (response.ok) {
		yield put(askQuestionSuccessAction(response.data?.result))
		yield put(userProfileUpdateAskLimitsAction(response.data?.result))
	} else {
		yield put(askQuestionFailureAction(response.data))
	}
}
