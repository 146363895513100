/**
 * @format
 */

import {AppRegistry} from 'react-native'
import TagManager from 'react-gtm-module'

import App from './App'
import * as appInfo from './app.json'
import config from './src/config'

AppRegistry.registerComponent(appInfo.name, () => App)

const tagManagerArgs = {
  gtmId: config.gtmId
}

!config.isDev && TagManager.initialize(tagManagerArgs)

AppRegistry.runApplication(appInfo.name, {
  rootTag: document.getElementById('root')
})
