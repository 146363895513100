import { createStore, applyMiddleware, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer } from 'redux-persist'
import storage from '@react-native-community/async-storage'

import AppReducer from '../redux/app'
import rootSaga from '../saga'
import AuthReducer from './auth'
import NotificationsReducer from './notifications'
import UserReducer from './user'
import AppointmentReducer from './appointments'
import FaqReducer from './faq'
import ServiceReducer from './services'
import ChatReducer from './chat'
import ConferenceReducer from './conference'
import _appReducer from './_app'
import PromocodeReducer from './promocode'
import PaymentReducer from './payment'
import ReviewReducer from './reviews'
import TestReducer from './tests'
import ArticleReducer from './articles'
import QuestionReducer from './questions'
import TrackerReducer from './trackers'

const rootReducer = combineReducers({
  _app: _appReducer,
  app: AppReducer,
  auth: AuthReducer,
  notifications: NotificationsReducer,
  user: UserReducer,
  appointment: AppointmentReducer,
  faq: FaqReducer,
  service: ServiceReducer,
  chat: ChatReducer,
  conference: ConferenceReducer,
  promocode: PromocodeReducer,
  payment: PaymentReducer,
  review: ReviewReducer,
  test: TestReducer,
  article: ArticleReducer,
  question: QuestionReducer,
  tracker: TrackerReducer,
})

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    '_app',
    'auth',
    'user',
    'notifications',
    'chat',
    'appointment',
    'conference',
    'review',
  ],
}

const composeEnhancers = composeWithDevTools({})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware()

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(sagaMiddleware)))

const persistor = persistStore(store, null, () => {
  store.getState()
})

sagaMiddleware.run(rootSaga)

export type AppState = ReturnType<typeof rootReducer>

export { persistor, store, storage }
