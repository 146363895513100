import {
	ApiFailure,
	ApiFailureResponse,
	Nullable,
	ArticleSuccessResponse,
	ArticleType,
	ArticleRequest,
	ArticleByIdRequest,
	ArticleLikeRequest,
	ArticleDislikeRequest,
	ArticleRepostRequest,
	ArticleSetViewedRequest,
} from '../../types'

export interface ArticleState {
	articles: Nullable<ArticleType[]>
	viewed_articles_ids: number[]
	fetching: boolean
	error: Nullable<ApiFailure>
	page: Nullable<number>
	limit: Nullable<number>
	total: Nullable<number>
	articleById: Nullable<ArticleType>
	articleByIdFetching: boolean
	articleByIdError: Nullable<ApiFailure>
	articleLikeFetching: boolean
	articleLikeError: Nullable<ApiFailure>
	articleDislikeFetching: boolean
	articleDislikeError: Nullable<ApiFailure>
	articleRepostFetching: boolean
	articleRepostError: Nullable<ApiFailure>
	articleSetViewedFetching: boolean
	articleSetViewedError: Nullable<ApiFailure>
}

export const ARTICLE_REQUEST = 'ARTICLE_REQUEST'
export const ARTICLE_SUCCESS = 'ARTICLE_SUCCESS'
export const ARTICLE_FAILURE = 'ARTICLE_FAILURE'
export const ARTICLE_BY_ID_REQUEST = 'ARTICLE_BY_ID_REQUEST'
export const ARTICLE_BY_ID_SUCCESS = 'ARTICLE_BY_ID_SUCCESS'
export const ARTICLE_BY_ID_FAILURE = 'ARTICLE_BY_ID_FAILURE'
export const ARTICLE_LIKE_REQUEST = 'ARTICLE_LIKE_REQUEST'
export const ARTICLE_LIKE_SUCCESS = 'ARTICLE_LIKE_SUCCESS'
export const ARTICLE_LIKE_FAILURE = 'ARTICLE_LIKE_FAILURE'
export const ARTICLE_DISLIKE_REQUEST = 'ARTICLE_DISLIKE_REQUEST'
export const ARTICLE_DISLIKE_SUCCESS = 'ARTICLE_DISLIKE_SUCCESS'
export const ARTICLE_DISLIKE_FAILURE = 'ARTICLE_DISLIKE_FAILURE'
export const ARTICLE_REPOST_REQUEST = 'ARTICLE_REPOST_REQUEST'
export const ARTICLE_REPOST_SUCCESS = 'ARTICLE_REPOST_SUCCESS'
export const ARTICLE_REPOST_FAILURE = 'ARTICLE_REPOST_FAILURE'
export const ARTICLE_SET_VIEWED_REQUEST = 'ARTICLE_SET_VIEWED_REQUEST'
export const ARTICLE_SET_VIEWED_SUCCESS = 'ARTICLE_SET_VIEWED_SUCCESS'
export const ARTICLE_SET_VIEWED_FAILURE = 'ARTICLE_SET_VIEWED_FAILURE'

export const articleRequestAction = (params?: ArticleRequest) => ({ type: ARTICLE_REQUEST, params })
export const articleSuccessAction = (payload) => ({ type: ARTICLE_SUCCESS, payload })
export const articleFailureAction = (payload) => ({ type: ARTICLE_FAILURE, payload })

export const articleByIdRequestAction = (params?: ArticleByIdRequest) => ({ type: ARTICLE_BY_ID_REQUEST, params })
export const articleByIdSuccessAction = (payload) => ({ type: ARTICLE_BY_ID_SUCCESS, payload })
export const articleByIdFailureAction = (payload) => ({ type: ARTICLE_BY_ID_FAILURE, payload })

export const articleLikeRequestAction = (params?: ArticleLikeRequest) => ({ type: ARTICLE_LIKE_REQUEST, params })
export const articleLikeSuccessAction = (payload) => ({ type: ARTICLE_LIKE_SUCCESS, payload })
export const articleLikeFailureAction = (payload) => ({ type: ARTICLE_LIKE_FAILURE, payload })

export const articleDislikeRequestAction = (params?: ArticleDislikeRequest) => ({ type: ARTICLE_DISLIKE_REQUEST, params })
export const articleDislikeSuccessAction = (payload) => ({ type: ARTICLE_DISLIKE_SUCCESS, payload })
export const articleDislikeFailureAction = (payload) => ({ type: ARTICLE_DISLIKE_FAILURE, payload })

export const articleRepostRequestAction = (params?: ArticleRepostRequest) => ({ type: ARTICLE_REPOST_REQUEST, params })
export const articleRepostSuccessAction = (payload) => ({ type: ARTICLE_REPOST_SUCCESS, payload })
export const articleRepostFailureAction = (payload) => ({ type: ARTICLE_REPOST_FAILURE, payload })

export const articleSetViewedRequestAction = (params?: ArticleSetViewedRequest) => ({ type: ARTICLE_SET_VIEWED_REQUEST, params })
export const articleSetViewedSuccessAction = (payload) => ({ type: ARTICLE_SET_VIEWED_SUCCESS, payload })
export const articleSetViewedFailureAction = (payload) => ({ type: ARTICLE_SET_VIEWED_FAILURE, payload })

const initialState: ArticleState = {
	articles: null,
	viewed_articles_ids: [],
	fetching: false,
	error: null,
	page: null,
	limit: null,
	total: null,
	articleById: null,
	articleByIdFetching: false,
	articleByIdError: null,
	articleLikeFetching: false,
	articleLikeError: null,
	articleDislikeFetching: false,
	articleDislikeError: null,
	articleRepostFetching: false,
	articleRepostError: null,
	articleSetViewedFetching: false,
	articleSetViewedError: null,
}

const ArticleReducer = (state = initialState, action: any): ArticleState => {
	const { type, payload } = action
	const error = payload?.error

	switch (type) {
		case ARTICLE_REQUEST: {
			return { ...state, error: null, fetching: true }
		}
		case ARTICLE_SUCCESS: {
			const { posts, page, limit, total } = payload as ArticleSuccessResponse
			const _articles = page === 1 ? posts : [...(state.articles || []), ...posts]
			return { ...state, fetching: false, articles: _articles, page, limit, total }
		}
		case ARTICLE_FAILURE: {
			return { ...state, fetching: false, error }
		}
		case ARTICLE_BY_ID_REQUEST:
			return { ...state, articleByIdError: null, articleByIdFetching: true }
		case ARTICLE_BY_ID_SUCCESS:
			return { ...state, articleByIdFetching: false, articleById: payload }
		case ARTICLE_BY_ID_FAILURE:
			return { ...state, articleByIdFetching: false, articleByIdError: error }
		case ARTICLE_LIKE_REQUEST:
			return { ...state, articleLikeError: null, articleLikeFetching: true }
		case ARTICLE_LIKE_SUCCESS:
			return {
				...state,
				articleLikeFetching: false,
				articleById: payload,
				articles: state.articles ? state.articles.map(article => {
					if (article.id === payload.id) return payload
					return article
				}) : null
			}
		case ARTICLE_LIKE_FAILURE:
			return { ...state, articleLikeFetching: false, articleLikeError: error }
		case ARTICLE_DISLIKE_REQUEST:
			return { ...state, articleDislikeError: null, articleDislikeFetching: true }
		case ARTICLE_DISLIKE_SUCCESS:
			return {
				...state,
				articleDislikeFetching: false,
				articleById: payload,
				articles: state.articles ? state.articles.map(article => {
					if (article.id === payload.id) return payload
					return article
				}) : null
			}
		case ARTICLE_DISLIKE_FAILURE:
			return { ...state, articleDislikeFetching: false, articleDislikeError: error }
		case ARTICLE_REPOST_REQUEST:
			return { ...state, articleRepostError: null, articleRepostFetching: true }
		case ARTICLE_REPOST_SUCCESS:
			return {
				...state,
				articleRepostFetching: false,
				articleById: payload,
				articles: state.articles ? state.articles.map(article => {
					if (article.id === payload.id) return payload
					return article
				}) : null
			}
		case ARTICLE_REPOST_FAILURE:
			return { ...state, articleRepostFetching: false, articleRepostError: error }
		case ARTICLE_SET_VIEWED_REQUEST:
				return { ...state, articleSetViewedError: null, articleSetViewedFetching: true }
		case ARTICLE_SET_VIEWED_SUCCESS:
			const viewedArtclesIds = new Set(state.viewed_articles_ids);
			viewedArtclesIds.add(payload.id);
			return {
				...state,
				articleSetViewedFetching: false,
				articleSetViewedError: null,
				articleById: payload,
				articles: state.articles!.map(article => {
					if (article.id === payload.id) return payload
					return article
				}),
				viewed_articles_ids: [...viewedArtclesIds],
			}
		case ARTICLE_SET_VIEWED_FAILURE:
			return { ...state, articleRepostFetching: false, articleRepostError: error }
		default:
			return state
	}
}

export default ArticleReducer
