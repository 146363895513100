import React from 'react'
import { Linking, useColorScheme, View, ActivityIndicator, StyleSheet } from 'react-native'
import { createStackNavigator } from '@react-navigation/stack'
import {
	LinkingOptions,
	NavigationContainer,
	getStateFromPath,
	getPathFromState,
	getFocusedRouteNameFromRoute,
} from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { toLower } from 'lodash'

import {
	AuthScreen,
	AuthByEmailScreen,
	MainScreen,
	NotificationsScreen,
	NotificationScreen,
	CodeConfirmationScreen,
	CodeConfirmationByEmailScreen,
	ProfileScreen,
	FaqScreen,
	SupportChatScreen,
	AppointmentListScreen,
	NewAppointmentScreen,
	NewAppointmentGenderScreen,
	NewAppointmentDatetimeScreen,
	NewAppointmentConfirmScreen,
	GuideScreen,
	InstructionsScreen,
	AboutAppScreen,
	AideraScreen,
	DocumentScreen,
	IncomingCallScreen,
	PaymentScreen,
	ConferenceWebScreen,
	// ConferenceScreen,
	ConferenceChatScreen,
	ConferenceDoneScreen,
	NewAppointmentDoctorScreen,
	NotFoundScreen,
	TestListScreen,
	TestScreen,
	ArticleListScreen,
	ArticleScreen,
	QuestionListScreen,
	QuestionScreen,
	TrackerListScreen,
	AddTrackerScreen,
	TrackerScreen,
} from './screens'
import { AppState } from './store'
import { Alert } from './components'
import { ThemesList, useTheme } from './theme'
import {
	MainNavigatorProps,
	AppointmentNavigatorProps,
	CallNavigatorProps,
	TestNavigatorProps,
	ArticleNavigatorProps,
	QuestionNavigatorProps,
	TrackerNavigatorProps,
 } from './types'
import { t } from './localization'
import config from './config'

const RootNavigator = createStackNavigator()
const MainNavigator = createStackNavigator<MainNavigatorProps>()
const AuthNavigator = createStackNavigator()
const AppointmentNavigator = createStackNavigator<AppointmentNavigatorProps>()
const CallNavigator = createStackNavigator<CallNavigatorProps>()
const TestNavigator = createStackNavigator<TestNavigatorProps>()
const ArticleNavigator = createStackNavigator<ArticleNavigatorProps>()
const QuestionNavigator = createStackNavigator<QuestionNavigatorProps>()
const TrackerNavigator = createStackNavigator<TrackerNavigatorProps>()

// const navigationRef = React.createRef()

// export function navigate(name: string, params?: any) {
// 	// @ts-ignore
// 	navigationRef.current?.navigate(name, params)
// }

const defaultTitleText = t('AIDERA')

const MainStack = ({ route }) => (
	<MainNavigator.Navigator
		initialRouteName="MainScreen"
		screenOptions={{
			gestureEnabled: getGestureEnabled(route),
			headerShown: false,
			cardStyle: { flex: 1 },
		}}
	>
		<MainNavigator.Screen
			name="MainScreen"
			component={MainScreen}
			listeners={resetParams}
		/>
		<MainNavigator.Screen
			name="FaqScreen"
			component={FaqScreen}
			listeners={resetParams}
			// options={{ title: t('faq') }}
		/>
		<MainNavigator.Screen
			name="SupportChatScreen"
			component={SupportChatScreen}
			listeners={resetParams}
			// options={{ title: t('feedback') }}
		/>
		<MainNavigator.Screen
			name="DoctorChatScreen"
			component={ConferenceChatScreen}
			listeners={resetParams}
			// options={{ title: t('doctor_chat_web') }}
		/>
		<MainNavigator.Screen
			name="NotificationsScreen"
			component={NotificationsScreen}
			listeners={resetParams}
			// options={{ title: t('notifications') }}
		/>
		<MainNavigator.Screen
			name="ProfileScreen"
			component={ProfileScreen}
			listeners={resetParams}
			// options={{ title: t('profile') }}
		/>
		<MainNavigator.Screen
			name="AppointmentListScreen"
			component={AppointmentListScreen}
			listeners={resetParams}
			// options={{ title: t('appointment') }}
		/>
		<MainNavigator.Screen
			name="InstructionsScreen"
			component={InstructionsScreen}
			listeners={resetParams}
			// options={{ title: t('instruction') }}
		/>
		<MainNavigator.Screen
			name="AboutAppScreen"
			component={AboutAppScreen}
			listeners={resetParams}
			// options={{ title: t('about_app_web') }}
		/>
		<MainNavigator.Screen
			name="AideraScreen"
			component={AideraScreen}
			listeners={resetParams}
		/>
		<MainNavigator.Screen
			name="DocumentScreen"
			component={DocumentScreen}
			listeners={resetParams}
		/>
		<MainNavigator.Screen
			name="QuestionStack"
			component={QuestionStack}
			listeners={resetParams}
		/>
		<MainNavigator.Screen
			name="TrackerStack"
			component={TrackerStack}
			listeners={resetParams}
		/>
	</MainNavigator.Navigator>
)

const AuthStack = ({ route }: any) => (
	<AuthNavigator.Navigator
		initialRouteName='AuthScreen'
		screenOptions={{
			gestureEnabled: getGestureEnabled(route),
			headerShown: false,
			cardStyle: { flex: 1 },
		}}
	>
		<AuthNavigator.Screen
			name="AuthScreen"
			component={AuthScreen}
			listeners={resetParams}
			// options={{ title: t('auth_web') }}
		/>
		<AuthNavigator.Screen
			name="AuthByEmailScreen"
			component={AuthByEmailScreen}
			listeners={resetParams}
			// options={{ title: t('auth_web') }}
		/>
		<AuthNavigator.Screen
			name="CodeConfirmationScreen"
			component={CodeConfirmationScreen}
			listeners={resetParams}
			// options={{ title: t('auth_web') }}
		/>
		<AuthNavigator.Screen
			name="CodeConfirmationByEmailScreen"
			component={CodeConfirmationByEmailScreen}
			listeners={resetParams}
			// options={{ title: t('auth_web') }}
		/>
		<AuthNavigator.Screen
			name="DocumentScreen"
			component={DocumentScreen}
			listeners={resetParams}
		/>
	</AuthNavigator.Navigator>
)

const AppointmentStack = ({ route }: any) => (
	<AppointmentNavigator.Navigator
		// initialRouteName='NewAppointmentScreen'
		screenOptions={{
			gestureEnabled: getGestureEnabled(route),
			headerShown: false,
			cardStyle: { flex: 1 },
		}}
	>
		<AppointmentNavigator.Screen
			name="NewAppointmentScreen"
			component={NewAppointmentScreen}
			listeners={resetParams}
			// options={{ title: t('service_appointment') }}
		/>
		<AppointmentNavigator.Screen
			name="NewAppointmentGenderScreen"
			component={NewAppointmentGenderScreen}
			listeners={resetParams}
			// options={{ title: t('service_appointment') }}
		/>
		<AppointmentNavigator.Screen
			name="NewAppointmentDatetimeScreen"
			component={NewAppointmentDatetimeScreen}
			listeners={resetParams}
			// options={{ title: t('service_appointment') }}
		/>
		<AppointmentNavigator.Screen
			name="NewAppointmentDoctorScreen"
			component={NewAppointmentDoctorScreen}
			listeners={resetParams}
			// options={{ title: t('service_appointment') }}
		/>
		<AppointmentNavigator.Screen
			name="NewAppointmentConfirmScreen"
			component={NewAppointmentConfirmScreen}
			listeners={resetParams}
			// options={{ title: t('service_appointment') }}
		/>
		<AppointmentNavigator.Screen
			name="PaymentScreen"
			component={PaymentScreen}
			listeners={resetParams}
			// options={{ title: t('service_appointment') }}
		/>
		<AppointmentNavigator.Screen
			name="DocumentScreen"
			component={DocumentScreen}
			listeners={resetParams}
		/>
	</AppointmentNavigator.Navigator>
)

const CallStack = ({ route }: any) => (
	<CallNavigator.Navigator
		initialRouteName='IncomingCallScreen'
		screenOptions={{
			gestureEnabled: getGestureEnabled(route),
			headerShown: false,
			cardStyle: { flex: 1 },
		}}
	>
		<CallNavigator.Screen
			name="IncomingCallScreen"
			component={IncomingCallScreen}
			listeners={resetParams}
		/>
		<CallNavigator.Screen
			name="ConferenceScreen"
			component={ConferenceWebScreen}
			listeners={resetParams}
		/>
		{/* <CallNavigator.Screen
			name="ConferenceChatScreen"
			component={ConferenceChatScreen}
			listeners={resetParams}
		/> */}
		<CallNavigator.Screen
			name="ConferenceDoneScreen"
			component={ConferenceDoneScreen}
			listeners={resetParams}
		/>
	</CallNavigator.Navigator>
)

const TestStack = ({ route }: any) => (
	<TestNavigator.Navigator
		initialRouteName={'TestListScreen'}
		screenOptions={{
			gestureEnabled: getGestureEnabled(route),
			headerShown: false,
			cardStyle: { flex: 1 },
		}}
	>
		<TestNavigator.Screen
			name="TestListScreen"
			component={TestListScreen}
			listeners={resetParams}
		/>
		<TestNavigator.Screen
			name="TestScreen"
			component={TestScreen}
			listeners={resetParams}
		/>
	</TestNavigator.Navigator>
)

const ArticleStack = ({ route }: any) => (
	<ArticleNavigator.Navigator
		initialRouteName={'ArticleListScreen'}
		screenOptions={{
			gestureEnabled: getGestureEnabled(route),
			headerShown: false,
			cardStyle: { flex: 1 },
		}}
	>
		<ArticleNavigator.Screen
			name="ArticleListScreen"
			component={ArticleListScreen}
			listeners={resetParams}
		/>
		<ArticleNavigator.Screen
			name="ArticleScreen"
			component={ArticleScreen}
			listeners={resetParams}
		/>
	</ArticleNavigator.Navigator>
)

const QuestionStack = ({ route }: any) => (
	<QuestionNavigator.Navigator
		initialRouteName={'QuestionListScreen'}
		screenOptions={{
			gestureEnabled: getGestureEnabled(route),
			headerShown: false,
			cardStyle: { flex: 1 },
		}}
	>
		<QuestionNavigator.Screen
			name="QuestionListScreen"
			component={QuestionListScreen}
			listeners={resetParams}
		/>
		<QuestionNavigator.Screen
			name="QuestionScreen"
			component={QuestionScreen}
			listeners={resetParams}
		/>
	</QuestionNavigator.Navigator>
)

const TrackerStack = ({ route }: any) => (
	<TrackerNavigator.Navigator
		initialRouteName={'TrackerListScreen'}
		screenOptions={{
			gestureEnabled: getGestureEnabled(route),
			headerShown: false,
			cardStyle: { flex: 1 },
		}}
	>
		<TrackerNavigator.Screen
			name="TrackerListScreen"
			component={TrackerListScreen}
			listeners={resetParams}
		/>
		<TrackerNavigator.Screen
			name="AddTrackerScreen"
			component={AddTrackerScreen}
			listeners={resetParams}
		/>
		<TrackerNavigator.Screen
			name="TrackerScreen"
			component={TrackerScreen}
			listeners={resetParams}
		/>
	</TrackerNavigator.Navigator>
)

export default function AppMainNavigator() {
	const { colors } = useTheme()
	const deviceScheme = useColorScheme()

	const profile = useSelector((state: AppState) => state.user.profile)
	const { token } = useSelector((state: AppState) => state.auth)
	const { show_guide, theme, deviceTheme, fetching } = useSelector((state: AppState) => state.app)

	if (fetching) {
		return (
			<View style={styles.activityIndicator} >
				<ActivityIndicator color={colors.fetching} size="large" />
			</View>
		)
	}

	return (
		<NavigationContainer
			theme={ThemesList[deviceTheme ? deviceScheme : theme]}
			linking={linking}
			// @ts-ignore
			// ref={navigationRef}
			documentTitle={{
				formatter: (options, route) => {
					return options?.title
						? `${defaultTitleText} | ${options?.title}`
						: defaultTitleText
				}
			}}
		>
			<Alert />
			<RootNavigator.Navigator screenOptions={{
				animationEnabled: false,
				headerShown: false,
				cardStyle: { flex: 1 },
			}}>
				{!!token && !!profile
					? !!profile?.registered_at
						? (<>
							<RootNavigator.Screen name="MainStack" component={MainStack} />
							<RootNavigator.Screen
								name="AppointmentStack"
								component={AppointmentStack}
							/>
							<RootNavigator.Screen
								name="TestStack"
								component={TestStack}
							/>
						</>)
						: (
							<RootNavigator.Screen
								name="ProfileScreen"
								component={ProfileScreen}
								// options={{ title: t('profile') }}
							/>
						)
					: show_guide && false
						? <RootNavigator.Screen name="GuideScreen" component={GuideScreen} />
						: <RootNavigator.Screen name="AuthStack" component={AuthStack} />
				}
				<RootNavigator.Screen name="CallStack" component={CallStack} />
				<RootNavigator.Screen
					name="NotificationScreen"
					component={NotificationScreen}
					listeners={resetParams}
					// options={{ title: t('notification') }}
				/>
				<RootNavigator.Screen
					name="ArticleStack"
					component={ArticleStack}
				/>
				<RootNavigator.Screen
					name="NotFoundScreen"
					component={NotFoundScreen}
					// options={{ title: t('not_found') }}
				/>
			</RootNavigator.Navigator>
		</NavigationContainer >
	)
}

const styles = StyleSheet.create({
	activityIndicator: {
		position: 'absolute',
		flex: 1,
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 1,
	}
})

const getLinkingPathOptions = (): Pick<LinkingOptions, 'getStateFromPath' | 'getPathFromState'> => {
	let currentUrl = ''

	return {
		getStateFromPath: (path, config) => {
			currentUrl = path

			return getStateFromPath(currentUrl, config)
		},
		getPathFromState: (state, config) => {
			const path = getPathFromState(state, config)

			return path === '/NotFoundScreen' ? currentUrl : path
		}
	}
}

const linking = {
	prefixes: [config.web_url, 'aidera://'],
	config: {
		screens: {
			MainStack: {
				screens: {
					MainScreen: '',
					FaqScreen: 'faq',
					SupportChatScreen: 'support',
					DoctorChatScreen: 'chat/:id',
					NotificationsScreen: 'notifications',
					ProfileScreen: 'profile',
					AppointmentListScreen: {
						path: 'appointments',
						stringify: {
							type: (type: string) => toLower(type),
						},
					},
					InstructionsScreen: 'instructions',
					AboutAppScreen: 'about',
					AideraScreen: 'about/aidera',
					DocumentScreen: {
						path: 'about/doc/:document?',
						stringify: {
							document: () => '',
						},
					},
					QuestionStack: {
						path: 'questions',
						initialRouteName: 'QuestionListScreen',
						screens: {
							QuestionListScreen: '',
							QuestionScreen: {
								path: ':id',
								parse: {
									id: Number,
								},
							},
						},
					},
					TrackerStack: {
						path: 'trackers',
						initialRouteName: 'TrackerListScreen',
						screens: {
							TrackerListScreen: '',
							TrackerScreen: {
								path: ':id',
								parse: {
									id: Number,
								},
							},
							AddTrackerScreen: {
								path: 'add',
							},
						},
					},
				},
			},
			AppointmentStack: {
				path: 'appointment/new',
				// initialRouteName: 'NewAppointmentScreen',
				screens: {
					NewAppointmentScreen: '',
					NewAppointmentGenderScreen: 'gender',
					NewAppointmentDatetimeScreen: 'date',
					NewAppointmentDoctorScreen: 'doctor',
					NewAppointmentConfirmScreen: 'confirm',
					PaymentScreen: {
						path: '/checkappontment/:payment_id',
						exact: true,
					},
					DocumentScreen: {
						path: '/doc/:document?',
						stringify: {
							document: () => '',
						},
					},
				},
			},
			AuthStack: {
				path: 'auth',
				initialRouteName: 'AuthScreen',
				screens: {
					AuthScreen: '',
					AuthByEmailScreen: 'email',
					CodeConfirmationScreen: 'code-confirmation',
					CodeConfirmationByEmailScreen: 'code-confirmation-email',
					DocumentScreen: {
						path: 'doc/:type?/:document?/:title?',
						exact: true,
						stringify: {
							type: () => '',
							document: () => '',
							title: () => '',
						},
					},
				},
			},
			CallStack: {
				screens: {
					IncomingCallScreen: 'incoming-call',
					ConferenceScreen: 'conference',
					// ConferenceChatScreen: 'conference/chat',
					ConferenceDoneScreen: 'conference/done',
				},
			},
			NotificationScreen: {
				path: 'notifications/:notification_id',
			},
			GuideScreen: 'guide',
			ProfileScreen: 'profile/new',
			TestStack: {
				screens: {
					TestListScreen: 'tests',
					TestScreen: {
						path: 'tests/:id/:from?',
						stringify: {
							from: () => '',
						},
					},
				},
			},
			ArticleStack: {
				path: 'articles',
				initialRouteName: 'ArticleListScreen',
				screens: {
					ArticleListScreen: '',
					ArticleScreen: {
						path: ':id',
						parse: {
							id: Number,
						},
					},
				},
			},
			NotFoundScreen: '*',
		},
	},
	subscribe(listener) {
		const onReceiveURL = ({ url }: { url: string }) => listener(url)
		const urlListener = Linking.addEventListener('url', onReceiveURL)
		return () => {
			urlListener.remove()
		}
	},
	...getLinkingPathOptions(),
} as LinkingOptions

const resetParams = ({ navigation }) => {
	return {
		blur: () => navigation.setParams({ screen: undefined })
	}
}

const getGestureEnabled = (route) => getFocusedRouteNameFromRoute(route) !== 'PaymentScreen'
