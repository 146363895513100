import React from 'react'
import {
	FlatList,
	StyleSheet,
	Dimensions,
	View,
	Image,
	Text,
} from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { connect } from 'react-redux'
import moment from 'moment'
import 'moment/locale/ru'

moment().locale('ru')

import { Screen, ArticleCard } from '../components'
import { MainNavigatorProps } from '../types'
import { AppState } from '../store/redux'
import { articleRequestAction } from '../store/redux/articles'
import { appointmentFailure } from '../images'
import { t } from '../localization'
import { font, ThemeColors, useTheme } from '../theme'

type ScreenProps = DispatchProps & StateProps & StackScreenProps<MainNavigatorProps, 'ArticleStack'>

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

interface Props extends ScreenProps {
	colors: ThemeColors,
}

interface State {
	screenWidth: number
}

function ArticleListScreenWrapper(props: Props) {
	const { colors } = useTheme()
	return <ArticleListScreen colors={colors} {...props} />
}

class ArticleListScreen extends React.Component<Props, State>{
	dimensionsSubscription: any

	constructor(props: Props) {
		super(props)
		this.state = {
			screenWidth: window.innerWidth,
		}
	}

	componentDidMount() {
		this.props.getArticles()
		this.dimensionsSubscription = Dimensions.addEventListener("change", this.onChange)
	}

	componentWillUnmount() {
		this.dimensionsSubscription?.remove()
	}

	onChange = ({ window }: any) => {
		this.setState({ screenWidth: window.width })
	}

	render() {
		const { articles, fetching, colors } = this.props
		const { screenWidth } = this.state
		const isScreenLess635 = screenWidth < 635

		return (
			<Screen>
				<FlatList
					data={articles}
					renderItem={({ item }) => (
						<ArticleCard article={item} />
					)}
					keyExtractor={(item, index) => index.toString()}
					contentContainerStyle={[styles.container, {
						width: !isScreenLess635 ? '47.22%' : '100%',
						minWidth: !isScreenLess635 ? 635 : 'auto',
					}]}
					refreshing={fetching}
					onRefresh={this.onRefresh}
					onEndReached={() => this.getPagination()}
					onEndReachedThreshold={0.5}
					ListEmptyComponent={() => {
						return (
							<View style={styles.emptyContainer}>
								<Image
									source={appointmentFailure}
									style={styles.emptyImage}
								/>
								<Text style={[
									styles.emptyText, {
									color: colors.text.title,
								}]}>{t('articles_empty')}</Text>
							</View>
						)
					}}
				/>
			</Screen>
		)
	}

	onRefresh = () => {
		this.getPagination(1)
	}

	getPagination = (_page?: number) => {
		const {
			page = 0,
			limit = 0,
			total = 0,
			fetching,
			getArticles,
		} = this.props
		if (!fetching && (!!_page || page * limit < total)) {
			getArticles({ page: _page || (page + 1) })
		}
	}
}

const mapStateToProps = (state: AppState) => ({
	articles: state.article.articles || [],
	page: state.article.page,
	total: state.article.total,
	limit: state.article.limit,
	fetching: state.article.fetching,
})

const mapDispatchToProps = {
	getArticles: articleRequestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleListScreenWrapper)

const styles = StyleSheet.create({
	container: {
		flexGrow: 1,
		alignItems: 'stretch',
		alignSelf: 'center',
		marginBottom: 40,
		paddingHorizontal: 20,
		marginTop: 25,
	},
	emptyContainer: {
		flex: 1,
		justifyContent: 'center',
	},
	emptyImage: {
		width: 314,
		height: 243,
		alignSelf: 'center',
	},
	emptyText: {
		textAlign: 'center',
		marginTop: 10,
		fontFamily: font('bold'),
		fontSize: 24,
	},
})
