import React, { ReactElement } from 'react'
import {
  ScrollView,
  StyleSheet,
  Text,
  View,
  ViewStyle,
  TouchableWithoutFeedback,
} from 'react-native'
import ReactNativeModal from 'react-native-modal'
import { font, useMetrics, useTheme } from '../theme'
import Icon, { IconsType } from './Icon'

interface ModalProps {
  style?: ViewStyle | ViewStyle[]
  isVisible: boolean
  children: ReactElement<any, any> | ReactElement<any, any>[]
  footer?: ReactElement<any, any> | ReactElement<any, any>[]
  onClose: () => void
  closeButton?: boolean
  closeIcon?: IconsType
  iconColor?: string
  header?: string
  onModalHide?: () => void
  position?: 'bottom' | 'center'
  width?: string | number
  scrollableContent?: boolean
}

export default function ({
  style,
  isVisible,
  onClose,
  children,
  closeButton = true,
  header,
  footer,
  onModalHide,
  position = 'bottom',
  width,
  scrollableContent = true,
}: ModalProps) {
  const { colors } = useTheme()

  const { responsiveHeight, responsiveWidth } = useMetrics()

  const isSmallScreen = responsiveWidth < 725

  let containerStyle = position === 'bottom'
    ? [styles.container, {
      // marginTop: responsiveHeight * 0.2,
    }]
    : styles.centerContainer

  return (

    <ReactNativeModal
      isVisible={isVisible}
      customBackdrop={<CustomBackDrop onClose={onClose} />}
      style={[containerStyle, { width: width ? '100%' : 'auto' }]}
      onModalHide={onModalHide}
      avoidKeyboard
    >
      <View style={[
        style,
        styles.modalContent, {
          backgroundColor: colors.background.primary,
          maxHeight: responsiveHeight - 60,
          maxWidth: position === 'center' && !isSmallScreen ? 1024 : '100%',
          width: width && position === 'center' ? width : 'auto',
          borderBottomLeftRadius: position === 'center' ? 20 : 0,
          borderBottomRightRadius: position === 'center' ? 20 : 0,
        }
      ]}>
        <View style={styles.closeIcon}>
          <Icon
            name={'close_circle'}
            size={30}
            color={colors.closeIcon.icon}
            backgroundColor={colors.closeIcon.backgroundColor}
            onPress={onClose}
            isVisible={closeButton}
          />
        </View>
        {!!header && (
          <Text style={styles.header}>
            {header}
          </Text>
        )}
        { scrollableContent ? (
          <ScrollView bounces={false}>
            {children}
          </ScrollView>
        ) : (
          <View style={styles.contentContainerStyle}>
            {children}
          </View>
        )}
        {footer}
      </View>
    </ReactNativeModal>
  )
}

function CustomBackDrop({ onClose }) {
  const { colors } = useTheme()

  return (
    <TouchableWithoutFeedback onPress={onClose}>
      <View style={[styles.backDrop, { backgroundColor: colors.shadow }]}/>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 0,
    justifyContent: 'flex-end',
  },
  centerContainer: {
    margin: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    overflow: 'hidden',
    minWidth: 375,
    paddingVertical: 20,
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    zIndex: 1,
  },
  contentContainerStyle: {
    flex: 1,
  },
  header: {
    fontFamily: font('bold'),
    fontSize: 24,
    lineHeight: 29,
    marginLeft: 16,
    marginTop: 12,
    marginBottom: 4,
    marginRight: 40,
  },
  backDrop: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
})
