import { put, call, delay, select } from 'redux-saga/effects'
import { api, ApiSuccessResponse } from '../../api'
import {
	Action,
	NotificationsRequest,
	NotificationsSuccessResponse,
	NotificationsReadSuccessResponse,
} from '../../types';
import { ApiResponse } from 'apisauce';
import { notificationsFailureAction, notificationsReadFailureAction, notificationsReadSuccessAction, notificationsSuccessAction } from '../redux/notifications';

export function* notificationsRequestSaga(action: Action<NotificationsRequest>) {
	const response: ApiResponse<ApiSuccessResponse<NotificationsSuccessResponse>> = yield call(api.notificationsRequest, action.params)
	if (response.ok) {
		yield put(notificationsSuccessAction(response.data.result))
	} else {
		yield put(notificationsFailureAction(response.data))
	}
}

export function* notificationsReadRequestSaga(action: Action<number>) {
	const response: ApiResponse<ApiSuccessResponse<NotificationsReadSuccessResponse>> = yield call(api.notificationsReadRequest, action.params)
	if (response.ok) {
		yield put(notificationsReadSuccessAction(response.data.result))
	} else {
		yield put(notificationsReadFailureAction(response.data))
	}
}
