import React from 'react'
import { connect } from 'react-redux'
import { AppState } from '../store/redux'
import { StackScreenProps } from '@react-navigation/stack'
import {
	Image,
	ImageStyle,
	// Linking,
	ScrollView,
	Text,
	TextStyle,
	ViewStyle,
	Dimensions,
} from 'react-native'
import { Menu, MenuRowType, Screen } from '../components'
import { t } from '../localization'
import { logo } from '../images'
// import DeviceInfo from 'react-native-device-info'
import { font, ThemeColors, useTheme } from '../theme'
// import { MainNavigatorProps, MarketType, StoresType } from '../types'
import { MainNavigatorProps } from '../types'
// import { getStoreName } from '../service'
import config from '../../src/config'

type ScreenProps = DispatchProps & StateProps & StackScreenProps<MainNavigatorProps, 'AboutAppScreen'>

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

interface Props extends ScreenProps {
	colors: ThemeColors
}

interface State {
	screenWidth: number
}

function AboutAppScreenWrapper(props: Props) {
	const { colors } = useTheme()
	return <AboutAppScreen colors={colors} {...props} />
}

class AboutAppScreen extends React.Component<Props, State>{
	dimensionsSubscription: any
	state = { screenWidth: window.innerWidth }

	componentDidMount() {
		this.dimensionsSubscription = Dimensions.addEventListener("change", this.onChange)
	}

	componentWillUnmount() {
		this.dimensionsSubscription?.remove()
	}

	onChange = ({ window }: any) => {
		this.setState({ screenWidth: window.width })
	}

	render() {
		const { colors } = this.props
		const app_version = config.web_version
		const { screenWidth } = this.state
		const isScreenLess595 = screenWidth < 595

		return (
			<Screen>
				<ScrollView
					bounces={false}
					contentContainerStyle={styles.contentContainer}
				>
					<Image
						style={styles.logo}
						source={logo}
						resizeMode={'contain'}
					/>
					<Text style={[styles.version, { color: colors.text.primary }]}>
						{`версия ${app_version}`}
					</Text>
					<Menu
						data={this.getMenu()}
						style={[
							styles.menu,
							{
								backgroundColor: colors.background.primary,
								width: !isScreenLess595 ? '47.22%' : '100%',
								minWidth: !isScreenLess595 ? 595 : 'auto',
							}
						]}
						bottomBorders
					/>
				</ScrollView>
			</Screen>
		)
	}

	getMenu = () => {
		const { documents, navigation } = this.props
		const menu: MenuRowType[] = [
			{ text: t('AIDERA'), onPress: () => navigation.navigate('AideraScreen', {}) },
			// { text: t('rate_app'), onPress: this.onRatePress },
		]
		if (Array.isArray(documents)) {
			documents.forEach(document => {
				menu.push({
					text: document.title,
					onPress: () => navigation.navigate('DocumentScreen', { document })
				})
			})
		}

		return menu
	}

	// onRatePress = () => {
	//  const { markets } = this.props
	//  const store: StoresType = getStoreName()
	//  const market: MarketType = markets.find(market => market.store === store)
	//  if (market && market.url) {
	//    Linking.openURL(market.url)
	//   }
	// }

}

const mapStateToProps = (state: AppState) => ({
	documents: state.app.documents || [],
	// markets: state.app.markets || [],
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AboutAppScreenWrapper)

const styles = {
	contentContainer: {
		flexGrow: 1,
		margin: 30,
	} as ViewStyle,
	logo: {
		alignSelf: 'center',
		width: 253,
		height: 95,
	} as ImageStyle,
	version: {
		alignSelf: 'center',
		marginTop: 20,
		marginBottom: 40,
		fontFamily: font(),
		fontSize: 14,
		lineHeight: 18,
	} as TextStyle,
	menu: {
		alignSelf: 'center',
		borderRadius: 20,
		paddingHorizontal: 20,
	} as ViewStyle,
}
