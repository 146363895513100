import React from 'react'
import { View, Dimensions, StyleSheet } from 'react-native'
import { connect } from 'react-redux'
import { StackScreenProps } from '@react-navigation/stack'
import * as _ from 'lodash'

import { Screen, Chat } from '../components'
import { AppState } from '../store/redux'
import {
  conferenceChatDeleteFileRequestAction,
  conferenceChatFilesUploadRequestAction,
  conferenceChatMessageRequestAction,
  conferenceChatReadAction,
  conferenceChatSendMessageRequestAction,
} from '../store/redux/conference'
import {
  CallNavigatorProps,
  PickedFileType,
  UploadFileType,
} from '../types'
import { ThemeColors, useTheme } from '../theme'

type ScreenProps = DispatchProps & StateProps & StackScreenProps<CallNavigatorProps, 'ConferenceChatScreen'>

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

interface Props extends ScreenProps {
  colors: ThemeColors
}

interface State {
  screenWidth: number
}

function ConferenceChatScreenWrapper(props: Props) {
  const { colors } = useTheme()
  return <ConferenceChatScreen colors={colors} {...props} />
}

class ConferenceChatScreen extends React.Component<Props, State>{
  dimensionsSubscription: any
	state = { screenWidth: window.innerWidth }

  componentDidMount() {
    this.readChat()
    this.dimensionsSubscription = Dimensions.addEventListener("change", this.onChange)

    const { future_appointments, route, navigation } = this.props
    const futureAppointmentsIds = future_appointments.map(appointment => appointment.id)
    if (!_.includes(futureAppointmentsIds, +route.params.id)) {
      navigation.navigate('NotFoundScreen')
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.navigation.isFocused()) {
      this.readChat()
    }
  }

  componentWillUnmount() {
    this.dimensionsSubscription?.remove()
  }

  onChange = ({ window }: any) => {
    this.setState({ screenWidth: window.width })
  }

  render() {
    const { screenWidth } = this.state
    const isScreenLess635 = screenWidth < 635

    const {
      messages,
      client_id,
      files,
      fetching,
      colors,
    } = this.props

    return (
      <Screen>
        <View style={[styles.container, {
          width: !isScreenLess635 ? '47.22%' : '100%',
          minWidth: !isScreenLess635 ? 635 : 'auto',
        }]}>
          <View style={[styles.content, {
            backgroundColor: colors.background.primary,
          }]}>
            <Chat
              type='conference'
              client_id={client_id}
              files={files}
              onDeleteFilePress={this.onDeleteFilePress}
              sendMessage={this.sendMessage}
              onAddFiles={this.onAddFiles}
              onEndReached={this.onEndReached}
              fetching={fetching}
              messages={messages}
            />
          </View>
        </View>
      </Screen>
    )
  }

  onDeleteFilePress = (params: UploadFileType) => {
    const { deleteFile } = this.props
    deleteFile(params.id)
  }

  onAddFiles = (params: PickedFileType[]) => {
    const { addFiles, chat_id } = this.props
    addFiles({ id: chat_id, files: params })
  }

  onEndReached = () => {
    const {
      page,
      limit,
      total,
      fetching,
      getMessages,
      chat_id,
    } = this.props
    if (!fetching && page * limit < total) {
      getMessages({ id: chat_id, page: page + 1 })
    }
  }

  sendMessage = ({ message, files, appended }) => {
    const { sendMessage, chat_id } = this.props
    sendMessage({ id: chat_id, message, files, appended })
  }

  readChat = () => {
    const { unread, chatRead, chat_id } = this.props
    !!unread && chatRead(chat_id)
  }

}

const mapStateToProps = (state: AppState) => ({
  client_id: state.conference.chat?.client._id || 0,
  messages: state.conference.messages,
  page: state.conference.page,
  limit: state.conference.limit,
  total: state.conference.total,
  fetching: state.conference.messages_fetching,
  files: state.conference.files,
  unread: state.conference.unread,
  chat_id: state.conference.chat?.id || 0,
  future_appointments: state.appointment.future_appointments || []
})

const mapDispatchToProps = {
  sendMessage: conferenceChatSendMessageRequestAction,
  getMessages: conferenceChatMessageRequestAction,
  addFiles: conferenceChatFilesUploadRequestAction,
  deleteFile: conferenceChatDeleteFileRequestAction,
  chatRead: conferenceChatReadAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConferenceChatScreenWrapper)

const styles = StyleSheet.create({
	container: {
		flexGrow: 1,
		marginVertical: 30,
		paddingTop: 12,
		paddingHorizontal: 20,
		alignSelf: 'center',
	},
	content: {
		flex: 1,
		borderRadius: 10,
		overflow: 'hidden',
	},
})
