const PsyMale = require('./psychologistMale.png')
const PsyFemale = require('./psychologistFemale.png')
const appointmentCalendar = require('./appointment_calendar.png')
const appointmentClient = require('./appointment_client.png')
const appointmentService = require('./appointment_service.png')
const appointmentFemale = require('./appointment_female.png')
const appointmentMale = require('./appointment_male.png')
const appointmentPrice = require('./appointment_price.png')
const appointmentSuccess = require('./appointment_success.png')
const appointmentFailure = require('./appointment_failure.png')
const appointmentInfo = require('./appointment_info.png')
const emptyNotifications = require('./empty_lists/notifications.png')
const emptyAppointment = require('./empty_lists/appointment.png')
const logo = require('./logo.png')
const logo_mini = require('./logo_mini.png')
const conferenceDone = require('./conference_done.png')
const percent = require('./percent.png')
const notFound = require('./notFound.png')
const googlePlay = require('./google-play.png')
const appStore = require('./app-store.png')
const appGallery = require('./app-gallery.png')
const star = require('./star.png')
const starOutline = require('./star-outline.png')
const starEmpty = require('./star-empty.png')
const achieveLocked = require('./achieve-locked.png')
const defaultAchieve = require('./default-achieve.png')
const defaultTestMini = require('./default-test-mini.png')
const ru = require('./flags/ru.png')
const by = require('./flags/by.png')
const kz = require('./flags/kz.png')
const uz = require('./flags/uz.png')
const notepad = require('./notepad.png')
const done = require('./done.png')

export {
  PsyMale,
  PsyFemale,
  appointmentCalendar,
  appointmentClient,
  appointmentService,
  appointmentFemale,
  appointmentMale,
  appointmentPrice,
  appointmentSuccess,
  appointmentFailure,
  appointmentInfo,
  emptyNotifications,
  emptyAppointment,
  logo,
  logo_mini,
  conferenceDone,
  percent,
  notFound,
  googlePlay,
  appStore,
  appGallery,
  star,
  starOutline,
  starEmpty,
  achieveLocked,
  defaultAchieve,
  defaultTestMini,

  ru,
  by,
  kz,
  uz,

  notepad,
  done,
}
