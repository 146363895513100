import {
	ApiFailure,
	ApiFailureResponse,
	PromocodeSuccessResponse,
	Nullable,
	PromocodeRequest,
} from '../../types'

export interface PromocodeState {
	promocode: Nullable<string>
	fetching: boolean
	error: Nullable<ApiFailure>
	result: Nullable<PromocodeSuccessResponse>
}

export const PROMOCODE_REQUEST = 'PROMOCODE_REQUEST'
export const PROMOCODE_SUCCESS = 'PROMOCODE_SUCCESS'
export const PROMOCODE_FAILURE = 'PROMOCODE_FAILURE'

export const promocodeRequestAction = (params: PromocodeRequest) => ({ type: PROMOCODE_REQUEST, params })
export const promocodeSuccessAction = (payload) => ({ type: PROMOCODE_SUCCESS, payload })
export const promocodeFailureAction = (payload) => ({ type: PROMOCODE_FAILURE, payload })

const initialState: PromocodeState = {
	promocode: null,
	fetching: false,
	error: null,
	result: null,
}

const PromocodeReducer = (state = initialState, action: any): PromocodeState => {
	const { type, payload, params } = action
	switch (type) {
		case PROMOCODE_REQUEST: {
			return { ...state, error: null, fetching: true }
		}
		case PROMOCODE_SUCCESS: {
			return { ...state, fetching: false, result: payload }
		}
		case PROMOCODE_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, fetching: false, error }
		}
		default:
			return state
	}
}

export default PromocodeReducer
