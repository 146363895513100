import {
	Nullable,
	ApiFailure,
	ApiFailureResponse,
	UserProfile,
	UserProfileSuccessResponse,
	UserProfileUpdateRequest,
	ApiSuccessResponse,
} from '../../types'
import { AUTH_LOGOUT_SUCCESS } from './auth'

export interface UserState {
	profile: Nullable<UserProfile>
	fetching: boolean
	error: Nullable<ApiFailure>
	deleteFetching: boolean
	deleteError: Nullable<ApiFailure>
}

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST'
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS'
export const USER_PROFILE_FAILURE = 'USER_PROFILE_FAILURE'

export const USER_PROFILE_DELETE_REQUEST = 'USER_PROFILE_DELETE_REQUEST'
export const USER_PROFILE_DELETE_FAILURE = 'USER_PROFILE_DELETE_FAILURE'

export const USER_PROFILE_UPDATE_REQUEST = 'USER_PROFILE_UPDATE_REQUEST'
export const USER_PROFILE_UPDATE_SUCCESS = 'USER_PROFILE_UPDATE_SUCCESS'
export const USER_PROFILE_UPDATE_FAILURE = 'USER_PROFILE_UPDATE_FAILURE'

export const userProfileRequestAction = () => ({ type: USER_PROFILE_REQUEST })
export const userProfileSuccessAction = (payload: UserProfileSuccessResponse) => (
	{ type: USER_PROFILE_SUCCESS, payload }
)
export const userProfileFailureAction = (payload: ApiSuccessResponse<UserProfileSuccessResponse> | undefined) => (
	{ type: USER_PROFILE_FAILURE, payload }
)

export const userProfileDeleteRequestAction = () => ({ type: USER_PROFILE_DELETE_REQUEST })
export const userProfileDeleteFailureAction = (payload: any) => (
	{ type: USER_PROFILE_DELETE_FAILURE, payload }
)

export const userProfileUpdateRequestAction = (params: UserProfileUpdateRequest) => (
	{ type: USER_PROFILE_UPDATE_REQUEST, params }
)
export const userProfileUpdateSuccessAction = (payload: UserProfileSuccessResponse) => (
	{ type: USER_PROFILE_UPDATE_SUCCESS, payload }
)
export const userProfileUpdateFailureAction = (payload: ApiSuccessResponse<UserProfileSuccessResponse> | undefined) => (
	{ type: USER_PROFILE_UPDATE_FAILURE, payload }
)

const initialState: UserState = {
	profile: null,
	fetching: false,
	error: null,
	deleteFetching: false,
	deleteError: null,
}

const UserReducer = (state = initialState, action: any): UserState => {
	const { type, payload, params } = action
	switch (type) {
		case USER_PROFILE_UPDATE_REQUEST:
		case USER_PROFILE_REQUEST: {
			return { ...state, error: null, fetching: true }
		}
		case USER_PROFILE_UPDATE_SUCCESS:
		case USER_PROFILE_SUCCESS: {
			const { profile } = payload as UserProfileSuccessResponse
			return { ...state, fetching: false, profile }
		}
		case USER_PROFILE_UPDATE_FAILURE:
		case USER_PROFILE_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, fetching: false, error }
		}
		case USER_PROFILE_DELETE_REQUEST: {
			return { ...state, deleteFetching: true, deleteError: null }
		}
		case USER_PROFILE_DELETE_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, deleteFetching: false, deleteError: error }
		}
		case AUTH_LOGOUT_SUCCESS:
			return initialState
		default:
			return state
	}
}

export default UserReducer
