import React from 'react'
import { connect } from 'react-redux'
import { AppState } from '../store/redux'
import { StackScreenProps } from '@react-navigation/stack'
import { Image, ScrollView, Text, TextStyle, ViewStyle, Platform } from 'react-native'
import { Button, Screen } from '../components'
import { t } from '../localization'
import { CommonActions } from '@react-navigation/native'
import { CallNavigatorProps } from '../types'
import { font, metrics, ThemeColors, useTheme } from '../theme'
import { conferenceDone } from '../images'

type ScreenProps = DispatchProps & StateProps & StackScreenProps<CallNavigatorProps, 'ConferenceDoneScreen'>

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

interface Props extends ScreenProps {
	colors: ThemeColors
}

interface State { }

function ConferenceDoneScreenWrapper(props: Props) {
	const { colors } = useTheme()
	return <ConferenceDoneScreen colors={colors} {...props} />
}

class ConferenceDoneScreen extends React.Component<Props, State>{

	render() {
		const { colors, navigation } = this.props

		return (
			<Screen borderless hideHeader>
				<ScrollView
					bounces={false}
					contentContainerStyle={styles.contentContainer}
					showsVerticalScrollIndicator={Platform.OS === 'web'}
				>
					<Image
						source={conferenceDone}
						style={styles.image}
						resizeMode={'contain'}
					/>
					<Text style={[styles.title, { color: colors.text.title }]}>
						{t('conference_done')}
					</Text>
					<Text style={[styles.text, { color: colors.text.default }]}>
						{t('conference_done_text')}
					</Text>
					<Button
						text={t('return')}
						style={styles.button}
						onPress={() => navigation.dispatch(
							CommonActions.reset({
								index: 0,
								routes: [{ name: 'MainStack', params: { screen: 'MainScreen' } }],
							})
						)}
					/>
				</ScrollView>
			</Screen>
		)
	}

}

const mapStateToProps = (state: AppState) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ConferenceDoneScreenWrapper)

const styles = {
	contentContainer: {
		flexGrow: 1,
		paddingTop: 12,
		paddingBottom: 40,
		paddingHorizontal: 16,
		alignItems: 'center',
		justifyContent: 'center',
	} as ViewStyle,
	image: {
		width: metrics.screenWidth - 30,
		maxWidth: 314,
		height: (metrics.screenWidth - 30) * (243 / 314),
		maxHeight: 243,
	},
	title: {
		marginTop: 30,
		fontFamily: font('bold'),
		fontSize: 24,
		lineHeight: 29,
	},
	text: {
		marginTop: 16,
		fontFamily: font(),
		fontSize: 14,
		lineHeight: 18,
		textAlign: 'center',
		width: metrics.screenWidth - 30,
		maxWidth: Platform.OS !== 'web' ? 314 : 'initial',
	} as TextStyle,
	button: {
		marginTop: 16,
		width: 201,
	},
}
