import React, { useEffect, useState } from 'react'
import { ScrollView, StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'

import { Screen, CodeInput, Timer, Logo } from '../../components'
import { t } from '../localization'
import { font, useTheme } from '../../theme'
import { usePrevious } from '../../hooks/usePrevious'
import config from '../../config'
import { AppState } from '../store/redux'
import { authRequestAction } from '../store/redux/auth'

function CodeConfirmationScreen() {
	const navigation = useNavigation()
	const dispatch = useDispatch()

	const email = useSelector((state: AppState) => state.auth.email)
	const fetching = useSelector((state: AppState) => state.auth.fetching)

	const [code, setCode] = useState('')

	const prevFetching = usePrevious(fetching)

	useEffect(() => {
		if (!email) {
			navigation.replace('AuthByEmailScreen')
		}
	}, [])

	useEffect(() => {
		if (!fetching && !prevFetching && !!code && code.length === config.smsCodeLength) {
			dispatch(authRequestAction({ email, code }))
		}
	}, [code])

	const onCodeChange = (code: string) => {
		setCode(code)
	}

	const onSendCode = () => {
		dispatch(authRequestAction({ email }))
	}

	return (
		<Screen fetching={fetching} hideHeader={true}>
			<ScrollView
				bounces={false}
				contentContainerStyle={styles.contentContainer}
			>
				<Logo style={{ marginTop: 40 }} clickable={false} />
				<View style={styles.wrapper}>
					<Header email={email} />
					<CodeInput
						style={styles.smsCode}
						smsCodeLength={config.smsCodeLength}
						onCodeEntered={onCodeChange}
					/>
					<CodeRepeat onSendCode={onSendCode} />
				</View>
				<View />
			</ScrollView>
		</Screen>
	)
}

export default CodeConfirmationScreen

const styles = StyleSheet.create({
	contentContainer: {
		flexGrow: 1,
		justifyContent: 'space-between',
		alignItems: 'center',
	} as ViewStyle,
	wrapper: {
		width: '33%',
		minWidth: 450,
		paddingHorizontal: 35,
		textAlign: 'center',
		alignItems: 'center',
		marginBottom: 40,
	} as ViewStyle,
	smsCode: {
		marginTop: 16,
	} as TextStyle,
})

function Header({email = ''}: {email: string}) {
	const { colors } = useTheme()
	return (
		<View style={{ marginTop: 60 }}>
			<Text style={[headerStyles.auth, { color: colors.text.title }]}>
				{t('email_code')}
			</Text>
			<Text style={[headerStyles.subtitle, { color: colors.text.secondary }]}>
				{t('code_sended_to', [email])}
			</Text>
		</View>
	)
}

const headerStyles = StyleSheet.create({
	auth: {
		fontFamily: font(),
		fontSize: 36,
		lineHeight: 29,
		marginBottom: 12,
	},
	subtitle: {
		fontFamily: font(),
		fontSize: 16,
		lineHeight: 19,
	},
})

function CodeRepeat({onSendCode}: {onSendCode: () => void}) {
	const { colors } = useTheme()
	const [canSend, setCanSend] = useState(false)
	return (
		<View style={smsRepeatStyles.container}>
			{!canSend && (
				<Timer
					duration={110}
					onRender={value => (
						<Text style={[smsRepeatStyles.text, { fontFamily: font(), color: colors.text.secondary }]}>
							{t('resend_sms_code').replace('%s', value)}
						</Text>
					)}
					onDone={() => setCanSend(true)}
				/>
			)}
			{canSend && (
				<>
					<Text
						style={[smsRepeatStyles.text, {
							color: colors.text.subtitle,
							marginBottom: 8,
						}]}
						onPress={() => {
							setCanSend(false)
							onSendCode()
						}}
					>
						{t('send_code')}
					</Text>
				</>
			)}
		</View>
	)
}
const smsRepeatStyles = StyleSheet.create({
	container: {
		marginTop: 16,
	},
	text: {
		fontFamily: font(),
		fontSize: 16,
		cursor: 'pointer',
	},
})
