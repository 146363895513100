import React from 'react'
import { Image as RNImage } from 'react-native';

interface Props {
  width: number,
  height?: number
  uri: string
}

interface State {
  width: number,
  height: number,
}

export default class Image extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
    }
  }

  componentDidMount() {
    const {
      uri,
      width: _width,
      height: _height,
    } = this.props

    if (!uri) return

    RNImage.getSize(uri,
      (width: number, height: number) => {

        const ratio = width / height;
        if (_width && !_height) {
          this.setState({
            width: Math.round(_width),
            height: Math.round(_width / ratio)
          })
        }
        if (_width && _height) {
          this.setState({
            width: _width,
            height: _height,
          })
        }
      },
      error => console.log(error)
    )
  }

  render() {
    const {
      width,
      height,
    } = this.state;
    const {
      uri,
    } = this.props;
    const isLoaded = !!width && !!height

    if (!uri) return null

    return (
      <RNImage
        style={[isLoaded && { width, height }]}
        source={{ uri }}
      />
    )
  }

}
