import { put, call, delay, select } from 'redux-saga/effects'
import { ApiResponse } from 'apisauce';
import { api, ApiSuccessResponse } from '../../api'
import {
	Action,
	ChatMessagesRequest,
	ChatMessageSuccessResponse,
	ChatSendMessageRequest,
	ChatSendMessageSuccessResponse,
	ChatSuccessResponse,
	FileDeleteResponse,
	FileUploadResponse,
	ChatFilesUploadActionParams,
} from '../../types';
import {
	chatDeleteFileSuccessAction,
	chatFailureAction,
	chatFilesUploadSetAction,
	chatMessageFailureAction,
	chatMessageRequestAction,
	chatMessageSuccessAction,
	chatSendMessageFailureAction,
	chatSendMessageSuccessAction,
	chatSuccessAction,
} from '../redux/chat';

export function* chatRequestSaga() {
	const response: ApiResponse<ApiSuccessResponse<ChatSuccessResponse>> = yield call(api.chatRequest)
	if (response.ok) {
		const id = response.data.result.chat.id
		yield put(chatSuccessAction(response.data.result))
		yield put(chatMessageRequestAction({ id, page: 1 }))
	} else {
		yield put(chatFailureAction(response.data))
	}
}

export function* chatMessageRequestSaga(action: Action<ChatMessagesRequest>) {
	const response: ApiResponse<ApiSuccessResponse<ChatMessageSuccessResponse>> = yield call(api.chatMessageRequest, action.params)
	if (response.ok) {
		yield put(chatMessageSuccessAction(response.data.result))
	} else {
		yield put(chatMessageFailureAction(response.data))
	}
}

export function* chatSendMessageSaga(action: Action<ChatSendMessageRequest>) {
	const response: ApiResponse<ApiSuccessResponse<ChatSendMessageSuccessResponse>> = yield call(api.chatSendMessage, action.params)
	if (response.ok) {
		yield put(chatSendMessageSuccessAction(response.data.result))
	} else {
		yield put(chatSendMessageFailureAction(response.data))
	}
}

export function* chatFilesUploadRequestSaga(action: { type: string; params: ChatFilesUploadActionParams }) {
	const { id, files } = action.params
	const result: FileUploadResponse = yield Promise.all(
		files.map((file, idx) => {
			return api.chatFileUpload(id, file, idx)
		})
	)
	yield put(chatFilesUploadSetAction(result))
}

export function* chatFilesDeleteRequestSaga(action: any) {
	if (typeof action.params == 'number') {
		const response: ApiResponse<ApiSuccessResponse<FileDeleteResponse>> = yield call(api.chatFileDelete, action.params)
		if (response.ok)
			yield put(chatDeleteFileSuccessAction({ id: action.params, ...response.data }))
	} else {
		yield call(api.fileUploadCancel, action.params)
	}
}

export function* chatReadSaga(action: { type: string; payload: number }) {
	const response: ApiResponse<ApiSuccessResponse<undefined>> = yield call(api.chatRead, action.payload)
}
