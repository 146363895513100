import React from 'react'
import { StyleSheet, View, ViewStyle, Platform } from 'react-native'
import RadioButton, { RadioButtonProps } from './RadioButton'

interface RadioButtonGroup {
	buttons: RadioButtonProps[]
	checked: string | number
	onSelect: (value: any) => void
	disabled?: boolean
	style?: ViewStyle | ViewStyle[]
	horizontal?: boolean
}

export default function RadioButtonGroup({
	buttons = [],
	checked,
	onSelect,
	style,
	disabled,
	horizontal,
}: RadioButtonGroup) {

	return (
		<View style={[horizontal ? styles.horizontal : styles.vertical, style]}>
			{buttons.map((button, index) => (
				<RadioButton
					style={[
						styles[(horizontal || index == 0) ? 'first' : 'default'],
						horizontal ? { marginRight: 40 } : {},
						Platform.OS === 'web' ? { flexGrow: 0, flexBasis: 'auto' } : {},
					]}
					key={index.toString()}
					{...button}
					checked={checked === button.value}
					onChange={onSelect}
					disabled={disabled}
				/>
			))}
		</View>
	)
}

const styles = StyleSheet.create({
	vertical: {},
	horizontal: { flexDirection: 'row' },
	first: {
		flex: 1,
		marginLeft: 0,
	},
	default: {
		flex: 1,
		marginTop: 10,
	},
})
