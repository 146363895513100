import React, { useEffect, useState } from 'react'
import {
  ScrollView,
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Switch,
  Dimensions,
  ScaledSize,
} from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import EmojiPicker from 'emoji-picker-react'
import { showMessage } from 'react-native-flash-message'
import get from 'lodash/get'

import { Button, Icon, Modal, Screen, TextInput } from '../../components'
import { t } from '../localization'
import {
  createTrackerRequestAction,
  editTrackerRequestAction,
  trackersRequestAction,
  createTrackerAction,
} from '../store/redux/tracker'
import { font, useTheme } from '../../theme'
import config from '../../config'
import { AppState } from '../store'
import { usePrevious } from '../../hooks/usePrevious'
import { TrackerType } from '../types'
import { getUtcOffset } from '../../service'

export default function AddTrackerScreen({ navigation, route }: { navigation: any, route: any }) {
  const { colors } = useTheme()
  // const navigation = useNavigation()
  const dispatch = useDispatch()

  const id = get(route.params, 'id', 0)
  const mode = get(route.params, 'mode', 'add')

  const createTrackerSuccess: number = useSelector((state: AppState) => state.tracker.create_tracker_success)
  const editTrackerSuccess: boolean = useSelector((state: AppState) => state.tracker.edit_tracker_success)
  const tracker: TrackerType | undefined = useSelector((state: AppState) => state.tracker.trackers.find((tracker: TrackerType) => tracker.id === id))
  const [screenData, setScreenData] = useState(Dimensions.get('window'))

  const prevEditTrackerSuccess = usePrevious(editTrackerSuccess)

  const enableNotifications = tracker?.notifications ?? false
  const [title, setTitle] = useState<string>(tracker?.title ?? '')
  const [description, setDescription] = useState<string>(tracker?.description ?? '')
  const [emoji, setEmoji] = useState<string>(tracker?.icon ?? '')
  const [background, setBackground] = useState<string>(tracker?.background ?? config.trackerDefaultColors[0])
  const [isDegradation, setIsDegradation] = useState<boolean>(tracker?.is_degradation ?? false)
  const [isModalBackgroundColorVisible, setIsModalBackgroundColorVisible] = useState(false)
  const [isModalEmojiVisible, setIsModalEmojiVisible] = useState(false)

  useEffect(() => {
    const onChange = (result: { window: React.SetStateAction<ScaledSize> }) => {
      setScreenData(result.window)
    }

    const subscription = Dimensions.addEventListener('change', onChange)

    return () => subscription?.remove()
  })

  useEffect(() => {
    if (mode == 'edit' && (!tracker || +id !== tracker.id)) {
      navigation.replace('MainStack', { screen: 'MainScreen' })
    }
  }, [])

  useEffect(() => {
    if (mode !== 'edit' && createTrackerSuccess !== null) {
      dispatch(trackersRequestAction({ tz_offset: getUtcOffset() }))
      showMessage({
        type: 'success',
        message: t('create_tracker_success'),
        duration: config.api.messageDuration,
      })

      dispatch(createTrackerAction())

      navigation.goBack()
    }
  }, [createTrackerSuccess])

  useEffect(() => {
    if (prevEditTrackerSuccess !== null && !prevEditTrackerSuccess && editTrackerSuccess) {
      dispatch(trackersRequestAction({ tz_offset: getUtcOffset() }))
      showMessage({
        type: 'success',
        message: t('edit_tracker_success'),
        duration: config.api.messageDuration,
      })

      navigation.goBack()
    }
  }, [editTrackerSuccess])

  const onCloseBackgroundColorModal = () => {
    setIsModalBackgroundColorVisible(false)
  }

  const onCloseEmojiModal = () => {
    setIsModalEmojiVisible(false)
  }

  const renderTitle = () => {
    return (
      <View style={styles.title}>
        <Text style={[styles.titleText, { color: colors.text.title }]}>
          {mode === 'add' ? t('tracker_creating') : t('tracker_editing')}
        </Text>
      </View>
    )
  }

	const isSmallScreen = screenData.width < 655
  const isDefault = tracker != null && tracker.is_default

  return (
    <Screen>
      <View style={[
        styles.contentWrapper,
        {
          width: !isSmallScreen ? '47.22%' : '100%',
          minWidth: !isSmallScreen ? 635 : 'auto',
          paddingLeft: 20,
        },
      ]}>
        {renderTitle()}
        <ScrollView contentContainerStyle={styles.container}>
          <View style={[styles.card, { backgroundColor: colors.background.primary }]}>
            <View>
              <TextInput
                style={styles.titleField}
                value={title}
                textStyle={styles.titleInput}
                placeholder={t('tracker_title_placeholder')}
                onChange={(value) => {
                  setTitle(value)
                }}
                maxLength={80}
                readonly={isDefault}
              />
              {!isDefault && (
                <TouchableOpacity
                  onPress={() => {
                    setIsModalEmojiVisible(true)
                  }}
                  style={styles.emojiSelector}
                >
                  {emoji.length === 0 ? (
                    <Icon name='smile' size={20} color={colors.icon.inactive} />
                  ) : (
                    <Text style={{
                      color: colors.text.default,
                      fontSize: 18,
                    }}>{emoji}</Text>
                  )}
                </TouchableOpacity>
              )}
              {!isDefault && (
                <TouchableOpacity
                  onPress={() => {
                    setIsModalBackgroundColorVisible(true)
                  }}
                  style={[styles.backgroundSelector, {backgroundColor: background}]}
                />
              )}
            </View>
            <TextInput
              placeholder={t('tracker_description_placeholder')}
              value={description}
              onChange={(value) => {
                setDescription(value)
              }}
              multiline={true}
              readonly={isDefault}
            />
          </View>
          <View style={[styles.card, { backgroundColor: colors.background.primary }]}>
            <View style={styles.row_switch}>
              <Text style={{ color: colors.text.default }}>{t('degradation_mode')}</Text>
              <Switch value={isDegradation} onValueChange={(value) => {
                setIsDegradation(value)
              }} />
            </View>
            <Text style={[styles.degradation_description, { color: colors.text.default }]}>
              {t('degradation_mode_description')}
            </Text>
          </View>
          <View style={{flexDirection: 'row'}}>
            <Button
              style={[styles.createTrackerBtn, {marginRight: 12}]}
              type='secondary'
              text={t('back')}
              onPress={() => {
                if (navigation.canGoBack()) {
                  navigation.goBack()
                } else {
                  navigation.replace('MainStack', { screen: 'MainScreen' })
                }
              }}
            />
            <Button
              style={styles.createTrackerBtn}
              text={mode == 'add' ? t('create_tracker') : t('save_changes')}
              onPress={() => {
                dispatch(mode == 'add'
                  ? createTrackerRequestAction({
                    title: title,
                    description: description,
                    icon: emoji,
                    background: background,
                    notifications: enableNotifications,
                    is_degradation: isDegradation,
                  })
                  : editTrackerRequestAction({
                    id: id,
                    title: title,
                    description: description,
                    icon: emoji,
                    background: background,
                    notifications: enableNotifications,
                    is_degradation: isDegradation,
                  })
                )
              }}
            />
          </View>
        </ScrollView>
        <Modal
          isVisible={isModalBackgroundColorVisible}
          onClose={onCloseBackgroundColorModal}
          position='center'
        >
          <View style={styles.modalContainer}>
            <Text style={[styles.selectBgModalTitle, { color: colors.text.default }]}>
              {t('tracker_color')}
            </Text>
            <View style={styles.selectBgModalContent}>
              {config.trackerDefaultColors.map((color, index) => {
                const isSelected = color === background
                return (
                  <TouchableWithoutFeedback
                    key={index}
                    onPress={() => {
                      setBackground(color)
                    }}
                  >
                    <View style={[styles.colorContent, {
                      backgroundColor: color,
                      borderColor: isSelected ? colors.border.selected : 'rgba(114, 114, 114, 0.2)',
                    }]}>
                      {isSelected && <Icon name='checkBoxCheck' size={16} color={colors.icon.default} />}
                    </View>
                  </TouchableWithoutFeedback>
                );
              })}
            </View>
          </View>
        </Modal>
        <Modal
          isVisible={isModalEmojiVisible}
          onClose={onCloseEmojiModal}
          position='center'
        >
          <View style={{
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 38,
          }}>
            <EmojiPicker
              onEmojiClick={(emojiData) => {
                setEmoji(emojiData.emoji)
                setIsModalEmojiVisible(false)
              }}
              skinTonesDisabled
            />
          </View>
        </Modal>
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    paddingBottom: 24,
  },
  contentWrapper: {
    marginTop: 40,
    paddingRight: 20,
    alignSelf: 'center',
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24,
  },
  titleText: {
    fontSize: 24,
    fontFamily: font('bold'),
  },
  card: {
    padding: 16,
    marginBottom: 8,
    borderRadius: 20,
  },
  titleField: {
    marginBottom: 8,
  },
  titleInput: {
    paddingRight: 58,
    height: 40,
  },
  row_switch: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  degradation_description: {
    fontSize: 10,
  },
  modalContainer: {
    paddingHorizontal: 16,
  },
  selectBgModalContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24,
  },
  selectBgModalTitle: {
    paddingBottom: 24,
    fontSize: 24,
    fontWeight: 'bold',
  },
  colorContent: {
    width: 56,
    height: 56,
    borderWidth: 1,
    borderRadius: 28,
    justifyContent: 'center',
    alignItems: 'center',
  },
  backgroundSelector: {
    position: 'absolute',
    right: 40,
    top: 8,
    width: 24,
    height: 24,
    borderRadius: 12,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: 'rgba(114, 114, 114, 0.2)',
    borderWidth: 1,
  },
  emojiSelector: {
    position: 'absolute',
    right: 8,
    top: 8,
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
  createTrackerBtn: {
    flex: 1,
    marginTop: 24,
  },
})
