import { ApisauceInstance, ApiOkResponse, ApiErrorResponse } from 'apisauce'
import { AxiosRequestConfig } from 'axios'
import config from '../config'
import { Api, ApiFailure, ApiFailureResponse } from '../api'
import loginFixture from './login'
import userFixture from './user'
import notificationsFixture from './notifications'
interface FixtureResponseRequest {
	url: string
	data?: any
	params?: any
	axiosConfig: AxiosRequestConfig
}

export interface FixtureApi {
	resolve: (response: any) => void
	successResponse: (axiosConfig: AxiosRequestConfig, data: any) => void
	errorResponse: (axiosConfig: AxiosRequestConfig, data?: any, status?: number) => void
	axiosConfig: AxiosRequestConfig
	data?: any
	params?: any
}

export function create() {
	return {
		get: (url: string, params?: {}, axiosConfig?: AxiosRequestConfig) => response({
			url, params, axiosConfig: { ...axiosConfig, method: 'get', url }
		}),
		delete: (url: string, params?: {}, axiosConfig?: AxiosRequestConfig) => response({
			url, params, axiosConfig: { ...axiosConfig, method: 'delete', url }
		}),
		post: (url: string, data?: any, axiosConfig?: AxiosRequestConfig) => response({
			url, data, axiosConfig: { ...axiosConfig, method: 'post', url }
		}),
		put: (url: string, data?: any, axiosConfig?: AxiosRequestConfig) => response({
			url, data, axiosConfig: { ...axiosConfig, method: 'put', url }
		}),
		patch: (url: string, data?: any, axiosConfig?: AxiosRequestConfig) => response({
			url, data, axiosConfig: { ...axiosConfig, method: 'patch', url }
		}),
	} as ApisauceInstance
}

function response({ url: _url, data, params, axiosConfig }: FixtureResponseRequest) {
	const index = _url.indexOf('/')
	const url = _url.substring(0, index > 0 ? index : undefined);
	return new Promise((resolve, reject) => {
		switch (url) {
			case Api.AUTH:
				loginFixture({ resolve, successResponse, errorResponse, axiosConfig });
				break;
			case Api.USER:
				userFixture({ resolve, successResponse, errorResponse, axiosConfig });
				break;
			case Api.NOTIFICATIONS:
				notificationsFixture({ resolve, successResponse, errorResponse, axiosConfig });
				break;
			default: resolve(errorResponse(axiosConfig))
		}
	})
}

function successResponse(axiosConfig: AxiosRequestConfig, data: any) {
	return {
		ok: true,
		duration: config.api.fixtureDuration,
		status: 200,
		config: axiosConfig,
		data,
	} as ApiOkResponse<any>
}

function errorResponse(axiosConfig: AxiosRequestConfig, error?: ApiFailure, status?: any) {
	return {
		ok: false,
		problem: 'UNKNOWN_ERROR',
		status: status || 500,
		config: axiosConfig,
		data: { error: error || { message: `Метод '${axiosConfig.method?.toUpperCase()} ${axiosConfig.url}' отсутствует в fixtures` } },
	} as ApiErrorResponse<ApiFailureResponse>
}