import notifee, {
  AndroidImportance,
  AndroidNotificationSetting,
  TimestampTrigger,
  TriggerType,
  RepeatFrequency,
} from '@notifee/react-native'
import moment from 'moment'
import startsWith from 'lodash/startsWith'
import uniqBy from 'lodash/uniqBy'

import { t } from '../localization'
import { Platform } from 'react-native'

export const useNotifications = () => {
  async function createDefaultChannel() {
    await notifee.createChannel({
      id: 'default',
      name: 'Default Channel',
    })
  }

  async function createRestoredChannel() {
    await notifee.createChannel(
      {
        id: 'Restored',
        name: 'Общие',
        description: 'Канал общих уведомлений',
        // playSound: true,
        sound: '',
        importance: AndroidImportance.HIGH,
        vibration: true,
      },
    )
  }

  async function createIncommingCallChannel() {
    await notifee.createChannel(
      {
        id: 'incoming_call',
        name: 'Входящие звонки',
        description: 'Канал уведомлений о входящем вывове',
        // playSound: true, // (optional) default: true
        // soundName: 'incoming_call.wav',
        sound: 'incoming_call',
        importance: AndroidImportance.HIGH,
        vibration: true,
      },
    )
  }

  async function cancelAllNotifications() {
    await notifee.cancelAllNotifications()
  }

  async function getTriggerNotifications() {
    return await notifee.getTriggerNotifications()
  }

  async function checkPermissions() {
    const settings = await notifee.getNotificationSettings()
    if (Platform.OS === 'android') {
      return settings.android.alarm == AndroidNotificationSetting.ENABLED
    } else if (Platform.OS === 'ios') {
      return !!settings.authorizationStatus
    }
    return false
  }

  async function requestIOSNotificationsPermissions() {
    await notifee.requestPermission()
  }

  async function openAlarmPermissionSettingsAndroid() {
    await notifee.openAlarmPermissionSettings()
  }

  // async function requestPermissionsIOS() {
  //   const settings = await notifee.requestPermission()
  //   if (settings.authorizationStatus >= AuthorizationStatus.AUTHORIZED) {
  //     return true
  //   }
  //   return false
  // }

  async function createTriggerNotification(id: number, time: string, emoji: string, title: string) {
    const date = moment(time, 'HH:mm')
    if (date.isSameOrBefore(moment())) {
      date.add(1, 'days')
    }
    date.seconds(0)

    const trigger: TimestampTrigger = {
      type: TriggerType.TIMESTAMP,
      timestamp: date.valueOf(),
      repeatFrequency: RepeatFrequency.DAILY,
      alarmManager: {
        allowWhileIdle: true,
      },
    }

    await notifee.createTriggerNotification(
      {
        id: `${id}_${time}`,
        title: 'Aidera',
        body: t('its_time_to_rate', [emoji, title]),
        android: {
          channelId: 'default',
          pressAction: {
            id: 'open-tracker',
            launchActivity: 'ru.bokus.aidera.MainActivity',
          },
          showTimestamp: true,
        },
        data: {
          deeplink: `/trackers/${id}`
        }
      },
      trigger,
    )
  }

  async function removeNotificationsByTrackerId(id: number) {
    const notifications = await getTriggerNotifications()
    await Promise.all(notifications.map(async notification => {
      if (startsWith(notification.notification.id, `${id}_`)) {
        await notifee.cancelNotification(notification.notification.id!)
      }
    }))
  }

  async function createTriggerNotificationsById(
    id: number,
    notifications: Array<{'time': string}>,
    emoji: string,
    title: string,
  ) {
    const notificationTimes = uniqBy(notifications, 'time')
    await Promise.all(notificationTimes.map(async notification => {
      createTriggerNotification(id, notification.time, emoji, title)
    }))
  }

  return {
    createDefaultChannel,
    createRestoredChannel,
    createIncommingCallChannel,
    // onForegroundEvent,
    // onBackgroundEvent,
    cancelAllNotifications,
    getTriggerNotifications,
    checkPermissions,
    openAlarmPermissionSettingsAndroid,
    requestIOSNotificationsPermissions,
    createTriggerNotification,
    removeNotificationsByTrackerId,
    createTriggerNotificationsById,
  }
}