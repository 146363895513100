import React, { useState } from 'react'
import { View, TouchableOpacity, Image, StyleSheet, Text, Platform } from 'react-native'
import ImageZoom from 'react-native-image-pan-zoom'
import Icon from './Icon'
import { useMetrics, useTheme } from '../theme'
import { Nullable } from '../types'
import { Modal } from '.'

interface Props {
  visible: boolean
  onCancel: () => void
  image: Nullable<string>
}

export default function ({
  visible,
  onCancel,
  image,
}: Props) {
  const { responsiveWidth, responsiveHeight } = useMetrics()
  const [imageHeight, setImageHeight] = useState<number>(responsiveHeight)
  const [cropHeight, setCropHeight] = useState<number>(responsiveHeight)
  const { colors } = useTheme()

  const setImageZoomSize = () => {
    const setImageSize = (width, height) => {
      const scale = responsiveWidth / width
      const ratio = width / height

      if (width > height && Platform.OS !== 'web') {
        const imgHeight = height * scale * ratio
        setCropHeight(imgHeight)
        setImageHeight(height * scale)
      } else {
        let imgHeight = width * scale * 1 / ratio
        const cropAdditionalSpace = 50
        const maxImgHeight = responsiveHeight - cropAdditionalSpace - 32
        if (imgHeight > maxImgHeight) imgHeight = maxImgHeight
        setCropHeight(imgHeight + cropAdditionalSpace)
        setImageHeight(imgHeight)
      }
    }

    Image.getSize(image, setImageSize)
  }

  return (
    <Modal
      isVisible={visible}
      onClose={onCancel}
      closeButton={false}
    >
      <View style={styles.container}>
        <View style={[styles.header, { backgroundColor: colors.background.default }]}>
          <TouchableOpacity
            hitSlop={{ top: 16, bottom: 16, left: 16, right: 16 }}
            style={styles.backBtn}
            onPress={onCancel}
          >
            <Icon name={'arrow_back'} size={24} color={colors.icon.default} />
          </TouchableOpacity>
        </View>
        <ImageZoom
          cropWidth={responsiveWidth}
          cropHeight={cropHeight}
          imageWidth={responsiveWidth}
          imageHeight={imageHeight}
          useNativeDriver
        >
          {!!image ? (
            <Image
              source={{ uri: image }}
              style={{ width: responsiveWidth, height: imageHeight }}
              resizeMode={'contain'}
              onLoadStart={setImageZoomSize}
            />
          ) : null}
        </ImageZoom>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  header: {
    height: 56,
    position: Platform.OS === 'web' ? 'relative' : 'absolute',
    top: Platform.OS === 'web' ? 0 : -20,
    zIndex: 2,
    width: '100%',
    justifyContent: 'center',
  },
  backBtn: {
    height: 24,
    width: 24,
    marginLeft: 16,
  },
})

