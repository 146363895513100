import React, { useState } from 'react'
import { View, TouchableOpacity, Platform, StyleSheet } from 'react-native'
import { useLinkProps } from '@react-navigation/native'

export default function({ to, action, children, style, ...rest }: any) {
  const { onPress, ...props } = useLinkProps({ to, action })

  const [isHovered, setIsHovered] = useState(false)

  if (Platform.OS === 'web') {
    // It's important to use a `View` or `Text` on web instead of `TouchableX`
    // Otherwise React Native for Web omits the `onClick` prop that's passed
    // You'll also need to pass `onPress` as `onClick` to the `View`
    // You can add hover effects using `onMouseEnter` and `onMouseLeave`
    return (
      <View
        onClick={onPress}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={[styles.wrapper, { transitionDuration: '150ms', opacity: isHovered ? 0.5 : 1 }, style]}
        {...props}
        {...rest}
      >
        {children}
      </View>
    )
  }

  return (
    <TouchableOpacity onPress={onPress} {...props} {...rest} style={style}>
      {children}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
