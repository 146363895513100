import { takeLatest, all, put } from 'redux-saga/effects'


import {
  appStartedAction,
  APP_GUIDE_REQUEST,
  APP_INSTRUCTIONS_REQUEST,
  APP_REQUEST,
  APP_STARTED,
} from '../redux/app'
import {
  appGuideRequestSaga,
  appInstructionsRequestSaga,
  appRequestSaga,
  appStartSaga,
} from './app'
import { appPersistedAction, APP_INITIALIZED } from '../redux/_app'
import { appInitializedSaga } from './_app'
import { AUTH_LOGOUT_REQUEST, AUTH_REQUEST, AUTH_BY_EMAIL_REQUEST, AUTH_SET_TOKEN } from '../redux/auth'
import { authLogoutRequestSaga, authRequestSaga, authByEmailRequestSaga, authSetTokenSaga } from './auth'
import { NOTIFICATIONS_READ_REQUEST, NOTIFICATIONS_REQUEST } from '../redux/notifications'
import { notificationsReadRequestSaga, notificationsRequestSaga } from './notifications'
import { USER_PROFILE_DELETE_REQUEST, USER_PROFILE_REQUEST, USER_PROFILE_UPDATE_REQUEST } from '../redux/user'
import { userProfileDeleteSaga, userProfileRequestSaga, userProfileUpdateSaga } from './user'
import {
  APPOINTMENT_CANCEL_REQUEST,
  APPOINTMENT_FUTURE_REQUEST,
  APPOINTMENT_GENDER_REQUEST,
  APPOINTMENT_MAKE_REQUEST,
  APPOINTMENT_PAST_REQUEST,
  APPOINTMENT_PATIENT_REQUEST,
  APPOINTMENT_PRIMARY_RESERVE_REQUEST,
  APPOINTMENT_PRIMARY_SLOTS_REQUEST,
  APPOINTMENT_SECONDARY_RESERVE_DELETE_REQUEST,
  APPOINTMENT_SECONDARY_RESERVE_REQUEST,
  APPOINTMENT_SECONDARY_SLOTS_REQUEST,
  APPOINTMENT_PAYMENT_CANCEL_REQUEST,
  APPOINTMENT_PAYMENT_STATUS_REQUEST,
  APPOINTMENT_CHECK_REQUEST,
  APPOINTMENT_PRIMARY_CALENDAR_REQUEST,
  APPOINTMENT_SECONDARY_CALENDAR_REQUEST,
} from '../redux/appointments'
import {
  appointmentGendersRequestSaga,
  appointmentMakeRequestSaga,
  appointmentPatientCheckSaga,
  appointmentPrimaryReserveRequestSaga,
  appointmentPrimarySlotsRequestSaga,
  appointmentsFutureRequestSaga,
  appointmentsPastRequestSaga,
  appointmentCancelSaga,
  appointmentSecondarySlotsRequestSaga,
  appointmentSecondaryReserveRequestSaga,
  appointmentSecondaryReserveDeleteRequestSaga,
  appointmentPaymentCancelSaga,
  appointmentPaymentStatusSaga,
  appointmentCheckRequestSaga,
  appointmentPrimaryCalendarRequestSaga,
  appointmentSecondaryCalendarRequestSaga
} from './appointments'
import { FAQ_REQUEST } from '../redux/faq'
import { faqRequestSaga } from './faq'
import { SERVICE_REQUEST, SERVICE_BY_ID_REQUEST } from '../redux/services'
import { serviceRequestSaga, serviceByIdRequestSaga } from './services'
import {
  CHAT_DELETE_FILE_REQUEST,
  CHAT_FILES_UPLOAD_REQUEST,
  CHAT_MESSAGE_REQUEST,
  CHAT_READ_ACTION,
  CHAT_REQUEST,
  CHAT_SEND_MESSAGE_REQUEST,
} from '../redux/chat'
import {
  chatFilesDeleteRequestSaga,
  chatFilesUploadRequestSaga,
  chatMessageRequestSaga,
  chatReadSaga,
  chatRequestSaga,
  chatSendMessageSaga,
} from './chat'
import {
  CONFERENCE_CHAT_DELETE_FILE_REQUEST,
  CONFERENCE_CHAT_FILES_UPLOAD_REQUEST,
  CONFERENCE_CHAT_MESSAGE_REQUEST,
  CONFERENCE_CHAT_READ_ACTION,
  CONFERENCE_CHAT_REQUEST,
  CONFERENCE_CHAT_SEND_MESSAGE_REQUEST,
} from '../redux/conference'
import {
  conferenceChatFilesDeleteRequestSaga,
  conferenceChatFilesUploadRequestSaga,
  conferenceChatMessageRequestSaga,
  conferenceChatReadSaga,
  conferenceChatRequestSaga,
  conferenceChatSendMessageSaga,
} from './conference'
import { PROMOCODE_REQUEST } from '../redux/promocode'
import { promocodeRequestSaga } from './promocode'
import { SERVICE_REVIEWS_REQUEST, CREATE_REVIEW_REQUEST } from '../redux/reviews'
import { serviceReviewsRequestSaga, createReviewRequestSaga } from './reviews'
import {
  TESTS_REQUEST,
  TESTS_CATEGORIES_REQUEST,
  TEST_REQUEST,
  TEST_RESULT_REQUEST,
} from '../redux/tests'
import {
  testsRequestSaga,
  testsCategoriesRequestSaga,
  testRequestSaga,
  testResultRequestSaga,
} from './tests'
import {
  ARTICLE_REQUEST,
  ARTICLE_BY_ID_REQUEST,
  ARTICLE_LIKE_REQUEST,
  ARTICLE_DISLIKE_REQUEST,
  ARTICLE_REPOST_REQUEST,
  ARTICLE_SET_VIEWED_REQUEST,
} from '../redux/articles'
import {
  articleRequestSaga,
  articleByIdRequestSaga,
  articleLikeRequestSaga,
  articleDislikeRequestSaga,
  articleRepostRequestSaga,
  articleSetViewedRequestSaga,
} from './articles'
import {
  QUESTION_REQUEST,
  USER_QUESTIONS_REQUEST,
  QUESTION_BY_ID_REQUEST,
  ASK_QUESTION_REQUEST,
} from '../redux/questions'
import {
  questionRequestSaga,
  userQuestionsRequestSaga,
  questionByIdRequestSaga,
  askQuestionRequestSaga,
} from './questions'
import {
  TRACKERS_REQUEST,
  CREATE_TRACKER_REQUEST,
  EDIT_TRACKER_REQUEST,
  DELETE_TRACKER_REQUEST,
  TRACKER_RATES_REQUEST,
  TRACKER_SETTINGS_REQUEST,
  TRACKER_STATS_REQUEST,
  TRACKER_SEND_STATS_REQUEST,
} from '../redux/trackers'
import {
  trackersRequestSaga,
  createTrackerRequestSaga,
  editTrackerRequestSaga,
  deleteTrackerRequestSaga,
  trackerRatesRequestSaga,
  trackerSettingsRequestSaga,
  trackerStatsRequestSaga,
  trackerSendStatsRequestSaga,
} from './trackers'

function* watchIncrementAsync() {
  yield takeLatest('persist/REHYDRATE', onRegidrate)
  yield takeLatest(APP_STARTED, appStartSaga)
  yield takeLatest(AUTH_REQUEST, authRequestSaga)
  yield takeLatest(AUTH_BY_EMAIL_REQUEST, authByEmailRequestSaga)
  yield takeLatest(AUTH_LOGOUT_REQUEST, authLogoutRequestSaga)
  yield takeLatest(AUTH_SET_TOKEN, authSetTokenSaga)
  yield takeLatest(USER_PROFILE_REQUEST, userProfileRequestSaga)
  yield takeLatest(USER_PROFILE_DELETE_REQUEST, userProfileDeleteSaga)
  yield takeLatest(USER_PROFILE_UPDATE_REQUEST, userProfileUpdateSaga)
  yield takeLatest(NOTIFICATIONS_REQUEST, notificationsRequestSaga)
  yield takeLatest(NOTIFICATIONS_READ_REQUEST, notificationsReadRequestSaga)
  yield takeLatest(FAQ_REQUEST, faqRequestSaga)
  yield takeLatest(SERVICE_REQUEST, serviceRequestSaga)
  yield takeLatest(SERVICE_BY_ID_REQUEST, serviceByIdRequestSaga)
  yield takeLatest(CHAT_REQUEST, chatRequestSaga)
  yield takeLatest(CHAT_MESSAGE_REQUEST, chatMessageRequestSaga)
  yield takeLatest(CHAT_SEND_MESSAGE_REQUEST, chatSendMessageSaga)
  yield takeLatest(CHAT_FILES_UPLOAD_REQUEST, chatFilesUploadRequestSaga)
  yield takeLatest(CHAT_DELETE_FILE_REQUEST, chatFilesDeleteRequestSaga)
  yield takeLatest(CHAT_READ_ACTION, chatReadSaga)
  yield takeLatest(APP_GUIDE_REQUEST, appGuideRequestSaga)
  yield takeLatest(APP_INSTRUCTIONS_REQUEST, appInstructionsRequestSaga)
  yield takeLatest(APP_REQUEST, appRequestSaga)
  yield takeLatest(APPOINTMENT_FUTURE_REQUEST, appointmentsFutureRequestSaga)
  yield takeLatest(APPOINTMENT_PAST_REQUEST, appointmentsPastRequestSaga)
  yield takeLatest(APPOINTMENT_GENDER_REQUEST, appointmentGendersRequestSaga)
  yield takeLatest(APPOINTMENT_PRIMARY_CALENDAR_REQUEST, appointmentPrimaryCalendarRequestSaga)
  yield takeLatest(APPOINTMENT_PRIMARY_SLOTS_REQUEST, appointmentPrimarySlotsRequestSaga)
  yield takeLatest(APPOINTMENT_SECONDARY_CALENDAR_REQUEST, appointmentSecondaryCalendarRequestSaga)
  yield takeLatest(APPOINTMENT_SECONDARY_SLOTS_REQUEST, appointmentSecondarySlotsRequestSaga)
  yield takeLatest(APPOINTMENT_PRIMARY_RESERVE_REQUEST, appointmentPrimaryReserveRequestSaga)
  yield takeLatest(APPOINTMENT_SECONDARY_RESERVE_REQUEST, appointmentSecondaryReserveRequestSaga)
  yield takeLatest(APPOINTMENT_SECONDARY_RESERVE_DELETE_REQUEST, appointmentSecondaryReserveDeleteRequestSaga)
  yield takeLatest(APPOINTMENT_MAKE_REQUEST, appointmentMakeRequestSaga)
  yield takeLatest(APPOINTMENT_PATIENT_REQUEST, appointmentPatientCheckSaga)
  yield takeLatest(APPOINTMENT_CANCEL_REQUEST, appointmentCancelSaga)
  yield takeLatest(APPOINTMENT_PAYMENT_STATUS_REQUEST, appointmentPaymentStatusSaga)
  yield takeLatest(APPOINTMENT_PAYMENT_CANCEL_REQUEST, appointmentPaymentCancelSaga)
  yield takeLatest(CONFERENCE_CHAT_REQUEST, conferenceChatRequestSaga)
  yield takeLatest(CONFERENCE_CHAT_MESSAGE_REQUEST, conferenceChatMessageRequestSaga)
  yield takeLatest(CONFERENCE_CHAT_SEND_MESSAGE_REQUEST, conferenceChatSendMessageSaga)
  yield takeLatest(CONFERENCE_CHAT_FILES_UPLOAD_REQUEST, conferenceChatFilesUploadRequestSaga)
  yield takeLatest(CONFERENCE_CHAT_DELETE_FILE_REQUEST, conferenceChatFilesDeleteRequestSaga)
  yield takeLatest(CONFERENCE_CHAT_READ_ACTION, conferenceChatReadSaga)
  yield takeLatest(APP_INITIALIZED, appInitializedSaga)
  yield takeLatest(APPOINTMENT_CHECK_REQUEST, appointmentCheckRequestSaga)
  yield takeLatest(PROMOCODE_REQUEST, promocodeRequestSaga)
  yield takeLatest(SERVICE_REVIEWS_REQUEST, serviceReviewsRequestSaga)
  yield takeLatest(CREATE_REVIEW_REQUEST, createReviewRequestSaga)
  yield takeLatest(TESTS_REQUEST, testsRequestSaga)
  yield takeLatest(TESTS_CATEGORIES_REQUEST, testsCategoriesRequestSaga)
  yield takeLatest(TEST_REQUEST, testRequestSaga)
  yield takeLatest(TEST_RESULT_REQUEST, testResultRequestSaga)
  yield takeLatest(ARTICLE_REQUEST, articleRequestSaga)
  yield takeLatest(ARTICLE_BY_ID_REQUEST, articleByIdRequestSaga)
  yield takeLatest(ARTICLE_LIKE_REQUEST, articleLikeRequestSaga)
  yield takeLatest(ARTICLE_DISLIKE_REQUEST, articleDislikeRequestSaga)
  yield takeLatest(ARTICLE_REPOST_REQUEST, articleRepostRequestSaga)
  yield takeLatest(ARTICLE_SET_VIEWED_REQUEST, articleSetViewedRequestSaga)
  yield takeLatest(QUESTION_REQUEST, questionRequestSaga)
  yield takeLatest(USER_QUESTIONS_REQUEST, userQuestionsRequestSaga)
  yield takeLatest(QUESTION_BY_ID_REQUEST, questionByIdRequestSaga)
  yield takeLatest(ASK_QUESTION_REQUEST, askQuestionRequestSaga)
  yield takeLatest(TRACKERS_REQUEST, trackersRequestSaga)
  yield takeLatest(CREATE_TRACKER_REQUEST, createTrackerRequestSaga)
  yield takeLatest(EDIT_TRACKER_REQUEST, editTrackerRequestSaga)
  yield takeLatest(DELETE_TRACKER_REQUEST, deleteTrackerRequestSaga)
  yield takeLatest(TRACKER_RATES_REQUEST, trackerRatesRequestSaga)
  yield takeLatest(TRACKER_SETTINGS_REQUEST, trackerSettingsRequestSaga)
  yield takeLatest(TRACKER_STATS_REQUEST, trackerStatsRequestSaga)
  yield takeLatest(TRACKER_SEND_STATS_REQUEST, trackerSendStatsRequestSaga)
}

function* onRegidrate() {
  yield put(appPersistedAction())
  yield put(appStartedAction())
}

export default function* init() {
  yield all([watchIncrementAsync()])
}
