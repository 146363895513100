import { takeLatest, all, put } from 'redux-saga/effects'

import {
  appStartedAction,
  APP_STARTED,
  APP_REQUEST,
} from '../redux/app'
import {
  appStartSaga,
  appRequestSaga,
} from './app'
// import { APP_INITIALIZED, appPersistedAction } from '../redux/_app'
import { APP_INITIALIZED } from '../redux/_app'
import { appInitializedSaga } from './_app'
import { AUTH_LOGOUT_REQUEST, AUTH_REQUEST, AUTH_SET_TOKEN } from '../redux/auth'
import { authLogoutRequestSaga, authRequestSaga, authSetTokenSaga } from './auth'
import { USER_PROFILE_DELETE_REQUEST, USER_PROFILE_REQUEST, USER_PROFILE_UPDATE_REQUEST } from '../redux/user'
import { userProfileDeleteSaga, userProfileRequestSaga, userProfileUpdateSaga } from './user'
import {
  TRACKERS_REQUEST,
  CREATE_TRACKER_REQUEST,
  EDIT_TRACKER_REQUEST,
  DELETE_TRACKER_REQUEST,
  TRACKER_STATS_REQUEST,
} from '../redux/tracker'
import {
  trackersRequestSaga,
  createTrackerRequestSaga,
  editTrackerRequestSaga,
  deleteTrackerRequestSaga,
  trackerStatsRequestSaga,
} from './tracker'
import {
  EMPLOYEES_REQUEST,
  ADD_EMPLOYEE_REQUEST,
  EMPLOYEE_REQUEST,
  ADD_TRACKER_TO_EMPLOYEE_REQUEST,
  REPLACE_EMPLOYEE_TRACKER_REQUEST,
  UNLINK_EMPLOYEE_TRACKER_REQUEST,
  DELETE_EMPLOYEE_REQUEST,
} from '../redux/employee'
import {
  employeesRequestSaga,
  addEmployeeRequestSaga,
  employeeRequestSaga,
  addTrackerToEmployeeRequestSaga,
  replaceEmployeeTrackerRequestSaga,
  unlinkEmployeeTrackerRequestSaga,
  deleteEmployeeRequestSaga,
} from './employee'

function* watchIncrementAsync() {
  yield takeLatest('persist/REHYDRATE', onRegidrate)
  yield takeLatest(APP_STARTED, appStartSaga)
  yield takeLatest(APP_REQUEST, appRequestSaga)
  yield takeLatest(AUTH_REQUEST, authRequestSaga)
  yield takeLatest(AUTH_LOGOUT_REQUEST, authLogoutRequestSaga)
  yield takeLatest(AUTH_SET_TOKEN, authSetTokenSaga)
  yield takeLatest(USER_PROFILE_REQUEST, userProfileRequestSaga)
  yield takeLatest(USER_PROFILE_DELETE_REQUEST, userProfileDeleteSaga)
  yield takeLatest(USER_PROFILE_UPDATE_REQUEST, userProfileUpdateSaga)
  yield takeLatest(APP_INITIALIZED, appInitializedSaga)
  yield takeLatest(TRACKERS_REQUEST, trackersRequestSaga)
  yield takeLatest(CREATE_TRACKER_REQUEST, createTrackerRequestSaga)
  yield takeLatest(EDIT_TRACKER_REQUEST, editTrackerRequestSaga)
  yield takeLatest(DELETE_TRACKER_REQUEST, deleteTrackerRequestSaga)
  yield takeLatest(TRACKER_STATS_REQUEST, trackerStatsRequestSaga)
  yield takeLatest(EMPLOYEES_REQUEST, employeesRequestSaga)
  yield takeLatest(ADD_EMPLOYEE_REQUEST, addEmployeeRequestSaga)
  yield takeLatest(EMPLOYEE_REQUEST, employeeRequestSaga)
  yield takeLatest(ADD_TRACKER_TO_EMPLOYEE_REQUEST, addTrackerToEmployeeRequestSaga)
  yield takeLatest(REPLACE_EMPLOYEE_TRACKER_REQUEST, replaceEmployeeTrackerRequestSaga)
  yield takeLatest(UNLINK_EMPLOYEE_TRACKER_REQUEST, unlinkEmployeeTrackerRequestSaga)
  yield takeLatest(DELETE_EMPLOYEE_REQUEST, deleteEmployeeRequestSaga)
}

function* onRegidrate() {
  // yield put(appPersistedAction())
  yield put(appStartedAction())
}

export default function* init() {
  yield all([watchIncrementAsync()])
}
