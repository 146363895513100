import config from '../../config'
import { Localizations } from '../../localization'
import { Themes } from '../../theme'
import {
	AboutAppType,
	AppDocumentType,
	AppSuccessResponse,
	GuideSuccessResponse,
	GuideType,
	InstructionsSuccessResponse,
	MarketType,
	Nullable,
	StoresType,
	GuideRequest,
	InstructionsRequest,
} from '../../types'

export interface AppState {
	locale: Localizations
	deviceTheme: boolean
	theme: Themes
	show_guide: boolean
	guides: Nullable<GuideType[]>
	instructions: Nullable<GuideType[]>
	about: Nullable<AboutAppType[]>
	documents: Nullable<AppDocumentType[]>
	markets: Nullable<MarketType[]>
	fetching: boolean,
}

export const APP_STARTED = 'APP_STARTED'
export const APP_SET_THEME = 'APP_SET_THEME'
export const APP_SET_DEVICE_THEME = 'APP_SET_DEVICE_THEME'
export const APP_GUIDE_REQUEST = 'APP_GUIDE_REQUEST'
export const APP_GUIDE_SUCCESS = 'APP_GUIDE_SUCCESS'
export const APP_GUIDE_FAILURE = 'APP_GUIDE_FAILURE'
export const APP_INSTRUCTIONS_REQUEST = 'APP_INSTRUCTIONS_REQUEST'
export const APP_INSTRUCTIONS_SUCCESS = 'APP_INSTRUCTIONS_SUCCESS'
export const APP_INSTRUCTIONS_FAILURE = 'APP_INSTRUCTIONS_FAILURE'
export const APP_SET_SHOW_GUIDE = 'APP_SET_SHOW_GUIDE'
export const APP_REQUEST = 'APP_REQUEST'
export const APP_SUCCESS = 'APP_SUCCESS'
export const APP_LOADED = 'APP_LOADED'
export const APP_FAILURE = 'APP_FAILURE'

export const appStartedAction = () => ({ type: APP_STARTED })
export const appThemeAction = (params: string) => ({ type: APP_SET_THEME, params })
export const appDeviceThemeAction = (params: boolean) => ({ type: APP_SET_DEVICE_THEME, params })
export const appGuideRequestAction = (params?: GuideRequest) => ({ type: APP_GUIDE_REQUEST, params })
export const appGuideSuccessAction = (payload: GuideSuccessResponse) => ({ type: APP_GUIDE_SUCCESS, payload })
export const appGuideFailureAction = (payload: any) => ({ type: APP_GUIDE_FAILURE, payload })
export const appInstructionsRequestAction = (params?: InstructionsRequest) => ({ type: APP_INSTRUCTIONS_REQUEST, params })
export const appInstructionsSuccessAction = (payload: InstructionsSuccessResponse) => ({ type: APP_INSTRUCTIONS_SUCCESS, payload })
export const appInstructionsFailureAction = (payload: any) => ({ type: APP_INSTRUCTIONS_FAILURE, payload })
export const appSetShowGuide = (params: boolean) => ({ type: APP_SET_SHOW_GUIDE, params })
export const appRequestAction = () => ({ type: APP_REQUEST })
export const appSuccessAction = (payload: AppSuccessResponse) => ({ type: APP_SUCCESS, payload })
export const appLoadedAction = () => ({ type: APP_LOADED })
export const appFailureAction = (payload: any) => ({ type: APP_FAILURE, payload })

const initialState: AppState = {
	show_guide: true,
	deviceTheme: false,
	theme: 'light',
	locale: 'ru',
	guides: null,
	instructions: null,
	about: null,
	documents: null,
	markets: null,
	fetching: false,
}

const AppReducer = (state = initialState, action: any): AppState => {
	const { type, params, payload } = action
	config.showReduxAction && console.log(type, !!action.route ? action.route : '')

	switch (type) {
		case APP_STARTED: {
			return { ...state, fetching: true }
		}
		case APP_SET_THEME: {
			return { ...state, theme: params }
		}
		case APP_SET_DEVICE_THEME: {
			return { ...state, deviceTheme: params }
		}
		case APP_GUIDE_REQUEST: {
			return { ...state }
		}
		case APP_GUIDE_SUCCESS: {
			const { guides } = payload as GuideSuccessResponse
			return { ...state, guides }
		}
		case APP_GUIDE_FAILURE: {
			return { ...state }
		}
		case APP_INSTRUCTIONS_REQUEST: {
			return { ...state }
		}
		case APP_INSTRUCTIONS_SUCCESS: {
			const { instructions } = payload as InstructionsSuccessResponse
			return { ...state, instructions }
		}
		case APP_INSTRUCTIONS_FAILURE: {
			return { ...state }
		}
		case APP_SET_SHOW_GUIDE: {
			return { ...state, show_guide: params }
		}
		case APP_REQUEST: {
			return { ...state, }
		}
		case APP_SUCCESS: {
			const { app: { about, documents, markets } } = payload as AppSuccessResponse
			return { ...state, about, documents, markets }
		}
		case APP_LOADED: {
			return { ...state, fetching: false }
		}
		case APP_FAILURE: {
			return { ...state, }
		}
		default: return state
	}
}

export default AppReducer
