import React from 'react'
import { connect } from 'react-redux'
import { AppState } from '../store/redux'
import { StackScreenProps } from '@react-navigation/stack'
import { View, ViewStyle } from 'react-native'
import { GuideCarousel, Screen } from '../components'
// import { t } from '../localization'
import { MainNavigatorProps } from '../types'
import { ThemeColors, useTheme } from '../theme'

type ScreenProps = DispatchProps & StateProps & StackScreenProps<MainNavigatorProps, 'InstructionsScreen'>

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

interface Props extends ScreenProps {
	colors: ThemeColors,
}

interface State { }

function InstructionsScreenWrapper(props: Props) {
	const { colors } = useTheme()

	return <InstructionsScreen colors={colors} {...props} />
}

class InstructionsScreen extends React.Component<Props, State>{

	render() {
		const {
			navigation,
			instructions,
			colors,
		} = this.props

		return (
			<Screen>
				<View style={styles.carouselContainer}>
					<View style={[styles.carouselWrapper, { backgroundColor: colors.background.primary }]}>
					<GuideCarousel
						style={styles.carousel}
						data={instructions}
						onConfirmPress={() => navigation.navigate('MainStack', { screen: 'MainScreen' })}
						scrollEnabled
						isInstruction
					/>
					</View>
				</View>
			</Screen >
		)
	}

}

const mapStateToProps = (state: AppState) => ({
	instructions: state.app.instructions || [],
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(InstructionsScreenWrapper)

const styles = {
	carouselContainer: {
		flex: 1,
		alignItems: 'center',
		marginHorizontal: 30,
		marginVertical: 30,
	} as ViewStyle,
	carouselWrapper: {
		flex: 1,
		width: '55%',
		minWidth: 691,
		marginVertical: 'auto',
		borderRadius: 20,
		overflow: 'hidden',
	},
	carousel: {
		marginTop: 4,
	} as ViewStyle,
}
