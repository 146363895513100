import React from 'react'
import { Text, TextStyle, TouchableOpacity, View, ViewStyle, Platform } from 'react-native'
import Icon from './Icon'
import { font, ThemeColors, useTheme } from '../theme'

type ButtonsType = 'radioButton' | 'checkBox'

export interface RadioButtonProps {
	checked?: boolean
	leftText?: string
	rightText?: string
	disabled?: boolean
	onChange?: (value?: any) => void
	value?: string | number
	style?: ViewStyle | ViewStyle[]
	type?: ButtonsType
}

export default function RadioButton({
	checked,
	leftText,
	rightText,
	disabled,
	onChange,
	value,
	style,
	type = 'radioButton',
}: RadioButtonProps) {

	const { colors } = useTheme()

	return (
		<TouchableOpacity
			style={[styles.container, style]}
			onPress={!onChange ? undefined : () => type == 'radioButton' ? onChange(value) : onChange(!checked)}
			disabled={disabled || !onChange}
			activeOpacity={1}
		>
			{!!leftText && (
				<Text style={[styles.leftText, { color: colors.text.default }]}>{leftText}</Text>
			)}
			{type == 'radioButton'
				? renderRadiobutton(checked, disabled, type, colors)
				: renderCheckBox(checked, disabled, type, colors)}
			{!!rightText && (
				<Text style={[styles.rightText, { color: colors.text.default }]}>{rightText}</Text>
			)}
		</TouchableOpacity >
	)
}

function renderCheckBox(checked: boolean, disabled: boolean, type: ButtonsType, colors: ThemeColors) {
	const checkboxActiveBorderColor = Platform.OS !== 'web' ? colors.checkBox.active : colors.checkBox.activeWeb
	const checkboxIconActiveColor = Platform.OS !== 'web' ? colors.checkBox.secondary : colors.checkBox.active
	const checkboxBgColor = Platform.OS !== 'web' ? colors.checkBox.active : 'transparent'
	return (
		<View
			style={[
				styles.outherButton[type], {
					borderColor: !disabled ? checkboxActiveBorderColor : colors.checkBox.disabled,
					backgroundColor: checked ? checkboxBgColor : 'transparent',
					borderWidth: Platform.OS !== 'web' ? 1 : 2,
				 }]}
		>
			{checked && (
				<Icon
					name={'checkBoxCheck'}
					color={!disabled ? checkboxIconActiveColor : colors.checkBox.disabled}
					size={Platform.OS !== 'web' ? 20 : 10}
				/>
			)}
		</View>
	)
}

function renderRadiobutton(checked: boolean, disabled: boolean, type: ButtonsType, colors: ThemeColors) {
	return (
		<View
			style={[
				styles.outherButton[type],
				{
					borderColor: !disabled ? colors.checkBox.active : colors.checkBox.disabled,
					borderWidth: checked ? 7 : 1,
				}
			]}
		>
		</View>
	)
}

const commonStyles = {
	button: {
		width: Platform.OS !== 'web' ? 24 : 18,
		height: Platform.OS !== 'web' ? 24 : 18,
		borderWidth: 1,
		alignItems: 'center',
		justifyContent: 'center',
	} as ViewStyle,
	text: {
		flex: 1,
		fontFamily: font(),
		fontSize: 16,
		lineHeight: 20,
	} as TextStyle,
}

const styles = {
	container: {
		flexDirection: 'row',
		height: Platform.OS !== 'web' ? 'auto' : 'fit-content',
		alignItems: Platform.OS !== 'web' ? 'flex-start' : 'center',
	} as ViewStyle,
	outherButton: {
		radioButton: {
			...commonStyles.button,
			width: Platform.OS !== 'web' ? commonStyles.button.width : 24,
			height: Platform.OS !== 'web' ? commonStyles.button.height : 24,
			borderRadius: 12,
			alignItems: 'center',
			justifyContent: 'center',
		} as ViewStyle,
		checkBox: {
			...commonStyles.button,
			borderRadius: 5,
		} as ViewStyle,
	},
	leftText: {
		...commonStyles.text,
	} as TextStyle,
	rightText: {
		...commonStyles.text,
		marginLeft: Platform.OS !== 'web' ? 10 : 12,
		textAlign: Platform.OS !== 'web' ? 'auto' : 'left',
	} as TextStyle,
}
