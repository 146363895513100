import React, { useEffect, useRef, useState } from 'react'
import {
	Image,
	StyleSheet,
	Text,
	View,
	TouchableWithoutFeedback,
	TouchableOpacity,
	Linking,
} from 'react-native'
import Clipboard from '@react-native-clipboard/clipboard'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import 'moment/locale/ru'

moment().locale('ru')

import 'swiper/css'
import 'swiper/css/pagination'

import { DoctorModal, Icon, Alert, Markdown, Button } from '../components'
import { t } from '../localization'
import { font, useTheme } from '../theme'
import { ArticleType } from '../types'
import config from '../config'
import {
	articleDislikeRequestAction,
	articleLikeRequestAction,
	articleRepostRequestAction,
	articleSetViewedRequestAction,
} from '../store/redux/articles'
import { appointmentGenderRequestAction } from '../store/redux/appointments'
import { AppState } from '../store'

export default function ArticleCard({
	article,
	isFull = false,
}: {
	article: ArticleType
	isFull?: boolean
}) {
	const { colors } = useTheme()

	const dispatch = useDispatch()
	const navigation = useNavigation()

	const [showModal, setShowModal] = useState(false)
	const [imageSizes, setImageSizes] = useState({ width: 0, height: 0 })
	const [isAddNewAppointment, setIsAddNewAppointment] = useState(false)

	const { token } = useSelector((state: AppState) => state.auth)
	const genderList = useSelector((state: AppState) => state.appointment.doctor_gender_list)

	const swiperRef = useRef(null)

	const viewedArticlesIds = useSelector((state: AppState) => state.article.viewed_articles_ids) || []

	useEffect(() => {
		const timer = setTimeout(() => {
			if (isFull) {
				let userIsViewedrticle = viewedArticlesIds.some(item => item === article.id)
				if (!userIsViewedrticle) dispatch(articleSetViewedRequestAction({ id: article.id }))
			}
		}, 10000);
		return () => clearTimeout(timer);
	}, [])

	useEffect(() => {
    if (isAddNewAppointment && genderList) {
      navigation.navigate('AppointmentStack', {
        screen: 'NewAppointmentScreen',
        params: { serviceId: doctor.service_id },
      })
    }
  }, [genderList, isAddNewAppointment])

	const { doctor } = article
	const published = moment(article.created_at).format('DD.MM.YYYY')
	const hasImage = article.images.length > 0

	const renderImages = () => {
		if (isFull) {
			return (
				<View
					onLayout={e => {
						setImageSizes({
							width: e.nativeEvent.layout.width,
							height: e.nativeEvent.layout.width * 9 / 16
						})
					}}
				>
					{ !!imageSizes.width && (
						<Swiper
							style={carouselStyles.container}
							ref={swiperRef}
							autoHeight={true}
							pagination={true}
							modules={[Pagination]}
						>
							{ article.images.map((item, i) => {
								return (
									<SwiperSlide
										key={item.id}
										style={carouselStyles.item}
										virtualIndex={i}
									>
										<Image
											style={[
												carouselStyles.image, {
												height: imageSizes.height,
											}]}
											source={{ uri: item.url }}
											resizeMode={'contain'}
										/>
									</SwiperSlide>
								)
							})}
						</Swiper>
					)}
				</View>
			)
		}

		return (
			<View onLayout={e => {
				setImageSizes({
					width: e.nativeEvent.layout.width,
					height: e.nativeEvent.layout.width * 9 / 16
				})
			}}>
				<Image
					style={[
						styles.carouselImage, {
						height: imageSizes.height,
					}]}
					source={{ uri: article.images[0].thumbnail_url }}
					resizeMode={'cover'}
				/>
			</View>
		)
	}

	const onPressText = () => {
		navigation.navigate('ArticleStack', { screen: 'ArticleScreen', params: { id: article.id } })
	}

	const renderNewAppointmentButton = () => {
		return (
			<Button
				style={styles.newAppointmentButton}
				text={t('new_appointment')}
				onPress={() => {
					if (token && doctor.service_id) {
						dispatch(appointmentGenderRequestAction({ service_id: doctor.service_id }))
						setIsAddNewAppointment(true)
					} else {
						navigation.navigate('AuthStack', { screen: 'AuthScreen' })
					}
				}}
			/>
		)
	}

	return (
		<View style={[styles.container, { backgroundColor: colors.background.primary }]}>
			<View style={styles.header}>
				<TouchableWithoutFeedback onPress={() => setShowModal(true)}>
					<View style={styles.imageContainer}>
						<Image
							source={{ uri: doctor.photo.thumbnail + '?w=40h=40' }}
							style={styles.avatar}
							resizeMode={'cover'}
						/>
					</View>
				</TouchableWithoutFeedback>
				<View style={styles.title}>
					<TouchableWithoutFeedback
						style={styles.center}
						onPress={() => setShowModal(true)}
					>
						<View style={styles.column}>
							<Text style={[
								styles.doctor_name,
								{ color: colors.text.title },
							]}>{`${doctor.lastname} ${doctor.firstname}`}</Text>
							<Text style={[styles.headerText, {
								color: colors.text.primary,
							}]}>{doctor.is_platform ? t('platform_psychologist') : t('psychologist')}</Text>
						</View>
					</TouchableWithoutFeedback>
					<Text style={[
						styles.headerText, {
						color: colors.text.primary,
					}]}>{published}</Text>
				</View>
			</View>
			<View style={styles.contentWrapper}>
				{!isFull ? (
					<>
						<TouchableOpacity onPress={() => onPressText()}>
							<Markdown
								text={article.preview}
								paragraphStyle={[styles.text, { color: colors.text.default }]}
							/>
						</TouchableOpacity>
						<Text
							style={[styles.more, {
								color: colors.text.title,
							}]}
							onPress={() => onPressText()}
						>{t('more')}</Text>
					</>
				) : (
					<Markdown
						text={article.content}
						paragraphStyle={[styles.text, { color: colors.text.default }]}
					/>
				)}
			</View>
			{!!hasImage && renderImages()}
			{doctor.is_platform && renderNewAppointmentButton()}
			<View style={styles.actionsWrapper}>
				<View style={styles.actionsContainer}>
					<View style={styles.action}>
						<Icon
							style={styles.actionIcon}
							name='like'
							size={16}
							color={article.has_like ? colors.icon.like_active : colors.icon.inactive}
							onPress={() => {
								if (token) {
									dispatch(articleLikeRequestAction({ id: article.id }))
								} else {
									Alert.alert(
										'',
										t('article_set_action'),
										[{ text: t('back') }],
									)
								}
							}}
						/>
						<Text style={[
							styles.text, {
							color: colors.text.primary,
						}]}>{article.likes_count}</Text>
					</View>
					<View style={styles.action}>
						<Icon
							style={styles.actionIcon}
							name='dislike'
							size={16}
							color={article.has_dislike ? colors.icon.dislike_active : colors.icon.inactive}
							onPress={() => {
								if (token) {
									dispatch(articleDislikeRequestAction({ id: article.id }))
								} else {
									Alert.alert(
										'',
										t('article_set_action'),
										[{ text: t('back') }],
									)
								}
							}}
						/>
						<Text style={[
							styles.text, {
							color: colors.text.primary,
						}]}>{article.dislikes_count}</Text>
					</View>
					<View style={styles.action}>
						<Icon
							style={styles.actionIcon}
							name='send'
							size={16}
							color={colors.icon.inactive}
							onPress={async () => {
								if (navigator.share) {
									try {
										const shareResponse = await navigator.share({
											url: `${config.web_url}/articles/${article.id}`,
											title: t('share_article_title'),
											text: t('share_article_message'),
										})
										if (token) {
											dispatch(articleRepostRequestAction({ id: article.id }))
										}
									} catch (e) {}
								} else {
									await Clipboard.setString(`${config.web_url}/articles/${article.id}`)
									Alert.alert('', t('share_article_clipboard_message'), [])
									if (token) {
										dispatch(articleRepostRequestAction({ id: article.id }))
									}
								}
							}}
						/>
						<Text style={[
							styles.text, {
							color: colors.text.primary,
						}]}>{article.reposts_count}</Text>
					</View>
					<View style={styles.action}>
						<Icon
							style={styles.actionIcon}
							name='eye'
							size={16}
							color={colors.icon.inactive}
						/>
						<Text style={[
							styles.text, {
							color: colors.text.primary,
						}]}>{article.views_count}</Text>
					</View>
				</View>

				<View style={styles.actionsContainer}>
					{doctor.vk && (
						<Icon
							style={{ marginRight: 15 }}
							name={'vk'}
							color={colors.icon.light_green}
							size={14}
							onPress={async () => {
								await Linking.canOpenURL(doctor.vk)
								Linking.openURL(doctor.vk)
							}}
						/>
					)}
					{doctor.whatsapp && (
						<Icon
							style={{ marginRight: 15 }}
							name={'whatsapp'}
							color={colors.icon.light_green}
							size={14}
							onPress={async () => {
								await Linking.canOpenURL(doctor.whatsapp)
								Linking.openURL(doctor.whatsapp)
							}}
						/>
					)}
					{doctor.telegram && (
						<Icon
							name={'telegram'}
							color={colors.icon.light_green}
							size={14}
							onPress={async () => {
								await Linking.canOpenURL(doctor.telegram)
								Linking.openURL(doctor.telegram)
							}}
						/>
					)}
				</View>
			</View>
			<DoctorModal
				isVisible={showModal}
				onClose={() => setShowModal(false)}
				doctor={doctor}
			/>
		</View>
	)
}
const styles = StyleSheet.create({
	container: {
		marginBottom: 10,
		borderRadius: 20,
		padding: 16,
	},
	imageContainer: {
		width: 40,
		height: 40,
		borderRadius: 20,
		overflow: 'hidden',
		cursor: 'pointer',
	},
	avatar: {
		width: '100%',
		height: '100%',
	},
	header: {
		flexDirection: 'row',
		marginBottom: 16,
	},
	title: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginLeft: 10,
	},
	doctor_name: {
		fontFamily: font('bold'),
		fontSize: 14,
		marginBottom: 4,
	},
	headerText: {
		fontFamily: font(),
		fontSize: 12,
	},
	contentWrapper: {
		marginBottom: 16,
	},
	text: {
		fontFamily: font(),
		fontSize: 14,
	},
	more: {
		marginTop: 4,
		fontFamily: font('bold'),
		fontSize: 14,
	},
	center: {
		alignItems: 'center',
	},
	column: {
		flexDirection: 'column',
		cursor: 'pointer',
	},
	actionsWrapper: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginTop: 16,
	},
	actionsContainer: {
		flexDirection: 'row',
	},
	action: {
		flexDirection: 'row',
		alignItems: 'center',
		marginRight: 15,
	},
	actionIcon: {
		marginRight: 5,
	},
	newAppointmentButton: {
		marginTop: 16,
	},
})

const carouselStyles = {
	container: {
		width: '100%',
		paddingBottom: 20,
	},
	item: {
		textAlign: 'center',
	},
	image: {
		flex: 1,
		borderRadius: 20,
	},
}