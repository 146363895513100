import React, { useState } from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { mask } from 'react-native-mask-text'
import moment from 'moment'
import 'moment/locale/ru'
import startsWith from 'lodash/startsWith'

import { Button, Calendar, Icon, Markdown, PhoneInput, RadioButtonGroup, TextInput } from '.'
import { t } from '../localization'
import { Nullable, PhoneCountry } from '../types'
import { font, useTheme } from '../theme'
import { countries } from '../service'
import config from '../config'

moment().locale('ru')

interface ClientFormProps {
  lastname: Nullable<string>
  firstname: Nullable<string>
  middlename: Nullable<string>
  birthdate: Nullable<string>
  companyName?: Nullable<string>
  companySite?: Nullable<string>
  onChange: (value: { [key: string]: string | boolean }) => void
  onSavePress?: () => void
  gender: number
  phone?: string
  email?: string
  type?: 'profile' | 'client' | 'registration'
  minAge?: number
  phoneFilled?: boolean
  emailFilled?: boolean
}

export default function ({
  lastname,
  firstname,
  middlename,
  birthdate,
  gender,
  phone = '',
  email = '',
  companyName,
  companySite,
  onChange,
  onSavePress,
  minAge,
  type = 'profile',
  phoneFilled = false,
  emailFilled = false,
}: ClientFormProps) {

  const { colors } = useTheme()
  const [profilePhone, setProfilePhone] = useState(phone)
  const [showCalendar, setShowCalendar] = useState(false)

  let country: Nullable<PhoneCountry> = null
  let phoneFormatted = ''
  if (phone && phoneFilled) {
    if (phone.length === 11 && startsWith(phone, '79')) {
      country = countries.filter(country => country.id === 'ru')[0]
      phoneFormatted = `+7 ${mask(phone.substring(1), country?.mask)}`
    } else if (phone.length === 11 && startsWith(phone, '76') || startsWith(phone, '77')) {
      country = countries.filter(country => country.id === 'kz')[0]
      phoneFormatted = `+7 ${mask(phone.substring(1), country?.mask)}`
    } else if (phone.length === 12 && startsWith(phone, '998')) {
      country = countries.filter(country => country.id === 'uz')[0]
      phoneFormatted = `+998 ${mask(phone.substring(3), country?.mask)}`
    } else if (phone.length === 12 && startsWith(phone, '375')) {
      country = countries.filter(country => country.id === 'by')[0]
      phoneFormatted = `+375 ${mask(phone.substring(3), country?.mask)}`
    }
  }

  const onPhoneChange = ({
    phone,
    phoneFormatted,
    country,
  }: {
    phone: string,
    phoneFormatted: string,
    country: PhoneCountry,
  }) => {
    setProfilePhone(phoneFormatted)
    onChange({ phone: `${country.code}${phone}` })
  }

  return (
    <View style={[styles.container, { backgroundColor: colors.background.primary }]}>
      {type == 'client' && (
        <View style={styles.clientTitleContainer}>
          <Text style={[styles.clientTitleTitle, { color: colors.text.subtitle }]}>
            {t('client_data')}
          </Text>
          <Text style={[styles.clientTitleDescription, { color: colors.text.primary }]}>
            {t('client_description')}
          </Text>
        </View>
      )}
      <View style={styles.line}>
        <View style={[styles.row, styles.lineRow]}>
          <Text style={[styles.label, { color: colors.text.default }]}>{t('lastname')}</Text>
          <TextInput
            textStyle={{ height: 40 }}
            forceCapitalize={true}
            value={lastname || ''}
            onChange={lastname => onChange({ lastname })}
          />
        </View>
        <View style={[styles.row, styles.lineRow, { marginHorizontal: 8 }]}>
          <Text style={[styles.label, { color: colors.text.default }]}>{t('firstname')}</Text>
          <TextInput
            textStyle={{ height: 40 }}
            forceCapitalize={true}
            value={firstname || ''}
            onChange={firstname => onChange({ firstname })}
          />
        </View>
        <View style={[styles.row, styles.lineRow]}>
          <Text style={[styles.label, { color: colors.text.default }]}>{t('middlename')}</Text>
          <TextInput
            textStyle={{ height: 40 }}
            forceCapitalize={true}
            value={middlename || ''}
            onChange={middlename => onChange({ middlename })}
          />
        </View>
      </View>
      <View style={styles.line}>
        <View style={[styles.row, styles.lineRow, { flex: 1, marginRight: 8 }]}>
          <Text style={[styles.label, { color: colors.text.default }]}>{t('birthdate')}</Text>
          <TouchableOpacity
            activeOpacity={1}
            style={[styles.calendarContainer, { backgroundColor: colors.background.input }]}
            onPress={() => setShowCalendar(true)}
          >
            <Text style={styles.birthdate}>{!birthdate ? '' : moment(birthdate).format('DD MMMM YYYY')}</Text>
            <Icon name={'calendar'} />
          </TouchableOpacity>
        </View>
        {type !== 'client' && (
          <View style={{ flex: 2 }}></View>
        )}
        {type === 'client' && (
          <View style={[styles.row, styles.hintWrapper, { textAlign: 'left' }]}>
            <Markdown
              text={t('client_age_description', [minAge])}
              paragraphStyle={[markdownStyles.ageDescription, { color: colors.text.primary }]}
            />
          </View>
        )}
      </View>
      <View style={styles.row}>
        <Text style={[styles.label, { color: colors.text.default }]}>{t('gender')}</Text>
        <RadioButtonGroup
          buttons={[
            { rightText: t('male'), value: 1 },
            { rightText: t('female'), value: 0 },
          ]}
          checked={gender}
          onSelect={gender => onChange({ gender })}
          horizontal
        />
      </View>
      {!config.isB2B ? (
        <>
          {(type === 'profile' || (type === 'registration' && phoneFilled)) && (
            <View style={styles.line}>
              <View style={[styles.row, { flex: 1, marginRight: 8 }]}>
                <Text style={[styles.label, { color: colors.text.default }]}>{t('phone_number')}</Text>
                <TextInput
                  textStyle={{ height: 40 }}
                  value={phoneFormatted}
                  readonly
                />
              </View>
              <View style={[styles.hintWrapper]}>
                {type === 'profile' && (
                  <Text style={[styles.hint, { color: colors.text.date }]}>{t('hint_for_change_number')}</Text>
                )}
              </View>
            </View>
          )}
          {type === 'registration' && !phoneFilled && (
            <View style={styles.line}>
              <View style={[styles.row, { flex: 2, marginRight: 8 }]}>
                <Text style={[styles.label, { color: colors.text.default }]}>{t('phone_number')}</Text>
                <PhoneInput
                  type={'phone'}
                  value={profilePhone}
                  onChange={onPhoneChange}
                  placeholder={t('phone_number')}
                />
              </View>
              <View style={{ flex: 1 }}></View>
            </View>
          )}
          {(type === 'profile' || type === 'registration') && (
            <View style={styles.row}>
              <Text style={[styles.label, { color: colors.text.default }]}>{t('email')}</Text>
              <TextInput
                textStyle={{ height: 40 }}
                value={email || ''}
                onChange={email => onChange({ email })}
                keyboardType={'email-address'}
                readonly={emailFilled}
              />
            </View>
          )}
        </>
      ) : (
        <>
          <View style={styles.line}>
            <View style={[styles.row, styles.lineRow]}>
              <Text style={[styles.label, { color: colors.text.default }]}>{t('company_name')}</Text>
              <TextInput
                textStyle={{ height: 40 }}
                forceCapitalize={true}
                value={companyName || ''}
                onChange={companyName => onChange({ companyName })}
              />
            </View>
            <View style={[styles.row, styles.lineRow, { marginHorizontal: 8 }]}>
              <Text style={[styles.label, { color: colors.text.default }]}>{t('site')}</Text>
              <TextInput
                textStyle={{ height: 40 }}
                forceCapitalize={true}
                value={companySite || ''}
                onChange={companySite => onChange({ companySite })}
              />
            </View>
            <View style={{ flex: 1 }}></View>
          </View>
        </>
      )}
      {!!onSavePress && (
        <Button style={styles.saveButton} text={t('save')} onPress={onSavePress} />
      )}
      <Calendar
        isVisible={showCalendar}
        onClose={() => setShowCalendar(false)}
        dates={[birthdate]}
        onSelect={(dates: string[]) => {
          onChange({ birthdate: dates[0] })
          setShowCalendar(false)
        }}
      />
    </View>
  )
}


const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
    overflow: 'hidden',
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  clientTitleContainer: {
    marginBottom: 16,
  },
  clientTitleTitle: {
    fontFamily: font(),
    fontSize: 20,
    lineHeight: 24,
  },
  clientTitleDescription: {
    marginTop: 8,
    fontFamily: font(),
    fontSize: 14,
    lineHeight: 16,
  },
  row: {
    marginBottom: 16,
  },
  line: {
    flexDirection: 'row',
  },
  lineRow: {
    flex: 1,
  },
  label: {
    fontFamily: font('bold'),
    fontSize: 14,
    lineHeight: 17,
    marginBottom: 4,
    textAlign: 'left',
  },
  hintWrapper: {
    flex: 2,
    alignSelf: 'center',
  },
  hint: {
    fontFamily: font(),
    fontSize: 14,
    lineHeight: 16,
    textAlign: 'left'
  },
  calendarContainer: {
    paddingVertical: 8,
    paddingLeft: 11,
    paddingRight: 10,
    borderRadius: 10,
    overflow: 'hidden',
    flexDirection: 'row',
    alignItems: 'center'
  },
  birthdate: {
    fontFamily: font(),
    fontSize: 16,
    lineHeight: 19,
    minHeight: 19,
    flex: 1,
    textAlign: 'left',
  },
  saveButton: {
    marginBottom: 16
  },
})

const markdownStyles = {
  ageDescription: {
    fontFamily: font(),
    fontSize: 12,
    lineHeight: 14,
  },
}
