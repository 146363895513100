import React, { useState, useEffect } from 'react'
import {
  StyleSheet,
  Text,
  View,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
  Image,
  Dimensions,
} from 'react-native'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import { Button, Expandable, Icon, Modal, Screen } from '../components'
import { t } from '../localization'
import { font, useTheme } from '../theme'
import { Nullable, TestCategory } from '../types'
import { AppState } from '../store'
import { testsCategoriesRequestAction, testRequestAction } from '../store/redux/tests'
import { usePrevious } from '../hooks/usePrevious'
import { defaultTestMini, defaultAchieve } from '../images'

const ModalTestTextBlock = ({ label, text }: { label: string, text: string }) => {
  const { colors } = useTheme()
  return (
    <>
      <Text style={[
        styles.modalTestLabel,
        { color: colors.text.default }
      ]}>{label}</Text>
      <Text style={[
        styles.modalTestText,
        { color: colors.text.default }
      ]}>{text}</Text>
    </>
  )
}

export default function TestListScreen() {
  const { colors } = useTheme()
  const navigation = useNavigation()
  const dispatch = useDispatch()

  const testsCategories = useSelector((state: AppState) => state.test.testsCategories)
  const testsCategoriesFetching = useSelector((state: AppState) => state.test.testsCategoriesFetching)
  const test = useSelector((state: AppState) => state.test.test)

  const [expanded, setExpanded] = useState(-1)
  const [isTestModalVisible, setIsTestModalVisible] = useState(false)
  const [isRepeatModalVisible, setIsRepeatModalVisible] = useState(false)
  const [clickedTestId, setClickedTestId] = useState<Nullable<number>>(null)
  const [screenData, setScreenData] = useState(Dimensions.get('window'))

  const previousTest = usePrevious(test)

  const isFocused = useIsFocused()

  useEffect(() => {
    const onChange = (result) => {
      setScreenData(result.window)
    }

    const subscription = Dimensions.addEventListener('change', onChange)

		return () => subscription?.remove()
  })

  useEffect(() => {
    dispatch(testsCategoriesRequestAction())
  }, [])

  useEffect(() => {
    if (isFocused && test && clickedTestId === test.id && !isEqual(previousTest, test)) {
      setIsTestModalVisible(true)
    }
  }, [test])

  useEffect(() => {
    setExpanded(-1)
  }, [testsCategories])

  const onHeaderPress = (index: number) => {
    setExpanded(expanded === index ? -1 : index)
  }

  const onTestPress = (id: number) => {
    setClickedTestId(id)
    if (id === test?.id) {
      setIsTestModalVisible(true)
    }
    dispatch(testRequestAction({ id }))
  }

  const renderCategory = ({ item, index }: { item: TestCategory, index: number }) => {
    const isOpen = expanded === index
    return (
      <Expandable
        isOpen={isOpen}
        style={[
          styles.expandableContainer, {
            backgroundColor: !isOpen ? colors.background.primary : colors.background.default,
          },
        ]}
        header={
          <TouchableOpacity
            activeOpacity={1}
            style={[styles.headerContainer, { paddingHorizontal: isOpen ? 0 : 12 }]}
            onPress={() => onHeaderPress(index)}
          >
            <Text style={[styles.title, { color: isOpen ? colors.text.title : colors.text.default }]} >
              {item.title}
            </Text>
            <Icon
              name={isOpen ? 'collapse' : 'expand'}
              style={styles.icon}
              color={colors.icon.primary}
            />
          </TouchableOpacity>
        }
      >
        <View style={styles.expandableContent}>
          {
            item.tests.map(test => {
              const image = test.has_result
                ? test.result.achievement_image ? { uri: test.result.achievement_image } : defaultAchieve
                : test.image ? { uri: test.image } : defaultTestMini
              return (
                <TouchableOpacity
                  key={`${test.id}`}
                  activeOpacity={1}
                  style={[styles.test, { backgroundColor: colors.background.primary }]}
                  onPress={() => onTestPress(test.id)}
                >
                  <View>
                    <Image
                      style={styles.preview}
                      source={image}
                    />
                    {test.has_result && (
                      <Text style={[
                        styles.finished,
                        {
                          color: colors.text.title,
                          backgroundColor: colors.background.default,
                        }
                      ]}>{t('test_finished')}</Text>
                    )}
                  </View>
                  <Text style={{ flex: 1 }}>{test.title}</Text>
                </TouchableOpacity>
              )
            })
          }
        </View>
      </Expandable>
    )
  }

  const onTestModalClose = () => {
    setIsTestModalVisible(false)
  }

  const onRepeatModalClose = () => {
    setIsRepeatModalVisible(false)
  }

  const onPressStart = () => {
    setIsTestModalVisible(false)
    if (!test?.result) {
      navigation.navigate('TestScreen', { id: test?.id, from: 'testList' })
    } else {
      setTimeout(() => setIsRepeatModalVisible(true), 500)
    }
  }

  const onModalRepeatConfirm = () => {
    setIsRepeatModalVisible(false)
    navigation.navigate('TestScreen', { id: test?.id, from: 'testList' })
  }

  const isSmallScreen = screenData.width < 635

  return (
    <Screen>
      {testsCategoriesFetching ? (
        <View style={styles.activityIndicator}>
          <ActivityIndicator size={'large'} color={colors.fetching} style={{ padding: 16 }} />
        </View>
      ) : (
        <FlatList
          bounces={false}
          contentContainerStyle={[
            styles.container,
            {
              width: !isSmallScreen ? '47.22%' : '100%',
              minWidth: !isSmallScreen ? 635 : 'auto',
            },
          ]}
          keyExtractor={(item, index) => `${index}`}
          data={testsCategories}
          renderItem={renderCategory}
          ListEmptyComponent={() => (
            <View style={[styles.emptyListContainer, { backgroundColor: colors.background.primary }]}>
              <Text style={[styles.emptyListText, { color: colors.text.empty }]}>
                {t('tests_soon')}
              </Text>
            </View>
          )}
          ListHeaderComponent={
            <Text style={[styles.pageTitle, { color: colors.text.title }]}>{t('tests')}</Text>
          }
        />
      )}
      <Modal
        isVisible={isTestModalVisible}
        onClose={onTestModalClose}
        position='center'
        width='80%'
      >
        <View style={styles.modalTestContainer}>
          <Text style={[styles.modalTestHeaderTitle, { color: colors.text.title }]}>
            {
              !test?.result
                ? t('first_testing')
                : t('retesting')
            }
          </Text>
          { !!test?.image && (
            <Image
              style={styles.modalTestPreview}
              source={{ uri: test?.image }}
              resizeMode='contain'
            />
          )}
          <Text style={[styles.modalTestTitle, {
            color: colors.text.title,
            marginTop: !!test?.image ? 10 : 0,
          }]}>
            {test?.title}
          </Text>
          { test?.result && (
            <ModalTestTextBlock label={t('test_title')} text={test?.result.description} />
          )}
          { !test?.result && test?.author && (
            <ModalTestTextBlock label={t('test_author')} text={test.author} />
          )}
          { !test?.result && (
            <ModalTestTextBlock
              label={t('test_questions_count')}
              text={`${test?.questions.length}`}
            />
          )}
          <ModalTestTextBlock
            label={t('test_instruction')}
            text={test?.description || ''}
          />
          { test?.result && test?.author && (
            <ModalTestTextBlock label={t('test_author')} text={test.author} />
          )}
          <Button
            text={
              !test?.result
                ? t('test_start')
                : t('test_repeat')
            }
            style={styles.modalTestButton}
            onPress={onPressStart}
          />
        </View>
      </Modal>
      <Modal
        isVisible={isRepeatModalVisible}
        onClose={onRepeatModalClose}
        position='center'
        width={375}
      >
        <View style={styles.modalRepeatContainer}>
          <Text style={[styles.modalRepeatHeaderTitle, { color: colors.text.title }]}>
            { t('retesting') }
          </Text>
          <Text style={[styles.modalRepeatText, { color: colors.text.secondary }]}>
            {t('test_repeat_confirm')}
          </Text>
          <View style={styles.modalRepeatButtonContainer}>
            <Button
              text={t('yes')}
              style={styles.modalRepeatButton}
              type={'secondary'}
              onPress={onModalRepeatConfirm}
            />
            <Button
              text={t('no')}
              style={styles.modalRepeatButton}
              onPress={onRepeatModalClose}
            />
          </View>
        </View>
      </Modal>
    </Screen>
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    alignItems: 'stretch',
    alignSelf: 'center',
    marginVertical: 40,
    paddingHorizontal: 20,
  },
  pageTitle: {
    fontFamily: font(),
    fontSize: 24,
    lineHeight: 29,
    marginBottom: 24,
  },
  expandableContainer: {
    paddingVertical: 16,
    borderRadius: 20,
    marginVertical: 12,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    flex: 1,
    fontFamily: font('bold'),
    fontSize: 16,
    lineHeight: 19,
  },
  icon: {
    marginLeft: 12,
  },
  expandableContent: {
    marginTop: 16,
  },
  test: {
    paddingHorizontal: 12,
    paddingVertical: 16,
    borderRadius: 20,
    marginBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  preview: {
    width: 74,
    height: 74,
    marginRight: 12,
    borderRadius: 16,
  },
  finished: {
    position: 'absolute',
    width: 74,
    bottom: 0,
    fontFamily: font(),
    fontSize: 12,
    lineHeight: 23,
    textAlign: 'center',
    borderRadius: 5,
  },
  emptyListContainer: {
    flex: 1,
    paddingVertical: 16,
    borderRadius: 20,
    marginHorizontal: 18,
    marginVertical: 12,
    alignItems: 'center',
  },
  emptyListText: {
    fontFamily: font('bold'),
    fontSize: 18,
  },

  modalTestContainer: {
    flex: 1,
    paddingHorizontal: 16,
  },
  modalTestHeaderTitle: {
    fontFamily: font('bold'),
    fontSize: 20,
    lineHeight: 24,
  },
  modalTestTitle: {
    fontFamily: font('bold'),
    fontSize: 20,
    lineHeight: 24,
    marginBottom: 10,
  },
  modalTestLabel: {
    fontFamily: font('bold'),
    fontSize: 14,
    lineHeight: 17,
    marginBottom: 5,
  },
  modalTestText: {
    fontFamily: font(),
    fontSize: 14,
    marginBottom: 10,
  },
  modalTestPreview: {
    flex: 1,
    height: 224,
    marginTop: 16,
  },
  modalTestButton: {
    height: 40,
  },
  modalRepeatContainer: {
    flex: 1,
    paddingHorizontal: 16,
  },
  modalRepeatHeaderTitle: {
    fontFamily: font('bold'),
    fontSize: 20,
    lineHeight: 24,
    marginBottom: 14,
  },
  modalRepeatText: {
    fontFamily: font(),
    fontSize: 16,
  },
  modalRepeatButtonContainer: {
    flex: 1,
    marginTop: 24,
    flexDirection: 'row',
  },
  modalRepeatButton: {
    flex: 1,
    marginHorizontal: 5,
  },

  activityIndicator: {
    position: 'absolute',
    flex: 1,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
})