import React from 'react'
import { View, StyleSheet, Platform, ViewStyle } from 'react-native'

import { Button } from '.'
import { font, useTheme } from '../theme'

interface TabBarProps {
	containerStyle?: ViewStyle
	active: number
	onChange: (active: number) => void
	leftTabText: string
	rightTabText: string
}

export default function TabBar({
	containerStyle,
	active,
	onChange,
	leftTabText,
	rightTabText,
}: TabBarProps) {
	const { colors } = useTheme()
	return (
		<View style={[containerStyle, tabBatStyles.container, { backgroundColor: colors.background.secondary }]}>
			<Button
				style={tabBatStyles.button}
				text={leftTabText}
				onPress={() => onChange(0)}
				type={active === 0 ? 'primary' : 'secondary'} />
			<Button
				style={tabBatStyles.button}
				text={rightTabText}
				onPress={() => onChange(1)}
				type={active === 1 ? 'primary' : 'secondary'} />
		</View>
	)
}

const tabBatStyles = StyleSheet.create({
  container: {
    marginHorizontal: Platform.OS !== 'web' ? 16 : 'initial',
    borderRadius: 46,
    overflow: 'hidden',
    flexDirection: 'row',
    maxWidth: Platform.OS !== 'web' ? 'auto' : 343,
  },
	text: {
		fontFamily: font(),
		fontSize: 16,
		lineHeight: 19,
		paddingVertical: 10,
	},
	button: {
		flex: 1,
		borderRadius: 50,
	},
})
