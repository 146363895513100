import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { AppState } from '../store/redux'
import { StackScreenProps } from '@react-navigation/stack'
import {
	Image,
	ScrollView,
	StyleSheet,
	Text,
	TouchableOpacity,
	View,
	ViewStyle,
	Dimensions,
} from 'react-native'
import { isNull } from 'lodash'

import { Screen, Button } from '../components'
import { t } from '../localization'
import { font, useTheme } from '../theme'
import { PsyFemale, PsyMale } from '../images'
import { appointmentNewAddData } from '../store/redux/appointments'
import { AppointmentNavigatorProps, Nullable } from '../types'

type ScreenProps = DispatchProps & StateProps & StackScreenProps<AppointmentNavigatorProps, 'NewAppointmentGenderScreen'>

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

interface Props extends ScreenProps {
	isSmallScreen: boolean
}

interface State {
	doctorGender: Nullable<number>
}

function NewAppointmentGenderScreenWrapper(props: Props) {
	const { colors } = useTheme()
	const [screenData, setScreenData] = useState(Dimensions.get('window'))

	useEffect(() => {
		const onChange = (result) => {
			setScreenData(result.window)
		}

		const subscription = Dimensions.addEventListener('change', onChange)

		return () => subscription?.remove()
	})

	return <NewAppointmentGenderScreen colors={colors} isSmallScreen={screenData.width < 655} {...props} />
}

class NewAppointmentGenderScreen extends React.Component<Props, State>{
	constructor(props: Props) {
		super(props)

		this.state = {
			doctorGender: props.gender || null,
		}
	}

	componentDidMount() {
		const { doctor_gender_list, navigation } = this.props
		if (!doctor_gender_list.length) {
			navigation.replace('MainStack', { screen: 'MainScreen' })
		}
	}

	render() {
		const { doctor_gender_list, colors, isSmallScreen } = this.props
		const { doctorGender } = this.state
		return (
			<Screen>
				<ScrollView
					bounces={false}
					contentContainerStyle={styles.contentContainer}
				>
					<View style={[
						styles.contentWrapper,
						{
							width: !isSmallScreen ? '55%' : '100%',
							minWidth: !isSmallScreen ? 655 : 'auto',
						},
					]}>
						<Text style={[styles.title, { color: colors.text.title }]}>{t('choose_psychologist')}</Text>
						<Title />
						<View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 12 }}>
							{doctor_gender_list.map((item, index) => (
								<GenderCard
									key={index.toString()}
									style={{ flex: 1, marginLeft: index === 1 ? 12 : 0 }}
									gender={item}
									onPress={this.onGenderSelect}
									active={doctorGender === item}
								/>
							))}
						</View>
						<View style={styles.buttonsGroup}>
							<Button
								style={styles.prevButton}
								type='secondary'
								text={t('prev')}
								onPress={this.onPrevPress}
							/>
							<Button
								style={styles.nextButton}
								text={t('next')}
								disabled={isNull(doctorGender)}
								onPress={this.onNextPress}
							/>
						</View>
					</View>
				</ScrollView>
			</Screen>
		)
	}

	onPrevPress = () => {
		const { navigation } = this.props
		navigation.goBack()
	}

	onNextPress = () => {
		const { navigation, setAppointmentData, service_id } = this.props
		const { doctorGender } = this.state
		// @ts-ignore
		setAppointmentData({ doctorGender })
		navigation.navigate('NewAppointmentDatetimeScreen', { service_id, gender: doctorGender })
	}

	onGenderSelect = (doctorGender: 0 | 1) => {
		this.setState({ doctorGender })
	}

}

const mapStateToProps = (state: AppState) => ({
	doctor_gender_list: state.appointment.doctor_gender_list || [],
	service_id: state.appointment.new_appointment?.serviceId,
	gender: state.appointment.new_appointment?.doctorGender,
})

const mapDispatchToProps = {
	setAppointmentData: appointmentNewAddData,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewAppointmentGenderScreenWrapper)

const styles = {
	contentContainer: {
		flexGrow: 1,
	} as ViewStyle,
	contentWrapper: {
		flexGrow: 1,
		marginVertical: 40,
		paddingHorizontal: 30,
		alignSelf: 'center',
	},
	title: {
		marginBottom: 24,
		fontFamily: font(),
		fontSize: 24,
		lineHeight: 29,
	},
	buttonsGroup: {
		flexDirection: 'row',
		marginTop: 16,
	},
	prevButton: {
		flex: 1,
	},
	nextButton: {
		flex: 1,
		marginLeft: 12,
	},
}

function Title() {

	const { colors } = useTheme()

	return (
		<Text style={[titleStyles.title, { color: colors.text.primary }]}>
			{t('choose_psychologist_gender')}
		</Text>
	)
}


const titleStyles = StyleSheet.create({
	title: {
		fontFamily: font(),
		fontSize: 14,
		lineHeight: 16,
		marginBottom: 12,
	},
})

function GenderCard({
	style,
	gender,
	onPress,
	active,
}: {
	style: ViewStyle,
	gender: 0 | 1
	onPress: (gender: 0 | 1) => void
	active: boolean
}) {

	const { colors } = useTheme()

	return (
		<TouchableOpacity
			activeOpacity={1}
			style={[
				style,
				genderCardStyles.container,
				{
					backgroundColor: colors.background.primary,
					borderWidth: 2,
					borderColor: active ? colors.border.active : 'transparent',
				}
			]}
			onPress={() => onPress(gender)}
		>
			<View style={genderCardStyles.imageContainer}>
				<Image
					source={!!gender ? PsyMale : PsyFemale}
					style={genderCardStyles.image}
					resizeMode={'cover'}
				/>
			</View>
			<View style={genderCardStyles.textContainer}>
				<Text style={[genderCardStyles.label, { color: colors.text.primary }]}>
					{t('psychologist')}
				</Text>
				<Text style={[genderCardStyles.text, { color: colors.text.default }]}>
					{t(!!gender ? 'male' : 'female')}
				</Text>
			</View>
		</TouchableOpacity>
	)
}

const genderCardStyles = StyleSheet.create({
	container: {
		borderRadius: 20,
		overflow: 'hidden',
		padding: 14,
		flexDirection: 'row',
	},
	imageContainer: {
		borderRadius: 20,
		overflow: 'hidden',
		width: 92,
		height: 92,
		marginRight: 20,
	},
	image: {
		width: 92,
		height: 92,
	},
	textContainer: {
		justifyContent: 'center',
	},
	label: {
		fontFamily: font(),
		fontSize: 16,
		lineHeight: 19,
	},
	text: {
		fontFamily: font(),
		fontSize: 24,
		lineHeight: 29,
	},
})
