import { put, call } from 'redux-saga/effects'
import { ApiResponse } from 'apisauce'

import { api, ApiSuccessResponse } from '../../api'
import {
	Action,
	TrackerRequest,
	TrackerSuccessResponse,
	CreateTrackerRequest,
	CreateTrackerSuccessResponse,
	EditTrackerRequest,
	EditTrackerSuccessResponse,
	DeleteTrackerRequest,
	DeleteTrackerSuccessResponse,
	TrackerStatsRequest,
	TrackerStatsSuccessResponse,
} from '../../types'
import {
	trackersSuccessAction,
	trackersFailureAction,
	createTrackerSuccessAction,
	createTrackerFailureAction,
	editTrackerSuccessAction,
	editTrackerFailureAction,
	deleteTrackerSuccessAction,
	deleteTrackerFailureAction,
	trackerStatsSuccessAction,
	trackerStatsFailureAction,
} from '../redux/tracker'

export function* trackersRequestSaga(action: Action<TrackerRequest>) {
	const response: ApiResponse<ApiSuccessResponse<TrackerSuccessResponse>> = yield call(api.trackerRequest, { 'tz_offset': action.params?.tz_offset })
	if (response.ok) {
		yield put(trackersSuccessAction({ trackers: response.data!.result.trackers }))
	} else {
		yield put(trackersFailureAction(response.data))
	}
}

export function* createTrackerRequestSaga(action: Action<CreateTrackerRequest>) {
	const response: ApiResponse<ApiSuccessResponse<CreateTrackerSuccessResponse>> = yield call(api.createTrackerRequest, action.params)
	if (response.ok) {
		yield put(createTrackerSuccessAction(response.data.result))
	} else {
		yield put(createTrackerFailureAction(response.data))
	}
}

export function* editTrackerRequestSaga(action: Action<EditTrackerRequest>) {
	const response: ApiResponse<ApiSuccessResponse<EditTrackerSuccessResponse>> = yield call(api.editTrackerRequest, action.params)
	if (response.ok) {
		yield put(editTrackerSuccessAction(response.data.result))
	} else {
		yield put(editTrackerFailureAction(response.data))
	}
}

export function* deleteTrackerRequestSaga(action: Action<DeleteTrackerRequest>) {
	const response: ApiResponse<ApiSuccessResponse<DeleteTrackerSuccessResponse>> = yield call(api.deleteTrackerRequest, action.params)
	if (response.ok) {
		yield put(deleteTrackerSuccessAction(response.data!.result))
	} else {
		yield put(deleteTrackerFailureAction(response.data))
	}
}

export function* trackerStatsRequestSaga(action: Action<TrackerStatsRequest>) {
	const response: ApiResponse<ApiSuccessResponse<TrackerStatsSuccessResponse>> = yield call(api.trackerStatsRequest, action.params)
	if (response.ok) {
		yield put(trackerStatsSuccessAction({ tracker_results: response.data.result.tracker_results }))
	} else {
		yield put(trackerStatsFailureAction(response.data))
	}
}
