import React, { useState } from 'react'
import { Image, ImageStyle, TouchableOpacity, View, ViewStyle, Text, TextStyle, Linking } from 'react-native'
import { ScaleImageModal, Icon } from '.'
import { font, useTheme } from '../theme'
import { Circle } from 'react-native-progress'
import { Nullable } from '../types'

type State = {
  fullscreenImage: string | null
}

type Props = {
  uri: string
  style?: Array<ViewStyle> | ViewStyle
  onDelete?: () => void
  progress?: number
  fetching?: boolean
  type?: string
}

export default function ({
  uri,
  style,
  onDelete,
  progress = 0,
  fetching = false,
  type = '',
}: Props) {

  const { colors } = useTheme()
  const [fullscreenImage, setFullscreenImage] = useState<Nullable<string>>(null)

  const isImage = ['jpg', 'jpeg', 'png', 'bmp'].includes(type)

  return (
    <TouchableOpacity
      style={[
        styles.container[isImage ? 'image' : 'document'],
        {
          borderColor: isImage ? colors.border.default : colors.border.default,
          backgroundColor: isImage ? undefined : colors.background.secondary
        },
        style
      ]}
      onPress={() => (isImage ? setFullscreenImage(uri) : Linking.openURL(uri))}
      activeOpacity={fetching ? 1 : 0.5}
      disabled={fetching}
    >
      {isImage ? (
        <Image source={{ uri }} resizeMethod={'auto'} resizeMode={'cover'} style={styles.image} />
      ) : (
        <>
          <Icon name={'file_line'} />
          <Text style={[styles.file.text, { color: colors.text.secondary }]}>
            {type.substring(0, 1).toUpperCase() + type.substring(1)}
          </Text>
        </>
      )}
      {!!onDelete && (
        <View style={[styles.delete, { backgroundColor: colors.closeIcon.backgroundColor }]} >
          <Icon name={'close_circle'} color={colors.closeIcon.icon} onPress={onDelete} />
        </View>
      )}
      {fetching && (
        <View style={styles.preloader.container}>
          <View style={{ width: 30, height: 30 }}>
            <Circle
              progress={progress / 100}
              color={colors.icon.primary}
              thickness={5}
              size={30}
              style={styles.preloader.loader}
              borderWidth={0}
            />
            <Circle
              progress={1 - progress / 100}
              color={'rgba(255, 255, 255, 0.4)'}
              thickness={5}
              size={30}
              style={styles.preloader.loader}
              borderWidth={0}
              direction={'counter-clockwise'}
            />
          </View>
        </View>
      )}
      <ScaleImageModal
        visible={!!fullscreenImage}
        image={fullscreenImage || ''}
        onCancel={() => setFullscreenImage(null)}
      />
    </TouchableOpacity>
  )

}

const commonStyles = {
  container: {
    width: 80,
    height: 80,
    borderRadius: 5,
    overflow: 'hidden',
    borderWidth: 1,
  } as ViewStyle,
}

const styles = {
  container: {
    image: {
      ...commonStyles.container,
    } as ViewStyle,
    document: {
      ...commonStyles.container,
      alignItems: 'center',
      paddingTop: 24,
    } as ViewStyle,
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  } as ImageStyle,
  delete: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 5,
    borderRadius: 4,
  } as ViewStyle,
  preloader: {
    container: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1,
    } as ViewStyle,
    loader: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    } as ViewStyle,
  },
  file: {
    text: {
      marginTop: 4,
      fontFamily: font('bold'),
      fontSize: 12,
      lineHeight: 15,
    } as TextStyle,
  },
}
