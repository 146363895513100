import React, { createRef, RefObject, useState, useEffect } from 'react'
import {
  ScrollView,
  StyleSheet,
  Text,
  ViewStyle,
  View,
  Dimensions,
  ScaledSize,
} from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { connect, useDispatch } from 'react-redux'
import moment from 'moment'

import { Screen, Alert, Modal, Button, ClientForm, Logo } from '../../components'
import { t } from '../localization'
import { font, useTheme, ThemeColors } from '../../theme'
import { GenderType, MainNavigatorProps, Nullable } from '../types'
import { checkClientData, getUtcOffset } from '../../service'
import { AppState } from '../store/redux'
import { userProfileDeleteRequestAction, userProfileUpdateRequestAction } from '../store/redux/user'
import { authLogoutRequestAction } from '../store/redux/auth'

type ScreenProps = DispatchProps & StateProps & StackScreenProps<MainNavigatorProps, 'ProfileScreen'>

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

interface Props extends ScreenProps {
	colors: ThemeColors,
	isSmallScreen: boolean,
}

interface State {
	firstname: Nullable<string>
	lastname: Nullable<string>
	middlename: Nullable<string>
	birthdate: Nullable<string>
	email: Nullable<string>
	gender: Nullable<GenderType>
  companyName: Nullable<string>
  companySite: Nullable<string>
	registered_at: Nullable<string>
}

function ProfileScreenWrapper(props: Props) {
  const { colors } = useTheme()
  const [screenData, setScreenData] = useState(Dimensions.get('window'))

  useEffect(() => {
    const onChange = (result: { window: React.SetStateAction<ScaledSize> }) => {
      setScreenData(result.window)
    }

    const subscription = Dimensions.addEventListener('change', onChange)

		return () => subscription?.remove()
  })

  return <ProfileScreen colors={colors} isSmallScreen={screenData.width < 635} {...props} />
}

class ProfileScreen extends React.Component<Props, State>{
  dimensionsSubscription: any
  scrollViewRef: RefObject<ScrollView>

  constructor(props: Props) {
    super(props)

    this.state = {
      lastname: null,
      firstname: null,
      middlename: null,
      birthdate: null,
      gender: null,
      email: null,
      companyName: null,
      companySite: null,
      registered_at: null,
    }

    this.scrollViewRef = createRef<ScrollView>()
  }

  componentDidMount() {
    const { profile } = this.props
    this.setState({
      lastname: profile?.lastname || null,
      firstname: profile?.firstname || null,
      middlename: profile?.middlename || null,
      gender: profile?.gender || null,
      birthdate: profile?.birthdate || '1999-01-01',
      email: profile?.email || null,
      companyName: profile?.company_name || null,
      companySite: profile?.company_site || null,
      registered_at: profile?.registered_at || null
    })
  }

  componentDidUpdate(prevProps: Props) {
    const { profile, fetching, navigation, error } = this.props
    if (JSON.stringify(prevProps.profile || '') !== JSON.stringify(profile || '')) {
      this.setState({ ...profile })
    }
    if (prevProps.fetching && !fetching && !error) {
      Alert.alert(null, t('profile_success'),
        [{ text: t('back') }],
        {onDismiss: () => {
          if (navigation.canGoBack()) {
            navigation.goBack()
          } else {
            navigation.navigate('MainStack', { screen: 'MainScreen' })
          }
        }}
      )
    }
  }

  renderTitle = () => {
    const { colors } = this.props
    const { registered_at } = this.state

    if (!registered_at) {
      return (<>
        <Text style={[styles.contentTitle, { color: colors.text.title }]}>{t('familiarity')}</Text>
        <Title />
      </>)
    }

    return (
      <View style={styles.title}>
        <Text style={[styles.titleText, { color: colors.text.title }]}>{t('profile')}</Text>
      </View>
    )
  }

  render() {
    const { isSmallScreen, profile } = this.props;
    const {
      lastname,
      firstname,
      middlename,
      birthdate,
      gender,
      email,
      companyName,
      companySite,
      registered_at,
    } = this.state
    const birthdatePlaceholder = moment('1999-01-01').format('YYYY-MM-DD')
    const _birthdate = !registered_at && !birthdate ? birthdatePlaceholder : birthdate

    return (
      <Screen hideHeader={!registered_at}>
        {!registered_at && <Logo style={styles.logo} clickable={false} />}
        <View style={[
          styles.contentWrapper,
          {
            width: !isSmallScreen ? '55%' : '100%',
            minWidth: !isSmallScreen ? 635 : 'auto',
          },
        ]}>
          <View style={styles.contentForm}>
            { this.renderTitle() }
            <ScrollView
              ref={this.scrollViewRef}
              bounces={false}
              contentContainerStyle={styles.contentContainer}
            >
              <ClientForm
                lastname={lastname}
                firstname={firstname}
                middlename={middlename}
                birthdate={_birthdate}
                gender={gender}
                email={email}
                companyName={companyName}
                companySite={companySite}
                onChange={this.onChange}
                onSavePress={this.onSavePress}
                type={!registered_at ? 'registration' : 'profile'}
                emailFilled={!!profile?.email}
              />
              {!!registered_at && (
                <>
                  <Logout />
                  <Delete />
                </>
              )}
            </ScrollView>
          </View>
        </View>
      </Screen>
    )
  }

  onChange = (params: { [key: string]: string | boolean }) => {
    this.setState({ ...params })
  }

  onSavePress = () => {
    const { profileUpdate, profile } = this.props
    const {
      lastname,
      firstname,
      middlename,
      birthdate,
      gender,
      email,
      companyName,
      companySite,
      registered_at
    } = this.state

    const isValid = checkClientData(
      {
        lastname,
        firstname,
        birthdate,
        gender,
        email,
        companyName,
      },
      true,
      !registered_at && !profile?.email,
    )
    if (isValid) {
      profileUpdate({
        registered_at,
        firstname,
        lastname,
        middlename,
        birthdate,
        gender,
        email: email || undefined,
        company_name: companyName,
        company_site: companySite,
        tz_offset: getUtcOffset(),
      })
    }
  }

}

const mapStateToProps = (state: AppState) => ({
  profile: state.user.profile,
  fetching: state.user.fetching,
  error: state.user.error,
})

const mapDispatchToProps = {
  profileUpdate: userProfileUpdateRequestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileScreenWrapper)

const styles = StyleSheet.create({
  contentContainer: {
    flexGrow: 1,
  } as ViewStyle,
  contentWrapper: {
    flexGrow: 1,
    marginTop: 26,
    marginBottom: 40,
    paddingHorizontal: 20,
    paddingBottom: 17,
    alignSelf: 'center',
  },
  contentForm: {
    textAlign: 'center',
  },
  contentTitle: {
    fontSize: 36,
    marginBottom: 20,
  },
  logo: {
    marginTop: 40,
    marginBottom: 34,
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 30,
  },
  titleText: {
    fontSize: 24,
    fontFamily: font('bold'),
  },
  titleBackIcon: {
    marginRight: 15,
  },
  tabBar: {
    marginBottom: 30,
  },
})

function Title() {
  const { colors } = useTheme()
  return (
    <Text style={[titleStyles.text, { color: colors.text.primary }]}>
      {t('fill_profile')}
    </Text>
  )
}

const titleStyles = StyleSheet.create({
  text: {
    fontFamily: font(),
    fontSize: 16,
    lineHeight: 19,
    marginBottom: 24,
  },
})

function Logout() {
  const { colors } = useTheme()
  const dispatch = useDispatch()

  return (
    <Text
      style={[logoutStyles.text, { color: colors.text.title }]}
      onPress={() => dispatch(authLogoutRequestAction())}
    >
      {t('logout')}
    </Text>
  )
}

const logoutStyles = StyleSheet.create({
  text: {
    marginTop: 20,
    textAlign: 'center',
    fontFamily: font('bold'),
    fontSize: 14,
    lineHeight: 17,
  },
})

function Delete() {
  const { colors } = useTheme();
  const dispatch = useDispatch();
  const [isVisible, setVisible] = useState(false)

  return (
    <>
      <Text
        style={[deleteStyle.text, { color: colors.text.subtitle }]}
        onPress={() => setVisible(true)}
      >
        {t('profile_delete')}
      </Text>
      <DeleteProfileModal
        isVisible={isVisible}
        onConfirm={() => {
          dispatch(userProfileDeleteRequestAction())
        }}
        onCancel={() => setVisible(false)}
      />
    </>
  )
}

const deleteStyle = StyleSheet.create({
  text: {
    marginTop: 16,
    textAlign: 'center',
    fontFamily: font('bold'),
    fontSize: 14,
    lineHeight: 17,
  },
})

interface CancelAppointmentModalProps {
  isVisible: boolean
  onConfirm: () => void
  onCancel: () => void
}

function DeleteProfileModal({
  isVisible,
  onConfirm,
  onCancel,
}: CancelAppointmentModalProps) {

  const { colors } = useTheme()

  return (
    <Modal
      isVisible={isVisible}
      onClose={onCancel}
      position='center'
    >
      <View style={deleteModal.container}>
        <Text style={[deleteModal.title, { color: colors.text.title }]}>
          {t('profile_delete')}
        </Text>
        <Text style={[deleteModal.text, { color: colors.text.default }]}>
          {t('confim_profile_delete')}
        </Text>
      </View>
      <View style={deleteModal.buttonContainer}>
        <Button
          text={t('no')}
          style={deleteModal.button}
          type={'secondary'}
          onPress={() => onCancel()}
        />
        <Button
          text={t('yes')}
          style={deleteModal.button}
          onPress={() => onConfirm()}
        />
      </View>
    </Modal>
  )
}

const deleteModal = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
  title: {
    fontFamily: font('bold'),
    fontSize: 20,
    lineHeight: 24,
  },
  text: {
    marginTop: 14,
    fontFamily: font(),
    fontSize: 16,
    lineHeight: 19,
  },
  buttonContainer: {
    marginTop: 24,
    paddingHorizontal: 11,
    flexDirection: 'row',
  },
  button: {
    flex: 1,
    marginHorizontal: 5,
  },
})
