import React from 'react'
import ReactNativeMarkdown from 'react-native-markdown-display'
import { StyleSheet, TextStyle, View } from 'react-native'
import { font, useTheme } from '../theme'

interface Props {
  text: any
  style?: TextStyle | TextStyle[]
  paragraphStyle?: TextStyle | TextStyle[]
  linkStyle?: TextStyle | TextStyle[]
  onLinkPress?: (url: string) => void
}

export default function ({
  text,
  style,
  paragraphStyle,
  linkStyle,
  onLinkPress,
}: Props) {

  const { colors } = useTheme()
  const _paragraphStyle = Array.isArray(paragraphStyle) ? paragraphStyle.reduce((res, style) => ({ ...res, ...style }), {}) : paragraphStyle
  const _linkStyle = Array.isArray(linkStyle) ? linkStyle.reduce((res, style) => ({ ...res, ...style }), {}) : linkStyle

  return (
    <View style={style}>
      <ReactNativeMarkdown
        style={{
          paragraph: {
            ...styles.paragraph,
            color: colors.text.default,
            ..._paragraphStyle,
          },
          link: {
            ...styles.link,
            color: colors.text.subtitle,
            ..._linkStyle
          },
          list_item: {
            ...styles.listItem,
            color: colors.text.default,
          }
        }}
        mergeStyle
        onLinkPress={url => {
          !!onLinkPress && onLinkPress(url)
          return !onLinkPress
        }}
      >
        {text}
      </ReactNativeMarkdown>
    </View>
  )
}


const styles = StyleSheet.create({
  paragraph: {
    marginTop: 8,
    marginBottom: 0,
    fontFamily: font(),
    fontSize: 16,
    lineHeight: 20,
  },
  link: {
    textDecorationLine: 'none',
  },
  listItem: {
    fontFamily: font(),
    fontSize: 16,
    lineHeight: 20,
  }
})
