import React, { useEffect } from 'react'
import { Text, StyleSheet, TextStyle } from 'react-native'
import { useState } from 'react'
import { CodeField, Cursor, useBlurOnFulfill, useClearByFocusCell } from 'react-native-confirmation-code-field'
import { font, useTheme } from '../theme'

type Props = {
  onCodeEntered: (code: string) => void
  editable?: boolean
  style?: TextStyle | TextStyle[]
  smsCodeLength: number
}

function CodeInput(props: Props) {
  const { onCodeEntered, editable, style, smsCodeLength } = props

  const { colors } = useTheme()
  const [value, setValue] = useState('')
  const ref = useBlurOnFulfill({ value, cellCount: smsCodeLength })
  const [codeFiledProps, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  })

  const handleCodeFinished = () => {
    onCodeEntered && onCodeEntered(value)
    setValue('')
  }

  const setFormattedValue = (value: String) => {
    if (value.length) setValue(value.replace(/[^0-9.]/g, ''))
  }

  useEffect(() => {
    if (value?.length === smsCodeLength) {
      handleCodeFinished()
    }
  }, [value])

  return (
    <CodeField
      ref={ref}
      {...codeFiledProps}
      value={value}
      onChangeText={setFormattedValue}
      cellCount={smsCodeLength}
      autoFocus
      editable={editable}
      rootStyle={styles.field}
      keyboardType='number-pad'
      textContentType="oneTimeCode"
      textInputStyle={{ height: '100%' }}
      renderCell={({ index, symbol, isFocused }) => (
        <Text
          key={index}
          style={[
            styles.cell,
            isFocused && [styles.focused, { borderColor: colors.border.default }],
            {
              backgroundColor: colors.background.primary,
              color: colors.text.default,
            },
            style,
          ]}
          onLayout={getCellOnLayoutHandler(index)}
        >
          {symbol || (isFocused ? <Cursor /> : null)}
        </Text>
      )}
    />
  )
}

const styles = StyleSheet.create({
  field: {
    justifyContent: 'flex-start',
  },
  cell: {
    width: 60,
    height: 70,
    marginRight: 12,
    borderRadius: 5,
    textAlign: 'center',
    fontFamily: font(),
    fontSize: 24,
    lineHeight: 70,
  },
  focused: {
    borderWidth: 1,
  },
})

export default CodeInput
