import React from 'react'
import { Image, ScrollView, StyleSheet, Text, View } from "react-native"
import { appointmentFailure } from '../images'
import { Button, Markdown } from '.'
import { t } from '../localization'
import { font, useTheme } from '../theme'

interface PaymentFailureModalProps {
	isVisible?: boolean
	onButtonPress: () => void
}

export default function ({
	onButtonPress,
}: PaymentFailureModalProps) {

	const { colors } = useTheme()

	return (
		<ScrollView
			bounces={false}
			contentContainerStyle={styles.contentContainerStyle}
		>
			<Image
				source={appointmentFailure}
				style={styles.image}
			/>
			<View style={{ marginHorizontal: 16 }}>
				<Text style={[styles.title, { color: colors.text.title }]}>
					{t('appointment_failure')}
				</Text>
				<Markdown
					style={styles.notice}
					paragraphStyle={{ textAlign: 'center' }}
					text={t('appointment_failure_notice')}
				/>
				<Button
					style={styles.button}
					text={t('return')}
					onPress={onButtonPress}
				/>
			</View>
		</ScrollView>
	)
}

const styles = StyleSheet.create({
	contentContainerStyle: {
		flexGrow: 1,
		justifyContent: 'center',
		paddingBottom: 30
	},
	button: {
		flex: 1,
		marginHorizontal: 5,
		width: 200,
		alignSelf: 'center'
	},
	image: {
		width: 314,
		height: 243,
		alignSelf: 'center',
	},
	title: {
		marginTop: 20,
		fontFamily: font('bold'),
		fontSize: 24,
		lineHeight: 29,
		textAlign: 'center'
	},
	notice: {
		marginBottom: 16,
		alignItems: 'center',
	},
})
