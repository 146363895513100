import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
import { ExceptionEvent, OpenVidu, StreamEvent } from 'openvidu-browser'

import UserVideoComponent from './UserVideoComponent'
import Controller from '../Conference/Controller'

const PUBLISHER_CONFIG = {
  audioSource: undefined, // The source of audio. If undefined default microphone
  videoSource: undefined, // The source of video. If undefined default webcam
  publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
  publishVideo: true, // Whether you want to start publishing with your video enabled or not
  resolution: '640x480', // The resolution of your video
  frameRate: 30, // The frame rate of your video
  insertMode: 'REPLACE', // How the video is inserted in the target element 'video-container'
  mirror: false, // Whether to mirror your local video or not
}

interface Props {
  token: string,
  onLeaveSession: Function,
}

interface State {
  session: any,
  publisher: any,
  subscriber: any,
}

class ConferenceWeb extends Component<Props, State> {
  isCameraOn: boolean
  isMicrophoneOn: boolean
  OV: any

  constructor(props: Props) {
    super(props)

    this.state = {
      session: undefined,
      publisher: undefined,
      subscriber: null,
    }
    this.isCameraOn = true
    this.isMicrophoneOn = true
    this.joinSession = this.joinSession.bind(this)
    this.leaveSession = this.leaveSession.bind(this)
  }

  componentDidMount() {
    this.joinSession(this.props.token)
  }

  joinSession(token: string) {
    this.OV = new OpenVidu()
    this.setState({ session: this.OV.initSession() }, () => {
      const mySession = this.state.session

      mySession.on('streamCreated', (event: StreamEvent) => {
        const subscriber = mySession.subscribe(event.stream, undefined);
        this.setState({ subscriber })
      })

      mySession.on('streamDestroyed', () => {
        this.leaveSession()
      })

      mySession.on('exception', (exception: ExceptionEvent) => {
        console.warn(exception)
      })

      mySession.connect(token, { clientData: 'clientName' })
        .then(() => {
          const publisher = this.OV.initPublisher(undefined, PUBLISHER_CONFIG);
          console.log('publisher', publisher)
          mySession.publish(publisher)
          this.setState({ publisher: publisher })
        })
        .catch((error: any) => {
          console.log('There was an error connecting to the session:', error.code, error.message);
        })
    })
  }

  leaveSession() {
    const mySession = this.state.session

    if (mySession) {
      mySession.disconnect()
    }

    this.props.onLeaveSession()
  }

  render() {
    return (
      <>
        <View style={styles.container}>
          {!!this.state.session && (
            <View style={styles.session}>
              {!!this.state.subscriber && (
                <View style={styles.remoteVideoContainer}>
                  <UserVideoComponent
                    type='remote'
                    streamManager={this.state.subscriber}
                    autoPlay={true}
                  />
                </View>
              )}
              {!!this.state.publisher && (
                <View style={styles.localVideoContainer}>
                  <UserVideoComponent type='local' streamManager={this.state.publisher} />
                </View>
              )}
            </View>
          )}
        </View>
        <Controller
          style={styles.controller}
          toggleMicrophone={this.changeMicrophoneStatus}
          toggleVideo={this.changeCameraStatus}
          onEndCallPress={this.leaveSession}
          isVideoActive={this.isCameraOn}
          isAudioActive={this.isMicrophoneOn}
        />
      </>
    );
  }

  changeMicrophoneStatus = () => {
    this.isMicrophoneOn = !this.isMicrophoneOn
    const publisher = this.state.publisher
    publisher?.publishAudio(this.isMicrophoneOn)
    this.setState({ publisher })
  }

  changeCameraStatus = () => {
    this.isCameraOn = !this.isCameraOn
    const publisher = this.state.publisher
    publisher?.publishVideo(this.isCameraOn)
    this.setState({ publisher })
  }

  sendSignalUserChanged(data) {
    const signalOptions = {
      data: JSON.stringify(data),
      type: 'userChanged',
    };
    this.state.session.signal(signalOptions);
  }
}

export default ConferenceWeb;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: 'stretch',
  },
  horizontalCenter: {
    margin: '0 auto',
  },
  session: {
    overflow: 'hidden',
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'black',
  },
  remoteVideoContainer: {},
  localVideoContainer: {
    position: 'absolute',
    right: 16,
    bottom: 16,
    width: '20vw',
    height: 'calc(20vw * 3 / 4)',
    zIndex: 1,
    justifyContent: 'center',
    overflow: 'hidden',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#EDEDED',
    backgroundColor: 'black',
  },
  controller: {
    position: 'absolute',
    bottom: 20,
    padding: 10,
    borderRadius: 40,
    display: 'flex',
    backgroundColor: 'black',
  },
})
