import React from 'react'
import { StyleSheet, View } from 'react-native'
import { GiftedChat } from 'react-native-gifted-chat'
import moment from 'moment'
import 'moment/locale/ru'

import ChatMessage from './ChatMessage'
import ChatFooter from './ChatFooter'
import { ThemeColors, useTheme } from '../../theme'
import { ChatMessageType, Nullable, UploadFileType } from '../../types'

moment().locale('ru')

interface ChatProps {
  client_id: number
  files: UploadFileType[]
  onDeleteFilePress: (file: UploadFileType) => void
  onAddFiles: (params: any) => void
  sendMessage: (params: any) => void
  onEndReached: () => void
  fetching: boolean
  messages: Nullable<ChatMessageType[]>
  type: 'support' | 'conference'
}

type Props = ChatProps & {
  colors: ThemeColors
}

interface State {
  messages: Nullable<ChatMessageType[]>
  message: string
}

export default function ChatWrapper(props: ChatProps) {
  const { colors } = useTheme()
  return <Chat colors={colors} {...props} />
}

class Chat extends React.Component<Props, State> {
  chatRef: GiftedChat<ChatMessageType>
  constructor(props: Props) {
    super(props)
    this.state = {
      messages: null,
      message: '',
    }
  }

  render() {
    const {
      client_id,
      files,
      onDeleteFilePress,
      fetching,
      onEndReached,
      onAddFiles,
      colors,
      messages,
      type,
    } = this.props

    const {
      message,
    } = this.state

    return (
      <View style={[styles.content, { backgroundColor: colors.background.primary }]}>
        <View style={[styles.container, { backgroundColor: colors.background.primary }]}>
          <GiftedChat
            ref={(ref) => (this.chatRef = ref)}
            messages={messages || []}
            user={{ _id: client_id || 0 }}
            // inverted={true}
            renderMessage={(message => (
              <ChatMessage message={message} client_id={client_id} />
            ))}
            alwaysShowSend={true}
            minInputToolbarHeight={0}
            scrollToBottom={true}
            renderChatFooter={() => (
              <ChatFooter
                type={type}
                files={files}
                message={message}
                onDeleteFilePress={onDeleteFilePress}
                onMessageChange={this.onMessageChange}
                onSend={this._onSend}
                onActionPress={this.onPressActionButton}
                onAddFiles={onAddFiles}
              />
            )}
            onPressActionButton={this.onPressActionButton}
            onSend={this.onMessageSendPress}
            renderInputToolbar={() => null}
            // isKeyboardInternallyHandled={false}
            isLoadingEarlier={fetching}
            listViewProps={{
              onEndReached,
              onEndReachedThreshold: 0.5,
            }}
          />
        </View >
      </View>
    )
  }

  onMessageChange = (message: string) => {
    this.setState({ message })
  }

  onPressActionButton = () => {
    const { type } = this.props
    const fileElem = document.getElementById(`uploadFile_${type}`)
    if (fileElem) {
      fileElem.click();
    }
  }

  _onSend = () => {
    const {
      message,
    } = this.state
    const {
      files,
      client_id,
    } = this.props
    const onSend = this.chatRef?.props.onSend
    if (!!onSend) {
      onSend([
        {
          id: undefined,
          client: undefined,
          text: message || '',
          files,
          datetime: moment().toLocaleString(),
          _id: Date.now().toString(),
          createdAt: new Date(),
          user: { _id: client_id, id: client_id, phone: undefined, email: undefined },
        },
      ])
    }
  }

  onMessageSendPress = (messages: Array<ChatMessageType>) => {
    const {
      messages: s_messages,
      sendMessage,
      files
    } = this.props
    const message = messages[0]?.text || ''
    const message_file = messages[0]
    message_file.files = Object.values(files)
    const appended = GiftedChat.append(s_messages || [], [message_file], true)
    sendMessage({
      message,
      files: files.map((file) => file.id),
      appended,
    })
    this.setState({ message: '' })
  }

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  }
})
