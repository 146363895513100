import {
	ApiFailure,
	ApiFailureResponse,
	AppointmentsSuccessResponse,
	Nullable,
	AppointmentGenderRequest,
	GenderType,
	AppointmentSlotType,
	AppointmentMakeSuccessResponse,
	AppointmentType,
	AppointmentSlotsSuccessResponse,
	AppointmentPrimaryReserveSuccessResponse,
	AppointmentPrimarySlotsRequest,
	AppointmentGenderSuccessResponse,
	AppointmentPrimaryReserveRequest,
	AppointmentMakeRequest,
	AppointmentPatientRequest,
	AppointmentPatientSuccessRespoonse,
	PatientType,
	AppointmentsRequest,
	AppointmentCancelRequest,
	AppointmentCancelSuccessResponse,
	AppointmentSecondarySlotsRequest,
	AppointmentSecondaryReserveSuccessResponse,
	AppointmentSecondaryReserveRequest,
	AppointmentSecondaryReserveDeleteRequest,
	AppointmentSecondaryReserveDeleteSuccessResponse,
	PaymentType,
	DoctorType,
	ServiceType,
	AppointmentPaymentStatusRequest,
	AppointmentPaymentStatusSuccessResponse,
	AppointmentCheckSuccess,
	AppointmentCheckRequest,
	AppointmentPrimaryScheduleResponse,
	AppointmentPrimaryScheduleRequest,
	AppointmentSecondaryScheduleRequest,
	AppointmentSecondaryScheduleResponse,
} from '../../types'
import { CREATE_REVIEW_SUCCESS } from './reviews'

export interface NewAppointment {
	serviceId: number
	client: PatientType,
	doctorGender: 0 | 1
	datetime: string
	comment?: string
	doctor?: DoctorType
	service?: ServiceType
	pack?: {
		isPack: boolean,
		parentId: number,
		count: number,
	}
}

export interface AppointmentState {
	future_appointments: Nullable<AppointmentType[]>
	future_appointments_fetching: boolean
	future_appointments_error: Nullable<ApiFailure>
	future_appointments_page: Nullable<number>
	future_appointments_limit: Nullable<number>
	future_appointments_total: Nullable<number>
	past_appointments: Nullable<AppointmentType[]>
	past_appointments_fetching: boolean
	past_appointments_error: Nullable<ApiFailure>
	past_appointments_page: Nullable<number>
	past_appointments_limit: Nullable<number>
	past_appointments_total: Nullable<number>
	new_appointment: Nullable<NewAppointment>
	doctor_gender_list: Nullable<GenderType[]>
	doctor_gender_fetching: boolean
	doctor_gender_error: Nullable<ApiFailure>
	slots_fetching: boolean
	slots: Nullable<AppointmentSlotType[]>
	slots_error: Nullable<ApiFailure>
	reserve: Nullable<null>
	reserve_error: Nullable<ApiFailure>
	appointments_fetching: boolean
	appointment: Nullable<AppointmentType>
	appointments_error: Nullable<ApiFailure>
	patient_fetching: boolean
	patient_error: Nullable<ApiFailure>
	reserve_slot: Nullable<AppointmentSlotType>
	primary_reserve_fetching: boolean
	secondary_reserve_fetching: boolean
	delete_secondary_reserve_fetching: boolean
	appointment_cancel_fetching: boolean
	appointment_cancel_error: Nullable<ApiFailure>
	payment: Nullable<PaymentType>
	appointment_payment_cancel_fetching: boolean
	appointment_payment_cancel_error: Nullable<ApiFailure>
	appointment_payment_status_fetching: boolean
	appointment_payment_status_error: Nullable<ApiFailure>
	payment_status: Nullable<string>
	check: Nullable<string>
	check_fetching: boolean
	check_error: Nullable<ApiFailure>
	calendar: Nullable<string[]>
	calendar_fetching: boolean
	calendar_error: Nullable<ApiFailure>
	temp_reserve: Nullable<AppointmentPrimaryReserveRequest>
	temp_reserve_params: Nullable<AppointmentSecondaryReserveRequest>
}

export const APPOINTMENT_FUTURE_REQUEST = 'APPOINTMENT_FUTURE_REQUEST'
export const APPOINTMENT_FUTURE_SUCCESS = 'APPOINTMENT_FUTURE_SUCCESS'
export const APPOINTMENT_FUTURE_FAILURE = 'APPOINTMENT_FUTURE_FAILURE'
export const APPOINTMENT_PAST_REQUEST = 'APPOINTMENT_PAST_REQUEST'
export const APPOINTMENT_PAST_SUCCESS = 'APPOINTMENT_PAST_SUCCESS'
export const APPOINTMENT_PAST_FAILURE = 'APPOINTMENT_PAST_FAILURE'
export const APPOINTMENT_NEW_ADD_DATA = 'APPOINTMENT_NEW_ADD_DATA'
export const APPOINTMENT_NEW_RESET = 'APPOINTMENT_NEW_RESET'
export const APPONTMENT_NEW_RESET = 'APPONTMENT_NEW_RESET'
export const APPOINTMENT_PART_RESET = 'APPOINTMENT_PART_RESET'
export const APPOINTMENT_GENDER_REQUEST = 'APPOINTMENT_GENDER_REQUEST'
export const APPOINTMENT_GENDER_SUCCESS = 'APPOINTMENT_GENDER_SUCCESS'
export const APPOINTMENT_GENDER_FAILURE = 'APPOINTMENT_GENDER_FAILURE'
export const APPOINTMENT_PRIMARY_CALENDAR_REQUEST = 'APPOINTMENT_PRIMARY_CALENDAR_REQUEST'
export const APPOINTMENT_PRIMARY_CALENDAR_SUCCESS = 'APPOINTMENT_PRIMARY_CALENDAR_SUCCESS'
export const APPOINTMENT_PRIMARY_CALENDAR_FAILURE = 'APPOINTMENT_PRIMARY_CALENDAR_FAILURE'
export const APPOINTMENT_PRIMARY_SLOTS_REQUEST = 'APPOINTMENT_PRIMARY_SLOTS_REQUEST'
export const APPOINTMENT_PRIMARY_SLOTS_SUCCESS = 'APPOINTMENT_PRIMARY_SLOTS_SUCCESS'
export const APPOINTMENT_PRIMARY_SLOTS_FAILURE = 'APPOINTMENT_PRIMARY_SLOTS_FAILURE'
export const APPOINTMENT_SECONDARY_CALENDAR_REQUEST = 'APPOINTMENT_SECONDARY_CALENDAR_REQUEST'
export const APPOINTMENT_SECONDARY_CALENDAR_SUCCESS = 'APPOINTMENT_SECONDARY_CALENDAR_SUCCESS'
export const APPOINTMENT_SECONDARY_CALENDAR_FAILURE = 'APPOINTMENT_SECONDARY_CALENDAR_FAILURE'
export const APPOINTMENT_SECONDARY_SLOTS_REQUEST = 'APPOINTMENT_SECONDARY_SLOTS_REQUEST'
export const APPOINTMENT_SECONDARY_SLOTS_SUCCESS = 'APPOINTMENT_SECONDARY_SLOTS_SUCCESS'
export const APPOINTMENT_SECONDARY_SLOTS_FAILURE = 'APPOINTMENT_SECONDARY_SLOTS_FAILURE'
export const APPOINTMENT_PRIMARY_RESERVE_REQUEST = 'APPOINTMENT_PRIMARY_RESERVE_REQUEST'
export const APPOINTMENT_PRIMARY_RESERVE_SUCCESS = 'APPOINTMENT_PRIMARY_RESERVE_SUCCESS'
export const APPOINTMENT_PRIMARY_RESERVE_FAILURE = 'APPOINTMENT_PRIMARY_RESERVE_FAILURE'
export const APPOINTMENT_SECONDARY_RESERVE_REQUEST = 'APPOINTMENT_SECONDARY_RESERVE_REQUEST'
export const APPOINTMENT_SECONDARY_RESERVE_SUCCESS = 'APPOINTMENT_SECONDARY_RESERVE_SUCCESS'
export const APPOINTMENT_SECONDARY_RESERVE_FAILURE = 'APPOINTMENT_SECONDARY_RESERVE_FAILURE'
export const APPOINTMENT_SECONDARY_RESERVE_DELETE_REQUEST = 'APPOINTMENT_SECONDARY_RESERVE_DELETE_REQUEST'
export const APPOINTMENT_SECONDARY_RESERVE_DELETE_SUCCESS = 'APPOINTMENT_SECONDARY_RESERVE_DELETE_SUCCESS'
export const APPOINTMENT_SECONDARY_RESERVE_DELETE_FAILURE = 'APPOINTMENT_SECONDARY_RESERVE_DELETE_FAILURE'
export const APPOINTMENT_MAKE_REQUEST = 'APPOINTMENT_MAKE_REQUEST'
export const APPOINTMENT_MAKE_SUCCESS = 'APPOINTMENT_MAKE_SUCCESS'
export const APPOINTMENT_MAKE_FAILURE = 'APPOINTMENT_MAKE_FAILURE'
export const APPOINTMENT_PATIENT_REQUEST = 'APPOINTMENT_PATIENT_REQUEST'
export const APPOINTMENT_PATIENT_SUCCESS = 'APPOINTMENT_PATIENT_SUCCESS'
export const APPOINTMENT_PATIENT_FAILURE = 'APPOINTMENT_PATIENT_FAILURE'
export const APPOINTMENT_CANCEL_REQUEST = 'APPOINTMENT_CANCEL_REQUEST'
export const APPOINTMENT_CANCEL_SUCCESS = 'APPOINTMENT_CANCEL_SUCCESS'
export const APPOINTMENT_CANCEL_FAILURE = 'APPOINTMENT_CANCEL_FAILURE'
export const APPOINTMENT_PAYMENT_CANCEL_REQUEST = 'APPOINTMENT_PAYMENT_CANCEL_REQUEST'
export const APPOINTMENT_PAYMENT_CANCEL_SUCCESS = 'APPOINTMENT_PAYMENT_CANCEL_SUCCESS'
export const APPOINTMENT_PAYMENT_CANCEL_FAILURE = 'APPOINTMENT_PAYMENT_CANCEL_FAILURE'
export const APPOINTMENT_PAYMENT_STATUS_REQUEST = 'APPOINTMENT_PAYMENT_STATUS_REQUEST'
export const APPOINTMENT_PAYMENT_STATUS_SUCCESS = 'APPOINTMENT_PAYMENT_STATUS_SUCCESS'
export const APPOINTMENT_PAYMENT_STATUS_FAILURE = 'APPOINTMENT_PAYMENT_STATUS_FAILURE'
export const APPOINTMENT_CHECK_REQUEST = 'APPOINTMENT_CHECK_REQUEST'
export const APPOINTMENT_CHECK_SUCCESS = 'APPOINTMENT_CHECK_SUCCESS'
export const APPOINTMENT_CHECK_FAILURE = 'APPOINTMENT_CHECK_FAILURE'

export const appointmentsFutureRequestAction = (params?: AppointmentsRequest) =>
	({ type: APPOINTMENT_FUTURE_REQUEST, params })
export const appointmentsFutureSuccessAction = (payload: AppointmentsSuccessResponse) =>
	({ type: APPOINTMENT_FUTURE_SUCCESS, payload })
export const appointmentsFutureFailureAction = (payload: ApiFailureResponse) =>
	({ type: APPOINTMENT_FUTURE_FAILURE, payload })
export const appointmentsPastRequestAction = (params?: AppointmentsRequest) =>
	({ type: APPOINTMENT_PAST_REQUEST, params })
export const appointmentsPastSuccessAction = (payload: AppointmentsSuccessResponse) =>
	({ type: APPOINTMENT_PAST_SUCCESS, payload })
export const appointmentsPastFailureAction = (payload: ApiFailureResponse) =>
	({ type: APPOINTMENT_PAST_FAILURE, payload })
export const appointmentNewAddData = (params: NewAppointment) =>
	({ type: APPOINTMENT_NEW_ADD_DATA, params })
export const appointmentNewReset = () =>
	({ type: APPOINTMENT_NEW_RESET })
export const appointmentNewResetAction = (params: boolean) =>
	({ type: APPONTMENT_NEW_RESET, params })
export const appointmentPartResetAction = () =>
	({ type: APPOINTMENT_PART_RESET })
export const appointmentGenderRequestAction = (params: AppointmentGenderRequest) =>
	({ type: APPOINTMENT_GENDER_REQUEST, params })
export const appointmentGenderSuccessAction = (payload: AppointmentGenderSuccessResponse) =>
	({ type: APPOINTMENT_GENDER_SUCCESS, payload })
export const appointmentGenderFailureAction = (payload: ApiFailureResponse) =>
	({ type: APPOINTMENT_GENDER_FAILURE, payload })
export const appointmentSecondaryCalendarRequestAction = (params?: AppointmentSecondaryScheduleRequest) =>
	({ type: APPOINTMENT_SECONDARY_CALENDAR_REQUEST, params })
export const appointmentSecondaryCalendarSuccessAction = (payload: AppointmentSecondaryScheduleResponse) =>
	({ type: APPOINTMENT_SECONDARY_CALENDAR_SUCCESS, payload })
export const appointmentSecondaryCalendarFailureAction = (payload: ApiFailureResponse) =>
	({ type: APPOINTMENT_SECONDARY_CALENDAR_FAILURE, payload })
export const appointmentPrimaryCalendarRequestAction = (params?: AppointmentPrimaryScheduleRequest) =>
	({ type: APPOINTMENT_PRIMARY_CALENDAR_REQUEST, params })
export const appointmentPrimaryCalendarSuccessAction = (payload: AppointmentPrimaryScheduleResponse) =>
	({ type: APPOINTMENT_PRIMARY_CALENDAR_SUCCESS, payload })
export const appointmentPrimaryCalendarFailureAction = (payload: ApiFailureResponse) =>
	({ type: APPOINTMENT_PRIMARY_CALENDAR_FAILURE, payload })
export const appointmentPrimarySlotsRequestAction = (params: AppointmentPrimarySlotsRequest) =>
	({ type: APPOINTMENT_PRIMARY_SLOTS_REQUEST, params })
export const appointmentPrimarySlotsSuccessAction = (payload: AppointmentSlotsSuccessResponse) =>
	({ type: APPOINTMENT_PRIMARY_SLOTS_SUCCESS, payload })
export const appointmentPrimarySlotsFailureAction = (payload: ApiFailureResponse) =>
	({ type: APPOINTMENT_PRIMARY_SLOTS_FAILURE, payload })
export const appointmentSecondarySlotsRequestAction = (params: AppointmentSecondarySlotsRequest) =>
	({ type: APPOINTMENT_SECONDARY_SLOTS_REQUEST, params })
export const appointmentSecondarySlotsSuccessAction = (payload: AppointmentSlotsSuccessResponse) =>
	({ type: APPOINTMENT_SECONDARY_SLOTS_SUCCESS, payload })
export const appointmentSecondarySlotsFailureAction = (payload: ApiFailureResponse) =>
	({ type: APPOINTMENT_SECONDARY_SLOTS_FAILURE, payload })
export const appointmentPrimaryReserveRequestAction = (params: AppointmentPrimaryReserveRequest) =>
	({ type: APPOINTMENT_PRIMARY_RESERVE_REQUEST, params })
export const appointmentPrimaryReserveSuccessAction = (payload: AppointmentPrimaryReserveSuccessResponse) =>
	({ type: APPOINTMENT_PRIMARY_RESERVE_SUCCESS, payload })
export const appointmentPrimaryReserveFailureAction = (payload: ApiFailureResponse) =>
	({ type: APPOINTMENT_PRIMARY_RESERVE_FAILURE, payload })
export const appointmentSecondaryReserveRequestAction = (params: AppointmentSecondaryReserveRequest) =>
	({ type: APPOINTMENT_SECONDARY_RESERVE_REQUEST, params })
export const appointmentSecondaryReserveSuccessAction = (payload: AppointmentSecondaryReserveSuccessResponse) =>
	({ type: APPOINTMENT_SECONDARY_RESERVE_SUCCESS, payload })
export const appointmentSecondaryReserveFailureAction = (payload: ApiFailureResponse) =>
	({ type: APPOINTMENT_SECONDARY_RESERVE_FAILURE, payload })
export const appointmentSecondaryReserveDeleteRequestAction = (params: AppointmentSecondaryReserveDeleteRequest) =>
	({ type: APPOINTMENT_SECONDARY_RESERVE_DELETE_REQUEST, params })
export const appointmentSecondaryReserveDeleteSuccessAction = (payload: AppointmentSecondaryReserveDeleteSuccessResponse) =>
	({ type: APPOINTMENT_SECONDARY_RESERVE_DELETE_SUCCESS, payload })
export const appointmentSecondaryReserveDeleteFailureAction = (payload: ApiFailureResponse) =>
	({ type: APPOINTMENT_SECONDARY_RESERVE_DELETE_FAILURE, payload })
export const appointmentMakeRequestAction = (params: AppointmentMakeRequest) =>
	({ type: APPOINTMENT_MAKE_REQUEST, params })
export const appointmentMakeSuccessAction = (payload: AppointmentMakeSuccessResponse) =>
	({ type: APPOINTMENT_MAKE_SUCCESS, payload })
export const appointmentMakeFailureAction = (payload: ApiFailureResponse) =>
	({ type: APPOINTMENT_MAKE_FAILURE, payload })
export const appointmentPatientRequestAction = (params: AppointmentPatientRequest) =>
	({ type: APPOINTMENT_PATIENT_REQUEST, params })
export const appointmentPatientSuccessAction = (payload: AppointmentPatientSuccessRespoonse) =>
	({ type: APPOINTMENT_PATIENT_SUCCESS, payload })
export const appointmentPatientFailureAction = (payload: ApiFailureResponse) =>
	({ type: APPOINTMENT_PATIENT_FAILURE, payload })
export const appointmentCancelRequestAction = (params: AppointmentCancelRequest) =>
	({ type: APPOINTMENT_CANCEL_REQUEST, params })
export const appointmentCancelSuccessAction = (payload: AppointmentCancelSuccessResponse) =>
	({ type: APPOINTMENT_CANCEL_SUCCESS, payload })
export const appointmentCancelFailureAction = (payload: ApiFailureResponse) =>
	({ type: APPOINTMENT_CANCEL_FAILURE, payload })
export const appointmentPaymentCancelRequestAction = (id: number) =>
	({ type: APPOINTMENT_PAYMENT_CANCEL_REQUEST, params: id })
export const appointmentPaymentCancelSuccessAction = () =>
	({ type: APPOINTMENT_PAYMENT_CANCEL_SUCCESS })
export const appointmentPaymentCancelFailureAction = (payload: ApiFailureResponse) =>
	({ type: APPOINTMENT_PAYMENT_CANCEL_FAILURE, payload })
export const appointmentPaymentStatusRequestAction = (paymentId: AppointmentPaymentStatusRequest) =>
	({ type: APPOINTMENT_PAYMENT_STATUS_REQUEST, params: paymentId })
export const appointmentPaymentStatusSuccessAction = (payload: AppointmentPaymentStatusSuccessResponse) =>
	({ type: APPOINTMENT_PAYMENT_STATUS_SUCCESS, payload })
export const appointmentPaymentStatusFailureAction = (payload?: ApiFailureResponse) =>
	({ type: APPOINTMENT_PAYMENT_STATUS_FAILURE, payload })
export const appointmentCheckRequestAction = (params?: AppointmentCheckRequest) =>
	({ type: APPOINTMENT_CHECK_REQUEST, params })
export const appointmentCheckSuccessAction = () =>
	({ type: APPOINTMENT_CHECK_SUCCESS })
export const appointmentCheckFailureAction = (payload: ApiFailureResponse) =>
	({ type: APPOINTMENT_CHECK_FAILURE, payload })

const initialState: AppointmentState = {
	future_appointments: null,
	future_appointments_fetching: false,
	future_appointments_error: null,
	future_appointments_page: null,
	future_appointments_limit: null,
	future_appointments_total: null,
	past_appointments: null,
	past_appointments_fetching: false,
	past_appointments_error: null,
	past_appointments_page: null,
	past_appointments_limit: null,
	past_appointments_total: null,
	new_appointment: null,
	doctor_gender_list: null,
	doctor_gender_fetching: false,
	doctor_gender_error: null,
	slots_fetching: false,
	slots: null,
	slots_error: null,
	reserve: null,
	reserve_error: null,
	appointments_fetching: false,
	appointment: null,
	appointments_error: null,
	patient_fetching: false,
	patient_error: null,
	reserve_slot: null,
	primary_reserve_fetching: false,
	secondary_reserve_fetching: false,
	delete_secondary_reserve_fetching: false,
	appointment_cancel_fetching: false,
	appointment_cancel_error: null,
	payment: null,
	appointment_payment_cancel_fetching: false,
	appointment_payment_cancel_error: null,
	appointment_payment_status_fetching: false,
	appointment_payment_status_error: null,
	payment_status: null,
	check: null,
	check_fetching: false,
	check_error: null,
	calendar: null,
	calendar_fetching: false,
	calendar_error: null,
	temp_reserve: null,
	temp_reserve_params: null,
}

const AppointmentReducer = (state = initialState, action: any): AppointmentState => {
	const { type, payload, params } = action
	switch (type) {
		case APPOINTMENT_FUTURE_REQUEST: {
			return { ...state, future_appointments_error: null, future_appointments_fetching: true }
		}
		case APPOINTMENT_FUTURE_SUCCESS: {
			const { appointments, page, limit, total } = payload as AppointmentsSuccessResponse
			const future_appointments = page === 1 ? appointments : [...state.future_appointments, ...appointments]
			return {
				...state,
				future_appointments_fetching: false,
				future_appointments,
				future_appointments_page: page,
				future_appointments_limit: limit,
				future_appointments_total: total,
			}
		}
		case APPOINTMENT_FUTURE_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, future_appointments_fetching: false, future_appointments_error: error }
		}
		case APPOINTMENT_PAST_REQUEST: {
			return { ...state, past_appointments_error: null, past_appointments_fetching: true }
		}
		case APPOINTMENT_PAST_SUCCESS: {
			const { appointments, page, limit, total } = payload as AppointmentsSuccessResponse
			const past_appointments = page === 1 ? appointments : [...state.past_appointments, ...appointments]
			return {
				...state,
				past_appointments_fetching: false,
				past_appointments,
				past_appointments_page: page,
				past_appointments_limit: limit,
				past_appointments_total: total
			}
		}
		case APPOINTMENT_PAST_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, past_appointments_fetching: false, past_appointments_error: error }
		}
		case APPOINTMENT_NEW_ADD_DATA: {
			const new_appointment = { ...(state.new_appointment || {}), ...params, }
			return { ...state, new_appointment }
		}
		case APPOINTMENT_NEW_RESET: {
			return { ...state, new_appointment: null }
		}
		case APPONTMENT_NEW_RESET: {
			const resetAll = action.params
			if (resetAll) {
				return { ...state, reserve_slot: null, new_appointment: null }
			}
			return { ...state, reserve_slot: null }
		}
		case APPOINTMENT_PART_RESET: {
			return {
				...initialState,
				new_appointment: state.new_appointment,
				doctor_gender_list: state.doctor_gender_list,
				reserve_slot: state.reserve_slot,
			}
		}
		case APPOINTMENT_GENDER_REQUEST: {
			return { ...state, doctor_gender_fetching: true, doctor_gender_list: null }
		}
		case APPOINTMENT_GENDER_SUCCESS: {
			return { ...state, doctor_gender_fetching: false, doctor_gender_list: payload }
		}
		case APPOINTMENT_GENDER_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, doctor_gender_fetching: false, doctor_gender_error: error }
		}
		case APPOINTMENT_PRIMARY_SLOTS_REQUEST:
		case APPOINTMENT_SECONDARY_SLOTS_REQUEST: {
			return { ...state, slots_error: null, slots_fetching: true }
		}
		case APPOINTMENT_PRIMARY_SLOTS_SUCCESS:
		case APPOINTMENT_SECONDARY_SLOTS_SUCCESS: {
			const { slots } = payload as AppointmentSlotsSuccessResponse
			return { ...state, slots_fetching: false, slots }
		}
		case APPOINTMENT_PRIMARY_SLOTS_FAILURE:
		case APPOINTMENT_SECONDARY_SLOTS_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, slots_fetching: false, slots_error: error }
		}
		case APPOINTMENT_PRIMARY_RESERVE_REQUEST: {
			const temp_reserve = action.params as AppointmentPrimaryReserveRequest
			return { ...state, primary_reserve_fetching: true, reserve_error: null, reserve_slot: null, temp_reserve }
		}
		case APPOINTMENT_PRIMARY_RESERVE_SUCCESS: {
			const { slot } = payload as AppointmentPrimaryReserveSuccessResponse
			return { ...state, primary_reserve_fetching: false, reserve_slot: slot }
		}
		case APPOINTMENT_PRIMARY_RESERVE_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, primary_reserve_fetching: false, reserve_error: error }
		}
		case APPOINTMENT_SECONDARY_RESERVE_REQUEST: {
			const temp_reserve_params = action.params as AppointmentSecondaryReserveRequest
			return { ...state, secondary_reserve_fetching: true, reserve_error: null, temp_reserve_params }
		}
		case APPOINTMENT_SECONDARY_RESERVE_SUCCESS: {
			const { slot } = payload as AppointmentSecondaryReserveSuccessResponse
			return { ...state, secondary_reserve_fetching: false, reserve_slot: slot, temp_reserve_params: null }
		}
		case APPOINTMENT_SECONDARY_RESERVE_FAILURE: {
			const { error } = payload as ApiFailureResponse
			const slots = [...(state.slots || [])]
			const slot = slots.find(s => s.id === state.temp_reserve_params?.slot_id)
			slot.available = false
			return { ...state, secondary_reserve_fetching: false, reserve_error: error, slots }
		}
		case APPOINTMENT_SECONDARY_RESERVE_DELETE_REQUEST: {
			return { ...state, delete_secondary_reserve_fetching: true, reserve_error: null, reserve_slot: null }
		}
		case APPOINTMENT_SECONDARY_RESERVE_DELETE_SUCCESS: {
			return { ...state, delete_secondary_reserve_fetching: false }
		}
		case APPOINTMENT_SECONDARY_RESERVE_DELETE_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, delete_secondary_reserve_fetching: false, reserve_error: error }
		}
		case APPOINTMENT_MAKE_REQUEST: {
			return { ...state, appointments_fetching: true, appointments_error: null, payment: null }
		}
		case APPOINTMENT_MAKE_SUCCESS: {
			const { appointment, payment } = payload as AppointmentMakeSuccessResponse
			return { ...state, appointments_fetching: false, appointment, payment, temp_reserve: null }
		}
		case APPOINTMENT_MAKE_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, appointments_fetching: false, appointments_error: error }
		}
		case APPOINTMENT_PATIENT_REQUEST: {
			return { ...state, patient_fetching: true, patient_error: null }
		}
		case APPOINTMENT_PATIENT_SUCCESS: {
			return { ...state, patient_fetching: false }
		}
		case APPOINTMENT_PATIENT_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, patient_fetching: false, patient_error: error }
		}
		case APPOINTMENT_CANCEL_REQUEST: {
			return { ...state, appointment_cancel_fetching: true, appointment_cancel_error: null }
		}
		case APPOINTMENT_CANCEL_SUCCESS: {
			return { ...state, appointment_cancel_fetching: false }
		}
		case APPOINTMENT_CANCEL_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, appointment_cancel_fetching: false, appointment_cancel_error: error }
		}
		case APPOINTMENT_PAYMENT_CANCEL_REQUEST: {
			return { ...state, appointment_payment_cancel_fetching: true, appointment_payment_cancel_error: null }
		}
		case APPOINTMENT_PAYMENT_CANCEL_SUCCESS: {
			return { ...state, appointment_payment_cancel_fetching: false, appointment: null, payment: null }
		}
		case APPOINTMENT_PAYMENT_CANCEL_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, appointment_payment_cancel_fetching: false, appointment_payment_cancel_error: error }
		}
		case APPOINTMENT_PAYMENT_STATUS_REQUEST: {
			return { ...state, appointment_payment_status_fetching: true, appointment_payment_status_error: null, payment_status: null }
		}
		case APPOINTMENT_PAYMENT_STATUS_SUCCESS: {
			const { status } = payload as AppointmentPaymentStatusSuccessResponse
			return { ...state, appointment_payment_status_fetching: false, payment_status: status }
		}
		case APPOINTMENT_PAYMENT_STATUS_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, appointment_payment_status_fetching: false, appointment_payment_status_error: error }
		}
		case APPOINTMENT_CHECK_REQUEST: {
			return { ...state, check_fetching: true, check: null }
		}
		case APPOINTMENT_CHECK_SUCCESS: {
			return { ...state, check_fetching: false, }
		}
		case APPOINTMENT_CHECK_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, check_fetching: false, check_error: error }
		}
		case APPOINTMENT_SECONDARY_CALENDAR_REQUEST:
		case APPOINTMENT_PRIMARY_CALENDAR_REQUEST: {
			return { ...state, calendar_fetching: true, calendar: null, calendar_error: null }
		}
		case APPOINTMENT_SECONDARY_CALENDAR_SUCCESS:
		case APPOINTMENT_PRIMARY_CALENDAR_SUCCESS: {
			const { schedule } = payload as AppointmentPrimaryScheduleResponse
			return { ...state, calendar_fetching: false, calendar: schedule }
		}
		case APPOINTMENT_SECONDARY_CALENDAR_FAILURE:
		case APPOINTMENT_PRIMARY_CALENDAR_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, calendar_fetching: false, calendar_error: error }
		}
		case CREATE_REVIEW_SUCCESS: {
			const { appointment } = action.payload
			return {
				...state,
				past_appointments: state.past_appointments
					? state.past_appointments.map(pastAppointment => {
						if (pastAppointment.id === appointment.id) {
							return appointment
						}
						return pastAppointment
					})
					: [],
			}
		}
		default:
			return state
	}
}

export default AppointmentReducer
