import { createStore, applyMiddleware, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer } from 'redux-persist'
import storage from '@react-native-community/async-storage'

import rootSaga from '../saga'
import _appReducer from './_app'
import AppReducer from './app'
import AuthReducer from './auth'
import UserReducer from './user'
import TrackerReducer from './tracker'
import EmployeeReducer from './employee'

const rootReducer = combineReducers({
  _app: _appReducer,
  app: AppReducer,
  auth: AuthReducer,
  user: UserReducer,
  tracker: TrackerReducer,
  employee: EmployeeReducer,
})

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    '_app',
    'auth',
    'user',
  ],
}

const composeEnhancers = composeWithDevTools({})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware()

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(sagaMiddleware)))

const persistor = persistStore(store, null, () => {
  store.getState()
})

sagaMiddleware.run(rootSaga)

export type AppState = ReturnType<typeof rootReducer>

export { persistor, store, storage }
