import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { ActivityIndicator, View, ViewStyle, Dimensions, Text } from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'

import { Screen, Button } from '../components'
import { AppointmentNavigatorProps } from '../types'
import { font, ThemeColors, useTheme } from '../theme'
import { t } from '../localization'
import { AppState } from '../store/redux'
import { appointmentPaymentStatusRequestAction, appointmentPaymentCancelRequestAction } from '../store/redux/appointments'
import { paymentPartResetAction, paymentResetAction } from '../store/redux/payment'

type ScreenProps = DispatchProps & StateProps & StackScreenProps<AppointmentNavigatorProps, 'PaymentScreen'>

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

interface Props extends ScreenProps {
	colors: ThemeColors
	isSmallScreen: boolean
}

interface State {
	stopCheckStatus: boolean
}

function PaymentScreenWrapper(props: ScreenProps) {
	const { colors } = useTheme()
	const [screenData, setScreenData] = useState(Dimensions.get('window'))

  useEffect(() => {
    const onChange = (result) => {
      setScreenData(result.window)
    }

    const subscription = Dimensions.addEventListener('change', onChange)

		return () => subscription?.remove()
  })

	return <PaymentScreen colors={colors} isSmallScreen={screenData.width < 655} {...props} />
}

class PaymentScreen extends React.Component<Props, State>{
	isConfirmed: boolean
	isReturned: boolean
	checkout: any
	times: number = 0

	constructor(props: Props) {
		super(props)
		this.isConfirmed = false
		this.isReturned = false
		this.state = {
			stopCheckStatus: false,
		}
	}

	componentDidMount() {
		const { confirmation_url, payment_id } = this.props.route.params || {}

		if (confirmation_url && confirmation_url.startsWith('https://yoomoney.ru/checkout/payments')) {
			window.open(confirmation_url, '_self')
		} else if (payment_id) {
			this.props.checkPaymentStatus(payment_id)
		} else {
			this.props.navigation.navigate('MainStack', { screen: 'MainScreen' })
		}

		// const script = document.createElement("script")
		// script.src = "https://yookassa.ru/checkout-widget/v1/checkout-widget.js"
		// script.async = true
		// script.onload = () => this.scriptLoaded()
		// document.body.appendChild(script)
	}

	async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
		const {
			payment_status,
			payment_status_error,
			navigation,
			cancelPayment,
			payment,
			partResetPayment,
		} = this.props
		const { payment_id } = this.props.route.params || null
		const { stopCheckStatus } = this.state

		if (payment_status_error) {
			navigation.navigate('MainStack', { screen: 'MainScreen' })
		} else {
			this.isConfirmed = true
			switch (payment_status) {
				case 'waiting_for_capture':
				case 'succeeded':
					navigation.replace('NewAppointmentConfirmScreen', { success: true })
					break;
				case 'canceled':
					if (payment) {
						cancelPayment(payment?.id)
						partResetPayment()
					} else {
						navigation.replace('NewAppointmentConfirmScreen', { success: false })
					}
					break;
				case 'pending':
					if (!stopCheckStatus && navigation.isFocused()) {
						if (this.times < 150) {
							await timeout(2000)
							this.times++
							this.props.checkPaymentStatus(payment_id)
						} else {
							if (payment) {
								cancelPayment(payment?.id)
								partResetPayment()
							} else {
								navigation.navigate('MainStack', { screen: 'MainScreen' })
							}
						}
					}
					break;
				default:
			}
		}
	}

	componentWillUnmount() {
		const { cancelPayment, payment } = this.props

		if (!this.isConfirmed && payment?.id) {
			cancelPayment(payment.id)
		}
	}

	// scriptLoaded() {
	// 	const { payment_id, confirmation_url } = this.props.route.params || null
	// 	const [_, token] = confirmation_url.split('=')
	// 	this.checkout = new window.YooMoneyCheckoutWidget({
	// 		confirmation_token: token,
	// 		return_url: `http://localhost:8080/checkappontment/${payment_id}`,
	// 		error_callback: function(error) {
	// 			console.log(error)
	// 		}
	// 	})
	// 	this.checkout.render('payment-form')
	// }

	onPress = () => {
		this.setState({ stopCheckStatus: true }, () => {
			this.props.resetPayment()
			this.props.navigation.replace('MainStack', { screen: 'MainScreen' })
		})
	}

	render() {
		const { isSmallScreen, colors } = this.props
		const { confirmation_url } = this.props.route.params || {}

		return (
			<Screen>
				<View style={styles.contentContainer}>
					<View style={[
						styles.contentWrapper,
						{
							width: !isSmallScreen ? '55%' : '100%',
							minWidth: !isSmallScreen ? 655 : 'auto',
							backgroundColor: colors.background.primary,
							borderRadius: 20,
						},
					]}>
						<View style={{
							flex: 1,
							justifyContent: 'center',
							alignItems: 'center',
						}}>
							{ !confirmation_url && (
							<Text style={{
								color: colors.text.title,
								fontFamily: font('bold'),
								fontSize: 16,
								marginBottom: 24,
							}}>{t('payment_info')}</Text>
							)}
							<View style={{ height: 80 }}>
								<Loading />
							</View>
							{ !confirmation_url && (
							<Button
								style={{ marginTop: 24 }}
								text={t('to_main')}
								onPress={this.onPress}
							/>
							)}
						</View>
					</View>
				</View>
			</Screen >
		)
	}
}

function timeout(delay: number) {
	return new Promise( res => setTimeout(res, delay) );
}

const mapStateToProps = (state: AppState) => ({
	new_appoinment: state.payment.new_appointment,
	payment: state.payment.payment,
	payment_status: state.appointment.payment_status,
	payment_status_error: state.appointment.appointment_payment_status_error,
})

const mapDispatchToProps = {
	checkPaymentStatus: appointmentPaymentStatusRequestAction,
	cancelPayment: appointmentPaymentCancelRequestAction,
	partResetPayment: paymentPartResetAction,
	resetPayment: paymentResetAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentScreenWrapper)

function Loading() {
	const { colors } = useTheme()
	return (
		<View style={styles.loader} >
			<ActivityIndicator color={colors.fetching} size="large" />
		</View>
	)
}

const styles = {
	contentContainer: {
		flexGrow: 1,
	} as ViewStyle,
	contentWrapper: {
		flexGrow: 1,
		marginVertical: 30,
		paddingHorizontal: 30,
		alignSelf: 'center',
	},
	loader: {
		position: 'absolute',
		flex: 1,
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 1,
	} as ViewStyle,
}
