import { put, call } from 'redux-saga/effects'
import { ApiResponse } from 'apisauce';

import { api, ApiSuccessResponse } from '../../api'
import {
	Action,
	TrackerRequest,
	TrackerSuccessResponse,
	CreateTrackerRequest,
	CreateTrackerSuccessResponse,
	EditTrackerRequest,
	EditTrackerSuccessResponse,
	DeleteTrackerRequest,
	DeleteTrackerSuccessResponse,
	TrackerRatesRequest,
	TrackerRatesSuccessResponse,
	TrackerSettingsRequest,
	TrackerSettingsSuccessResponse,
	TrackerStatsRequest,
	TrackerStatsSuccessResponse,
	TrackerSendStatsRequest,
	TrackerSendStatsSuccessResponse,
	TrackerType,
} from '../../types'
import {
	trackersSuccessAction,
	trackersFailureAction,
	createTrackerSuccessAction,
	createTrackerFailureAction,
	editTrackerSuccessAction,
	editTrackerFailureAction,
	deleteTrackerSuccessAction,
	deleteTrackerFailureAction,
	trackerRatesSuccessAction,
	trackerRatesFailureAction,
	trackerSettingsSuccessAction,
	trackerSettingsFailureAction,
	trackerStatsSuccessAction,
	trackerStatsFailureAction,
	trackerSendStatsSuccessAction,
	trackerSendStatsFailureAction,
} from '../redux/trackers'
import { useNotifications } from '../../hooks/useNotifications'

async function setNotifications(tracker: TrackerType) {
	const {
		removeNotificationsByTrackerId,
		createTriggerNotificationsById,
	} = useNotifications()
	if (tracker.notifications) {
		let notificationsTimes = JSON.parse(tracker.notification_data)
		if (notificationsTimes.length === 0) {
			notificationsTimes = [{time: '20:00'}]
		}
		await createTriggerNotificationsById(
			tracker.id,
			notificationsTimes,
			tracker.icon,
			tracker.title,
		)
	} else {
		await removeNotificationsByTrackerId(tracker.id)
	}
}

export function* trackersRequestSaga(action: Action<TrackerRequest>) {
	const response: ApiResponse<ApiSuccessResponse<TrackerSuccessResponse>> = yield call(api.trackerRequest, { 'tz_offset': action.params?.tz_offset })
	if (response.ok) {
		yield put(trackersSuccessAction({ trackers: response.data!.result.trackers }))

		if (action.params?.initial) {
			response.data!.result.trackers.forEach(tracker => {
				setNotifications(tracker)
			})
		}
	} else {
		yield put(trackersFailureAction(response.data))
	}
}

export function* createTrackerRequestSaga(action: Action<CreateTrackerRequest>) {
	const response: ApiResponse<ApiSuccessResponse<CreateTrackerSuccessResponse>> = yield call(api.createTrackerRequest, action.params)
	if (response.ok) {
		yield put(createTrackerSuccessAction(response.data.result))
	} else {
		yield put(createTrackerFailureAction(response.data))
	}
}

export function* editTrackerRequestSaga(action: Action<EditTrackerRequest>) {
	const response: ApiResponse<ApiSuccessResponse<EditTrackerSuccessResponse>> = yield call(api.editTrackerRequest, action.params)
	if (response.ok) {
		yield put(editTrackerSuccessAction(response.data.result))
	} else {
		yield put(editTrackerFailureAction(response.data))
	}
}

export function* deleteTrackerRequestSaga(action: Action<DeleteTrackerRequest>) {
	const response: ApiResponse<ApiSuccessResponse<DeleteTrackerSuccessResponse>> = yield call(api.deleteTrackerRequest, action.params)
	if (response.ok) {
		yield put(deleteTrackerSuccessAction(response.data.result))
	} else {
		yield put(deleteTrackerFailureAction(response.data))
	}
}

export function* trackerRatesRequestSaga(action: Action<TrackerRatesRequest>) {
	const response: ApiResponse<ApiSuccessResponse<TrackerRatesSuccessResponse>> = yield call(api.trackerRatesRequest, action.params)
	if (response.ok) {
		yield put(trackerRatesSuccessAction({ rates: response.data.result.rates}))
	} else {
		yield put(trackerRatesFailureAction(response.data))
	}
}

export function* trackerSettingsRequestSaga(action: Action<TrackerSettingsRequest>) {
	const response: ApiResponse<ApiSuccessResponse<TrackerSettingsSuccessResponse>> = yield call(api.trackerSettingsRequest, action.params)
	if (response.ok) {
		yield put(trackerSettingsSuccessAction({ settings: response.data.result.settings }))
	} else {
		yield put(trackerSettingsFailureAction(response.data))
	}
}

export function* trackerStatsRequestSaga(action: Action<TrackerStatsRequest>) {
	const response: ApiResponse<ApiSuccessResponse<TrackerStatsSuccessResponse>> = yield call(api.trackerStatsRequest, action.params)
	if (response.ok) {
		yield put(trackerStatsSuccessAction({ tracker_results: response.data.result.tracker_results }))
	} else {
		yield put(trackerStatsFailureAction(response.data))
	}
}

export function* trackerSendStatsRequestSaga(action: Action<TrackerSendStatsRequest>) {
	const response: ApiResponse<ApiSuccessResponse<TrackerSendStatsSuccessResponse>> = yield call(api.trackerSendStatsRequest, action.params)
	if (response.ok) {
		yield put(trackerSendStatsSuccessAction({ result: response.data.result }))
	} else {
		yield put(trackerSendStatsFailureAction(response.data))
	}
}
