import React, { useEffect, useState } from 'react'
import {
  View,
  Text,
  Image,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Dimensions,
} from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import {
  VictoryScatter,
  VictoryChart,
  VictoryLine,
  VictoryTooltip,
  VictoryAxis,
  VictoryArea,
} from 'victory'
// import {
//   // Defs,
//   // LinearGradient,
//   // Stop,
//   Svg,
// } from 'react-native-svg'
import { showMessage } from 'react-native-flash-message'
import moment from 'moment'
import upperFirst from 'lodash/upperFirst'

import { Button, Calendar, Icon, Modal, Screen, TextInput } from '../components'
import { t } from '../localization'
import { AppState } from '../store'
import { font, useTheme } from '../theme'
import {
  Nullable,
  TrackerRateType,
  TrackerPeriodStatType,
  TrackerType,
  TrackerStatType,
} from '../types'
import {
  trackerSendStatsRequestAction,
  trackerStatsRequestAction,
  trackersRequestAction,
} from '../store/redux/trackers'
import { done, emptyAppointment } from '../images'
import { usePrevious } from '../hooks/usePrevious'
import { getUtcOffset } from '../service'
import config from '../config'

export default function TrackerScreen({ route }) {
  const { id } = route.params
  const { colors } = useTheme()
  const dispatch = useDispatch()
  const navigation = useNavigation()

  const trackers = useSelector((state: AppState) => state.tracker.trackers)
  const tracker = trackers.find((item: TrackerType) => item.id === id)
  const trackerRates = useSelector((state: AppState) => state.tracker.rates)
  const trackerStats = useSelector((state: AppState) => state.tracker.tracker_stats)
  const sendRateSuccess = useSelector((state: AppState) => state.tracker.tracker_send_stats_success)
  const rates = useSelector((state: AppState) => state.tracker.rates)

  const [selectedRate, setSelectedRate] = useState<Nullable<TrackerRateType>>(null)
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [startDate, setStartDate] = useState(moment().subtract(6, 'days').startOf('day'))
  const [endDate, setEndDate] = useState(moment().endOf('day'))
  const [showCalendar, setShowCalendar] = useState<boolean>(false)
  const [selectedDate, setSelectedDate] = useState(null)
  const [chartData, setChartData] = useState([])
  const [selectedEvent, setSelectedEvent] = useState<Nullable<TrackerRateType>>(null)
  const [selectedDateRates, setSelectedDateRates] = useState<Array<TrackerStatType>>([])
  const [selectedDateEventsCount, setSelectedDateEventsCount] = useState({})
  // const [externalMutations, setExternalMutations] = useState(undefined)
  const [screenData, setScreenData] = useState(Dimensions.get('window'))
  const [isRateSended, setIsRateSended] = useState(false)

  const prevSendRateSuccess = usePrevious(sendRateSuccess)
  const prevShowCalendar = usePrevious(showCalendar)
  const prevStartDate = usePrevious(startDate)
  const prevEndDate = usePrevious(endDate)

  useEffect(() => {
    const onChange = (result) => {
      setScreenData(result.window)
    }

    const subscription = Dimensions.addEventListener('change', onChange)

    return () => subscription?.remove()
  })

  useEffect(() => {
    if (!tracker || +id !== tracker.id) {
      navigation.navigate('TrackerStack', { screen: 'TrackerListScreen' })
    } else {
      dispatch(trackerStatsRequestAction({
        id,
        from: startDate.utc().format('YYYY-MM-DD HH:mm:ss'),
        to: endDate.utc().format('YYYY-MM-DD HH:mm:ss'),
        tz_offset: getUtcOffset(),
      }))
    }
  }, [])

  useEffect(() => {
    if (prevSendRateSuccess != null && !prevSendRateSuccess && sendRateSuccess) {
      setIsRateSended(true)
      dispatch(trackerStatsRequestAction({
        id,
        from: startDate.utc().format('YYYY-MM-DD HH:mm:ss'),
        to: endDate.utc().format('YYYY-MM-DD HH:mm:ss'),
        tz_offset: getUtcOffset(),
      }))
      dispatch(trackersRequestAction({ tz_offset: getUtcOffset() }))
    }
  }, [sendRateSuccess])

  useEffect(() => {
    if (prevShowCalendar && !showCalendar && (startDate !== prevStartDate || endDate !== prevEndDate)) {
      setSelectedDate(null)
      setChartData([])
      setSelectedEvent(null)
      setSelectedDateRates([])
      setSelectedDateEventsCount({})
      dispatch(trackerStatsRequestAction({
        id,
        from: startDate.utc().format('YYYY-MM-DD HH:mm:ss'),
        to: endDate.utc().format('YYYY-MM-DD HH:mm:ss'),
        tz_offset: getUtcOffset(),
      }))
    }
  }, [showCalendar])

  useEffect(() => {
    setChartData(trackerStats.map((stat: TrackerPeriodStatType, index) => {
      return {
        points: stat.count ?? 0,
        date: moment(stat.date).format('DD.MM.YYYY'),
      }
    }))
  }, [trackerStats])

  useEffect(() => {
    if (selectedDate !== null) {
      setSelectedDateRates(
        trackerStats.find(
          (stat: TrackerPeriodStatType) =>
            moment(stat.date, 'YYYY-MM-DD')
              .isSame(moment(selectedDate, 'DD.MM.YYYY')
          )
        ).events
      )
      setSelectedDateEventsCount({})
      setSelectedEvent(null)
    }
  }, [selectedDate])

  useEffect(() => {
    if (selectedDateRates.length != 0) {
      let events = {}
      selectedDateRates.forEach((rate: TrackerStatType) => {
        if (rate.rate !== null) {
          if (!events[rate.rate.id]) {
            events[rate.rate.id] = 0
          }
          events[rate.rate.id] += 1
        }
      })
      setSelectedDateEventsCount(events)
    }
  }, [selectedDateRates])

  // useEffect(() => {
  //   if (chartData.length !== 0) {
  //     setExternalMutations([
  //       {
  //         childName: 'Scatter',
  //         target: 'labels',
  //         eventKey: 'all',
  //         mutation: () => ({active: false}),
  //         callback: () => setExternalMutations(undefined)
  //       }
  //     ])
  //   }
  // }, [chartData])

  const renderTrackerCard = () => (
    <View style={[styles.trackerCard, {
      backgroundColor: colors.background.primary,
    }]}>
      <View style={[styles.trackerIconContainer, { backgroundColor: tracker?.background }]}>
        <Text style={[styles.trackerIcon, { color: colors.text.default }]}>{tracker?.icon}</Text>
      </View>
      <View style={styles.trackerDescriptionContainer}>
        <Text style={[styles.trackerTitle, {
          color: colors.text.subtitle,
        }]}>{tracker?.title}</Text>
        <Text
          style={{color: colors.text.primary}}
        >{tracker?.description}</Text>
      </View>
    </View>
  )

  const renderLastEvent = () => {
    return (
      <View style={[styles.lastEventCard, { backgroundColor: colors.background.primary }]}>
        <Text style={[styles.statTitle, {
          color: colors.text.subtitle,
        }]}>{t('last_event')}</Text>
        <View style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 8,
        }}>
          <View style={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
          }}>
            <Text style={{
              fontSize: 24,
              marginRight: 8,
            }}>{tracker?.last_event.rate.icon}</Text>
            <Text numberOfLines={1} style={{
              fontFamily: font('bold'),
            }}>{tracker?.last_event.title}</Text>
          </View>
          <Text style={{
            fontSize: 10,
            color: colors.text.primary,
          }}>{moment(tracker?.last_event.created_at).format('DD.MM.YYYY')}</Text>
        </View>
        <Text style={{
          color: colors.text.primary,
        }}>{tracker?.last_event.description}</Text>
      </View>
    )
  }

  const renderGradeTrackerCard = () => {
    if (tracker?.today_results >= 20) {
      return (
        <View style={[styles.trackerGradeCard, {
          backgroundColor: colors.background.primary,
        }]}>
          <View style={styles.limitTodayRateContainer}>
            <Image
              style={styles.image}
              source={emptyAppointment}
              resizeMode='contain'
            />
            <Text style={[styles.limitTodayRateText, {
              color: colors.text.default,
            }]}>{t('return_for_new_rate')}</Text>
          </View>
        </View>
      )
    }

    if (isRateSended) {
      return (
        <View style={[styles.succesRateSendingContainer, {
          backgroundColor: colors.background.primary,
        }]}>
          <Image
            style={{ width: 254, height: 230 }}
            source={done}
            resizeMode={'contain'}
          />
          <Text style={[styles.rateDoneMessage, {
            color: colors.text.title,
          }]}>{t('everything_will_be_fine')}</Text>
        </View>
      )
    }

    return (
      <View style={[styles.trackerGradeCard, {
        backgroundColor: colors.background.primary,
      }]}>
        <View style={styles.gradeRow}>
          {trackerRates.map((rate: TrackerRateType) => {
            const isSelected = selectedRate?.id === rate.id
            const bgColor = rate.weight > 0 ? colors.tracker.positive : colors.tracker.negative
            return (
              <TouchableOpacity
                key={rate.id}
                onPress={() => {
                  setSelectedRate(rate)
                }}
                style={[styles.rateIconContainer, {
                  backgroundColor: isSelected ? bgColor : 'transparent',
                }]}
              >
                <Text style={[styles.rateIcon, {
                  color: colors.text.default,
                }]}>{rate.icon}</Text>
              </TouchableOpacity>
            )
          })}
        </View>
        <View style={styles.noteContainer}>
          <Text>
            <Text style={[styles.noteTitle, {
              color: colors.text.default,
            }]}>{t('important')}</Text>
            <Text style={[styles.noteDescription, {
              color: colors.text.default,
            }]}>{t('rate_description')}</Text>
          </Text>
        </View>
        <TextInput
          style={styles.titleField}
          textStyle={{ height: 40 }}
          value={title}
          placeholder={t('tracker_label_placeholder')}
          onChange={(value) => {
            setTitle(value)
          }}
          maxLength={100}
        />
        <TextInput
          placeholder={t('tracker_description_placeholder')}
          value={description}
          onChange={(value) => {
            setDescription(value)
          }}
          multiline={true}
          maxLength={1000}
        />
        <Button
          style={styles.createRateBtn}
          text={t('save')}
          onPress={() => {
            dispatch(trackerSendStatsRequestAction({
              id,
              title,
              description: description,
              rate_id: selectedRate?.id!,
            }))
          }}
        />
        {tracker?.today_results > 0 && (
          <View style={styles.createRateWarningContainer}>
            <Text style={styles.createRateWarningTextWrapper}>
              <Text style={[styles.createRateWarningTextWrapper, {
                color: colors.text.default,
              }]}>{t('warning')}</Text>
              <Text style={{color: colors.text.default}}>{` ${t('today_rate_warning')}`}</Text>
            </Text>
          </View>
        )}
      </View>
    )
  }

  const renderStatCard = () => {
    let points = chartData.map(data => data.points)
    points = [...new Set(points)]
    return (
      <View
        style={[styles.trackerGradeCard, {
          backgroundColor: colors.background.primary,
        }]}
      >
        <Text style={[styles.statTitle, {
          color: colors.text.subtitle,
        }]}>{t('statistic')}</Text>
        <TouchableOpacity
          activeOpacity={1}
          style={[styles.calendarContainer, { backgroundColor: colors.background.input }]}
          onPress={() => setShowCalendar(true)}
        >
          <Text style={[styles.dateRange, {color: colors.text.default}]}>
            {`${moment(startDate).local().format('DD.MM.YYYY')} - ${moment(endDate).local().format('DD.MM.YYYY')}`}
          </Text>
          <Icon name={'calendar'} />
        </TouchableOpacity>
        <View>
          {/* <Defs>
            <LinearGradient id='gradientStroke' x1='0' y1='0' x2='0' y2='1'>
              <Stop offset='0' stopColor='red' stopOpacity='1'/>
              <Stop offset='1' stopColor='green' stopOpacity='1'/>
            </LinearGradient>
          </Defs> */}
          <VictoryChart
            // padding={{ top: 40, bottom: 40, left: 40, right: 80 }}
            // containerComponent={<Svg />}
          >
            <VictoryArea
              data={chartData}
              x='date'
              y='points'
              animate={{
                duration: 1000,
                onLoad: { duration: 300 }
              }}
              style={{
                data: {
                  // fill: 'url(#gradientStroke)',
                  fill: '#C3E2E1',
                  opacity: '0.4',
                }
              }}
            />
            <VictoryAxis
              crossAxis={false}
              dependentAxis
              offsetX={40}
              domain={points.length === 1 ? { y: [points[0], points[0] + 10] } : undefined}
              style={{
                axis: {stroke: 'transparent'},
                ticks: {size: 0},
                tickLabels: {
                  fontSize: 14,
                  fontWeight: 'bold',
                  padding: 8,
                  fill: ({ tick, ticks }: { tick: number, ticks: Array<number> }) => {
                    return tick === Math.max(...ticks)
                      ? colors.chart.axisYMaxLabel
                      : tick === Math.min(...ticks)
                        ? colors.chart.axisYMinLabel
                        : colors.chart.axisYLabel
                  },
                  // opacity: ({ tick }: { tick: number }) => tick === 0 ? 0 : 1,
                },
                grid: {stroke: ({ tick }: { tick: number }) => tick === 0
                  ? colors.chart.axisYCenterLine
                  : colors.chart.axisYLines},
              }}
            />
            <VictoryAxis
              offsetY={40}
              tickFormat={(t) => upperFirst(moment(t, 'DD.MM.YYYY').format('dd'))}
              style={{
                axis: {stroke: 'transparent'},
                ticks: {size: 0},
                tickLabels: {
                  fontSize: 10,
                  padding: 18,
                  fill: colors.chart.axisXLabel,
                },
              }}
            />
            <VictoryLine
              style={{
                data: { stroke: colors.chart.stroke },
                labels: { fill: 'red', fontSize: 10 },
              }}
              animate={{
                duration: 1000,
                onLoad: { duration: 300 }
              }}
              data={chartData}
              x='date'
              y='points'
              // interpolation='monotoneX'
            />
            <VictoryScatter
              data={chartData}
              x='date'
              y='points'
              size={6}
              animate={{
                duration: 1000,
                onLoad: { duration: 300 }
              }}
              name="Scatter"
              // externalEventMutations={externalMutations}
              labelComponent={
                <VictoryTooltip
                  cornerRadius={7}
                  dy={-5}
                  flyoutHeight={14}
                  flyoutPadding={{ left: 8, right: 8 }}
                  flyoutStyle={{ fill: colors.chart.label, strokeWidth: 0 }}
                  style={{ fill: colors.chart.labelText, fontSize: 10 }}
                  pointerLength={4}
                  pointerWidth={6}
                />
              }
              labels={({ datum }) => datum.date}
              style={{
                data: {
                  fill: colors.chart.scatter,
                  stroke: colors.chart.scatterStroke,
                  strokeWidth: 4,
                },
              }}
              events={[{
                target: 'data',
                eventHandlers: {
                  onMouseDown: () => {
                    return [
                      {
                        target: 'data',
                        eventKey: 'all',
                        mutation: () => ({
                          style: {
                            fill: colors.chart.scatter,
                            stroke: colors.chart.scatterStroke,
                            strokeWidth: 4,
                          },
                        }),
                      },
                    ]
                  },
                  onMouseUp: () => {
                    return [
                      {
                        target: 'data',
                        mutation: (props) => {
                          setSelectedDate(props.datum.date)
                          return ({
                            style: {
                              fill: colors.chart.scatterStroke,
                              stroke: colors.chart.scatter,
                              strokeWidth: 6,
                            },
                          })
                        },
                      }
                    ]
                  },
                },
              }]}
            />
          </VictoryChart>
        </View>
        <Calendar
          isVisible={showCalendar}
          onClose={() => setShowCalendar(false)}
          dates={[startDate.local().format(), endDate.local().format()]}
          onSelect={(dates: string[]) => {
            const diff = moment(dates[1]).diff(moment(dates[0]), 'days') + 1
            if (diff > 14 && dates[1]) {
              showMessage({
                type: 'danger',
                message: t('select_interval_warning'),
                duration: config.api.messageDuration,
              })
            } else {
              setStartDate(moment(dates[0]))
              setEndDate(dates[1] ? moment(dates[1]).endOf('day') : moment(dates[0]).endOf('day'))
            }
            setShowCalendar(false)
          }}
          multiSelection
        />
      </View>
    )
  }

  const renderEvents = () => {
    const positiveRates = rates.filter((rate: TrackerRateType) => rate.weight > 0)
    const negativeRates = rates.filter((rate: TrackerRateType) => rate.weight < 0)
    negativeRates.reverse()
    return (
      <View style={[styles.trackerGradeCard, {
        backgroundColor: colors.background.primary,
      }]}>
        <Text style={[styles.eventsTitle, {
          color: colors.text.subtitle,
        }]}>{t('events')}</Text>
        <View style={[styles.eventsRow, styles.mb16]}>
          {positiveRates.map((rate: TrackerRateType) => {
            const eventCounts = selectedDateEventsCount[rate.id] ?? 0
            return (
              <TouchableOpacity key={rate.id} onPress={() => setSelectedEvent(rate)}>
                <View style={styles.eventContainer}>
                  <View style={[styles.eventIconContainer, {
                    backgroundColor: selectedEvent?.id !== rate.id
                      ? colors.tracker.positive
                      : colors.tracker.positiveSelected,
                  }]}>
                    <View style={[styles.eventBadge, {
                      backgroundColor: colors.badge.background,
                    }]}>
                      <Text style={[styles.eventsBadgeLabel, {
                        color: colors.badge.text,
                      }]}>{`${eventCounts}`}</Text>
                    </View>
                    <Text style={[styles.eventIcon, {
                      color: colors.text.default,
                    }]}>{rate.icon}</Text>
                  </View>
                  <Text style={[styles.eventTitle, {
                    color: colors.text.default,
                  }]}>{rate.title}</Text>
                </View>
              </TouchableOpacity>
            )
          }
        )}
        </View>
        <View style={styles.eventsRow}>
          {negativeRates.map((rate: TrackerRateType) => {
            const eventCounts = selectedDateEventsCount[rate.id] ?? 0
            return (
              <TouchableOpacity key={rate.id} onPress={() => setSelectedEvent(rate)}>
                <View style={styles.eventContainer}>
                  <View style={[styles.eventIconContainer, {
                    backgroundColor: selectedEvent?.id !== rate.id
                    ? colors.tracker.negative
                    : colors.tracker.negativeSelected,
                  }]}>
                    <View style={[styles.eventBadge, {
                      backgroundColor: colors.badge.background,
                    }]}>
                      <Text style={[styles.eventsBadgeLabel, {
                        color: colors.badge.text,
                      }]}>{`${eventCounts}`}</Text>
                    </View>
                    <Text style={[styles.eventIcon, {
                      color: colors.text.default,
                    }]}>{rate.icon}</Text>
                  </View>
                  <Text style={[styles.eventTitle, {
                    color: colors.text.default,
                  }]}>{rate.title}</Text>
                </View>
              </TouchableOpacity>
            )
          }
        )}
        </View>
      </View>
    )
  }

  const renderRates = () => {
    const eventRates = selectedDateRates.filter(rate => (
      rate.rate.id === selectedEvent?.id
    ))
    return eventRates.map(rate => (
      <View key={rate.id} style={[styles.trackerGradeCard, {
        backgroundColor: colors.background.primary,
      }]}>
        <View style={styles.rateTitleContainer}>
          <View style={styles.rateTitle}>
            <Text style={[styles.eventIcon, {color: colors.text.default}]}>
              {rate.rate.icon}
            </Text>
            <Text style={[styles.eventRateTitle, {color: colors.text.default}]}>
              {rate.title}
            </Text>
          </View>
          <Text style={[{color: colors.text.default}]}>
            {moment(rate.created_at).format('DD.MM.YYYY')}
          </Text>
        </View>
        {!!rate.description && (
          <Text style={[styles.rateDescription, {
            color: colors.text.primary,
          }]}>{rate.description}</Text>
        )}
      </View>
    ))
  }

  const renderTitle = () => {
		return (
			<View style={styles.title}>
				<Text style={[styles.titleText, { color: colors.text.title }]}>{t('trackers')}</Text>
			</View>
		)
	}

	const isSmallScreen = screenData.width < 655

  return (
    <Screen>
      <View style={[
        styles.contentWrapper,
        {
          width: !isSmallScreen ? '47.22%' : '100%',
          minWidth: !isSmallScreen ? 635 : 'auto',
          paddingLeft: 20,
        },
      ]}>
        {renderTitle()}
        <ScrollView
          style={styles.container}
        >
          <>
            {renderTrackerCard()}
            {tracker.last_event && renderLastEvent()}
            {renderGradeTrackerCard()}
            {renderStatCard()}
            {selectedDate !== null && renderEvents()}
            {selectedEvent !== null && renderRates()}
            <View style={{marginBottom: 20}}></View>
          </>
        </ScrollView>
      </View>
    </Screen>
  )
}
const styles = StyleSheet.create({
  container: {
    paddingBottom: 24,
  },
  contentWrapper: {
    marginTop: 40,
    paddingRight: 20,
    alignSelf: 'center',
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24,
  },
  titleText: {
    fontSize: 24,
    fontFamily: font('bold'),
  },
  trackerCard: {
    borderRadius: 20,
    padding: 16,
    flexDirection: 'row',
    marginBottom: 8,
  },
  trackerIconContainer: {
    width: 40,
    height: 40,
    marginTop: 5,
    marginRight: 8,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  trackerIcon: {
    fontSize: 20,
  },
  trackerDescriptionContainer: {
    flex: 1,
    marginRight: 8,
  },
  trackerTitle: {
    fontSize: 18,
    marginBottom: 4,
  },
  trackerGradeCard: {
    borderRadius: 20,
    padding: 16,
    marginBottom: 8,
  },
  rateIconContainer: {
    borderRadius: 6,
    width: 28,
    height: 28,
    marginHorizontal: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  rateIcon: {
    fontSize: 18,
  },
  titleField: {
    marginBottom: 8,
  },
  createRateBtn: {
    marginTop: 24,
  },
  createRateWarningContainer: {
    marginTop: 8,
    alignItems: 'center',
  },
  createRateWarningTextWrapper: {
    textAlign: 'center',
  },
  createRateWarningText: {
    fontFamily: font('bold'),
  },
  succesRateSendingContainer: {
    paddingHorizontal: 60,
    paddingVertical: 40,
    marginBottom: 8,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
  },
  rateDoneMessage: {
    fontFamily: font('bold'),
    fontSize: 24,
    marginTop: 16,
  },
  gradeRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 26,
  },
  noteContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16,
  },
  noteTitle: {
    marginRight: 8,
    fontFamily: font('bold'),
  },
  noteDescription: {
    flex: 1,
  },
  statTitle: {
    fontSize: 18,
    marginBottom: 24,
  },
  calendarContainer: {
    paddingVertical: 8,
    paddingLeft: 16,
    paddingRight: 19,
    borderRadius: 10,
    overflow: 'hidden',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24,
  },
  dateRange: {
    fontFamily: font(),
    fontSize: 16,
    lineHeight: 19,
    minHeight: 19,
    flex: 1,
  },
  image: {
    width: '100%',
    height: 240,
  },
  limitTodayRateContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 4,
  },
  limitTodayRateText: {
    fontFamily: font(),
    fontSize: 18,
    textAlign: 'center',
  },
  eventsTitle: {
    fontSize: 18,
    marginBottom: 16,
  },
  eventsRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mb16: {
    marginBottom: 16,
  },
  eventContainer: {
    alignItems: 'center',
    width: 100,
  },
  eventIconContainer: {
    width: 40,
    height: 40,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 8,
  },
  eventBadge: {
    position: 'absolute',
    height: 16,
    borderRadius: 8,
    paddingHorizontal: 6,
    overflow: 'hidden',
    top: 0,
    right: 0,
    zIndex: 1,
  },
  eventsBadgeLabel: {
    fontFamily: font('bold'),
    fontSize: 12,
  },
  eventIcon: {
    fontSize: 20,
  },
  eventTitle: {
    fontFamily: font(),
    fontSize: 12,
    textAlign: 'center',
  },
  eventRateTitle: {
    fontFamily: font('bold'),
    marginLeft: 8,
  },
  rateTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  rateTitle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rateDescription: {
    fontFamily: font(),
  },
  lastEventCard: {
    borderRadius: 20,
    padding: 16,
    marginBottom: 8,
  },
})