import React, { useState } from 'react'
import {
	View,
	ViewStyle,
	StyleSheet,
	Image,
	Text,
	TouchableWithoutFeedback,
	TouchableOpacity,
} from 'react-native'
import { MaskedTextInput } from 'react-native-mask-text'

import { Icon, Modal } from '../components'
import { font, useTheme } from '../theme'
import { getNumber, countries } from '../service'
import { PhoneCountry } from '../types'

interface PhoneInputProps {
	style?: ViewStyle | ViewStyle[]
	type?: 'phone' | 'auth_phone'
	value?: string | undefined
	onChange?: ({
		phone,
		phoneFormatted,
		country,
	}: {
		phone: string,
		phoneFormatted: string,
		country: PhoneCountry,
	}) => void
	placeholder?: string
}

export default function PhoneInput({
	style,
	type,
	value,
	placeholder,
	onChange,
	...props
}: PhoneInputProps) {
	const { colors } = useTheme()

	const [isFocused, setFocus] = useState(false)
	const [countryIndex, setCountryIndex] = useState(0)
	const [isShowSelectCountryModal, setIsShowSelectCountryModal] = useState(false)

	const _textStyle = type === 'auth_phone' ? styles.authText : styles.text

	const countryCode = countries[countryIndex].code
	value = isFocused || !!value ? getNumber(value || '') : undefined

	let paddingVertical: string | number = type === 'auth_phone' ? 13 : 10

	const onChangeText = (text: string, rawText: string) => {
		onChange && onChange({ phone: rawText, phoneFormatted: text, country: countries[countryIndex] })
	}

	const onCountryPress = () => {
		setIsShowSelectCountryModal(true)
	}

	return (
		<View style={style}>
			<View
				style={[
					styles.wrapper,
					{
						backgroundColor: type === 'phone'
							? colors.background.input
							: colors.background.primary,
					}
				]}
			>
				<View style={styles.selectCountryContainer}>
					<TouchableWithoutFeedback onPress={onCountryPress}>
						<View style={styles.flagContainer}>
							<Image style={styles.flagImage} source={countries[countryIndex].flag} />
							<Icon size={10} name={'arrow_down'} color={colors.shadow} />
						</View>
					</TouchableWithoutFeedback>
					<View style={styles.selectCountrySeparator}></View>
					<Text style={[styles.countryCode, { fontSize: type === 'auth_phone' ? 20 : 16 }]}>{`+${countryCode}`}</Text>
					<MaskedTextInput
						style={[
							_textStyle,
							styles.phone,
							{
								color: colors.text.default,
								height: _textStyle.lineHeight,
								paddingVertical,
							},
						]}
						mask={countries[countryIndex].mask}
						value={value}
						placeholder={placeholder}
						keyboardType='phone-pad'
						onFocus={() => setFocus(true)}
						onBlur={() => setFocus(false)}
						onChangeText={onChangeText}
						placeholderTextColor={colors.text.placeholder}
						{...props}
					/>
				</View>
			</View>
			<Modal
				isVisible={isShowSelectCountryModal}
				onClose={() => setIsShowSelectCountryModal(false)}
				position='center'
			>
				{countries.map((country, index) => {
					return (
						<TouchableOpacity
							key={`${country.code}_${index}`}
							style={[
								styles.selectCountryButton,
								{
									borderBottomColor: colors.border.secondary,
									borderBottomWidth: index === countries.length - 1 ? 0 : 1,
								},
							]}
							onPress={() => {
								setIsShowSelectCountryModal(false)
								setCountryIndex(index)
								onChange && onChange({ phone: '', phoneFormatted: '', country: countries[countryIndex] })
							}}
						>
							<Image style={styles.flagImage} source={country.flag} />
							<Text>{country.name}</Text>
						</TouchableOpacity>
					)
				})}
			</Modal>
		</View>
	)
}

const styles = StyleSheet.create({
	wrapper: {
		borderRadius: 10,
		flexDirection: 'row',
	},
	authText: {
		fontFamily: font(),
		fontSize: 20,
		lineHeight: 24,
	},
	text: {
		fontFamily: font(),
		fontSize: 16,
		lineHeight: 19,
		textAlignVertical: 'top',
	},
	selectCountryContainer: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		paddingHorizontal: 15,
	},
	flagContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		cursor: 'pointer',
	},
	flagImage: {
		width: 24,
		height: 19,
		borderRadius: 5,
		borderWidth: 1,
		marginRight: 12,
	},
	selectCountrySeparator: {
		marginLeft: 12,
		marginRight: 11,
		width: 2,
		height: 46,
		backgroundColor: '#EFEFF4',
	},
	countryCode: {
		fontFamily: font(),
		lineHeight: 24,
	},
	phone: {
		flex: 1,
		paddingLeft: 5,
		paddingRight: 16,
		cursor: 'auto',
	},
	selectCountryButton: {
		flexDirection: 'row',
		alignItems: 'center',
		paddingHorizontal: 32,
		height: 52,
	},
})
