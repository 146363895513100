import { put, call, select } from 'redux-saga/effects'
import { authSetTokenAction } from '../redux/auth'
import Cookies from 'universal-cookie'
import {
	Action,
	AppSuccessResponse,
	GuideSuccessResponse,
	InstructionsSuccessResponse,
	Nullable,
	GuideRequest,
	InstructionsRequest,
} from '../../types'
import I18n, { Localizations } from '../../localization'
import { AppState } from '..'
import { faqRequestAction } from '../redux/faq'
import { serviceRequestAction } from '../redux/services'
import { ApiResponse } from 'apisauce'
import { api, ApiSuccessResponse } from '../../api'
import { appInitializedAction } from '../redux/_app'
import {
	appFailureAction,
	appGuideFailureAction,
	appGuideRequestAction,
	appGuideSuccessAction,
	appInstructionsFailureAction,
	appInstructionsRequestAction,
	appInstructionsSuccessAction,
	appRequestAction,
	appSuccessAction,
} from '../redux/app'
import { appointmentNewResetAction, appointmentPartResetAction } from '../redux/appointments'

const getCredentials = (service: string) => {
	const cookies = new Cookies()
	return cookies.get(service)
}

const getLocale = (store: AppState): Localizations => store.app.locale

export function* appStartSaga() {
	yield put(appGuideRequestAction({ type: 'web' }))
	yield put(faqRequestAction())
	yield put(serviceRequestAction())
	yield put(appInstructionsRequestAction({ type: 'web' }))
	yield put(appRequestAction())
	yield put(appointmentNewResetAction(true))
	// yield put(appointmentPartResetAction())

	I18n.locale = yield select(getLocale)
	const token: Nullable<string> = getCredentials('token')

	if (!!token) {
		yield put(authSetTokenAction({ token }));
	} else {
		yield put(appInitializedAction())
	}
}

export function* appGuideRequestSaga(action: Action<GuideRequest>) {
	const response: ApiResponse<ApiSuccessResponse<GuideSuccessResponse>> = yield call(api.appGuideRequest, action?.params)
	if (response.ok) {
		yield put(appGuideSuccessAction(response.data.result))
	} else {
		yield put(appGuideFailureAction(response.data))
	}
}

export function* appInstructionsRequestSaga(action: Action<InstructionsRequest>) {
	const response: ApiResponse<ApiSuccessResponse<InstructionsSuccessResponse>> = yield call(api.appInstructionsRequest, action?.params)
	if (response.ok) {
		yield put(appInstructionsSuccessAction(response.data.result))
	} else {
		yield put(appInstructionsFailureAction(response.data))
	}
}

export function* appRequestSaga() {
	const response: ApiResponse<ApiSuccessResponse<AppSuccessResponse>> = yield call(api.appRequest)
	if (response.ok) {
		yield put(appSuccessAction(response.data.result))
	} else {
		yield put(appFailureAction(response.data))
	}
}
