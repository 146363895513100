import { put, call, delay } from 'redux-saga/effects'
import { ApiResponse } from 'apisauce';
import { api, ApiSuccessResponse } from '../../api'
import {
	Action,
	ServiceRequest,
	ServiceByIdRequest,
	ServiceSuccessResponse,
	ServiceByIdSuccessResponse,
} from '../../types';
import {
	serviceFailureAction,
	serviceSuccessAction,
	serviceByIdFailureAction,
	serviceByIdSuccessAction,
} from '../redux/services';

export function* serviceRequestSaga(action: Action<ServiceRequest>) {
	const response: ApiResponse<ApiSuccessResponse<ServiceSuccessResponse>> = yield call(api.serviceRequest, action.params)
	if (response.ok) {
		yield put(serviceSuccessAction(response.data?.result))
	} else {
		yield put(serviceFailureAction(response.data))
	}
}

export function* serviceByIdRequestSaga(action: Action<ServiceByIdRequest>) {
	const response: ApiResponse<ApiSuccessResponse<ServiceByIdSuccessResponse>> = yield call(api.serviceByIdRequest, action.params)
	if (response.ok) {
		yield put(serviceByIdSuccessAction(response.data?.result))
	} else {
		yield put(serviceByIdFailureAction(response.data))
	}
}