import React, { Component } from 'react';
import { Platform } from 'react-native';
import { StreamManager } from 'openvidu-browser';

interface Props {
  streamManager: StreamManager,
  autoPlay?: boolean,
  // playsInline: boolean,
  muted?: boolean,
  loop?: boolean,
  type?: 'remote' | 'local',
}

export default class OpenViduVideoComponent extends Component<Props> {
  videoRef: any

  constructor(props: Props) {
    super(props);

    this.videoRef = React.createRef();
  }

  componentDidUpdate(props: Props) {
    if (props && !!this.videoRef) {
      this.props.streamManager.addVideoElement(this.videoRef.current);
    }
  }

  componentDidMount() {
    if (this.props && !!this.videoRef) {
      this.props.streamManager.addVideoElement(this.videoRef.current);
    }
  }

  render() {
    const {
      autoPlay,
      // playsInline,
      muted = false,
      loop,
      type,
    } = this.props

    const remoteStyles = Platform.OS === 'web' && type === 'remote'
      ? { height: 'calc(100vh - 75px)' }
      : undefined

    return (
      <video
        style={remoteStyles}
        ref={this.videoRef}
        autoPlay={autoPlay}
        // playsInline={playsInline}
        muted={muted}
        loop={loop}
      />
    )
  }
}
