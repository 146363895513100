import React from 'react'
import { View, Text, Image, StyleSheet } from 'react-native'

import { useTheme } from '../theme'
import { t } from '../localization'
import { googlePlay, appStore, appGallery } from '../images'
import config from '../config'

function AuthFooter() {
	const { colors } = useTheme()
	const apps = [
		{
			name: 'google_play',
			img: googlePlay,
			href: config.stores.google_play,
			hrefAttrs: {
				target: 'blank',
			},
		},
		{
			name: 'app_store',
			img: appStore,
			href: config.stores.app_store,
			hrefAttrs: {
				target: 'blank',
			},
		},
		{
			name: 'app_gallery',
			img: appGallery,
			href: config.stores.app_gallery,
			hrefAttrs: {
				target: 'blank',
			},
		},
	]
	return (
		<View style={[styles.container, { borderTopColor: colors.border.secondary }]}>
			<View style={styles.wrapper}>
				<Text style={[styles.text, { color: colors.text.secondary }]}>{t('auth_apps_text')}</Text>
				{apps.map(app => (
					<View style={styles.appImageLink} key={app.name} href={app.href} hrefAttrs={app.hrefAttrs}>
						<Image
							key={app.name}
							source={app.img}
							style={styles.appImage}
							resizeMode={'contain'}
						/>
					</View>
				))}
			</View>
		</View>
	)
}

export default AuthFooter

const styles = StyleSheet.create({
	container: {
		width: '100%',
		borderTopWidth: 1,
		height: 80,
		alignItems: 'center',
		paddingHorizontal: 20,
	},
	wrapper: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'row',
	},
	text: {
		fontSize: 16,
	},
	appImageLink: {
		marginLeft: 30,
	},
	appImage: {
		width: 135,
		height: 40,
	}
})
