import React, { useState } from 'react'
import { StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native'
import { font, useTheme } from '../theme'

interface BadgeProps {
  style?: ViewStyle | ViewStyle[]
  textStyle?: TextStyle | TextStyle[]
  count: number | string
  hideZero?: boolean
}

export default function ({
  style,
  count,
  hideZero = true,
  textStyle,
}: BadgeProps) {

  const { colors } = useTheme()
  const [offset, setOffset] = useState(0)
  const _style = (!!style ? Array.isArray(style) ? style : [style] : [{}])[0] || {} as ViewStyle
  const offsetStyle = {
    left: _style.left !== undefined ? (_style.left as number - offset) : undefined,
    right: _style.right !== undefined ? (_style.right as number - offset) : undefined,
  } as ViewStyle

  if (count === null || count === undefined || (count === 0 && hideZero)) return null
  return (
    <View
      style={[
        styles.container,
        { backgroundColor: colors.badge.background },
        style, offsetStyle,
      ]}
      onLayout={e => {
        const offset = Math.round((e.nativeEvent.layout.width - 5) / 2)
        setOffset(offset)
      }}
    >
      <Text
        style={[styles.text, { color: colors.badge.text }, textStyle]}
        numberOfLines={1}
      >{count}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    minWidth: 17,
    paddingVertical: 0,
    paddingHorizontal: 3,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontFamily: font(),
    fontSize: 12,
    lineHeight: 15,
  }
})
