import React from 'react'
import { TouchableOpacity, View, ViewStyle } from 'react-native'
import icons from './icons'
import { useTheme } from '../../theme'

export type IconsType = keyof typeof icons

const HIT_SLOP_AREA = 10

interface IconProps {
	disabled?: boolean
	isVisible?: boolean
	size?: number
	color?: string
	name: IconsType
	style?: ViewStyle | ViewStyle[]
	backgroundColor?: string
	onPress?: () => void
}

export default function Icon({
	style,
	name,
	size = 24,
	color,
	onPress,
	backgroundColor,
	isVisible = true,
	disabled,
}: IconProps) {
	if (!isVisible) return null
	const { colors } = useTheme()

	const HIT_SLOP = { top: HIT_SLOP_AREA, bottom: HIT_SLOP_AREA, left: HIT_SLOP_AREA, right: HIT_SLOP_AREA }
	const onPressHandler = (!!onPress && !disabled) ? () => onPress() : undefined

	const drawIcon = () => (
		<View style={[style, { width: size, height: size }]}>
			{icons[name](size, disabled ? colors.icon.disabled : (color || colors.icon.default), backgroundColor)}
		</View>
	)

	if (icons[name]) {
		if (!!onPressHandler) {
			return (
				<TouchableOpacity
					activeOpacity={1}
					hitSlop={HIT_SLOP}
					onPress={onPressHandler}
				>
					{drawIcon()}
				</TouchableOpacity>
			)
		} else {
			return drawIcon()
		}
	}
	return null
}
