
import React, { useState, useEffect, useCallback, } from 'react'
import {
	TouchableOpacity,
	Text,
	ViewStyle,
	StyleSheet,
	TextStyle,
} from 'react-native';
import { Badge, Icon, IconsType } from '.';
import { font, useTheme } from '../theme';
import { ButtonsType } from '../theme';
import * as _ from 'lodash'

export type ButtonType = keyof ButtonsType

interface ButtonProps {
	text: string,
	onPress?: () => void
	style?: ViewStyle | ViewStyle[]
	textStyle?: TextStyle | TextStyle[]
	disabled?: boolean
	type?: ButtonType,
	fetching?: boolean
	badgeCount?: number
	leftIcon?: IconsType
	leftIconColor?: string
}

export default function Button({
	text,
	onPress,
	style,
	textStyle,
	disabled,
	type = 'primary',
	fetching,
	badgeCount,
	leftIcon,
	leftIconColor,
}: ButtonProps) {
	const { colors } = useTheme();
	const [pressed, setPressed] = useState(false);
	let isCancelled = false;

	useEffect(() => {
		return () => {
			isCancelled = true;
		};
	}, []);

	useEffect(() => {
		if (!fetching && !isCancelled) {
			callback();
		}
	}, [fetching])

	const callback = useCallback(_.debounce(() => {
		!isCancelled && setPressed(false);
	}, 1000), [])

	const buttonType = (disabled || pressed) ? 'disabled' : type

	return (
		<TouchableOpacity
			style={[{ backgroundColor: colors.button[buttonType].background }, styles.container, style]}
			onPress={!!onPress ? () => {
				if (fetching !== undefined) {
					setPressed(true);
				}
				onPress();
			} : null}
			disabled={disabled || pressed || fetching}
		>
			{!!leftIcon && (
				<Icon name={leftIcon} color={leftIconColor} style={styles.leftIcon} />
			)}
			<Text style={[{ color: colors.button[buttonType].text }, styles.text, textStyle]}>{text}</Text>
			{!!badgeCount && (<Badge count={badgeCount} style={styles.badge} />)}
		</TouchableOpacity >
	)
}

const styles = StyleSheet.create({
	container: {
		borderRadius: 10,
		padding: 10,
		justifyContent: 'center',
		flexDirection: 'row',
		alignItems: 'center',
	},
	text: {
		fontFamily: font('bold'),
		fontSize: 16,
		lineHeight: 19,
		textAlign: 'center',
	},
	fetching: {
		marginLeft: 10,
	},
	badge: {
		position: 'relative',
		marginLeft: 4,
		minWidth: 20,
		height: 14,
	},
	leftIcon: {
		marginRight: 10,
	},
})
