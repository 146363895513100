import React from 'react'
import { ScrollView, View, Text, ViewStyle, StyleSheet, Dimensions } from 'react-native'
import { connect } from 'react-redux'
import { StackScreenProps } from '@react-navigation/stack'
import moment from 'moment'
import 'moment/locale/ru'

import { Button, Markdown, Screen, Image } from '../components'
import { AppState } from '../store/redux'
import { notificationsReadRequestAction } from '../store/redux/notifications'
import { NotificationType } from '../types'
import { font, metrics, useTheme } from '../theme'
import { t } from '../localization'

moment().locale('ru')

type ScreenProps = DispatchProps & StateProps & StackScreenProps<any>

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

interface Props extends ScreenProps { }

interface State {
	screenWidth: number
}

class NotificationScreen extends React.Component<Props, State>{
	dimensionsSubscription: any
	state = { screenWidth: window.innerWidth }

	componentDidMount(): void {
		const {
			readNotification,
			route,
			notification,
			token,
			navigation,
		} = this.props

		if (!notification) {
			if (token) {
				navigation.replace('MainStack', { screen: 'NotificationsScreen' })
			} else {
				navigation.replace('AuthStack', { screen: 'AuthScreen' })
			}
		} else {
			const { notification_id } = route.params || {}
			const _notification_id = notification_id || notification?.id

			if (!!_notification_id) {
				readNotification(_notification_id)
			}

			if (!token) {
				navigation.replace('AuthStack', { screen: 'AuthScreen' })
			}
		}

		this.dimensionsSubscription = Dimensions.addEventListener("change", this.onChange)
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
		const { readNotification, notification, token, navigation } = this.props

		if (!prevProps.notification?.id && !!notification?.id) {
			readNotification(notification.id)
		}

		if (!!prevProps.token && !token) {
			navigation.replace('AuthStack', { screen: 'AuthScreen' })
		}
	}

	componentWillUnmount() {
		this.dimensionsSubscription?.remove()
	}

	onChange = ({ window }: any) => {
		this.setState({ screenWidth: window.width })
  }

	onBackPress = () => {
		this.props.navigation.goBack()
	}

	render() {
		const { notification, navigation, token } = this.props
		const { screenWidth } = this.state
		const isScreenLess635 = screenWidth < 635

		return (
			<Screen hideHeader={!token}>
				<ScrollView
					bounces={false}
					contentContainerStyle={[
						styles.contentContainer,
						{
							width: !isScreenLess635 ? '47.22%' : '100%',
							minWidth: !isScreenLess635 ? 635 : 'auto',
						}
					]}
				>
					<Notification notification={notification} />
					<Button
						style={styles.backButton}
						type='secondary'
						text={t('back_to_notifications')}
						onPress={this.onBackPress}
					/>
				</ScrollView>
			</Screen >
		)
	}

}

function Notification({ notification }: { notification: NotificationType }) {
	if (!notification) return null

	const { colors } = useTheme();

	const diff = moment.duration(moment().diff(notification.sended_at)).asHours()
	const date = diff < 24 ? moment(notification.sended_at).fromNow() : moment(notification.sended_at).format('DD MMMM YYYY, HH:mm')
	const uri = !!notification.image.thumbnail ? `${notification.image.thumbnail}?w=${metrics.screenWidth - 64}` : null

	return (
		<View style={[notificationStyles.container, { backgroundColor: colors.background.primary }]}>
			<Text style={[notificationStyles.title, { color: colors.text.default }]}>
				{notification.title}
			</Text>
			<Text style={[notificationStyles.date, { color: colors.text.date }]}>
				{date}
			</Text>
			<Markdown
				paragraphStyle={[notificationStyles.message, { color: colors.text.default }]}
				style={notificationStyles.markdown}
				text={notification.message}
			/>
			<View style={notificationStyles.imageContainer}>
				<Image uri={uri} width={metrics.screenWidth - 64} />
			</View>
		</View>
	)
}

const mapStateToProps = (state: AppState, ownProps: any) => {
	const { notification_id, created_at } = ownProps.route.params || {}
	const notification =
		state.notifications.notifications?.find(
			notification => notification.id === notification_id
		)
		|| state.notifications.notifications?.find(
			notification => moment(notification.sended_at).format('YYYY-MM-DD HH:mm:ss') === moment(created_at).format('YYYY-MM-DD HH:mm:ss')
		)

	return ({
		notification,
		token: state.auth.token,
	})
}

const mapDispatchToProps = {
	readNotification: notificationsReadRequestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationScreen)

const styles = {
	contentContainer: {
		flexGrow: 1,
			alignItems: 'stretch',
			marginTop: 26,
			paddingHorizontal: 20,
			paddingBottom: 17,
			alignSelf: 'center',
	} as ViewStyle,
	backButton: {
		marginTop: 24,
	},
}

const notificationStyles = StyleSheet.create({
	container: {
		borderRadius: 20,
		overflow: 'hidden',
		padding: 16,
	},
	title: {
		fontFamily: font('bold'),
		fontSize: 16,
		lineHeight: 19,
	},
	date: {
		marginTop: 8,
		fontFamily: font(),
		fontSize: 14,
		lineHeight: 18,
	},
	message: {
		marginTop: 8,
		fontFamily: font(),
		fontSize: 14,
		lineHeight: 18,
	},
	markdown: {
		marginTop: 8,
	},
	imageContainer: {
		marginTop: 16,
		borderRadius: 20,
		overflow: 'hidden',
	},
	image: {},
})
