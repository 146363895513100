import React, { useState, useEffect } from 'react'
import {
	View,
	ViewStyle,
	FlatList,
	Text,
	Dimensions,
} from 'react-native'
import { connect } from 'react-redux'
import { StackScreenProps } from '@react-navigation/stack'

import { AppState } from '../store/redux'
import {
	NotificationItem,
	Screen,
	ListEmptyComponent,
} from '../components/'
import { notificationsRequestAction } from '../store/redux/notifications'
import { MainNavigatorProps, NotificationType } from '../types'
import { t } from '../localization'
import { font, useTheme, ThemeColors } from '../theme'

type ScreenProps = DispatchProps & StateProps & StackScreenProps<MainNavigatorProps, 'NotificationsScreen'>

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

interface Props extends ScreenProps {
	colors: ThemeColors
	isSmallScreen: boolean
}

interface State {
	screenWidth: number
}

function NotificationsScreenWrapper(props: Props) {
	const { colors } = useTheme()
	const [screenData, setScreenData] = useState(Dimensions.get('window'))

	useEffect(() => {
		const onChange = (result) => {
			setScreenData(result.window)
		}

		const subscription = Dimensions.addEventListener('change', onChange)

		return () => subscription?.remove()
	})

	return <NotificationsScreen colors={colors} isSmallScreen={screenData.width < 635} {...props} />
}

class NotificationsScreen extends React.Component<Props, State>{
	componentDidMount() {
		this.onRefresh()
	}

	render() {
		const {
			notifications,
			fetching,
			isSmallScreen,
			colors,
		} = this.props

		return (
			<Screen>
				<FlatList
					data={notifications}
					renderItem={({ item }) => (
						<NotificationItem notification={item} onPress={this.onNotificationPress} />
					)}
					ListHeaderComponent={<Text style={[styles.content.title, { color: colors.text.title }]}>{t('notifications')}</Text>}
					keyExtractor={(item, index) => index.toString()}
					contentContainerStyle={[
						styles.content.container,
						{
							width: !isSmallScreen ? '47.22%' : '100%',
							minWidth: !isSmallScreen ? 635 : 'auto',
						},
					]}
					ItemSeparatorComponent={() => <View style={styles.separator} />}
					onRefresh={this.onRefresh}
					onEndReachedThreshold={0.5}
					onEndReached={() => this.getPagination()}
					refreshing={fetching}
					ListEmptyComponent={() => (
						<View style={{
							flex: 1,
							backgroundColor: !notifications.length ? colors.background.primary : 'inherit',
							borderRadius: !notifications.length ? 20 : 0,
						}}>
							<ListEmptyComponent type={'notifications'} offset={0} />
						</View>
					)}
				/>
			</Screen>
		)
	}

	onNotificationPress = (notification: NotificationType) => {
		const { navigation } = this.props;
		navigation.navigate('NotificationScreen', { notification_id: notification.id })
	}

	onRefresh = () => {
		this.getPagination(1);
	}

	getPagination = (_page?: number) => {
		const {
			page = 0,
			limit,
			fetching,
			total,
			getNotifications,
		} = this.props;
		if (!fetching && (!!_page || page * limit < total)) {
			getNotifications({ page: _page || page + 1 })
		}
	}
}

const mapStateToProps = (state: AppState) => ({
	notifications: state.notifications.notifications || [],
	page: state.notifications.page,
	limit: state.notifications.limit,
	total: state.notifications.total,
	fetching: state.notifications.fetching,
})

const mapDispatchToProps = {
	getNotifications: notificationsRequestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsScreenWrapper)

const styles = {
	content: {
		container: {
			flexGrow: 1,
			alignItems: 'stretch',
			alignSelf: 'center',
			marginVertical: 40,
			paddingHorizontal: 20,
		} as ViewStyle,
		title: {
			marginBottom: 24,
			fontFamily: font(),
			fontSize: 24,
			lineHeight: 29,
		},
	},
	separator: {
		height: 10,
	} as ViewStyle,
}
