import React, { useState } from 'react'
import { ChatMessageType, Nullable, UploadedFileType } from '../../types'
import { MessageProps } from 'react-native-gifted-chat'
import moment from 'moment'
import 'moment/locale/ru'
moment().locale('ru')
import {
  Image,
  ImageStyle,
  Linking,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
  Platform,
} from 'react-native'
import { font, useTheme } from '../../theme'
import { Icon, ScaleImageModal } from '..'

let MESSAGE_IMAGE_WIDTH = 0
const ASPECT_RATIO = 0.64

interface _MessageType {
  key: string
  isMyMessage: boolean
  datetime: string
  admin?: string
  text?: string
  file?: UploadedFileType
  messageType: 'my' | 'default'
}

interface _ImageType {
  key: string
  datetime: string
  file: UploadedFileType
  messageType: 'my' | 'default'
}

interface Props {
  client_id: number
  message: MessageProps<ChatMessageType>
}

export default function ({
  client_id,
  message,
}: Props) {

  const { colors } = useTheme()
  const [fullscreenImage, setFullscreenImage] = useState<Nullable<string>>(null)

  const isMyMessage = client_id === message.currentMessage?.user._id
  const messageType = isMyMessage ? 'my' : 'default'
  const files: UploadedFileType[] = message.currentMessage?.files || []
  const text = message.currentMessage?.text
  const key = message.currentMessage?._id?.toString() || Date.now().toString()
  const datetime = moment(message.currentMessage?.datetime).format('HH:mm')
  const currentMsgDate = moment(message.currentMessage?.datetime).format('YYYY-MM-DD')
  const prevMsgDate = moment(message.previousMessage?.datetime).format('YYYY-MM-DD')
  const hasPrevMsg = !!Object.keys(message.previousMessage || {}).length
  const isDayEqual = !!hasPrevMsg && currentMsgDate == prevMsgDate
  const dateBaloon = !isDayEqual ? moment(message.currentMessage?.datetime).format('DD MMMM') : null

  const admin = message.currentMessage?.user.name

  const _renderMessage = ({ key, isMyMessage, messageType, datetime, admin, text, file }: _MessageType) => {
    const backgroundColor = isMyMessage
      ? colors.chat.messages.my.background
      : Platform.OS !== 'web'
        ? colors.background.primary
        : colors.background.default
    const borderColor = Platform.OS !== 'web'
      ? isMyMessage ? colors.background.secondary : colors.border.secondary
      : 'transparent'
    return (
      <View
        key={`message-${key}`}
        style={[styles.content[messageType], {
          backgroundColor,
          borderColor
        }]}
      >
        {!isMyMessage && (
          <Text style={[styles.operator, { color: colors.chat.messages.admin.text }]}>
            {admin}
          </Text>
        )}
        {!!file ? (
          <TouchableOpacity
            style={styles.file.container[messageType]}
            onPress={() => Linking.openURL(file.url)}
          >
            <View style={[styles.file.image.container, { backgroundColor: colors.background.default }]}>
              <Icon name={'file_line'} size={24} color={colors.icon.default} />
            </View>
            <View style={styles.file.image.description.container}>
              <Text style={[
                styles.file.image.description.fileNmae[messageType],
                { color: isMyMessage ? colors.chat.messages.my.text : colors.chat.messages.admin.text }
              ]} numberOfLines={1}>
                {file.original}
              </Text>
              <Text style={[
                styles.file.image.description.weight[messageType],
                { color: isMyMessage ? colors.chat.messages.my.text : colors.chat.messages.admin.text }
              ]}>
                {file.size} {file.size_unit}
              </Text>
            </View>
          </TouchableOpacity>
        ) : (
          <Text style={[
            styles.text[messageType],
            {
              color: isMyMessage
                ? colors.chat.messages.my.text
                : colors.chat.messages.admin.text
            }
          ]}>
            {text}
          </Text>
        )}
        <Text style={[
          styles.datetime[messageType],
          {
            color: isMyMessage
              ? colors.chat.messages.my.text
              : colors.chat.messages.admin.text
          }
        ]}>{datetime}</Text>
      </View>
    )
  }

  const _renderImage = ({
    key,
    messageType,
    datetime,
    file,
  }: _ImageType) => {
    const uri = file.thumbnail_url + `?w=${MESSAGE_IMAGE_WIDTH}&h=${Math.round(MESSAGE_IMAGE_WIDTH * ASPECT_RATIO)}&fit=crop-center`
    return (
      <TouchableOpacity
        style={[styles.content[messageType], styles.image.container]}
        key={`image-${key}`}
        onPress={() => setFullscreenImage(file.url)}
      >
        <Image
          source={{ uri }}
          style={styles.image.image}
          resizeMethod={'resize'}
          resizeMode={'cover'}
        />
        <Text style={[styles.datetime['image'], { color: colors.closeIcon.icon }]}>
          {datetime}
        </Text>
      </TouchableOpacity>
    )
  }

  return (
    <>
      <View
        style={styles.container[messageType]}
        onLayout={e => MESSAGE_IMAGE_WIDTH = Math.floor(e.nativeEvent.layout.width)}
      >
        {files?.map((file, index) => {
          const extension = file.original.substring(file.original.lastIndexOf('.') + 1)
          const isImage = ['jpg', 'jpeg', 'png', 'bmp'].includes(extension)
          if (isImage) return _renderImage({ key: `${key}-${index}`, messageType, datetime, file })
          else return _renderMessage({ key: `${key}-${index}`, isMyMessage, messageType, admin, file, datetime })
        })}
        {!!text && _renderMessage({ key, isMyMessage, messageType, admin, text, datetime })}
      </View>
      {!!dateBaloon && (
        <View style={styles.dateBaloon.container}>
          <Text style={[styles.dateBaloon.text, { color: colors.text.title }]}>{dateBaloon}</Text>
        </View>
      )}
      <ScaleImageModal
        visible={!!fullscreenImage}
        image={fullscreenImage || ''}
        onCancel={() => setFullscreenImage(null)}
      />
    </>
  )
}

const commonStyles = {
  container: {
    marginHorizontal: 16,
  } as ViewStyle,
  content: {
    paddingTop: 6,
    marginBottom: 12,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    overflow: 'hidden',
  } as ViewStyle,
  text: {
    marginTop: 6,
    marginHorizontal: 12,
    fontFamily: font(),
    fontSize: 16,
    lineHeight: 22,
  } as TextStyle,
  datetime: {
    fontFamily: font(),
    fontSize: 14,
    lineHeight: 17,
    marginTop: 4,
    marginBottom: 8,
    marginHorizontal: 12,
    opacity: 0.5,
    textAlign: 'right',
  } as TextStyle,
  description: {
    fontFamily: font(),
    fontSize: 14,
    lineHeight: 17,
  } as TextStyle,
  file: {
    marginHorizontal: 12,
    flexDirection: 'row',
  } as ViewStyle,
}

const styles = {
  container: {
    my: {
      ...commonStyles.container,
      marginLeft: '20%',
      alignItems: 'flex-end',
    } as ViewStyle,
    default: {
      ...commonStyles.container,
      marginRight: '20%',
      alignItems: 'flex-start',
    } as ViewStyle,
  },
  content: {
    my: {
      ...commonStyles.content,
      borderTopLeftRadius: 20,
    } as ViewStyle,
    default: {
      ...commonStyles.content,
      borderTopRightRadius: 20,
      borderWidth: 1,
    } as ViewStyle,
  },
  dateBaloon: {
    container: {
      alignItems: 'center',
      marginVertical: 12,
    } as ViewStyle,
    text: {
      fontFamily: font(),
      fontSize: 16,
      lineHeight: 19,
    } as TextStyle,
  },
  file: {
    container: {
      my: {
        paddingTop: 8,
        ...commonStyles.file,
      } as ViewStyle,
      default: {
        ...commonStyles.file,
      } as ViewStyle,
    },
    image: {
      container: {
        width: 40,
        height: 40,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
      } as ViewStyle,
      description: {
        container: {
          marginLeft: 11,
          flexShrink: 1,
        } as ViewStyle,
        fileNmae: {
          my: {
            ...commonStyles.description,
          } as TextStyle,
          default: {
            ...commonStyles.description,
          } as TextStyle,
        },
        weight: {
          my: {
            ...commonStyles.description,
            marginTop: 4,
            opacity: 0.5,
          } as TextStyle,
          default: {
            ...commonStyles.description,
            marginTop: 4,
            opacity: 0.5,
          } as TextStyle,
        },
      },
    },
  },
  operator: {
    fontFamily: font('bold'),
    fontSize: 16,
    lineHeight: 20,
    marginBottom: 6,
    marginHorizontal: 12,
  } as TextStyle,
  text: {
    my: {
      ...commonStyles.text,
    } as TextStyle,
    default: {
      ...commonStyles.text,
    } as TextStyle,
  },
  datetime: {
    my: {
      ...commonStyles.datetime,
    } as TextStyle,
    default: {
      ...commonStyles.datetime,
    } as TextStyle,
    image: {
      ...commonStyles.datetime,
      opacity: 1,
      position: 'absolute',
      bottom: -4,
      right: -6,
      paddingHorizontal: 6,
      paddingVertical: 4,
      backgroundColor: 'rgba(45, 55, 65, 0.8)',
      borderRadius: 38,
    } as TextStyle,
  },
  image: {
    container: {
      width: '100%',
      height: 200,
      paddingTop: 0,
    } as ViewStyle,
    image: {
      width: '100%',
      height: '100%',
    } as ImageStyle,
  },
}
