import { put, call } from 'redux-saga/effects'
import { ApiResponse } from 'apisauce';
import { api, ApiSuccessResponse } from '../../api'
import {
	Action,
	ServiceReviewsRequest,
	ServiceReviewsSuccessResponse,
	CreateReviewRequest,
	CreateReviewSuccessResponse,
} from '../../types';
import {
	serviceReviewsSuccessAction,
	serviceReviewsFailureAction,
	createReviewSuccessAction,
	createReviewFailureAction,
} from '../redux/reviews';

export function* serviceReviewsRequestSaga(action: Action<ServiceReviewsRequest>) {
	const response: ApiResponse<ApiSuccessResponse<ServiceReviewsSuccessResponse>> = yield call(api.serviceReviewsRequest, action.params)
	if (response.ok) {
		yield put(serviceReviewsSuccessAction({ serviceId: action.params.serviceId, result: response.data.result }))
	} else {
		yield put(serviceReviewsFailureAction(response.data))
	}
}

export function* createReviewRequestSaga(action: Action<CreateReviewRequest>) {
	const response: ApiResponse<ApiSuccessResponse<CreateReviewSuccessResponse>> = yield call(api.createReviewRequest, action.params)
	if (response.ok) {
		yield put(createReviewSuccessAction(response.data.result))
	} else {
		yield put(createReviewFailureAction(response.data))
	}
}
