export default {
  AIDERA: 'AIDERA COMPANY',
  auth_web: 'Вход в личный кабинет',
  auth_text: 'Пожалуйста, зарегистрируйтесь для получения информации и записи на прием',
  next: 'Далее',
  email: 'E-mail',
  back: 'Назад',
  auth_agreement: 'Нажимая на кнопку "%s", я принимаю условия [Пользовательского соглашения](terms_of_use) и даю согласие на обработку моей персональной информации на условиях, определённых [Политикой конфиденциальности](privacy_policy).',
  page_not_found: 'Страница не найдена',
  page_not_found_description: 'Страница устарела, была удалена или не существовала вовсе',
  to_main: 'На главную',
  logo: 'ваш мобильный кабинет психолога',
  email_code: 'Код из письма',
  code_sended_to: 'Отправили на %s',
  resend_sms_code: 'Получить код повторно через %s сек',
  send_code: 'Выслать код повторно',
  familiarity: 'Давайте знакомиться',
  profile: 'Профиль',
  fill_profile: 'Пожалуйста, заполните анкету',
  logout: 'Выйти из аккаунта',
  profile_delete: 'Удалить аккаунт',
  confim_profile_delete: 'Вы действительно хотите удалить вашу учетную запись?',
  yes: 'Да',
  no: 'Нет',
  profile_success: 'Изменения сохранены',
  firstname: 'Имя',
  lastname: 'Фамилия',
  middlename: 'Отчество',
  birthdate: 'Дата рождения',
  gender: 'Пол',
  male: 'Мужской',
  female: 'Женский',
  company_name: 'Название компании',
  site: 'Сайт (необязательно)',
  save: 'Сохранить',
  select: 'Выбрать',
  cancel: 'Отменить',
  fill_req_fields: 'Необходимо заполнить поля',
  error: 'Ошибка',
  min_age: 'Минимальный возраст %s лет',

  trackers: 'Трекеры',
  tracker: 'Трекер',
  new_tracker: 'Новый трекер',
  create_tracker: 'Создать трекер',
  empty_trackers: 'Начните отслеживать состояние сотрудников',
  edit: 'Редактировать',
  delete: 'Удалить',
  delete_placeholder: 'DELETE',
  tracker_deleting: 'Удаление трекера',
  tracker_deleting_description_start: 'Внимание, вы собираетесь удалить трекер и всю накопленную в нём историю, удалённые данные не подлежат восстановлению, введите слово ',
  tracker_deleting_description_end: ', чтобы продолжить',
  delete_field_error: 'Неверно введено слово',
  tracker_creating: 'Создание нового трекера',
  tracker_title_placeholder: 'Введите название',
  tracker_description_placeholder: 'Введите описание',
  degradation_mode: 'Режим деградации',
  degradation_mode_description: 'Если данная опция включена, то ваш прогресс будет незначительно убавляться каждый день, словно в реальной жизни вы начинаете что-то забывать или упускать.',
  tracker_color: 'Цвет трекера',
  tracker_name: 'Трекер: %s',
  no_tracker: 'Трекер: Нет',
  statistic: 'Статистика',
  create_tracker_success: 'Трекер успешно создан',
  edit_tracker_success: 'Трекер успешно изменен',
  delete_tracker_success: 'Трекер успешно удален',
  tracker_editing: 'Редактор трекера',
  save_changes: 'Сохранить изменения',
  events: 'События',
  select_interval_warning: 'Выберите интервал не больше 2 недель',
  employees: 'Сотрудники',
  new_employee: 'Новый сотрудник',
  sort_by: 'Сортировать по',
  fio: 'ФИО',
  byCurrentRating: 'Текущему рейтингу',
  byRating7DaysAgo: 'Рейтингу 7 дней назад',
  employee_creating: 'Новый сотрудник',
  employee_email_placeholder: 'E-mail',
  warning: 'Внимание',
  warning_description: 'Ваш сотрудник обязательно должен установить приложение Aidera для правильной работы системы',
  add_employee: 'Добавить сотрудника',
  add_employee_success: 'Сотрудник успешно добавлен',
  connect_tracker: 'Присоединить трекер',
  employee_delete: 'Удалить сотрудника',
  employee_deleting: 'Удаление сотрудника',
  employee_deleting_description_start: 'Внимание, вы собираетесь удалить сотрудника и всю накопленную историю, удалённые данные не подлежат восстановлению, введите слово ',
  employee_deleting_description_end: ', чтобы продолжить',
  delete_employee_success: 'Сотрудник успешно удален',
  select_tracker: 'Выберите трекер',
  delete_tracker: 'Удалить трекер',
  reconnect_tracker: 'Присоеденить повторно',
  reconnect_another_tracker: 'Переназначить другой трекер',
  conntect_tracker_to_employee_success: 'Трекер успешно назначен сотруднику',
  reconntect_employee_tracker_success: 'Трекер успешно переназначен сотруднику',
  unlink_employee_tracker_success: 'Трекер успешно отсоединен от сотрудника',
  active_tracker: 'Трекер активен',
  inactive_tracker: 'Трекер отсоединен',
  last_event: 'Последнее событие',
}
