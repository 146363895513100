export default {
  AIDERA: 'AIDERA',
  auth: 'Войти в приложение',
  auth_email: 'Войти по email',
  auth_phone: 'Войти по номеру телефона',
  auth_web: 'Вход в личный кабинет',
  auth_text: 'Пожалуйста, зарегистрируйтесь для получения информации и записи на прием',
  phone_number: 'Номер телефона',
  confim_profile_delete: 'Вы действительно хотите удалить вашу учетную запись?',
  profile_delete: 'Удалить аккаунт',
  next: 'Далее',
  prev: 'Назад',
  auth_agreement: 'Нажимая на кнопку "%s", я принимаю условия [Пользовательского соглашения](terms_of_use) и даю согласие на обработку моей персональной информации на условиях, определённых [Политикой конфиденциальности](privacy_policy).',
  sms_code: 'Код из смс',
  code: 'Код',
  email_code: 'Код из письма',
  code_sended_to: 'Отправили на %s',
  phone_send: 'Вам поступит звонок с уникального номера. Введите код, который вам скажут при ответе на звонок',
  resend_sms_code: 'Получить код повторно через %s сек',
  recall_code: 'Получить звонок повторно через %s сек',
  send_code: 'Выслать код повторно',
  send_call: 'Получить звонок повторно',
  familiarity: 'Давайте знакомиться',
  fill_profile: 'Пожалуйста, заполните анкету',
  firstname: 'Имя',
  lastname: 'Фамилия',
  middlename: 'Отчество',
  birthdate: 'Дата рождения',
  gender: 'Пол',
  male: 'Мужской',
  female: 'Женский',
  male2: 'Мужчина',
  female2: 'Женщина',
  save: 'Сохранить',
  select: 'Выбрать',
  cancel: 'Отменить',
  fill_req_fields: 'Необходимо заполнить поля',
  accept: 'Принять',
  error: 'Ошибка',
  min_age: 'Минимальный возраст %s лет',
  email: 'E-mail',
  logout: 'Выйти из аккаунта',
  notifications: 'Уведомления',
  notification: 'Уведомление',
  main: 'Главная',
  profile: 'Профиль',
  appointment: 'Журнал записей',
  faq: 'FAQ',
  instruction: 'Инструкция',
  about_app: 'О приложении',
  about_app_web: 'О сервисе',
  recive_code: 'Получить код',
  themes_dark: 'Темная',
  themes_device: 'Тема устройства',
  themes_light: 'Светлая',
  email_invalid: 'Неверный формат E-mail',
  phone_invalid: 'Неверный формат номера телефона',
  greetings: 'Здравствуйте',
  services: 'Консультации',
  nearest_service: 'Ближайшая консультация',
  psychologist: 'Психолог',
  platform_psychologist: 'Психолог платформы',
  experience: 'Опыт: %s',
  profile_success: 'Изменения сохранены',
  profile_about: 'Обо мне',
  profile_info: 'Данные профиля',
  back: 'Назад',
  feedback: 'Техподдержка',
  registry_feature: 'Будущие',
  registry_past: 'Прошедшие',
  add_appointment: 'Добавить запись',
  repeat_appointment: 'Повторить запись',
  appointment_cancel: 'Отменить запись',
  appointment_canceled: 'Запись отменена',
  appointment_cancel_confirm: 'Вы действительно хотите отменить запись? Ожидайте возврат суммы в течении 3 дней',
  appointment_cancel_reject: 'Консультацию нельзя отменить менее чем за\u00a024 часа',
  yes: 'Да',
  no: 'Нет',
  new_appointment: 'Записаться на консультацию',
  client: 'Клиент',
  service_appointment: 'Запись на консультацию',
  client_data: 'Данные клиента',
  client_description: 'Если вы хотите записать другого человека, то укажите его данные',
  client_age_description: `В случае записи человека возрастом **меньше %s лет**, необходимо присутствие совершеннолетнего законного представителя (опекун или родитель).`,
  service_subject: 'Что хотите обсудить?',
  service_subject_description: `Напишите, что бы вам хотелось обсудить на консультации`,
  choose_psychologist: 'Выберите психолога',
  choose_psychologist_gender: 'Выберите с кем вам комфортнее общаться, и мы подберем специалиста',
  choose_datetime: 'Выберите дату и время',
  date: 'Дата',
  time: 'Время',
  choose_time: 'Выберите время',
  selected_dates: 'Выбранные даты',
  appointment_confirm: 'Подтвердите запись',
  pay_agreement: 'Я согласен с условиями [публичной оферты]()',
  pay: 'Оплатить',
  service: 'Консультация',
  services_count: 'Консультаций',
  cost: 'Стоимость',
  currency: '%s ₽',
  download_file: 'Загрузить файл',
  attachment_photo: 'Сделать фотографию',
  attachment_gallery: 'Выбрать из галереи',
  attachment_document: 'Выбрать документ',
  unsupported_type: 'Формат файла не поддерживается',
  your_message: 'Ваше сообщение',
  duration: '%sмин',
  empty_notifications: 'У вас пока нет\nуведомлений',
  empty_notifications_web: 'У вас пока нет уведомлений',
  empty_appointment: 'У вас пока\nнет записей',
  empty_appointment_web: 'У вас пока нет записей',
  confirm: 'Понятно',
  skip: 'Пропустить',
  rate_app: 'Оценить приложение',
  to_appointments: 'В журнал записи',
  appointment_succes: 'Запись прошла успешно',
  appointment_succes_web: 'Запись прошла успешно!',
  appointment_succes_notice: 'За **5 минут** до начала консультации зайдите в **мобильное приложение** и ожидайте звонок от психолога.',
  appointment_succes_notice_web: 'За **5 минут** до начала консультации зайдите в web-приложение\n(либо в мобильное приложение Aidera) и ожидайте звонок от психолога.',
  appointment_failure: 'Ошибка оплаты',
  appointment_failure_notice: 'При выполнении операции произошла ошибка',
  appointment_slot_error: 'К сожалению, максимальное количество возможных записей в день: %s',
  hint_for_change_number: 'Для изменения номера обратитесь в техническую поддержку (раздел "Вопросы и ответы")',
  incoming_call: 'Входящий вызов',
  conference_done: 'Сеанс завершен!',
  conference_done_text: 'Спасибо, что выбрали Aidera.\nДля возврата на главную страницу нажмите кнопку “Вернуться”.',
  return: 'Вернуться',
  socket_logout_message: 'Ошибка авторизации. Осуществлен вход в приложение на другом устройстве.',
  download_check: 'Скачать чек',
  check_download_success: 'Чек %s сохранен',
  write_permission_denied: 'Ошибка при сохранении на устройство.\nОтсутствует разрешение на запись.',
  camera_permission_denied: 'Отсутствует разрешение на использование камеры.',
  app_new_version_available: 'Доступна новая версия приложения',
  update: 'Обновить',
  later: 'Позже',
  support_service: 'Служба поддержки',
  doctor_chat: 'Чат с психологом',
  goto_caht: 'Напишите в чат',
  doctor_confirm: 'Подтвердите психолога',
  promocode: 'Промокод',
  enter_promocode: 'Введите промокод',
  apply: 'Применить',
  promocode_success: 'Промокод применен',
  promocode_failure: 'Ошибка',
  promocode_applied: 'Применен промокод: %s',
  discount: 'Скидка: %s',
  appointment_notice: 'Внимание! Для осуществления консультации по видеосвязи необходимо иметь стабильное интернет соединение или использовать подключение через wifi',
  page_not_found: 'Страница не найдена',
  page_not_found_description: 'Страница устарела, была удалена или не существовала вовсе',
  to_main: 'На главную',
  logo: 'ваш мобильный кабинет психолога',
  auth_apps_text: 'Также вы можете воспользоваться нашим мобильным приложением',
  not_found: 'Страница не найдена',
  payment_info: 'Уважаемые пользователи, обработка платежа может занять до 5 минут. Записи на сеансы могут отобразиться в личном кабинете с небольшой задержкой',
  back_to_notifications: 'Назад к списку уведомлений',
  timezone: 'Время указано в вашем часовом поясе: %s',

  leave_review: 'Оставить отзыв',
  send_review: 'Отправить отзыв',
  review_description: 'Пожалуйста оставьте свой отзыв. Ваше мнение важно для нас',
  consultation_name: 'Название консультации',
  rating: 'Оценка',
  review: 'Отзыв',
  send_anonimously: 'Отправить анонимно',
  thanks_for_rating: 'Спасибо за оценку!',
  thanks_note: 'Ваш отзыв отправлен на проверку',
  reviews_count: ['отзыв', 'отзыва', 'отзывов'],
  description: 'Описание',
  reviews: 'Отзывы',
  anonymous: 'Аноним',
  empty_reviews: 'Отзывов пока нет',
  reviews_more: 'Загрузить еще',

  microphone_muted: 'Вкл. звук',
  microphone_active: 'Выкл. звук',
  camera_off: 'Вкл. видео',
  camera_on: 'Выкл. видео',
  complete: 'Завершить',
  turn: 'Повернуть',

  pack: '%s за %s ₽',
  pack_agreement: 'Купить %s за %s ₽, [выгода %s%]()',
  pack_expiration_date: 'Дата окончания',
  package_offer: 'Пакетное предложение',
  pack_total: 'Всего консультаций - %s',
  pack_appointment_number: '%s/%s консультаций',
  pack_expiration_date_expanded: 'Дата окончания пакета - %s',
  pack_sessions_count: 'Количество сессий',
  pack_sessions: '%s из %s',
  pack_appointment_cancelled: 'Запись отменена',
  pack_expired: 'Срок действия пакета истек',
  pack_finished: 'Ваше пакетное предложение окончено, вы можете записаться повторно',
  pack_confirm: 'Подтвердить',
  pack_info: 'Для того, чтобы добавить запись пакетного предложения, перейдите в раздел Журнал записей. В данном разделе выберите необходимое пакетное предложение и добавьте дополнительную запись.',

  tests: 'Тесты',
  first_testing: 'Первое прохождение',
  retesting: 'Повторное прохождение',
  test_title: 'Результат',
  test_instruction: 'Инструкция',
  test_author: 'Автор',
  test_questions_count: 'Количество вопросов',
  test_start: 'Начать',
  test_repeat: 'Пройти заново',
  test_repeat_confirm: 'Вы уверены, что хотите заново пройти тест? Текущие результаты будут сброшены',
  additional_tests: 'Необязательные тесты',
  test_finished: 'Пройдено',
  test_close: 'Завершение теста',
  test_close_description: 'Вы уверены, что хотите завершить свой прогресс. Ведь осталось совсем чуть-чуть. При закрытии теста результат будет сброшен.',
  question: 'Вопрос',
  test_congratulations: 'Поздравляем!\nВы прошли тест: "%s". Результаты будут сохранены в вашем профиле.',
  tests_soon: 'Скоро здесь появятся тесты',

  russia: 'Россия',
  belarus: 'Беларусь',
  kazakhstan: 'Казахстан',
  uzbekistan: 'Узбекистан',

  articles: 'Статьи',
  more: 'читать далее',
  share_article_title: 'Статья психолога',
  share_article_message: 'Ссылка на статью:',
  share_article_clipboard_message: 'Ссылка на статью скопирована в буфер обмена',
  articles_empty: 'Статей нет',
  article_set_action: 'Для выполнения данного действия вам необходимо авторизоваться',
  questions: 'Вопросы к психологу',
  my_questions: 'Мои вопросы',
  all_questions: 'Все вопросы',
  questions_empty: 'Вопросов нет',
  ask_question: 'Задать вопрос',
  ask_question_description: 'Напишите, что бы вам хотелось узнать. [Важно.](important_show)',
  ask_question_description_full: 'Напишите, что бы вам хотелось узнать. [Важно.](important_hide) “Aidera” - это не скорая помощь. Если у вас серьезные, угрожающие вашей жизни проблемы, которые требуют немедленного решения, вам лучше обратиться в какую-либо из этих [организаций.](https://aidera.help/kontakty-krizisnyh-czentrov/)',
  send_question: 'Отправить',
  thanks_ask: 'Вопрос отправлен',
  ask_limit_notification: 'Уведомление',
  ask_limit_description: 'Вы можете задать %s %s в месяц',
  questions_count: ['вопрос', 'вопроса', 'вопросов'],
  asks_count: ['ответ', 'ответа', 'ответов'],
  ask_limit_ok: 'Понятно',
  show_asks: 'Посмотреть ответы',
  new_text: 'new',

  trackers: 'Трекеры',
  tracker: 'Трекер',
  new_tracker: 'Новый трекер',
  create_tracker: 'Создать трекер',
  empty_trackers: 'Начните отслеживать свое состояние',
  edit: 'Редактировать',
  delete: 'Удалить',
  delete_placeholder: 'DELETE',
  tracker_deleting: 'Удаление трекера',
  tracker_deleting_description_start: 'Внимание, вы собираетесь удалить трекер и всю накопленную в нём историю, удалённые данные не подлежат восстановлению, введите слово ',
  tracker_b2b_deleting_description_start: 'Внимание, вы собираетесь отключиться от бизнес-трекера. Данные трекера останутся у вашей кампании. Трекер не будет отображаться на этом экране. Введите слово ',
  tracker_deleting_description_end: ', чтобы продолжить',
  delete_field_error: 'Неверно введено слово',
  tracker_creating: 'Создание нового трекера',
  tracker_title_placeholder: 'Введите название',
  tracker_label_placeholder: 'Введите заголовок',
  tracker_description_placeholder: 'Введите описание',
  degradation_mode: 'Режим деградации',
  degradation_mode_description: 'Если данная опция включена, то ваш прогресс будет незначительно убавляться каждый день, словно в реальной жизни вы начинаете что-то забывать или упускать.',
  tracker_rate_notification: 'Напоминание об оценке трекинга',
  notification_count: '%s уведомление',
  hours: 'Часы',
  minutes: 'Минуты',
  tracker_color: 'Цвет трекера',
  important: 'Важно',
  rate_description: 'Оцените текущее событие относительно предыдущего',
  everything_will_be_fine: 'Всё будет хорошо!',
  statistic: 'Статистика',
  create_tracker_success: 'Трекер успешно создан',
  edit_tracker_success: 'Трекер успешно изменен',
  delete_tracker_success: 'Трекер успешно удален',
  tracker_editing: 'Редактор трекера',
  save_changes: 'Сохранить изменения',
  warning: 'Внимание!',
  today_rate_warning: 'Вы уже давали оценку сегодняшнему дню',
  return_for_new_rate: 'Возвращайтесь завтра, чтобы дать новую оценку.',
  events: 'События',
  its_time_to_rate: 'Пора дать оценку для: %s %s',
  select_interval_warning: 'Выберите интервал не больше 2 недель',
  last_event: 'Последнее событие',

  company_name: 'Название компании',
}
