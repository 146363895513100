import React, { useState, useRef } from 'react'
import { View, Image, StyleSheet, ViewStyle } from 'react-native'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperEvents from 'swiper'
import { debounce } from 'lodash'
import { font, useTheme } from '../theme'
import { GuideType } from '../types'
import { t } from '../localization'
import { Button, Markdown } from '.'
import Header from './screen/Header'

import 'swiper/css'

interface GuideCarouselProps {
  readonly data: GuideType[]
  readonly onConfirmPress: () => void
  readonly style?: ViewStyle | ViewStyle[]
  readonly scrollEnabled?: boolean
  readonly isInstruction?: boolean
}

export default function (props: GuideCarouselProps) {
  const { colors } = useTheme()
  const swiperRef = useRef(null)

  const {
    style,
    scrollEnabled = false,
    data,
    onConfirmPress,
    isInstruction = false,
  } = props

  let [activeIndex, setActiveIndex] = useState(0)
  let [buttonText, setButtonText] = useState(t('next'))
  let [imageHeight, setImageHeight] = useState(0)
  let [carouselWidth, setCarouselWidth] = useState(0)

  const length = data?.length || 0
  // const title = (data || [])[activeIndex]?.title
  const description = (data || [])[activeIndex]?.description

  const renderProgress = (progress: number) => {
    return (
      <View style={[styles.progressContainer, { backgroundColor: colors.background.secondary }]}>
        <View style={[styles.progress, {
          backgroundColor: colors.background.progress,
          width: `${progress * 100}%`,
        }]} />
      </View>
    )
  }

  const onPressButton = () => {
    const _length: number = data?.length || 0

    if (activeIndex < _length - 1) {
      swiperRef.current.swiper.slideNext()
    } else {
      onConfirmPress()
    }
  }
  const onPressButtonDebounce = debounce(onPressButton, 300)

  const onSnapToItem = (swiper: SwiperEvents) => {
    const _length: number = data?.length || 0
    setActiveIndex(swiper.activeIndex)
    setButtonText(swiper.activeIndex < _length - 1 ? t('next') : t('confirm'))
  }

  const onSlidePrev = () => {
    swiperRef.current.swiper.slidePrev()
  }
  const onSlidePrevDebounce = debounce(onSlidePrev, 300)

  return (
    <View style={[styles.container, style]}>
      <Header
        style={{ padding: 15 }}
        titleStyle={{ fontFamily: font('bold'), fontSize: 24 }}
        title={!isInstruction ? t('AIDERA') : t('instruction')}
        onGoBackPress={onSlidePrevDebounce}
        onSkipPress={!isInstruction ? () => onConfirmPress : null}
        hideGoBack={activeIndex === 0 || isInstruction}
      />
      { renderProgress((activeIndex + 1) / length) }
      <View style={{ marginBottom: 20, height: 40, paddingHorizontal: 60 }}>
        {/* {!!title && (
          <Text style={[styles.contentTitle, { color: colors.text.title }]}>
            {title}
          </Text>
        )} */}
        {!!description && (
          <Markdown
            style={{ textAlign: 'center', alignItems: 'center' }}
            text={description}
            paragraphStyle={{
              fontFamily: font(),
              fontSize: 16,
              lineHeight: 20,
              textAlign: 'center',
              color: colors.text.default,
            }}
          />
        )}
      </View>
      <View
        style={{ flex: 1 }}
        onLayout={e => {
          const height = e.nativeEvent.layout.height
          if (activeIndex === 0 && imageHeight !== height) {
            setImageHeight(height)
            setCarouselWidth(e.nativeEvent.layout.width)
          }
        }}
      >
        { !!carouselWidth && (
          <Swiper
            style={{ width: '100%' }}
            ref={swiperRef}
            onSlideChange={onSnapToItem}
            allowTouchMove={scrollEnabled}
            autoHeight={true}
          >
            { data.map((item, i) => (
              <SwiperSlide key={item.id} style={{ textAlign: 'center' }} virtualIndex={i}>
                <Image
                  style={{ height: imageHeight }}
                  source={{ uri: item.image.original }}
                  resizeMode={'contain'}
                />
              </SwiperSlide>
            )) }
          </Swiper>
        )}
      </View>
      <Button
        text={buttonText}
        style={styles.button}
        onPress={onPressButtonDebounce}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  progressContainer: {
    height: 5,
    borderRadius: 3,
    overflow: 'hidden',
    marginBottom: 20,
  },
  progress: {
    height: '100%',
    borderRadius: 3,
    overflow: 'hidden',
  },
  contentTitle: {
    fontFamily: font('bold'),
    fontSize: 20,
    lineHeight: 24,
    textAlign: 'center',
  },
  button: {
    marginTop: 20,
    borderRadius: 0,
  },
})
