import {
	ApiFailure,
	ApiFailureResponse,
	Nullable,
	TrackerRequest,
	TrackerSuccessResponse,
	CreateTrackerRequest,
	CreateTrackerSuccessResponse,
	EditTrackerRequest,
	EditTrackerSuccessResponse,
	DeleteTrackerRequest,
	DeleteTrackerSuccessResponse,
	TrackerRatesRequest,
	TrackerRatesSuccessResponse,
	TrackerSettingsRequest,
	TrackerSettingsSuccessResponse,
	TrackerStatsRequest,
	TrackerStatsSuccessResponse,
	TrackerSendStatsRequest,
	TrackerSendStatsSuccessResponse,
	TrackerType,
} from '../../types'
import { AUTH_LOGOUT_SUCCESS } from '../redux/auth'

export interface TrackersState {
	trackers: any
	trackers_fetching: boolean
	trackers_error: Nullable<ApiFailure>
	create_tracker_success: any
	create_tracker_fetching: boolean
	create_tracker_error: Nullable<ApiFailure>
	edit_tracker_success: boolean
	edit_tracker_fetching: boolean
	edit_tracker_error: Nullable<ApiFailure>
	delete_tracker_success: boolean
	delete_tracker_fetching: boolean
	delete_tracker_error: Nullable<ApiFailure>
	rates: any
	rates_fetching: boolean
	rates_error: Nullable<ApiFailure>
	tracker_settings: any
	tracker_settings_fetching: boolean
	tracker_settings_error: Nullable<ApiFailure>
	tracker_stats: any
	tracker_stats_fetching: boolean
	tracker_stats_error: Nullable<ApiFailure>
	tracker_send_stats_success: any
	tracker_send_stats_fetching: boolean
	tracker_send_stats_error: Nullable<ApiFailure>
	// trackers_notifications: any
}

export const TRACKERS_REQUEST = 'TRACKERS_REQUEST'
export const TRACKERS_SUCCESS = 'TRACKERS_SUCCESS'
export const TRACKERS_FAILURE = 'TRACKERS_FAILURE'
export const CREATE_TRACKER = 'CREATE_TRACKER'
export const CREATE_TRACKER_REQUEST = 'CREATE_TRACKER_REQUEST'
export const CREATE_TRACKER_SUCCESS = 'CREATE_TRACKER_SUCCESS'
export const CREATE_TRACKER_FAILURE = 'CREATE_TRACKER_FAILURE'
export const EDIT_TRACKER_REQUEST = 'EDIT_TRACKER_REQUEST'
export const EDIT_TRACKER_SUCCESS = 'EDIT_TRACKER_SUCCESS'
export const EDIT_TRACKER_FAILURE = 'EDIT_TRACKER_FAILURE'
export const DELETE_TRACKER_REQUEST = 'DELETE_TRACKER_REQUEST'
export const DELETE_TRACKER_SUCCESS = 'DELETE_TRACKER_SUCCESS'
export const DELETE_TRACKER_FAILURE = 'DELETE_TRACKER_FAILURE'
export const TRACKER_RATES_REQUEST = 'TRACKER_RATES_REQUEST'
export const TRACKER_RATES_SUCCESS = 'TRACKER_RATES_SUCCESS'
export const TRACKER_RATES_FAILURE = 'TRACKER_RATES_FAILURE'
export const TRACKER_SETTINGS_REQUEST = 'TRACKER_SETTINGS_REQUEST'
export const TRACKER_SETTINGS_SUCCESS = 'TRACKER_SETTINGS_SUCCESS'
export const TRACKER_SETTINGS_FAILURE = 'TRACKER_SETTINGS_FAILURE'
export const TRACKER_STATS_REQUEST = 'TRACKER_STATS_REQUEST'
export const TRACKER_STATS_SUCCESS = 'TRACKER_STATS_SUCCESS'
export const TRACKER_STATS_FAILURE = 'TRACKER_STATS_FAILURE'
export const TRACKER_SEND_STATS_REQUEST = 'TRACKER_SEND_STATS_REQUEST'
export const TRACKER_SEND_STATS_SUCCESS = 'TRACKER_SEND_STATS_SUCCESS'
export const TRACKER_SEND_STATS_FAILURE = 'TRACKER_SEND_STATS_FAILURE'
export const TRACKER_SET_NOTIFICATIONS = 'TRACKER_SET_NOTIFICATIONS'

export const trackersRequestAction = (params?: TrackerRequest) =>
	({ type: TRACKERS_REQUEST, params })
export const trackersSuccessAction = (payload: TrackerSuccessResponse) =>
	({ type: TRACKERS_SUCCESS, payload })
export const trackersFailureAction = (payload: ApiFailureResponse) =>
	({ type: TRACKERS_FAILURE, payload })
export const createTrackerAction = () =>
	({ type: CREATE_TRACKER })
export const createTrackerRequestAction = (params: CreateTrackerRequest) =>
	({ type: CREATE_TRACKER_REQUEST, params })
export const createTrackerSuccessAction = (payload: CreateTrackerSuccessResponse) =>
	({ type: CREATE_TRACKER_SUCCESS, payload })
export const createTrackerFailureAction = (payload: ApiFailureResponse) =>
	({ type: CREATE_TRACKER_FAILURE, payload })
export const editTrackerRequestAction = (params: EditTrackerRequest) =>
	({ type: EDIT_TRACKER_REQUEST, params })
export const editTrackerSuccessAction = (payload: EditTrackerSuccessResponse) =>
	({ type: EDIT_TRACKER_SUCCESS, payload })
export const editTrackerFailureAction = (payload: ApiFailureResponse) =>
	({ type: EDIT_TRACKER_FAILURE, payload })
export const deleteTrackerRequestAction = (params: DeleteTrackerRequest) =>
	({ type: DELETE_TRACKER_REQUEST, params })
export const deleteTrackerSuccessAction = (payload: DeleteTrackerSuccessResponse) =>
	({ type: DELETE_TRACKER_SUCCESS, payload })
export const deleteTrackerFailureAction = (payload: ApiFailureResponse) =>
	({ type: DELETE_TRACKER_FAILURE, payload })
export const trackerRatesRequestAction = (params?: TrackerRatesRequest) =>
	({ type: TRACKER_RATES_REQUEST, params })
export const trackerRatesSuccessAction = (payload: TrackerRatesSuccessResponse) =>
	({ type: TRACKER_RATES_SUCCESS, payload })
export const trackerRatesFailureAction = (payload: ApiFailureResponse) =>
	({ type: TRACKER_RATES_FAILURE, payload })
export const trackerSettingsRequestAction = (params?: TrackerSettingsRequest) =>
	({ type: TRACKER_SETTINGS_REQUEST, params })
export const trackerSettingsSuccessAction = (payload: TrackerSettingsSuccessResponse) =>
	({ type: TRACKER_SETTINGS_SUCCESS, payload })
export const trackerSettingsFailureAction = (payload: ApiFailureResponse) =>
	({ type: TRACKER_SETTINGS_FAILURE, payload })
export const trackerStatsRequestAction = (params?: TrackerStatsRequest) =>
	({ type: TRACKER_STATS_REQUEST, params })
export const trackerStatsSuccessAction = (payload: TrackerStatsSuccessResponse) =>
	({ type: TRACKER_STATS_SUCCESS, payload })
export const trackerStatsFailureAction = (payload: ApiFailureResponse) =>
	({ type: TRACKER_STATS_FAILURE, payload })
export const trackerSendStatsRequestAction = (params?: TrackerSendStatsRequest) =>
	({ type: TRACKER_SEND_STATS_REQUEST, params })
export const trackerSendStatsSuccessAction = (payload: TrackerSendStatsSuccessResponse) =>
	({ type: TRACKER_SEND_STATS_SUCCESS, payload })
export const trackerSendStatsFailureAction = (payload: ApiFailureResponse) =>
	({ type: TRACKER_SEND_STATS_FAILURE, payload })
export const trackerSetNotificationsAction = (payload: { id: number, notifications: boolean }) =>
	({ type: TRACKER_SET_NOTIFICATIONS, payload })

const initialState: TrackersState = {
	trackers: [],
	trackers_fetching: false,
	trackers_error: null,
	create_tracker_success: null,
	create_tracker_fetching: false,
	create_tracker_error: null,
	edit_tracker_success: false,
	edit_tracker_fetching: false,
	edit_tracker_error: null,
	delete_tracker_success: false,
	delete_tracker_fetching: false,
	delete_tracker_error: null,
	rates: [],
	rates_fetching: false,
	rates_error: null,
	tracker_settings: [],
	tracker_settings_fetching: false,
	tracker_settings_error: null,
	tracker_stats: [],
	tracker_stats_fetching: false,
	tracker_stats_error: null,
	tracker_send_stats_success: [],
	tracker_send_stats_fetching: false,
	tracker_send_stats_error: null,
	// trackers_notifications: {},
}

const TrackerReducer = (state = initialState, action: any): TrackersState => {
	const { type, payload } = action
	switch (type) {
		case TRACKERS_REQUEST: {
			return { ...state, trackers_fetching: true, trackers_error: null }
		}
		case TRACKERS_SUCCESS: {
			const { trackers } = payload as TrackerSuccessResponse
			return {
				...state,
				trackers_fetching: false,
				trackers: trackers,
			}
		}
		case TRACKERS_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, trackers_fetching: false, trackers_error: error }
		}
		case CREATE_TRACKER: {
			return {
				...state,
				create_tracker_success: null,
			}
		}
		case CREATE_TRACKER_REQUEST: {
			return {
				...state,
				create_tracker_success: null,
				create_tracker_fetching: true,
				create_tracker_error: null,
			}
		}
		case CREATE_TRACKER_SUCCESS: {
			const { id } = payload as CreateTrackerSuccessResponse
			return { ...state, create_tracker_success: id, create_tracker_fetching: false }
		}
		case CREATE_TRACKER_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, create_tracker_fetching: false, create_tracker_error: error }
		}
		case EDIT_TRACKER_REQUEST: {
			return {
				...state,
				edit_tracker_success: false,
				edit_tracker_fetching: true,
				edit_tracker_error: null,
			}
		}
		case EDIT_TRACKER_SUCCESS: {
			return { ...state, edit_tracker_success: true, edit_tracker_fetching: false }
		}
		case EDIT_TRACKER_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, edit_tracker_fetching: false, edit_tracker_error: error }
		}
		case DELETE_TRACKER_REQUEST: {
			return {
				...state,
				delete_tracker_success: false,
				delete_tracker_fetching: true,
				delete_tracker_error: null,
			}
		}
		case DELETE_TRACKER_SUCCESS: {
			return { ...state, delete_tracker_success: true, delete_tracker_fetching: false }
		}
		case DELETE_TRACKER_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, delete_tracker_fetching: false, delete_tracker_error: error }
		}
		case TRACKER_RATES_REQUEST: {
			return { ...state, rates_fetching: true, rates_error: null }
		}
		case TRACKER_RATES_SUCCESS: {
			const { rates } = payload as TrackerRatesSuccessResponse
			return {
				...state,
				rates_fetching: false,
				rates: rates,
			}
		}
		case TRACKER_RATES_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, rates_fetching: false, rates_error: error }
		}
		case TRACKER_SETTINGS_REQUEST: {
			return { ...state, tracker_settings_fetching: true, tracker_settings_error: null }
		}
		case TRACKER_SETTINGS_SUCCESS: {
			const { settings } = payload as TrackerSettingsSuccessResponse
			return {
				...state,
				tracker_settings_fetching: false,
				tracker_settings: settings,
			}
		}
		case TRACKER_SETTINGS_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, tracker_settings_fetching: false, tracker_settings_error: error }
		}
		case TRACKER_STATS_REQUEST: {
			return { ...state, tracker_stats_fetching: true, tracker_stats_error: null }
		}
		case TRACKER_STATS_SUCCESS: {
			const { tracker_results } = payload as TrackerStatsSuccessResponse
			return {
				...state,
				tracker_stats_fetching: false,
				tracker_stats: tracker_results,
			}
		}
		case TRACKER_STATS_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, tracker_stats_fetching: false, tracker_stats_error: error }
		}
		case TRACKER_SEND_STATS_REQUEST: {
			return {
				...state,
				tracker_send_stats_success: false,
				tracker_send_stats_fetching: true,
				tracker_send_stats_error: null,
			}
		}
		case TRACKER_SEND_STATS_SUCCESS: {
			return { ...state, tracker_send_stats_success: true, tracker_send_stats_fetching: false }
		}
		case TRACKER_SEND_STATS_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, tracker_send_stats_fetching: false, tracker_send_stats_error: error }
		}
		case TRACKER_SET_NOTIFICATIONS: {
			// const { id, times } = payload as TrackerNotificationsType
			// return {
			// 	...state,
			// 	trackers_notifications: {
			// 		...state.trackers_notifications,
			// 		[id]: times,
			// 	},
			// }
			return {
				...state,
				trackers: state.trackers.map((tracker: TrackerType) => {
					if (tracker.id === payload.id) {
						return {
							...tracker,
							notifications: payload.notifications,
						}
					}
					return tracker
				}),
			}
		}
		case AUTH_LOGOUT_SUCCESS: {
			return { ...initialState }
		}
		default:
			return state
	}
}

export default TrackerReducer
