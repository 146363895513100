import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { StackScreenProps } from '@react-navigation/stack'
import { FlatList, StyleSheet, Text, View, ViewStyle, Dimensions } from 'react-native'
import moment from 'moment'
import 'moment/locale/ru'
import { isNull, toUpper, toLower, isNumber } from 'lodash'

import { ListEmptyComponent, AppointmentCard, Screen, TabBar } from '../components'
import { t } from '../localization'
import { font, useTheme } from '../theme'
import { AppointmentType, MainNavigatorProps, Nullable } from '../types'
import { capitalize } from '../service'
import { AppState } from '../store/redux'
import {
	appointmentNewAddData,
	appointmentsFutureRequestAction,
	appointmentsPastRequestAction,
	appointmentNewResetAction,
} from '../store/redux/appointments'

moment().locale('ru')

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

type ScreenProps = DispatchProps & StackScreenProps<MainNavigatorProps, 'AppointmentListScreen'>

type Props = ScreenProps & StateProps

type Type = 'FUTURE' | 'PAST'

interface State {
	tabType: Type
}

function AppointmentListScreenWrapper(props: Props) {
	const { colors } = useTheme()
	const [screenData, setScreenData] = useState(Dimensions.get('window'))

	useEffect(() => {
		const onChange = (result) => {
			setScreenData(result.window)
		}

		const subscription = Dimensions.addEventListener('change', onChange)

		return () => subscription?.remove()
	})

	return <AppointmentListScreen colors={colors} isSmallScreen={screenData.width < 655} {...props} />
}

class AppointmentListScreen extends React.Component<Props, State>{
	constructor(props: Props) {
		super(props)

		const type = toUpper(props.route.params?.type)
		this.state = {
			tabType: type === 'FUTURE' || type === 'PAST' ? type : 'FUTURE'
		}
	}

	componentDidMount() {
		this.onTabChange(this.state.tabType)
	}

	renderTitle = () => {
		const { colors } = this.props
		return (
			<View style={styles.title}>
				<Text style={[styles.titleText, { color: colors.text.title }]}>{t('appointment')}</Text>
			</View>
		)
	}

	render() {
		const type = this.state.tabType
		const { checkFetching, colors, isSmallScreen, unread } = this.props
		const { fetching, appointments } = this.props[toLower(type)]

		const nearestConsultationId = appointments.length && appointments[0].id || null

		return (
			<Screen>
				<View style={[
					styles.contentWrapper,
					{
						width: !isSmallScreen ? '47.22%' : '100%',
						minWidth: !isSmallScreen ? 635 : 'auto',
						paddingLeft: appointments && appointments.length > 3 ? 12 : 20,
					},
				]}>
					{this.renderTitle()}
					<TabBar
						containerStyle={{ marginBottom: 12 }}
						active={type === 'FUTURE' ? 0 : 1}
						onChange={this.onTabChange}
						leftTabText={t('registry_feature')}
						rightTabText={t('registry_past')}
					/>
				</View>
				<FlatList
					data={appointments}
					renderItem={({ item, index, }) => (
						<_Appointment
							key={index.toString()}
							appointment={item}
							index={index}
							type={type}
							prevAppointment={appointments[index - 1] || {} as AppointmentType}
							onAddRecord={this.onAddRecord}
							downloadCheckButton
							disableCheckDownloadButton={checkFetching}
							isUnreadMessages={unread}
							nearestConsultationId={nearestConsultationId}
						/>
					)}
					keyExtractor={(item, index) => index.toString()}
					onEndReachedThreshold={0.5}
					onRefresh={this.onRefresh}
					onEndReached={() => this.getPagination()}
					refreshing={fetching}
					contentContainerStyle={[
						styles.listContainer,
						{
							width: !isSmallScreen ? '47.22%' : '100%',
							minWidth: !isSmallScreen ? 635 : 'auto',
						}
					]}
					ListEmptyComponent={
						<View style={{
							flex: 1,
							backgroundColor: appointments && !appointments.length || isNull(appointments)
								? colors.background.primary
								: 'initial',
							borderRadius: appointments && !appointments.length || isNull(appointments) ? 20 : 0,
						}}>
							<ListEmptyComponent type={'appointment'} />
						</View>
					}
				/>
			</Screen>
		)
	}

	onRefresh = () => {
		this.getPagination(1)
	}

	getPagination = (_page?: number) => {
		const type = this.state.tabType
		const { getFutureAppointments, getPastAppointments } = this.props
		const {
			page = 0,
			limit = 0,
			total = 0,
			fetching,
		} = this.props[toLower(type)]
		if (!fetching && (!!_page || page * limit < total)) {
			if (this.state.tabType === 'FUTURE') getFutureAppointments({ page: _page || (page + 1), limit })
			else if (this.state.tabType === 'PAST') getPastAppointments({ page: _page || (page + 1), limit })
		}
	}

	onTabChange = (id: number | Type) => {
		const types: Array<Type> = ['FUTURE', 'PAST']
		const type: Type = isNumber(id) ? types[id] : id
		this.props.navigation.setParams({ type })
		this.setState({ tabType: type }, () => {
			this.getPagination(1)
		})
	}

	onAddRecord = (appointment: AppointmentType) => {
		const { navigation, setAppointmentData, resetAppointment } = this.props

		resetAppointment(true)

		const isPack = appointment.is_pack
		const isPackExpired = moment(appointment.pack.pack_expiration_date).diff(moment(), 'days') <= 0
		const isPackAllowed = appointment.pack.current_pack < appointment.pack.max_in_pack
		const isPackFull = appointment.pack.is_pack_full
		const isCancelled = appointment.status === 'CANCELLED'

		// @ts-ignore
		setAppointmentData({
			doctor: appointment.doctor,
			client: appointment.patient,
			serviceId: appointment.service.id,
			pack: {
				isPack: isPack && !isPackExpired && isPackAllowed && !isCancelled && !isPackFull,
				parentId: appointment.parent_id || appointment.id,
				count: appointment.pack.current_pack + 1,
			},
		})
		navigation.navigate('AppointmentStack', {
			// @ts-ignore
			screen: 'NewAppointmentDatetimeScreen', params: {
				service_id: appointment.service.id,
				doctor_id: appointment.doctor.id,
			}
		})
	}

}

const mapStateToProps = (state: AppState) => ({
	future: {
		fetching: state.appointment.future_appointments_fetching || false,
		page: state.appointment.future_appointments_page || 0,
		limit: state.appointment.future_appointments_limit || 0,
		total: state.appointment.future_appointments_total || 0,
		appointments: state.appointment.future_appointments || [],
	},
	past: {
		fetching: state.appointment.past_appointments_fetching || false,
		page: state.appointment.past_appointments_page || 0,
		limit: state.appointment.past_appointments_limit || 0,
		total: state.appointment.past_appointments_total || 0,
		appointments: state.appointment.past_appointments || [],
	},
	checkFetching: state.appointment.check_fetching,
	unread: !!state.conference.unread,
})

const mapDispatchToProps = {
	getFutureAppointments: appointmentsFutureRequestAction,
	getPastAppointments: appointmentsPastRequestAction,
	setAppointmentData: appointmentNewAddData,
	resetAppointment: appointmentNewResetAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentListScreenWrapper)

const styles = {
	contentContainer: {
		flex: 1,
	} as ViewStyle,
	contentWrapper: {
		marginTop: 40,
		paddingRight: 20,
		alignSelf: 'center',
	} as ViewStyle,
	listContainer: {
		flexGrow: 1,
		alignSelf: 'center',
		marginBottom: 40,
		paddingHorizontal: 20,
	} as ViewStyle,
	title: {
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: 24,
	},
	titleText: {
		fontSize: 24,
		fontFamily: font('bold'),
	},
	titleBackIcon: {
		marginRight: 15,
	}
}

interface AppointmentProps {
	appointment: AppointmentType
	prevAppointment: AppointmentType
	type: Type
	index: number
	onAddRecord?: (appointment: AppointmentType) => void
	downloadCheckButton: boolean
	disableCheckDownloadButton: boolean
	isUnreadMessages: boolean
	nearestConsultationId: Nullable<number>
}

function _Appointment({
	appointment,
	index,
	type,
	prevAppointment,
	onAddRecord,
	downloadCheckButton = false,
	disableCheckDownloadButton = false,
	isUnreadMessages = false,
	nearestConsultationId = null,
}: AppointmentProps) {

	const { colors } = useTheme()

	const marginTop = !index ? 0 : 20
	const isNewMonth = index === 0 ||
		moment(appointment.slot.datetime).month() !== moment(prevAppointment.slot.datetime).month()

	return (
		<>
			{isNewMonth && (
				<Text style={[_registryStyles.month, { color: colors.text.subtitle, marginTop }]}>
					{capitalize(moment(appointment.slot.datetime).format('MMMM'))}
				</Text>
			)}
			<AppointmentCard
				style={_registryStyles.card}
				appointment={appointment}
				onAddRecord={onAddRecord}
				cancelAppointmentButton={type === 'FUTURE'}
				downloadCheckButton={downloadCheckButton}
				disableCheckDownloadButton={disableCheckDownloadButton}
				isUnreadMessages={isUnreadMessages}
				nearestConsultationId={nearestConsultationId}
			/>
		</>
	)
}

const _registryStyles = StyleSheet.create({
	card: {
		marginBottom: 12,
	},
	month: {
		marginBottom: 12,
		fontFamily: font(),
		fontSize: 24,
		lineHeight: 29,
	},
})
