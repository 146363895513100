import {
	ApiFailure,
	ApiFailureResponse,
	FaqSuccessResponse,
	Faq,
	Nullable,
} from '../../types'

export interface FaqState {
	faq: Nullable<Faq[]>
	fetching: boolean
	error: Nullable<ApiFailure>
}

export const FAQ_REQUEST = 'FAQ_REQUEST'
export const FAQ_SUCCESS = 'FAQ_SUCCESS'
export const FAQ_FAILURE = 'FAQ_FAILURE'

export const faqRequestAction = () => ({ type: FAQ_REQUEST })
export const faqSuccessAction = (payload) => ({ type: FAQ_SUCCESS, payload })
export const faqFailureAction = (payload) => ({ type: FAQ_FAILURE, payload })


const initialState: FaqState = {
	faq: null,
	fetching: false,
	error: null,
}

const FaqReducer = (state = initialState, action: any): FaqState => {
	const { type, payload, params } = action
	switch (type) {
		case FAQ_REQUEST: {
			return { ...state, error: null, fetching: true }
		}
		case FAQ_SUCCESS: {
			const { faqs } = payload as FaqSuccessResponse
			return { ...state, fetching: false, faq: faqs }
		}
		case FAQ_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, fetching: false, error }
		}
		default:
			return state
	}
}

export default FaqReducer
