import { ApiFailure, ApiFailureResponse } from '../../api'
import {
	Chat,
	ChatMessagesRequest,
	ChatMessageSuccessResponse,
	ChatMessageType,
	ChatSendMessageRequest,
	ChatSendMessageSuccessResponse,
	ChatSuccessResponse,
	ConferenceType,
	FileDeleteResponse,
	FileUploadProgress,
	FileUploadResponse,
	Nullable,
	ChatFilesUploadActionParams,
	UploadedFileType,
	UploadFileType,
} from '../../types'
import {
	deleteFileRequestWorker,
	deleteFileSuccessWorker,
	filesUploadDoneWorker,
	filesUploadProgressWorker,
	filesUploadRequestWorker,
	filesUploadSuccessWorker,
	chatMessageSuccessWorker,
	chatSendMessageSuccessWorker,
} from '../../service'

export interface ConferenceState {
	session: Nullable<ConferenceType>
	chat: Nullable<Chat>
	fetching: boolean
	error: Nullable<ApiFailure>
	messages: Nullable<ChatMessageType[]>
	messages_fetching: boolean
	messages_error: Nullable<ApiFailure>
	page: Nullable<number>
	limit: Nullable<number>
	total: Nullable<number>
	unread: number
	files: UploadFileType[]
	inRoom: boolean
	sessionIdsBlacklist: Nullable<string[]>
}

export const CONFERENCE_SET = 'CONFERENCE_SET'
export const CONFERENCE_CHAT_REQUEST = 'CONFERENCE_CHAT_REQUEST'
export const CONFERENCE_CHAT_SUCCESS = 'CONFERENCE_CHAT_SUCCESS'
export const CONFERENCE_CHAT_FAILURE = 'CONFERENCE_CHAT_FAILURE'
export const CONFERENCE_CHAT_MESSAGE_REQUEST = 'CONFERENCE_CHAT_MESSAGE_REQUEST'
export const CONFERENCE_CHAT_MESSAGE_SUCCESS = 'CONFERENCE_CHAT_MESSAGE_SUCCESS'
export const CONFERENCE_CHAT_MESSAGE_FAILURE = 'CONFERENCE_CHAT_MESSAGE_FAILURE'
export const CONFERENCE_CHAT_SEND_MESSAGE_REQUEST = 'CONFERENCE_CHAT_SEND_MESSAGE_REQUEST'
export const CONFERENCE_CHAT_SEND_MESSAGE_SUCCESS = 'CONFERENCE_CHAT_SEND_MESSAGE_SUCCESS'
export const CONFERENCE_CHAT_SEND_MESSAGE_FAILURE = 'CONFERENCE_CHAT_SEND_MESSAGE_FAILURE'
export const CONFERENCE_CHAT_SET_UNREAD = 'CONFERENCE_CHAT_SET_UNREAD'
export const CONFERENCE_CHAT_FILES_UPLOAD_REQUEST = 'CONFERENCE_CHAT_FILES_UPLOAD_REQUEST'
export const CONFERENCE_CHAT_FILES_UPLOAD_PROGRESS = 'CONFERENCE_CHAT_FILES_UPLOAD_PROGRESS'
export const CONFERENCE_CHAT_FILES_UPLOAD_SUCCESS = 'CONFERENCE_CHAT_FILES_UPLOAD_SUCCESS'
export const CONFERENCE_CHAT_FILES_UPLOAD_DONE = 'CONFERENCE_CHAT_FILES_UPLOAD_DONE'
export const CONFERENCE_CHAT_DELETE_FILE_REQUEST = 'CONFERENCE_CHAT_DELETE_FILE_REQUEST'
export const CONFERENCE_CHAT_DELETE_FILE_SUCCESS = 'CONFERENCE_CHAT_DELETE_FILE_SUCCESS'
export const CONFERENCE_CHAT_READ_ACTION = 'CONFERENCE_CHAT_READ_ACTION'
export const CONFERENCE_ACCEPT_CALL_ACTION = 'CONFERENCE_ACCEPT_CALL_ACTION'
export const CONFERENCE_CANCEL_CALL_ACTION = 'CONFERENCE_CANCEL_CALL_ACTION'

export const conferenceSetAction = (params: ConferenceType) => ({ type: CONFERENCE_SET, params })
export const conferenceChatRequestAction = (id: number) => ({ type: CONFERENCE_CHAT_REQUEST, params: id })
export const conferenceChatSuccessAction = (payload) => ({ type: CONFERENCE_CHAT_SUCCESS, payload })
export const conferenceChatFailureAction = (payload) => ({ type: CONFERENCE_CHAT_FAILURE, payload })
export const conferenceChatMessageRequestAction = (params: ChatMessagesRequest) => ({ type: CONFERENCE_CHAT_MESSAGE_REQUEST, params })
export const conferenceChatMessageSuccessAction = (payload) => ({ type: CONFERENCE_CHAT_MESSAGE_SUCCESS, payload })
export const conferenceChatMessageFailureAction = (payload) => ({ type: CONFERENCE_CHAT_MESSAGE_FAILURE, payload })
export const conferenceChatSendMessageRequestAction = (params: ChatSendMessageRequest) => ({ type: CONFERENCE_CHAT_SEND_MESSAGE_REQUEST, params })
export const conferenceChatSendMessageSuccessAction = (payload: ChatSendMessageSuccessResponse) => ({ type: CONFERENCE_CHAT_SEND_MESSAGE_SUCCESS, payload })
export const conferenceChatSendMessageFailureAction = (payload) => ({ type: CONFERENCE_CHAT_SEND_MESSAGE_FAILURE, payload })
export const conferenceChatSetUnread = (params: number) => ({ type: CONFERENCE_CHAT_SET_UNREAD, params })
export const conferenceChatFilesUploadRequestAction = (params: ChatFilesUploadActionParams) => ({ type: CONFERENCE_CHAT_FILES_UPLOAD_REQUEST, params })
export const conferenceChatFilesUploadProgressAction = (payload: FileUploadProgress) => ({
	type: CONFERENCE_CHAT_FILES_UPLOAD_PROGRESS,
	payload,
})
export const conferenceChatFilesUploadSetAction = (payload: FileUploadResponse) => ({ type: CONFERENCE_CHAT_FILES_UPLOAD_SUCCESS, payload })
export const conferenceChatFilesUploadDoneAction = (payload: { id: string; file: UploadedFileType }) => ({
	type: CONFERENCE_CHAT_FILES_UPLOAD_DONE,
	payload,
})
export const conferenceChatDeleteFileRequestAction = (params: number | string) => ({ type: CONFERENCE_CHAT_DELETE_FILE_REQUEST, params })
export const conferenceChatDeleteFileSuccessAction = (payload: any) => ({ type: CONFERENCE_CHAT_DELETE_FILE_SUCCESS, payload })
export const conferenceChatReadAction = (id: number) => ({ type: CONFERENCE_CHAT_READ_ACTION, payload: id })
export const conferenceAcceptCallAction = () => ({ type: CONFERENCE_ACCEPT_CALL_ACTION })
export const conferenceCancelCallAction = () => ({ type: CONFERENCE_CANCEL_CALL_ACTION })

const initialState: ConferenceState = {
	session: null,
	chat: null,
	fetching: false,
	error: null,
	messages: null,
	messages_fetching: false,
	messages_error: null,
	page: null,
	limit: null,
	total: null,
	unread: 0,
	files: [],
	inRoom: false,
	sessionIdsBlacklist: null,
}

const ConferenceReducer = (state = initialState, action: any): ConferenceState => {
	const { type, payload, params } = action
	switch (type) {
		case CONFERENCE_SET: {
			const sessionIdsBlacklist = [...(state.sessionIdsBlacklist || [])]
			const { id } = params as ConferenceType
			if (!sessionIdsBlacklist.includes(id)) {
				sessionIdsBlacklist.push(id)
			}
			return { ...state, session: params, sessionIdsBlacklist }
		}
		case CONFERENCE_CHAT_REQUEST: {
			return { ...state, error: null, fetching: true }
		}
		case CONFERENCE_CHAT_SUCCESS: {
			const { chat } = payload as ChatSuccessResponse
			const unread = chat.unread || 0
			return { ...state, fetching: false, chat, unread }
		}
		case CONFERENCE_CHAT_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, fetching: false, error }
		}
		case CONFERENCE_CHAT_MESSAGE_REQUEST: {
			return { ...state, messages_fetching: true }
		}
		case CONFERENCE_CHAT_MESSAGE_SUCCESS: {
			const { page, limit, total } = payload as ChatMessageSuccessResponse
			const isNewMessage = total != state.total
			const messages = chatMessageSuccessWorker(state.messages, payload as ChatMessageSuccessResponse, isNewMessage)
			return { ...state, messages_fetching: false, messages: messages, page, limit, total }
		}
		case CONFERENCE_CHAT_MESSAGE_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, messages_fetching: false, messages_error: error }
		}
		case CONFERENCE_CHAT_SEND_MESSAGE_REQUEST: {
			const { appended } = params as ChatSendMessageRequest
			return { ...state, messages: appended }
		}
		case CONFERENCE_CHAT_SEND_MESSAGE_SUCCESS: {
			const messages = chatSendMessageSuccessWorker(state.messages, payload as ChatSendMessageSuccessResponse)
			return { ...state, files: [], messages, total: state.total + 1 }
		}
		case CONFERENCE_CHAT_SEND_MESSAGE_FAILURE: {
			return { ...state }
		}
		case CONFERENCE_CHAT_SET_UNREAD: {
			return { ...state, unread: params }
		}
		case CONFERENCE_CHAT_FILES_UPLOAD_REQUEST: {
			const { files } = action.params as ChatFilesUploadActionParams
			const filesMerged = filesUploadRequestWorker(state.files, files)
			return { ...state, files: filesMerged }
		}
		case CONFERENCE_CHAT_FILES_UPLOAD_PROGRESS: {
			const files = filesUploadProgressWorker(state.files, payload as FileUploadProgress)
			return { ...state, files }
		}
		case CONFERENCE_CHAT_FILES_UPLOAD_SUCCESS: {
			const files = filesUploadSuccessWorker(state.files, payload as Array<{ id: string; uri: string }>)
			return { ...state, files }
		}
		case CONFERENCE_CHAT_FILES_UPLOAD_DONE: {
			const files = filesUploadDoneWorker(state.files, payload as { id: string; file: UploadedFileType })
			return { ...state, files }
		}
		case CONFERENCE_CHAT_DELETE_FILE_REQUEST: {
			const files = deleteFileRequestWorker(state.files, action.params as number | string)
			return { ...state, files }
		}
		case CONFERENCE_CHAT_DELETE_FILE_SUCCESS: {
			const files = deleteFileSuccessWorker(state.files, payload as FileDeleteResponse)
			return { ...state, files }
		}
		case CONFERENCE_ACCEPT_CALL_ACTION: {
			return { ...state, inRoom: true }
		}
		case CONFERENCE_CANCEL_CALL_ACTION: {
			const sessionIdsBlacklist: string[] = [...(state.sessionIdsBlacklist || [])]
			// return { ...initialState, sessionIdsBlacklist }
			return {
				...state,
				session: initialState.session,
				inRoom: initialState.inRoom,
				sessionIdsBlacklist,
			}
		}
		default:
			return state
	}
}

export default ConferenceReducer
