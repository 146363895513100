import config from '../config'
import { FixtureApi } from '.'

export default ({ resolve, successResponse, errorResponse, axiosConfig }: FixtureApi) => {
	switch (axiosConfig.method?.toUpperCase()) {
		case 'POST': post({ resolve, successResponse, errorResponse, axiosConfig }); break;
		default: resolve(errorResponse(axiosConfig)); break;
	}
}

function post({ resolve, successResponse, errorResponse, axiosConfig }: FixtureApi) {
	const phone = axiosConfig.data.phone;
	if (phone == '79999999999' || phone == '79999999998' || phone == '79999999997') {
		const code = axiosConfig.data.code;
		if (!!code) {
			const _code = phone.substring(phone.length - config.smsCodeLength)
			if (code == _code) {
				setTimeout(() => resolve(successResponse(axiosConfig, { token: `token:${phone}` })),
					config.api.fixtureDuration)
			} else {
				setTimeout(() => resolve(errorResponse(axiosConfig, {
					message: `Неправильный код подтверждения\n(правильный код ${_code})`
				})), config.api.fixtureDuration)
			}
		} else {
			setTimeout(() => resolve(successResponse(axiosConfig, null)), config.api.fixtureDuration)
		}
	} else {
		setTimeout(() => resolve(errorResponse(axiosConfig, {
			message: 'Ошибка при отправке СМС сообщения.\nНомер не найден',
		})), config.api.fixtureDuration)
	}
}