import React, { useEffect, useState } from 'react'
import {
	FlatList,
	StyleSheet,
	Dimensions,
	Image,
	Text,
	View,
	Linking,
} from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { connect, useSelector } from 'react-redux'
import isNumber from 'lodash/isNumber'
import toUpper from 'lodash/toUpper'
import moment from 'moment'
import 'moment/locale/ru'

moment().locale('ru')

import {
	Screen,
	QuestionCard,
	TabBar,
	Button,
	Modal,
	TextInput,
	Markdown,
} from '../components'
import { Type } from '../components/QuestionCard'
import { t } from '../localization'
import { font, metrics, ThemeColors, useTheme } from '../theme'
import { MainNavigatorProps } from '../types'
import { AppState } from '../store/redux'
import {
	questionRequestAction,
	userQuestionsRequestAction,
	askQuestionRequestAction,
} from '../store/redux/questions'
import { appointmentFailure, conferenceDone } from '../images'
import { plural } from '../service'

type ScreenProps = DispatchProps & StackScreenProps<MainNavigatorProps, 'QuestionStack'>

type StateProps = ReturnType<typeof mapStateToProps> & ScreenProps

type DispatchProps = typeof mapDispatchToProps

interface Props extends StateProps {
	colors: ThemeColors,
	isSmallScreen: boolean
}

interface State {
	showAskQuestionModal: boolean
	showThanksForAskMsg: boolean
	showLimitWarningModal: boolean
	tabType: Type
}

function QuestionListScreenWrapper(props: Props) {
	const { colors } = useTheme()
	const [screenData, setScreenData] = useState(Dimensions.get('window'))

	useEffect(() => {
		const onChange = (result) => {
			setScreenData(result.window)
		}

		const subscription = Dimensions.addEventListener('change', onChange)

		return () => subscription?.remove()
	})

	return <QuestionListScreen colors={colors} isSmallScreen={screenData.width < 655} {...props} />
}

class QuestionListScreen extends React.Component<Props, State>{
	constructor(props: Props) {
		super(props)

		const type = toUpper(props.route.params?.type)
		this.state = {
			showAskQuestionModal: false,
			showThanksForAskMsg: false,
			showLimitWarningModal: false,
			tabType: type === 'MY' || type === 'ALL' ? type : 'MY'
		}
	}

	componentDidMount() {
		this.props.getUserQuestions()
		this.props.getQuestions()
		this.onTabChange(this.state.tabType)
	}

	componentDidUpdate(prevProps: Readonly<Props>): void {
		if (prevProps.askQuestionResult === null && this.props.askQuestionResult) {
			this.setState({ showThanksForAskMsg: true })
			this.props.getUserQuestions()
		}
		if (prevProps.askQuestionError === null && this.props.askQuestionError) {
			this.setState({ showAskQuestionModal: false })
		}
	}

	onTabChange = (id: number | Type) => {
		const types: Array<Type> = ['MY', 'ALL']
		const type: Type = isNumber(id) ? types[id] : id
		this.props.navigation.setParams({ type })
		this.setState({ tabType: type }, () => {
			this.getPagination(1)
		})
	}

	onRefresh = () => {
		this.getPagination(1)
	}

	getPagination = (_page?: number) => {
		const {
			page = 0,
			limit = 0,
			total = 0,
			fetching,
			getQuestions,
			userQuestionsPage = 0,
			userQuestionsLimit = 0,
			userQuestionsTotal = 0,
			userQuestionsFetching,
			getUserQuestions,
		} = this.props
		if (this.state.tabType === 'MY') {
			if (!userQuestionsFetching && (!!_page || userQuestionsPage * userQuestionsLimit < userQuestionsTotal)) {
				getUserQuestions({ page: _page || (userQuestionsPage + 1) })
			}
		} else {
			if (!fetching && (!!_page || page * limit < total)) {
				getQuestions({ page: _page || (page + 1) })
			}
		}
	}

	renderTitle = () => {
		const { colors } = this.props
		return (
			<View style={styles.title}>
				<Text style={[styles.titleText, { color: colors.text.title }]}>{t('questions')}</Text>
			</View>
		)
	}

	render() {
		const {
			isSmallScreen,
			questions,
			userQuestions,
			fetching,
			userQuestionsFetching,
			colors,
			route,
			askQuestion,
			userAsksLimit,
			userCanAskQuestion,
		} = this.props
		const {
			showAskQuestionModal,
			showThanksForAskMsg,
			showLimitWarningModal,
		} = this.state
		const type = this.state.tabType

		return (
			<Screen>
				<View style={[
					styles.contentWrapper,
					{
						width: !isSmallScreen ? '47.22%' : '100%',
						minWidth: !isSmallScreen ? 635 : 'auto',
						paddingLeft: questions && questions.length > 3 ? 12 : 20,
					},
				]}>
					{this.renderTitle()}
					<TabBar
						containerStyle={{ marginBottom: 12 }}
						active={type === 'MY' ? 0 : 1}
						onChange={this.onTabChange}
						leftTabText={t('my_questions')}
						rightTabText={t('all_questions')}
					/>
					<FlatList
						data={type === 'MY' ? userQuestions : questions}
						renderItem={({ item }) => (
							<QuestionCard question={item} type={type} />
						)}
						keyExtractor={(item, index) => index.toString()}
						contentContainerStyle={styles.container}
						refreshing={fetching || userQuestionsFetching}
						onRefresh={this.onRefresh}
						onEndReached={() => this.getPagination()}
						onEndReachedThreshold={0.5}
						ListEmptyComponent={() => {
							return (
								<View style={styles.emptyContainer}>
									<Image
										source={appointmentFailure}
										style={styles.emptyImage}
									/>
									<Text style={[
										styles.emptyText, {
										color: colors.text.title,
									}]}>{t('questions_empty')}</Text>
								</View>
							)
						}}
					/>
					<Footer
						onAskQuestionPress={() => {
							if (userCanAskQuestion) {
								this.setState({ showAskQuestionModal: true })
							} else {
								this.setState({ showLimitWarningModal: true })
							}
						}}
						buttonInactive={!userCanAskQuestion}
					/>
				</View>
				<AskQuestionModal
					isVisible={showAskQuestionModal}
					showThanksForAskMsg={showThanksForAskMsg}
					onConfirm={data => askQuestion(data)}
					onCancel={() => this.setState({
						showAskQuestionModal: false,
						showThanksForAskMsg: false,
					})}
				/>
				<LimitWarningModal
					isVisible={showLimitWarningModal}
					onCancel={() => this.setState({ showLimitWarningModal: false })}
					limit={userAsksLimit}
				/>
			</Screen>
		)
	}
}

const mapStateToProps = (state: AppState) => ({
	questions: state.question.questions || [],
	page: state.question.page,
	total: state.question.total,
	limit: state.question.limit,
	fetching: state.question.fetching,
	userQuestions: state.question.userQuestions || [],
	userQuestionsPage: state.question.userQuestionsPage,
	userQuestionsTotal: state.question.userQuestionsTotal,
	userQuestionsLimit: state.question.userQuestionsLimit,
	userQuestionsFetching: state.question.userQuestionsFetching,
	askQuestionResult: state.question.askQuestion,
	askQuestionError: state.question.askQuestionError,
	userAsksLimit: state.user.profile?.max_questions || 0,
	userCanAskQuestion: state.user.profile?.can_ask || false,
})

const mapDispatchToProps = {
	getQuestions: questionRequestAction,
	getUserQuestions: userQuestionsRequestAction,
	askQuestion: askQuestionRequestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionListScreenWrapper)

const styles = StyleSheet.create({
	container: {
		flexGrow: 1,
		width: '100%',
		alignItems: 'stretch',
		alignSelf: 'center',
	},
	contentWrapper: {
		flex: 1,
		marginTop: 40,
		paddingRight: 20,
		alignSelf: 'center',
	},
	title: {
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: 24,
	},
	titleText: {
		fontSize: 24,
		fontFamily: font('bold'),
	},
	emptyContainer: {
		flex: 1,
		justifyContent: 'center',
		alignSelf: 'center',
	},
	emptyImage: {
		width: 314,
		height: 243,
		alignSelf: 'center',
	},
	emptyText: {
		textAlign: 'center',
		marginTop: 10,
		fontFamily: font('bold'),
		fontSize: 24,
	},
})

function Footer({ onAskQuestionPress, buttonInactive }:
	{ onAskQuestionPress: () => void, buttonInactive: boolean }) {
	const { colors } = useTheme()

	const onPress = () => {
		onAskQuestionPress()
	}

	let inactiveButtonBgColor = {}
	let inactiveButtonTextColor = {}
	if (buttonInactive) {
		inactiveButtonBgColor = { backgroundColor: colors.button.inactive.background }
		inactiveButtonTextColor = { color: colors.button.inactive.text }
	}

	return (
		<View style={[footerStyles.container, { backgroundColor: colors.background.primary }]} >
			<Button
				style={[ footerStyles.button, inactiveButtonBgColor ]}
				textStyle={inactiveButtonTextColor}
				text={t('ask_question')}
				onPress={() => onPress()}
			/>
		</View>
	)
}
const footerStyles = StyleSheet.create({
	container: {
		borderRadius: 20,
		marginVertical: 10,
	},
	button: {
		margin: 16,
	},
	modalTextStyle: {
		flex: 0,
	},
})

interface AskQuestionModalProps {
  onConfirm: ({}) => void
  onCancel: () => void
	isVisible: boolean
	showThanksForAskMsg: boolean
}
function AskQuestionModal({
	onConfirm,
	onCancel,
	isVisible,
	showThanksForAskMsg,
}: AskQuestionModalProps) {
  const { colors } = useTheme()

  const [question, setQuestion] = useState('')
	const [questionDescription, setQuestionDescription] = useState(t('ask_question_description'))

	const userAskQuestionResult = useSelector((state: AppState) => state.question.askQuestion)

  useEffect(() => {
    if (userAskQuestionResult) {
      setQuestion('')
    }
  }, [userAskQuestionResult])

	const sendQuestion = () => {
		onConfirm({ content: question })
	}

	const onLinkPress = async (url: string) => {
		if (url === 'important_show') {
			setQuestionDescription(t('ask_question_description_full'))
		} else if (url === 'important_hide') {
			setQuestionDescription(t('ask_question_description'))
		} else {
			try {
				await Linking.canOpenURL(url)
				Linking.openURL(url)
			} catch (e) {
				console.log('e', e)
			}
		}
	}

	return (
		<Modal isVisible={isVisible} onClose={onCancel} position='center' width={'80%'}>
			{ showThanksForAskMsg ? (
				<View style={modalStyles.thanksContentContainer}>
					<Image
						source={conferenceDone}
						style={modalStyles.thanksImage}
						resizeMode={'contain'}
					/>
					<Text style={[modalStyles.thanksText, { color: colors.text.title }]}>
						{t('thanks_ask')}
					</Text>
				</View>
			) : (
				<View style={modalStyles.container}>
					<Text style={[modalStyles.title, { color: colors.text.default }]}>
						{t('ask_question')}
					</Text>
					<View style={modalStyles.field}>
						<Markdown
							style={modalStyles.markdown}
							text={questionDescription}
							onLinkPress={onLinkPress}
							paragraphStyle={[modalStyles.markdownText, { color: colors.text.primary }]}
							linkStyle={[modalStyles.markdownLink, { color: colors.text.title }]}
						/>
					</View>
					<View style={modalStyles.field}>
						<TextInput multiline numberOfLines={5} onChange={setQuestion} value={question} />
					</View>
					<View style={modalStyles.buttonContainer}>
						<Button
							text={t('send_question')}
							onPress={sendQuestion}
						/>
					</View>
				</View>
			)}
		</Modal>
	)
}

interface LimitWarningModalProps {
	onCancel: () => void
	isVisible: boolean
	limit: number
}
function LimitWarningModal({
	onCancel,
	isVisible,
	limit,
}: LimitWarningModalProps) {
	const { colors } = useTheme()

	return (
		<Modal isVisible={isVisible} onClose={onCancel} position='center'>
			<View style={modalStyles.container}>
				<Text style={[modalStyles.title, { color: colors.text.title }]}>
					{t('ask_limit_notification')}
				</Text>
				<View style={modalStyles.field}>
					<Text style={[modalStyles.description, { color: colors.text.primary }]}>
						{t('ask_limit_description', [limit, plural(limit, t('questions_count'))])}
					</Text>
				</View>
				<View style={modalStyles.buttonContainer}>
					<Button
						text={t('ask_limit_ok')}
						onPress={onCancel}
					/>
				</View>
			</View>
		</Modal>
	)
}

const modalStyles = StyleSheet.create({
	container: {
		paddingVertical: 4,
		paddingHorizontal: 20,
	},
	title: {
		fontFamily: font('bold'),
		fontSize: 24,
	},
	field: {
		marginTop: 16,
	},
	description: {
		fontFamily: font(),
		fontSize: 16,
		lineHeight: 20,
	},
	buttonContainer: {
		marginTop: 16,
	},
	button: {
		flex: 1,
		paddingHorizontal: 20,
	},

	thanksContentContainer: {
		flexGrow: 1,
		paddingTop: 12,
		paddingBottom: 20,
		paddingHorizontal: 16,
		alignItems: 'center',
		justifyContent: 'center',
	},
	thanksImage: {
		width: metrics.screenWidth - 30,
		maxWidth: 314,
		height: (metrics.screenWidth - 30) * (243 / 314),
		maxHeight: 243,
	},
	thanksText: {
		marginTop: 16,
		fontFamily: font('bold'),
		fontSize: 24,
		lineHeight: 29,
		textAlign: 'center',
		width: metrics.screenWidth - 30,
	},

	markdown: {
		flex: 1,
	},
	markdownText: {
		marginTop: 0,
		fontFamily: font(),
		fontSize: 14,
		lineHeight: 17,
	},
	markdownLink: {
		fontFamily: font('bold'),
	},
})