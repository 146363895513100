import {
	ApiFailure,
	ApiFailureResponse,
	ChatSuccessResponse,
	Chat,
	Nullable,
	ChatMessageType,
	ChatMessageSuccessResponse,
	ChatMessagesRequest,
	ChatSendMessageRequest,
	PickedFileType,
	ChatFilesUploadActionParams,
	FileUploadProgress,
	FileUploadResponse,
	UploadedFileType,
	UploadFileType,
	FileDeleteResponse,
	ChatSendMessageSuccessResponse,
} from '../../types'
import {
	deleteFileRequestWorker,
	deleteFileSuccessWorker,
	filesUploadDoneWorker,
	filesUploadProgressWorker,
	filesUploadRequestWorker,
	filesUploadSuccessWorker,
	chatMessageSuccessWorker,
	chatSendMessageSuccessWorker,
} from '../../service'

export interface ChatState {
	chat: Nullable<Chat>
	fetching: boolean
	error: Nullable<ApiFailure>
	messages: Nullable<ChatMessageType[]>
	messages_fetching: boolean
	messages_error: Nullable<ApiFailure>
	page: Nullable<number>
	limit: Nullable<number>
	total: Nullable<number>
	unread: number
	files: UploadFileType[]
}

export const CHAT_REQUEST = 'CHAT_REQUEST'
export const CHAT_SUCCESS = 'CHAT_SUCCESS'
export const CHAT_FAILURE = 'CHAT_FAILURE'
export const CHAT_MESSAGE_REQUEST = 'CHAT_MESSAGE_REQUEST'
export const CHAT_MESSAGE_SUCCESS = 'CHAT_MESSAGE_SUCCESS'
export const CHAT_MESSAGE_FAILURE = 'CHAT_MESSAGE_FAILURE'
export const CHAT_SEND_MESSAGE_REQUEST = 'CHAT_SEND_MESSAGE_REQUEST'
export const CHAT_SEND_MESSAGE_SUCCESS = 'CHAT_SEND_MESSAGE_SUCCESS'
export const CHAT_SEND_MESSAGE_FAILURE = 'CHAT_SEND_MESSAGE_FAILURE'
export const CHAT_SET_UNREAD = 'CHAT_SET_UNREAD'
export const CHAT_FILES_UPLOAD_REQUEST = 'CHAT_FILES_UPLOAD_REQUEST'
export const CHAT_FILES_UPLOAD_PROGRESS = 'CHAT_FILES_UPLOAD_PROGRESS'
export const CHAT_FILES_UPLOAD_SUCCESS = 'CHAT_FILES_UPLOAD_SUCCESS'
export const CHAT_FILES_UPLOAD_DONE = 'CHAT_FILES_UPLOAD_DONE'
export const CHAT_DELETE_FILE_REQUEST = 'CHAT_DELETE_FILE_REQUEST'
export const CHAT_DELETE_FILE_SUCCESS = 'CHAT_DELETE_FILE_SUCCESS'
export const CHAT_READ_ACTION = 'CHAT_READ_ACTION'

export const chatRequestAction = () => ({ type: CHAT_REQUEST })
export const chatSuccessAction = (payload) => ({ type: CHAT_SUCCESS, payload })
export const chatFailureAction = (payload) => ({ type: CHAT_FAILURE, payload })
export const chatMessageRequestAction = (params: ChatMessagesRequest) => ({ type: CHAT_MESSAGE_REQUEST, params })
export const chatMessageSuccessAction = (payload) => ({ type: CHAT_MESSAGE_SUCCESS, payload })
export const chatMessageFailureAction = (payload) => ({ type: CHAT_MESSAGE_FAILURE, payload })
export const chatSendMessageRequestAction = (params: ChatSendMessageRequest) => ({ type: CHAT_SEND_MESSAGE_REQUEST, params })
export const chatSendMessageSuccessAction = (payload: ChatSendMessageSuccessResponse) => ({ type: CHAT_SEND_MESSAGE_SUCCESS, payload })
export const chatSendMessageFailureAction = (payload) => ({ type: CHAT_SEND_MESSAGE_FAILURE, payload })
export const chatSetUnread = (params: number) => ({ type: CHAT_SET_UNREAD, params })
export const chatFilesUploadRequestAction = (params: ChatFilesUploadActionParams) => ({ type: CHAT_FILES_UPLOAD_REQUEST, params })
export const chatFilesUploadProgressAction = (payload: FileUploadProgress) => ({
	type: CHAT_FILES_UPLOAD_PROGRESS,
	payload,
})
export const chatFilesUploadSetAction = (payload: FileUploadResponse) => ({ type: CHAT_FILES_UPLOAD_SUCCESS, payload })
export const chatFilesUploadDoneAction = (payload: { id: string; file: UploadedFileType }) => ({
	type: CHAT_FILES_UPLOAD_DONE,
	payload,
})
export const chatDeleteFileRequestAction = (params: number | string) => ({ type: CHAT_DELETE_FILE_REQUEST, params })
export const chatDeleteFileSuccessAction = (payload: any) => ({ type: CHAT_DELETE_FILE_SUCCESS, payload })
export const chatReadAction = (id: number) => ({ type: CHAT_READ_ACTION, payload: id })

const initialState: ChatState = {
	chat: null,
	fetching: false,
	error: null,
	messages: null,
	messages_fetching: false,
	messages_error: null,
	page: null,
	limit: null,
	total: null,
	unread: 0,
	files: [],
}

const ChatReducer = (state = initialState, action: any): ChatState => {
	const { type, payload, params } = action
	switch (type) {
		case CHAT_REQUEST: {
			return { ...state, error: null, fetching: true }
		}
		case CHAT_SUCCESS: {
			const { chat } = payload as ChatSuccessResponse
			const unread = chat.unread || 0
			return { ...state, fetching: false, chat, unread }
		}
		case CHAT_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, fetching: false, error }
		}
		case CHAT_MESSAGE_REQUEST: {
			return { ...state, messages_fetching: true }
		}
		case CHAT_MESSAGE_SUCCESS: {
			const { page, limit, total } = payload as ChatMessageSuccessResponse
			const isNewMessage = total != state.total
			const messages = chatMessageSuccessWorker(state.messages, payload as ChatMessageSuccessResponse, isNewMessage)
			return { ...state, messages_fetching: false, messages, page, limit, total }
		}
		case CHAT_MESSAGE_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, messages_fetching: false, messages_error: error }
		}
		case CHAT_SEND_MESSAGE_REQUEST: {
			const { appended } = params as ChatSendMessageRequest
			return { ...state, messages: appended }
		}
		case CHAT_SEND_MESSAGE_SUCCESS: {
			const messages = chatSendMessageSuccessWorker(state.messages, payload as ChatSendMessageSuccessResponse)
			return { ...state, files: [], messages, total: state.total + 1 }
		}
		case CHAT_SEND_MESSAGE_FAILURE: {
			return { ...state }
		}
		case CHAT_SET_UNREAD: {
			return { ...state, unread: params }
		}
		case CHAT_FILES_UPLOAD_REQUEST: {
			const { files } = action.params as ChatFilesUploadActionParams
			const filesMerged = filesUploadRequestWorker(state.files, files)
			return { ...state, files: filesMerged }
		}
		case CHAT_FILES_UPLOAD_PROGRESS: {
			const files = filesUploadProgressWorker(state.files, payload as FileUploadProgress)
			return { ...state, files }
		}
		case CHAT_FILES_UPLOAD_SUCCESS: {
			const files = filesUploadSuccessWorker(state.files, payload as Array<{ id: string; uri: string }>)
			return { ...state, files }
		}
		case CHAT_FILES_UPLOAD_DONE: {
			const files = filesUploadDoneWorker(state.files, payload as { id: string; file: UploadedFileType })
			return { ...state, files }
		}
		case CHAT_DELETE_FILE_REQUEST: {
			const files = deleteFileRequestWorker(state.files, action.params as number | string)
			return { ...state, files }
		}
		case CHAT_DELETE_FILE_SUCCESS: {
			const files = deleteFileSuccessWorker(state.files, payload as FileDeleteResponse)
			return { ...state, files }
		}
		default:
			return state
	}
}

export default ChatReducer
