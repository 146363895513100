import React, { Component } from 'react'
import { View, StyleSheet, Text, Platform } from 'react-native'
import Modal from 'react-native-modal'
import { Calendar, LocaleConfig, DateData, MarkingTypes, MarkedDatesType } from 'react-native-calendars'
import XDate from 'xdate'
import moment from 'moment'
import 'moment/locale/ru'

import { Icon, Button } from '.'
import { font, ThemeColors, useTheme } from '../theme'
import { t } from '../localization'
import { Nullable } from '../types'
import { capitalize } from '../service'
import { metrics } from '../theme'

moment().locale('ru')

LocaleConfig.locales['ru'] = {
  monthNames: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
  monthNamesShort: ['Янв.', 'Фев.', 'Март', 'Апр.', 'Май', 'Июнь', 'Июль', 'Авг.', 'Сент.', 'Окт.', 'Ноя.', 'Дек.'],
  dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
  dayNamesShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  today: 'Сегодня',
}
LocaleConfig.defaultLocale = 'ru'

export interface CalendarProps {
  colors?: ThemeColors
  multiSelection?: boolean
  isVisible: boolean
  onClose: () => void
  onSelect: (dates: Array<string>) => void
  dates: Array<string>
}

interface State {
  dates: Array<DateData>
  markDates: {
    [date: string]: MarkedDatesType
  }
  month: Nullable<string>
  isFromDatePicked: boolean
  isToDatePicked: boolean
}

class CalendarCustom extends Component<CalendarProps, State> {
  calendarRef: Calendar
  constructor(props: CalendarProps) {
    super(props)
    this.state = {
      dates: [],
      markDates: {},
      month: null,
      isFromDatePicked: false,
      isToDatePicked: false,
    }
  }

  static defaultProps = {
    multiSelection: false,
  }

  componentDidUpdate(prevProps: CalendarProps, prevState: State) {
    const { isVisible, dates } = this.props
    if (!prevProps.isVisible && isVisible) {
      const _datesFiltered = dates.filter((date) => !!date)
      const _dates = _datesFiltered.map((date) => {
        const _date = moment(date, 'YYYY-MM-DD')
        return {
          day: _date.date(),
          dateString: _date.format('YYYY-MM-DD'),
          month: _date.month(),
          timestamp: _date.valueOf(),
          year: _date.year(),
        }
      })

      let datesRange: Array<string> = []
      if (_dates.length === 2) {
        datesRange = this.getDatesRange(_dates[0], _dates[1])
      }
      const _markDates = this.markDatesHelper(_dates, datesRange)
      this.setState({
        dates: _dates,
        markDates: _markDates,
        isFromDatePicked: _dates.length > 1,
        isToDatePicked: _dates.length === 2,
      })
      if (!!this.calendarRef && !!_dates[0]?.dateString) {
        this.calendarRef.updateMonth(XDate(_dates[0].dateString))
      }
    }
  }

  getDatesRange = (day1: DateData, day2: DateData): Array<string> => {
    const timestamp1 = day1.timestamp
    const timestamp2 = day2.timestamp
    const dif = timestamp2 - timestamp1
    const dayTime = 1000 * 60 * 60 * 24
    const daysInRange = dif / dayTime
    const newDate = moment(day1.dateString, 'YYYY-MM-DD')
    const newDates = []

    for (let i = 1; i < daysInRange; i++) {
      const _newDate = newDate.add(1, 'd')
      newDates.push(_newDate.format('YYYY-MM-DD'))
    }

    return newDates
  }

  markDatesHelper = (dates: Array<DateData>, datesRange: Array<string>) => {
    const { colors } = this.props
    let _markDates = {}

    if (dates.length === 1) {
      _markDates = {
        [dates[0].dateString]: {
          customStyles: {
            container: {
              backgroundColor: colors.background.selection,
              borderRadius: 8,
              width: '80%',
            },
            text: {
              color: colors.text.selection,
            },
          }
        },
      }
    } else if (dates.length === 2) {
      const datesStyle: { [date: string]: MarkedDatesType } = {}
      datesRange.forEach((date) => {
        datesStyle[date] = {
          customStyles: {
            container: {
              backgroundColor: colors.background.selection,
              borderRadius: 0,
              width: '105%',
            },
            text: {
              color: colors.text.selection,
            },
          }
        }
      })

      _markDates = {
        ...datesStyle,
        [dates[0].dateString]: {
          customStyles: {
            container: {
              borderRadius: 0,
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
              width: '100%',
              backgroundColor: colors.background.selection,
            },
            text: {
              color: 'white',
            },
          }
        },
        [dates[1].dateString]: {
          customStyles: {
            container: {
              backgroundColor: colors.background.selection,
              borderRadius: 0,
              borderTopRightRadius: 8,
              borderBottomRightRadius: 8,
              width: '100%',
            },
            text: {
              color: colors.text.selection,
            },
          }
        },
      }
    }

    return _markDates
  }

  onDateSelect = (day: DateData) => {
    const { multiSelection } = this.props
    const { dates, isFromDatePicked, isToDatePicked } = this.state
    const _dates = [...dates]
    let _markDates = {}

    if (!multiSelection || !dates.length) {
      _dates[0] = day
      _markDates = this.markDatesHelper(_dates, [])
    } else {
      // const firstDate = _dates[0].timestamp
      // const selectedDate = day.timestamp

      // if (selectedDate < firstDate) {
      //   if (!_dates[1]) _dates[1] = _dates[0]
      //   _dates[0] = day
      // } else _dates[1] = day
      if (!isFromDatePicked || (isFromDatePicked && isToDatePicked)) {
        _dates[0] = day
        _dates.length = 1
        this.setState({isFromDatePicked: true, isToDatePicked: false})
      } else if (!isToDatePicked && _dates[0].timestamp !== day.timestamp) {
        _dates[1] = day
        this.setState({isFromDatePicked: true, isToDatePicked: true})
      }

      let datesRange: Array<string> = []
      if (_dates.length === 2) {
        if (_dates[0].timestamp > _dates[1].timestamp) {
          _dates.reverse()
        }
        datesRange = this.getDatesRange(_dates[0], _dates[1])
      }
      // const datesRange = this.getDatesRange(_dates[0], _dates[1])
      _markDates = this.markDatesHelper(_dates, datesRange)
    }

    this.setState({ dates: _dates, markDates: _markDates })
  }

  renderButtons() {
    const { onClose, onSelect } = this.props
    const { dates } = this.state
    return (
      <View style={styles.buttons}>
        <Button
          style={styles.buttons_button}
          onPress={() => {
            this.setState({ dates: [], markDates: {} })
            onClose()
          }}
          text={t('cancel')}
          type={'secondary'}
        />
        <View style={styles.buttons_space} />
        <Button
          onPress={() => onSelect(dates.map((date) => date.dateString))}
          style={styles.buttons_button}
          text={t('select')}
        />
      </View>
    )
  }

  render() {
    const { isVisible, onClose, colors } = this.props
    const { markDates, dates } = this.state

    return (
      <Modal
        style={styles.modal}
        isVisible={isVisible}
        onBackdropPress={onClose}
        useNativeDriver
        hideModalContentWhileAnimating={true}
      >
        <View style={[styles.content, { backgroundColor: colors.background.primary }]}>
          <this.renderHeader />
          <Calendar
            style={styles.calendar}
            ref={ref => this.calendarRef = ref}
            current={moment(dates[0]?.dateString).toDate()}
            maxDate={moment().toDate()}
            hideArrows={true}
            renderHeader={() => null}
            onVisibleMonthsChange={this.onVisibleMonthsChange}
            hideExtraDays={false}
            firstDay={1}
            hideDayNames={false}
            enableSwipeMonths={false}
            disableMonthChange={false}
            markingType={MarkingTypes.CUSTOM}
            markedDates={markDates}
            theme={{
              calendarBackground: colors.background.primary,
              textSectionTitleColor: colors.text.default,
              todayTextColor: colors.text.title,
              dayTextColor: colors.text.default,
              textDisabledColor: colors.text.placeholder,
              monthTextColor: colors.text.default,
              textDayFontFamily: 'ProductSans-Regular',
              textMonthFontFamily: 'ProductSans-Bold',
              textDayHeaderFontFamily: 'ProductSans-Regular',
              textDayFontWeight: '400',
              textMonthFontWeight: '700',
              textDayHeaderFontWeight: '400',
              textDayFontSize: 16,
              textMonthFontSize: 16,
              textDayHeaderFontSize: 14,
            }}
            onDayPress={this.onDateSelect}
          />
          {this.renderButtons()}
        </View>
      </Modal>
    )
  }

  renderHeader = () => {
    const { colors } = this.props
    const { month } = this.state;
    const displayMonth = capitalize(moment(month || undefined).format('MMMM YYYY'))
    return (
      <View style={styles.headerContainer}>
        <Icon
          style={styles.headerArrows}
          name={'arrow_double_left'}
          size={16}
          onPress={() => this.onMonthChange(-12)}
        />
        <Icon
          style={styles.headerArrows}
          name={'arrow_left'}
          size={16}
          onPress={() => this.onMonthChange(-1)}
        />
        <Text style={[styles.headerText, { color: colors.text.default }]} >
          {displayMonth}
        </Text>
        <Icon
          style={styles.headerArrows}
          name={'arrow_right'}
          size={16}
          onPress={() => this.onMonthChange(1)}
        />
        <Icon
          style={styles.headerArrows}
          name={'arrow_double_right'}
          size={16}
          onPress={() => this.onMonthChange(12)}
        />
      </View>
    )
  }

  onMonthChange = (value: number) => {
    if (!!this.calendarRef) {
      this.calendarRef.addMonth(value)
    }
  }

  onVisibleMonthsChange = (params: DateData[]) => {
    this.setState({ month: params[0].dateString })
  }
}

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    padding: 0,
    justifyContent: 'center',
    alignItems: 'center',
    width: Platform.OS !== 'web'
      ? 'auto'
      : metrics.screenWidth <= 597 ? 'auto' : 597,
    alignSelf: 'center',
  },
  calendar: {
    height: 320,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 12,
  },
  headerArrows: {
    marginHorizontal: 10,
  },
  headerText: {
    flex: 1,
    textAlign: 'center',
    fontFamily: font('bold'),
    fontSize: 16,
    lineHeight: 19,
  },
  content: {
    width: '100%',
    marginHorizontal: 16,
    borderRadius: 10,
    justifyContent: 'space-between',
    overflow: 'hidden',
  },

  buttons: {
    flexDirection: 'row',
    width: '100%',
    padding: 12,
  },
  buttons_button: {
    flex: 1,
  },
  buttons_space: {
    width: 7,
  },
})

export default function (props: CalendarProps) {

  const { colors } = useTheme()

  return (
    <CalendarCustom
      {...props}
      colors={colors}
    />
  )
}
