import React from 'react'
import {
  Image,
  ImageSourcePropType,
  ImageStyle,
  TouchableOpacity,
  View,
  ViewStyle,
  Platform,
} from "react-native"
import { Icon, IconsType } from "."
import { useTheme } from '../theme'

interface ContentRowProps {
  children: JSX.Element | JSX.Element[]
  image: ImageSourcePropType
  style?: ViewStyle | ViewStyle[]
  borderless?: boolean
  rightIcon?: IconsType
  onPress?: () => void
}

export default function ({
  children,
  image,
  style,
  borderless = false,
  rightIcon,
  onPress,
}: ContentRowProps) {
  const { colors } = useTheme()

  return (
    <TouchableOpacity
      activeOpacity={1}
      style={[
        styles.row,
        borderless
          ? null
          : [
            styles.border,
            {
              borderColor: Platform.OS !== 'web'
                ? colors.border.secondary
                : colors.border.secondaryWeb,
            }
          ],
        style
      ]}
      onPress={onPress}
    >
      <View style={styles.image.container}>
        <Image source={image} style={styles.image.image} resizeMode={'cover'} />
      </View>
      <View style={styles.text.container}>
        {children}
      </View>
      {!!rightIcon && (
        <Icon name={rightIcon} size={16} />
      )}
    </TouchableOpacity>
  )
}

const styles = {
  row: {
    flexDirection: 'row',
    paddingVertical: 16,
    alignItems: 'center',
  } as ViewStyle,
  border: {
    borderBottomWidth: 1,
  } as ViewStyle,
  image: {
    container: {
      width: 40,
      height: 40,
      borderRadius: 20,
      overflow: 'hidden',
    } as ViewStyle,
    image: {
      width: '100%',
      height: '100%',
    } as ImageStyle,
  },
  text: {
    container: {
      marginLeft: 8,
      flex: 1,
    } as ViewStyle,
  },
}
