import React from 'react'
import moment from 'moment'
import 'moment/locale/ru'
moment().locale('ru')
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { FilePreview, Icon, TextInput } from '..'
import { PickedFileType, UploadFileType } from '../../types'
import { t } from '../../localization'
import { useTheme } from '../../theme'
import { filePicker, acceptedUploadedFileTypes } from '../../service'
import config from '../../config'

interface Props {
  files: UploadFileType[]
  message: string
  onDeleteFilePress: (file: UploadFileType) => void
  onMessageChange: (text: string) => void
  onSend: () => void
  onActionPress: () => void
  onAddFiles: (files: PickedFileType[]) => void
  type: 'support' | 'conference'
}

export default function ({
  files,
  message,
  onDeleteFilePress,
  onMessageChange,
  onSend,
  onActionPress,
  onAddFiles,
  type,
}: Props) {

  const { colors } = useTheme()

  const renderActions = () => {
    const file_fetching = !!files.length && files.map((file) => file.fetching).every(Boolean)
    return (<>
      <input
        style={{ width: 1, height: 1, opacity: 0, position: 'absolute', overflow: 'hidden', zIndex: -1 }}
        id={`uploadFile_${type}`}
        type='file'
        multiple
        accept={acceptedUploadedFileTypes(config.chat.fileTypes)}
        onChange={handleFileChange}
        onClick={e => e.target.value = null}
      />
      <TouchableOpacity
        style={[styles.attachContainer, { opacity: file_fetching ? 0.5 : 1 }]}
        onPress={onActionPress}
        disabled={file_fetching}
      >
        <Icon name={'attachment'} color={colors.icon.primary} />
      </TouchableOpacity>
    </>)
  }

  const renderComposer = () => (
    <View style={styles.inputToolbarContainer}>
      <View style={[styles.inputToolbaSeparator, { backgroundColor: colors.background.primary }]} />
      <TextInput
        style={inputStyles.inputToolbaInput}
        textStyle={{ overflowX: 'hidden', height: 'auto' }}
        value={message}
        onChange={onMessageChange}
        smallMultiline
        placeholder={t('your_message')}
        blurOnSubmit={true}
        onSubmitEditing={() => {
          if (message && onSend) {
              onSend({text: message.trim()}, true)
            }
          }
        }
        // autoFocus={true}
      />
    </View>
  )

  const renderSend = () => {
    const file_fetching = !!files.length && !!files.filter(file => file.fetching).length
    const disabled = (!files.length && !message) || file_fetching

    return (
      <TouchableOpacity
        style={[styles.inputToolbarSend, {
          opacity: disabled ? 0.5 : 1,
          backgroundColor: colors.background.selection,
        }]}
        onPress={onSend}
        disabled={disabled}
      >
        <Icon name={'paper_plane'} color={colors.icon.white} />
      </TouchableOpacity>
    )
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { files } = e.target

    if (files?.length) {
      const items: UploadFileType[] | PickedFileType[] | null = filePicker({
        files,
        maxFileSize: config.chat.maxFileSize,
        maxCount: config.chat.maxFiles,
        extensions: config.chat.fileTypes,
        selected: Object.values(files || {}),
      })
      !!items?.length && onAddFiles(items)
    }
  }

  return (
    <View>
      {!!Object.keys(files || {}).length && (
        <View style={[styles.container, {
          backgroundColor: colors.background.primary,
          borderColor: colors.border.secondary,
        }]}>
          {files.map((file) => (
            <FilePreview
              style={styles.file}
              key={`preview-${file.id}`}
              uri={file.url}
              onDelete={() => onDeleteFilePress(file)}
              progress={file.progress}
              fetching={file.fetching}
              type={file.name.substring(file.name.lastIndexOf('.') + 1)}
            />
          ))}
        </View>
      )}
      <View style={[
        styles.inputContainer, {
          borderTopColor: colors.border.secondary,
          backgroundColor: colors.background.primary,
          shadowColor: colors.shadow,
        }
      ]}>
        <View style={[styles.inputContent, { backgroundColor: colors.background.input }]}>
          {renderActions()}
          {renderComposer()}
          {renderSend()}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    borderTopWidth: 1,
    paddingHorizontal: 5,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  file: {
    marginVertical: 8,
    marginHorizontal: 5,
  },
  inputContainer: {
    padding: 12,
  },
  inputContent: {
    padding: 4,
    flexDirection: 'row',
    alignItems: 'flex-end',
    borderRadius: 10,
  },
  attachContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
  },
  inputToolbarContainer: {
    flex: 1,
    flexDirection: 'row',
    maxHeight: 150,
    height: '100%',
    alignItems: 'center',
  },
  inputToolbaSeparator: {
    marginVertical: 15,
    width: 1,
  },
  inputToolbarSend: {
    width: 40,
    height: 40,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  }
})

const inputStyles = {
  inputToolbaInput: {
    borderWidth: 0,
    flex: 1,
    paddingHorizontal: 8,
    paddingVertical: 0,
  },
}
