import React from 'react'
import { Image, ScrollView, StyleSheet, Text, View, Platform } from 'react-native'
import { Button, Markdown, NewAppointmentInfo } from '.'
import { appointmentSuccess } from '../images'
import { t } from '../localization'
import { getFullName } from '../service'
import { font, useTheme } from '../theme'
import { AppointmentType } from "../types"

interface PaymentSuccessModalProps {
  appointment: AppointmentType
  onButtonPress: (params: ModalButtonsType) => void
  serviceDiscountCost?: number
}

export type ModalButtonsType = 'instructions' | 'appointment'

export default function ({
  appointment,
  onButtonPress,
  serviceDiscountCost,
}: PaymentSuccessModalProps) {
  if (!appointment) return null

  const { colors } = useTheme()

  const patientName = getFullName(appointment.patient.lastname, appointment.patient.firstname, appointment.patient.middlename)
  const patientBirthdate = appointment.patient.birthdate
  const serviceImage = appointment.service.image.thumbnail + '?w=40&h=40'
  const serviceName = appointment.service.name
  const serviceCost = !appointment.service.is_pack ? appointment.service.cost : appointment.service.pack_cost
  const serviceCount = !appointment.service.is_pack ? 1 : appointment.service.pack_count
  const doctorName = getFullName(appointment.doctor.lastname, appointment.doctor.firstname, appointment.doctor.middlename)
  const doctorPhoto = appointment.doctor.photo.thumbnail + '?w=40&h=40'
  const datetime = appointment.slot.datetime
  return (
    <>
      <ScrollView
        bounces={false}
        contentContainerStyle={styles.contentContainerStyle}
      >
        <Image
          source={appointmentSuccess}
          style={styles.image}
        />
        <View style={{ marginHorizontal: 16 }}>
          <Text style={[styles.title, { color: colors.text.title }]}>
            {Platform.OS !== 'web' ? t('appointment_succes') : t('appointment_succes_web')}
          </Text>
          <Markdown
            style={styles.notice}
            paragraphStyle={{ textAlign: 'center' }}
            text={Platform.OS !== 'web' ? t('appointment_succes_notice') : t('appointment_succes_notice_web')}
          />
          <NewAppointmentInfo
            patientName={patientName}
            patientBirthdate={patientBirthdate}
            serviceImage={serviceImage}
            serviceName={serviceName}
            serviceCost={serviceCost}
            serviceDiscountCost={serviceDiscountCost}
            serviceCount={serviceCount}
            doctorName={doctorName}
            doctorPhoto={doctorPhoto}
            datetime={datetime}
            isPack={appointment.service.is_pack}
          />
        </View>
        <View style={styles.buttonContainer}>
          <Button
            style={styles.button}
            text={t('instruction')}
            type={'secondary'}
            onPress={() => onButtonPress('instructions')}
          />
          <Button
            style={styles.button}
            text={t('to_appointments')}
            onPress={() => onButtonPress('appointment')}
          />
        </View>
      </ScrollView>
    </>
  )
}

const styles = StyleSheet.create({
  contentContainerStyle: {
    flexGrow: 1,
    justifyContent: 'flex-start',
  },
  buttonContainer: {
    paddingHorizontal: 11,
    paddingVertical: 16,
    flexDirection: 'row',
  },
  button: {
    flex: 1,
    marginHorizontal: 5,
  },
  image: {
    width: 314,
    height: 243,
    alignSelf: 'center',
  },
  title: {
    marginTop: 16,
    fontFamily: font('bold'),
    fontSize: 24,
    lineHeight: 29,
    textAlign: 'center'
  },
  notice: {
    marginTop: 8,
    marginBottom: 16,
    alignItems: 'center',
    textAlign: 'center',
  },
})
