import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import {
  FlatList,
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
} from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'react-native-flash-message'

import { Button, Icon, ListEmptyComponent, Modal, Screen, TextInput } from '../components'
import { t } from '../localization'
import { AppState } from '../store'
import { font, useTheme } from '../theme'
import { Nullable, TrackerType } from '../types'
import {
  trackersRequestAction,
  deleteTrackerRequestAction,
} from '../store/redux/trackers'
import { usePrevious } from '../hooks/usePrevious'
import config from '../config'
import { getUtcOffset } from '../service'

export default function TrackerListScreen() {
  const { colors } = useTheme()
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const trackers: Array<TrackerType> = useSelector((state: AppState) => state.tracker.trackers)
  const deleteTrackerSuccess: boolean = useSelector((state: AppState) => state.tracker.delete_tracker_success)
  const trackersFetching: boolean = useSelector((state: AppState) => state.tracker.trackers_fetching)

  const [isManageTrackerModalVisible, setIsManageTrackerModalVisible] = useState<boolean>(false)
  const [isDeleteTrackerModalVisible, setIsDeleteTrackerModalVisible] = useState<boolean>(false)
  const [selectedTracker, setSelectedTracker] = useState<Nullable<TrackerType>>(null)
  const [deleteFieldValue, setDeleteFieldValue] = useState<string>('')
  const [showErrorText, setShowErrorText] = useState<boolean>(false)
  const [screenData, setScreenData] = useState(Dimensions.get('window'))

  const prevDeleteTrackerSuccess = usePrevious(deleteTrackerSuccess)

  useEffect(() => {
		const onChange = (result) => {
			setScreenData(result.window)
		}

		const subscription = Dimensions.addEventListener('change', onChange)

		return () => subscription?.remove()
	})

  useEffect(() => {
    dispatch(trackersRequestAction({ tz_offset: getUtcOffset() }))
  }, [])

  useEffect(() => {
    if (prevDeleteTrackerSuccess !== null && !prevDeleteTrackerSuccess && deleteTrackerSuccess) {
      setIsDeleteTrackerModalVisible(false)
      dispatch(trackersRequestAction({ tz_offset: getUtcOffset() }))
      showMessage({
        type: 'success',
        message: t('delete_tracker_success'),
        duration: config.api.messageDuration,
      })
    }
  }, [deleteTrackerSuccess])

  const onManageTrackerModalClose = () => {
    setIsManageTrackerModalVisible(false)
  }

  const onDeleteTrackerModalClose = () => {
    setIsDeleteTrackerModalVisible(false)
  }

  const onDeleteTrackerModalConfirm = () => {
    if (deleteFieldValue !== 'DELETE') {
      setShowErrorText(true)
    } else {
      dispatch(deleteTrackerRequestAction({ id: selectedTracker!.id }))
    }
  }

  const onRefresh = () => {
    dispatch(trackersRequestAction({ tz_offset: getUtcOffset() }))
  }

  const renderTrackerListTile = ({ item, index }: { item: TrackerType, index: number }) => {
    return (
      <>
        {item.company_name && (
          <View style={styles.trackerCompanyContainer}>
            <Text style={[styles.trackerCompany, { color: colors.text.title }]}>{item.company_name}</Text>
          </View>
        )}
        <TouchableOpacity
          style={[styles.trackerCard, {
            backgroundColor: colors.background.primary,
            marginBottom: item.company_name ? 16 : 0,
          }]}
          onPress={() => {
            navigation.navigate('TrackerStack', { screen: 'TrackerScreen', params: { id: item.id } })
          }}
        >
          <View style={[styles.trackerIconContainer, { backgroundColor: item.background }]}>
            <Text style={[styles.trackerIcon, { color: colors.text.default }]}>{item.icon}</Text>
          </View>
          <View style={styles.trackerDescriptionContainer}>
            <Text style={[styles.trackerTitle, {
              color: colors.text.subtitle,
            }]}>{item.title}</Text>
            <Text
              style={{color: colors.text.primary}}
              numberOfLines={2}
            >{item.description}</Text>
          </View>
          <View style={styles.trackerTrailing}>
            <Icon
              name={'more'}
              color={colors.icon.inactive}
              onPress={() => {
                setSelectedTracker(item)
                setIsManageTrackerModalVisible(true)}
              }
            />
            <Text style={[styles.trackerPoints, {
              color: item.points > 0 ? colors.text.title : colors.text.subtitle,
            }]}>{item.points}</Text>
          </View>
        </TouchableOpacity>
      </>
    )
  }

  const Divider = () => (
    <View style={[styles.divider, {
      borderBottomColor: colors.divider,
    }]} />
  )

  const renderTitle = () => {
		return (
			<View style={styles.title}>
				<Text style={[styles.titleText, { color: colors.text.title }]}>{t('trackers')}</Text>
			</View>
		)
	}

  const isSmallScreen = screenData.width < 655

  return (
    <Screen>
      <View style={[
        styles.contentWrapper,
        {
          width: !isSmallScreen ? '47.22%' : '100%',
          minWidth: !isSmallScreen ? 635 : 'auto',
          paddingLeft: 20,
        },
      ]}>
        {renderTitle()}
        <View style={styles.container}>
          <FlatList
            style={styles.content}
            bounces={false}
            keyExtractor={(item, index) => `${index}_${item.id}`}
            data={trackers}
            renderItem={renderTrackerListTile}
            ItemSeparatorComponent={() => <View style={styles.separator} />}
            ListEmptyComponent={() => (
              <View style={styles.emptyContainer}>
                <ListEmptyComponent type={'trackers'} />
                <Button
                  style={styles.emptyButton}
                  text={`+ ${t('new_tracker')}`}
                  onPress={() => {
                    navigation.navigate('TrackerStack', { screen: 'AddTrackerScreen' })
                  }}
                />
              </View>
            )}
            onRefresh={onRefresh}
            refreshing={trackersFetching}
          />
          {trackers.length > 0 && (
            <Button
              text={`+ ${t('new_tracker')}`}
              onPress={() => {
                navigation.navigate('TrackerStack', { screen: 'AddTrackerScreen' })
              }}
            />
          )}
        </View>
        <Modal
          isVisible={isManageTrackerModalVisible}
          onClose={onManageTrackerModalClose}
          onModalHide={onManageTrackerModalClose}
          position='center'
        >
          <View style={manageTrackerModalStyles.container}>
            <Text style={[manageTrackerModalStyles.title, { color: colors.text.default }]}>
              {t('tracker')}
            </Text>
            <TouchableOpacity onPress={() => {
              setIsManageTrackerModalVisible(false)
              navigation.navigate('TrackerStack', { screen: 'AddTrackerScreen', params: {
                id: selectedTracker!.id,
                mode: 'edit',
              }})
            }}>
              <View style={manageTrackerModalStyles.tileContainer}>
                <Text style={manageTrackerModalStyles.tileText}>{t('edit')}</Text>
              </View>
            </TouchableOpacity>
            <Divider />
            <TouchableOpacity onPress={() => {
              setIsManageTrackerModalVisible(false)
              setIsDeleteTrackerModalVisible(true)
            }}>
              <View style={manageTrackerModalStyles.tileContainer}>
                <Text style={manageTrackerModalStyles.tileText}>{t('delete')}</Text>
              </View>
            </TouchableOpacity>
          </View>
        </Modal>
        <Modal
          isVisible={isDeleteTrackerModalVisible}
          onClose={onDeleteTrackerModalClose}
          position='center'
          width={375}
        >
          <View style={deleteTrackerModalStyles.container}>
            <Text style={[deleteTrackerModalStyles.title, { color: colors.text.default }]}>
              {t('tracker_deleting')}
            </Text>
            <View>
              <Text style={deleteTrackerModalStyles.description}>
                {t(
                    selectedTracker?.company_name != null
                    ? 'tracker_b2b_deleting_description_start'
                    : 'tracker_deleting_description_start'
                )}
                <Text style={{ color: colors.text.error }}>DELETE</Text>
                {t('tracker_deleting_description_end')}
              </Text>
              <TextInput
                textStyle={{ height: 40 }}
                placeholder={t('delete_placeholder')}
                onChange={(value) => {
                  setShowErrorText(false)
                  setDeleteFieldValue(value)
                }}
                maxLength={10}
                error={showErrorText ? t('delete_field_error') : ''}
              />
              <View style={deleteTrackerModalStyles.buttonContainer}>
                <Button
                  text={t('cancel')}
                  style={deleteTrackerModalStyles.modalDeleteTrackerButton}
                  type={'secondary'}
                  onPress={onDeleteTrackerModalClose}
                />
                <View style={deleteTrackerModalStyles.spacer}/>
                <Button
                  text={t('delete')}
                  type={'warning'}
                  style={deleteTrackerModalStyles.modalDeleteTrackerButton}
                  onPress={onDeleteTrackerModalConfirm}
                  disabled={deleteFieldValue.length === 0}
                />
              </View>
            </View>
          </View>
        </Modal>
      </View>
    </Screen>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 24,
  },
  contentWrapper: {
    marginTop: 40,
    paddingRight: 20,
    alignSelf: 'center',
  },
  content: {
    marginBottom: 12,
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24,
  },
  titleText: {
    fontSize: 24,
    fontFamily: font('bold'),
  },
  separator: {
    height: 8,
  },
  divider: {
    height: 1,
    borderBottomWidth: 1,
  },
  trackerCard: {
    borderRadius: 20,
    padding: 16,
    flexDirection: 'row',
  },
  trackerCompanyContainer: {
    marginBottom: 16,
  },
  trackerCompany: {
    fontFamily: font(),
    fontSize: 18,
  },
  trackerIconContainer: {
    width: 40,
    height: 40,
    marginTop: 5,
    marginRight: 8,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  trackerIcon: {
    fontSize: 20,
  },
  trackerDescriptionContainer: {
    flex: 1,
    marginRight: 8,
  },
  trackerTitle: {
    fontSize: 18,
    marginBottom: 12,
  },
  trackerTrailing: {
    alignItems: 'flex-end',
  },
  trackerPoints: {
    fontSize: 18,
  },
  emptyContainer: {
    alignItems: 'center',
  },
  emptyButton: {
    marginTop: 16,
    width: '100%',
  },
})
const manageTrackerModalStyles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
  title: {
    paddingBottom: 8,
    fontSize: 24,
    fontWeight: 'bold',
  },
  tileContainer: {
    paddingVertical: 18,
  },
  tileText: {
    fontWeight: 'bold',
    fontSize: 16,
  },
})
const deleteTrackerModalStyles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
  title: {
    paddingBottom: 8,
    fontSize: 24,
    fontWeight: 'bold',
  },
  modalDeleteTrackerButton: {
    flex: 1,
  },
  description: {
    marginBottom: 16,
  },
  buttonContainer: {
    marginTop: 24,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  spacer: {
    width: 3,
  },
})