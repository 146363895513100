import React, { useEffect, useState } from 'react'
import {
	ScrollView,
	Text,
	View,
	StyleSheet,
	ViewStyle,
	TextStyle,
	TextInput,
} from 'react-native'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigation, useLinkTo } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'

import { AppState } from '../store'
import {
	Screen,
	Button,
	Markdown,
	RadioButton,
	Logo,
} from '../../components/'
import { authRequestAction } from '../store/redux/auth'
import { t } from '../localization'
import { font, ThemeColors, useTheme } from '../../theme'
import { usePrevious } from '../../hooks/usePrevious'

type ScreenProps = StackScreenProps<any>

interface Props extends ScreenProps {
	colors: ThemeColors
}

function AuthScreen() {
	const { colors } = useTheme()
	const navigation = useNavigation()
	const dispatch = useDispatch()
	const linkTo = useLinkTo()

	const fetching = useSelector((state: AppState) => state.auth.fetching)
	const error = useSelector((state: AppState) => state.auth.error)
	const documents = useSelector((state: AppState) => state.app.documents)

	const [email, setEmail] = useState('')
	const [checked, setChecked] = useState(false)

	const prevFetching = usePrevious(fetching)

	useEffect(() => {
		if (prevFetching && !fetching && !error) {
			setEmail('')
			setChecked(false)
			linkTo('/auth/code-confirmation')
		}
	}, [fetching])

	const onCheckBoxChange = (checked: any) => {
		setChecked(checked)
	}

	const onEmailChange = (email: string) => {
		setEmail(email)
	}

	const onSendEmailPress = () => {
		dispatch(authRequestAction({ email }))
	}

	const onLinkPress = (url: string) => {
		const document = documents && documents.find((document: { type: string }) => document.type === url)
		if (!!document) {
			navigation.navigate('DocumentScreen', { document, type: document.type, title: document.title })
		}
	}

	const disabled = fetching || !checked
	const buttonText = t('next')

	return (
		<Screen fetching={fetching} hideHeader={true}>
			<ScrollView contentContainerStyle={styles.container} bounces={false}>
				<Logo style={styles.logo} clickable={false} />
				<View style={styles.wrapper}>
					<View style={styles.form}>
						<Text style={[styles.auth, { color: colors.text.title }]}>
							{t('auth_web')}
						</Text>
						<Text style={[styles.subtitle, { color: colors.text.secondary }]}>
							{t('auth_text')}
						</Text>
						<TextInput
							style={[styles.emailInput, {
								color: colors.text.default,
								backgroundColor: colors.background.primary,
							}]}
							keyboardType={'email-address'}
							value={email}
							onChangeText={onEmailChange}
							placeholder={t('email')}
							placeholderTextColor={colors.text.placeholder}
						/>
						<Button
							style={styles.button}
							text={buttonText}
							onPress={onSendEmailPress}
							disabled={disabled}
							fetching={fetching}
						/>
					</View>
					<View style={styles.agreementContainer}>
						<RadioButton
							checked={checked}
							type={'checkBox'}
							onChange={onCheckBoxChange}
						/>
						<Markdown
							style={styles.agreementMarkdown}
							text={t('auth_agreement', [buttonText])}
							onLinkPress={onLinkPress}
							paragraphStyle={[styles.agreementText, { color: colors.text.secondary }]}
							linkStyle={styles.agreementLink}
						/>
					</View>
				</View>
				<View />
			</ScrollView>
		</Screen>
	)
}

export default AuthScreen

const styles = StyleSheet.create({
	container: {
		flexGrow: 1,
		justifyContent: 'space-between',
		alignItems: 'center',
	} as ViewStyle,
	wrapper: {
		width: '33%',
		minWidth: 420,
		paddingHorizontal: 35,
		marginBottom: 40,
	},
	form: {
		textAlign: 'center',
	} as ViewStyle,
	button: {
		marginTop: 16,
	} as ViewStyle,
	emailInput: {
		height: 46,
		borderRadius: 10,
		marginTop: 16,
		paddingHorizontal: 15,
		fontFamily: font(),
		fontSize: 16,
		lineHeight: 19,
		paddingTop: 0,
		paddingBottom: 0,
	},
	auth: {
		fontFamily: font(),
		fontSize: 36,
		lineHeight: 29,
		marginBottom: 12,
	} as TextStyle,
	subtitle: {
		fontFamily: font(),
		fontSize: 16,
		lineHeight: 20,
	} as TextStyle,
	agreementContainer: {
		marginTop: 16,
		flexDirection: 'row',
	} as ViewStyle,
	agreementMarkdown: {
		marginLeft: 8,
		flex: 1,
	} as ViewStyle,
	agreementText: {
		marginTop: 0,
		fontFamily: font(),
		fontSize: 14,
		lineHeight: 17,
	} as TextStyle,
	agreementLink: {
		fontFamily: font('bold'),
	} as TextStyle,
	logo: {
		marginTop: 40,
	},
})
