import { put, call, delay, select } from 'redux-saga/effects'
import { api, ApiSuccessResponse } from '../../api'
import {
	Action,
	UserProfileRequest,
	UserProfileSuccessResponse,
	UserProfileUpdateRequest,
} from '../../types';
import { ApiResponse } from 'apisauce';
import {
	userProfileFailureAction,
	userProfileSuccessAction,
	userProfileUpdateFailureAction,
	userProfileUpdateRequestAction,
	userProfileUpdateSuccessAction,
	userProfileDeleteFailureAction,
} from '../redux/user';
import { appInitializedAction } from '../redux/_app';
import { getUtcOffset } from '../../service';
import { authLogoutSuccessAction } from '../redux/auth';

export function* userProfileRequestSaga(action: Action<UserProfileRequest>) {
	const response: ApiResponse<ApiSuccessResponse<UserProfileSuccessResponse>> =
		yield call(api.userProfileRequest, action.params)

	if (response.ok) {
		yield put(userProfileSuccessAction(response.data.result))
		if (!!response.data?.result?.profile?.registered_at) {
			const {
				registered_at,
				firstname,
				lastname,
				middlename,
				birthdate,
				gender,
				email,
				phone,
			} = response.data.result.profile
			const profile: UserProfileUpdateRequest = {
				registered_at,
				firstname,
				lastname,
				middlename,
				birthdate,
				gender,
				email: !!email ? email : undefined,
				phone: !!phone ? phone : undefined,
				tz_offset: getUtcOffset(),
			}
			yield put(userProfileUpdateRequestAction(profile))
		}
	} else {
		yield put(userProfileFailureAction(response.data))
	}
	yield delay(500)
	yield put(appInitializedAction())
}

export function* userProfileUpdateSaga(action: Action<UserProfileUpdateRequest>) {
	const response: ApiResponse<ApiSuccessResponse<UserProfileSuccessResponse>> =
		yield call(api.userProfileUpdateRequest, action.params)

	if (response.ok) {
		yield put(userProfileUpdateSuccessAction(response.data.result))
	} else {
		yield put(userProfileUpdateFailureAction(response.data))
	}
}

export function* userProfileDeleteSaga() {
	const response = yield call(api.userProfileDeleteRequest)
	if (response.ok) {
		yield put(authLogoutSuccessAction())
	} else {
		yield put(userProfileDeleteFailureAction(response.data))
	}
}