import React, { Component, ReactNode } from 'react'

interface Props {
  duration: number
  onDone: () => void
  onRender: (value: number) => ReactNode
}

interface State {
  timer: number
  timeout: number
}

class Timer extends Component<Props, State> {
  private timeout: any = undefined

  constructor(props: Props) {
    super(props)
    this.state = {
      timeout: 0,
      timer: 0,
    }
  }

  componentDidMount() {
    const duration = this.props.duration || 0
    this.setState(
      {
        timeout: ((new Date().getTime() / 1000) | 0) + duration,
        timer: duration,
      },
      this.tick
    )
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  tick() {
    const { onDone = () => { } } = this.props
    const { timer, timeout } = this.state
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    if (timer > 0) {
      this.timeout = setTimeout(() => {
        this.setState({ timer: timeout - ((new Date().getTime() / 1000) | 0) }, this.tick)
      }, 1000)
    } else {
      onDone()
    }
  }

  render() {
    const { onRender } = this.props
    const { timer } = this.state
    return onRender(timer)
  }

  restart = () => {
    this.componentDidMount()
  }
}

export default Timer
