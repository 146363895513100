import React, { useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Button, Modal, TextInput } from '.'
import { t } from '../localization'
import { font, useTheme } from '../theme'
import { Nullable } from '../types'

interface PromocodeModalProps {
  isVisible?: boolean
  onClose: () => void
  isPromocodeSuccess: Nullable<boolean>
  onPromocodeApplyPress: (promocode: string) => void
  promocodeErrorText?: string
  position?: 'bottom' | 'center'
}

export default function ({
  isVisible,
  onClose,
  onPromocodeApplyPress,
  isPromocodeSuccess,
  promocodeErrorText = t('promocode_failure'),
  position = 'bottom'
}: PromocodeModalProps) {
  const { colors } = useTheme()

  const [promocode, setPromocode] = useState<string>('')
  const [isPromoSend, setIsPromoSend] = useState<boolean>(false)
  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      header={t('promocode')}
      position={position}
    >
      <View style={styles.content}>
        <TextInput
          value={promocode}
          onChange={promocode => {
            setPromocode(promocode)
            setIsPromoSend(false)
          }}
          placeholder={t('enter_promocode')}
        />
        {(isPromoSend && isPromocodeSuccess !== null) && (
          <Text style={[styles.text, { color: isPromocodeSuccess ? colors.text.success : colors.text.error }]}>
            {isPromocodeSuccess ? t('promocode_success') : promocodeErrorText}
          </Text>
        )}
        <Button
          style={styles.applyButton}
          text={t('apply')}
          onPress={() => {
            onPromocodeApplyPress(promocode)
            setIsPromoSend(true)
          }}
          disabled={isPromoSend || !promocode.length}
        />
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: 16,
  },
  applyButton: {
    marginTop: 20,
  },
  text: {
    marginTop: 4,
    fontFamily: font(),
    fontSize: 16,
    lineHeight: 19,
  }
})
