import {
	ApiFailure,
	ApiFailureResponse,
	Nullable,
	QuestionSuccessResponse,
	QuestionType,
	QuestionRequest,
	QuestionByIdRequest,
	AskQuestionRequest,
} from '../../types'

export interface QuestionState {
	questions: Nullable<QuestionType[]>
	fetching: boolean
	error: Nullable<ApiFailure>
	page: Nullable<number>
	limit: Nullable<number>
	total: Nullable<number>
	userQuestions: Nullable<QuestionType[]>
	userQuestionsFetching: boolean
	userQuestionsError: Nullable<ApiFailure>
	userQuestionsPage: Nullable<number>
	userQuestionsLimit: Nullable<number>
	userQuestionsTotal: Nullable<number>
	questionById: Nullable<QuestionType>
	questionByIdFetching: boolean
	questionByIdError: Nullable<ApiFailure>
	askQuestion: any
	askQuestionFetching: boolean
	askQuestionError: Nullable<ApiFailure>
}

export const QUESTION_REQUEST = 'QUESTION_REQUEST'
export const QUESTION_SUCCESS = 'QUESTION_SUCCESS'
export const QUESTION_FAILURE = 'QUESTION_FAILURE'
export const USER_QUESTIONS_REQUEST = 'USER_QUESTIONS_REQUEST'
export const USER_QUESTIONS_SUCCESS = 'USER_QUESTIONS_SUCCESS'
export const USER_QUESTIONS_FAILURE = 'USER_QUESTIONS_FAILURE'
export const QUESTION_BY_ID_REQUEST = 'QUESTION_BY_ID_REQUEST'
export const QUESTION_BY_ID_SUCCESS = 'QUESTION_BY_ID_SUCCESS'
export const QUESTION_BY_ID_FAILURE = 'QUESTION_BY_ID_FAILURE'
export const ASK_QUESTION_REQUEST = 'ASK_QUESTION_REQUEST'
export const ASK_QUESTION_SUCCESS = 'ASK_QUESTION_SUCCESS'
export const ASK_QUESTION_FAILURE = 'ASK_QUESTION_FAILURE'

export const questionRequestAction = (params?: QuestionRequest) =>
	({ type: QUESTION_REQUEST, params })
export const questionSuccessAction = (payload: QuestionSuccessResponse) =>
	({ type: QUESTION_SUCCESS, payload })
export const questionFailureAction = (payload: ApiFailureResponse) =>
	({ type: QUESTION_FAILURE, payload })

export const userQuestionsRequestAction = (params?: QuestionRequest) =>
	({ type: USER_QUESTIONS_REQUEST, params })
export const userQuestionsSuccessAction = (payload: QuestionSuccessResponse) =>
	({ type: USER_QUESTIONS_SUCCESS, payload })
export const userQuestionsFailureAction = (payload: ApiFailureResponse) =>
	({ type: USER_QUESTIONS_FAILURE, payload })

export const questionByIdRequestAction = (params?: QuestionByIdRequest) =>
	({ type: QUESTION_BY_ID_REQUEST, params })
export const questionByIdSuccessAction = (payload: QuestionSuccessResponse) =>
	({ type: QUESTION_BY_ID_SUCCESS, payload })
export const questionByIdFailureAction = (payload: ApiFailureResponse) =>
	({ type: QUESTION_BY_ID_FAILURE, payload })

export const askQuestionRequestAction = (params?: AskQuestionRequest) =>
	({ type: ASK_QUESTION_REQUEST, params })
export const askQuestionSuccessAction = (payload: QuestionSuccessResponse) =>
	({ type: ASK_QUESTION_SUCCESS, payload })
export const askQuestionFailureAction = (payload: ApiFailureResponse) =>
	({ type: ASK_QUESTION_FAILURE, payload })

const initialState: QuestionState = {
	questions: null,
	fetching: false,
	error: null,
	page: null,
	limit: null,
	total: null,
	userQuestions: null,
	userQuestionsFetching: false,
	userQuestionsError: null,
	userQuestionsPage: null,
	userQuestionsLimit: null,
	userQuestionsTotal: null,
	questionById: null,
	questionByIdFetching: false,
	questionByIdError: null,
	askQuestion: null,
	askQuestionFetching: false,
	askQuestionError: null,
}

const QuestionReducer = (state = initialState, action: any): QuestionState => {
	const { type, payload } = action
	const error = payload?.error

	switch (type) {
		case QUESTION_REQUEST: {
			return { ...state, error: null, fetching: true }
		}
		case QUESTION_SUCCESS: {
			const { questions, page, limit, total } = payload as QuestionSuccessResponse
			const _questions = page === 1 ? questions : [...(state.questions || []), ...questions]
			return { ...state, fetching: false, questions: _questions, page, limit, total }
		}
		case QUESTION_FAILURE: {
			return { ...state, fetching: false, error }
		}
		case USER_QUESTIONS_REQUEST: {
			return { ...state, userQuestionsError: null, userQuestionsFetching: true }
		}
		case USER_QUESTIONS_SUCCESS: {
			const { questions, page, limit, total } = payload as QuestionSuccessResponse
			const _questions = page === 1 ? questions : [...(state.userQuestions || []), ...questions]
			return {
				...state,
				userQuestionsFetching: false,
				userQuestions: _questions,
				userQuestionsPage: page,
				userQuestionsLimit: limit,
				userQuestionsTotal: total,
			}
		}
		case USER_QUESTIONS_FAILURE:
			return { ...state, userQuestionsFetching: false, userQuestionsError: error }
		case QUESTION_BY_ID_REQUEST:
			return { ...state, questionByIdError: null, questionByIdFetching: true }
		case QUESTION_BY_ID_SUCCESS:
			return { ...state, questionByIdFetching: false, questionById: payload }
		case QUESTION_BY_ID_FAILURE:
			return { ...state, questionByIdFetching: false, questionByIdError: error }
		case ASK_QUESTION_REQUEST:
			return { ...state, askQuestionError: null, askQuestionFetching: true, askQuestion: null }
		case ASK_QUESTION_SUCCESS:
			return { ...state, askQuestionFetching: false, askQuestion: payload }
		case ASK_QUESTION_FAILURE:
			return { ...state, askQuestionFetching: false, askQuestionError: error }
		default:
			return state
	}
}

export default QuestionReducer
