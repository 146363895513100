import React from 'react'
import { StyleSheet, Text, View, ViewStyle, Platform, TouchableWithoutFeedback } from 'react-native'

import { Icon } from '../'
import { useTheme, font } from '../../theme'
import { t } from '../../localization'

interface ControllerProps {
  style?: ViewStyle | ViewStyle[],
  toggleCamera?: () => void
  toggleMicrophone: () => void
  toggleVideo: () => void
  onEndCallPress: () => void
  isFrontCamera: boolean
  isVideoActive: boolean
  isAudioActive: boolean
}

export default function ({
  style,
  toggleCamera,
  toggleMicrophone,
  toggleVideo,
  onEndCallPress,
  isFrontCamera = false,
  isVideoActive,
  isAudioActive,
}: ControllerProps) {
  const { colors } = useTheme()
  const iconSize = Platform.OS !== 'web' ? 60 : 40

  const iconActiveColor = colors.icon.videoController.active.color
  const iconActiveBg = colors.icon.videoController.active.background
  const iconInactiveColor = colors.icon.videoController.inactive.color
  const iconInactiveBg = colors.icon.videoController.inactive.background

  return (
    <View style={[styles.container, { backgroundColor: colors.background.conference }, style]}>
      { Platform.OS !== 'web' && (
        <View style={styles.iconButtonContainer}>
          <Icon
            name='toggle_camera'
            size={iconSize}
            color={isFrontCamera ? iconActiveColor : iconInactiveColor}
            backgroundColor={isFrontCamera ? iconActiveBg : iconInactiveBg}
            onPress={toggleCamera}
          />
          <Text style={[styles.iconText, { color: colors.text.selection }]}>
            {t('turn')}
          </Text>
        </View>
      )}
      <View style={styles.iconButtonContainer}>
        <Icon
          name={isAudioActive ? 'microphone_active' : 'microphone_muted'}
          size={iconSize}
          color={isAudioActive ? iconInactiveColor : iconActiveColor}
          backgroundColor={isAudioActive ? iconInactiveBg : iconActiveBg}
          onPress={toggleMicrophone}
        />
        <Text style={[styles.iconText, { color: colors.text.selection }]}>
          {isAudioActive ? t('microphone_active') : t('microphone_muted')}
        </Text>
      </View>
      <View style={styles.iconButtonContainer}>
        <Icon
          name={isVideoActive ? 'camera_on' : 'camera_off'}
          size={iconSize}
          color={isVideoActive ? iconInactiveColor : iconActiveColor}
          backgroundColor={isVideoActive ? iconInactiveBg : iconActiveBg}
          onPress={toggleVideo}
        />
        <Text style={[styles.iconText, { color: colors.text.selection }]}>
          {isVideoActive ? t('camera_on') : t('camera_off')}
        </Text>
      </View>
      <View style={[styles.iconButtonContainer, { marginRight: 0 }]}>
        <Icon
          name='decline_call'
          onPress={onEndCallPress}
          size={iconSize}
          color={colors.icon.videoController.decline.color}
          backgroundColor={colors.icon.videoController.decline.background}
        />
        <Text style={[styles.iconText, { color: colors.text.selection }]}>
          {t('complete')}
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingHorizontal: Platform.OS !== 'web' ? 12 : 20,
    paddingVertical: Platform.OS !== 'web' ? 12 : 10,
    justifyContent: 'space-between',
  },
  iconButtonContainer: {
    width: 57,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconText: {
    fontFamily: font(),
    fontSize: Platform.OS !== 'web' ? 12 : 10,
    marginTop: 4,
  },
})
