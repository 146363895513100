import React, { useEffect, useState } from 'react'
import {
	ScrollView,
	Text,
	View,
	ViewStyle,
	TextStyle,
	TouchableWithoutFeedback,
} from 'react-native'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigation, useLinkTo } from '@react-navigation/native'

import { AppState } from '../store'
import { StackScreenProps } from '@react-navigation/stack'
import {
	Screen,
	PhoneInput,
	Button,
	Markdown,
	RadioButton,
	Logo,
	AuthFooter,
} from '../components/'
import { authRequestAction, authSetCountryAction } from '../store/redux/auth'
import { t } from '../localization'
import { font, ThemeColors, useTheme } from '../theme'
import { usePrevious } from '../hooks/usePrevious'
import { PhoneCountry } from '../types'
import { countries } from '../service'

type ScreenProps = StackScreenProps<any>

interface Props extends ScreenProps {
	colors: ThemeColors
}

function AuthScreen(props: Props) {
	const { colors } = useTheme()
	const navigation = useNavigation()
	const dispatch = useDispatch()
	const linkTo = useLinkTo()

	const fetching = useSelector((state: AppState) => state.auth.fetching)
	const error = useSelector((state: AppState) => state.auth.error)
	const documents = useSelector((state: AppState) => state.app.documents)

	const [phone, setPhone] = useState('')
	const [country, setCountry] = useState<PhoneCountry>(countries[0])
	const [checked, setChecked] = useState(false)

	const prevFetching = usePrevious(fetching)

	useEffect(() => {
		if (prevFetching && !fetching && !error) {
			setPhone('')
			setCountry(countries[0])
			setChecked(false)
			linkTo('/auth/code-confirmation')
		}
	}, [fetching])

	const onCheckBoxChange = (checked: any) => {
		setChecked(checked)
	}

	const onPhoneChange = ({ phone, country }: { phone: string, country: PhoneCountry }) => {
		setPhone(phone)
		setCountry(country)
	}

	const onSendPhonePress = () => {
		dispatch(authSetCountryAction({ country }))
		dispatch(authRequestAction({ phone: `${country.code}${phone}`, call: true }))
	}

	const onLinkPress = (url: string) => {
		const document = documents && documents.find(document => document.type === url)
		if (!!document) {
			navigation.navigate('DocumentScreen', { document, type: document.type, title: document.title })
		}
	}

	const minLength = [998, 375].includes(country?.code) ? 9 : 10
	const disabled = (phone || '').length < minLength || fetching || !checked
	const buttonText = t('next')

	return (
		<Screen fetching={fetching} hideHeader={true}>
			<ScrollView contentContainerStyle={styles.content.container} bounces={false}>
				<Logo style={styles.logo} clickable={false} />
				<View style={styles.content.wrapper}>
					<View style={styles.content.form}>
						<Text style={[styles.auth, { color: colors.text.title }]}>
							{t('auth_web')}
						</Text>
						<Text style={[styles.subtitle, { color: colors.text.secondary }]}>
							{t('auth_text')}
						</Text>
						<PhoneInput
							style={styles.content.phoneInput}
							type={'auth_phone'}
							value={phone}
							onChange={onPhoneChange}
							placeholder={t('phone_number')}
						/>
						<Button
							style={styles.content.button}
							text={buttonText}
							onPress={onSendPhonePress}
							disabled={disabled}
							fetching={fetching}
						/>
						<View style={{ marginTop: 8, alignItems: 'center', cursor: 'pointer' }}>
							<TouchableWithoutFeedback onPress={() => {
								setPhone('')
								setCountry(countries[0])
								setChecked(false)
								navigation.navigate('AuthStack', { screen: 'AuthByEmailScreen' })
							}}>
								<Text style={{ color: colors.text.title, fontFamily: font('bold') }}>
									{t('auth_email')}
								</Text>
							</TouchableWithoutFeedback>
						</View>
					</View>
					<View style={styles.agreement.container}>
						<RadioButton
							checked={checked}
							type={'checkBox'}
							onChange={onCheckBoxChange}
						/>
						<Markdown
							style={styles.agreement.markdown}
							text={t('auth_agreement', [buttonText])}
							onLinkPress={onLinkPress}
							paragraphStyle={[styles.agreement.text, { color: colors.text.secondary }]}
							linkStyle={styles.agreement.link}
						/>
					</View>
				</View>
				<AuthFooter />
			</ScrollView>
		</Screen>
	)
}

export default AuthScreen

const styles = {
	content: {
		container: {
			flexGrow: 1,
			justifyContent: 'space-between',
			alignItems: 'center',
		} as ViewStyle,
		wrapper: {
			width: '33%',
			minWidth: 420,
			paddingHorizontal: 35,
			marginBottom: 40,
		},
		form: {
			textAlign: 'center',
		} as ViewStyle,
		button: {
			marginTop: 16,
		} as ViewStyle,
		phoneInput: {
			marginTop: 16,
		} as ViewStyle,
	},
	auth: {
		fontFamily: font(),
		fontSize: 36,
		lineHeight: 29,
		marginBottom: 12,
	} as TextStyle,
	subtitle: {
		fontFamily: font(),
		fontSize: 16,
		lineHeight: 20,
	} as TextStyle,
	agreement: {
		container: {
			marginTop: 16,
			flexDirection: 'row',
		} as ViewStyle,
		markdown: {
			marginLeft: 8,
			flex: 1,
		} as ViewStyle,
		text: {
			marginTop: 0,
			fontFamily: font(),
			fontSize: 14,
			lineHeight: 17,
		} as TextStyle,
		link: {
			fontFamily: font('bold'),
		} as TextStyle,
	},
	logo: {
		marginTop: 40,
	},
}
