import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { AppState } from '../store/redux'
import { StackScreenProps } from '@react-navigation/stack'
import { ScrollView, Text, TextStyle, View, ViewStyle, Dimensions } from 'react-native'
import { Button, Screen, TextInput, ClientForm } from '../components'
import { t } from '../localization'
import { AppointmentNavigatorProps, Nullable } from '../types'
import { font, ThemeColors, useTheme } from '../theme'
import {
	appointmentNewAddData,
	appointmentNewResetAction,
	appointmentPatientRequestAction,
} from '../store/redux/appointments'
import { checkClientData } from '../service'

type ScreenProps = DispatchProps & StateProps & StackScreenProps<AppointmentNavigatorProps, "NewAppointmentScreen">

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

interface Props extends ScreenProps {
	colors: ThemeColors,
	isSmallScreen: boolean,
}

interface State {
	lastname: Nullable<string>
	firstname: Nullable<string>
	middlename: Nullable<string>
	birthdate: Nullable<string>
	gender: Nullable<0 | 1>
	comment: string
}

function NewAppointmentScreenWrapper(props: Props) {
	const { colors } = useTheme()
	const [screenData, setScreenData] = useState(Dimensions.get('window'))

  useEffect(() => {
    const onChange = (result) => {
      setScreenData(result.window)
    }

    const subscription = Dimensions.addEventListener('change', onChange)

		return () => subscription?.remove()
  })

	return <NewAppointmentScreen colors={colors} isSmallScreen={screenData.width < 655} {...props} />
}

class NewAppointmentScreen extends React.Component<Props, State>{
	constructor(props: Props) {
		super(props)
		this.state = {
			lastname: null,
			firstname: null,
			middlename: null,
			birthdate: null,
			gender: null,
			comment: props.comment,
		}
	}

	componentDidMount() {
		const {
			user,
			route,
			setAppointmentData,
			navigation,
			doctor_gender_list,
			resetAppointment,
		} = this.props
		const serviceId = +route.params.serviceId || {}

		// resetAppointment(true)

		if (!serviceId || !doctor_gender_list.length) {
			navigation.replace('MainStack', { screen: 'MainScreen' })
		} else {
			// @ts-ignore
			setAppointmentData({ serviceId })
			const { lastname, firstname, middlename, birthdate, gender } = user
			this.setState({ lastname, firstname, middlename, birthdate, gender })
		}
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		const { patient_fetching, patient_error, route, setAppointmentData } = this.props
		if (prevProps.patient_fetching && !patient_fetching && !patient_error) {
			const { navigation, doctor_gender_list } = this.props
			const { firstname, lastname, middlename, birthdate, gender, comment } = this.state
			const data: any = {
				client: { firstname, lastname, middlename, birthdate, gender },
				comment,
			}
			if (doctor_gender_list.length === 1) {
				data.doctorGender = doctor_gender_list[0]
			}
			setAppointmentData(data)
			if (data.doctorGender !== undefined) {
				const { serviceId } = this.props.route.params
				navigation.navigate('NewAppointmentDatetimeScreen', { service_id: serviceId, gender: data.doctorGender })
			} else {
				navigation.navigate('NewAppointmentGenderScreen', {})
			}
		}

		if (prevProps.route.params.serviceId !== route.params.serviceId && route.params.serviceId) {
			setAppointmentData({ serviceId: route.params.serviceId })
		}
	}

	// componentWillUnmount() {
	// 	this.props.resetAppointment(true)
	// }

	render() {
		const {
			lastname,
			firstname,
			middlename,
			birthdate,
			gender,
			comment,
		} = this.state
		const { colors, isSmallScreen } = this.props

		return (
			<Screen>
				<ScrollView
					bounces={false}
					contentContainerStyle={styles.contentContainer}
				>
					<View style={[
						styles.contentWrapper,
						{
							width: !isSmallScreen ? '55%' : '100%',
							minWidth: !isSmallScreen ? 655 : 'auto',
						},
					]}>
						<Header />
						<ClientForm
							type={'client'}
							lastname={lastname}
							firstname={firstname}
							middlename={middlename}
							birthdate={birthdate}
							onChange={this.onChange}
							gender={gender}
							minAge={15}
						/>
						<View style={[styles.comment.container, { backgroundColor: colors.background.primary }]}>
							<Text style={[styles.comment.title, { color: colors.text.subtitle }]}>
								{t('service_subject')}
							</Text>
							<Text style={[styles.comment.description, { color: colors.text.primary }]}>
								{t('service_subject_description')}
							</Text>
							<TextInput
								style={styles.comment.input}
								value={comment}
								onChange={comment => this.onChange({ comment })}
								multiline
								numberOfLines={4}
							/>
						</View>
						<Button style={styles.nextBtn} text={t('next')} onPress={this.onNextPress} />
					</View>
				</ScrollView>
			</Screen>
		)
	}

	onChange = (params: { [key: string]: string | boolean }) => {
		// @ts-ignore
		this.setState({ ...params })
	}

	onNextPress = () => {
		const { checkPatient } = this.props
		const {
			firstname,
			lastname,
			gender,
			middlename,
			birthdate,
		} = this.state
		const isValid = checkClientData({
			lastname,
			firstname,
			birthdate,
			gender,
		}, false)
		if (isValid) {
			checkPatient({
				id: undefined,
				firstname,
				lastname,
				gender,
				middlename,
				birthdate,
			})
		}
	}

}

const mapStateToProps = (state: AppState) => ({
	user: state.user.profile,
	doctor_gender_list: state.appointment.doctor_gender_list || [],
	patient_fetching: state.appointment.patient_fetching,
	patient_error: state.appointment.patient_error,
	comment: state.appointment.new_appointment?.comment || ''
})

const mapDispatchToProps = {
	setAppointmentData: appointmentNewAddData,
	checkPatient: appointmentPatientRequestAction,
	resetAppointment: appointmentNewResetAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewAppointmentScreenWrapper)

function Header() {

	const { colors } = useTheme()

	return (
		<View style={styles.title}>
			<Text style={[styles.titleText, { color: colors.text.title }]}>{t('service_appointment')}</Text>
		</View>
	)
}

const styles = {
	contentContainer: {
		flexGrow: 1,
	} as ViewStyle,
	contentWrapper: {
		flexGrow: 1,
		marginVertical: 40,
		paddingHorizontal: 30,
		alignSelf: 'center',
	},
	title: {
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: 24,
	},
	titleText: {
		fontSize: 24,
		fontFamily: font('bold'),
	} as TextStyle,
	comment: {
		container: {
			marginTop: 12,
			borderRadius: 20,
			overflow: 'hidden',
			paddingVertical: 16,
			paddingHorizontal: 12,
		} as ViewStyle,
		title: {
			fontFamily: font(),
			fontSize: 20,
			lineHeight: 24,
		} as TextStyle,
		description: {
			marginTop: 8,
			fontFamily: font(),
			fontSize: 14,
			lineHeight: 16,
		} as TextStyle,
		input: {
			marginTop: 16,
		} as ViewStyle,
	},
	nextBtn: {
		marginTop: 16,
	} as ViewStyle,
}
