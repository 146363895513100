import { put, call, delay } from 'redux-saga/effects'
import { ApiResponse } from 'apisauce';
import { api, ApiSuccessResponse } from '../../api'
import {
	Action,
	TestsRequest,
	TestsCategoriesRequest,
	TestRequest,
	TestsSuccessResponse,
	TestsCategoriesSuccessResponse,
	TestSuccessResponse,
	TestResultRequest,
	TestResultSuccessResponse,
} from '../../types';
import {
	testsSuccessAction,
	testsFailureAction,
	testsCategoriesSuccessAction,
	testsCategoriesFailureAction,
	testSuccessAction,
	testFailureAction,
	testResultSuccessAction,
	testResultFailureAction,
} from '../redux/tests';

export function* testsRequestSaga(action: Action<TestsRequest>) {
	const response: ApiResponse<ApiSuccessResponse<TestsSuccessResponse>> = yield call(api.testsRequest, action.params)
	if (response.ok) {
		yield put(testsSuccessAction(response.data?.result))
	} else {
		yield put(testsFailureAction(response.data))
	}
}

export function* testsCategoriesRequestSaga(action: Action<TestsCategoriesRequest>) {
	const response: ApiResponse<ApiSuccessResponse<TestsCategoriesSuccessResponse>> = yield call(api.testsCategoriesRequest, action.params)
	if (response.ok) {
		yield put(testsCategoriesSuccessAction(response.data?.result))
	} else {
		yield put(testsCategoriesFailureAction(response.data))
	}
}

export function* testRequestSaga(action: Action<TestRequest>) {
	const response: ApiResponse<ApiSuccessResponse<TestSuccessResponse>> = yield call(api.testRequest, action.params)
	if (response.ok) {
		yield put(testSuccessAction(response.data?.result))
	} else {
		yield put(testFailureAction(response.data))
	}
}

export function* testResultRequestSaga(action: Action<TestResultRequest>) {
	const response: ApiResponse<ApiSuccessResponse<TestResultSuccessResponse>> = yield call(api.testResultRequest, action.params)
	if (response.ok) {
		yield put(testResultSuccessAction(response.data?.result.test_result))
	} else {
		yield put(testResultFailureAction(response.data))
	}
}