import { put, call, delay, select } from 'redux-saga/effects'
import { ApiResponse } from 'apisauce';
import { api, ApiSuccessResponse } from '../../api'
import {
	Action,
	ChatMessagesRequest,
	ChatMessageSuccessResponse,
	ChatSendMessageRequest,
	ChatSendMessageSuccessResponse,
	ChatSuccessResponse,
	FileDeleteResponse,
	FileUploadResponse,
	ChatFilesUploadActionParams,
} from '../../types';
import {
	conferenceChatDeleteFileSuccessAction,
	conferenceChatFailureAction,
	conferenceChatFilesUploadSetAction,
	conferenceChatMessageFailureAction,
	conferenceChatMessageRequestAction,
	conferenceChatMessageSuccessAction,
	conferenceChatSendMessageFailureAction,
	conferenceChatSendMessageSuccessAction,
	conferenceChatSuccessAction,
} from '../redux/conference';

export function* conferenceChatRequestSaga(action: Action<number>) {
	const response: ApiResponse<ApiSuccessResponse<ChatSuccessResponse>> = yield call(api.conferenceChatRequest, action.params)
	if (response.ok) {
		const id = response.data.result.chat.id
		yield put(conferenceChatSuccessAction(response.data.result))
		yield put(conferenceChatMessageRequestAction({ id, page: 1 }))
	} else {
		yield put(conferenceChatFailureAction(response.data))
	}
}

export function* conferenceChatMessageRequestSaga(action: Action<ChatMessagesRequest>) {
	const response: ApiResponse<ApiSuccessResponse<ChatMessageSuccessResponse>> = yield call(api.chatMessageRequest, action.params)
	if (response.ok) {
		yield put(conferenceChatMessageSuccessAction(response.data.result))
	} else {
		yield put(conferenceChatMessageFailureAction(response.data))
	}
}

export function* conferenceChatSendMessageSaga(action: Action<ChatSendMessageRequest>) {
	const response: ApiResponse<ApiSuccessResponse<ChatSendMessageSuccessResponse>> = yield call(api.chatSendMessage, action.params)
	if (response.ok) {
		yield put(conferenceChatSendMessageSuccessAction(response.data.result))
	} else {
		yield put(conferenceChatSendMessageFailureAction(response.data))
	}
}

export function* conferenceChatFilesUploadRequestSaga(action: { type: string; params: ChatFilesUploadActionParams }) {
	const { id, files } = action.params
	const result: FileUploadResponse = yield Promise.all(
		files.map((file, idx) => {
			return api.conferenceChatFileUpload(id, file, idx)
		})
	)
	yield put(conferenceChatFilesUploadSetAction(result))
}

export function* conferenceChatFilesDeleteRequestSaga(action: any) {
	if (typeof action.params == 'number') {
		const response: ApiResponse<ApiSuccessResponse<FileDeleteResponse>> = yield call(api.chatFileDelete, action.params)
		if (response.ok)
			yield put(conferenceChatDeleteFileSuccessAction({ id: action.params, ...response.data }))
	} else {
		yield call(api.fileUploadCancel, action.params)
	}
}

export function* conferenceChatReadSaga(action: { type: string; payload: number }) {
	const response: ApiResponse<ApiSuccessResponse<undefined>> = yield call(api.chatRead, action.payload)
}
