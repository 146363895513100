export interface _appState {
	persisted: boolean
	initialized: boolean
}

export const APP_PERSISTED = 'APP_PERSISTED'
export const APP_INITIALIZED = 'APP_INITIALIZED'

export const appPersistedAction = () => ({ type: APP_PERSISTED })
export const appInitializedAction = () => ({ type: APP_INITIALIZED })

const initialState: _appState = {
	persisted: false,
	initialized: false,
}

const _appReducer = (state = initialState, action: any): _appState => {
	const { type } = action
	switch (type) {
		case APP_PERSISTED: {
			return { ...state, persisted: true }
		}
		case APP_INITIALIZED: {
			return { ...state, initialized: true }
		}
		default:
			return state
	}
}

export default _appReducer
