import React from 'react'
import { connect } from 'react-redux'
import { GiftedChat } from 'react-native-gifted-chat'
import { showMessage } from 'react-native-flash-message'
import Echo from 'laravel-echo'
import socketio from 'socket.io-client'

import config from '../config'
import {
  ChatIncomingMessageType,
  ConferenceNotificationAdditionalDataType,
} from '../types'
import { AppState } from '../store'
import { chatMessageSuccessAction, chatSetUnread } from '../store/redux/chat'
import {
  conferenceSetAction,
  conferenceChatRequestAction,
  conferenceChatMessageSuccessAction,
  conferenceChatSetUnread,
  conferenceCancelCallAction
} from '../store/redux/conference'
import { authLogoutSuccessAction } from '../store/redux/auth'
import { t } from '../localization'
import { useNotifications } from '../hooks/useNotifications'

type DispatchProps = typeof mapDispatchToProps
type StateProps = ReturnType<typeof mapStateToProps>
type Props = DispatchProps & StateProps & {}

interface State { }

class SocketService extends React.Component<Props, State>{
  private static SocketListenerInstance
  echo: Echo
  constructor(props: Props) {
    super(props)
    this.state = {}
    SocketService.SocketListenerInstance = this
  }

  static socketAcceptCall() {
    SocketService.SocketListenerInstance._socketAcceptCall()
  }

  static socketDeclineCall() {
    SocketService.SocketListenerInstance._socketDeclineCall()
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {
      chat,
      // chat_fetching,
      profile,
      conf_chat,
      // conf_chat_fetching,
      conf_inRoom,
      token,
    } = this.props

    if (!!prevProps.token && !token) {
      Object.keys(this.echo?.connector.channels || {}).forEach(channel_name => {
        this.echo.leave(channel_name)
      });
      this.echo = null
    }

    if (!this.echo && !!token) {
      console.log(`socket new connection`)
      this.echo = new Echo({
        host: `${config.api.baseUrl}:${config.api.chatPort}`,
        broadcaster: 'socket.io',
        secure: true,
        client: socketio,
        auth: {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        },
      })
    }

    if (!!this.echo) {
      const channels: string[] = Object.keys(this.echo.connector.channels) || []

      const chatChannelName = `chat.${chat?.id}`
      if (!!chat?.id && !this.hasChannel(channels, chatChannelName)) {
        this.echo.join(chatChannelName).listen('.user.message.created', this.incoming)
        console.log(`socket listen feedback ${chatChannelName}.user.message.created`);
        this.echo.join(chatChannelName).listen('.client.chat.readed', this.readed)
        console.log(`socket listen feedback ${chatChannelName}.client.chat.readed`);
      }

      if (!!profile?.id) {
        const conferenceChannelName = `incoming_call.${profile.id}`
        if (!this.hasChannel(channels, conferenceChannelName)) {
          this.echo.join(conferenceChannelName).listen('.started', this.socketIncomingCall)
          console.log(`socket listen ${conferenceChannelName}.started`);
          this.echo.join(conferenceChannelName).listen('.rejected', this.socketCancelCall)
          console.log(`socket listen ${conferenceChannelName}.rejected`);
        }

        const LogoutChannelName = `user.${profile.id}`
        if (!this.hasChannel(channels, conferenceChannelName)) {
          this.echo.join(LogoutChannelName).listen('.logout', this.socketUserLogout)
          console.log(`socket listen ${LogoutChannelName}.logout`);
        }
      }

      const confChatChannelName = `chat.${conf_chat?.id}`
      if (!!conf_chat?.id && !this.hasChannel(channels, confChatChannelName)) {
        this.echo.join(confChatChannelName).listen('.user.message.created', this.incomingConf)
        console.log(`socket listen conference ${confChatChannelName}.user.message.created`);
        this.echo.join(confChatChannelName).listen('.client.chat.readed', this.readedConf)
        console.log(`socket listen conference ${confChatChannelName}.client.chat.readed`);
      }

      if (!!profile && !prevProps.conf_inRoom && conf_inRoom) {
        this._socketAcceptCall()
      }
    }
  }

  incoming = (incoming: ChatIncomingMessageType) => {
    const {
      messages,
      page,
      limit,
      total,
      setMessages,
      setUnread,
      unread,
    } = this.props
    const isNew = !(messages || []).filter((message) => message._id === incoming.id).length
    let appended = null
    if (isNew) {
      incoming._id = incoming.id
      incoming.createdAt = new Date(incoming.datetime)
      // @ts-ignore
      appended = GiftedChat.append(messages || [], [incoming], true)
      setMessages({ messages: appended, page, limit, total: total + 1 })
      setUnread(unread + 1)
    }
  }

  incomingConf = (incoming: ChatIncomingMessageType) => {
    const {
      conf_messages,
      conf_page,
      conf_limit,
      conf_total,
      setConfMessages,
      setConfUnread,
      conf_unread,
    } = this.props
    const isNew = !(conf_messages || []).filter((message) => message._id === incoming.id).length
    let appended = null
    if (isNew) {
      incoming._id = incoming.id
      incoming.createdAt = new Date(incoming.datetime)
      // @ts-ignore
      appended = GiftedChat.append(conf_messages || [], [incoming], true)
      setConfMessages({ messages: appended, page: conf_page, limit: conf_limit, total: conf_total + 1 })
      setConfUnread(conf_unread + 1)
    }
  }

  readed = () => {
    const { setUnread } = this.props
    setUnread(0)
  }

  readedConf = () => {
    const { setConfUnread } = this.props
    setConfUnread(0)
  }

  socketIncomingCall = (params: ConferenceNotificationAdditionalDataType) => {
    const {
      setConferenceSession,
      conferenceSession,
      getConferenceChat,
      sessionIdsBlacklist,
    } = this.props
    const { doctor, session_id, token, appointment_id } = params
    const { id } = conferenceSession || {}
    if (id !== session_id && !(sessionIdsBlacklist).includes(session_id)) {
      console.log('socketIncomingCall', session_id)
      setConferenceSession({ id: session_id, token, doctor })
      getConferenceChat(appointment_id)
    }
  }

  socketCancelCall = (params: any) => {
    console.log(`socketCancelCall`);
    this.props.cancelCall()
  }

  socketUserLogout = () => {
    console.log('socketUserLogout')
    showMessage({
      type: 'danger',
      message: t('socket_logout_message'),
      duration: config.api.messageDuration,
    })
    const { cancelAllNotifications } = useNotifications()
    cancelAllNotifications()
    this.props.logout();
  }

  render() {
    return null
  }

  _socketAcceptCall() {
    const {
      profile,
    } = this.props
    const channel = this.echo.connector.channels[`presence-incoming_call.${profile.id}`]
    if (!!channel?.socket) {
      console.log(`socket client event client-accepted`);
      channel.socket.emit('client event', {
        channel: channel.name,
        event: 'client-accepted',
      })
    } else {
      showMessage({ type: 'danger', message: 'Сокет не найден!!!' })
      console.log(`----- _socketAcceptCall ERROR`, { channels: this.echo.connector.channels });
    }
  }

  _socketDeclineCall() {
    const {
      profile,
    } = this.props
    const channel = this.echo.connector.channels[`presence-incoming_call.${profile.id}`]
    if (!!channel?.socket) {
      console.log(`socket client event client-rejected`);
      channel.socket.emit('client event', {
        channel: channel.name,
        event: 'client-rejected',
      })
    } else {
      showMessage({ type: 'danger', message: 'Сокет не найден!!!' })
      console.log(`----- _socketDeclineCall ERROR`, { channels: this.echo.connector.channels });
    }
  }

  hasChannel = (rooms: string[], room: string) => {
    return !!rooms.filter(r => r.includes(room)).length
  }

}

const mapStateToProps = (state: AppState) => ({
  chat: state.chat.chat,
  // chat_fetching: state.chat.fetching,
  token: state.auth.token,
  messages: state.chat.messages,
  page: state.chat.page,
  limit: state.chat.limit,
  total: state.chat.total,
  unread: state.chat.unread,
  profile: state.user.profile,
  conferenceSession: state.conference.session,
  // conf_chat_fetching: state.conference.fetching,
  conf_chat: state.conference.chat,
  conf_messages: state.conference.messages,
  conf_page: state.conference.page,
  conf_limit: state.conference.limit,
  conf_total: state.conference.total,
  conf_unread: state.conference.unread,
  conf_inRoom: state.conference.inRoom,
  sessionIdsBlacklist: state.conference.sessionIdsBlacklist || [],
})

const mapDispatchToProps = {
  setMessages: chatMessageSuccessAction,
  setUnread: chatSetUnread,
  setConferenceSession: conferenceSetAction,
  getConferenceChat: conferenceChatRequestAction,
  setConfMessages: conferenceChatMessageSuccessAction,
  setConfUnread: conferenceChatSetUnread,
  cancelCall: conferenceCancelCallAction,
  logout: authLogoutSuccessAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(SocketService)
