import React from 'react'
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  StyleSheet,
  ViewStyle,
  ImageStyle,
  TextStyle,
} from 'react-native'

import { useTheme, font } from '../theme'
import { t } from '../localization'
import { logo, logo_mini } from '../images'

enum LogoTypes {
  small,
  full,
}

type Props = {
  style: ImageStyle,
  type: LogoTypes,
  clickable: boolean,
  onClick: () => {},
}

function LogoImage({ type }: { type: LogoTypes }) {
  return (
    <Image
      style={[type === LogoTypes.full ? styles.img : styles.imgMini]}
      source={type === LogoTypes.full ? logo : logo_mini}
      resizeMode={'contain'}
    />
  )
}

function Logo({ style, type = LogoTypes.full, clickable = true, onClick }: Props) {
  const { colors } = useTheme()

  const description = type === LogoTypes.full && (
    <Text style={[styles.text, { color: colors.text.title }]}>
      {t('logo')}
    </Text>
  )

  return !clickable
    ? (
    <View style={[style, styles.wrapper]}>
      <LogoImage type={type} />
      {description}
    </View>
    ) : (
    <TouchableOpacity
      style={[style, styles.wrapper]}
      activeOpacity={1}
      onPress={onClick}
    >
      <LogoImage type={type} />
      {description}
    </TouchableOpacity>
    )
}

export default Logo

const styles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
  } as ViewStyle,
  img: {
    alignSelf: 'center',
    width: 155,
    height: 58,
  } as ImageStyle,
  imgMini: {
    alignSelf: 'center',
    width: 112,
		height: 15,
  },
  text: {
    paddingTop: 10,
    fontSize: 10,
    lineHeight: 12,
    fontFamily: font('light'),
  } as TextStyle,
})
