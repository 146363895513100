import React, { useState } from 'react'
import {
	FlatList,
	Image,
	StyleSheet,
	Text,
	TextStyle,
	TouchableOpacity,
	View,
	ViewStyle,
	Dimensions,
	ScrollView,
} from 'react-native'
import { Rating } from 'react-native-ratings'
import { StackScreenProps } from '@react-navigation/stack'
import { connect, useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import 'moment/locale/ru'

moment().locale('ru')

import {
	Screen,
	AppointmentCard,
	Modal,
	Button,
	Markdown,
	TabBar,
} from '../components/'
import { t } from '../localization'
import { font, useTheme } from '../theme'
import { starEmpty } from '../images'
import { MainNavigatorProps, Nullable, ReviewType, ServiceType } from '../types'
import { plural } from '../service'
import { AppState } from '../store/redux'
import { serviceRequestAction } from '../store/redux/services'
import {
	appointmentGenderRequestAction,
	appointmentsFutureRequestAction,
	appointmentNewResetAction,
} from '../store/redux/appointments'
import { serviceReviewsRequestAction } from '../store/redux/reviews'
import { useIsFocused } from '@react-navigation/native'

type ScreenProps = DispatchProps & StateProps & StackScreenProps<MainNavigatorProps, 'MainScreen'>

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

interface Props extends ScreenProps {
	isFocused: boolean
}

interface State {
	service_id: Nullable<number>
	screenWidth: number
}

function MainScreenWrapper(props: Props) {
  const isFocused = useIsFocused()

  return <MainScreen {...props} isFocused={isFocused} />
}

class MainScreen extends React.Component<Props, State>{
	dimensionsSubscription: any

	constructor(props: Props) {
		super(props)
		this.state = {
			service_id: null,
			screenWidth: window.innerWidth,
		}
	}

	componentDidMount() {
		this.dimensionsSubscription = Dimensions.addEventListener("change", this.onChange)
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		const { doctor_fetching, doctor_list, isFocused } = this.props
		if (prevProps.doctor_fetching && !doctor_fetching && !!doctor_list?.length) {
			const { navigation, } = this.props
			const { service_id } = this.state
			if (service_id && isFocused) {
				// @ts-ignore
				navigation.navigate('AppointmentStack', { screen: 'NewAppointmentScreen', params: { serviceId: service_id } })
			}
		}
	}

	componentWillUnmount() {
		this.dimensionsSubscription?.remove()
	}

	onChange = ({ window }: any) => {
		this.setState({ screenWidth: window.width })
	}

	render() {
		const { services, appointment, doctor_fetching, fetching, doctor_chat_unread } = this.props
		const { screenWidth } = this.state
		const isScreenLess635 = screenWidth < 635

		return (
			<Screen>
				<FlatList
					ListHeaderComponent={() => (
						<View style={{ marginTop: 28 }}>
							{!!appointment && (
								<>
									<Title text={t('nearest_service')} />
									<AppointmentCard
										background={'secondary'}
										appointment={appointment}
										notice={t('appointment_notice')}
										isUnreadMessages={doctor_chat_unread}
										nearestConsultationId={appointment.id}
									/>
								</>
							)}
							<Title text={t('services')} />
						</View>
					)}
					data={services || []}
					renderItem={({ item }) => (
						<ServiceCard
							service={item}
							onSelect={this.onServiceSelect}
						/>)}
					keyExtractor={(item, index) => index.toString()}
					contentContainerStyle={[styles.content.container, {
						width: !isScreenLess635 ? '47.22%' : '100%',
						minWidth: !isScreenLess635 ? 635 : 'auto',
					}]}
					refreshing={doctor_fetching || fetching}
					onRefresh={this.onRefresh}
					onEndReached={() => this.getPagination()}
					onEndReachedThreshold={0.5}
				/>
			</Screen >
		)
	}

	onRefresh = () => {
		this.getPagination(1)
		this.props.getFutureAppointments()
	}

	getPagination = (_page?: number) => {
		const {
			page = 0,
			limit = 0,
			total = 0,
			fetching,
			getServices,
		} = this.props
		if (!fetching && (!!_page || page * limit < total)) {
			getServices({ page: _page || (page + 1) })
		}

	}

	onServiceSelect = (service_id: number) => {
		this.setState({ service_id }, () => {
			this.props.resetAppointment(true)
			this.props.getGenders({ service_id })
		})
	}
}

const mapStateToProps = (state: AppState) => ({
	support_chat_unread: state.chat.unread,
	user: state.user.profile,
	services: state.service.services,
	page: state.service.page,
	total: state.service.total,
	limit: state.service.limit,
	fetching: state.service.fetching,
	appointment: (state.appointment.future_appointments || [])[0],
	doctor_fetching: state.appointment.doctor_gender_fetching,
	doctor_list: state.appointment.doctor_gender_list,
	doctor_chat_unread: !!state.conference.unread,
})

const mapDispatchToProps = {
	getServices: serviceRequestAction,
	getGenders: appointmentGenderRequestAction,
	getFutureAppointments: appointmentsFutureRequestAction,
	resetAppointment: appointmentNewResetAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(MainScreenWrapper)

const styles = {
	header: {
		icon: {
			width: 40,
			height: 40,
			borderRadius: 10,
			overflow: 'hidden',
			marginLeft: 24,
			alignItems: 'center',
			justifyContent: 'center',
		} as ViewStyle,
		badge: {
			width: 12,
			position: 'absolute',
			height: 12,
			borderRadius: 6,
			overflow: 'hidden',
			top: 7,
			right: 7,
		} as ViewStyle,
	},
	content: {
		container: {
			flexGrow: 1,
			alignItems: 'stretch',
			alignSelf: 'center',
			marginBottom: 40,
			paddingHorizontal: 20,
		} as ViewStyle,
		title: {
			marginTop: 12,
			marginBottom: 12,
			fontFamily: font(),
			fontSize: 24,
			lineHeight: 29,
		} as TextStyle,
	},
}

function Title({ text }: { text: string }) {

	const { colors } = useTheme()

	return (
		<Text style={[styles.content.title, { color: colors.text.title }]}>{text}</Text>
	)
}

function ServiceCard({
	service,
	onSelect,
}: {
	service: ServiceType
	onSelect: (service_id: number) => void
}) {

	const { colors } = useTheme()

	const dispatch = useDispatch()

	let reviews: ReviewType[] = []
	const service_reviews = useSelector((state: AppState) => state.review.service_reviews)
	if (service_reviews) {
		reviews = service_reviews[service.id]?.reviews || []
	}

	const [showModal, setShowModal] = useState(false)
	const uri = service.image.thumbnail + '?w=140&h=140&fit=crop-center'

	const { reviews_count: reviewsCount, reviews_avg_rate: rating } = service

	return (
		<TouchableOpacity
			style={[serviceStyles.container, { backgroundColor: colors.background.primary }]}
			onPress={() => {
				setShowModal(true)
				if (!showModal && reviews.length === 0) {
					dispatch(serviceReviewsRequestAction({ serviceId: service.id }))
				}
			}}
		>
			<View style={{ flexDirection: 'row' }}>
				<View>
					<View style={serviceStyles.imageContainer}>
						<Image source={{ uri }} resizeMode={'cover'} style={serviceStyles.image} />
					</View>
					<View style={{ alignItems: 'center' }}>
						<Rating
							type='custom'
							ratingImage={starEmpty}
							ratingColor={colors.rating}
							imageSize={10}
							readonly={true}
							startingValue={rating}
							style={{ marginTop: 8, marginBottom: 4 }}
						/>
						<Text style={{ fontFamily: font(), fontSize: 12, color: colors.text.primary }}>{`${reviewsCount} ${plural(reviewsCount, t('reviews_count'))}`}</Text>
					</View>
				</View>
				<View style={serviceStyles.textContainer}>
					<Text style={[serviceStyles.title, { color: colors.text.default }]}>
						{service.name}
					</Text>
					{!!service.shortDescription && (
						<Markdown
							text={service.shortDescription}
							paragraphStyle={{ color: colors.text.primary }}
						/>
					)}
					<View style={serviceStyles.infoWrapper}>
						{!service.hide_payment && (
							<Text style={[serviceStyles.info, { color: colors.text.subtitle }]}>
								{t('currency', [service.cost])}/{t('duration', [service.duration])}
							</Text>
						)}
						{service.is_pack && !service.hide_pack && (
							<Text style={[serviceStyles.info, { color: colors.text.subtitle, marginRight: 16 }]}>
								{t('pack', [service.pack_count, Number(service.pack_cost).toLocaleString()])}
							</Text>
						)}
					</View>
				</View>
			</View>
			<ServiceModal
				service={service}
				isVisible={showModal}
				onClose={() => setShowModal(false)}
				onNextPress={() => {
					setShowModal(false)
					onSelect(service.id)
				}}
			/>
		</TouchableOpacity>
	)
}
const serviceStyles = StyleSheet.create({
	container: {
		marginBottom: 8,
		borderRadius: 20,
		padding: 12,
		overflow: 'hidden',
	},
	imageContainer: {
		width: 74,
		height: 78,
		borderRadius: 16,
		overflow: 'hidden',
	},
	image: {
		width: '100%',
		height: '100%',
	},
	textContainer: {
		flex: 1,
		marginLeft: 12,
		justifyContent: 'center',
	},
	title: {
		fontFamily: font(),
		fontSize: 18,
		lineHeight: 22,
	},
	infoWrapper: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginTop: 4,
	},
	info: {
		fontFamily: font(),
		fontSize: 14,
		lineHeight: 17,
	},
})

interface ServiceModalProps {
	isVisible: boolean
	onClose: () => void
	onNextPress: () => void
	service: ServiceType
}

function ServiceModal({
	isVisible,
	onClose,
	onNextPress,
	service,
}: ServiceModalProps) {

	const { colors } = useTheme()

	const [showDescription, setShowDescription] = useState(0)

	const service_reviews = useSelector((state: AppState) => state.review.service_reviews)
	const fetching = useSelector((state: AppState) => state.review.service_reviews_fetching)

	const dispatch = useDispatch()

	let reviews: ReviewType[] = []
	let total = 0
	if (service_reviews) {
		reviews = service_reviews[service.id]?.reviews || []
		total = service_reviews[service.id]?.total || 0
	}

	const uri = service.image.thumbnail + '?w=250&h=250&fit=crop-center'

	const onTabChange = (tabId: number) => {
		setShowDescription(tabId)
	}

	const getPagination = (_page?: number) => {
		const {
			page = 0,
			limit = 0,
			total = 0,
		} = service_reviews[service.id]
		if (!fetching && (!!_page || page * limit < total)) {
			dispatch(serviceReviewsRequestAction({
				serviceId: service.id,
				page: _page || (page + 1),
			}))
		}
	}

	return (
		<Modal
			width='80%'
			isVisible={isVisible}
			onClose={onClose}
			footer={!service.hide_booking && showDescription === 0
				? (
					<Button
						style={serviceModalStyles.button}
						text={t('new_appointment')}
						onPress={onNextPress}
					/>
				) : <></>
			}
			position='center'
			scrollableContent={false}
		>
			<View style={serviceModalStyles.modalContent}>
				<View style={serviceModalStyles.centered}>
					<View style={[serviceModalStyles.photoContainer]}>
						<Image
							source={{ uri }}
							style={serviceModalStyles.photo}
							resizeMode={'contain'}
						/>
					</View>
					<Text style={[serviceModalStyles.theme, { color: colors.text.title }]}>
						{service.name}
					</Text>
					<View style={serviceModalStyles.costWrapper}>
						{!service.hide_payment && (
							<Text style={[serviceModalStyles.price, { color: colors.text.subtitle }]}>
								{t('currency', [service.cost])}/{t('duration', [service.duration])}
							</Text>
						)}
						{service.is_pack && !service.hide_pack && (
							<Text style={[
								serviceModalStyles.pack,
								{
									color: colors.text.subtitle,
									marginLeft: !service.hide_payment ? 40 : 0,
								}
							]}>
								{t('pack', [service.pack_count, Number(service.pack_cost).toLocaleString()])}
							</Text>
						)}
					</View>
				</View>
				<TabBar
					containerStyle={serviceModalStyles.tabBarContainer}
					active={showDescription}
					onChange={onTabChange}
					leftTabText={t('description')}
					rightTabText={t('reviews')}
				/>
				{ showDescription === 0 ? (
					<ScrollView
						bounces={false}
						contentContainerStyle={serviceModalStyles.content}
					>
						<Markdown text={service.fullDescription} />
					</ScrollView>
				) : (
					<ReviewList
						reviews={reviews}
						total={total}
						isFetching={fetching}
						getPagination={() => getPagination()}
					/>
				)}
			</View>
		</Modal >
	)
}
const serviceModalStyles = StyleSheet.create({
	modalContent: {
		flex: 1,
		paddingHorizontal: 16,
	},
	tabBarContainer: {
		marginVertical: 16,
		alignSelf: 'center',
		width: '100%',
	},
	centered: {
		alignItems: 'center',
	},
	photoContainer: {
		width: 185,
		height: 185,
		overflow: 'hidden',
		borderRadius: 20,
	},
	photo: {
		width: '100%',
		height: '100%',
	},
	theme: {
		marginTop: 16,
		fontFamily: font(),
		fontSize: 20,
		lineHeight: 24,
	},
	costWrapper: {
		flexDirection: 'row',
		marginTop: 4,
	},
	price: {
		fontFamily: font(),
		fontSize: 14,
		lineHeight: 20,
	},
	pack: {
		fontFamily: font(),
		fontSize: 14,
		lineHeight: 20,
	},
	button: {
		marginHorizontal: 16,
		marginTop: 16,
	},
	content: {
		flex: 1,
	},
})

function ReviewList({ reviews, total, isFetching, getPagination }: {
	reviews: ReviewType[],
	total: number,
	isFetching: boolean,
	getPagination: () => void,
}) {
	return (
		<View style={reviewStyles.reviewsWrapper}>
			<FlatList
				data={reviews}
				renderItem={({ item, index, }) => (
					<Review
						key={index.toString()}
						review={item}
					/>
				)}
				keyExtractor={(item, index) => index.toString()}
				onEndReachedThreshold={0.5}
				refreshing={isFetching}
				style={reviewStyles.container}
				ListFooterComponent={
					(reviews.length !== total ? (
						<Button
							text={t('reviews_more')}
							onPress={() => getPagination()}
							type={'secondary'}
						/>
					) : null)
				}
				ListEmptyComponent={<NoReviews />}
			/>
		</View>
	)
}
function Review({ review }: { review: ReviewType }) {
	const { colors } = useTheme()

	const { body, anonymous, rate, date, client } = review
	const name = anonymous ? t('anonymous') : `${client.firstname} ${client.lastname}`
	return (
		<View style={reviewStyles.content}>
			<Text style={[reviewStyles.name, { color: colors.text.default }]}>{name}</Text>
			<Text style={[reviewStyles.date, { color: colors.text.primary }]}>
				{moment(date).format('DD MMMM YYYY')}
			</Text>
			<Rating
				type='custom'
				ratingImage={starEmpty}
				ratingColor={colors.rating}
				imageSize={10}
				readonly={true}
				startingValue={rate}
				style={reviewStyles.rating}
			/>
			<Text style={[reviewStyles.review, { color: colors.text.default }]}>{body}</Text>
		</View>
	)
}
const reviewStyles = StyleSheet.create({
	reviewsWrapper: {
		overflow: 'auto',
		flex: 1,
	},
	container: {
		flex: 1,
	},
	content: {
		marginBottom: 16
	},
	name: {
		fontFamily: font('bold'),
		fontSize: 16,
		marginBottom: 4,
	},
	date: {
		fontFamily: font(),
		fontSize: 12,
		marginBottom: 4,
	},
	rating: {
		marginBottom: 8,
		alignItems: 'flex-start',
	},
	review: {
		fontFamily: font(),
	},
	emptyContainer: {
		overflow: 'hidden',
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},
	emptyContent: {
		lineHeight: 244,
	},
})

function NoReviews() {
	const { colors } = useTheme()
	return (
		<View style={reviewStyles.emptyContainer}>
			<Text style={[reviewStyles.emptyContent, { color: colors.text.secondary }]}>{t('empty_reviews')}</Text>
		</View>
	)
}
