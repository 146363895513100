import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import moment from 'moment'
import 'moment/locale/ru'
moment().locale('ru')

import { NewAppointmentInfoRow } from '.'
import {
  appointmentCalendar,
  appointmentClient,
  appointmentPrice,
  appointmentInfo,
} from '../images'
import { t } from '../localization'
import { font, useTheme } from '../theme'
import { getTimezoneNote } from '../service'
import { Nullable } from '../types'

interface NewAppointmentInfoProps {
  patientName: string
  patientBirthdate: string
  serviceImage: string
  serviceName: string
  serviceCost: number
  serviceCount: number
  doctorName: string
  doctorPhoto: string
  datetime: string
  serviceDiscountCost?: number
  packType: 'new' | 'repack' | null
  packCounts: Array<number>
  packDuration?: Nullable<number>
}

export default function ({
  patientName,
  patientBirthdate,
  serviceImage,
  serviceName,
  serviceCost,
  serviceCount,
  doctorName,
  doctorPhoto,
  datetime,
  serviceDiscountCost,
  packType,
  packCounts,
  packDuration,
}: NewAppointmentInfoProps) {

  const { colors } = useTheme()
  const hasDiscount = serviceDiscountCost !== undefined
  const price = hasDiscount ? serviceDiscountCost : serviceCost
  const oldPrice = hasDiscount ? serviceCost : null

  return (
    <View style={[styles.container, { backgroundColor: colors.background.primary }]}>
      <NewAppointmentInfoRow image={appointmentClient} >
        <Text style={[styles.primary, { color: colors.text.default }]}>
          {patientName}
        </Text>
        <Text style={[styles.secondary, { color: colors.text.subtitle, marginTop: 2 }]}>
          {moment(patientBirthdate).format('DD MMMM YYYY')}
        </Text>
      </NewAppointmentInfoRow>
      <NewAppointmentInfoRow image={typeof serviceImage === 'string' ? { uri: serviceImage } : serviceImage}>
        <Text style={[styles.secondary, { color: colors.text.subtitle }]}>
          {t('service')}
        </Text>
        <Text style={[styles.primary, { color: colors.text.default, marginTop: 2 }]}>
          {serviceName}
        </Text>
      </NewAppointmentInfoRow>
      <NewAppointmentInfoRow image={typeof doctorPhoto === 'string' ? { uri: doctorPhoto } : doctorPhoto} >
        <Text style={[styles.secondary, { color: colors.text.subtitle }]}>
          {t('psychologist')}
        </Text>
        <Text style={[styles.primary, { color: colors.text.default, marginTop: 2 }]}>
          {doctorName}
        </Text>
      </NewAppointmentInfoRow>
      <NewAppointmentInfoRow image={appointmentCalendar}>
        <Text style={[styles.primary, { color: colors.text.default }]}>
          {moment(datetime).format('DD MMMM, HH:mm')}
        </Text>
        <Text style={[styles.timezone, { color: colors.text.primary }]}>
          {getTimezoneNote()}
        </Text>
      </NewAppointmentInfoRow>
      {packType !== 'repack' && (
        <NewAppointmentInfoRow image={appointmentPrice} borderless={!packType}>
          <Text style={[styles.secondary, { color: colors.text.default }]}>
            {`${t('services_count')}: ${serviceCount}`}
          </Text>
          <View style={{ flexDirection: 'row' }}>
            <Text style={[
              styles.primary,
              styles.price,
              { color: colors.text.subtitle }
            ]}>{t('cost')} {t('currency', [price || 0])}</Text>
            <Text style={[
              styles.primary,
              styles.price,
              styles.oldPrice,
              { color: colors.text.default }]}
            >{oldPrice}</Text>
          </View>
        </NewAppointmentInfoRow>
      )}
      {packType === 'new' && packDuration && (
        <NewAppointmentInfoRow image={appointmentInfo} borderless={!!packType}>
          <Text style={[styles.secondary, { color: colors.text.default }]}>
            {t('pack_expiration_date')}
          </Text>
          <Text style={[styles.secondary, { color: colors.text.subtitle, marginTop: 2 }]}>
            {moment(datetime).add(packDuration - 1, 'days').format('DD.MM.YYYY')}
          </Text>
        </NewAppointmentInfoRow>
      )}
       {packType === 'repack' && packDuration && (
        <NewAppointmentInfoRow image={appointmentInfo} borderless={!!packType}>
          <Text style={[styles.secondary, { color: colors.text.default }]}>
            {t('pack_sessions_count')}
          </Text>
          <Text style={[styles.secondary, { color: colors.text.subtitle, marginTop: 2 }]}>
            {t('pack_sessions', [packCounts[0], packCounts[1]])}
          </Text>
        </NewAppointmentInfoRow>
      )}
    </View >
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
    overflow: 'hidden',
    paddingHorizontal: 16,
  },
  primary: {
    fontFamily: font(),
    fontSize: 16,
    lineHeight: 19,
  },
  secondary: {
    fontFamily: font(),
    fontSize: 14,
    lineHeight: 17,
  },
  price: {
    marginTop: 2,
    fontFamily: font('bold'),
  },
  oldPrice: {
    marginLeft: 8,
    textDecorationLine: 'line-through',
    opacity: 0.6,
  },
  timezone: {
    fontFamily: font(),
    fontSize: 12,
    marginTop: 4,
  },
})
