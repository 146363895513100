import React, { useState, useEffect } from 'react'
import moment from 'moment'
import 'moment/locale/ru'
moment().locale('ru')
import { connect } from 'react-redux'
import { AppState } from '../store/redux'
import { StackScreenProps } from '@react-navigation/stack'
import {
	ScrollView,
	View,
	ViewStyle,
	Text,
	TextStyle,
	Linking,
	Dimensions,
} from 'react-native'
import {
	Screen,
	Button,
	RadioButton,
	PaymentFailureModal,
	PaymentSuccessModal,
	PaymentButtonType,
	NewAppointmentInfo,
	NewAppointmentInfoRow,
	PromocodeModal,
	Markdown,
} from '../components'
import { t } from '../localization'
import { CommonActions } from '@react-navigation/native'
import { font, ThemeColors, useTheme } from '../theme'
import {
	appointmentService,
	appointmentFemale,
	appointmentMale,
	percent,
} from '../images'
import { getFullName } from '../service'
import { appointmentMakeRequestAction } from '../store/redux/appointments'
import { AppointmentNavigatorProps, DoctorType, Nullable } from '../types'
import config from '../config'
import { promocodeRequestAction } from '../store/redux/promocode'
import { paymentAppointmentAction, paymentResetAction } from '../store/redux/payment'
import { serviceByIdRequestAction } from '../store/redux/services'

type ScreenProps = DispatchProps & StateProps & StackScreenProps<AppointmentNavigatorProps, 'NewAppointmentConfirmScreen'>

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

interface Props extends ScreenProps {
	colors: ThemeColors,
	isSmallScreen: boolean,
}

interface State {
	isPayAgreementChecked: boolean
	isPackAgreementChecked: boolean
	showSuccess: boolean
	showFailure: boolean
	showPromocodeModal: boolean
	isPromocodeSuccess: Nullable<boolean>
	promocode: Nullable<string>
	clearPayment: boolean
}

function NewAppointmentConfirmScreenWrapper(props: ScreenProps) {
	const { colors } = useTheme()
	const [screenData, setScreenData] = useState(Dimensions.get('window'))

  useEffect(() => {
    const onChange = (result) => {
      setScreenData(result.window)
    }

		const subscription = Dimensions.addEventListener('change', onChange)

		return () => subscription?.remove()
  })

	return <NewAppointmentConfirmScreen colors={colors} isSmallScreen={screenData.width < 655} {...props} />
}

class NewAppointmentConfirmScreen extends React.Component<Props, State>{
	constructor(props: Props) {
		super(props)
		this.state = {
			isPackAgreementChecked: false,
			isPayAgreementChecked: false,
			showSuccess: false,
			showFailure: false,
			showPromocodeModal: false,
			isPromocodeSuccess: null,
			promocode: null,
			clearPayment: true,
		}
	}

	componentDidMount() {
		const {
			service_id,
			navigation,
			new_appointment,
			reserve_slot,
			setPaymentAppointment,
			getServiceById,
		} = this.props
		if (!new_appointment || !new_appointment.client) {
			navigation.replace('MainStack', { screen: 'MainScreen' })
		}

		const routeSuccess = this.props.route.params?.success
		if (routeSuccess === undefined && service_id && new_appointment && reserve_slot) {
			setPaymentAppointment({
				new_appointment,
				reserve_slot,
			})
		}
		if (routeSuccess !== undefined) {
			const isSuccess = routeSuccess === 'true' || routeSuccess === true
			if (isSuccess) {
				this.setState({ showSuccess: true }, () => {
					this.props.navigation.setParams({ success: undefined })
				})
			} else {
				this.setState({ showFailure: true }, () => {
					this.props.navigation.setParams({ success: undefined })
				})
			}
		}

		getServiceById({ id: new_appointment.serviceId })
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		const {
			appointments_fetching,
			payment,
			promocode_fetching,
			checkPromocode,
			promocode_error,
			navigation,
			service_id,
			appointment,
			setPaymentAppointment,
		} = this.props
		const { promocode } = this.state
		if (prevProps.appointments_fetching && !appointments_fetching && !!payment) {
			if (!!payment.confirmation_url) {
				setPaymentAppointment({ appointment, payment })
				this.setState({ clearPayment: false }, () => {
					navigation.navigate('PaymentScreen', {
						payment_id: payment.id,
						confirmation_url: payment.confirmation_url
					})
				})
			} else {
				this.setState({ showSuccess: true })
			}
		}

		const success = this.props.route.params?.success
		const prevSuccess = prevProps.route.params?.success
		if (success !== null && success !== prevSuccess) {
			if (success === true) {
				this.setState({ showSuccess: true }, () => {
					this.props.navigation.setParams({ success: undefined })
				})
			} else {
				this.setState({ showFailure: true }, () => {
					this.props.navigation.setParams({ success: undefined })
				})
			}
		}
		if (promocode && prevState.promocode !== promocode) {
			checkPromocode({ coupon: promocode, service_id })
			this.setState({ isPromocodeSuccess: null })
		}
		if (prevProps.promocode_fetching && !promocode_fetching) {
			this.setState({ isPromocodeSuccess: !promocode_error })
		}
	}

	componentWillUnmount() {
		const { resetPayment } = this.props
		const { clearPayment } = this.state
		if (clearPayment) {
			resetPayment()
		}
	}

	render() {
		const {
			colors,
			appointment,
			new_appointment,
			service,
			appointments_fetching,
			reserve_slot,
			promocode_error,
			promocode_discount,
			isSmallScreen,
		} = this.props
		const {
			isPackAgreementChecked,
			isPayAgreementChecked,
			showSuccess,
			showFailure,
			showPromocodeModal,
			isPromocodeSuccess,
			promocode,
		} = this.state

		let content = null
		if (!showSuccess && !showFailure) {
			if (!new_appointment) return null

			const doctor = new_appointment.doctor || reserve_slot.doctor || {} as DoctorType
			const doctorName = getFullName(doctor.lastname, doctor.firstname, doctor.middlename)
			const doctorPhoto = !!doctor.photo ? (doctor.photo.thumbnail + '?w=40&h=40') : undefined
			const serviceImage = service?.image.thumbnail + '?w=40&h=40'

			const profit = service
				? Math.round(100 - (service?.pack_cost * 100 / (service?.cost * service?.pack_count)))
				: 0

			const isRepackAppointment = new_appointment.pack?.isPack || false
			const isPack = isPackAgreementChecked || isRepackAppointment

			const appointmentCost = isPack ? service?.pack_cost : service?.cost
			const appointmentCount = isPack ? service?.pack_count : 1
			const packDuration = isPack ? service?.pack_duration : null
			const packType = isPackAgreementChecked
				? 'new'
				: isRepackAppointment
					? 'repack'
					: null

			content = (
				<>
					<Text style={[styles.title, { color: colors.text.title }]}>{t('appointment_confirm')}</Text>
					<NewAppointmentInfo
						patientName={getFullName(new_appointment.client.lastname, new_appointment.client.firstname, new_appointment.client.middlename)}
						patientBirthdate={new_appointment.client.birthdate}
						serviceImage={serviceImage || appointmentService}
						serviceName={service?.name}
						serviceCost={appointmentCost}
						serviceDiscountCost={isPromocodeSuccess ? promocode_discount?.discount_price : undefined}
						serviceCount={appointmentCount}
						doctorName={doctorName || t(new_appointment.doctorGender === 0 ? 'female2' : 'male2')}
						doctorPhoto={doctorPhoto || (new_appointment.doctorGender === 0 ? appointmentFemale : appointmentMale)}
						datetime={new_appointment.datetime}
						packType={packType}
						packCounts={[new_appointment.pack?.count, service?.pack_count]}
						packDuration={packDuration}
					/>
					{ !isPack && !isRepackAppointment && (
						<NewAppointmentInfoRow
							borderless
							image={percent}
							style={[styles.promocode, styles.content.container, { backgroundColor: colors.background.primary }]}
							rightIcon={!isPromocodeSuccess ? 'arrow_right' : null}
							onPress={this.onPromocodePress}
						>
							<>
								<Text style={[styles.content.text.secondary, { color: colors.text.default }]}>
									{isPromocodeSuccess ? t('promocode_applied', [promocode]) : t('enter_promocode')}
								</Text>
								{isPromocodeSuccess && (
									<Text style={[styles.content.text.discount, { color: colors.text.subtitle }]}>
										{t('currency', [t('discount', [promocode_discount?.discount_value])])}
									</Text>
								)}
							</>
						</NewAppointmentInfoRow>
					)}
					<View>
						{service?.is_pack && !isRepackAppointment && (
							<View style={styles.footer.checkBox.container}>
								<RadioButton
									checked={isPackAgreementChecked}
									type={'checkBox'}
									onChange={this.onPackAgreementChange}
								/>
								<Markdown
									style={styles.footer.checkBox.markdown}
									text={t('pack_agreement', [service.pack_count, Number(service.pack_cost).toLocaleString(), profit])}
									paragraphStyle={[styles.footer.checkBox.text, { color: colors.text.default }]}
									linkStyle={styles.footer.checkBox.link}
									onLinkPress={() => {}}
								/>
							</View>
						)}
						<View style={styles.footer.checkBox.container}>
							<RadioButton
								checked={isPayAgreementChecked}
								type={'checkBox'}
								onChange={this.onPayAgreementChange}
							/>
							<Markdown
								style={styles.footer.checkBox.markdown}
								text={t('pay_agreement')}
								paragraphStyle={[styles.footer.checkBox.text, { color: colors.text.default }]}
								linkStyle={styles.footer.checkBox.link}
								onLinkPress={this.onAgreementLinkPress}
							/>
						</View>
						<Button
							style={styles.footer.button}
							text={!isRepackAppointment ? t('pay') : t('pack_confirm')}
							onPress={this.onPayPress}
							disabled={!isPayAgreementChecked || appointments_fetching}
							fetching={appointments_fetching}
						/>
						{/* <View style={styles.support.container}>
							<Text style={[styles.support.title, { color: colors.text.default }]}>{t('support_service')}</Text>
							<View style={[styles.support.linkWrapper, { borderBottomColor: colors.text.subtitle }]}>
								<Text style={[styles.support.link, { color: colors.text.subtitle }]} onPress={this.onEmailPress}>{config.support_mail}</Text>
							</View>
						</View> */}
					</View>
				</>
			)
		} else if (showSuccess && appointment) {
			content = (
				<PaymentSuccessModal
					isVisible={true}
					onButtonPress={this.onButtonPress}
					appointment={appointment}
					serviceDiscountCost={isPromocodeSuccess ? promocode_discount?.discount_price : undefined}
				/>
			)
		} else {
			content = (
				<PaymentFailureModal
					isVisible={true}
					onButtonPress={() => this.setState({ showFailure: false })}
				/>
			)
		}

		return (
			<Screen>
				<ScrollView
					bounces={false}
					contentContainerStyle={styles.contentContainer}
				>
					<View style={[
						styles.contentWrapper,
						{
							width: !isSmallScreen ? '55%' : '100%',
							minWidth: !isSmallScreen ? 655 : 'auto',
						},
					]}>
						{ content }
					</View>
					<PromocodeModal
						isVisible={showPromocodeModal}
						onClose={this.onPromocodeModalClose}
						onPromocodeApplyPress={this.onPromocodeApplyPress}
						isPromocodeSuccess={isPromocodeSuccess}
						promocodeErrorText={promocode_error?.reason}
						position='center'
					/>
				</ScrollView>
			</Screen >
		)
	}

	onPayAgreementChange = (checked: any) => {
		this.setState({ isPayAgreementChecked: checked })
	}

	onPackAgreementChange = (checked: any) => {
		this.setState({
			isPackAgreementChecked: checked,
			promocode: null,
			isPromocodeSuccess: null,
		})
	}

	onAgreementLinkPress = () => {
		const { navigation, documents } = this.props
		const document = documents.find(document => document.type === 'terms_of_use')
		if (!!document) {
			// @ts-ignore
			navigation.navigate('AppointmentStack', { screen: 'DocumentScreen', params: { document } })
		}
	}

	onPayPress = () => {
		const {
			makeAppointment,
			reserve_slot,
			service_id,
			patient,
			comment,
			new_appointment,
		} = this.props
		const { promocode, isPromocodeSuccess, isPackAgreementChecked } = this.state

		const slot_id = reserve_slot.id
		const parentId = new_appointment.pack?.parentId || null
		const isPack = isPackAgreementChecked || new_appointment.pack?.isPack

		makeAppointment({
			slot_id,
			service_id,
			patient,
			comment: !!comment ? comment : undefined,
			coupon: (!!promocode && isPromocodeSuccess) ? promocode : undefined,
			parent_id: isPack && parentId ? parentId : null,
			is_pack: isPack,
		})
	}

	onButtonPress = (params: PaymentButtonType) => {
		const { navigation, resetPayment } = this.props;
		resetPayment()
		let screen = ''
		switch (params) {
			case 'instructions':
				screen = 'InstructionsScreen'
				break
			default:
				screen = 'AppointmentListScreen'
				break
		}
		navigation.dispatch(
			CommonActions.reset({
				index: 0,
				routes: [
					{ name: 'MainStack', params: { screen: 'MainScreen' } },
					{ name: 'MainStack', params: { screen } },
				],
			})
		);
		this.setState({ showSuccess: false })
	}

	onEmailPress = () => {
		Linking.openURL(`mailto:${config.support_mail}`)
	}

	onPromocodePress = () => {
		this.setState({ showPromocodeModal: true })
	}

	onPromocodeModalClose = () => {
		this.setState({ showPromocodeModal: false })
	}

	onPromocodeApplyPress = (promocode: string) => {
		this.setState({ promocode })
	}
}

const mapStateToProps = (state: AppState) => ({
	documents: state.app.documents || [],
	reserve_slot: state.appointment.reserve_slot || state.payment.reserve_slot,
	service_id: state.appointment.new_appointment?.serviceId || state.payment.new_appointment?.serviceId,
	patient: state.appointment.new_appointment?.client || state.payment.new_appointment?.client,
	comment: state.appointment.new_appointment?.comment || state.payment.new_appointment?.comment,
	appointments_fetching: state.appointment.appointments_fetching,
	appointment: state.appointment.appointment || state.payment.appointment,
	new_appointment: state.appointment.new_appointment || state.payment.new_appointment,
	service: state.service.serviceById,
	payment: state.appointment.payment || state.payment.payment,
	promocode_fetching: state.promocode.fetching,
	promocode_error: state.promocode.error,
	promocode_discount: state.promocode.result,
})

const mapDispatchToProps = {
	makeAppointment: appointmentMakeRequestAction,
	checkPromocode: promocodeRequestAction,
	setPaymentAppointment: paymentAppointmentAction,
	resetPayment: paymentResetAction,
	getServiceById: serviceByIdRequestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewAppointmentConfirmScreenWrapper)

const styles = {
	contentContainer: {
		flexGrow: 1,
	} as ViewStyle,
	contentWrapper: {
		flexGrow: 1,
		marginVertical: 30,
		paddingHorizontal: 30,
		alignSelf: 'center',
	},
	content: {
		container: {
			borderRadius: 20,
			overflow: 'hidden',
			paddingHorizontal: 16,
		} as ViewStyle,
		text: {
			secondary: {
				fontFamily: font(),
				fontSize: 14,
				lineHeight: 17,
			} as TextStyle,
			discount: {
				marginTop: 2,
				fontFamily: font('bold'),
				fontSize: 16,
				lineHeight: 19,
			} as TextStyle,
		},
	},
	title: {
		marginBottom: 24,
		fontFamily: font(),
		fontSize: 24,
		lineHeight: 29,
	},
	footer: {
		container: {} as ViewStyle,
		checkBox: {
			container: {
				flexDirection: 'row',
				alignItems: 'center',
				marginTop: 16,
			} as ViewStyle,
			markdown: {
				marginLeft: 8,
			} as ViewStyle,
			text: {
				marginTop: 0,
				marginBottom: 0,
				fontFamily: font(),
				fontSize: 14,
				lineHeight: 17,
			} as TextStyle,
			link: {
				fontFamily: font('bold'),
				textDecorationLine: 'none',
			} as TextStyle,
		},
		button: {
			marginTop: 30,
		} as ViewStyle,
	},
	// support: {
	// 	container: {
	// 		marginTop: 15,
	// 		alignItems: 'center',
	// 	} as ViewStyle,
	// 	title: {
	// 		fontFamily: font('bold'),
	// 		fontSize: 14,
	// 		lineHeight: 17,
	// 	} as TextStyle,
	// 	linkWrapper: {
	// 		borderBottomWidth: 1,
	// 	} as ViewStyle,
	// 	link: {
	// 		fontFamily: font(),
	// 		fontSize: 14,
	// 		lineHeight: 17,
	// 	} as TextStyle,
	// },
	promocode: {
		marginTop: 10,
	} as ViewStyle,
}
