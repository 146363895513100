import React from 'react'
import {
	ActivityIndicator,
	ScrollView,
	View,
	StyleSheet,
	Dimensions,
	Text,
} from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { connect } from 'react-redux'
import moment from 'moment'
import 'moment/locale/ru'

moment().locale('ru')

import { Screen, ArticleCard, Icon } from '../components'
import { useTheme, ThemeColors, font } from '../theme'
import { MainNavigatorProps } from '../types'
import { AppState } from '../store/redux'
import { articleByIdRequestAction } from '../store/redux/articles'
import { t } from '../localization'

type ScreenProps = DispatchProps & StateProps & StackScreenProps<MainNavigatorProps, 'ArticleStack'>

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

interface Props extends ScreenProps {
	colors: ThemeColors
}

interface State {
	screenWidth: number
}

function ArticleScreenWrapper(props: Props) {
	const { colors } = useTheme()
	return <ArticleScreen colors={colors} {...props} />
}

class ArticleScreen extends React.Component<Props, State>{
	dimensionsSubscription: any

	constructor(props: Props) {
		super(props)
		this.state = {
			screenWidth: window.innerWidth,
		}
	}

	componentDidMount() {
		this.props.getArticleById({ id: this.props.route.params?.id })
		this.dimensionsSubscription = Dimensions.addEventListener("change", this.onChange)
	}

	componentDidUpdate(prevProps: Props): void {
		if (this.props.error) {
			if (+this.props.error?.code === 404) {
				this.props.navigation.navigate('NotFoundScreen')
			}
		}

		if (prevProps.route.params.id !== this.props.route.params.id && this.props.route.params.id) {
			this.props.getArticleById({ id: this.props.route.params?.id })
		}
	}

	componentWillUnmount() {
		this.dimensionsSubscription?.remove()
	}

	onChange = ({ window }: any) => {
		this.setState({ screenWidth: window.width })
	}

	render() {
		const { article, fetching, colors } = this.props
		const { screenWidth } = this.state
		const isScreenLess635 = screenWidth < 635

		return (
			<Screen>
				<>
					{this.props.navigation.canGoBack() && (
						<View style={styles.back}>
							<View style={styles.backLink} onClick={() => this.props.navigation.goBack()}>
								<Icon
									size={16}
									style={styles.backIcon}
									name={'arrow_back'}
									color={colors.icon.default}
								/>
								<Text style={[styles.backText, { color: colors.text.title }]}>{t('back')}</Text>
							</View>
						</View>
					)}
					<ScrollView>
						<View style={[styles.container, {
							width: !isScreenLess635 ? '47.22%' : '100%',
							minWidth: !isScreenLess635 ? 635 : 'auto',
						}]}>
							{fetching || !article ? (
								<ActivityIndicator color={colors.fetching} size="large" />
							) : (
								<ArticleCard article={article} isFull={true} />
							)}
						</View>
					</ScrollView>
				</>
			</Screen>
		)
	}
}

const mapStateToProps = (state: AppState) => ({
	article: state.article.articleById || null,
	fetching: state.article.articleByIdFetching,
	error: state.article.articleByIdError,
})

const mapDispatchToProps = {
	getArticleById: articleByIdRequestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleScreenWrapper)

const styles = StyleSheet.create({
	container: {
		alignSelf: 'center',
		marginBottom: 40,
		paddingHorizontal: 20,
		marginTop: 25,
	},
	back: {
		position: 'fixed',
		top: 80,
		left: 25,
		zIndex: 1,
	},
	backLink: {
		flexDirection: 'row',
		alignItems: 'center',
		cursor: 'pointer',
	},
	backText: {
		fontFamily: font(),
		fontSize: 20,
	},
	backIcon: {
		marginRight: 15,
	},
})
