import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { AppState as reduxAppState } from '../store/redux'
import { StackScreenProps } from '@react-navigation/stack'
import {
	AppState,
	AppStateStatus,
	Image,
	ImageStyle,
	LayoutChangeEvent,
	StyleSheet,
	Text,
	TextStyle,
	View,
	ViewStyle,
	BackHandler,
	TouchableOpacity,
} from 'react-native'
import { Screen, Icon } from '../components'
import { t } from '../localization'
import { font, ThemeColors, useTheme } from '../theme'
// import { PsyMale } from '../images'
import { logo_mini } from '../images'
import { ReactElement } from 'hoist-non-react-statics/node_modules/@types/react'
import { CallNavigatorProps } from '../types'
// import InCallManager from 'react-native-incall-manager'
// import SplashScreen from 'react-native-splash-screen'
// import { conferenceSetAction, conferenceAcceptCallAction, conferenceCancelCallAction } from '../store/redux/conference'
import { conferenceAcceptCallAction, conferenceCancelCallAction } from '../store/redux/conference'
import { getFullName } from '../service'
import SocketService from '../services/SocketService'

type ScreenProps = DispatchProps & StateProps & StackScreenProps<CallNavigatorProps, 'IncomingCallScreen'>

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

interface Props extends ScreenProps {
	colors: ThemeColors
}

interface State {
	containerWidth: number
	imageCenter: [number, number]
	appState?: AppStateStatus
}

function IncomingCallScreenWrapper(props: Props) {
	const { colors } = useTheme()
	useEffect(() => {
		const backAction = () => true;

		const backHandler = BackHandler.addEventListener(
			"hardwareBackPress",
			backAction
		);

		return () => backHandler.remove();
	}, []);

	return <IncomingCallScreen colors={colors} {...props} />
}

class IncomingCallScreen extends React.Component<Props, State>{
	private photoSize: number = 90
	// private unsubscribe_didFocus: () => void
	// private unsubscribe_didBlur: () => void
	private subscription: any

	constructor(props: Props) {
		super(props)
		this.state = {
			containerWidth: 0,
			imageCenter: [0, 0],
			appState: null,
		}
		// this.unsubscribe_didFocus = props.navigation.addListener('focus', () => {
		// 	if (Platform.OS === 'android' && AppState.currentState === 'active') {
		// 		InCallManager.startRingtone('_DEFAULT_');
		// 	}
		// })

		// this.unsubscribe_didBlur = props.navigation.addListener('blur', () => {
		// 	console.log('blur')
		// 	InCallManager.stopRingtone()
		// })

		this.subscription = AppState.addEventListener('change', this.handleAppStateChange)
	}

	private handleAppStateChange = (nextAppState: AppStateStatus) => {
		this.setState({ appState: nextAppState })
	}

	// componentDidMount() {
	// 	if (Platform.OS == 'ios') {
	// 		InCallManager.startRingtone('_DEFAULT_')
	// 	}
	// }

	public componentWillUnmount() {
		// this.unsubscribe_didFocus()
		// this.unsubscribe_didBlur()
		// InCallManager.stopRingtone();
		this.subscription.remove('change', this.handleAppStateChange)
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		const { token, calls, navigation } = this.props;
		// const { imageCenter } = this.state
		// const prevToken = prevProps.token;

		// if (!!prevToken && !token) {
		if (!token) {
			// @ts-ignore
			navigation.replace('MainStack') || navigation.replace('AuthStack');
		}
		// if (!prevState.imageCenter.every(a => !!a) && imageCenter.every(a => !!a)) {
		// 	SplashScreen.hide()
		// }
		// if (!calls.length && prevProps.calls.length) {
		// 	console.log('stopRingtone')
		// 	// InCallManager.stopRingtone();
		// } else if (!calls.length) {
		// 	console.log('stopRingtone')
		// 	// InCallManager.stopRingtone();
		// } else {
		// 	console.log('start setForceSpeakerphoneOn')
		// 	// InCallManager.start({ media: 'video' })
		// 	// InCallManager.setForceSpeakerphoneOn(true)
		// 	if (Platform.OS == 'ios') {
		// 		console.log('startRingtone _DEFAULT_')
		// 		// InCallManager.startRingtone('_DEFAULT_');
		// 	} else if (this.state.appState === 'active') {
		// 		console.log('startRingtone _DEFAULT_')
		// 		// InCallManager.startRingtone('_DEFAULT_');
		// 	}
		// }
	}

	render() {
		// const { colors, calls, doctor } = this.props
		const { colors, doctor, navigation } = this.props
		const {
			containerWidth,
			imageCenter,
		} = this.state
		const _iconSize = Math.round(containerWidth * 0.24)
		const iconSize = _iconSize > 70 ? 70 : _iconSize

		// const lastCall = calls[calls.length - 1];

		return (
			<Screen hideHeader >
				<View style={{ flex: 1 }}>
					<TouchableOpacity
						style={styles.logoButton}
						activeOpacity={1}
						onPress={this.onDeclinePress}
					>
						<Image
							style={styles.logo}
							source={logo_mini}
							resizeMode={'contain'}
						/>
					</TouchableOpacity>
					<View style={[styles.container, { backgroundColor: colors.background.primary }]} onLayout={this.containerOnLayout}>
						<Text style={[styles.title, { color: colors.text.title }]}>
							{t('incoming_call')}
						</Text>
						<View style={styles.wrapper}>
							<View style={styles.information.container} onLayout={this.informationOnLayout}>
								<View style={[styles.information.image.container, { width: this.photoSize, height: this.photoSize }]} >
									<View style={[styles.information.image.content, { width: this.photoSize, height: this.photoSize }]} >
										<Image
											source={{ uri: doctor?.photo?.original || '' }}
											style={styles.information.image.image}
											resizeMode={'cover'}
										/>
									</View>
								</View>
								<View style={[styles.information.psychologist.container, { backgroundColor: colors.background.progress }]}>
									<Text style={[styles.information.psychologist.psychologist, { color: colors.text.selection }]}>
										{getFullName(doctor?.lastname, doctor?.firstname, doctor?.middlename)}
									</Text>
									<Text style={[styles.information.psychologist.post, { color: colors.text.selection }]}>
										{doctor?.post}
									</Text>
								</View>
							</View>
							<View style={styles.buttons.container}>
								<Icon
									name={'accept_call'} size={iconSize}
									color={colors.icon.videoController.accept.color}
									backgroundColor={colors.icon.videoController.accept.background}
									onPress={() => this.onAcceptPress()}
								/>
								<Icon
									name={'decline_call'} size={iconSize}
									color={colors.icon.videoController.decline.color}
									backgroundColor={colors.icon.videoController.decline.background}
									onPress={() => this.onDeclinePress()}
								/>
							</View>
							<Circles center={imageCenter} radius={(this.photoSize + 2) / 2} />
						</View>
					</View>
				</View>
			</Screen>
		)
	}

	containerOnLayout = (e: LayoutChangeEvent) => {
		this.setState({ containerWidth: e.nativeEvent.layout.width })
	}

	informationOnLayout = (e: LayoutChangeEvent) => {
		const { width, height, x, y } = e.nativeEvent.layout
		const iconSize = this.photoSize + 4
		const centerX = width / 2
		const centerY = y + iconSize / 2
		this.setState({ imageCenter: [centerX, centerY] })
	}

	onAcceptPress = () => {
		const { navigation, acceptCall, doctor, token } = this.props
		acceptCall()
		navigation.replace('ConferenceScreen', {});
	}

	onDeclinePress = () => {
		const { navigation, decline } = this.props
		!!decline && decline()
		// @ts-ignore
		navigation.replace('MainStack');
		SocketService.socketDeclineCall()
	}

}

const mapStateToProps = (state: reduxAppState) => ({
	calls: [{ session: 1 }],
	token: state.conference.session?.token,
	id: state.conference.session?.id,
	doctor: state.conference.session?.doctor,
})

const mapDispatchToProps = {
	// sessionSet: conferenceSetAction,
	decline: conferenceCancelCallAction,
	acceptCall: conferenceAcceptCallAction
}

export default connect(mapStateToProps, mapDispatchToProps)(IncomingCallScreenWrapper)

const styles = {
	container: {
		flex: 1,
		alignItems: 'center',
		marginHorizontal: 30,
		marginBottom: 20,
		borderRadius: 20,
	} as ViewStyle,
	wrapper: {
		width: '33%',
		minWidth: 305,
		marginVertical: 'auto',
	},
	title: {
		fontFamily: font('bold'),
		fontSize: 24,
		textAlign: 'center',
		zIndex: 2,
		marginTop: 40,
	} as TextStyle,
	information: {
		container: {} as ViewStyle,
		image: {
			container: {
				marginTop: 2,
				borderRadius: 45,
				overflow: 'hidden',
				alignSelf: 'center',
			} as ViewStyle,
			content: {
				zIndex: -3,
			} as ViewStyle,
			image: {
				width: '100%',
				height: '100%',
			} as ImageStyle,
		},
		psychologist: {
			container: {
				marginTop: 7,
				borderRadius: 80,
				paddingTop: 16,
				paddingBottom: 16,
			} as ViewStyle,
			psychologist: {
				fontFamily: font('bold'),
				fontSize: 18,
				lineHeight: 22,
				textAlign: 'center',
			} as TextStyle,
			post: {
				marginTop: 4,
				fontFamily: font(),
				fontSize: 16,
				lineHeight: 19,
				textAlign: 'center',
			} as TextStyle,
		},
	},
	buttons: {
		container: {
			marginTop: 54,
			marginHorizontal: 20,
			justifyContent: 'space-around',
			flexDirection: 'row',
		} as ViewStyle,
	},
	logoButton: {
		alignSelf: 'center',
		marginVertical: 20,
	},
	logo: {
		width: 112,
		height: 15,
	},
}

function AbsoluteIcon({
	center,
	radius,
	opacity,
	zIndex,
}: {
	center: [number, number]
	radius: number
	opacity: number
	zIndex: number
}) {
	return (
		<Icon
			name={'incoming_call_animation'}
			size={radius * 2}
			style={[absoluteIconStyle.icon, {
				left: center[0] - radius,
				top: center[1] - radius,
				opacity,
				zIndex,
			}]}
		/>
	)
}

const absoluteIconStyle = StyleSheet.create({
	icon: {
		position: 'absolute',
	} as ViewStyle
})

function Circles({ center, radius: _radius }: { center: [number, number]; radius: number }) {
	if (center.every(a => !a)) return null

	let radius = _radius
	let opacity = 0.875
	const result: ReactElement<any, any>[] = []
	for (let index = 0; index < 7; index++) {

		if (!index) {
			result.push(
				<AbsoluteIcon center={center} radius={radius} opacity={opacity} key={index.toString()} zIndex={1} />
			)
		}
		radius += !index ? 36 : 32
		result.push(
			<AbsoluteIcon center={center} radius={radius} opacity={opacity} key={(index + 1).toString()} zIndex={-1} />
		)
		opacity = +(Math.round((opacity - 0.142) * 1000) / 1000).toFixed(3)
	}

	return <>{result}</>
}
