import React, { useEffect, useState } from 'react'
import {
  View,
  Text,
  FlatList,
  StyleSheet,
  ActivityIndicator,
  TouchableOpacity,
  ScrollView,
  Dimensions,
  ScaledSize,
} from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import {
  VictoryScatter,
  VictoryChart,
  VictoryLine,
  VictoryTooltip,
  VictoryAxis,
  VictoryArea,
} from 'victory'
import { showMessage } from 'react-native-flash-message'
import moment from 'moment'
import upperFirst from 'lodash/upperFirst'

import { Button, Calendar, Icon, Modal, Screen, TextInput } from '../../components'
import { t } from '../localization'
import { AppState } from '../store'
import { font, useTheme } from '../../theme'
import {
  EmployeeType,
  TrackerType,
  Nullable,
  EmployeeDetailType,
  TrackerRateType,
  TrackerStatType,
  TrackerPeriodStatType,
} from '../types'
import { trackerStatsRequestAction } from '../store/redux/tracker'
import {
  employeesRequestAction,
  employeeRequestAction,
  deleteEmployeeRequestAction,
  addTrackerToEmployeeRequestAction,
  replaceEmployeeTrackerRequestAction,
  unlinkEmployeeTrackerRequestAction,
} from '../store/redux/employee'
import { usePrevious } from '../../hooks/usePrevious'
import { getUtcOffset } from '../../service'
import config from '../../config'

type TrackerActionType = 'ADD' | 'REPLACE'

export default function EmployeeScreen({ route }: { route: any }) {
  const { id } = route.params
  const { colors } = useTheme()
  const dispatch = useDispatch()
  const navigation = useNavigation()

  const trackers: Array<TrackerType> = useSelector((state: AppState) => state.tracker.trackers)
  const employees = useSelector((state: AppState) => state.employee.employees)
  const employee = employees.find((item: EmployeeType) => item.id === id)
  const employeeDetail: Nullable<EmployeeDetailType> = useSelector((state: AppState) => state.employee.employee)
  const employeeDetailFetching = useSelector((state: AppState) => state.employee.employee_fetching)
  const trackerStats = useSelector((state: AppState) => state.tracker.tracker_stats)
  const addTrackerToEmployeeSuccess = useSelector((state: AppState) => state.employee.add_tracker_to_employee_success)
  const addTrackerToEmployeeError = useSelector((state: AppState) => state.employee.add_tracker_to_employee_error)
  const replaceEmployeeTrackerSuccess = useSelector((state: AppState) => state.employee.replace_employee_tracker_success)
  const replaceEmployeeTrackerError = useSelector((state: AppState) => state.employee.replace_employee_tracker_error)
  const unlinkEmployeeTrackerSuccess = useSelector((state: AppState) => state.employee.unlink_employee_tracker_success)
  const unlinkEmployeeTrackerError = useSelector((state: AppState) => state.employee.unlink_employee_tracker_error)

  const [action, setAction] = useState<Nullable<TrackerActionType>>(null)
  const [startDate, setStartDate] = useState(moment().subtract(6, 'days').startOf('day'))
  const [endDate, setEndDate] = useState(moment().endOf('day'))
  const [showCalendar, setShowCalendar] = useState<boolean>(false)
  const [selectedDate, setSelectedDate] = useState(null)
  const [chartData, setChartData] = useState([])
  const [selectedEvent, setSelectedEvent] = useState<Nullable<TrackerRateType>>(null)
  const [selectedDateRates, setSelectedDateRates] = useState<Array<TrackerStatType>>([])
  const [selectedDateEventsCount, setSelectedDateEventsCount] = useState<any>({})
  const [isModalSelectTrackerVisible, setIsModalSelectTrackerVisible] = useState(false)
  const [isManageTrackerModalVisible, setIsManageTrackerModalVisible] = useState<boolean>(false)
  const [screenData, setScreenData] = useState(Dimensions.get('window'))

  const prevAddTrackerToEmployeeSuccess = usePrevious(addTrackerToEmployeeSuccess)
  const prevAddTrackerToEmployeeError = usePrevious(addTrackerToEmployeeError)
  const prevReplaceEmployeeTrackerSuccess = usePrevious(replaceEmployeeTrackerSuccess)
  const prevReplaceEmployeeTrackerError = usePrevious(replaceEmployeeTrackerError)
  const prevUnlinkEmployeeTrackerSuccess = usePrevious(unlinkEmployeeTrackerSuccess)
  const prevUnlinkEmployeeTrackerError = usePrevious(unlinkEmployeeTrackerError)
  const prevShowCalendar = usePrevious(showCalendar)
  const prevStartDate = usePrevious(startDate)
  const prevEndDate = usePrevious(endDate)

  useEffect(() => {
    const onChange = (result: { window: React.SetStateAction<ScaledSize> }) => {
      setScreenData(result.window)
    }

    const subscription = Dimensions.addEventListener('change', onChange)

    return () => subscription?.remove()
  })

  useEffect(() => {
    if (!employee || +id !== employee.id) {
      navigation.navigate('MainStack', { screen: 'MainScreen', params: { type: 'EMPLOYEES' } })
    } else {
      dispatch(employeeRequestAction({id}))
    }
  }, [])

  useEffect(() => {
    if (!employee || +id !== employee.id) {
      navigation.navigate('MainStack', { screen: 'MainScreen', params: { type: 'EMPLOYEES' } })
    }
  }, [employee])

  useEffect(() => {
    if (employeeDetail?.tracker && employeeDetail.tracker_status === 'active') {
      dispatch(trackerStatsRequestAction({
        id: employeeDetail.tracker.id,
        employee_id: employeeDetail.client_id,
        from: startDate.utc().format('YYYY-MM-DD HH:mm:ss'),
        to: endDate.utc().format('YYYY-MM-DD HH:mm:ss'),
        tz_offset: getUtcOffset(),
      }))
    }
  }, [employeeDetail])

  useEffect(() => {
    if (prevAddTrackerToEmployeeSuccess !== null && !prevAddTrackerToEmployeeSuccess && addTrackerToEmployeeSuccess) {
      setIsModalSelectTrackerVisible(false)
      dispatch(employeeRequestAction({id}))
      dispatch(employeesRequestAction())
      setSelectedDate(null)
      setSelectedEvent(null)
      showMessage({
        type: 'success',
        message: t('conntect_tracker_to_employee_success'),
        duration: config.api.messageDuration,
      })
    }
  }, [addTrackerToEmployeeSuccess])

  useEffect(() => {
    if (prevAddTrackerToEmployeeError !== null && !prevAddTrackerToEmployeeError && addTrackerToEmployeeError) {
      showMessage({
        type: 'danger',
        message: addTrackerToEmployeeError.message,
        duration: config.api.messageDuration,
      })
    }
  }, [addTrackerToEmployeeError])

  useEffect(() => {
    if (prevReplaceEmployeeTrackerSuccess !== null && !prevReplaceEmployeeTrackerSuccess && replaceEmployeeTrackerSuccess) {
      setIsModalSelectTrackerVisible(false)
      dispatch(employeeRequestAction({id}))
      dispatch(employeesRequestAction())
      setSelectedDate(null)
      setSelectedEvent(null)
      showMessage({
        type: 'success',
        message: t('reconntect_employee_tracker_success'),
        duration: config.api.messageDuration,
      })
    }
  }, [replaceEmployeeTrackerSuccess])

  useEffect(() => {
    if (prevReplaceEmployeeTrackerError !== null && !prevReplaceEmployeeTrackerError&& replaceEmployeeTrackerError) {
      showMessage({
        type: 'danger',
        message: replaceEmployeeTrackerError.message,
        duration: config.api.messageDuration,
      })
    }
  }, [replaceEmployeeTrackerError])

  useEffect(() => {
    if (prevUnlinkEmployeeTrackerSuccess !== null && !prevUnlinkEmployeeTrackerSuccess && unlinkEmployeeTrackerSuccess) {
      dispatch(employeeRequestAction({id}))
      dispatch(employeesRequestAction())
      setSelectedDate(null)
      setSelectedEvent(null)
      showMessage({
        type: 'success',
        message: t('unlink_employee_tracker_success'),
        duration: config.api.messageDuration,
      })
    }
  }, [unlinkEmployeeTrackerSuccess])

  useEffect(() => {
    if (prevUnlinkEmployeeTrackerError !== null && !prevUnlinkEmployeeTrackerError&& unlinkEmployeeTrackerError) {
      showMessage({
        type: 'danger',
        message: unlinkEmployeeTrackerError.message,
        duration: config.api.messageDuration,
      })
    }
  }, [unlinkEmployeeTrackerError])

  useEffect(() => {
    if (prevShowCalendar && !showCalendar && (startDate !== prevStartDate || endDate !== prevEndDate)) {
      setSelectedDate(null)
      setChartData([])
      setSelectedEvent(null)
      setSelectedDateRates([])
      setSelectedDateEventsCount({})
      dispatch(trackerStatsRequestAction({
        id: employeeDetail!.tracker.id,
        employee_id: employeeDetail!.client_id,
        from: startDate.utc().format('YYYY-MM-DD HH:mm:ss'),
        to: endDate.utc().format('YYYY-MM-DD HH:mm:ss'),
        tz_offset: getUtcOffset(),
      }))
    }
  }, [showCalendar])

  useEffect(() => {
    setChartData(trackerStats.map((stat: TrackerPeriodStatType) => {
      return {
        points: stat.count ?? 0,
        date: moment(stat.date).format('DD.MM.YYYY'),
      }
    }))
  }, [trackerStats])

  useEffect(() => {
    if (selectedDate !== null) {
      setSelectedDateRates(
        trackerStats.find(
          (stat: TrackerPeriodStatType) =>
            moment(stat.date, 'YYYY-MM-DD')
              .isSame(moment(selectedDate, 'DD.MM.YYYY')
          )
        ).events
      )
      setSelectedDateEventsCount({})
      setSelectedEvent(null)
    }
  }, [selectedDate])

  useEffect(() => {
    if (selectedDateRates.length != 0) {
      let events: any = {}
      selectedDateRates.forEach((rate: TrackerStatType) => {
        if (rate.rate !== null) {
          if (!events[rate.rate.id]) {
            events[rate.rate.id] = 0
          }
          events[rate.rate.id] += 1
        }
      })
      setSelectedDateEventsCount(events)
    }
  }, [selectedDateRates])

  const onModalSelectTrackerClose = () => {
    setIsModalSelectTrackerVisible(false)
  }

  const onManageTrackerModalClose = () => {
    setIsManageTrackerModalVisible(false)
  }

  const renderEmployeeCard = () => {
    let status = ''
    let bgColor = colors.background.tracker_active
    let textColor = colors.text.title
    if (employeeDetail?.tracker_status) {
      switch (employeeDetail?.tracker_status) {
        case 'active':
          status = t('active_tracker')
          bgColor = colors.background.tracker_active
          textColor = colors.text.title
          break
        case 'disconnected':
          status = t('inactive_tracker')
          bgColor = colors.background.tracker_inactive
          textColor = colors.text.red
          break
      }
    }
    return (
      <View style={[styles.card, {
        backgroundColor: colors.background.primary,
      }]}>
        <Text style={[styles.employeeFIO, { color: colors.text.title }]}>
          {`${employee?.lastname} ${employee?.firstname} ${employee?.middlename != null ? employee?.middlename : ''}`.trim()}
        </Text>
        <Text style={[styles.employeeEmail, { color: colors.text.subtitle }]}>{employee?.email}</Text>
        {employeeDetail?.tracker.id && (
          <View style={{
            borderRadius: 20,
            padding: 16,
            marginTop: 16,
            flexDirection: 'row',
            backgroundColor: bgColor,
            borderColor: colors.border.tracker_active,
          }}>
            <View style={[styles.trackerIconContainer, { backgroundColor: colors.background.primary }]}>
              <Text style={[styles.trackerIcon, { color: colors.text.default }]}>
                {employeeDetail?.tracker.icon}
              </Text>
            </View>
            <View style={styles.trackerDescriptionContainer}>
              <Text style={[styles.trackerTitle, {
                color: colors.text.subtitle,
              }]}>
                {employeeDetail?.tracker.title}
              </Text>
              <Text
                style={[{
                  marginTop: 4,
                  fontSize: 12,
                  color: textColor,
                }]}
              >
                {status}
              </Text>
            </View>
            <View style={{
              flexDirection: 'row',
              alignItems: 'center',
              height: 10,
            }}>
              <Icon name={employeeDetail?.tracker_status === 'active' ? 'link' : 'unlink'} size={20} color={colors.icon.default} />
              <View style={{marginRight: 18}} />
              <Icon
                name='more'
                color={colors.icon.inactive}
                onPress={() => {
                  setIsManageTrackerModalVisible(true)
                }}
              />
            </View>
          </View>
        )}
      </View>
    )
  }

  const renderLastEvent = () => {
    return (
      <View style={[styles.card, { backgroundColor: colors.background.primary }]}>
        <Text style={[styles.cardTitle, {
          color: colors.text.subtitle,
        }]}>{t('last_event')}</Text>
        <View style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 8,
        }}>
          <View style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}>
            <Text style={{
              fontSize: 24,
              marginRight: 8,
            }}>{employeeDetail?.last_event.rate.icon}</Text>
            <Text style={{
              fontFamily: font('bold'),
            }}>{employeeDetail?.last_event.title}</Text>
          </View>
          <Text style={{
            fontSize: 10,
            color: colors.text.primary,
          }}>{moment(employeeDetail?.last_event.created_at).format('DD.MM.YYYY')}</Text>
        </View>
        <Text style={{
          color: colors.text.primary,
        }}>{employeeDetail?.last_event.description}</Text>
      </View>
    )
  }

  const renderStatCard = () => {
    let points = chartData.map(data => data.points)
    points = [...new Set(points)]
    return (
      <View
        style={[styles.trackerGradeCard, {
          backgroundColor: colors.background.primary,
        }]}
      >
        <Text style={[styles.statTitle, {
          color: colors.text.subtitle,
        }]}>{t('statistic')}</Text>
        <TouchableOpacity
          activeOpacity={1}
          style={[styles.calendarContainer, { backgroundColor: colors.background.input }]}
          onPress={() => setShowCalendar(true)}
        >
          <Text style={[styles.dateRange, {color: colors.text.default}]}>
            {`${moment(startDate).local().format('DD.MM.YYYY')} - ${moment(endDate).local().format('DD.MM.YYYY')}`}
          </Text>
          <Icon name={'calendar'} />
        </TouchableOpacity>
        <View>
          <VictoryChart>
            <VictoryArea
              data={chartData}
              x='date'
              y='points'
              animate={{
                duration: 1000,
                onLoad: { duration: 300 }
              }}
              style={{
                data: {
                  fill: '#C3E2E1',
                  opacity: '0.4',
                }
              }}
            />
            <VictoryAxis
              crossAxis={false}
              dependentAxis
              offsetX={40}
              domain={points.length === 1 ? { y: [points[0], points[0] + 10] } : undefined}
              style={{
                axis: {stroke: 'transparent'},
                ticks: {size: 0},
                tickLabels: {
                  fontSize: 14,
                  fontWeight: 'bold',
                  padding: 8,
                  fill: ({ tick, ticks }: { tick: number, ticks: Array<number> }) => {
                    return tick === Math.max(...ticks)
                      ? colors.chart.axisYMaxLabel
                      : tick === Math.min(...ticks)
                        ? colors.chart.axisYMinLabel
                        : colors.chart.axisYLabel
                  },
                },
                grid: {stroke: ({ tick }: { tick: number }) => tick === 0
                  ? colors.chart.axisYCenterLine
                  : colors.chart.axisYLines},
              }}
            />
            <VictoryAxis
              offsetY={40}
              tickFormat={(t) => upperFirst(moment(t, 'DD.MM.YYYY').format('dd'))}
              style={{
                axis: {stroke: 'transparent'},
                ticks: {size: 0},
                tickLabels: {
                  fontSize: 10,
                  padding: 18,
                  fill: colors.chart.axisXLabel,
                },
              }}
            />
            <VictoryLine
              style={{
                data: { stroke: colors.chart.stroke },
                labels: { fill: 'red', fontSize: 10 },
              }}
              animate={{
                duration: 1000,
                onLoad: { duration: 300 }
              }}
              data={chartData}
              x='date'
              y='points'
              // interpolation='monotoneX'
            />
            <VictoryScatter
              data={chartData}
              x='date'
              y='points'
              size={6}
              animate={{
                duration: 1000,
                onLoad: { duration: 300 }
              }}
              name="Scatter"
              labelComponent={
                <VictoryTooltip
                  cornerRadius={7}
                  dy={-5}
                  flyoutHeight={14}
                  flyoutPadding={{ left: 8, right: 8 }}
                  flyoutStyle={{ fill: colors.chart.label, strokeWidth: 0 }}
                  style={{ fill: colors.chart.labelText, fontSize: 10 }}
                  pointerLength={4}
                  pointerWidth={6}
                />
              }
              labels={({ datum }) => datum.date}
              style={{
                data: {
                  fill: colors.chart.scatter,
                  stroke: colors.chart.scatterStroke,
                  strokeWidth: 4,
                },
              }}
              events={[{
                target: 'data',
                eventHandlers: {
                  onMouseDown: () => {
                    return [
                      {
                        target: 'data',
                        eventKey: 'all',
                        mutation: () => ({
                          style: {
                            fill: colors.chart.scatter,
                            stroke: colors.chart.scatterStroke,
                            strokeWidth: 4,
                          },
                        }),
                      },
                    ]
                  },
                  onMouseUp: () => {
                    return [
                      {
                        target: 'data',
                        mutation: (props) => {
                          setSelectedDate(props.datum.date)
                          return ({
                            style: {
                              fill: colors.chart.scatterStroke,
                              stroke: colors.chart.scatter,
                              strokeWidth: 6,
                            },
                          })
                        },
                      }
                    ]
                  },
                },
              }]}
            />
          </VictoryChart>
        </View>
        <Calendar
          isVisible={showCalendar}
          onClose={() => setShowCalendar(false)}
          dates={[startDate.local().format(), endDate.local().format()]}
          onSelect={(dates: string[]) => {
            const diff = moment(dates[1]).diff(moment(dates[0]), 'days') + 1
            if (diff > 14 && dates[1]) {
              showMessage({
                type: 'danger',
                message: t('select_interval_warning'),
                duration: config.api.messageDuration,
              })
            } else {
              setStartDate(moment(dates[0]))
              setEndDate(dates[1] ? moment(dates[1]).endOf('day') : moment(dates[0]).endOf('day'))
            }
            setShowCalendar(false)
          }}
          multiSelection
        />
      </View>
    )
  }

  const renderEvents = () => {
    const positiveRates = employeeDetail!.rates.filter((rate: TrackerRateType) => rate.weight > 0)
    const negativeRates = employeeDetail!.rates.filter((rate: TrackerRateType) => rate.weight < 0)
    negativeRates.reverse()
    return (
      <View style={[styles.trackerGradeCard, {
        backgroundColor: colors.background.primary,
      }]}>
        <Text style={[styles.eventsTitle, {
          color: colors.text.subtitle,
        }]}>{t('events')}</Text>
        <View style={[styles.eventsRow, styles.mb16]}>
          {positiveRates.map((rate: TrackerRateType) => {
            const eventCounts = selectedDateEventsCount[rate.id] ?? 0
            return (
              <TouchableOpacity key={rate.id} onPress={() => setSelectedEvent(rate)}>
                <View style={styles.eventContainer}>
                  <View style={[styles.eventIconContainer, {
                    backgroundColor: selectedEvent?.id !== rate.id
                      ? colors.tracker.positive
                      : colors.tracker.positiveSelected,
                  }]}>
                    <View style={[styles.eventBadge, {
                      backgroundColor: colors.badge.background,
                    }]}>
                      <Text style={[styles.eventsBadgeLabel, {
                        color: colors.badge.text,
                      }]}>{`${eventCounts}`}</Text>
                    </View>
                    <Text style={[styles.eventIcon, {
                      color: colors.text.default,
                    }]}>{rate.icon}</Text>
                  </View>
                  <Text style={[styles.eventTitle, {
                    color: colors.text.default,
                  }]}>{rate.title}</Text>
                </View>
              </TouchableOpacity>
            )
          }
        )}
        </View>
        <View style={styles.eventsRow}>
          {negativeRates.map((rate: TrackerRateType) => {
            const eventCounts = selectedDateEventsCount[rate.id] ?? 0
            return (
              <TouchableOpacity key={rate.id} onPress={() => setSelectedEvent(rate)}>
                <View style={styles.eventContainer}>
                  <View style={[styles.eventIconContainer, {
                    backgroundColor: selectedEvent?.id !== rate.id
                    ? colors.tracker.negative
                    : colors.tracker.negativeSelected,
                  }]}>
                    <View style={[styles.eventBadge, {
                      backgroundColor: colors.badge.background,
                    }]}>
                      <Text style={[styles.eventsBadgeLabel, {
                        color: colors.badge.text,
                      }]}>{`${eventCounts}`}</Text>
                    </View>
                    <Text style={[styles.eventIcon, {
                      color: colors.text.default,
                    }]}>{rate.icon}</Text>
                  </View>
                  <Text style={[styles.eventTitle, {
                    color: colors.text.default,
                  }]}>{rate.title}</Text>
                </View>
              </TouchableOpacity>
            )
          }
        )}
        </View>
      </View>
    )
  }

  const renderRates = () => {
    const eventRates = selectedDateRates.filter(rate => (
      rate.rate.id === selectedEvent?.id
    ))
    return eventRates.map(rate => (
      <View key={rate.id} style={[styles.trackerGradeCard, {
        backgroundColor: colors.background.primary,
      }]}>
        <View style={styles.rateTitleContainer}>
          <View style={styles.rateTitle}>
            <Text style={[styles.eventIcon, {color: colors.text.default}]}>
              {rate.rate.icon}
            </Text>
            <Text style={[styles.eventRateTitle, {color: colors.text.default}]}>
              {rate.title}
            </Text>
          </View>
          <Text style={[{color: colors.text.default}]}>
            {moment(rate.created_at).format('DD.MM.YYYY')}
          </Text>
        </View>
        {!!rate.description && (
          <Text style={[styles.rateDescription, {
            color: colors.text.primary,
          }]}>{rate.description}</Text>
        )}
      </View>
    ))
  }

  const renderTitle = () => {
    return (
      <View style={styles.title}>
        <Text style={[styles.titleText, { color: colors.text.title }]}>{t('tracker')}</Text>
      </View>
    )
  }

  const Divider = () => (
    <View style={[styles.divider, {
      borderBottomColor: colors.divider,
    }]} />
  )

  const renderTrackerListTile = ({ item, index }: { item: TrackerType, index: number }) => {
    return (
      <TouchableOpacity
        style={[selectTrackerStyles.trackerCard, {
          backgroundColor: colors.background.primary,
          borderColor: colors.border.secondaryWeb,
        }]}
        onPress={() => {
          if (action === 'ADD') {
            dispatch(addTrackerToEmployeeRequestAction({ client_id: employee?.id || 0, tracker_id: item.id }))
          } else if (action === 'REPLACE') {
            dispatch(replaceEmployeeTrackerRequestAction({ client_id: employee?.id || 0, tracker_id: item.id }))
          }
        }}
      >
        <View style={[selectTrackerStyles.trackerIconContainer, { backgroundColor: item.background }]}>
          <Text style={[selectTrackerStyles.trackerIcon, { color: colors.text.default }]}>{item.icon}</Text>
        </View>
        <View style={selectTrackerStyles.trackerDescriptionContainer}>
          <Text style={[selectTrackerStyles.trackerTitle, {
            color: colors.text.subtitle,
          }]}>{item.title}</Text>
          <Text
            style={{color: colors.text.primary}}
            numberOfLines={2}
          >{item.description}</Text>
        </View>
      </TouchableOpacity>
    )
  }

	const isSmallScreen = screenData.width < 655

  return (
    <Screen>
      <View style={[
        styles.contentWrapper,
        {
          width: !isSmallScreen ? '47.22%' : '100%',
          minWidth: !isSmallScreen ? 635 : 'auto',
          paddingLeft: 20,
        },
      ]}>
        {renderTitle()}
        <ScrollView
          style={styles.container}
        >
          {employeeDetailFetching ? (
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
              <ActivityIndicator color={colors.fetching} size="large" />
            </View>
          ) : (
            <>
              {renderEmployeeCard()}
              {!!employeeDetail?.tracker && employeeDetail?.tracker_status === 'active' ? (
                <>
                  <View style={{marginTop: 8}} />
                  {employeeDetail.last_event && renderLastEvent()}
                  <View style={{marginTop: 8}} />
                  <View style={{marginTop: 8}} />
                  {renderStatCard()}
                  <View style={{marginTop: 8}} />
                  {selectedDate !== null && renderEvents()}
                  {selectedEvent !== null && renderRates()}
                </>
              ) : employeeDetail?.tracker_status !== 'disconnected' ? (
                <Button
                  text={t('connect_tracker')}
                  style={{ marginTop: 24 }}
                  disabled={trackers.length === 0}
                  onPress={() => {
                    setAction('ADD')
                    setIsModalSelectTrackerVisible(true)
                  }}
                />
              ) : <></>}
              <View style={{marginBottom: 24}}></View>
              <DeleteEmployee employee_id={employee?.id || 0} />
              <Modal
                isVisible={isModalSelectTrackerVisible}
                onClose={onModalSelectTrackerClose}
                position='center'
                width={375}
              >
                <View style={selectTrackerStyles.container}>
                  <Text style={[selectTrackerStyles.title, { color: colors.text.default }]}>
                    {t('select_tracker')}
                  </Text>
                  <FlatList
                    style={selectTrackerStyles.listContent}
                    bounces={false}
                    keyExtractor={(item, index) => `${index}_${item.id}`}
                    data={trackers}
                    renderItem={renderTrackerListTile}
                    ItemSeparatorComponent={() => <View style={selectTrackerStyles.separator} />}
                  />
                </View>
              </Modal>
              <Modal
                isVisible={isManageTrackerModalVisible}
                onClose={onManageTrackerModalClose}
                onModalHide={onManageTrackerModalClose}
                position='center'
              >
                <View style={manageTrackerModalStyles.container}>
                  <Text style={[manageTrackerModalStyles.title, { color: colors.text.default }]}>
                    {t('tracker')}
                  </Text>
                  {employeeDetail?.tracker_status === 'disconnected' && (
                    <>
                      <TouchableOpacity onPress={() => {
                        setIsManageTrackerModalVisible(false)
                        dispatch(addTrackerToEmployeeRequestAction({ client_id: employee?.id || 0, tracker_id: employeeDetail?.tracker.id }))
                      }}>
                        <View style={manageTrackerModalStyles.tileContainer}>
                          <View style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}>
                            <Text style={manageTrackerModalStyles.tileText}>{t('reconnect_tracker')}</Text>
                            <Icon name='unlink' size={20} color={colors.icon.inactive} />
                          </View>
                        </View>
                      </TouchableOpacity>
                      <Divider />
                    </>
                  )}
                  <TouchableOpacity onPress={() => {
                    setAction('REPLACE')
                    setIsManageTrackerModalVisible(false)
                    setIsModalSelectTrackerVisible(true)
                  }}>
                    <View style={manageTrackerModalStyles.tileContainer}>
                      <Text style={manageTrackerModalStyles.tileText}>{t('reconnect_another_tracker')}</Text>
                    </View>
                  </TouchableOpacity>
                  {employeeDetail?.tracker_status !== 'disconnected' && (
                    <>
                      <Divider />
                      <TouchableOpacity onPress={() => {
                        setIsManageTrackerModalVisible(false)
                        dispatch(unlinkEmployeeTrackerRequestAction({ client_id: employee?.id || 0, tracker_id: employeeDetail?.tracker.id }))
                      }}>
                        <View style={manageTrackerModalStyles.tileContainer}>
                          <Text style={manageTrackerModalStyles.tileText}>{t('delete_tracker')}</Text>
                        </View>
                      </TouchableOpacity>
                    </>
                  )}
                </View>
              </Modal>
            </>
          )}
        </ScrollView>
      </View>
    </Screen>
  )
}
const styles = StyleSheet.create({
  container: {
    paddingBottom: 24,
  },
  contentWrapper: {
    marginTop: 40,
    paddingRight: 20,
    alignSelf: 'center',
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24,
  },
  titleText: {
    fontSize: 24,
    fontFamily: font(),
  },
  card: {
    borderRadius: 20,
    padding: 16,
  },
  cardTitle: {
    fontSize: 18,
    marginBottom: 24,
  },
  employeeFIO: {
    fontFamily: font(),
    fontSize: 20,
    marginBottom: 4,
  },
  employeeEmail: {
    fontFamily: font('bold'),
  },
  trackerIconContainer: {
    width: 40,
    height: 40,
    marginRight: 8,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  trackerIcon: {
    fontSize: 20,
  },
  trackerDescriptionContainer: {
    flex: 1,
    marginRight: 8,
  },
  trackerTitle: {
    fontSize: 18,
  },
  divider: {
    height: 1,
    borderBottomWidth: 1,
  },
  trackerGradeCard: {
    borderRadius: 20,
    padding: 16,
    marginBottom: 8,
  },
  statTitle: {
    fontSize: 18,
    marginBottom: 24,
  },
  calendarContainer: {
    paddingVertical: 8,
    paddingLeft: 16,
    paddingRight: 19,
    borderRadius: 10,
    overflow: 'hidden',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24,
  },
  dateRange: {
    fontFamily: font(),
    fontSize: 16,
    lineHeight: 19,
    minHeight: 19,
    flex: 1,
  },
  eventsTitle: {
    fontSize: 18,
    marginBottom: 16,
  },
  eventsRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mb16: {
    marginBottom: 16,
  },
  eventContainer: {
    alignItems: 'center',
    width: 100,
  },
  eventIconContainer: {
    width: 40,
    height: 40,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 8,
  },
  eventBadge: {
    position: 'absolute',
    height: 16,
    borderRadius: 8,
    paddingHorizontal: 6,
    overflow: 'hidden',
    top: 0,
    right: 0,
    zIndex: 1,
  },
  eventsBadgeLabel: {
    fontFamily: font('bold'),
    fontSize: 12,
  },
  eventIcon: {
    fontSize: 20,
  },
  eventTitle: {
    fontFamily: font(),
    fontSize: 12,
    textAlign: 'center',
  },
  eventRateTitle: {
    fontFamily: font('bold'),
    marginLeft: 8,
  },
  rateTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  rateTitle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rateDescription: {
    fontFamily: font(),
  },
})
const manageTrackerModalStyles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
  title: {
    paddingBottom: 8,
    fontSize: 24,
    fontWeight: 'bold',
  },
  tileContainer: {
    paddingVertical: 18,
  },
  tileText: {
    fontWeight: 'bold',
    fontSize: 16,
  },
})
const selectTrackerStyles = StyleSheet.create({
  text: {
    textAlign: 'center',
    fontFamily: font('bold'),
    fontSize: 14,
    lineHeight: 17,
  },
  container: {
    paddingHorizontal: 16,
  },
  title: {
    paddingBottom: 8,
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 7,
  },
  listContent: {
    marginBottom: 12,
  },
  trackerCard: {
    borderRadius: 20,
    padding: 16,
    flexDirection: 'row',
    borderWidth: 1,
  },
  trackerIconContainer: {
    width: 40,
    height: 40,
    marginRight: 8,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  trackerIcon: {
    fontSize: 20,
  },
  trackerDescriptionContainer: {
    flex: 1,
    marginRight: 8,
  },
  trackerTitle: {
    fontSize: 18,
    marginBottom: 4,
  },
  separator: {
    height: 8,
  },
})

function DeleteEmployee({ employee_id }: { employee_id: number }) {
  const { colors } = useTheme()
  const dispatch = useDispatch()

  const [isVisible, setVisible] = useState(false)
  const [showErrorText, setShowErrorText] = useState<boolean>(false)
  const [deleteFieldValue, setDeleteFieldValue] = useState<string>('')

  const deleteEmployeeSuccess = useSelector((state: AppState) => state.employee.delete_employee_success)

  const prevDeleteEmployeeSuccess = usePrevious(deleteEmployeeSuccess)

  useEffect(() => {
    if (prevDeleteEmployeeSuccess !== null && !prevDeleteEmployeeSuccess && deleteEmployeeSuccess) {
      setVisible(false)
      dispatch(employeesRequestAction())
      showMessage({
        type: 'success',
        message: t('delete_employee_success'),
        duration: config.api.messageDuration,
      })
    }
  }, [deleteEmployeeSuccess])

  const onModalClose = () => {
    setVisible(false)
  }

  const onModalConfirm = () => {
    if (deleteFieldValue !== 'DELETE') {
      setShowErrorText(true)
    } else {
      dispatch(deleteEmployeeRequestAction({ id: employee_id }))
    }
  }

  return (
    <>
      <Text
        style={[deleteEmployeeStyles.text, { color: colors.text.subtitle }]}
        onPress={() => {
          setShowErrorText(false)
          setVisible(true)
        }}
      >
        {t('employee_delete')}
      </Text>
      <Modal
        isVisible={isVisible}
        onClose={onModalClose}
        position='center'
        width={375}
      >
        <View style={deleteEmployeeStyles.container}>
          <Text style={[deleteEmployeeStyles.title, { color: colors.text.default }]}>
            {t('employee_deleting')}
          </Text>
          <View>
            <Text style={deleteEmployeeStyles.description}>
              {t('employee_deleting_description_start')}
              <Text style={{ color: colors.text.error }}>DELETE</Text>
              {t('employee_deleting_description_end')}
            </Text>
            <TextInput
              textStyle={{ height: 40 }}
              placeholder={t('delete_placeholder')}
              onChange={(value) => {
                setShowErrorText(false)
                setDeleteFieldValue(value)
              }}
              maxLength={10}
              error={showErrorText ? t('delete_field_error') : ''}
            />
            <View style={deleteEmployeeStyles.buttonContainer}>
              <Button
                text={t('cancel')}
                style={deleteEmployeeStyles.modalDeleteTrackerButton}
                type={'secondary'}
                onPress={onModalClose}
              />
              <View style={deleteEmployeeStyles.spacer}/>
              <Button
                text={t('delete')}
                type={'warning'}
                style={deleteEmployeeStyles.modalDeleteTrackerButton}
                onPress={onModalConfirm}
                disabled={deleteFieldValue.length === 0}
              />
            </View>
          </View>
        </View>
      </Modal>
    </>
  )
}
const deleteEmployeeStyles = StyleSheet.create({
  text: {
    textAlign: 'center',
    fontFamily: font('bold'),
    fontSize: 14,
    lineHeight: 17,
  },
  container: {
    paddingHorizontal: 16,
  },
  title: {
    paddingBottom: 8,
    fontSize: 24,
    fontWeight: 'bold',
  },
  description: {
    marginBottom: 16,
  },
  buttonContainer: {
    marginTop: 24,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  modalDeleteTrackerButton: {
    flex: 1,
  },
  spacer: {
    width: 3,
  },
})