import { Nullable, AppointmentType, AppointmentSlotType, PaymentType } from '../../types'
import { NewAppointment } from './appointments'

export interface PaymentState {
	appointment: Nullable<AppointmentType>
	new_appointment: Nullable<NewAppointment>
	reserve_slot: Nullable<AppointmentSlotType>
	payment: Nullable<PaymentType>
}

export const PAYMENT_APPOINTMENT = 'PAYMENT_APPOINTMENT'
export const PAYMENT_PART_RESET = 'PAYMENT_PART_RESET'
export const PAYMENT_RESET = 'PAYMENT_RESET'

export const paymentAppointmentAction = (params: {
	appointment?: AppointmentType,
	new_appointment?: NewAppointment,
	reserve_slot?: AppointmentSlotType,
	payment?: PaymentType
}) => ({ type: PAYMENT_APPOINTMENT, params })
export const paymentPartResetAction = () => ({ type: PAYMENT_PART_RESET })
export const paymentResetAction = () => ({ type: PAYMENT_RESET })

const initialState: PaymentState = {
	appointment: null,
	new_appointment: null,
	reserve_slot: null,
	payment: null,
}

const PaymentReducer = (state = initialState, action: any): PaymentState => {
	const { type, params } = action
	switch (type) {
		case PAYMENT_APPOINTMENT: {
			return {
				...state,
				...params,
			}
		}
		case PAYMENT_PART_RESET: {
			return {
				...initialState,
				new_appointment: state.new_appointment,
				reserve_slot: state.reserve_slot,
			}
		}
		case PAYMENT_RESET: {
			return initialState
		}
		default:
			return state
	}
}

export default PaymentReducer
