import React, { useEffect, useState } from 'react'
import {
  ScrollView,
  StyleSheet,
  View,
  Text,
  Dimensions,
  ScaledSize,
} from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'react-native-flash-message'

import { Button, Screen, TextInput } from '../../components'
import { t } from '../localization'
import {
  addEmployeeRequestAction,
  employeesRequestAction,
  addEmployeeAction,
} from '../store/redux/employee'
import { font, useTheme } from '../../theme'
import { AppState } from '../store'
import config from '../../config'
import { usePrevious } from '../../hooks/usePrevious'

export default function AddEmployeeScreen({ navigation }: { navigation: any }) {
  const { colors } = useTheme()
  const dispatch = useDispatch()

  const createEmployeeSuccess: boolean = useSelector((state: AppState) => state.employee.add_employee_success)
  const addEmployeeError = useSelector((state: AppState) => state.employee.add_employee_error)
  const [screenData, setScreenData] = useState(Dimensions.get('window'))

  const [email, setEmail] = useState<string>('')

  const prevAddEmployeeError = usePrevious(addEmployeeError)

  useEffect(() => {
    const onChange = (result: { window: React.SetStateAction<ScaledSize> }) => {
      setScreenData(result.window)
    }

    const subscription = Dimensions.addEventListener('change', onChange)

    return () => subscription?.remove()
  })

  useEffect(() => {
    if (createEmployeeSuccess) {
      dispatch(employeesRequestAction())
      showMessage({
        type: 'success',
        message: t('add_employee_success'),
        duration: config.api.messageDuration,
      })

      dispatch(addEmployeeAction())

      navigation.canGoBack()
        ? navigation.goBack()
        : navigation.replace('MainStack', { screen: 'MainScreen', params: { type: 'EMPLOYEES' } })
    }
  }, [createEmployeeSuccess])

  useEffect(() => {
    if (prevAddEmployeeError !== null && !prevAddEmployeeError && addEmployeeError) {
      showMessage({
        type: 'danger',
        message: addEmployeeError!.reason,
        duration: config.api.messageDuration,
      })
    }
  }, [addEmployeeError])

  const renderTitle = () => {
    return (
      <View style={styles.title}>
        <Text style={[styles.titleText, { color: colors.text.title }]}>
          {t('employee_creating')}
        </Text>
      </View>
    )
  }

	const isSmallScreen = screenData.width < 655

  return (
    <Screen>
      <View style={[
        styles.contentWrapper,
        {
          width: !isSmallScreen ? '47.22%' : '100%',
          minWidth: !isSmallScreen ? 635 : 'auto',
          paddingLeft: 20,
        },
      ]}>
        {renderTitle()}
        <ScrollView contentContainerStyle={styles.container}>
          <View style={[styles.card, { backgroundColor: colors.background.primary }]}>
            <View>
              <TextInput
                style={styles.emailField}
                value={email}
                textStyle={styles.emailInput}
                placeholder={t('employee_email_placeholder')}
                onChange={(value) => {
                  setEmail(value)
                }}
                maxLength={80}
              />
            </View>
          </View>
          <View style={[styles.card, { backgroundColor: colors.background.primary }]}>
            <Text style={[styles.warning, { color: colors.text.default }]}>{t('warning')}</Text>
            <Text style={[styles.warning_description, { color: colors.text.default }]}>
              {t('warning_description')}
            </Text>
          </View>
          <View style={{
            flexDirection: 'row',
          }}>
            <Button
              style={[styles.createEmployeeBtn, {
                marginRight: 12,
              }]}
              type='secondary'
              text={t('back')}
              onPress={() => {
                if (navigation.canGoBack()) {
                  navigation.goBack()
                } else {
                  navigation.replace('MainStack', { screen: 'MainScreen', params: { type: 'EMPLOYEES' } })
                }
              }}
            />
            <Button
              style={styles.createEmployeeBtn}
              text={t('add_employee')}
              onPress={() => {
                dispatch(addEmployeeRequestAction({email: email}))
              }}
            />
          </View>
        </ScrollView>
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    paddingBottom: 24,
  },
  contentWrapper: {
    marginTop: 40,
    paddingRight: 20,
    alignSelf: 'center',
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24,
  },
  titleText: {
    fontSize: 24,
    fontFamily: font(),
  },
  card: {
    padding: 16,
    marginBottom: 8,
    borderRadius: 20,
  },
  emailField: {
    marginBottom: 8,
  },
  emailInput: {
    paddingRight: 58,
    height: 40,
  },
  warning: {
    fontFamily: font(),
    fontSize: 14,
    paddingBottom: 8,
  },
  warning_description: {
    width: 400,
    fontFamily: font(),
    fontSize: 10,
  },
  createEmployeeBtn: {
    flex: 1,
    marginTop: 24,
  },
})
