import { put, call, delay, select } from 'redux-saga/effects'
import { ApiResponse } from 'apisauce';
import { api, ApiSuccessResponse } from '../../api'
import {
	Action,
	FaqRequest,
	FaqSuccessResponse,
} from '../../types';
import {
	faqFailureAction,
	faqSuccessAction,
} from '../redux/faq';

export function* faqRequestSaga(action: Action<FaqRequest>) {
	const response: ApiResponse<ApiSuccessResponse<FaqSuccessResponse>> = yield call(api.faqRequest, action.params)
	if (response.ok) {
		yield put(faqSuccessAction(response.data.result))
	} else {
		yield put(faqFailureAction(response.data))
	}
}
