import config from '../config'
import { FixtureApi } from '.'
import { UserProfileSuccessResponse } from '../types'

export default ({ resolve, successResponse, errorResponse, axiosConfig }: FixtureApi) => {
	switch (axiosConfig.method?.toUpperCase()) {
		case 'GET': get({ resolve, successResponse, errorResponse, axiosConfig }); break;
		default: resolve(errorResponse(axiosConfig)); break;
	}
}

function get({ resolve, successResponse, errorResponse, axiosConfig }: FixtureApi) {
	const token = axiosConfig.headers.Authorization
	switch (token) {
		case 'Bearer token:79999999999':
			setTimeout(() => resolve(successResponse(axiosConfig, {
				profile: {
					id: 1,
					firstName: 'Иван',
					lastName: 'Иванов',
					phone: '79999999999',
					email: null,
				}
			} as UserProfileSuccessResponse)), config.api.fixtureDuration)
			break;
		case 'Bearer token:79999999998':
			setTimeout(() => resolve(successResponse(axiosConfig, {
				profile: {
					id: 2,
					firstName: 'Анна',
					lastName: 'Иванова',
					phone: '79999999998',
					email: null,
				}
			} as UserProfileSuccessResponse)), config.api.fixtureDuration)
			break;

		default:
			setTimeout(() => resolve(errorResponse(axiosConfig, {
				message: 'Сессия устарела',
			}, 401)), config.api.fixtureDuration)
			break;
	}
}