import Cookies from 'universal-cookie'

import {
	Nullable,
	AuthSuccessResponse,
	AuthRequest,
	AuthByEmailRequest,
	PhoneCountry,
} from '../../types'

export interface AuthState {
	token: Nullable<string>
	fetching: boolean
	phone: Nullable<string>
	email: Nullable<string>
	error: any
	country: Nullable<PhoneCountry>
}

export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN'
export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAILURE = 'AUTH_FAILURE'
export const AUTH_BY_EMAIL_REQUEST = 'AUTH_BY_EMAIL_REQUEST'
export const AUTH_BY_EMAIL_SUCCESS = 'AUTH_BY_EMAIL_SUCCESS'
export const AUTH_BY_EMAIL_FAILURE = 'AUTH_BY_EMAIL_FAILURE'
export const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST'
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS'
export const AUTH_LOGOUT_FAILURE = 'AUTH_LOGOUT_FAILURE'
export const AUTH_SET_COUNTRY = 'AUTH_SET_COUNTRY'

export const authSetTokenAction = (payload: AuthSuccessResponse) => ({
	type: AUTH_SET_TOKEN,
	payload,
})
export const authRequestAction = (params) => ({ type: AUTH_REQUEST, params })
export const authSuccessAction = () => ({ type: AUTH_SUCCESS })
export const authFailureAction = (payload) => ({ type: AUTH_FAILURE, payload })
export const authByEmailRequestAction = (params) => ({ type: AUTH_BY_EMAIL_REQUEST, params })
export const authByEmailSuccessAction = () => ({ type: AUTH_BY_EMAIL_SUCCESS })
export const authByEmailFailureAction = (payload) => ({ type: AUTH_BY_EMAIL_FAILURE, payload })
export const authLogoutRequestAction = () => ({ type: AUTH_LOGOUT_REQUEST })
export const authLogoutSuccessAction = () => ({ type: AUTH_LOGOUT_SUCCESS })
export const authLogoutFailureAction = () => ({ type: AUTH_LOGOUT_FAILURE })
export const authSetCountryAction = (params) => ({ type: AUTH_SET_COUNTRY, params})

const initialState: AuthState = {
	token: null,
	fetching: false,
	phone: null,
	email: null,
	error: null,
	country: null,
}

const AuthReducer = (state = initialState, action: any): AuthState => {
	const { type, payload, params } = action
	const cookies = new Cookies()
	switch (type) {
		case AUTH_SET_TOKEN: {
			const { token } = payload as AuthSuccessResponse
			cookies.set('token', token, {
				// maxAge: 60 * 60 * 1000,
				// httpOnly: true,
				// secure: true,
				sameSite: 'strict',
				path: '/'
			})
			return {
				...state,
				fetching: true,
				token,
			}
		}
		case AUTH_REQUEST: {
			const { phone } = params as AuthRequest
			return { ...state, error: null, fetching: true, phone }
		}
		case AUTH_SUCCESS: {
			return { ...state, fetching: false }
		}
		case AUTH_FAILURE: {
			const { error } = payload
			return { ...state, fetching: false, error }
		}
		case AUTH_BY_EMAIL_REQUEST: {
			const { email } = params as AuthByEmailRequest
			return { ...state, error: null, fetching: true, email }
		}
		case AUTH_BY_EMAIL_SUCCESS: {
			return { ...state, fetching: false }
		}
		case AUTH_BY_EMAIL_FAILURE: {
			const { error } = payload
			return { ...state, fetching: false, error }
		}
		case AUTH_SET_COUNTRY: {
			const { country } = params
			return { ...state, country }
		}
		case AUTH_LOGOUT_REQUEST: {
			return { ...state }
		}
		case AUTH_LOGOUT_SUCCESS: {
			cookies.remove('token')
			return { ...initialState }
		}
		case AUTH_LOGOUT_FAILURE: {
			return { ...state }
		}
		default:
			return state
	}
}

export default AuthReducer
