import React from 'react'
import {
	View,
	ViewStyle,
	StyleSheet,
	TouchableOpacity,
	Text,
	Image,
	ImageStyle,
} from 'react-native'
import { useNavigation, Link } from '@react-navigation/native'
import { useSelector } from 'react-redux';

import { Icon, IconsType, LinkButton, Menu, MenuRowType, Badge } from '../'
import { AppState } from '../../store'
import { font, useTheme, useWindowMetrics } from '../../theme';
import { t } from '../../localization'
import { logo_mini, notepad } from '../../images'
import config from '../../config';

type HeaderMenuRowType = {
	text: string
	leftIcon?: IconsType
	leftIconColor?: string
	rightIcon?: IconsType
	rightIconColor?: string
	to?: string | { screen: string, params?: any }
	customItem?: JSX.Element
	badge?: number | string
	rowStyle?: ViewStyle | ViewStyle[]
	type?: 'primaty-button' | 'secondary-button'
	maxLength?: number
}

export default function HeaderWithMenu() {
	const { colors } = useTheme()

	const { firstname } = useSelector((state: AppState) => state.user.profile) || {}
	const notificationsCount = !config.isB2B
		? useSelector(
			(state: AppState) => state.notifications.notifications?.filter(n => !n.readed_at)?.length
		)
		: null
	const unread = !config.isB2B ? useSelector((state: AppState) => state.chat.unread) : 0

	const navigation = useNavigation()

	const onPressNotifications = () => navigation.navigate('MainStack', { screen: 'NotificationsScreen' })

	const getMenu = (): HeaderMenuRowType[] => {
		return ([
			{ text: t('appointment'), leftIcon: 'calendar', to: '/appointments' },
			{ text: t('tests'), leftIcon: 'test', to: '/tests' },
			{ text: t('articles'), leftIcon: 'test', to: '/articles' },
			{ text: t('questions'), leftIcon: 'message', to: '/questions' },
			{ text: t('faq'), leftIcon: 'faq', to: '/faq' },
			{ text: t('feedback'), leftIcon: 'support_chat', to: '/support', badge: unread },
			{ text: t('instruction'), leftIcon: 'book', to: '/instructions' },
			{ text: t('about_app_web'), leftIcon: 'about', to: '/about' },
			// { text: firstname, leftIcon: 'user', to: '/profile' },
		])
	}

	const { width: screenWidth } = useWindowMetrics()
	const isWidthLess1800 = screenWidth < 1800

	const renderTrackerBtn = () => (
		<LinkButton to={!config.isB2B ? '/trackers' : '/'}>
			<View style={[trackerButtonStyles.container, {
				backgroundColor: colors.menu.button.background,
			}]}>
				<View style={trackerButtonStyles.content}>
					<Image
						style={trackerButtonStyles.image}
						source={notepad}
						resizeMode={'contain'}
					/>
					<Text style={[
						trackerButtonStyles.text,
						{ color: colors.menu.button.actionText },
					]}>{t('trackers')}</Text>
				</View>
			</View>
		</LinkButton>
	)

	const renderMenuItem = (item: HeaderMenuRowType, idx: number = 0, maxLength: number = -1) => (
		<View key={idx} style={{ paddingHorizontal: 15 }}>
			<LinkButton to={ item.to }>
				{item.leftIcon && (
					<View>
						<Icon
							style={style.leftIcon}
							name={item.leftIcon}
							color={item.leftIconColor || colors.icon.default}
						/>
						<Badge count={item.badge} style={badgeStyle.badge} />
					</View>
				)}
				<Text style={[style.text, { color: colors.text.title }]}>
					{
						maxLength > -1 && item.text.length > maxLength
							? `${item.text.substring(0, maxLength - 3)}...`
							: item.text
					}
				</Text>
				{item.rightIcon && !item.customItem && (
					<Icon
						style={style.rightIcon}
						name={item.rightIcon}
						color={item.rightIconColor || colors.icon.default}
					/>
				)}
				{!item.rightIcon && item.customItem}
			</LinkButton>
		</View>
	)

	const renderMenu = () => getMenu().map((item, i) => renderMenuItem(item, i))

	const getDropdownMenu = (): MenuRowType[] => {
		return ([
			{ text: t('profile'), leftIcon: 'user', onPress: () => navigation.navigate('MainStack', { screen: 'ProfileScreen' }) },
			{ text: t('appointment'), leftIcon: 'calendar', onPress: () => navigation.navigate('MainStack', { screen: 'AppointmentListScreen' }) },
			{ text: t('tests'), leftIcon: 'test', onPress: () => navigation.navigate('TestStack', { screen: 'TestListScreen' }) },
			{ text: t('articles'), leftIcon: 'test', onPress: () => navigation.navigate('ArticleStack', { screen: 'ArticleListScreen' }) },
			{ text: t('questions'), leftIcon: 'message', onPress: () => navigation.navigate('MainStack', { screen: 'QuestionStack' }) },
			{ text: t('faq'), leftIcon: 'faq', onPress: () => navigation.navigate('MainStack', { screen: 'FaqScreen' }) },
			{ text: t('feedback'), leftIcon: 'support_chat', onPress: () => navigation.navigate('MainStack', { screen: 'SupportChatScreen' }), badge: unread },
			{ text: t('instruction'), leftIcon: 'book', onPress: () => navigation.navigate('MainStack', { screen: 'InstructionsScreen' }) },
			{ text: t('about_app_web'), leftIcon: 'about', onPress: () => navigation.navigate('MainStack', { screen: 'AboutAppScreen' }) },
		])
	}

	return (
		<View style={[style.headerContainer, { backgroundColor: colors.background.primary }]}>
			<View style={style.headerWrapper}>
				<Link to={'/'}>
					<Image
						style={style.logoMini}
						source={logo_mini}
						resizeMode={'contain'}
					/>
				</Link>
				{ !isWidthLess1800 && !config.isB2B && (
					<View style={[
						style.menuWrapper,
						// !isWidthLess1300 ? {
						// 	position: 'absolute',
						// 	left: '50%',
						// 	top: '50%',
						// 	transform: 'translate(-50%, -50%)',
						// } : {}
					]}>
						{ renderMenu() }
					</View>
				)}
				<View style={{ flexDirection: 'row', alignItems: 'center' }}>
					{ isWidthLess1800 && !config.isB2B && (
						<Menu
							data={getDropdownMenu()}
							button
						/>
					)}
					{renderTrackerBtn()}
					{renderMenuItem({ text: firstname || t('profile'), leftIcon: 'user', to: '/profile' }, 0, 19) }
					{!config.isB2B && (
						<TouchableOpacity
							activeOpacity={1}
							hitSlop={{ top: 4, bottom: 4, left: 4, right: 4 }}
							style={[style.icon, { backgroundColor: colors.background.secondary }]}
							onPress={onPressNotifications}
						>
							<Icon name={'bell'} />
							{!!notificationsCount && (
								<View style={[style.notificationBadge, { backgroundColor: colors.badge.background }]} />
							)}
						</TouchableOpacity>
					)}
				</View>
			</View>
		</View>
	)
}

const style = StyleSheet.create({
	headerContainer: {
		zIndex: 1,
	},
	headerWrapper: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingHorizontal: 20,
		height: 60,
	},
	logoMini: {
		alignSelf: 'center',
		width: 112,
		height: 15,
		marginRight: 20,
	} as ImageStyle,
	menuWrapper: {
		flex: 1,
		justifyContent: 'center',
		flexDirection: 'row',
	},
	leftIcon: {
		marginRight: 7,
		opacity: 0.4,
	},
	rightIcon: {
		marginLeft: 7,
		opacity: 0.4,
	},
	text: {
		flex: 1,
		fontFamily: font('bold'),
		fontSize: 16,
	},
	icon: {
		width: 40,
		height: 40,
		borderRadius: 10,
		overflow: 'hidden',
		marginLeft: 9,
		alignItems: 'center',
		justifyContent: 'center',
	} as ViewStyle,
	notificationBadge: {
		width: 12,
		position: 'absolute',
		height: 12,
		borderRadius: 6,
		overflow: 'hidden',
		top: 7,
		right: 7,
	} as ViewStyle,
	// badge: {
	// 	top: -4,
	// 	right: 0,
	// },
});

const badgeStyle = {
	badge: {
		top: -2,
		right: 8,
	},
}

const trackerButtonStyles = StyleSheet.create({
	container: {
		borderRadius: 20,
		paddingHorizontal: 20,
		height: 40,
		justifyContent: 'center',
	},
	content: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
	image: {
		marginRight: 10,
		width: 24,
		height: 24,
	},
	text: {

	},
})