import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View, ViewStyle, Platform } from 'react-native';
import { NotificationType } from '../types';
import { font, useTheme } from '../theme';
import moment from 'moment'
import 'moment/locale/ru'
import { Markdown } from '.';
moment().locale('ru')

interface NotificationItemProps {
	style?: ViewStyle | ViewStyle[]
	notification: NotificationType
	onPress: (notification: NotificationType) => void
}

export default function NotificationItem({
	notification,
	onPress,
	style,
}: NotificationItemProps) {

	const { colors } = useTheme()
	const diff = moment.duration(moment().diff(notification.sended_at)).asHours()
	const date = diff < 24 ? moment(notification.sended_at).fromNow() : moment(notification.sended_at).format('DD MMMM YYYY, HH:mm')

	return (
		<TouchableOpacity
			style={[styles.container, { backgroundColor: colors.background.primary }, style]}
			onPress={() => onPress(notification)}
		>
			{!notification.readed_at && (
				<View style={[styles.badge, { backgroundColor: colors.badge.background }]} />
			)}
			<View style={{ flex: Platform.OS !== 'web' ? 0 : 1 }}>
				<Text
					style={[styles.title, { color: colors.text.default }]}
				>{notification.title}</Text>
				<Markdown
					paragraphStyle={[styles.message, { color: colors.text.default }]}
					style={{ marginTop: 8 }}
					text={notification.message}
					onLinkPress={() => onPress(notification)}
				/>
				<Text
					style={[styles.date, { color: colors.text.date }]}
				>{date}</Text>
			</View>
		</TouchableOpacity>
	)
}

const styles = StyleSheet.create({
	container: {
		borderRadius: 20,
		overflow: 'hidden',
		padding: 16,
		flexDirection: 'row',
	},
	title: {
		flex: 1,
		fontFamily: font('bold'),
		fontSize: 16,
		lineHeight: 19,
	},
	message: {
		marginTop: 8,
		fontFamily: font(),
		fontSize: 14,
		lineHeight: 18,
	},
	date: {
		textAlign: 'left',
		marginTop: 8,
		fontFamily: font(),
		fontSize: 14,
		lineHeight: 18,
	},
	badge: {
		marginTop: 4,
		marginLeft: 1,
		marginRight: 9,
		width: 10,
		height: 10,
		borderRadius: 5,
	}
})
