import mime from 'mime'
import { showMessage } from 'react-native-flash-message'
import moment from 'moment-timezone'
import 'moment/locale/ru'

import { t } from './localization'
import { ru, by, kz, uz } from './images'
import {
  ChatMessageSuccessResponse,
  ChatMessageType,
  ChatSendMessageSuccessResponse,
  FileDeleteResponse,
  FileUploadProgress,
  GenderType,
  Nullable,
  PickedFileType,
  UploadedFileType,
  UploadFileType,
  PhoneCountry,
} from './types'
import config from './config'

moment().locale('ru')

export function getNumber(_value: string) {
  const value = !!_value ? _value : ''
  const code = value.substring(0, 3)
  const operator = `(${code}${code.length == 3 ? ')' : ''}`

  const _number = [
    value.substring(3, 6),
    value.substring(6, 8),
    value.substring(8),
  ]
  const _a = `${_number[0]}${_number[0].length == 3 ? '-' : ''}`
  const _b = `${_number[1]}${_number[1].length == 2 ? '-' : ''}`
  const number = `${_a}${_b}${_number[2]}`
  return `${operator}${!!number ? ` ${number}` : ''}`
}

export function capitalize(text: string) {
  return text.substring(0, 1).toUpperCase() + text.substring(1)
}

export function sortString(a: string, b: string) {
  return a == b ? 0 : a < b ? -1 : 1
}

export type FilePickerSourceType = 'gallery' | 'document' | 'camera'

type FilePickerType = {
  maxCount?: Nullable<number>
  selected?: Array<UploadFileType | PickedFileType>
  maxFileSize?: Nullable<number> // Mb !!!
  maxTotalSize?: Nullable<number> // Mb !!!
  extensions?: Nullable<Array<string>>
  files: Array<UploadFileType | PickedFileType>
}

export function acceptedUploadedFileTypes(arrFileExtensions: Array<string>) {
  const arrFileTypes = arrFileExtensions.map(ext => mime.getType(ext))
  return [...new Set(arrFileTypes)].join(',')
}

export function filePicker(params: FilePickerType): PickedFileType[] {
  const { maxCount, selected = [], maxFileSize, maxTotalSize, extensions, files } = params

  let errorFiles = [] as Array<PickedFileType>
  let resultFiles = [] as Array<PickedFileType>
  let size = 0

  let errorMessage = ''
  let maxFilesError = false
  let maxSizeError = false
  let totalSizeError = false

  for (const file of files) {
    const fileNameParts = file.name.split('.')
    const extension = fileNameParts.pop() || ''
    const fileSize = file.size
    const extensionOk = !!extensions ? extensions.includes(extension) : true
    const sizeOk = !!maxFileSize ? fileSize < maxFileSize * 1024 ** 2 : true
    const totalSizeOk = !!maxTotalSize ? size + fileSize < maxTotalSize * 1024 ** 2 : true
    const isNewFile = selected.findIndex(item => (item as UploadFileType)?.original === file.name) == -1
    const countOk = !!maxCount ? resultFiles.length + selected.length < maxCount : true

    maxFilesError = !countOk || maxFilesError
    maxSizeError = !sizeOk || maxSizeError
    totalSizeError = !totalSizeOk || totalSizeError

    if (isNewFile && extensionOk && sizeOk && totalSizeOk && countOk) {
      resultFiles.push(file)
      size += fileSize
    } else {
      if (!extensionOk) {
        if (!!errorMessage) errorMessage += `\nФайл ${file.name} не поддерживается`
        else errorMessage = `Файл ${file.name} не поддерживается`
      }
      if (!isNewFile) {
        if (!!errorMessage) errorMessage += `\nФайл ${file.name} уже добавлен`
        else errorMessage = `Файл ${file.name} уже добавлен`
      }
      errorFiles.push(file)
    }
  }

  if (maxFilesError) {
    if (!!errorMessage) errorMessage += `\nМожно прикрепить не более ${maxCount} файлов`
    else errorMessage = `Можно прикрепить не более ${maxCount} файлов`
  }
  if (maxSizeError) {
    if (!!errorMessage) errorMessage += `\nМаксимальный размер файла ${maxFileSize}Мб`
    else errorMessage = `Максимальный размер файла ${maxFileSize}Мб`
  }
  if (totalSizeError) {
    if (!!errorMessage) errorMessage += `\nОбщий размер файлов не может превышать ${maxTotalSize} файлов`
    else errorMessage = `Общий размер файлов не может превышать ${maxTotalSize}Мб`
  }

  if (!!errorMessage) {
    showMessage({
      type: 'danger',
      message: errorMessage,
      duration: 3000,
    })
  }
  return resultFiles
}

interface ClientType {
  lastname: string
  firstname: string
  birthdate: string
  gender: GenderType
  email?: string
  phone?: string
  companyName?: string
  companySite?: string
}
export function checkClientData(
  client: ClientType,
  checkAge: boolean = true,
  checkEmail: boolean = false,
  checkPhone: boolean = false,
): boolean {
  const { lastname, firstname, gender, birthdate, email, phone, companyName } = client
  const minAge = 15

  const err = []
  if (!lastname) err.push(t('lastname'))
  if (!firstname) err.push(t('firstname'))
  if (gender === null) err.push(t('gender'))
  if (!birthdate) err.push(t('birthdate'))
  if (checkEmail && !email && !config.isB2B) err.push(t('email'))
  if (checkPhone && !phone && !config.isB2B) err.push(t('phone_number'))
  if (!companyName && config.isB2B) err.push(t('company_name'))
  if (!!err.length) {
    alert(`${t('fill_req_fields')}:\n${err.join('\n')}`)
  } else {
    const errors = []
    const diff = Math.floor(moment.duration(moment().diff(birthdate || moment())).asYears())
    if (checkAge && diff < minAge) errors.push(t('min_age', [minAge]))
    if (email && !isEmailValid(email) && !config.isB2B) errors.push(t('email_invalid'))
    if (phone && phone.length < 10 && !config.isB2B) errors.push(t('phone_invalid'))

    if (!!errors.length) {
      alert(`${t('error')}:\n${errors.join('\n')}`)
    } else {
      return true
    }
  }
  return false
}

export function isEmailValid(email: string) {
  if (!email) return true

  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(email.toLowerCase())
}

export function getTimeZone() {
  const _offset = moment().utcOffset()
  const abs = Math.abs(_offset)
  const h = Math.floor(abs / 60)
  const m = (abs % 60)
  return `${_offset < 0 ? '-' : '+'}${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}`
}

export function getFullName(lastname: string, firstname: string, middlename?: string) {
  if (!lastname && !firstname && !middlename) return undefined
  let result = ''
  if (!!lastname) result += lastname
  if (!!firstname) result += !!result ? ` ${firstname}` : firstname
  if (!!middlename) result += !!result ? ` ${middlename}` : middlename
  return result
}

export function chatMessageSuccessWorker(state_messages: Nullable<ChatMessageType[]>, payload: ChatMessageSuccessResponse, isNew: boolean) {
  const { messages, page } = payload
  const new_messages = messages.map((message) => {
    if (!message.user) message.user = message.client
    return message
  })
  let _messages = [] as ChatMessageType[]
  if (isNew) {
    _messages = new_messages
  } else {
    _messages = [...(state_messages || [])]
    new_messages.forEach((message) => {
      const index = _messages.findIndex((item) => item.id == message.id)
      if (index == -1) {
        _messages.push(message)
      }
    })
  }
  return _messages
}

export function chatSendMessageSuccessWorker(messages: Nullable<ChatMessageType[]>, payload: ChatSendMessageSuccessResponse) {
  const { message } = payload
  message.user = message.client
  const _messages = [...(messages || [])]
  const index = _messages.findIndex(msg => !msg.id)
  _messages[index] = message
  return _messages
}

export function filesUploadRequestWorker(state_files: UploadFileType[], params: PickedFileType[]) {
  let files = [...state_files]
  params.forEach((file, index) => {
    files.push({
      progress: 0,
      fetching: true,
      id: `${file.name}_${file.size}_${index}`,
      uri: '',
      name: file.name,
      size: file.size,
      type: file.type,
      original: '',
      size_unit: '',
      url: '',
      thumbnail_url: null
    })
  })
  return files
}

export function filesUploadProgressWorker(state_files: UploadFileType[], payload: FileUploadProgress) {
  const { id, progress } = payload
  const files = [...state_files]
  const index = files.findIndex((file) => file.id == id)
  if (index != -1) {
    files[index].progress = progress
  }
  return files
}

export function filesUploadSuccessWorker(state_files: UploadFileType[], payload: Array<{ id: string; uri: string }>) {
  const files = [...state_files]
  payload.forEach((item) => {
    const index = files.findIndex((file) => file.uri == item.uri)
    if (index != -1) {
      files[index].id = item.id
    }
  })
  return files
}

export function filesUploadDoneWorker(state_files: UploadFileType[], payload: { id: string; file: UploadedFileType }) {
  const files = [...state_files]
  const index = files.findIndex((file) => file.id == payload.id)
  if (index != -1) {
    if (Object.keys(payload.file).length == 0) {
      files.splice(index, 1)
      showMessage({
        type: 'danger',
        message: t('unsupported_type'),
      })
    } else {
      files[index] = { ...files[index], ...payload.file, fetching: false }
    }
  }
  return files
}

export function deleteFileRequestWorker(state_files: UploadFileType[], id: number | string) {
  const files = [...state_files]
  if (typeof id == 'string') {
    const index = files.findIndex((file) => file.id == id)
    if (index != -1) {
      files.splice(index, 1)
    }
  }
  return files
}

export function deleteFileSuccessWorker(state_files: UploadFileType[], payload: FileDeleteResponse) {
  const { success, id } = payload
  const files = [...state_files]
  if (success) {
    const index = files.findIndex((file) => file.id == id)
    files.splice(index, 1)
  }
  return files
}

export function getUtcOffset() {
  const offset = moment().utcOffset() * 60
  return offset
}

export async function saveBase64File(base64: string, fileName: string) {
  let a = document.createElement('a')
  a.href = `data:application/octet-stream;base64,${base64}`
  a.download = fileName
  a.click()
  a.remove()
}

const timezones: any = {
  'Asia/Anadyr': 'Анадырь',
  'Asia/Barnaul': 'Барнаул',
  'Asia/Chita': 'Чита',
  'Asia/Irkutsk': 'Иркутск',
  'Asia/Kamchatka': 'Петропавловск-Камчатский',
  'Asia/Khandyga': 'Хандыга',
  'Asia/Krasnoyarsk': 'Красноярск',
  'Asia/Magadan': 'Магадан',
  'Asia/Novokuznetsk': 'Новокузнецк',
  'Asia/Novosibirsk': 'Новосибирск',
  'Asia/Omsk': 'Омск',
  'Asia/Sakhalin': 'Южно-Сахалинск',
  'Asia/Srednekolymsk': 'Среднеколымск',
  'Asia/Tomsk': 'Томск',
  'Asia/Ust-Nera': 'Усть-Нера',
  'Asia/Vladivostok': 'Владивосток',
  'Asia/Yakutsk': 'Якутск',
  'Asia/Yekaterinburg': 'Екатеринбург',
  'Europe/Astrakhan': 'Астрахань',
  'Europe/Kaliningrad': 'Калининград',
  'Europe/Kirov': 'Киров',
  'Europe/Moscow': 'Москва',
  'Europe/Samara': 'Самара',
  'Europe/Saratov': 'Саратов',
  'Europe/Simferopol': 'Симферополь',
  'Europe/Ulyanovsk': 'Ульяновск',
  'Europe/Volgograd': 'Волгоград',
}
export function getTimezoneNote() {
  const timezone = moment.tz.guess(true)
  const timezoneCity = timezones[timezone] || timezone.split('/')[1] || timezone
  const timezoneTxt = `${timezoneCity}, UTC ${moment().format('Z')}`
  return t('timezone').replace('%s', timezoneTxt)
}

export function plural(number: number, titles: string[]) {
  number = Math.abs(number);
  if (Number.isInteger(number)) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
  }
  return titles[1];
}

export const countries: PhoneCountry[] = [
	{
    id: 'ru',
		flag: ru,
		name: t('russia'),
		code: 7,
		mask: '(999) 999-99-99',
	},
	{
    id: 'kz',
		flag: kz,
		name: t('kazakhstan'),
		code: 7,
		mask: '(999) 999-99-99',
	},
	{
    id: 'uz',
		flag: uz,
		name: t('uzbekistan'),
		code: 998,
		mask: '99-999-9999',
	},
	{
    id: 'by',
		flag: by,
		name: t('belarus'),
		code: 375,
		mask: '(99) 999-99-99',
	},
]