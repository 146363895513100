import React, { useState, useEffect, useRef } from 'react'
import {
	Animated,
	ScrollView,
	StyleSheet,
	Text,
	TouchableOpacity,
	TouchableWithoutFeedback,
	View,
	ViewStyle,
} from 'react-native'
import Button from './Button'
import { font, useTheme } from '../theme'
import Badge from './Badge'
import Icon, { IconsType } from './Icon'

export type MenuRowType = {
	text: string
	leftIcon?: IconsType
	leftIconColor?: string
	rightIcon?: IconsType
	rightIconColor?: string
	onPress?: () => void
	customItem?: JSX.Element
	badge?: number | string
	rowStyle?: ViewStyle | ViewStyle[]
	type?: 'primaty-button' | 'secondary-button'
}

interface MenuProps {
	data: MenuRowType[]
	scrollEnabled?: boolean
	showsVerticalScrollIndicator?: boolean
	bottomBorders?: boolean
	lastBottomBorder?: boolean
	button?: boolean
	style?: ViewStyle | ViewStyle[]
}

export default function (props: MenuProps) {
	if (props.button) {
		return (<MenuButton  {...props} />)
	} else {
		return <Menu {...props} />
	}
}

function MenuButton({
	data,
	scrollEnabled = false,
	bottomBorders = false,
	lastBottomBorder = false,
	style,
}: MenuProps) {
	const iconButtonRef = useRef(null)
	const dropdownMenuRef = useRef(null)
	const { colors } = useTheme()
	const [collapsed, setCollapsed] = useState(true)
	const [isHovered, setIsHovered] = useState(false)
	const badge = data.filter(item => !!(+item.badge)).map(item => item.badge as number).reduce((a, b) => a + b, 0)

	useEffect(() => {
		iconButtonRef.current.setNativeProps({ id: 'btnDropdownMenu' })
	}, [])

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownMenuRef.current && !dropdownMenuRef.current.contains(event.target) && !document.getElementById('btnDropdownMenu')?.contains(event.target)) {
				setCollapsed && setCollapsed(true);
			}
		}

		document.addEventListener('mousedown', handleClickOutside, true)
		document.addEventListener('touchstart', handleClickOutside, true)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside, true)
			document.removeEventListener('touchstart', handleClickOutside, true)
		}
	}, [ collapsed ])

	return (
		<>
			<Animated.View
				style={[menuButtonStyles.container, style]}
				ref={iconButtonRef}
			>
				<TouchableWithoutFeedback onPress={() => setCollapsed(!collapsed)}>
					<View
						style={[menuButtonStyles.buttonContent, {
							opacity: isHovered ? 0.5 : 1,
							backgroundColor: colors.button.primary.background,
						}]}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
					>
						<Icon
							name={'burger'}
							color={colors.menu.button.actionIcon}
						/>
						<Badge count={badge} style={badgeStyles.menuBadge} />
					</View>
				</TouchableWithoutFeedback>
				<Animated.View
					style={[
						menuButtonStyles.containerDropdown, {
							backgroundColor: colors.menu.button.background,
							shadowColor: colors.shadow,
						},
						style,
						{
							display: !collapsed ? 'flex' : 'none',
						}
					]}
					ref={dropdownMenuRef}
				>
					<ScrollView
						contentContainerStyle={{
							paddingVertical: 7.5,
							paddingRight: 10,
							paddingLeft: 12.5,
						}}
						bounces={false}
						scrollEnabled={scrollEnabled}
					>
						{data.map((row, index) => (
							<View key={index.toString()}
								style={[menuButtonStyles.rowContainer, {
									borderBottomColor: colors.border.default,
									borderBottomWidth: bottomBorders
										? !lastBottomBorder && index == data.length - 1 ? 0 : 1
										: 0
								}]} >
								<TouchableOpacity style={menuButtonStyles.row} onPress={() => {
									!!row.onPress && row.onPress()
									setCollapsed(true)
								}}>
									{row.leftIcon && (
										<View style={menuButtonStyles.leftIcon}>
											<Icon
												size={20}
												name={row.leftIcon}
												color={row.leftIconColor || colors.menu.button.icon}
											/>
											<Badge count={row.badge} style={badgeStyles.badge} />
										</View>
									)}
									<Text style={[menuButtonStyles.text, { color: colors.menu.button.text }]}>{row.text}</Text>
									{row.rightIcon && !row.customItem && (
										<Icon
											style={menuButtonStyles.rightIcon}
											name={row.rightIcon}
											color={row.rightIconColor || colors.icon.default}
										/>
									)}
									{!row.rightIcon && row.customItem}
								</TouchableOpacity>
							</View>
						))}
					</ScrollView>
				</Animated.View>
			</Animated.View>
		</>
	)
}

const menuButtonStyles = StyleSheet.create({
	container: {
		marginRight: 10,
	},
	containerDropdown: {
		borderRadius: 10,
		position: 'absolute',
		top: 60,
    right: 0,
	},
	rowContainer: {
		borderBottomWidth: 1,
		borderBottomColor: 'red'
	},
	row: {
		flexDirection: 'row',
		marginVertical: 7.5,
		alignItems: 'center',
	},
	leftIcon: {
		marginRight: 10,
	},
	rightIcon: {
		marginLeft: 10,
	},
	buttonContent: {
		alignItems: 'center',
		justifyContent: 'center',
		transitionDuration: '150ms',
		cursor: 'pointer',
		width: 40,
		height: 40,
		borderRadius: 20,
	},
	text: {
		fontSize: 16,
		lineHeight: 24,
		fontFamily: font('bold'),
		whiteSpace: 'nowrap',
	},
	button: {
		position: 'absolute',
		right: 0,
		bottom: 0,
		width: 40,
		height: 40,
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 10,
		overflow: 'hidden',
	},
})

const badgeStyles = {
	badge: {
		top: 0,
		right: 0,
	},
	menuBadge: {
		right: 8,
		top: 2,
	},
}

function Menu({
	data,
	scrollEnabled = false,
	bottomBorders = false,
	lastBottomBorder = false,
	style,
}: MenuProps) {

	const { colors } = useTheme()

	return (
		<ScrollView
			style={{ flexGrow: 0 }}
			scrollEnabled={scrollEnabled}
			bounces={false}
			contentContainerStyle={style}
		>
			{data.map((row, index) => {
				const { type, text, rowStyle, leftIcon, leftIconColor, onPress, badge } = row
				if (type === 'primaty-button' || type === 'secondary-button') {
					return (
						<Button
							key={index.toString()}
							text={text}
							style={rowStyle}
							type={type === 'primaty-button' ? 'primary' : 'secondary'}
							leftIcon={leftIcon}
							leftIconColor={leftIconColor}
							onPress={onPress}
							badgeCount={badge as number}
						/>
					)
				}
				return (
					<TouchableOpacity
						key={index.toString()}
						style={[
							menuStyles.row,
							{
								borderBottomColor: colors.border.secondary,
								borderBottomWidth: bottomBorders
									? !lastBottomBorder && index == data.length - 1 ? 0 : 1
									: 0
							},
							row.rowStyle
						]}
						onPress={row.onPress}
					>
						{row.leftIcon && (
							<Icon
								style={menuStyles.leftIcon}
								name={row.leftIcon}
								color={row.leftIconColor || colors.icon.default}
							/>
						)}
						<Text style={[menuStyles.text, { color: colors.text.default }]}>{row.text}</Text>
						{row.rightIcon && !row.customItem && (
							<Icon
								style={menuStyles.rightIcon}
								name={row.rightIcon}
								color={row.rightIconColor || colors.icon.default}
							/>
						)}
						{!row.rightIcon && row.customItem}
					</TouchableOpacity>
				)
			})}
		</ScrollView>
	)
}

const menuStyles = StyleSheet.create({
	row: {
		flexDirection: 'row',
		paddingVertical: 16,
		alignItems: 'center',
	},
	leftIcon: {
		marginRight: 10,
	},
	rightIcon: {
		marginLeft: 10,
	},
	text: {
		flex: 1,
		fontFamily: font(),
		fontSize: 16,
		lineHeight: 24,
	},
})
