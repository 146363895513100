import React from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'
import { StackScreenProps } from '@react-navigation/stack'

import { Chat } from '.'
import { AppState } from '../store/redux'
import {
  conferenceChatDeleteFileRequestAction,
  conferenceChatFilesUploadRequestAction,
  conferenceChatMessageRequestAction,
  conferenceChatReadAction,
  conferenceChatSendMessageRequestAction,
} from '../store/redux/conference'
import {
  CallNavigatorProps,
  PickedFileType,
  UploadFileType,
} from '../types'

type ScreenProps = DispatchProps & StateProps & StackScreenProps<CallNavigatorProps, 'ConferenceChatScreen'>

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

interface Props extends ScreenProps { }

interface State { }

class ConferenceChat extends React.Component<Props, State>{

  componentDidMount() {
    this.readChat()
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    this.readChat()
  }

  render() {
    const {
      messages,
      client_id,
      files,
      fetching,
    } = this.props
    return (
      <View style={{ flex: 1 }}>
        <Chat
          client_id={client_id}
          files={files}
          onDeleteFilePress={this.onDeleteFilePress}
          sendMessage={this.sendMessage}
          onAddFiles={this.onAddFiles}
          onEndReached={this.onEndReached}
          fetching={fetching}
          messages={messages}
        />
      </View>
    )
  }

  onDeleteFilePress = (params: UploadFileType) => {
    const { deleteFile } = this.props
    deleteFile(params.id)
  }

  onAddFiles = (params: PickedFileType[]) => {
    const { addFiles, chat_id } = this.props
    addFiles({ id: chat_id, files: params })
  }

  onEndReached = () => {
    const {
      page,
      limit,
      total,
      fetching,
      chat_id,
      getMessages,
    } = this.props
    if (!fetching && page * limit < total) {
      getMessages({ id: chat_id, page: page + 1 })
    }
  }

  sendMessage = ({ message, files, appended }) => {
    const { sendMessage, chat_id } = this.props
    sendMessage({ id: chat_id, message, files, appended })
  }

  readChat = () => {
    const { unread, chatRead, chat_id } = this.props
    !!unread && chatRead(chat_id)
  }

}

const mapStateToProps = (state: AppState) => ({
  client_id: state.conference.chat?.client._id || 0,
  messages: state.conference.messages,
  page: state.conference.page,
  limit: state.conference.limit,
  total: state.conference.total,
  fetching: state.conference.messages_fetching,
  files: state.conference.files,
  unread: state.conference.unread,
  chat_id: state.conference.chat?.id || 0,
})

const mapDispatchToProps = {
  sendMessage: conferenceChatSendMessageRequestAction,
  getMessages: conferenceChatMessageRequestAction,
  addFiles: conferenceChatFilesUploadRequestAction,
  deleteFile: conferenceChatDeleteFileRequestAction,
  chatRead: conferenceChatReadAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConferenceChat)
