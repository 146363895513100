import React, { useEffect, useState } from 'react'
import { StyleSheet, Text, View, Image, Platform, Linking } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'

import { t } from '../localization'
import { getFullName } from '../service'
import { font, useTheme } from '../theme'
import { DoctorType } from '../types'
import Modal from './Modal'
import { Icon, Markdown, Button } from '.'
import { appointmentGenderRequestAction } from '../store/redux/appointments'
import { AppState } from '../store'

interface DoctorModalProps {
  isVisible: boolean
  onClose: () => void
  doctor: DoctorType
}

export default function ({
  isVisible,
  onClose,
  doctor,
}: DoctorModalProps) {
  const { colors } = useTheme()
  const navigation = useNavigation()
  const dispatch = useDispatch()

  const genderList = useSelector((state: AppState) => state.appointment.doctor_gender_list)
  const { token } = useSelector((state: AppState) => state.auth)

  const [isAddNewAppointment, setIsAddNewAppointment] = useState(false)

  useEffect(() => {
    if (isAddNewAppointment && genderList) {
      navigation.navigate('AppointmentStack', {
        screen: 'NewAppointmentScreen',
        params: { serviceId: doctor.service_id },
      })
      onClose()
    }
  }, [genderList, isAddNewAppointment])

  const renderSocialButtons = () => {
    const isSocial = doctor.vk || doctor.whatsapp
    return isSocial ? (
      <View style={styles.socialButtonsContainer}>
        {doctor.vk && (
          <Icon
            style={{ marginRight: 10 }}
            name={'vk_square'}
            color={colors.icon.white}
            backgroundColor={colors.icon.default}
            size={Platform.OS !== 'web' ? 30 : 40}
            onPress={async () => {
              await Linking.canOpenURL(doctor.vk)
              Linking.openURL(doctor.vk)
            }}
          />
        )}
        {doctor.whatsapp && (
          <Icon
            style={{ marginRight: 10 }}
            name={'whatsapp_square'}
            color={colors.icon.white}
            backgroundColor={colors.icon.default}
            size={Platform.OS !== 'web' ? 30 : 40}
            onPress={async () => {
              await Linking.canOpenURL(doctor.whatsapp)
              Linking.openURL(doctor.whatsapp)
            }}
          />
        )}
        {doctor.telegram && (
          <Icon
            name={'telegram_square'}
            color={colors.icon.white}
            backgroundColor={colors.icon.default}
            size={Platform.OS !== 'web' ? 30 : 40}
            onPress={async () => {
              await Linking.canOpenURL(doctor.telegram)
              Linking.openURL(doctor.telegram)
            }}
          />
        )}
      </View>
    ) : <></>
  }

  const renderNewAppointmentButton = () => {
    return token && doctor.service_id ? (
      <Button
        style={styles.newAppointmentButton}
        text={t('new_appointment')}
        onPress={() => {
          dispatch(appointmentGenderRequestAction({ service_id: doctor.service_id }))
          setIsAddNewAppointment(true)
        }}
        disabled={!doctor.is_platform}
      />
    ) : <></>
  }

  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      position={Platform.OS !== 'web' ? 'bottom' : 'center'}
    >
      <View style={styles.centered}>
        <View style={[styles.photoContainer, { borderColor: colors.border.default }]}>
          <Image source={{ uri: doctor.photo.original }} style={styles.photo} resizeMode={'contain'} />
        </View>
        <Text style={[styles.fio, { color: colors.text.title }]}>
          {getFullName(doctor.lastname, doctor.firstname, doctor.middlename)}
        </Text>
        <Text style={[styles.position, { color: colors.text.default }]}>
          {doctor.post}
        </Text>
        <Text style={[styles.experience, { color: colors.text.subtitle }]}>
          {t('experience', [doctor.experience])}
        </Text>
      </View>
      <Markdown
        style={styles.markdown}
        text={doctor.description}
      />
      { renderSocialButtons() }
      { renderNewAppointmentButton() }
    </Modal>
  )
}

const styles = StyleSheet.create({
  centered: {
    alignItems: 'center',
  },
  photoContainer: {
    width: 185,
    height: 185,
    overflow: 'hidden',
    borderRadius: 185,
    borderWidth: 1,
  },
  photo: {
    width: '100%',
    height: '100%',
  },
  fio: {
    marginTop: 16,
    fontFamily: font(),
    fontSize: 20,
    lineHeight: 24,
  },
  position: {
    marginTop: 4,
    fontFamily: font(),
    fontSize: 14,
    lineHeight: 20,
  },
  experience: {
    marginTop: 4,
    fontFamily: font('bold'),
    fontSize: 14,
    lineHeight: 20,
  },
  descriptionLabel: {
    fontFamily: font('bold')
  },
  markdown: {
    marginTop: 8,
    marginHorizontal: 16,
  },
  socialButtonsContainer: {
    marginTop: 8,
    marginHorizontal: 16,
    flexDirection: 'row',
  },
  newAppointmentButton: {
    flex: 1,
    marginTop: 16,
    marginHorizontal: 16,
  },
})
