import React, { Component } from 'react';
import { StreamManager } from 'openvidu-browser';

import OpenViduVideoComponent from './OvVideo';

interface Props {
  streamManager: StreamManager,
  autoPlay?: boolean,
  // playsInline: boolean,
  muted?: boolean,
  loop?: boolean,
  type?: 'remote' | 'local',
}

export default class UserVideoComponent extends Component<Props> {
	render() {
		if (!this.props.streamManager) return null
		return <OpenViduVideoComponent {...this.props} />
	}
}
