import I18n from 'i18n-js'
import * as RNLocalize from 'react-native-localize'

import ru from './ru'

const locales = RNLocalize.getLocales()

if (Array.isArray(locales)) {
  I18n.locale = locales[0].languageTag
}

const translations: { [key: string]: Localization } = {
  ru,
}

I18n.fallbacks = true
I18n.defaultLocale = 'ru'
I18n.translations = translations

export default I18n

export function t(message: Translation, replace: Array<string | number> = [], options?: { [key: string]: any }) {
  let _message = I18n.t(message, options)
  replace.forEach((string: string | number) => {
    if (message === 'currency') {
      string = string.toLocaleString('ru-RU', { maximumFractionDigits: 0 })
    }
    _message = _message.replace('%s', string.toString())
  })
  return _message
}
export type Localizations = keyof typeof translations
export type Localization = typeof ru
export type Translation = keyof Localization