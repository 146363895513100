import 'react-native-gesture-handler'
import React, { Component, useEffect } from 'react'
// import { Linking, LogBox } from 'react-native'
import { LogBox } from 'react-native'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
// import OneSignal from 'react-native-onesignal'
import FlashMessage from 'react-native-flash-message'

// import Navigation, { navigate } from './src/navigation'
import config from './src/config'
import SocketService from './src/services/SocketService'
import { ConferenceNotificationAdditionalDataType, Nullable } from './src/types'
// import { conferenceSetAction, conferenceChatRequestAction } from './src/store/redux/conference'
// import { notificationsRequestAction } from './src/store/redux/notifications'

const Navigation = !config.isB2B
  ? require('./src/navigation').default
  : require('./src/b2b/navigation').default

LogBox.ignoreAllLogs(true)

export default function App() {
  const { store, persistor } = !config.isB2B
    ? require('./src/store')
    : require('./src/b2b/store')
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        { !config.isB2B && <PushListener /> }
        { !config.isB2B && <SocketService /> }
        <Navigation />
      </PersistGate>
      <FlashMessage position="top" floating />
    </Provider>
  )
}


interface State {
  data: Nullable<ConferenceNotificationAdditionalDataType>
  refreshNotificationsKey: number
}

export class PushListener extends Component<{}, State> {
  private static PushListenerInstance

  constructor(props: {}) {
    super(props)
    this.state = {
      data: null,
      refreshNotificationsKey: 0,
    }
    PushListener.PushListenerInstance = this
  }

  static addNotification(data: any) {
    PushListener.PushListenerInstance._addNotification(data)
  }

  static refreshNotifications() {
    PushListener.PushListenerInstance._refreshNotifications()
  }

  componentDidMount() {
    // OneSignal.setLogLevel(6, 0);
    // OneSignal.setAppId(config.oneSignalAppID);

    //Method for handling notifications received while app in foreground
    // OneSignal.setNotificationWillShowInForegroundHandler(notificationReceivedEvent => {
    //   let notification = notificationReceivedEvent.getNotification();
    //   console.log("OneSignal.setNotificationWillShowInForegroundHandler", { notification });
    //   // Complete with null means don't show a notification.
    //   if (isVideoCall(notification)) {
    //     setTimeout(() => notificationReceivedEvent.complete(), 0)
    //     const data = notification.additionalData as ConferenceNotificationAdditionalDataType
    //     if (data.session_id !== this.state.data?.session_id) {
    //       this.setState({ data })
    //     }
    //   } else {
    //     setTimeout(() => notificationReceivedEvent.complete(notification), 0)
    //     PushListener.refreshNotifications()
    //   }
    // });

    // OneSignal.setNotificationOpenedHandler(({ notification }) => {
    //   console.log(`OneSignal.setNotificationOpenedHandler`, { notification })
    //   const url = notification.launchURL || 'aidera'
    //   // @ts-ignore
    //   const created_at = notification?.additionalData?.created_at
    //   Linking.canOpenURL(url).then(canOpenUrl => {
    //     if (canOpenUrl) {
    //       Linking.openURL(url)
    //     } else if (isVideoCall(notification)) {
    //       PushListener.addNotification(notification.additionalData)
    //     } else {
    //       PushListener.refreshNotifications()
    //       // @ts-ignore
    //       navigate('NotificationScreen', { created_at })
    //     }
    //   }).catch(error => {
    //     console.log('OneSignal.setNotificationOpenedHandler', error);
    //     PushListener.refreshNotifications()
    //     navigate('NotificationScreen', { created_at })
    //   })
    // });
  }

  render() {
    return (
      <_PushListener
        refreshNotificationsKey={this.state.refreshNotificationsKey}
        params={this.state.data}
      />)
  }

  _addNotification(data: ConferenceNotificationAdditionalDataType) {
    this.setState({ data })
  }

  _refreshNotifications() {
    this.setState({ refreshNotificationsKey: Date.now() })
  }
}

function _PushListener({
  params,
  refreshNotificationsKey,
}: {
  params: Nullable<ConferenceNotificationAdditionalDataType>
  refreshNotificationsKey: number
}) {
  const dispatch = useDispatch()
  const { AppState } = require('./src/store')
  const { conferenceSetAction, conferenceChatRequestAction } = require('./src/store/redux/conference')
  const { notificationsRequestAction } = require('./src/store/redux/notifications')
  const { session, sessionIdsBlacklist } = useSelector((state: typeof AppState) => state.conference)
  const { id } = session || {}

  useEffect(() => {
    const { doctor, session_id, token, appointment_id } = params || {}
    if (!!session_id && id !== session_id && !(sessionIdsBlacklist || []).includes(session_id)) {
      dispatch(conferenceSetAction({ id: session_id, token, doctor }))
      dispatch(conferenceChatRequestAction(appointment_id))
    }
  }, [params, session])

  useEffect(() => {
    if (!!refreshNotificationsKey) {
      dispatch(notificationsRequestAction())
    }
  }, [refreshNotificationsKey])

  return null
}

// function isVideoCall(notification: any) {
//   return !!notification.additionalData?.appointment_id && !!notification.additionalData?.token
// }
