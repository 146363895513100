import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import moment from 'moment'
import 'moment/locale/ru'

moment().locale('ru')

import { font, useTheme } from '../theme'
import { QuestionType } from '../types'
import { plural } from '../service'
import { t } from '../localization'
import { Button } from '.'

export type Type = 'MY' | 'ALL'

export default function QuestionCard({ question, type }: { question: QuestionType, type: Type }) {
	const { colors } = useTheme()
	const navigation = useNavigation()

	const { answers, client: user, content, status } = question
	let gender
	switch (user.gender) {
		case 0:
			gender = 'Женщина'
			break;
		case 1:
			gender = 'Мужчина'
			break;
		default:
			gender = 'Мужчина'
	}

	let labelBgColor;
	let labelTextColor;
	switch (question.status) {
		case 'На модерации':
			labelBgColor = colors.background.labelModeration;
			labelTextColor = colors.text.textModeration;
			break;
		case 'Ожидает ответа психолога':
			labelBgColor = colors.background.labelWaiting;
			labelTextColor = colors.text.textWaiting;
			break;
		case 'Дан ответ':
			labelBgColor = colors.background.correct;
			labelTextColor = colors.text.correct;
			break;
		case 'Отклонено':
			labelBgColor = colors.background.incorrect;
			labelTextColor = colors.text.incorrect;
			break;
		default:
			labelBgColor = colors.background.labelModeration;
			labelTextColor = colors.text.textModeration;
	}

	return (
		<View style={[styles.container, { backgroundColor: colors.background.primary }]}>
			{type === 'ALL' ? (
			<View style={styles.mb10}>
				<Text style={styles.textBold}>{user.fullname || gender}</Text>
			</View>
			) : null}
			<View style={styles.mb12}>
				<Text style={[styles.text, { color: colors.text.default }]}>{content}</Text>
			</View>
			<View style={styles.row}>
				<View style={[styles.label, { backgroundColor: labelBgColor }]}>
					<Text style={{ color: labelTextColor }}>{status}</Text>
				</View>
				{type === 'ALL' ? (
				<Text style={{ color: colors.text.primary }}>
					{`${answers.length} ${plural(answers.length, t('asks_count'))}`}
				</Text>
				) : null}
			</View>
			{type === 'ALL' && status === 'Дан ответ' ? (
			<Button
				style={styles.mb9}
				text={t('show_asks')}
				onPress={() => {
					navigation.navigate('QuestionStack', { screen: 'QuestionScreen', params: { id: question.id } })
				}}
			/>) : null}
		</View>
	)
}
const styles = StyleSheet.create({
	container: {
		marginBottom: 10,
		borderRadius: 20,
		padding: 16,
	},
	mb9: {
		marginTop: 9,
	},
	mb10: {
		marginBottom: 10,
	},
	mb12: {
		marginBottom: 12,
	},
	text: {
		fontFamily: font(),
		fontSize: 14,
	},
	label: {
		paddingHorizontal: 10,
		paddingVertical: 5,
		borderRadius: 5,
		alignSelf: 'flex-start',
	},
	textBold: {
		fontFamily: font('bold'),
	},
	row: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
})