import {
	ApiFailure,
	ApiFailureResponse,
	ServiceSuccessResponse,
	ServiceType,
	Nullable,
	ServiceRequest,
	ServiceByIdRequest,
} from '../../types'

export interface ServiceState {
	services: Nullable<ServiceType[]>
	fetching: boolean
	error: Nullable<ApiFailure>
	page: Nullable<number>
	limit: Nullable<number>
	total: Nullable<number>
	serviceById: Nullable<ServiceType>
	serviceByIdFetching: boolean
	serviceByIdError: Nullable<ApiFailure>
}

export const SERVICE_REQUEST = 'SERVICE_REQUEST'
export const SERVICE_SUCCESS = 'SERVICE_SUCCESS'
export const SERVICE_FAILURE = 'SERVICE_FAILURE'
export const SERVICE_BY_ID_REQUEST = 'SERVICE_BY_ID_REQUEST'
export const SERVICE_BY_ID_SUCCESS = 'SERVICE_BY_ID_SUCCESS'
export const SERVICE_BY_ID_FAILURE = 'SERVICE_BY_ID_FAILURE'

export const serviceRequestAction = (params?: ServiceRequest) => ({ type: SERVICE_REQUEST, params })
export const serviceSuccessAction = (payload) => ({ type: SERVICE_SUCCESS, payload })
export const serviceFailureAction = (payload) => ({ type: SERVICE_FAILURE, payload })

export const serviceByIdRequestAction = (params?: ServiceByIdRequest) => ({ type: SERVICE_BY_ID_REQUEST, params })
export const serviceByIdSuccessAction = (payload) => ({ type: SERVICE_BY_ID_SUCCESS, payload })
export const serviceByIdFailureAction = (payload) => ({ type: SERVICE_BY_ID_FAILURE, payload })

const initialState: ServiceState = {
	services: null,
	fetching: false,
	error: null,
	page: null,
	limit: null,
	total: null,
	serviceById: null,
	serviceByIdFetching: false,
	serviceByIdError: null,
}

const ServiceReducer = (state = initialState, action: any): ServiceState => {
	const { type, payload, params } = action
	switch (type) {
		case SERVICE_REQUEST: {
			return { ...state, error: null, fetching: true }
		}
		case SERVICE_SUCCESS: {
			const { services, page, limit, total } = payload as ServiceSuccessResponse
			const _services = page === 1 ? services : [...(state.services || []), ...services]
			return { ...state, fetching: false, services: _services, page, limit, total }
		}
		case SERVICE_FAILURE: {
			const { error } = payload as ApiFailureResponse
			return { ...state, fetching: false, error }
		}
		case SERVICE_BY_ID_REQUEST:
			return { ...state, serviceByIdError: null, serviceByIdFetching: true }
		case SERVICE_BY_ID_SUCCESS:
			return { ...state, serviceByIdFetching: false, serviceById: payload }
		case SERVICE_BY_ID_FAILURE:
			const { error } = payload as ApiFailureResponse
			return { ...state, serviceByIdFetching: false, serviceByIdError: error }
		default:
			return state
	}
}

export default ServiceReducer
