import { put, call } from 'redux-saga/effects'
import { ApiResponse } from 'apisauce'

import { api, ApiSuccessResponse } from '../../api'
import {
	Action,
	ArticleRequest,
	ArticleSuccessResponse,
	ArticleByIdRequest,
	ArticleByIdSuccessResponse,
	ArticleLikeRequest,
	ArticleLikeSuccessResponse,
	ArticleDislikeRequest,
	ArticleDislikeSuccessResponse,
	ArticleRepostRequest,
	ArticleRepostSuccessResponse,
	ArticleSetViewedRequest,
	ArticleSetViewedSuccessResponse,
} from '../../types'
import {
	articleFailureAction,
	articleSuccessAction,
	articleByIdFailureAction,
	articleByIdSuccessAction,
	articleLikeSuccessAction,
	articleLikeFailureAction,
	articleDislikeSuccessAction,
	articleDislikeFailureAction,
	articleRepostSuccessAction,
	articleRepostFailureAction,
	articleSetViewedSuccessAction,
	articleSetViewedFailureAction,
} from '../redux/articles'

export function* articleRequestSaga(action: Action<ArticleRequest>) {
	const response: ApiResponse<ApiSuccessResponse<ArticleSuccessResponse>> = yield call(api.articleRequest, action.params)
	if (response.ok) {
		yield put(articleSuccessAction(response.data?.result))
	} else {
		yield put(articleFailureAction(response.data))
	}
}

export function* articleByIdRequestSaga(action: Action<ArticleByIdRequest>) {
	const response: ApiResponse<ApiSuccessResponse<ArticleByIdSuccessResponse>> = yield call(api.articleByIdRequest, action.params)
	if (response.ok) {
		yield put(articleByIdSuccessAction(response.data?.result))
	} else {
		yield put(articleByIdFailureAction(response.data))
	}
}

export function* articleLikeRequestSaga(action: Action<ArticleLikeRequest>) {
	const response: ApiResponse<ApiSuccessResponse<ArticleLikeSuccessResponse>> = yield call(api.articleLikeRequest, action.params)
	if (response.ok) {
		yield put(articleLikeSuccessAction(response.data?.result))
	} else {
		yield put(articleLikeFailureAction(response.data))
	}
}

export function* articleDislikeRequestSaga(action: Action<ArticleDislikeRequest>) {
	const response: ApiResponse<ApiSuccessResponse<ArticleDislikeSuccessResponse>> = yield call(api.articleDislikeRequest, action.params)
	if (response.ok) {
		yield put(articleDislikeSuccessAction(response.data?.result))
	} else {
		yield put(articleDislikeFailureAction(response.data))
	}
}

export function* articleRepostRequestSaga(action: Action<ArticleRepostRequest>) {
	const response: ApiResponse<ApiSuccessResponse<ArticleRepostSuccessResponse>> = yield call(api.articleRepostRequest, action.params)
	if (response.ok) {
		yield put(articleRepostSuccessAction(response.data?.result))
	} else {
		yield put(articleRepostFailureAction(response.data))
	}
}

export function* articleSetViewedRequestSaga(action: Action<ArticleSetViewedRequest>) {
	const response: ApiResponse<ApiSuccessResponse<ArticleSetViewedSuccessResponse>> = yield call(api.articleSetViewedRequest, action.params)
	if (response.ok) {
		yield put(articleSetViewedSuccessAction(response.data?.result))
	} else {
		yield put(articleSetViewedFailureAction(response.data))
	}
}