import { useState, useEffect } from 'react'
import { Dimensions, useWindowDimensions, Platform } from 'react-native'
import { useTheme as reactNavigationUseTheme } from '@react-navigation/native';
import { debounce } from 'lodash'

import { t } from './localization'

export type Themes = keyof typeof ThemesList

type ButtonTheme = {
  background: string
  text: string
  fetching: string
}

export type ButtonsType = {
  primary: ButtonTheme
  secondary: ButtonTheme
  warning: ButtonTheme
  disabled: ButtonTheme
}

export type ThemeColors = typeof LightTheme.colors

export type CustomTheme = {
  dark: boolean
  name: string
  colors: ThemeColors
}

const LightTheme = {
  dark: false,
  name: t('themes_light'),
  colors: {
    border: {
      default: '#83C5BE',
      secondary: '#E5E5E5',
      secondaryWeb: '#F3F3F3',
      notice: '#99C5C9',
      active: '#006D77',
      selected: '#333333',
      tracker_active: 'rgba(114, 114, 114, 0.2)',
    },
    fetching: '#006D77',
    shadow: '#000000',
    header: {
      background: '#EDF6F9',
      title: '#006D77',
    },
    background: {
      default: '#EDF6F9',
      primary: '#FFFFFF',
      secondary: '#C3E2E1',
      input: '#F3F3F3',
      selection: '#E29578',
      progress: '#006D77',
      conference: '#000000',
      achieveLocked: '#D8DDE3',
      correct: '#D0FDDC',
      incorrect: '#FFE8EA',
      labelModeration: '#F4F4F4',
      labelWaiting: '#EDF6F9',
      blue: '#D5F3FD',
      green: '#D5F5E4',
      red: '#F8E1E1',
      tracker_active: '#D5F5E4',
      tracker_inactive: '#FDF3D0'
    },
    tabBar: {
      activeTintColor: '#FF4125',
      inactiveTintColor: '#DCDCDC',
      inactiveBackgroundColor: '#FFFFFF',
      activeBackgroundColor: '#FFFFFF',
    },
    badge: {
      text: '#FFFFFF',
      background: '#EB5757',
    },
    closeIcon: {
      icon: '#FFFFFF',
      backgroundColor: '#333333',
    },
    alert: {
      wrapper: 'rgba(0 ,0 ,0 ,0.6)',
      background: '#FFFFFF',
    },
    checkBox: {
      active: '#006D77',
      activeWeb: '#C3E2E1',
      disabled: '#DCDCDC',
      secondary: '#FFFFFF',
    },
    text: {
      default: '#333333',
      primary: '#828282',
      secondary: '#5C5C5C',
      placeholder: '#969ba0',
      title: '#006D77',
      subtitle: '#E29578',
      date: '#858585',
      selection: '#FFFFFF',
      success: '#42BA96',
      error: '#F54646',
      correct: '#118237',
      incorrect: '#DA5D70',
      empty: '#5FA3AB',
      textModeration: '#707B92',
      textWaiting: '#006D77',
      blue: '#0133B4',
      green: '#006D77',
      red: '#EB5757',
    },
    button: {
      primary: {
        background: '#006D77',
        text: '#FFFFFF',
        fetching: '#FFFFFF',
      },
      secondary: {
        background: '#C3E2E1',
        text: '#006D77',
        fetching: '#006D77',
      },
      disabled: {
        background: '#DCDCDC',
        text: '#828282',
        fetching: '#828282',
      },
      inactive: {
        background: '#E5E5E5',
        text: '#828282',
        fetching: '#828282',
      },
      warning: {
        background: '#B12326',
        text: '#FFFFFF',
        fetching: '#FFFFFF',
      },
    },
    icon: {
      default: '#006D77',
      primary: '#E29578',
      secondary: '#333333',
      disabled: '#DCDCDC',
      white: '#FFFFFF',
      light_green: 'rgba(0, 89, 89, 0.4)',
      inactive: '#828282',
      like_active: '#006D77',
      dislike_active: '#B12326',
      videoController: {
        inactive: {
          color: '#FFFFFF',
          background: '#4D4D4D',
        },
        active: {
          color: '#000000',
          background: '#FFFFFF',
        },
        accept: {
          color: '#FFFFFF',
          background: '#219653',
        },
        decline: {
          color: '#FFFFFF',
          background: '#B12326',
        },
      }
    },
    switch: {
      trackActive: '#C6ECD6',
      trackInactive: '#C6ECD6',
      trackDisabled: '#DCDCDC',
      toggleActive: '#1C9673',
      toggleInactive: '#FF4125',
      toggleDisabled: '#DCDCDC',
    },
    menu: {
      button: {
        background: '#006D77',
        text: '#FFFFFF',
        actionText: '#FFDDD2',
        actionIcon: '#FFDDD2',
        icon: '#99C5C9'
      }
    },
    psychologistCard: {
      background: {
        primary: '#FFFFFF',
        secondary: '#C3E2E1',
        info: '#006D77',
      },
      info: '#FFFFFF'
    },
    chat: {
      messages: {
        my: {
          text: '#FFFFFF',
          background: '#006D77',
        },
        admin: {
          text: '#333333',
          backgrount: '#FFFFFF',
        }
      }
    },
    rating: '#FCD735',
    divider: 'rgba(114, 114, 114, 0.2)',
    error: '#B3261E',
    tracker: {
      positive: '#EDF9EE',
      positiveSelected: '#006D77',
      negative: '#FFDDD2',
      negativeSelected: '#B3261E',
    },
    chart: {
      stroke: '#006D77',
      scatter: '#006D77',
      scatterStroke: '#C3E2E1',
      label: '#EB5757',
      labelText: '#FFFFFF',
      axisYMaxLabel: '#006D77',
      axisYLabel: '#828282',
      axisYMinLabel: '#E29578',
      axisXLabel: '#828282',
      axisYCenterLine: 'rgba(114, 114, 114, 0.2)',
      axisYLines: '#F3F3F3',
    },
  }
}

const DarkTheme = {
  ...LightTheme,
} as CustomTheme

export const useTheme = () => {
  if (Platform.OS !== 'web') {
    return reactNavigationUseTheme()
  }
  const { dark } = reactNavigationUseTheme()
  return {
    ...reactNavigationUseTheme(),
    colors: !dark ? LightTheme.colors : DarkTheme.colors
  }
}

export const ThemesList = {
  light: LightTheme,
  dark: DarkTheme,
}

export const font = (
  weight?:
    100 | 300 | 400 | 500 | 700 | 900 |
    'thin' | 'light' | 'normal' | 'medium' | 'bold' | 'black',
  italic?: boolean
): string => {
  switch (weight) {
    case 100:
    case 'thin':
      return italic ? 'ProductSans-ThinItalic' : 'ProductSans-Thin'
    case 300:
    case 'light':
      return italic ? 'ProductSans-LightItalic' : 'ProductSans-Light'
    case 'medium':
    case 500:
      return italic ? 'ProductSans-MediumItalic' : 'ProductSans-Medium'
    case 700:
    case 'bold':
      return italic ? 'ProductSans-BoldItalic' : 'ProductSans-Bold'
    case 900:
    case 'black':
      return italic ? 'ProductSans-BlackItalic' : 'ProductSans-Black'
    case 400:
    case 'normal':
    default:
      return italic ? 'ProductSans-Italic' : 'ProductSans-Regular'
  }
}

// const { width, height } = Dimensions.get('window')

export const metrics = (() => {
  const { width, height } = Platform.OS !== 'web' ? Dimensions.get('screen') : Dimensions.get('window')
  return {
    screenWidth: width < height ? width : height,
    screenHeight: width < height ? height : width,
  }
})()

export const useMetrics = (() => {
  let { width: originWidth, height: originHeight } = useWindowDimensions()

  let width = Platform.OS !== 'web'
    ? (originWidth < originHeight ? originWidth : originHeight)
    : originWidth
  let height = Platform.OS !== 'web'
    ? (originWidth < originHeight ? originHeight : originWidth)
    : originHeight

  const [responsiveWidth, setResponsiveWidth] = useState(width)
  const [responsiveHeight, setResponsiveHeight] = useState(height)

  if (responsiveWidth !== width) setResponsiveWidth(width)
  if (responsiveHeight !== height) setResponsiveHeight(height)

  return {
    responsiveWidth,
    responsiveHeight,
  }
})

export function useWindowMetrics(delay = 10) {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }
    const debouncedHandleResize = debounce(handleResize, delay)
    window.addEventListener('resize', debouncedHandleResize)
    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [delay])

  return { width, height }
}

export const hex2rgba = (hexCode: string, opacity: number): string => {
  let hex = hexCode.replace('#', '')
  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
  }
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)
  return `rgba(${r},${g},${b},${opacity / 100})`
}
