import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { StackScreenProps } from '@react-navigation/stack'
import {
	FlatList,
	ScrollView,
	StyleSheet,
	Text,
	TextStyle,
	TouchableOpacity,
	View,
	ViewStyle,
	Dimensions,
} from 'react-native'
import { isUndefined } from 'lodash'
import moment from 'moment'
import 'moment/locale/ru'
moment().locale('ru')

import {
	Button,
	Icon,
	Screen,
	Title,
} from '../components'
import { t } from '../localization'
import { font, useTheme } from '../theme'
import { capitalize, sortString, getTimezoneNote } from '../service'
import {
	AppointmentNavigatorProps,
	AppointmentSlotType,
	Nullable
} from '../types'
import { AppState } from '../store/redux'
import {
	appointmentNewAddData,
	appointmentPrimarySlotsRequestAction,
	appointmentPrimaryReserveRequestAction,
	appointmentSecondarySlotsRequestAction,
	appointmentSecondaryReserveRequestAction,
	appointmentSecondaryReserveDeleteRequestAction,
	// appointmentNewResetAction,
	appointmentPrimaryCalendarRequestAction,
	appointmentSecondaryCalendarRequestAction,
} from '../store/redux/appointments'

type ScreenProps = DispatchProps & StateProps & StackScreenProps<AppointmentNavigatorProps, 'NewAppointmentDatetimeScreen'>

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

interface Props extends ScreenProps {
	isSmallScreen: boolean
}

type DaysType = { [key: string]: TimeType[] }
type TimeType = {
	datetime: string
	available: boolean
	id?: Nullable<number>
}
type DateType = {
	date: string
	available: boolean
	times: TimeType[]
}

interface State {
	days: Nullable<DateType[]>
	schedule: Nullable<DaysType>
	selection: Nullable<Selection>
	current_date: Nullable<DateType>
}

interface Selection {
	[key: string]: string[]
}

function NewAppointmentDatetimeScreenWrapper(props: Props) {
	const { colors } = useTheme()
	const [screenData, setScreenData] = useState(Dimensions.get('window'))

  useEffect(() => {
    const onChange = (result) => {
      setScreenData(result.window)
    }

    const subscription = Dimensions.addEventListener('change', onChange)

		return () => subscription?.remove()
  })

	return <NewAppointmentDatetimeScreen colors={colors} isSmallScreen={screenData.width < 655} {...props} />
}

class NewAppointmentDatetimeScreen extends React.Component<Props, State>{
	constructor(props: Props) {
		super(props)
		this.state = {
			days: null,
			selection: null,
			current_date: null,
			schedule: null,
		}
	}

	componentDidMount() {
		const { route, navigation, getSecondaryCalendar, getPrimaryCalendar, store_service_id } = this.props
		const { doctor_id, gender, service_id } = route.params || {}
		if ((isUndefined(gender) && isUndefined(doctor_id)) || isUndefined(service_id) || isUndefined(store_service_id)) {
			navigation.replace('MainStack', { screen: 'MainScreen' })
		}

		!!doctor_id ? getSecondaryCalendar() : getPrimaryCalendar()
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		const { current_date } = this.state
		const {
			primary_reserve_fetching,
			secondary_reserve_fetching,
			reserve_error,
			reserve_slot,
			route,
			getSecondarySlots,
			calendar,
			calendar_fetching,
			getPrimarySlots,
		} = this.props
		const { service_id, gender, doctor_id } = route.params || {}

		if (prevProps.calendar_fetching && !calendar_fetching) {
			const days: DateType[] = []
			calendar.forEach(date => {
				days.push({ date, available: true, times: [] })
			})
			this.setState({ days })
		}

		if (!!current_date?.date && prevState.current_date?.date !== current_date.date) {
			if (!!doctor_id) {
				getSecondarySlots({ service_id, doctor_id, date: current_date.date })
			} else {
				getPrimarySlots({ service_id, gender, date: current_date.date })
			}
		}

		if (prevProps.primary_reserve_fetching && !primary_reserve_fetching && !!reserve_slot) {
			this.props.navigation.navigate('NewAppointmentDoctorScreen')
		}
		if (prevProps.secondary_reserve_fetching && !secondary_reserve_fetching && !!reserve_error) {
			this.setState({ selection: null })
		}
	}

	// componentWillUnmount() {
	// 	const { doctor } = this.props
	// 	this.props.resetAppointment(!!doctor)
	// }

	render() {
		const {
			selection,
			current_date,
		} = this.state;
		const {
			primary_reserve_fetching,
			secondary_reserve_fetching,
			delete_secondary_reserve_fetching,
			calendar,
			slots,
			colors,
			isSmallScreen,
		} = this.props;

		const fetching = primary_reserve_fetching || secondary_reserve_fetching || delete_secondary_reserve_fetching;

		return (
			<Screen fetching={fetching}>
				<ScrollView
					bounces={false}
					contentContainerStyle={{ flexGrow: 1 }}
				>
					<View style={styles.contentContainer}>
						<View style={[
							styles.contentWrapper,
							{
								width: !isSmallScreen ? '55%' : '100%',
								minWidth: !isSmallScreen ? 655 : 'auto',
							},
						]}>
							<Text style={[styles.title, { color: colors.text.title }]}>{t('choose_datetime')}</Text>
							<Title title={t('date')} style={styles.dateTitle} />
							<SmallCalendar
								days={calendar}
								selection={selection}
								onSelect={this.onDateSelect}
								current_date={current_date?.date}
							/>
							{!!current_date && (<>
								<Title title={t('time')} style={styles.timeTitle} />
								<TimeSelection
									selection={(selection || {})[current_date?.date || ''] || []}
									onTimePress={this.onTimePress}
									data={slots || []}
								/>
							</>)}
							<View style={styles.buttonsGroup}>
								<Button
									style={styles.prevButton}
									type='secondary'
									text={t('prev')}
									onPress={this.onPrevPress}
								/>
								<Button
									style={styles.nextButton}
									text={t('next')}
									onPress={this.onNextPress}
									disabled={!Object.values(selection || {}).some(arr => !!arr?.length)}
									fetching={fetching || undefined}
								/>
							</View>
							<Footer
								data={selection}
								onDeletePress={this.onDeleteTime}
							/>
						</View>
					</View>
				</ScrollView>
			</Screen>
		)
	}

	onDateSelect = (selected: string, remove: boolean) => {
		const { days } = this.state
		const current_date = days.find(day => day.date == selected)
		const { selection } = this.state
		const _selection = { ...selection }
		if (!_selection[selected]) {
			_selection[selected] = []
		}
		if (remove) {
			const { slots, deleteSecondaryReserve } = this.props
			const times = _selection[selected]
			const selected_slots: AppointmentSlotType[] = []
			times.forEach(time => {
				selected_slots.push(
					slots.find(slot => moment(slot.datetime).format('YYYY-MM-DD HH:mm') == moment(time).format('YYYY-MM-DD HH:mm'))
				)
			})
			const deleted_slot_ids = selected_slots.map(slot => slot.id)
			const can_delete = deleted_slot_ids.every(id => !!id) && deleted_slot_ids.length > 0
			if (can_delete) {
				deleteSecondaryReserve({ slot_id: deleted_slot_ids[0] })
			}
			delete _selection[selected]
		}
		this.setState({
			selection: _selection,
			current_date: remove ? null : current_date,
		})
	}

	onTimePress = (time: TimeType) => {
		const { selection, current_date } = this.state
		const {
			secondary_reserve_fetching,
			delete_secondary_reserve_fetching,
			reserve_slot,
			secondaryReserve,
			deleteSecondaryReserve,
		} = this.props
		const _selection = { ...selection }
		if (!_selection[current_date.date]) {
			_selection[current_date.date] = []
		}
		const day = _selection[current_date.date]

		if (secondary_reserve_fetching || delete_secondary_reserve_fetching) return

		const deleted_slot_id = reserve_slot?.id
		const can_delete = !!deleted_slot_id
		if (can_delete) {
			deleteSecondaryReserve({ slot_id: deleted_slot_id })
		}
		if (!!time.id) {
			secondaryReserve({ slot_id: time.id })
		}
		Object.keys(_selection).forEach(date => {
			if (date != current_date.date) {
				delete _selection[date]
			}
		})
		day[0] = time.datetime

		this.setState({ selection: _selection })
	}

	onDeleteTime = (datetime: string) => {
		const { deleteSecondaryReserve } = this.props
		const { selection, days } = this.state
		const _selection = { ...selection }
		const _datetime = datetime.split('T')
		const _day = _selection[_datetime[0]]
		const index = _day.findIndex(time => time === _datetime[1])
		_day.splice(index, 1)
		const time = days.find(day => day.date === _datetime[0]).times.find(time => time.datetime === _datetime[1])
		this.setState({ selection: _selection }, () => {
			if (!!time?.id) {
				deleteSecondaryReserve({ slot_id: time.id })
			}
		})
	}

	onPrevPress = () => {
		const { navigation } = this.props
		navigation.goBack()
	}

	onNextPress = () => {
		const { navigation, setAppointmentData, route, doctor } = this.props
		const { gender, service_id } = route.params || {}
		const { selection } = this.state
		let datetime
		Object.keys(selection).forEach(key => {
			selection[key].forEach(time => {
				datetime = time
			})
		})
		// @ts-ignore
		setAppointmentData({ datetime })
		if (!!doctor?.id) {
			navigation.navigate('NewAppointmentConfirmScreen')
		} else {
			const { primaryReserve } = this.props
			primaryReserve({ service_id, gender, datetime })
		}
	}
}

const mapStateToProps = (state: AppState) => ({
	slots: state.appointment.slots || [],
	secondary_reserve_fetching: state.appointment.secondary_reserve_fetching,
	delete_secondary_reserve_fetching: state.appointment.delete_secondary_reserve_fetching,
	primary_reserve_fetching: state.appointment.primary_reserve_fetching,
	reserve_slot: state.appointment.reserve_slot,
	reserve_error: state.appointment.reserve_error,
	doctor: state.appointment.new_appointment?.doctor,
	store_service_id: state.appointment.new_appointment?.serviceId,
	calendar: state.appointment.calendar || [],
	calendar_fetching: state.appointment.calendar_fetching,
})

const mapDispatchToProps = {
	getSecondaryCalendar: appointmentSecondaryCalendarRequestAction,
	getPrimaryCalendar: appointmentPrimaryCalendarRequestAction,
	getPrimarySlots: appointmentPrimarySlotsRequestAction,
	getSecondarySlots: appointmentSecondarySlotsRequestAction,
	setAppointmentData: appointmentNewAddData,
	primaryReserve: appointmentPrimaryReserveRequestAction,
	secondaryReserve: appointmentSecondaryReserveRequestAction,
	deleteSecondaryReserve: appointmentSecondaryReserveDeleteRequestAction,
	// resetAppointment: appointmentNewResetAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewAppointmentDatetimeScreenWrapper)

const styles = {
	contentContainer: {
		flexGrow: 1,
	} as ViewStyle,
	contentWrapper: {
		flexGrow: 1,
		marginTop: 40,
		paddingHorizontal: 30,
		alignSelf: 'center',
	},
	dateTitle: {
		marginBottom: 12,
	},
	timeTitle: {
		marginTop: 24,
	} as TextStyle,
	buttonsGroup: {
		flexDirection: 'row',
		marginTop: 24,
		marginBottom: 40,
	},
	prevButton: {
		flex: 1,
	} as ViewStyle,
	nextButton: {
		flex: 1,
		marginLeft: 12,
	} as ViewStyle,
	title: {
		marginBottom: 24,
		fontFamily: font(),
		fontSize: 24,
		lineHeight: 29,
	},
}


function SmallCalendar({
	days,
	selection,
	onSelect,
	current_date,
}: {
	days: string[]
	selection: Nullable<Selection>
	onSelect: (date: string, remove: boolean) => void
	current_date: string
}) {

	const { colors } = useTheme()
	const [width, setWidth] = useState(0)

	const _onSelect = (date: string) => {
		onSelect(date, date === current_date)
	}

	return (
		<View
			style={[smallCalendarStyles.container, { backgroundColor: colors.background.primary }]}
		>
			<View style={smallCalendarStyles.headerContainer}>
				<Text style={[smallCalendarStyles.headerText, { color: colors.text.default }]}>
					{capitalize(moment(current_date).format('MMMM YYYY'))}
				</Text>
			</View>
			<FlatList
				key={days.length.toString()}
				bounces={false}
				horizontal
				keyExtractor={(item, index) => index.toString()}
				data={[days]}
				showsHorizontalScrollIndicator={false}
				scrollEnabled={false}
				onLayout={e => setWidth(e.nativeEvent.layout.width)}
				renderItem={({ item, index }) => {
					return (
						<View style={[smallCalendarStyles.daysContainer, { width }]}>
							{item.map((item, item_index) => {
								const isActive = item === current_date
								const isSelected = !!(selection || {})[item]?.length
								const activeOpacity = item ? 1 : 0.4

								return (
									<TouchableOpacity
										activeOpacity={activeOpacity}
										key={`${index}-${item_index}`}
										style={[smallCalendarStyles.dayContailer, {
											opacity: activeOpacity,
											backgroundColor: isActive ? colors.background.selection : colors.background.primary,
										}]}
										onPress={() => _onSelect(item)}
									>
										<Text
											style={[smallCalendarStyles.dayOfWeek, {
												color: isActive
													? colors.text.selection
													: isSelected ? colors.text.subtitle : colors.text.default,
											}]}
										>
											{capitalize(moment(item).format('dd'))}
										</Text>
										<Text
											style={[smallCalendarStyles.date, {
												color: isActive
													? colors.text.selection
													: isSelected ? colors.text.subtitle : colors.text.default,
											}]}
										>
											{moment(item).format('DD')}
										</Text>
									</TouchableOpacity>
								)
							})}
						</View>
					)
				}}
			/>
		</View >
	)

}

const smallCalendarStyles = StyleSheet.create({
	container: {
		paddingTop: 16,
		paddingBottom: 14,
		borderRadius: 20,
		overflow: 'hidden',
		paddingHorizontal: 6,
	},
	headerContainer: {
		marginBottom: 12,
		flexDirection: 'row',
		marginHorizontal: 5,
		alignItems: 'center',
	},
	headerText: {
		flex: 1,
		textAlign: 'center',
		fontFamily: font(),
		fontSize: 16,
		lineHeight: 19,
	},
	daysContainer: {
		flexDirection: 'row',
	},
	dayContailer: {
		flex: 1,
		marginHorizontal: 1,
		paddingVertical: 6,
		borderRadius: 8,
		alignItems: 'center',
	},
	dayOfWeek: {
		fontFamily: font(),
		fontSize: 14,
		lineHeight: 17,
	},
	date: {
		marginTop: 8,
		fontFamily: font(),
		fontSize: 16,
		lineHeight: 19,
	},
})


function TimeSelection({
	onTimePress,
	data,
	selection,
}: {
	onTimePress: (time: TimeType) => void
	data: TimeType[]
	selection: string[]
}) {
	const numColumns = 4

	const { colors } = useTheme()
	const [contentWidth, setContentHeight] = useState(0)
	const [itemWidth, setItemWidth] = useState(0)

	useEffect(() => {
		const width = Math.floor(contentWidth / numColumns) - 6
		setItemWidth(width)
	}, [contentWidth])

	return (
		<View style={[daySelectionStyles.container, { backgroundColor: colors.background.primary }]}>
			<Text style={[daySelectionStyles.timezone, { color: colors.text.primary }]}>
				{getTimezoneNote()}
			</Text>
			<View
				style={daySelectionStyles.content}
				onLayout={e => contentWidth !== e.nativeEvent.layout.width && setContentHeight(e.nativeEvent.layout.width)}
			>
				<FlatList
					key={JSON.stringify(data)}
					keyExtractor={(item, index) => index.toString()}
					data={data}
					columnWrapperStyle={{ justifyContent: 'space-between' }}
					renderItem={({ item, index }) => {
						const isSelected = selection.includes(item.datetime)
						const hide = !item
						const isEnabled = !hide && item.available
						const activeOpacity = isEnabled ? 1 : 0.5
						if (itemWidth <= 0) return null
						return (
							<View
								key={index.toString()}
								style={{ width: itemWidth }}
							>
								<TouchableOpacity
									activeOpacity={activeOpacity}
									style={[daySelectionStyles.timeContainer, hide
										? { borderWidth: 0 }
										: {
											borderColor: isSelected ? colors.background.selection : colors.border.secondary,
											backgroundColor: isSelected ? colors.background.selection : colors.background.primary,
											opacity: activeOpacity,
										}]}
									onPress={() => isEnabled ? onTimePress(item) : null}
								>
									<Text style={[daySelectionStyles.time, {
										color: isSelected ? colors.text.selection : colors.text.default
									}]}>
										{moment(item.datetime).format('HH:mm')}
									</Text>
								</TouchableOpacity>
							</View>
						)
					}}
					numColumns={numColumns}
				/>
			</View>
		</View>
	)
}

const daySelectionStyles = StyleSheet.create({
	container: {
		marginTop: 12,
		padding: 16,
		borderRadius: 20,
		overflow: 'hidden',
	},
	content: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
	},
	timeContainer: {
		marginVertical: 4,
		borderWidth: 1,
		borderRadius: 6,
		paddingVertical: 6,
		alignItems: 'center',
		justifyContent: 'center',
		flex: 1,
	},
	time: {
		fontFamily: font(),
		fontSize: 14,
		lineHeight: 17,
	},
	timezone: {
		fontFamily: font(),
		fontSize: 12,
		marginBottom: 8,
	},
})


function Footer({
	data,
	onDeletePress
}: {
	data: Selection
	onDeletePress: (datetime: string) => void
}) {

	const { colors } = useTheme()

	const _data = []
	Object.keys(data || {}).forEach(key => {
		data[key].forEach(time => {
			_data.push(time)
		})
	})

	if (!_data.length) return null

	return (
		<View style={footerStyles.container} >
			<View style={{
				backgroundColor: colors.background.primary,
				borderTopLeftRadius: 20,
				borderTopRightRadius: 20,
				shadowRadius: 10,
				shadowOffset: {
					width: 0,
					height: 4,
				},
				shadowOpacity: 0.1,
			}} >
				<View style={footerStyles.contentContainerStyle} >
					<View style={{ backgroundColor: colors.background.primary }} >
						<Text style={[footerStyles.title, { color: colors.text.secondary }]}>
							{t('selected_dates')}
						</Text>
					</View>
					{_data.sort(sortString).map((date, index) => (
						<View
							key={index.toString()}
							style={footerStyles.dateContainer}
						>
							<View style={[footerStyles.imageContainer, { backgroundColor: colors.background.secondary }]} >
								<Icon
									name={'close'}
									onPress={() => onDeletePress(date)}
								/>
							</View>
							<Text style={[footerStyles.date, { color: colors.text.default }]} >
								{moment(date).format('DD MMMM, HH:mm')}
							</Text>
						</View>
					))}
				</View>
			</View>
		</View>
	)

}

const footerStyles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'flex-end',
	},
	title: {
		fontFamily: font(),
		fontSize: 14,
		lineHeight: 17,
		marginTop: 16,
		marginBottom: 8,
	},
	contentContainerStyle: {
		paddingHorizontal: 16,
		paddingBottom: 16,
	},
	dateContainer: {
		marginBottom: 8,
		flexDirection: 'row',
		alignItems: 'center',
	},
	imageContainer: {
		borderRadius: 3,
		overflow: 'hidden',
	},
	date: {
		marginLeft: 12,
		fontFamily: font('bold'),
		fontSize: 20,
		lineHeight: 24,
	}
})
