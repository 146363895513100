import { put, call, delay, select } from 'redux-saga/effects'
import { ApiResponse } from 'apisauce';
import { api, ApiSuccessResponse } from '../../api'
import {
	Action,
	PromocodeRequest,
	PromocodeSuccessResponse,
} from '../../types';
import {
	promocodeFailureAction,
	promocodeSuccessAction,
} from '../redux/promocode';

export function* promocodeRequestSaga(action: Action<PromocodeRequest>) {
	const response: ApiResponse<ApiSuccessResponse<PromocodeSuccessResponse>> = yield call(api.promocodeCheck, action.params)
	if (response.ok) {
		yield put(promocodeSuccessAction(response.data.result))
	} else {
		yield put(promocodeFailureAction(response.data))
	}
}
