import { showMessage } from 'react-native-flash-message'
import { create, ApisauceInstance, ApiResponse } from 'apisauce'
import moment from 'moment'
import 'moment/locale/ru'

import { store, AppState } from './store'
import config from '../config'
import { create as fixtureCreate } from './fixtures'
import { getTimeZone } from '../service'
import {
	Empty,
	AuthRequest,
	AuthSuccessResponse,
	UserProfileRequest,
	UserProfileSuccessResponse,
	UserProfileUpdateRequest,
	RegisterDeviceRequest,
	AppSuccessResponse,
	AuthLogoutSuccessResponse,
	TrackerRequest,
	TrackerSuccessResponse,
	CreateTrackerRequest,
	CreateTrackerSuccessResponse,
	EditTrackerRequest,
	EditTrackerSuccessResponse,
	DeleteTrackerRequest,
	DeleteTrackerSuccessResponse,
	TrackerStatsRequest,
	TrackerStatsSuccessResponse,
	EmployeesRequest,
	EmployeesSuccessResponse,
	AddEmployeeRequest,
	AddEmployeeSuccessResponse,
	EmployeeRequest,
	EmployeeSuccessResponse,
	AddTrackerToEmployeeRequest,
	AddTrackerToEmployeeSuccessResponse,
	ReplaceEmployeeTrackerRequest,
	ReplaceEmployeeTrackerSuccessResponse,
	UnlinkEmployeeTrackerRequest,
	UnlinkEmployeeTrackerSuccessResponse,
	DeleteEmployeeRequest,
	DeleteEmployeeSuccessResponse,
} from './types'
import { authLogoutSuccessAction } from './store/redux/auth'

moment().locale('ru')

type ApiRequest = (
	url: string,
	data: Object,
	axiosConfig?: any
) => Promise<ApiResponse<any>>

type ApiRequestParams = {
	url: string
	payload?: Object
	axiosConfig: {
		data?: Object
		headers: Object
		params?: Object
	}
}

export interface ApiFailure {
	message: string
	code: string
	reason: string
}

export interface ApiSuccessResponse<T> {
	result: T
	success: boolean
}

export type ApiFailureResponse = {
	error: ApiFailure
}

function showAlert(message: string) {
	showMessage({
		type: 'danger',
		message,
		duration: config.api.messageDuration,
	})
}

export class Api {
	private apisauce: ApisauceInstance
	private fixture: ApisauceInstance

	static AUTH = 'b2b_auth'
	static CLIENT = 'b2b_client'
	static APP = 'b2b_app'
	static TRACKERS = 'b2b_tracker'

	constructor(baseURL: string) {
		this.apisauce = create({
			baseURL,
			headers: {
				'Cache-Control': 'no-cache',
			},
			timeout: 60 * 1000,
		})
		this.fixture = fixtureCreate();
	}

	_setAxiosConfig = (config?: { params?: Object; data?: Object; headers?: Object }) => {
		const state: AppState = store.getState()
		const token = state.auth?.token || ''
		const timezone = getTimeZone()
		return {
			headers: {
				Authorization: 'Bearer ' + token,
				'X-TIMEZONE-OFFSET': timezone,
			},
			...(config || {}),
		}
	}

	_get = async (url: string, axiosConfig?: any) => {
		return await this._handleResponse(config.api.useFixture ? this.fixture.get : this.apisauce.get, { url, axiosConfig })
	}

	_put = async (url: string, axiosConfig?: any) => {
		return await this._handleResponse(config.api.useFixture ? this.fixture.put : this.apisauce.put, { url, axiosConfig })
	}

	_patch = async (url: string, axiosConfig?: any) => {
		return await this._handleResponse(config.api.useFixture ? this.fixture.patch : this.apisauce.patch, { url, axiosConfig })
	}

	_post = async (url: string, axiosConfig?: any) => {
		return await this._handleResponse(config.api.useFixture ? this.fixture.post : this.apisauce.post, { url, axiosConfig })
	}

	_delete = async (url: string, axiosConfig?: any) => {
		return await this._handleResponse(config.api.useFixture ? this.fixture.delete : this.apisauce.delete, { url, axiosConfig })
	}

	_handleResponse = async (
		apiRequest: ApiRequest,
		params: ApiRequestParams
	): Promise<ApiResponse<any>> => {
		const { url, axiosConfig } = params
		const res = await apiRequest(url, {}, axiosConfig)
		config.api.showLogs && console.log(res.config?.method?.toUpperCase(), res.status, res.config?.url, { res })
		return this._handleError(res)
	}

	_handleError = (res: ApiResponse<any>) => {
		if (!res.ok) {
			let message = res.data?.error?.reason
			let isShowAlert = true
			switch (res.status) {
				case 400: {
					const error = res.data?.error
					if (error.code == 1001 || error.code == 1002) {
						message = 'Ошибка на сервере, повторите попытку позже.'
					}
					break;
				}
				case 401:
				case 403:
					store.dispatch(authLogoutSuccessAction())
					break;
				case 404:
				case 422: {
					if (res.data?.error?.code === 1501) {
						isShowAlert = false
					}
					break;
				}
				case 449: {
					return res
				}
				default:
					if (res.status && res.status >= 500) {
						message = 'Ошибка на сервере, повторите попытку позже'
					}
					if (!message) {
						switch (res.problem) {
							case 'CLIENT_ERROR':
								message = 'Неизвестная ошибка приложения'
								break
							case 'SERVER_ERROR':
								message = 'Неизвестная ошибка сервера'
								break
							case 'TIMEOUT_ERROR':
								message = 'Время ожидания ответа от сервера истекло'
								break
							case 'CONNECTION_ERROR':
								message = 'Сервер недоступен'
								break
							case 'NETWORK_ERROR':
								message = 'Нет доступа к интернету'
								break
							default:
								message = 'Неизвестная ошибка'
						}
						if (!res.data?.error) {
							res.data = {
								error: {
									message
								}
							}
						}
					}
					break;
			}

			isShowAlert && showAlert(message || res.data?.error?.message)
			return res
		}
		return res
	}

	authRequest = async (data: AuthRequest): Promise<Empty<ApiSuccessResponse<AuthSuccessResponse> | ApiFailureResponse>> => {
		const url = Api.AUTH + (!data.code ? '/email' : '/email-confirm' )
		return await this._post(url, this._setAxiosConfig({ data }))
	}

	authLogout = async (): Promise<Empty<ApiSuccessResponse<AuthLogoutSuccessResponse> | ApiFailureResponse>> => {
		return await this._post(`${Api.AUTH}/logout`, this._setAxiosConfig())
	}

	userProfileRequest = async (params: UserProfileRequest): Promise<Empty<ApiSuccessResponse<UserProfileSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(`${Api.CLIENT}/profile`, this._setAxiosConfig({ params }))
	}

	userProfileUpdateRequest = async (data: UserProfileUpdateRequest): Promise<Empty<ApiSuccessResponse<UserProfileSuccessResponse> | ApiFailureResponse>> => {
		if (!!data.registered_at) {
			delete data.registered_at
			return await this._patch(`${Api.CLIENT}/profile`, this._setAxiosConfig({ data }))
		} else {
			delete data.registered_at
			return await this._post(`${Api.CLIENT}/register`, this._setAxiosConfig({ data }))
		}
	}

	userProfileDeleteRequest = async (): Promise<Empty<any | ApiFailureResponse>> => {
		return await this._delete(`${Api.CLIENT}/profile`, this._setAxiosConfig())
	}

	registerDevice = async (data: RegisterDeviceRequest): Promise<Empty<ApiSuccessResponse<undefined> | ApiFailureResponse>> => {
		return await this._patch(`${Api.CLIENT}/device`, this._setAxiosConfig({ data }))
	}

	appRequest = async (): Promise<Empty<ApiSuccessResponse<AppSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(Api.APP, this._setAxiosConfig())
	}

	trackerRequest = async (params?: TrackerRequest): Promise<Empty<ApiSuccessResponse<TrackerSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(Api.TRACKERS, this._setAxiosConfig({ params }))
	}

	createTrackerRequest = async (data: CreateTrackerRequest): Promise<Empty<ApiSuccessResponse<CreateTrackerSuccessResponse> | ApiFailureResponse>> => {
		return await this._post(Api.TRACKERS, this._setAxiosConfig({ data }))
	}

	editTrackerRequest = async (data: EditTrackerRequest): Promise<Empty<ApiSuccessResponse<EditTrackerSuccessResponse> | ApiFailureResponse>> => {
		return await this._post(`${Api.TRACKERS}/${data.id}`, this._setAxiosConfig({ data }))
	}

	deleteTrackerRequest = async (data: DeleteTrackerRequest): Promise<Empty<ApiSuccessResponse<DeleteTrackerSuccessResponse> | ApiFailureResponse>> => {
		return await this._delete(`${Api.TRACKERS}/${data.id}`, this._setAxiosConfig({ data }))
	}

	trackerStatsRequest = async (params: TrackerStatsRequest): Promise<Empty<ApiSuccessResponse<TrackerStatsSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(`${Api.TRACKERS}/${params.id}/stats`, this._setAxiosConfig({ params }))
	}

	employeesRequest = async (params?: EmployeesRequest): Promise<Empty<ApiSuccessResponse<EmployeesSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(`${Api.CLIENT}/employee`, this._setAxiosConfig({ params }))
	}
	addEmployeeRequest = async (data: AddEmployeeRequest): Promise<Empty<ApiSuccessResponse<AddEmployeeSuccessResponse> | ApiFailureResponse>> => {
		return await this._post(`${Api.CLIENT}/employee`, this._setAxiosConfig({ data }))
	}
	employeeRequest = async (data: EmployeeRequest): Promise<Empty<ApiSuccessResponse<EmployeeSuccessResponse> | ApiFailureResponse>> => {
		return await this._get(`${Api.CLIENT}/employee/${data.id}`, this._setAxiosConfig({ data }))
	}
	addTrackerToEmployeeRequest = async (data: AddTrackerToEmployeeRequest): Promise<Empty<ApiSuccessResponse<AddTrackerToEmployeeSuccessResponse> | ApiFailureResponse>> => {
		return await this._post(`${Api.TRACKERS}/connect`, this._setAxiosConfig({ data }))
	}
	replaceEmployeeTrackerRequest = async (data: ReplaceEmployeeTrackerRequest): Promise<Empty<ApiSuccessResponse<ReplaceEmployeeTrackerSuccessResponse> | ApiFailureResponse>> => {
		return await this._post(`${Api.TRACKERS}/replace`, this._setAxiosConfig({ data }))
	}
	unlinkEmployeeTrackerRequest = async (data: UnlinkEmployeeTrackerRequest): Promise<Empty<ApiSuccessResponse<UnlinkEmployeeTrackerSuccessResponse> | ApiFailureResponse>> => {
		return await this._post(`${Api.TRACKERS}/disconnect`, this._setAxiosConfig({ data }))
	}
	deleteEmployeeRequest = async (data: DeleteEmployeeRequest): Promise<Empty<ApiSuccessResponse<DeleteEmployeeSuccessResponse> | ApiFailureResponse>> => {
		return await this._delete(`${Api.CLIENT}/employee/${data.id}`, this._setAxiosConfig({ data }))
	}
}

export const api = new Api(config.api.url)
