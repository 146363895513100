import React from 'react'
import { connect } from 'react-redux'
import { AppState } from '../store/redux'
import { StackScreenProps } from '@react-navigation/stack'
import { View, ViewStyle } from 'react-native'
import { GuideCarousel, Screen, Logo } from '../components'
// import { t } from '../localization'
import { appSetShowGuide } from '../store/redux/app'
import { useTheme } from '../theme'

type ScreenProps = DispatchProps & StateProps & StackScreenProps<any>

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

interface Props extends ScreenProps {}

interface State { }

function GuideScreenWrapper(props: Props) {
	const { colors } = useTheme()

	return <GuideScreen colors={colors} {...props} />
}

class GuideScreen extends React.Component<Props, State>{

	render() {
		const { guides, colors } = this.props

		return (
			<Screen
				onSkipPress={this.onConfirmPress}
				hideHeader
			>
				<Logo style={{ marginTop: 40, marginBottom: 30 }} clickable={false} />
				<View style={styles.carouselContainer}>
					<View style={[styles.carouselWrapper, { backgroundColor: colors.background.primary }]}>
						<GuideCarousel
							style={styles.guide}
							data={guides}
							onConfirmPress={this.onConfirmPress}
						/>
					</View>
				</View>
			</Screen>
		)
	}


	onConfirmPress = () => {
		this.props.showGuide(false)
	}
}

const mapStateToProps = (state: AppState) => ({
	guides: state.app.guides || [],
})

const mapDispatchToProps = {
	showGuide: appSetShowGuide
}

export default connect(mapStateToProps, mapDispatchToProps)(GuideScreenWrapper)

const styles = {
	carouselContainer: {
		flex: 1,
		alignItems: 'center',
		marginHorizontal: 30,
		marginBottom: 30,
	} as ViewStyle,
	carouselWrapper: {
		flex: 1,
		width: '55%',
		minWidth: 691,
		marginVertical: 'auto',
		borderRadius: 20,
		overflow: 'hidden',
	},
	guide: {} as ViewStyle,
}
