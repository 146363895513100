import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { AppState } from '../store/redux'
import { StackScreenProps } from '@react-navigation/stack'
import { Image, ImageStyle, ScrollView, Text, TextStyle, View, ViewStyle, Dimensions } from 'react-native'
import { Button, Markdown, Screen } from '../components'
import { t } from '../localization'
import { AppointmentNavigatorProps } from '../types'
import { font, ThemeColors, useTheme } from '../theme'
import { getFullName } from '../service'

type ScreenProps = DispatchProps & StateProps & StackScreenProps<AppointmentNavigatorProps, 'NewAppointmentDoctorScreen'>

type StateProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

interface Props extends ScreenProps {
	colors: ThemeColors,
	isSmallScreen: boolean
}

interface State { }

function NewAppointmentDoctorScreenWrapper(props: ScreenProps) {
	const { colors } = useTheme()
	const [screenData, setScreenData] = useState(Dimensions.get('window'))

	useEffect(() => {
		const onChange = (result) => {
			setScreenData(result.window)
		}

		const subscription = Dimensions.addEventListener('change', onChange)

		return () => subscription?.remove()
	})

	return <NewAppointmentDoctorScreen colors={colors} isSmallScreen={screenData.width < 655} {...props} />
}

class NewAppointmentDoctorScreen extends React.Component<Props, State>{
	componentDidMount() {
		const { doctor, navigation } = this.props
		if (!doctor) {
			navigation.replace('MainStack', { screen: 'MainScreen' })
		}
	}

	render() {
		const { colors, doctor, isSmallScreen } = this.props
		const { photo, firstname, lastname, middlename, post, experience } = doctor || {}
		if (!doctor) return null

		return (
			<Screen>
				<ScrollView
					bounces={false}
					contentContainerStyle={styles.contentContainer}
				>
					<View style={[
						styles.contentWrapper,
						{
							width: !isSmallScreen ? '55%' : '100%',
							minWidth: !isSmallScreen ? 655 : 'auto',
						},
					]}>
						<Text style={[styles.title, { color: colors.text.title }]}>{t('doctor_confirm')}</Text>
						<View style={[styles.card, { backgroundColor: colors.background.primary }]}>
							<View style={styles.avatar.container}>
								<Image
									source={{ uri: photo?.original || '' }}
									style={styles.avatar.image}
									resizeMode={'contain'}
									resizeMethod={'scale'}
								/>
							</View>
							<Text style={[styles.doctorName, { color: colors.text.title }]}>{getFullName(lastname, firstname, middlename)}</Text>
							<Text style={[styles.post, { color: colors.text.subtitle }]}>{post}, {t('experience', [experience])}</Text>
							<Markdown
								style={styles.description}
								text={doctor.description}
							/>
						</View>
						<View style={styles.buttonsGroup}>
							<Button
								style={styles.prevButton}
								type='secondary'
								text={t('prev')}
								onPress={this.onPrevPress}
							/>
							<Button
								style={styles.nextButton}
								text={t('next')}
								onPress={this.onNextPress}
							/>
						</View>
					</View>
				</ScrollView>
			</Screen>
		)
	}

	onPrevPress = () => {
		const { navigation } = this.props
		navigation.goBack()
	}

	onNextPress = () => {
		const { navigation } = this.props
		navigation.navigate('NewAppointmentConfirmScreen')
	}
}

const mapStateToProps = (state: AppState) => ({
	doctor: state.appointment.reserve_slot?.doctor,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(NewAppointmentDoctorScreenWrapper)

const styles = {
	contentContainer: {
		flexGrow: 1,
	} as ViewStyle,
	contentWrapper: {
		flexGrow: 1,
		marginVertical: 40,
		paddingHorizontal: 30,
		alignSelf: 'center',
	},
	card : {
		borderRadius: 20,
		padding: 16,
	},
	avatar: {
		container: {
			borderRadius: 185 / 2,
			overflow: 'hidden',
			alignSelf: 'center',
		} as ViewStyle,
		image: {
			width: 185,
			height: 185,
		} as ImageStyle,
	},
	title: {
		marginBottom: 24,
		fontFamily: font(),
		fontSize: 24,
		lineHeight: 29,
	},
	doctorName: {
		marginTop: 16,
		fontFamily: font(),
		fontSize: 20,
		lineHeight: 24,
		textAlign: 'center',
	} as TextStyle,
	post: {
		marginTop: 4,
		fontFamily: font(700),
		fontSize: 14,
		lineHeight: 20,
		textAlign: 'center',
	} as TextStyle,
	description: {
		marginTop: 16,
	} as ViewStyle,
	buttonsGroup: {
		flexDirection: 'row',
		marginTop: 24,
	},
	prevButton: {
		flex: 1,
	} as ViewStyle,
	nextButton: {
		flex: 1,
		marginLeft: 12,
	} as ViewStyle,
}
