import React, { useState, useEffect } from 'react'
import { Image, StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useDispatch } from 'react-redux'
import { AirbnbRating } from 'react-native-ratings'
import moment from 'moment'
import 'moment/locale/ru'

import { t } from '../localization'
import { font, useTheme, metrics } from '../theme'
import { star, starOutline, conferenceDone } from '../images'
import { AppointmentType, DoctorType, Nullable, ServiceType } from '../types'
import { getFullName, getTimezoneNote } from '../service'
import { appointmentCancelRequestAction, appointmentCheckRequestAction } from '../store/redux/appointments'
import { createReviewRequestAction } from '../store/redux/reviews'
import { Modal, Button, DoctorModal, Icon, TextInput, RadioButton } from '.'

moment().locale('ru')

interface PsychologistCardProps {
  style?: ViewStyle | ViewStyle[]
  background?: 'primary' | 'secondary'
  onAddRecord?: (appointment: AppointmentType) => void
  cancelAppointmentButton?: boolean
  appointment: AppointmentType
  downloadCheckButton?: boolean
  disableCheckDownloadButton?: boolean
  notice?: string
  isUnreadMessages?: boolean
  nearestConsultationId?: Nullable<number>
}

export default function ({
  style,
  background = 'primary',
  appointment,
  onAddRecord,
  cancelAppointmentButton = false,
  downloadCheckButton = false,
  disableCheckDownloadButton = false,
  notice,
  isUnreadMessages = false,
  nearestConsultationId = null,
}: PsychologistCardProps) {
  const { colors } = useTheme()
  const dispatch = useDispatch()

  const [showModal, setShowModal] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showLeaveReviewModal, setShowLeaveReviewModal] = useState(false)
  const [showThanksForReviewMsg, setShowThanksForReviewMsg] = useState(false)
  const [cancel, setCancel] = useState<boolean>(false)
  const [paymentId, setPaymentId] = useState<Nullable<number>>(null)
  const [cancelAppointmentId, setCancelAppointmentId] = useState<Nullable<number>>(null)
  const [showPackModal, setShowPackModal] = useState(false)

  useEffect(() => {
    if (cancel) {
      setShowCancelModal(false)
    }
  }, [cancel])

  useEffect(() => {
    if (!!cancelAppointmentId) {
      dispatch(appointmentCancelRequestAction({ appointment_id: cancelAppointmentId }))
    }
  }, [cancelAppointmentId])

  useEffect(() => {
    if (!!paymentId) {
      dispatch(appointmentCheckRequestAction({ paymentId }))
      setPaymentId(null)
    }
  }, [paymentId])

  const isNew = appointment.status === 'PAID'
  const isCancelled = appointment.status === 'CANCELLED'
  const isCancelable = moment.duration(moment(appointment.slot.datetime).diff(moment())).asHours() > 24
  const isFinished = appointment.status === 'FINISHED'

  const { service } = appointment

  const navigation = useNavigation()
  const onPressChat = () => navigation.navigate('MainStack', { screen: 'DoctorChatScreen', params: { id: appointment.id } })
  const onPressPack = () => setShowPackModal(true)

  const isCanLeaveReview = isFinished && appointment.reviews_count === 0
  const isPack = appointment.is_pack
  const { current_pack, max_in_pack, pack_expiration_date } = appointment.pack
  const isPackExpired = moment(pack_expiration_date).diff(moment(), 'days') <= 0

  const packDescription = isPack
    ? isCancelled
      ? t('pack_appointment_cancelled')
      : isPackExpired
        ? t('pack_expired')
        : isFinished && current_pack === max_in_pack
          ? t('pack_finished')
          : t('pack_expiration_date_expanded', [moment(pack_expiration_date).format('DD.MM.YYYY')])
    : ''

  return (
    <View style={[
      styles.container,
      { backgroundColor: colors.psychologistCard.background[background] },
      style
    ]}>
      <View style={{ flexDirection: 'row' }}>
        <View>
          <View style={[styles.photoContainer, { borderColor: colors.border.default }]}>
            <Image source={{ uri: appointment.doctor.photo.thumbnail + '?w=40h=40' }} style={styles.photo} resizeMode={'cover'} />
          </View>
          <View style={styles.infoIcon} >
            <Icon
              name={'info'}
              color={colors.psychologistCard.info}
              backgroundColor={colors.psychologistCard.background.info}
              onPress={() => setShowModal(true)}
            />
          </View>
        </View>
        <View style={styles.aboutContainer}>
          <Text style={[styles.datetime, { color: colors.text.title }]}>
            {moment(appointment.slot.datetime).format('DD MMMM, HH:mm')}
          </Text>
          <Text style={[styles.timezone, { color: colors.text.primary }]}>
            {getTimezoneNote()}
          </Text>
          <View style={styles.row}>
            <Text style={[styles.position, { color: colors.text.default }]}>
              {t('service')} - {service?.name}
            </Text>
            <Text style={[styles.fio, { color: colors.text.primary }]}>
              {getFullName(appointment.doctor.lastname, appointment.doctor.firstname)}
            </Text>
          </View>
        </View>
        <View>
          {isNew && nearestConsultationId === appointment.id && (
            <TouchableOpacity
              activeOpacity={1}
              hitSlop={{ top: 4, bottom: 4, left: 4, right: 4 }}
              style={[styles.chatIcon, {
                backgroundColor: colors.background.progress,
                marginBottom: isPack ? 10 : 0,
              }]}
              onPress={onPressChat}
            >
              <Icon name={'doctor_chat'} color={colors.background.default} />
              {isUnreadMessages && (
                <View style={[styles.chatBadge, { backgroundColor: colors.badge.background }]} />
              )}
            </TouchableOpacity>
          )}
          {isPack && (
            <TouchableOpacity
              activeOpacity={1}
              hitSlop={{ top: 4, bottom: 4, left: 4, right: 4 }}
              style={[styles.chatIcon, { backgroundColor: colors.background.default }]}
              onPress={onPressPack}
            >
              <Icon name={'pack'} color={colors.icon.default}/>
            </TouchableOpacity>
          )}
        </View>
      </View>
      {isPack && (
        <View style={styles.packWrapper}>
          <Text style={[styles.packText, { color: colors.text.default }]}>
            {t('pack_total', [max_in_pack])}
          </Text>
          <Text style={[styles.packText, { color: colors.text.default }]}>
            {t('pack_appointment_number', [current_pack, max_in_pack])}
          </Text>
          <Text style={[styles.packText, { color: colors.text.default }]}>
            {packDescription}
          </Text>
        </View>
      )}
      {!!notice && (
        <View style={[styles.noticeContainer, { borderColor: colors.border.notice, }]}>
          <Text style={[styles.notice, { color: colors.text.title }]}>
            {notice}
          </Text>
        </View>
      )}
      { (!!onAddRecord && !service.hide_booking && !service.hide_service || cancelAppointmentButton || isCancelled) && (
        <View style={styles.buttonContainer}>
          {!!onAddRecord && !service.hide_booking && !service.hide_service && (
            <Button
              style={[styles.button, { marginRight: cancelAppointmentButton || isCancelled || isCanLeaveReview ? 10 : 0 }]}
              text={!isNew ? t('repeat_appointment') : t('add_appointment')}
              onPress={() => onAddRecord(appointment)}
            />
          )}
          {cancelAppointmentButton && (
            <Button
              style={styles.button}
              text={t('cancel')}
              onPress={() => setShowCancelModal(true)}
              type={'secondary'}
            />
          )}
          {isCancelled && (
            <Button
              disabled
              style={styles.button}
              text={t('appointment_canceled')}
            />
          )}
          {isCanLeaveReview && (
            <Button
              style={styles.button}
              text={t('leave_review')}
              onPress={() => {
                setShowThanksForReviewMsg(false)
                setShowLeaveReviewModal(true)
              }}
              type={'secondary'}
            />
          )}
        </View>
      )}
      {downloadCheckButton && !!appointment.paymentId && appointment.has_check && (
        <TouchableOpacity
          style={styles.checkContainer}
          onPress={() => setPaymentId(appointment.paymentId)}
          disabled={disableCheckDownloadButton}
        >
          <Icon
            name={'check'}
            color={colors.icon.default}
            size={18}
          />
          <Text style={[styles.ckeckText, { color: colors.icon.default }]}>{t('download_check')}</Text>
        </TouchableOpacity>
      )}
      <CancelAppointmentModal
        isVisible={showCancelModal && !cancel}
        isCancelable={isCancelable}
        onConfirm={() => setCancel(true)}
        onCancel={() => setShowCancelModal(false)}
        onModalHide={() => {
          if (cancel) {
            setCancelAppointmentId(appointment.id)
            setCancel(false)
          }
        }}
      />
      <DoctorModal
        isVisible={showModal}
        onClose={() => setShowModal(false)}
        doctor={appointment.doctor}
      />
      <LeaveReviewModal
        service={appointment.service}
        doctor={appointment.doctor}
        date={appointment.slot.datetime}
        appointmentId={appointment.id}
        isVisible={showLeaveReviewModal}
        showThanksForReviewMsg={showThanksForReviewMsg}
        onConfirm={data => {
          dispatch(createReviewRequestAction(data))
          setShowThanksForReviewMsg(true)
        }}
        onCancel={() => setShowLeaveReviewModal(false)}
      />
      <PackModal isVisible={showPackModal} onCancel={() => setShowPackModal(false)}/>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
    borderRadius: 20,
    overflow: 'hidden',
  },
  photoContainer: {
    borderRadius: 35,
    overflow: 'hidden',
    borderWidth: 1,
  },
  photo: {
    width: 70,
    height: 70,
  },
  infoIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 24,
    height: 24,
    borderRadius: 12,
  },
  aboutContainer: {
    flex: 1,
    marginLeft: 12,
  },
  datetime: {
    fontFamily: font(),
    fontSize: 20,
    lineHeight: 24,
  },
  timezone: {
    fontFamily: font(),
    fontSize: 12,
    marginVertical: 4,
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  position: {
    fontFamily: font(),
    fontSize: 14,
    lineHeight: 17,
    marginRight: 16,
    marginTop: 4,
  },
  fio: {
    fontFamily: font(),
    fontSize: 14,
    lineHeight: 17,
    marginTop: 4,
  },
  packWrapper: {
    marginTop: 8,
  },
  packText: {
    fontFamily: font(),
    fontSize: 14,
    lineHeight: 17,
    marginRight: 16,
    marginTop: 4,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 12,
  },
  button: {
    flex: 1,
  },
  checkContainer: {
    flexDirection: 'row',
    marginTop: 12,
    alignSelf: 'center',
    alignItems: 'center',
  },
  ckeckText: {
    marginLeft: 4,
    fontFamily: font(700),
    fontSize: 16,
    lineHeight: 19,
  },
  noticeContainer: {
    marginTop: 16,
    borderTopWidth: 1,
    paddingTop: 8,
  },
  notice: {
    fontFamily: font(),
    fontSize: 14,
    lineHeight: 16,
  },
  chatIcon: {
    width: 40,
    height: 40,
    borderRadius: 10,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-start',
  },
  chatBadge: {
    width: 12,
    position: 'absolute',
    height: 12,
    borderRadius: 6,
    overflow: 'hidden',
    top: 7,
    right: 7,
  },

  thanksContentContainer: {
    flexGrow: 1,
		paddingTop: 12,
		paddingBottom: 40,
		paddingHorizontal: 16,
		alignItems: 'center',
		justifyContent: 'center',
  },
  thanksImage: {
    width: metrics.screenWidth - 30,
		maxWidth: 314,
		height: (metrics.screenWidth - 30) * (243 / 314),
		maxHeight: 243,
  },
  thanksTitle: {
    marginTop: 30,
		fontFamily: font('bold'),
		fontSize: 24,
		lineHeight: 29,
  },
  thanksText: {
    marginTop: 16,
		fontFamily: font(),
		fontSize: 14,
		lineHeight: 18,
		textAlign: 'center',
		width: metrics.screenWidth - 30,
  },
})

interface CancelAppointmentModalProps {
  isVisible: boolean
  isCancelable: boolean
  onConfirm: () => void
  onCancel: () => void
  onModalHide: () => void
}
function CancelAppointmentModal({
  isVisible,
  isCancelable,
  onConfirm,
  onCancel,
  onModalHide,
}: CancelAppointmentModalProps) {

  const { colors } = useTheme()

  return (
    <Modal
      isVisible={isVisible}
      onClose={onCancel}
      onModalHide={onModalHide}
      position='center'
    >
      <View style={cancelAppointmentModalStyles.container}>
        <Text style={[cancelAppointmentModalStyles.title, { color: colors.text.title }]}>
          {t('appointment_cancel')}
        </Text>
        {isCancelable
          ? (
            <Text style={[cancelAppointmentModalStyles.text, { color: colors.text.default }]}>
              {t('appointment_cancel_confirm')}
            </Text>
          ) : (
            <Text style={[cancelAppointmentModalStyles.text, { color: colors.text.default }]}>
              {t('appointment_cancel_reject')}
            </Text>
          )}
      </View>
      <View style={cancelAppointmentModalStyles.buttonContainer}>
        {isCancelable ? (
          <View style={{ flexDirection: 'row' }}>
            <Button
              text={t('no')}
              style={cancelAppointmentModalStyles.button}
              type={'secondary'}
              onPress={() => onCancel()}
            />
            <Button
              text={t('yes')}
              style={cancelAppointmentModalStyles.button}
              onPress={() => onConfirm()}
            />
          </View>
        ) : (
          <Button
            text={t('confirm')}
            style={cancelAppointmentModalStyles.button}
            onPress={() => onCancel()}
          />
        )}
      </View>
    </Modal>
  )
}

const cancelAppointmentModalStyles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
  title: {
    fontFamily: font('bold'),
    fontSize: 20,
    lineHeight: 24,
  },
  text: {
    marginTop: 14,
    fontFamily: font(),
    fontSize: 16,
    lineHeight: 19,
  },
  buttonContainer: {
    marginTop: 24,
    paddingHorizontal: 11,
  },
  button: {
    flex: 1,
    marginHorizontal: 5,
  },
})

interface LeaveReviewModalProps {
  service: ServiceType
  doctor: DoctorType
  date: string
  appointmentId: number
  isVisible: boolean
  showThanksForReviewMsg: boolean
  onConfirm: ({}) => void
  onCancel: () => void
}
function LeaveReviewModal({
  service,
  doctor,
  date,
  appointmentId,
  isVisible,
  showThanksForReviewMsg,
  onConfirm,
  onCancel,
}: LeaveReviewModalProps) {
  const { colors } = useTheme()

  const [isAnonymous, setIsAnonymous] = useState(false)
  const [rating, setRating] = useState(0)
  const [review, setReview] = useState('')

  useEffect(() => {
    if (isVisible) {
      setRating(0)
      setReview('')
      setIsAnonymous(false)
    }
  }, [isVisible])

  const onCheckBoxChange = (checked: any) => {
    setIsAnonymous(checked)
  }
  const ratingCompleted = (rating: number) => {
    setRating(rating)
  }
  const sendReview = () => {
    onConfirm({
      body: review,
      anonymous: isAnonymous,
      service_id: service.id,
      doctor_id: doctor.id,
      appointment_id: appointmentId,
      rate: rating,
    })
  }

  return (
    <Modal
      width={'80%'}
      isVisible={isVisible}
      onClose={onCancel}
      position='center'
    >
      {showThanksForReviewMsg ? (
        <View style={styles.thanksContentContainer}>
          <Image
            source={conferenceDone}
            style={styles.thanksImage}
            resizeMode={'contain'}
          />
          <Text style={[styles.thanksTitle, { color: colors.text.title }]}>
            {t('thanks_for_rating')}
          </Text>
          <Text style={[styles.thanksText, { color: colors.text.default }]}>
            {t('thanks_note')}
          </Text>
        </View>
      ) : (
        <View style={leaveReviewModalStyles.container}>
          <>
            <Text style={[leaveReviewModalStyles.title, { color: colors.text.title }]}>
              {t('leave_review')}
            </Text>
            <View style={leaveReviewModalStyles.field}>
              <Text style={[leaveReviewModalStyles.description, { color: colors.text.secondary }]}>{t('review_description')}</Text>
            </View>
            <View style={leaveReviewModalStyles.field}>
              <Text style={[leaveReviewModalStyles.label, { color: colors.text.default }]}>{t('consultation_name')}</Text>
              <TextInput readonly value={service.name} />
            </View>
            <View style={leaveReviewModalStyles.row}>
              <View style={[leaveReviewModalStyles.field, { marginRight: 16 }]}>
                <Text style={[leaveReviewModalStyles.label, { color: colors.text.default }]}>{t('psychologist')}</Text>
                <TextInput readonly value={`${doctor.lastname} ${doctor.firstname}`} />
              </View>
              <View style={leaveReviewModalStyles.field}>
                <Text style={[leaveReviewModalStyles.label, { color: colors.text.default }]}>{t('date')}</Text>
                <TextInput readonly value={moment(date).format('DD MMMM YYYY')} />
              </View>
            </View>
            <View style={leaveReviewModalStyles.field}>
              <Text style={[leaveReviewModalStyles.label, { color: colors.text.default }]}>{t('rating')}</Text>
              <AirbnbRating
                defaultRating={rating}
                selectedColor={null}
                unSelectedColor={colors.rating}
                showRating={false}
                size={40}
                onFinishRating={ratingCompleted}
                starContainerStyle={{ width: 300, justifyContent: 'space-between', alignSelf: 'flex-start', marginVertical: 4 }}
                starImage={starOutline}
                starSelectedImage={star}
              />
            </View>
            <View style={leaveReviewModalStyles.field}>
              <Text style={[leaveReviewModalStyles.label, { color: colors.text.default }]}>{t('review')}</Text>
              <TextInput multiline numberOfLines={5} onChange={setReview} value={review} />
            </View>
            <View style={[leaveReviewModalStyles.field, leaveReviewModalStyles.checkbox]}>
              <RadioButton
                checked={isAnonymous}
                type={'checkBox'}
                onChange={onCheckBoxChange}
              />
              <Text
                style={[leaveReviewModalStyles.checkbox_label, { color: colors.text.default }]}
                onPress={() => onCheckBoxChange(!isAnonymous)}
              >
                {t('send_anonimously')}
              </Text>
            </View>
          </>
          <View style={leaveReviewModalStyles.buttonContainer}>
            <Button
              text={t('send_review')}
              disabled={!rating}
              onPress={sendReview}
            />
          </View>
        </View>
      )}
    </Modal>
  )
}
const leaveReviewModalStyles = StyleSheet.create({
  container: {
    paddingVertical: 4,
    paddingHorizontal: 20,
  },
  title: {
    fontFamily: font('bold'),
    fontSize: 24,
    lineHeight: 30,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  field: {
    flex: '1 1 auto',
    marginTop: 16,
  },
  description: {
    fontFamily: font(),
    fontSize: 16,
    lineHeight: 20,
  },
  label: {
    fontFamily: font('bold'),
    fontSize: 14,
    lineHeight: 17,
    marginBottom: 4,
  },
  checkbox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkbox_label: {
    fontFamily: font(),
    fontSize: 16,
    lineHeight: 20,
    marginLeft: 8,
  },
  buttonContainer: {
    marginTop: 16,
  },
  button: {
    flex: 1,
    paddingHorizontal: 20,
  },
})

interface PackModalProps {
  isVisible: boolean
  onCancel: () => void
}
function PackModal({
  isVisible,
  onCancel,
}: PackModalProps) {

  const { colors } = useTheme()

  return (
    <Modal isVisible={isVisible} onClose={onCancel} position='center'>
      <View style={packModalStyles.container}>
        <Text style={[packModalStyles.title, { color: colors.text.title }]}>
          {t('package_offer')}
        </Text>
        <Text style={[packModalStyles.content, { color: colors.text.secondary }]}>
          {t('pack_info')}
        </Text>
      </View>
    </Modal>
  )
}

const packModalStyles = StyleSheet.create({
  container: {
    padding: 16,
  },
  title: {
    fontFamily: font('bold'),
    fontSize: 20,
    marginBottom: 14,
  },
  content: {
    fontFamily: font(),
    fontSize: 16,
  },
})
