import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { StyleSheet, Text, TextStyle, View, ViewStyle, Platform } from 'react-native'
import { t } from '../../localization';
import { font, useTheme } from '../../theme';
import Icon from '../Icon';

interface HeaderProps {
	title?: string
	hideGoBack?: boolean
	onGoBackPress?: () => void
	onSkipPress?: () => void
	style?: ViewStyle
	titleStyle?: TextStyle
	skipTextStyle?: TextStyle
}

export default function ({
	title,
	hideGoBack,
	onGoBackPress,
	onSkipPress,
	style = {},
	titleStyle = {},
	skipTextStyle = {},
}: HeaderProps) {
	const { colors } = useTheme();
	const navigation = useNavigation();
	const canGoBack = navigation.canGoBack();

	return (
		<View style={[styles.container, style]}>
			{!hideGoBack && (canGoBack || !!onGoBackPress) && (
				<Icon
					size={16}
					style={styles.goBack}
					name={'arrow_back'}
					color={colors.icon.default}
					onPress={!!onGoBackPress ? onGoBackPress : navigation.goBack}
				/>
			)}
			<Title title={title} style={[titleStyle, { flex: 1 }]} />
			{!!onSkipPress && (
				<Text
					style={[styles.skip, { color: colors.text.title }, skipTextStyle]}
					onPress={onSkipPress}
				>
					{t('skip')}
				</Text>
			)}
		</View >
	)
}

export function Title({
	title,
	style,
}: {
	title: string
	style?: TextStyle | TextStyle[]
}) {

	const { colors } = useTheme()

	return (
		<Text style={[styles.title, { color: colors.header.title }, style]}>
			{title}
		</Text>
	)
}

const styles = StyleSheet.create({
	container: {
		paddingTop: Platform.OS !== 'web' ? 20 : 0,
		paddingBottom: Platform.OS !== 'web' ? 12 : 0,
		flexDirection: 'row',
		alignItems: 'center',
		paddingHorizontal: 16,
	},
	goBack: {
		marginRight: 15,
	},
	title: {
		fontSize: 20,
		lineHeight: 24,
		fontFamily: font(),
	},
	skip: {
		fontFamily: font('bold'),
		fontSize: 16,
		lineHeight: 19,
		alignSelf: 'flex-end',
	},
})
