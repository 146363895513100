import React, { useRef } from 'react'
import {
	View,
	Text,
	TextInput as RNTextInput,
	ViewStyle,
	TextStyle,
	KeyboardTypeOptions,
	StyleSheet,
} from 'react-native'
import { font, useTheme } from '../theme';
import { capitalize } from '../service';

interface TextInputProps {
	style?: ViewStyle | ViewStyle[]
	textStyle?: TextStyle | TextStyle[]
	value?: string | undefined
	placeholder?: string
	onChange?: (value: string) => void
	maxLength?: number
	keyboardType?: KeyboardTypeOptions
	smallMultiline?: boolean,
	multiline?: boolean,
	readonly?: boolean,
	forceCapitalize?: boolean,
	numberOfLines?: number,
	error?: string,
}

export default function TextInput({
	style,
	textStyle,
	value,
	placeholder,
	onChange,
	maxLength,
	keyboardType,
	smallMultiline,
	multiline,
	readonly,
	forceCapitalize = false,
	numberOfLines = 1,
	error,
	...props
}: TextInputProps) {

	const { colors } = useTheme()

	const inputRef = useRef(null)

	const _textStyle = styles.text

	let _style: ViewStyle = {}
	let paddingHorizontal: string | number = 16
	let paddingVertical: string | number = 10
	if (!!style) {
		if (Array.isArray(style)) {
			style.forEach(s => _style = { ..._style, ...s })
		} else {
			_style = { ...style }
		}
	}

	const onChangeText = (text: string) => {
		if (!onChange) return null
		let _text = text

		// ----- bugfix duplicate first letter ----- https://github.com/facebook/react-native/issues/11068
		const isBugfix = value?.length === 1 && ((text.length || 0) - (value?.length || 0) == 2) && value?.toLowerCase() === text.substring(1, 2).toLowerCase()
		if (isBugfix) _text = _text.replace(_text.substring(0, 2), value)
		// ---- ---- end ----- ----

		if (forceCapitalize && _text.length === 1) {
			_text = capitalize(_text)
			inputRef.current.setNativeProps({ text: _text })
		}
		onChange(_text)
	}

	const isError = !!error && error.length > 0

	return (
		<View style={_style}>
			<View
				style={[
					styles.wrapper,
					{
						backgroundColor: !isError ? colors.background.input : colors.background.primary,
						borderWidth: !isError ? 0 : 1,
						borderColor: !isError ? 'transparent' : colors.error,
					},
				]}>
				<RNTextInput
					ref={inputRef}
					style={[
						_textStyle,
						{
							color: !readonly ? colors.text.default : colors.text.primary,
							minHeight: !(multiline || smallMultiline)
								? undefined
								: multiline
									? _textStyle.lineHeight * 5
									: _textStyle.lineHeight,
							height: multiline ? undefined : _textStyle.lineHeight,
							paddingHorizontal,
							paddingVertical,
							cursor: readonly ? 'default' : 'auto',
						},
						textStyle
					]}
					value={value}
					placeholder={placeholder}
					keyboardType={keyboardType}
					onChangeText={onChangeText}
					maxLength={maxLength}
					multiline={multiline || smallMultiline}
					placeholderTextColor={colors.text.placeholder}
					editable={!readonly}
					autoCapitalize={'none'}
					numberOfLines={numberOfLines}
					{...props}
				/>
			</View>
			{isError && (
				<Text style={{
					marginTop: 8,
					color: colors.error,
				}}>{error}</Text>
			)}
		</View>
	)
}

const styles = StyleSheet.create({
	wrapper: {
		borderRadius: 10,
	},
	authText: {
		fontFamily: font(),
		fontSize: 20,
		lineHeight: 24,
	},
	text: {
		fontFamily: font(),
		fontSize: 16,
		lineHeight: 19,
		textAlignVertical: 'top',
	},
})
