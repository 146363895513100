import { put, call, delay, select } from 'redux-saga/effects'
import { ApiResponse } from 'apisauce';
import { api, ApiSuccessResponse } from '../../api'
import {
	Action,
	ApiFailureResponse,
	AppointmentCancelRequest,
	AppointmentCancelSuccessResponse,
	AppointmentGenderRequest,
	AppointmentGenderSuccessResponse,
	AppointmentMakeRequest,
	AppointmentMakeSuccessResponse,
	AppointmentPatientRequest,
	AppointmentPatientSuccessRespoonse,
	AppointmentPrimaryReserveRequest,
	AppointmentPrimaryReserveSuccessResponse,
	AppointmentPrimarySlotsRequest,
	AppointmentSlotsSuccessResponse,
	AppointmentsRequest,
	AppointmentsSuccessResponse,
	AppointmentSecondarySlotsRequest,
	AppointmentSecondaryReserveRequest,
	AppointmentSecondaryReserveSuccessResponse,
	AppointmentSecondaryReserveDeleteRequest,
	AppointmentSecondaryReserveDeleteSuccessResponse,
	AppointmentPaymentStatusRequest,
	AppointmentPaymentStatusSuccessResponse,
	AppointmentCheckRequest,
	AppointmentCheckSuccess,
	AppointmentType,
	AppointmentPrimaryScheduleRequest,
	AppointmentPrimaryScheduleResponse,
	AppointmentSecondaryScheduleRequest,
	AppointmentSecondaryScheduleResponse,
} from '../../types';
import {
	appointmentGenderFailureAction,
	appointmentGenderSuccessAction,
	appointmentPrimarySlotsSuccessAction,
	appointmentPrimarySlotsFailureAction,
	appointmentSecondarySlotsSuccessAction,
	appointmentSecondarySlotsFailureAction,
	appointmentSecondaryReserveSuccessAction,
	appointmentPrimaryReserveFailureAction,
	appointmentMakeSuccessAction,
	appointmentMakeFailureAction,
	appointmentPatientSuccessAction,
	appointmentPatientFailureAction,
	appointmentsFutureSuccessAction,
	appointmentsFutureFailureAction,
	appointmentsPastSuccessAction,
	appointmentsPastFailureAction,
	appointmentsFutureRequestAction,
	appointmentsPastRequestAction,
	appointmentPrimaryReserveSuccessAction,
	appointmentSecondaryReserveFailureAction,
	appointmentSecondaryReserveDeleteSuccessAction,
	appointmentSecondaryReserveDeleteFailureAction,
	appointmentPaymentStatusSuccessAction,
	appointmentPaymentStatusFailureAction,
	appointmentPaymentCancelSuccessAction,
	appointmentPaymentCancelFailureAction,
	appointmentCheckFailureAction,
	appointmentCheckSuccessAction,
	appointmentPrimaryCalendarFailureAction,
	appointmentPrimaryCalendarSuccessAction,
	appointmentSecondaryCalendarSuccessAction,
	appointmentSecondaryCalendarFailureAction,
} from '../redux/appointments';
import { conferenceChatRequestAction } from '../redux/conference';
import { saveBase64File } from '../../service';
import { AppState } from '..';

const getAppointments = (store: AppState): AppointmentType[] => ([...(store.appointment.future_appointments || []), ...(store.appointment.past_appointments || [])])


export function* appointmentsFutureRequestSaga(action: Action<AppointmentsRequest>) {
	const response: ApiResponse<ApiSuccessResponse<AppointmentsSuccessResponse>> = yield call(api.appointmentsFutureRequest, action?.params)
	if (response.ok) {
		yield put(appointmentsFutureSuccessAction(response.data.result))
		if (response.data?.result.appointments.length && response.data?.result.appointments[0].id) {
			yield put(conferenceChatRequestAction(response.data.result.appointments[0].id))
		}
	} else {
		yield put(appointmentsFutureFailureAction(response.data as unknown as ApiFailureResponse))
	}
}

export function* appointmentsPastRequestSaga(action: Action<AppointmentsRequest>) {
	const response: ApiResponse<ApiSuccessResponse<AppointmentsSuccessResponse>> = yield call(api.appointmentsPastRequest, action?.params)
	if (response.ok) {
		yield put(appointmentsPastSuccessAction(response.data.result))
	} else {
		yield put(appointmentsPastFailureAction(response.data as unknown as ApiFailureResponse))
	}
}

export function* appointmentGendersRequestSaga(action: Action<AppointmentGenderRequest>) {
	const response: ApiResponse<ApiSuccessResponse<AppointmentGenderSuccessResponse>> = yield call(api.appointmentGenderRequest, action.params)
	if (response.ok) {
		yield put(appointmentGenderSuccessAction(response.data.result))
	} else {
		yield put(appointmentGenderFailureAction(response.data as unknown as ApiFailureResponse))
	}
}

export function* appointmentPrimaryCalendarRequestSaga(action: Action<AppointmentPrimaryScheduleRequest>) {
	const response: ApiResponse<ApiSuccessResponse<AppointmentPrimaryScheduleResponse>> = yield call(api.appointmentPrimaryScheduleRequest, action.params)
	if (response.ok) {
		yield put(appointmentPrimaryCalendarSuccessAction(response.data.result))
	} else {
		yield put(appointmentPrimaryCalendarFailureAction(response.data as unknown as ApiFailureResponse))
	}
}

export function* appointmentPrimarySlotsRequestSaga(action: Action<AppointmentPrimarySlotsRequest>) {
	const response: ApiResponse<ApiSuccessResponse<AppointmentSlotsSuccessResponse>> = yield call(api.appointmentPrimarySlotsRequest, action.params)
	if (response.ok) {
		yield put(appointmentPrimarySlotsSuccessAction(response.data.result))
	} else {
		yield put(appointmentPrimarySlotsFailureAction(response.data as unknown as ApiFailureResponse))
	}
}

export function* appointmentSecondaryCalendarRequestSaga(action: Action<AppointmentSecondaryScheduleRequest>) {
	const response: ApiResponse<ApiSuccessResponse<AppointmentSecondaryScheduleResponse>> = yield call(api.appointmentSecondaryScheduleRequest, action.params)
	if (response.ok) {
		yield put(appointmentSecondaryCalendarSuccessAction(response.data.result))
	} else {
		yield put(appointmentSecondaryCalendarFailureAction(response.data as unknown as ApiFailureResponse))
	}
}

export function* appointmentSecondarySlotsRequestSaga(action: Action<AppointmentSecondarySlotsRequest>) {
	const response: ApiResponse<ApiSuccessResponse<AppointmentSlotsSuccessResponse>> = yield call(api.appointmentSecondarySlotsRequest, action.params)
	if (response.ok) {
		yield put(appointmentSecondarySlotsSuccessAction(response.data.result))
	} else {
		yield put(appointmentSecondarySlotsFailureAction(response.data as unknown as ApiFailureResponse))
	}
}

export function* appointmentPrimaryReserveRequestSaga(action: Action<AppointmentPrimaryReserveRequest>) {
	const response: ApiResponse<ApiSuccessResponse<AppointmentPrimaryReserveSuccessResponse>> = yield call(api.appointmentPrimaryReserveRequest, action.params)
	if (response.ok) {
		yield put(appointmentPrimaryReserveSuccessAction(response.data.result))
	} else {
		yield put(appointmentPrimaryReserveFailureAction(response.data as unknown as ApiFailureResponse))
	}
}

export function* appointmentSecondaryReserveRequestSaga(action: Action<AppointmentSecondaryReserveRequest>) {
	const response: ApiResponse<ApiSuccessResponse<AppointmentSecondaryReserveSuccessResponse>> = yield call(api.appointmentSecondaryReserveRequest, action.params)
	if (response.ok) {
		yield put(appointmentSecondaryReserveSuccessAction(response.data.result))
	} else {
		yield put(appointmentSecondaryReserveFailureAction(response.data as unknown as ApiFailureResponse))
	}
}

export function* appointmentSecondaryReserveDeleteRequestSaga(action: Action<AppointmentSecondaryReserveDeleteRequest>) {
	const response: ApiResponse<ApiSuccessResponse<AppointmentSecondaryReserveDeleteSuccessResponse>> = yield call(api.appointmentSecondaryReserveDeleteRequest, action.params)
	if (response.ok) {
		yield put(appointmentSecondaryReserveDeleteSuccessAction(response.data.result))
	} else {
		yield put(appointmentSecondaryReserveDeleteFailureAction(response.data as unknown as ApiFailureResponse))
	}
}

export function* appointmentMakeRequestSaga(action: Action<AppointmentMakeRequest>) {
	const response: ApiResponse<ApiSuccessResponse<AppointmentMakeSuccessResponse>> = yield call(api.appointmentMakeRequest, action.params)
	if (response.ok) {
		yield put(appointmentMakeSuccessAction(response.data.result))
		yield put(appointmentsFutureRequestAction())
	} else {
		yield put(appointmentMakeFailureAction(response.data as unknown as ApiFailureResponse))
	}
}

export function* appointmentPatientCheckSaga(action: Action<AppointmentPatientRequest>) {
	const response: ApiResponse<ApiSuccessResponse<AppointmentPatientSuccessRespoonse>> = yield call(api.appointmentPatientCheck, action.params)
	if (response.ok) {
		yield put(appointmentPatientSuccessAction(response.data.result))
	} else {
		yield put(appointmentPatientFailureAction(response.data as unknown as ApiFailureResponse))
	}
}

export function* appointmentCancelSaga(action: Action<AppointmentCancelRequest>) {
	const response: ApiResponse<ApiSuccessResponse<AppointmentCancelSuccessResponse>> = yield call(api.appointmentCancelRequest, action.params)
	if (response.ok) {
		yield put(appointmentPatientSuccessAction(response.data.result))
		yield put(appointmentsFutureRequestAction())
		yield put(appointmentsPastRequestAction())
	} else {
		yield put(appointmentPatientFailureAction(response.data as unknown as ApiFailureResponse))
	}
}

export function* appointmentPaymentCancelSaga(action: Action<number>) {
	const response: ApiResponse<ApiSuccessResponse<null>> = yield call(api.appointmentPaymentCancelRequest, action.params)
	if (response.ok) {
		yield put(appointmentPaymentCancelSuccessAction())
	} else {
		yield put(appointmentPaymentCancelFailureAction(response.data as unknown as ApiFailureResponse))
	}
}

export function* appointmentPaymentStatusSaga(action: Action<AppointmentPaymentStatusRequest>) {
	const response: ApiResponse<ApiSuccessResponse<AppointmentPaymentStatusSuccessResponse>> = yield call(api.appointmentPaymentStatusRequest, action.params)
	if (response.ok) {
		yield put(appointmentPaymentStatusSuccessAction(response.data.result))
	} else {
		yield put(appointmentPaymentStatusFailureAction(response.data as unknown as ApiFailureResponse))
	}
}

export function* appointmentCheckRequestSaga(action: Action<AppointmentCheckRequest>) {
	const response: ApiResponse<ApiSuccessResponse<AppointmentCheckSuccess>> = yield call(api.appointmentCheckRequest, action.params)
	if (response.ok) {
		const appointments: AppointmentType[] = yield select(getAppointments)
		const appointment_id = appointments.find(appointment => appointment.paymentId === action.params.paymentId)?.id
		const fileName = `Aidera-${appointment_id}.jpeg`
		yield call(saveBase64File.bind(this, response.data.result, fileName))
		yield put(appointmentCheckSuccessAction())
	} else {
		yield put(appointmentCheckFailureAction(response.data as unknown as ApiFailureResponse))
	}
}
