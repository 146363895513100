import config from '../../../config'
import { Localizations } from '../../localization'
import { Themes } from '../../../theme'
import {
	Nullable,
	AppDocumentType,
	AppSuccessResponse,
} from '../../types'

export interface AppState {
	locale: Localizations
	// deviceTheme: boolean
	theme: Themes
	documents: Nullable<AppDocumentType[]>
	fetching: boolean,
}

export const APP_STARTED = 'APP_STARTED'
export const APP_LOADED = 'APP_LOADED'
export const APP_REQUEST = 'APP_REQUEST'
export const APP_SUCCESS = 'APP_SUCCESS'
export const APP_FAILURE = 'APP_FAILURE'
// export const APP_SET_THEME = 'APP_SET_THEME'
// export const APP_SET_DEVICE_THEME = 'APP_SET_DEVICE_THEME'

export const appStartedAction = () => ({ type: APP_STARTED })
export const appRequestAction = () => ({ type: APP_REQUEST })
export const appSuccessAction = (payload: AppSuccessResponse) => ({ type: APP_SUCCESS, payload })
export const appFailureAction = (payload: any) => ({ type: APP_FAILURE, payload })
// export const appThemeAction = (params: string) => ({ type: APP_SET_THEME, params })
// export const appDeviceThemeAction = (params: boolean) => ({ type: APP_SET_DEVICE_THEME, params })
export const appLoadedAction = () => ({ type: APP_LOADED })

const initialState: AppState = {
	// deviceTheme: false,
	theme: 'light',
	locale: 'ru',
	documents: null,
	fetching: false,
}

const AppReducer = (state = initialState, action: any): AppState => {
	const { type, params, payload } = action
	config.showReduxAction && console.log(type, !!action.route ? action.route : '')

	switch (type) {
		case APP_STARTED: {
			return { ...state, fetching: true }
		}
		// case APP_SET_THEME: {
		// 	return { ...state, theme: params }
		// }
		// case APP_SET_DEVICE_THEME: {
		// 	return { ...state, deviceTheme: params }
		// }
		// case APP_REQUEST: {
		// 	return { ...state }
		// }
		case APP_SUCCESS: {
			const {
				app: {
					documents,
				},
			} = payload as AppSuccessResponse
			return {
				...state,
				documents,
			}
		}
		case APP_LOADED: {
			return { ...state, fetching: false }
		}
		// case APP_FAILURE: {
		// 	return { ...state }
		// }
		default: return state
	}
}

export default AppReducer
